import SolutionStatus from '../common/SolutionStatus';

export const config = {
  formId: 'highlights',
  formTitle: 'Highlights',
  formValueType: 'Object',
  tabs: [
    {
      id: 'highlights',
      label: 'Highlights',
      contents: [
        {
          id: 'highlights',
          fields: [
            {
              titleStyle: 'multiple-add-title-sub',
              type: 'multiple_add_form',
              name: 'highlight_list',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: function(status) {
                return (
                  !status ||
                  status === SolutionStatus.REGISTERED ||
                  status === SolutionStatus.RECALLED ||
                  status === SolutionStatus.REJECTED ||
                  status === SolutionStatus.REVOKED ||
                  status === SolutionStatus.DRAFT
                );
              },
              addLinkText: 'ADD HIGHLIGHT',
              formDefaultValue: {
                id: '',
                description: ''
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: 'description',
                        label: 'Highlight',
                        type: 'text_area',
                        maxLength: 128,
                        style:
                          'formtextfield form-text-area form-highlight-text-area',
                        gridStyle: 'layoutcell',
                        toolTipText:
                          'Brief information about the important highlights of the app.'
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    highlights: {
      highlight_list: []
    }
  },
  activeTabId: 'highlights'
};
