import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CogsLogo from '../common/images/cogs.png';
import { Link } from 'react-router-dom';
import { Column } from 'cdk-radial';
import { modifyStr } from '../../utils/utils';

const FeaturedListItemForSellPage = ({ solution, orgMapObj = [] }) => {
  const providerName = orgMapObj[solution && solution.orgId] || '';

  return solution ? (
    <Column>
      <div className="inline-featured-solution">
        <div
          className="cursor-pointer"
          data-qa={`featured-solution-${
            solution.overview ? solution.overview.name : 'no_image'
          }`}
        >
          <Link
            to={`/solutions/${modifyStr(
              providerName
                .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
                .toLowerCase()
            ).toLowerCase()}/${modifyStr(
              solution &&
                solution.overview &&
                solution.overview.name
                  .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
                  .toLowerCase()
            ).toLowerCase()}?id=${solution.id}`}
          >
            <div>
              {solution.overview && solution.overview.solutionLogo ? (
                <img
                  src={`${solution.overview.solutionLogo}?${Math.random()}`}
                  className="published-apps-sol-logo"
                />
              ) : (
                <img
                  src={CogsLogo}
                  alt="App Logo"
                  height="100px"
                  width="100px"
                />
              )}
            </div>
          </Link>
          <div className="featured-sol-name">
            {solution.overview && solution.overview.name ? (
              solution.overview.name
            ) : (
              <FormattedMessage
                id="FeaturedListItem.text1"
                defaultMessage="No app name to display..."
              />
            )}
          </div>
        </div>
      </div>
    </Column>
  ) : null;
};

export default injectIntl(FeaturedListItemForSellPage);
