import React from 'react';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import { FormattedMessage } from 'react-intl';
import SubscriptionStatusConstants from '../../../../../common/subscription-status/SubscriptionStatusConstants';

/**
 *  Mapping added for differentiate between label and Values
 */
let statusLabelMapping = {};
statusLabelMapping[SubscriptionStatusConstants.ACTIVE.toLowerCase()] = 'Active';
statusLabelMapping[
  SubscriptionStatusConstants.PENDING_ACTIVATION.toLowerCase()
] = 'Pending';
statusLabelMapping[SubscriptionStatusConstants.EXPIRED.toLowerCase()] =
  'Expired';
statusLabelMapping[SubscriptionStatusConstants.CANCELLED.toLowerCase()] =
  'Cancelled';
statusLabelMapping[SubscriptionStatusConstants.INACTIVE.toLowerCase()] =
  'Inactive';

class InsightPlanReport extends React.Component {
  constructor(props) {
    super(props);
    this.planStatus = [];
  }

  getHeaders(planReports) {
    this.planStatus = [];
    if (!planReports.length || !planReports[0]['usageReport']) {
      return this.planStatus;
    }
    this.planStatus = Object.keys(planReports[0]['usageReport']);
    return this.planStatus;
  }

  /**
   * Function finds unique plans from all Listing versions
   * @param {*} solutions 
   * return format 
   * {
      [plan_id] : {
        planName :[Actual Plan Name],
        isTheFreeTrial: [isTheFreeTrial]
      }
   * }
   */
  findUniquePlans(solutions) {
    let plans = {};
    if (solutions.slice(-1)[0].activateOnly !== false) {
      plans['NA'] = {
        planName: 'N/A',
        isTheFreeTrial: false
      };
      return plans;
    }
    solutions.map(solution => {
      return (
        solution.plans &&
        solution.plans.map(plan => {
          plans[plan.id] = {
            planName: plan.planName,
            isTheFreeTrial: plan.isTheFreeTrial
          };
          return plans;
        })
      );
    });
    return plans;
  }

  /**
   * Function finds subscriptions for given plain Id (If any)
   * @param {*} planId
   * @param {*} subscriptions
   */
  findPlanSubscription(planId, subscriptions) {
    if (!subscriptions || !subscriptions.length) {
      return [];
    }
    let filteredSubscriptions = subscriptions.filter(subscription => {
      if (planId === 'NA') {
        return true;
      }

      if (subscription.plan) {
        return subscription.plan.id === planId;
      }
      if (planId === 'manual') {
        return true;
      }
    });
    return filteredSubscriptions;
  }

  /**
   * function return planReport skeleton
   * @param {*} planName 
   * Return Format
      {
            planName: [Plan name],
            usageReport: {
              Active: 0,
              Pending: 0,
              Expired: 0,
              Cancelled: 0
            }
      }
   */
  preparePlanReportSkeleton(planName) {
    let planReport = {
      planName: planName,
      usageReport: {}
    };
    Object.keys(statusLabelMapping).map(value => {
      let label = statusLabelMapping[value];
      planReport['usageReport'][label] = 0;
      return planReport;
    });
    return planReport;
  }

  increaseSubscriptionCount(planReport, status, isTheFreeTrial) {
    if (
      !statusLabelMapping[status] ||
      (status === SubscriptionStatusConstants.EXPIRED.toLowerCase() &&
        !isTheFreeTrial)
    ) {
      return;
    }
    planReport['usageReport'][statusLabelMapping[status]] += 1;
  }

  /**
   * Function Prepares PlanReport array 
   * Return Format:
    [
        {
          planName: [Plan Name],
          usageReport: {
            Active: [count],
            Pending: [count],
            Expired: [count OR '-'],
            Cancelled: [count]
          }
        }
      ]
   */
  preparePlanReport() {
    const { solutions, subscriptions } = this.props;
    let planReports = [];
    if (!solutions || !solutions.length) {
      return [];
    }
    let plans = this.findUniquePlans(solutions);
    if (solutions.slice(-1)[0].activateOnly === false) {
      plans['manual'] = {
        planName: 'N/A',
        isTheFreeTrial: false
      };
    }
    Object.keys(plans).forEach(planId => {
      let plan = plans[planId];
      let filteredSubscriptions = this.findPlanSubscription(
        planId,
        subscriptions
      );
      let planReport = this.preparePlanReportSkeleton(plan.planName);
      if (!filteredSubscriptions.length) {
        return null;
      }

      filteredSubscriptions.forEach(subscription => {
        this.increaseSubscriptionCount(
          planReport,
          subscription.status.toLowerCase(),
          plan.isTheFreeTrial
        );
      });
      planReports.push(planReport);
    });
    return planReports;
  }

  render() {
    let planReport = this.preparePlanReport();
    if (!planReport || !planReport.length) {
      return null;
    }
    return (
      <div className="insight-plan-report">
        <hr />
        <DataTable className="table-list">
          <DataTableHeader>
            <DataTableRow>
              <DataTableHeaderCell
                style={{
                  width: '15%'
                }}
              >
                <div className="insight-plan-title">
                  <FormattedMessage
                    id="InsightPlanReport.Plans"
                    defaultMessage="Plans"
                  />
                </div>
              </DataTableHeaderCell>
              {planReport &&
                planReport.length &&
                this.getHeaders(planReport).map(status => {
                  return (
                    <DataTableHeaderCell
                      style={{
                        width: '15%'
                      }}
                    >
                      <FormattedMessage
                        id={'InsightPlanReport.Plans.' + status}
                        defaultMessage={status}
                      />
                    </DataTableHeaderCell>
                  );
                })}
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {planReport.map((plan, index) => {
              return (
                <DataTableRow key={index}>
                  <DataTableCell>
                    <div className="insight-plan-title">{plan.planName}</div>
                  </DataTableCell>
                  {this.planStatus.map(statusName => {
                    return (
                      <DataTableCell>
                        <div className="insight-usage-value">
                          {plan.usageReport[statusName]}
                        </div>
                      </DataTableCell>
                    );
                  })}
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTable>
      </div>
    );
  }
}

export default InsightPlanReport;
