import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { Switch } from '@cdk-uip/react-switch';

export class FormOnOffSwitch extends React.Component {
  handleCheckBoxChange = (event, name) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = event.target.checked;
    this.props.onChange(
      this.resetDependent(event.target.checked, formFieldValues)
    );
  };

  resetDependent = (value, formFieldValues) => {
    if (this.props.fieldProperties.resetDependent) {
      let resetValues = this.props.fieldProperties.resetDependent(value);
      if (resetValues) {
        Object.keys(resetValues).forEach(key => {
          formFieldValues[key] = resetValues[key];
        });
      }
    }
    return formFieldValues;
  };

  render() {
    return (
      <div
        className="form-field-with-tooltip onoff-switch-item"
        id={this.props.fieldProperties.name}
      >
        <Switch
          className="onoff-switch-item-switch"
          checked={this.props.value}
          disabled={this.props.fieldProperties.isDisabled}
          required={this.props.fieldProperties.required}
          onChange={event => {
            this.handleCheckBoxChange(event, this.props.fieldProperties.name);
          }}
        />

        <label className="onoff-switch-item-label">
          {typeof this.props.fieldProperties.label === 'function'
            ? this.props.fieldProperties.label(this.props.labelDependsOn)
            : this.props.fieldProperties.label}

          {this.props.fieldProperties.toolTipText && (
            <span>
              <i
                id={`tooltip_${this.props.fieldProperties.name}`}
                className="material-icons help-label-icon"
              >
                help
              </i>
            </span>
          )}
        </label>
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${this.props.fieldProperties.name}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>
        <label className={'onoff-switch-item-helper-text'}>
          {this.props.fieldProperties.helperText}
        </label>
      </div>
    );
  }
}

export default FormOnOffSwitch;
