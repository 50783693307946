import React from 'react';
import cogsImage from '../common/images/cogs.png';

class SolutionWithAvatarLogo extends React.Component {
  state = {
    solutionName: '',
    solutionLogoURL: ''
  };

  componentDidMount() {
    if (this.props.solution && this.props.solution.overview) {
      if (this.props.solution.overview.name) {
        this.setState({ solutionName: this.props.solution.overview.name });
      }
      if (this.props.solution.overview.solutionLogo) {
        this.setState({
          solutionLogoURL: this.props.solution.overview.solutionLogo
        });
      }
    }
  }

  render() {
    const { solutionName, solutionLogoURL } = this.state;
    return (
      <div style={{ display: 'inline-block' }}>
        {solutionLogoURL ? (
          <img
            src={`${solutionLogoURL}?${Math.random()}`}
            alt="App Logo"
            className="solution-default-avatar"
            style={{ display: 'inline-block' }}
          />
        ) : (
          <img
            src={cogsImage}
            alt="App Logo"
            className="solution-default-avatar"
            style={{ display: 'inline-block' }}
          />
        )}
        <div style={{ display: 'inline-block' }}>
          {solutionName ? solutionName : '-'}
        </div>
      </div>
    );
  }
}

export default SolutionWithAvatarLogo;
