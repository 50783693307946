import React from 'react';

const FormCaptionWithAnchorTagText = ({
  style,
  title,
  linkText,
  link,
  linkStyle
}) => {
  return (
    <div className={`form-field-label ${style}`}>
      {title}
      <a
        className={`form-field-label-anchor ${linkStyle}`}
        target="_blank"
        href={link}
      >
        {linkText}
      </a>
    </div>
  );
};

export default FormCaptionWithAnchorTagText;
