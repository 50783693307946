export const config = {
  formId: 'subscription_management',
  // formTitle: 'Subscription Management',
  formTitle: 'Subscription Enablement',
  formValueType: 'Object',
  formNote:
    'By selecting below option, you can allow or deny subscriptions for the app',
  tabs: [
    {
      id: 'subscription_management',
      //label: 'Subscription Management',
      label: 'Subscription Enablement',
      contents: [
        {
          id: 'allowSubscriptions',
          fields: [
            {
              formtitle: 'Select Subscription Option',
              type: 'FortellisTitle3',
              style: 'provisioningtitle'
            },
            {
              name: 'allowSubscriptions',
              label: 'Allow Subscriptions',
              type: 'radio-option',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['allowSubscriptions'] = true;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return formValue[name] === true;
              }
            }
          ]
        },
        {
          id: 'denySubscription',
          fields: [
            {
              name: 'allowSubscriptions',
              label: 'Deny Subscriptions',
              type: 'radio',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['allowSubscriptions'] = false;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return formValue[name] === false;
              }
            },
            {
              formtitle:
                'By selecting the above option, you will deny subscriptions for the app.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    subscription_management: {
      allowSubscriptions: ''
    }
  },
  activeTabId: 'subscription_management'
};
