import React from 'react';
import { FormattedMessage } from 'react-intl';
import FortellisDialog from '../../../common/FortellisDialog';

const ConfirmDelete = message => (
  <FormattedMessage
    id="FormBuilder.confirmDelete"
    defaultMessage={message.message}
  />
);

export class FormDeleteIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false
    };
    this.onAccept = () => {
      const { onAccept } = this.state.confirm;
      this.setState({ confirm: false });
      onAccept();
    };
    this.onCancel = () => {
      this.setState({ confirm: false });
    };
    this.onDelete = () => {
      this.setState({
        confirm: {
          message: () => <ConfirmDelete message={this.props.confirmMessage} />,
          onAccept: () => this.props.onDelete()
        }
      });
    };
  }

  render() {
    const { confirm } = this.state;
    return (
      <span>
        <i
          className={`material-icons ${this.props.deleteIconStyle}`}
          onClick={e => {
            e.preventDefault();
            this.onDelete();
          }}
          data-cy="icon_del_item"
        >
          delete
        </i>

        {!!confirm && (
          <FortellisDialog
            title={this.props.confirmMessageTitle}
            message={confirm ? confirm.message() : false}
            open={!!confirm}
            acceptButtonName="DELETE"
            cancelButtonName="CANCEL"
            onAccept={() => this.onAccept()}
            onCancel={() => this.onCancel()}
          />
        )}
      </span>
    );
  }
}

export default FormDeleteIcon;
