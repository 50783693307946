import React from 'react';
import './NoResults.scss';

const NoResults = ({ searchText }) => {
  return (
    <div className="no_results_container-nl">
      <label className="no-results-found-for_label-nl">
        {`No results found for `}
        <span className="no-results-found-for_searchText-nl">{searchText}</span>
      </label>
      <label className="check-spelling-and_label-nl">
        • Check spelling and try your search again
      </label>
      <label className="check-spelling-and_label-nl">
        • Look up by filters
      </label>
    </div>
  );
};

export default NoResults;
