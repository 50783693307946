import React, { Component } from 'react';
import './UserConsentDialog.scss';
import axios from 'axios';
import Spinner from './Spinner';
import OneClickConsentDialog from './consents/OneClickConsentDialog';
import ElectronicConsentDialog from './consents/ElectronicConsentDialog';
import { convertCoordToDocusign } from './consents/utils/CoordinatesParser';

export const consentStatusConst = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

export const consentTypeConst = {
  SOLUTION_TNC: 'solution_tnc',
  API_TOS: 'api_tos',
  API_DATA: 'api_data',
  PUBLISHER_AGREEMENT: 'publisher-agreement'
};

const actionsConst = {
  SUBMIT: 'submit',
  CANCEL: 'cancel'
};
const TextAreaConst = {
  MAXLENGTH: 1000
};
const TextFieldConst = {
  USERNAME_MAXLENGTH: 40,
  USER_TITLE_MAXLENGTH: 40,
  USER_COMPANY_MAXLENGTH: 40
};

//Error Constants
const SIGNATURE_COORDINATES_ARRAY_MISSING =
  'addendum object contains coordinates key but signatureCoordinates array is missing';
const SIGNATURE_COORDINATES_TYPE_ERROR =
  'expected signatureCoordinates to be an array';
const SIGNATURE_COORDINATES_ARRAY_EMPTY = 'signatureCoordinates array is empty';
const ADDENDUM_QUERY_ERROR =
  'error in querying getAddendumSignatureCoordinates';
const SIGNATURE_COORDINATES_PARSING_ERROR =
  'error in parsing signature coordinates';

class UserConsentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consentType: 'oneClick',
      spinner: false,
      terms: '',
      signatureCoordinates: '',
      failedToCheckTermsConsentType: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.pullTerms = this.pullTerms.bind(this);
    this.readBlob = this.readBlob.bind(this);
    this.setTermsType = this.setTermsType.bind(this);
    this.setAPITermsAddendumType = this.setAPITermsAddendumType.bind(this);
  }

  onSubmit = data => {
    this.setState({}, () => {
      this.props.onSubmit(data);
    });
  };

  onCancel = () => {
    this.setState({ showSubscriberConsentModal: false }, () => {
      this.props.onCancel(this.state);
    });
  };

  /***
   * make network call to pull terms as blob
   * @returns {Promise<void>}
   *  resolves - terms
   *  rejects - error
   */
  pullTerms = async () => {
    const { consentLink = '' } = this.props;
    if (consentLink) {
      await axios(consentLink, {
        params: {
          requestId: this.props.consentCount
        },
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          var data = response.data;
          this.readBlob(data);
        })
        .catch(error => {
          this.setState({
            failedToCheckTermsConsentType: true
          });
          console.error(error);
        });
    }
  };

  /***
   * read terms blob, implements FileReader to read blob as data url
   * @param data - stream of terms
   *  sets terms state to base64 blob
   */
  readBlob(data) {
    const reader = new FileReader();
    reader.onloadend = () => {
      var base64data = reader.result;
      this.setState({ terms: base64data });
    };
    reader.readAsDataURL(data);
  }

  /***
   * * * * determine if uploaded terms are electronic signing or one click
   * make HEAD call to terms s3 url, if response headers contain 'x-amz-meta-signature-coordinates' header, then term type is electronic signing
   * if response headers do not contain 'x-amz-meta-signature-coordinates' header, then term types is one click
   * set consentType state accordingly
   * @returns {Promise<void>}
   */
  setTermsType = async () => {
    const { consentLink = '' } = this.props;
    if (consentLink) {
      await axios(consentLink, {
        params: {
          requestId: this.props.consentCount
        },
        method: 'HEAD'
      })
        .then(response => {
          if (
            response.headers.hasOwnProperty('x-amz-meta-signature-coordinates')
          ) {
            console.log('solution terms are electronic signing');
            this.setState({
              spinner: false,
              consentType: 'electronic signing',
              signatureCoordinates:
                response.headers['x-amz-meta-signature-coordinates']
            });
          } else {
            console.log('solution terms are one click signing');
            this.setState({
              spinner: false
            });
          }
        })
        .catch(error => {
          this.setState({
            unableToLoadPdf: true,
            spinner: false,
            disableRadios: true
          });
          console.error(error);
        });
    }
  };

  /***
   * determine if uploaded API terms or addendum are electronic signing or one click
   * check if provider object contains addendum or terms key
   * if key exists, then check for signature coordinates
   * if signature coordinates exist then the consent type is electronic consent
   * set consentType state accordingly
   * @returns {Promise<void>}
   */
  setAPITermsAddendumType = async () => {
    const { provider } = this.props;
    try {
      this.setState({
        spinner: false,
        loading: false
      });

      if (!provider) {
        console.error('provider is undefined', this.props);
        this.props.onCancel(true);
      }

      let signatureObject;
      let type;
      if (this.props.type === 'addendum') {
        signatureObject = provider.addendum;
        type = 'addendum';
      } else {
        signatureObject = provider.terms;
        type = 'terms';
      }

      if (!signatureObject) {
        console.log(`consent type is one click, ${type} key not found`);
        return;
      }

      if (!signatureObject.coordinates) {
        console.log('consent type is one click, coordinates not found');
        return;
      }

      if (!signatureObject.coordinates.signatureCoordinates) {
        console.error(SIGNATURE_COORDINATES_ARRAY_MISSING);
        return this.props.onCancel(true);
      }

      if (!Array.isArray(signatureObject.coordinates.signatureCoordinates)) {
        console.error(SIGNATURE_COORDINATES_TYPE_ERROR);
        return this.props.onCancel(true);
      }

      if (!signatureObject.coordinates.signatureCoordinates.length > 0) {
        console.error(SIGNATURE_COORDINATES_ARRAY_EMPTY);
        return this.props.onCancel(true);
      }

      let signatureCoordinates;
      try {
        signatureCoordinates = signatureObject.coordinates.signatureCoordinates.map(
          coordinates => {
            return convertCoordToDocusign(
              {
                x: coordinates.x,
                y: coordinates.y,
                page: coordinates.page
              },
              signatureObject.docSize
            );
          }
        );
      } catch (e) {
        console.error(SIGNATURE_COORDINATES_PARSING_ERROR, e);
      }

      console.log('addendum type is electronic signing');
      this.setState({
        consentType: 'electronic signing',
        signatureCoordinates: JSON.stringify(signatureCoordinates),
        spinner: false
      });
    } catch (e) {
      console.error(ADDENDUM_QUERY_ERROR, e);
    }
  };

  componentDidMount() {
    const { type } = this.props;

    if (type === consentTypeConst.PUBLISHER_AGREEMENT) return;

    this.setState({ spinner: true });

    this.pullTerms();

    if (type === 'solution terms') {
      this.setTermsType();
    } else {
      this.setAPITermsAddendumType();
    }
  }

  render() {
    if (this.state.spinner) {
      return <Spinner />;
    }

    if (this.state.failedToCheckTermsConsentType) {
      //show error
    }

    if (this.state.error) {
      return null;
    }

    if (this.state.consentType === 'electronic signing') {
      return (
        <ElectronicConsentDialog
          type={this.props.type}
          consentLink={this.props.consentLink}
          email={this.props.email}
          terms={this.state.terms}
          signatureCoordinates={this.state.signatureCoordinates}
          onClose={this.props.onCancel}
          onSubmit={this.props.onSubmit}
          entity={this.props.entity}
        />
      );
    } else {
      return (
        <OneClickConsentDialog
          isPublisherAgreement={
            this.props.type === consentTypeConst.PUBLISHER_AGREEMENT
              ? true
              : false
          }
          consentLink={this.props.consentLink}
          onClose={this.props.onCancel}
          onSubmit={this.props.onSubmit}
          saving={this.props.saving}
        />
      );
    }
  }
}

export default UserConsentDialog;
