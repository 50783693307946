import React from 'react';
import { Card } from '@cdk-uip/react-card';
import { CardActions } from '@cdk-uip/react-card';
import { CardActionButtons } from '@cdk-uip/react-card';
import { Button } from '@cdk-uip/react-button';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import { LayoutGrid } from '@cdk-uip/react-layout-grid';
import { LayoutGridCell } from '@cdk-uip/react-layout-grid';
import SolutionStatus from '../../../../common/SolutionStatus';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import FortellisTitle5 from '../../../../common/FortellisTitle5';
import FortellisSubtitle2 from '../../../../common/FortellisSubtitle2';
import FortellisBadge from '../../../../common/FortellisBadge';
import Status from '../../../Status';
import FortellisDialog from '../../../../common/FortellisDialog';
import Spinner from '../../../../common/Spinner';
import SolutionTypeURLMapperUtil from '../../../../common/SolutionTypeURLMapperUtil';
import { FormConfig } from '../../../../solution-submission/solution_submition_form_config';
import { FormUtils } from '../../../../solution-submission/common-form-api/utils/FormUtils';
import SolutionOperations from '../../../../../containers/utils/SolutionOperations';
import FortellisConstants from '../../../../common/FortellisConstants';
import PreviewSolution from '../../../../../containers/PreviewSolutionContainer';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import LoadingStepper from '../../../../common/LoadingStepper';
import DeveloperAgreement from '../../../../solution-submission/DeveloperAgreement';
import { getRedLineContractStatus } from '../../../../common/RedlineContract';
import { sendAmplitudeData } from '../../../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../../../utils/amplitude-constants';
import { environmentURLs } from '../../../../common/environment/CaptureEnvironment';
import axios from 'axios';
import { connect } from 'react-redux';
import { Toast, TOAST_POSITIONS, TOAST_VISIBILITY_DURATIONS } from 'cdk-radial';
import PaymentInfoConstants from '../../../../admin-account/solution-moderation/PaymentInfoConstants';
import navConstants from '../left-nav-config-constants';
import PaymentCheckoutDialog from '../../../../solution-submission/PaymentCheckoutDialog';
import {
  verifyAccount,
  getPaymentSetting,
  resetOtherPaymentOption,
  setPaymentDetails,
  otherPaymentOptionRequest
} from '../../../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';
import { getSavedPaymentDetailsBySolutionId } from '../../../../../redux/connectedComponents/verifyAccount/savedPaymentDetails.slice';
import { getStripeSubscriptionInfo } from '../../../../../redux/connectedComponents/stripeSubscriptionInfo/stripeSubscriptionInfo.slice';
import { getAllBanksAppsForUser } from '../../../../../redux/connectedComponents/verifyAccount/bankApps.slice';
import { modifyStr } from '../../../../../utils/utils';
import VerifyAccount from './verifyAccount/VerifyAccount';
import LearnMore from '../../../../common/LearnMore/LearnMore';

const certificationStatusValues = {
  'ready-to-certify': 'Ready to Certify',
  'in-certification': 'In Certification',
  certified: 'Certified',
  'payment-pending': 'Certification Payment Pending'
};

const PAYMENTMODE_ONLINE = 'Online';
const PAYMENTMODE_OFFLINE_AND_ONLINE = 'Offline,Online';
const SUCCESS = 'SUCCESS';
const FAILED = 'FAILED';
const DECLINED = 'declined';
const STRIPE = PaymentInfoConstants.paymentOption.STRIPE;

const PublishMessage = props => {
  const prevAppListingFee =
    (props.prevListingVersion?.stripePriceId &&
      props.prevListingVersion?.appPublishingFee) ||
    '0';
  const appListingFee =
    (props.solution?.stripePriceId && props.solution?.appPublishingFee) || '0';
  let message = `This latest listing version - ${
    props.solution.listingVersion
  } will replace the current published version available in Marketplace.
  Do you want to proceed? `;

  if (props.solution.activateOnly !== false) {
    message =
      (appListingFee !== prevAppListingFee
        ? `Your App Listing Fee is changed to $${appListingFee} from $${prevAppListingFee} and will be applicable from the next billing cycle. `
        : `Your App Listing Fee is $${appListingFee}. `) + message;
  }
  return (
    <div>
      <FormattedMessage
        id="SolutionSubmissionForm.publishListingVersionConfirmationMessageNew"
        defaultMessage={message}
      />
    </div>
  );
};
class ManageListing extends React.Component {
  state = {
    confirm: false,
    openDialogForRecall: false,
    openDialogForWithdraw: false,
    editPublishedSolution: false,
    publishNewVersion: false,
    openPreviewDialog: false,
    publishConfirmation: false,
    showPaymentWarning: false,
    previewListingVersion: '',
    formStaticValues: {
      id: '',
      listingVersion: '',
      apigee_id: '',
      developerId: '',
      developerName: '',
      orgId: '',
      status: '',
      submittedOn: '',
      lastUpdatedBy: '',
      lastUpdatedOn: '',
      solutionType: '',
      comments: [],
      registeredOn: ''
    },
    formStaticValuesTemp: {},
    formValueFinal: {},
    developerId: this.props.userData.email,
    developerName: this.props.userData.name,
    saving: false,
    deleteConfirmationOpen: false,
    deleteDraft: false,
    publishingSteps: ['Publishing App', 'Notifying API Providers'],
    publishingActiveStep: 0,
    showPublishingStepper: false,
    isRedLineContractSigned: false,
    orgId: this.props && this.props.entity && this.props.entity.id,
    enablePublishToMarketplaceButton: false,
    openDialogForPaymentCheckout: false,
    errorDuringthePayment: false,
    toastMessage: '',
    errorVariant: 'negative',
    errorVariantDuration: TOAST_VISIBILITY_DURATIONS.EIGHT_SECONDS,
    latestCertificationStatus: '',
    isEditDisabled: false,
    certificationStatusFetched: false,
    savedSolutionPaymentDetails: [],
    bankApps: [],
    skipAppPublisherTerms: false
  };

  constructor(props) {
    super(props);
    this.solutionOps = new SolutionOperations();
  }

  onShowForRecall = () => {
    this.setState({ openDialogForRecall: true });
  };

  logAmplitudeEvent = (event, solution) => {
    const amplitudeProperties = {};
    amplitudeProperties[AMPLITUDE_PROPERTIES.ORG_NAME] = this.props.entity.name;

    if (solution.overview?.name) {
      amplitudeProperties[AMPLITUDE_PROPERTIES.APP_NAME] =
        solution.overview.name;
    }

    if (solution.id) {
      amplitudeProperties[AMPLITUDE_PROPERTIES.APP_ID] = solution.id;
    }

    if (solution.orgId) {
      amplitudeProperties[AMPLITUDE_PROPERTIES.ORG_ID] = solution.orgId;
    }

    if (solution.listingVersion) {
      amplitudeProperties[AMPLITUDE_PROPERTIES.APP_LISTING_VERSION] =
        solution.listingVersion;
    }

    if (solution.status) {
      amplitudeProperties[AMPLITUDE_PROPERTIES.APP_LISTING_STATUS] =
        solution.status;
    }

    sendAmplitudeData(event, amplitudeProperties);
  };
  getRecallSolutionDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionSubmissionForm.recallTitle"
          defaultMessage="Recall app listing from review process"
        />
      }
      message={
        <FormattedMessage
          id="SolutionSubmissionForm.recallMessage"
          defaultMessage="Are you sure you want to recall the app listing? After recall you can make further edits to your app listing"
        />
      }
      open={this.state.openDialogForRecall}
      acceptButtonName={
        <FormattedMessage
          id="SolutionSubmissionForm.recallAccept"
          defaultMessage="Recall"
        />
      }
      cancelButtonName={
        <FormattedMessage
          id="SolutionSubmissionForm.recallReject"
          defaultMessage="Cancel"
        />
      }
      onAccept={() => {
        this.onRecall(this.state.nonPublishedSolution);
      }}
      onCancel={() => {
        this.setState({ openDialogForRecall: false });
      }}
      acceptDataCy={'btn_dialog_recall_ok'}
      cancelDataCy={'btn_dialog_recall_cancel'}
    />
  );

  onShowForWithdraw = () => {
    this.setState({ openDialogForWithdraw: true });
  };

  getWithdrawSolutionDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionSubmissionForm.withdrawTitle"
          defaultMessage="Withdraw listing from marketplace?"
        />
      }
      message={
        <FormattedMessage
          id="SolutionSubmissionForm.withdrawMessage"
          defaultMessage="Are you sure you want to withdraw your app listing from Marketplace?"
        />
      }
      open={this.state.openDialogForWithdraw}
      acceptButtonName={
        <FormattedMessage
          id="SolutionSubmissionForm.withdrawAccept"
          defaultMessage="Withdraw Listing"
        />
      }
      cancelButtonName={
        <FormattedMessage
          id="SolutionSubmissionForm.withdrawReject"
          defaultMessage="Cancel"
        />
      }
      onAccept={() => {
        this.setState({ openDialogForWithdraw: false });
        this.onWithdraw(this.state.lastPublishedSolution);
        this.logAmplitudeEvent(
          AMPLITUDE_EVENTS.WITHDRAWN_IN_MANAGE_LISTING_VERSIONS,
          this.state.lastPublishedSolution
        );
      }}
      onCancel={() => {
        this.setState({ openDialogForWithdraw: false });
      }}
      acceptDataCy={'btn_dialog_withdraw_ok'}
      cancelDataCy={'btn_dialog_withdraw_cancel'}
    />
  );

  static getImmediatelyPreviousSolution = (solutions, nonPublishedSolution) => {
    return solutions[nonPublishedSolution.listingVersion - 2];
  };

  static getNonPublishedSolution = solutions => {
    return solutions.find(
      item =>
        item.status !== SolutionStatus.PUBLISHED &&
        item.status !== SolutionStatus.WITHDRAWN
    );
  };

  static getPublishedAndWithdrawnSolutions = solutions => {
    return solutions
      .filter(
        item =>
          item.status === SolutionStatus.PUBLISHED ||
          item.status === SolutionStatus.WITHDRAWN
      )
      .sort((a, b) => a.listingVersion - b.listingVersion);
  };

  async fetchProviders(apis) {
    const client = getApolloClient();
    let providers = [];
    if (apis.length) {
      let response = await client.query({
        query: getProviders,
        variables: {
          apiIds: apis
        }
      });
      if (response.data && response.data.providers) {
        providers = response.data.providers;
      }
      return providers;
    } else {
      return providers;
    }
  }

  async fetchConsents(solutionId, listingVersion) {
    const response = await axios.get(
      `${
        environmentURLs.consentServiceBaseUrl
      }/solutions/${solutionId}/${listingVersion}/consents`,
      {
        headers: {
          Authorization: this.props.accessToken
            ? `Bearer ${this.props.accessToken}`
            : ''
        }
      }
    );
    return response ? response.data : [];
  }
  getInitialState = solution => {
    if (solution) {
      let solutionData = JSON.parse(JSON.stringify(solution));
      // this.setState({ solutionName: solutionData.overview.name });
      let formValueFinal = FormUtils.mapFormValuesForView(
        solutionData,
        FormConfig
      );

      let staticData = FormUtils.initializeStaticFormFields(
        this.state.formStaticValues,
        formValueFinal,
        this.state.developerId
      );

      formValueFinal.developerAgreement =
        solution && solution.developerAgreement;

      formValueFinal.publisherAgreementDetails =
        solution && solution.publisherAgreementDetails;

      return {
        formValueFinal: formValueFinal,
        formStaticValues: staticData
      };
    }
  };

  onRecall = solution => {
    const { userData } = this.props;
    const { formValueFinal, formStaticValues } = this.getInitialState(solution);
    this.solutionOps.onRecall({
      formValueFinal: formValueFinal,
      formStaticValues: formStaticValues,
      solutionName: solution.overview.name,
      userData: userData,
      solutionWillBeRecalled: () => {
        this.setState({
          snackBar: false,
          saving: true
        });
      },
      solutionWasRecalled: async (
        formValueFinal,
        formStaticValues,
        latestComment
      ) => {
        await (this.props.onOperationComplete &&
          this.props.onOperationComplete());
        this.setState({
          openDialogForRecall: false,
          saving: false,
          published: false,
          formValueFinal: formValueFinal,
          latestComment: latestComment,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage2"
                  defaultMessage="App recalled successfully."
                />
              );
            }
          }
        });
      }
    });
    this.logAmplitudeEvent(
      AMPLITUDE_EVENTS.RECALL_IN_MANAGE_LISTING_VERSIONS,
      solution
    );
  };

  isFortellisSolution = solutionType => {
    return solutionType === FortellisConstants.FORTELLIS;
  };

  onPublishPending = async (solution, paymentDetails = {}) => {
    const { userData } = this.props;
    const { formValueFinal, formStaticValues } = this.getInitialState(solution);

    await this.solutionOps.onPublishPending({
      formValueFinal: formValueFinal,
      formStaticValues: formStaticValues,
      solutionName: solution.overview.name,
      userData: userData,
      entityInfo: {
        name: this.props && this.props.entity && this.props.entity.name,
        id: this.props && this.props.entity && this.props.entity.id
      },
      paymentDetails: paymentDetails,
      solutionWillPublishPending: () => {
        if (this.isFortellisSolution(solution.solutionType)) {
          this.setState({
            showPublishingStepper: true,
            publishingActiveStep: 0,
            snackBar: true
          });
        } else {
          this.setState({
            saving: true,
            snackBar: true
          });
        }
      },
      solutionWasPublishPending: async formValueFinal => {
        await (this.props.onOperationComplete &&
          this.props.onOperationComplete());
        if (solution.solutionType === FortellisConstants.FORTELLIS) {
          this.setState(prevState => ({
            publishingActiveStep: prevState.publishingActiveStep + 1,
            snackBar: true
          }));
          this.setState({
            showPublishingStepper: false,
            snackBar: true
          });
        } else {
          this.setState({
            saving: false
          });
        }
        this.setState({
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage3"
                  defaultMessage="App status changed to publish pending."
                />
              );
            }
          }
        });
      }
    });
  };

  onPublish = async (solution, paymentDetails = {}) => {
    const { userData } = this.props;
    const { formValueFinal, formStaticValues } = this.getInitialState(solution);

    if (
      solution.payment_info &&
      (solution.payment_info.paymentMode === PAYMENTMODE_ONLINE ||
        solution.payment_info.paymentMode === PAYMENTMODE_OFFLINE_AND_ONLINE) &&
      solution.payment_info.paymentOption &&
      solution.payment_info.paymentOption[STRIPE] > 0
    ) {
      this.addPlansInStripe(solution);
    }
    await this.solutionOps.onPublish({
      formValueFinal: formValueFinal,
      formStaticValues: formStaticValues,
      solutionName: solution.overview.name,
      userData: userData,
      entityInfo: {
        name: this.props && this.props.entity && this.props.entity.name,
        id: this.props && this.props.entity && this.props.entity.id,
        address: this.props && this.props.entity && this.props.entity.address
      },
      paymentDetails: paymentDetails,
      prevListing: this.state.immediatePreviousSolution,
      stripeSubscriptionData: this.props.activeStripeSubscriptions,
      solutionWillPublish: () => {
        if (this.isFortellisSolution(solution.solutionType)) {
          this.setState({
            showPublishingStepper: true,
            publishingActiveStep: 0,
            snackBar: false
          });
        } else {
          this.setState({
            saving: true,
            snackBar: false
          });
        }
      },
      solutionWasPublished: async formValueFinal => {
        await (this.props.onOperationComplete &&
          this.props.onOperationComplete());
        if (solution.solutionType === FortellisConstants.FORTELLIS) {
          this.setState(prevState => ({
            publishingActiveStep: prevState.publishingActiveStep + 1
          }));
          //commenting for time being as the requirement is send email on approval and not publishing
          // await this.solutionOps.notifyAPIProvider(
          //   formValueFinal,
          //   this.props.entity,
          //   this.props.userData
          // );
          this.setState({
            showPublishingStepper: false
          });
        } else {
          this.setState({
            saving: false
          });
        }
        if (
          formValueFinal.activateOnly !== false &&
          formValueFinal.stripePriceId !==
            this.state.immediatePreviousSolution?.stripePriceId
        )
          this.props.getStripeSubscriptionData(
            formValueFinal.id,
            formValueFinal.orgId,
            this.props.accessToken
          );
        this.setState({
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage3"
                  defaultMessage="App successfully published to Marketplace."
                />
              );
            }
          }
        });
      }
    });
  };

  onWithdraw = solution => {
    const { userData } = this.props;
    const { formValueFinal, formStaticValues } = this.getInitialState(solution);
    this.solutionOps.onWithdraw({
      formValueFinal: formValueFinal,
      formStaticValues: formStaticValues,
      solutionName: solution.overview.name,
      userData: userData,
      solutionWillWithdraw: () => {
        this.setState({
          snackBar: false,
          saving: true
        });
      },
      solutionWasWithdrawn: async formValueFinal => {
        await (this.props.onOperationComplete &&
          this.props.onOperationComplete());
        if (
          formValueFinal.activateOnly !== false &&
          formValueFinal.stripePriceId
        )
          this.props.getStripeSubscriptionData(
            formValueFinal.id,
            formValueFinal.orgId,
            this.props.accessToken
          );
        this.setState({
          formValueFinal: formValueFinal,
          saving: false,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage6"
                  defaultMessage="App withdrawn from marketplace successfully."
                />
              );
            }
          }
        });
      }
    });
  };

  submitSolution = solution => {
    const { userData } = this.props;
    const { formValueFinal, formStaticValues } = this.getInitialState(solution);

    this.solutionOps.onSubmit({
      formValueFinal: formValueFinal,
      formStaticValues: formStaticValues,
      formStaticValuesTemp: this.state.formStaticValuesTemp,
      userData: userData,
      solution: solution,
      solutionWillSubmit: () => {
        this.setState({
          snackBar: false,
          saving: true
        });
      },
      solutionValuesWillMap: staticData => {
        this.setState({ formStaticValuesTemp: staticData });
      },
      solutionWillSave: (validationResponse, menu) => {
        this.setState({
          saving: true,
          validationResponse: validationResponse
        });
        if (menu) {
          this.setState({ menu: menu });
        }
      },
      solutionDidSave: async (
        formValueFinal,
        formStaticValues,
        commentText
      ) => {
        await (this.props.onOperationComplete &&
          this.props.onOperationComplete());
        this.setState({
          formValueFinal: formValueFinal,
          formStaticValuesTemp: {},
          saving: false,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage4"
                  defaultMessage="App submitted for review successfully."
                />
              );
            }
          }
        });
      }
    });
  };

  onSubmitListingVersion = solution => {
    this.setState({
      solutionSubmissionConfirmation: true,
      solutionToSubmit: solution
    });
  };

  addPlansInStripe = async solutionData => {
    const client = getApolloClient();
    const createProductAndPriceInStripeResp = await client.mutate({
      mutation: createProductAndPriceInStripe,
      variables: {
        solutionId: solutionData.id,
        solutionName: solutionData.apigee_id,
        plans: solutionData.plans
      }
    });
  };
  updatePriceInStripeSubscription = async solutionData => {
    const client = getApolloClient();
    const response = await client.mutate({
      mutation: updatePriceInStripeSubscription,
      variables: {
        orgId: solutionData.orgId,
        solutionId: solutionData.id,
        priceId: solutionData.stripePriceId,
        userName: solutionData.developerName,
        userEmail: solutionData.developerId,
        amount: solutionData.appPublishingFee
      }
    });
    // after price change publishing the latest version.
    if (
      response.data.updatePriceInStripeSubscription.status === '200' ||
      response.data.updatePriceInStripeSubscription.status === 200
    ) {
      this.onPublish(this.solutionToPublish);
    } else {
      this.setState({
        showPublishingStepper: false
      });
    }
  };

  onDeveloperAgreementClose(status) {
    this.setState({ launchDeveloperAgreement: false });

    if (status.status === 'accepted') {
      if (
        this.state.isRedLineContractSigned ||
        this.state.skipAppPublisherTerms
      ) {
        this.solutionToPublish.developerAgreement = '';
        this.solutionToPublish.publisherAgreementDetails = {
          status: '',
          entityId: '',
          entity: '',
          acceptedBy: '',
          acceptedByEmail: ''
        };
      } else if (status.url && !status.publisherAgreementDetails) {
        this.solutionToPublish.developerAgreement = status.url;
        this.solutionToPublish.publisherAgreementDetails = {
          status: '',
          entityId: '',
          entity: '',
          acceptedBy: '',
          acceptedByEmail: ''
        };
      } else if (status.url && status.publisherAgreementDetails) {
        this.solutionToPublish.developerAgreement = status.url;
        this.solutionToPublish.publisherAgreementDetails = {
          status: 'accepted',
          entityId: status.publisherAgreementDetails.entityId,
          entity: status.publisherAgreementDetails.entity,
          acceptedBy: status.publisherAgreementDetails.acceptedBy,
          acceptedByEmail: status.publisherAgreementDetails.acceptedByEmail
        };
      }
      if (
        this.solutionToPublish.stripePriceId !==
          (this.state.immediatePreviousSolution &&
            this.state.immediatePreviousSolution.stripePriceId) &&
        this.props.activeStripeSubscriptions.length
      ) {
        this.setState({
          showPublishingStepper: true
        });
        this.updatePriceInStripeSubscription(this.solutionToPublish);
      } else if (
        this.solutionToPublish.activateOnly !== false &&
        this.solutionToPublish.stripePriceId &&
        !this.props.activeStripeSubscriptions.length
      ) {
        this.setState({ openDialogForPaymentCheckout: true });
      } else {
        this.onPublish(this.solutionToPublish);
      }
    } else if (status.status === DECLINED) {
      console.error('user declined to sign');
    }
  }
  onPaymentWindowClose(statusObj) {
    this.setState({ openDialogForPaymentCheckout: false });
    this.props.resetOtherPaymentOptionFlag();
    const {
      status,
      subscriptionStatus,
      message,
      ...paymentDetails
    } = statusObj;
    if (status === '200') {
      if (subscriptionStatus === 'active') {
        if (this.solutionToPublish)
          this.onPublish(this.solutionToPublish, paymentDetails);
        else if (this.state.nonPublishedSolution)
          this.onPublish(this.state.nonPublishedSolution, paymentDetails);
        else {
          this.setState({
            errorDuringthePayment: true,
            errorVariant: 'negative',
            errorVariantDuration: TOAST_VISIBILITY_DURATIONS.INFINITE,
            toastMessage:
              PaymentInfoConstants.GENERICERRORMESSAGEFORPAYMENTFAILURE
          });
        }
        if (this.solutionToPublish || this.state.nonPublishedSolution) {
          this.setState({
            errorDuringthePayment: true,
            errorVariant: 'positive',
            errorVariantDuration: TOAST_VISIBILITY_DURATIONS.INFINITE,
            toastMessage: PaymentInfoConstants.PAYMENTSUCCESSMESSAGE
          });
        }
      } else if (
        subscriptionStatus === 'incomplete' ||
        subscriptionStatus === null
      ) {
        if (this.solutionToPublish) {
          this.onPublishPending(this.solutionToPublish, paymentDetails);
          this.setState({
            errorDuringthePayment: true,
            errorVariant: 'neutral',
            errorVariantDuration: TOAST_VISIBILITY_DURATIONS.INFINITE,
            toastMessage: PaymentInfoConstants.ACHNEEDTOVERIFYMESSAGE
          });
        }
        this.props.getPaymentSettingData(
          this.props.userData.email,
          this.props.entity.address.countryCode,
          this.props.accessToken
        );
        this.props.getSavedPaymentDetailsBySolutionId(
          this.props.solutions[0]?.id,
          this.props.solutions[0]?.orgId,
          this.props.accessToken
        );
        this.props.getAllBanksAppsForUser(
          this.props.solutions[0]?.orgId,
          this.props.accessToken
        );
      }
    } else if (status === '500') {
      this.setState({ errorDuringthePayment: true, errorVariant: 'negative' });
      this.setState({ toastMessage: message });
    } else if (status === FAILED) {
      this.setState({ errorDuringthePayment: true, errorVariant: 'negative' });
      this.setState({ toastMessage: message });
    } else if (status === DECLINED) {
      console.error('user declined to do the payment');
    }
  }

  getLatestSolutionCertificationStatus = async data => {
    const client = getApolloClient();
    const currentCertificationStatus = await client.query({
      query: gql`
        query(
          $orgId: String!
          $solutionId: ID!
          $type: String!
          $listingVersion: String
        ) {
          apps(
            orgId: $orgId
            id: $solutionId
            type: $type
            listingVersion: $listingVersion
          ) {
            certificationStatus
            solutionStatus
          }
        }
      `,
      variables: {
        orgId: data.orgId,
        solutionId: data.id,
        type: 'f'
      }
    });
    this.setState({ certificationStatusFetched: true });
    return currentCertificationStatus;
  };

  getRedLineContractStatus = async () => {
    try {
      const response = await getRedLineContractStatus(
        this.props.accessToken,
        this.state.orgId
      );

      this.setState({
        enablePublishToMarketplaceButton: true
      });

      this.setState({
        isRedLineContractSigned:
          response &&
          response.data &&
          response.data.payload &&
          response.data.payload
      });
      this.props.updateSelectedPanelCache({
        data: {
          redLineContract:
            response &&
            response.data &&
            response.data.payload &&
            response.data.payload
        }
      });
    } catch (error) {
      console.error({ Error: error });
      this.setState({
        isRedLineContractSigned: false,
        enablePublishToMarketplaceButton: true
      });
    }
  };

  Id() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  modifyAppActivationData = async () => {
    const solutions = this.props.solutions;
    if (solutions.length > 1) {
      const newListingVersion = solutions[solutions.length - 1];
      const prevListingVersion = solutions[solutions.length - 2];
      const solutionId = newListingVersion.id;

      if (
        newListingVersion.app_subscriptions.appSubscriptionsType !==
        prevListingVersion.app_subscriptions.appSubscriptionsType
      ) {
        const client = getApolloClient();
        let response = await client.query({
          query: gql`
            query($solutionId: ID, $randomId: ID) {
              getMetadataSubscriptions(
                solutionId: $solutionId
                randomId: $randomId
              ) {
                id
                appSubscriptionActivationType
                appSubscriptionActivationState
              }
            }
          `,
          variables: {
            solutionId: solutionId,
            randomId: this.Id()
          }
        });

        const subscriptions = response.data.getMetadataSubscriptions;
        let updatedSubscriptions = [];

        subscriptions &&
          subscriptions.map(subscription => {
            let updatedSubscription = {};
            updatedSubscription.id = subscription.id;
            updatedSubscription.appSubscriptionActivationType =
              newListingVersion.app_subscriptions.appSubscriptionsType;
            if (subscription.appSubscriptionActivationState === 'Pending') {
              updatedSubscription.appSubscriptionActivationState = 'Active';
            } else {
              updatedSubscription.appSubscriptionActivationState =
                subscription.appSubscriptionActivationState;
            }
            updatedSubscriptions.push(updatedSubscription);
          });

        await client.mutate({
          mutation: updateAppActivationData,
          variables: {
            updatedSubscriptions: updatedSubscriptions
          }
        });
      }
    }
  };

  componentDidMount() {
    //check if red line contract information exists in selected panel cache
    //if it exists then skip network call
    const enhancedFlags =
      this.props.entity?.enhancedFlags &&
      JSON.parse(this.props.entity.enhancedFlags);
    if (enhancedFlags?.skipAppPublisherTerms) {
      this.setState({
        skipAppPublisherTerms: true
      });
    }
    if (
      this.props.selectedPanelCache &&
      this.props.selectedPanelCache.data &&
      this.props.selectedPanelCache.data.redLineContract
    ) {
      this.setState({
        isRedLineContractSigned: true,
        enablePublishToMarketplaceButton: true
      });
      return;
    }
    this.getRedLineContractStatus();
    if (
      this.state.nonPublishedSolution &&
      this.state.nonPublishedSolution.status === SolutionStatus.PUBLISH_PENDING
    ) {
      if (this.props.solnTobePublished) {
        this.onPublish(
          this.state.nonPublishedSolution,
          this.props.achPaymentDetails
        );
        this.props.resetVerifyAccountFlag();
        this.props.resetACHPaymentDetails();
      }
      if (
        this.props.otherPaymentOption &&
        !this.props.solnTobePublished &&
        !this.state.openDialogForPaymentCheckout
      ) {
        this.setState({ openDialogForPaymentCheckout: true });
      }
    }
    this.props.getSavedPaymentDetailsBySolutionId(
      this.props.solutions[0]?.id,
      this.props.solutions[0]?.orgId,
      this.props.accessToken
    );
    this.props.getAllBanksAppsForUser(
      this.props.solutions[0]?.orgId,
      this.props.accessToken
    );
    this.getLatestSolutionCertificationStatus({
      orgId: this.props.solutions[0].orgId,
      id: this.props.solutions[0].id
    }).then(data => {
      let {
        props: {
          history: { push }
        }
      } = this;
      if (data?.data?.apps[0]?.solutionStatus === SolutionStatus.CANCELLED)
        push('/marketplace-account/mysolutions');
      if (data?.data?.apps[0]?.certificationStatus) {
        this.setState({
          latestCertificationStatus: data.data.apps[0].certificationStatus
        });
        if (
          data.data.apps[0].certificationStatus === 'in-certification' ||
          data.data.apps[0].certificationStatus === 'certified'
        ) {
          this.setState({ isEditDisabled: false });
        } else {
          this.setState({ isEditDisabled: true });
        }
      } else {
        this.setState({ isEditDisabled: false });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.nonPublishedSolution &&
      this.state.nonPublishedSolution.status === SolutionStatus.APPROVED
    ) {
      if (
        this.state.nonPublishedSolution.payment_info &&
        (this.state.nonPublishedSolution.payment_info.paymentMode ===
          PAYMENTMODE_ONLINE ||
          this.state.nonPublishedSolution.payment_info.paymentMode ===
            PAYMENTMODE_OFFLINE_AND_ONLINE)
      ) {
        const { handleOnApprovedAndOnlinePaymentModeSelected } = this.props;
        handleOnApprovedAndOnlinePaymentModeSelected();
      }
    }
    if (
      this.state.nonPublishedSolution &&
      this.state.nonPublishedSolution.status === SolutionStatus.PUBLISH_PENDING
    ) {
      if (
        this.props.solnTobePublished &&
        this.props.solnTobePublished !== prevProps.solnTobePublished
      ) {
        this.onPublish(
          this.state.nonPublishedSolution,
          this.props.achPaymentDetails
        );
        this.props.resetVerifyAccountFlag();
        this.props.resetACHPaymentDetails();
        this.setState({
          errorDuringthePayment: true,
          errorVariant: 'neutral',
          errorVariantDuration: TOAST_VISIBILITY_DURATIONS.INFINITE,
          toastMessage: 'Your payment was successful and app is published!'
        });
      }
    }
    if (
      this.props.otherPaymentOption !== prevProps.otherPaymentOption &&
      this.props.otherPaymentOption &&
      !this.props.solnTobePublished &&
      !this.state.openDialogForPaymentCheckout
    ) {
      this.setState({ openDialogForPaymentCheckout: true });
    }
  }

  onAccept = () => {
    const { onAccept } = this.state.confirm;
    this.setState({ confirm: false });
    onAccept();
  };

  onCancel = () => {
    this.setState({ confirm: false });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { solutions } = nextProps;
    let immediatePreviousSolution;
    const mergedSolutions = [].concat(solutions);
    mergedSolutions.sort((a, b) => {
      if (a.listingVersion < b.listingVersion) {
        return 1;
      } else if (a.listingVersion > b.listingVersion) {
        return -1;
      } else {
        return 0;
      }
    });

    const nonPublishedSolution = ManageListing.getNonPublishedSolution(
      solutions
    );
    if (nonPublishedSolution) {
      immediatePreviousSolution = ManageListing.getImmediatelyPreviousSolution(
        solutions,
        nonPublishedSolution
      );
    }
    const publishedSolutions = ManageListing.getPublishedAndWithdrawnSolutions(
      mergedSolutions
    );
    let lastPublishedSolution;
    if (publishedSolutions && publishedSolutions.length) {
      lastPublishedSolution = publishedSolutions[publishedSolutions.length - 1];
    }
    return {
      nonPublishedSolution: nonPublishedSolution,
      publishedSolutions: publishedSolutions,
      lastPublishedSolution: lastPublishedSolution,
      immediatePreviousSolution: immediatePreviousSolution
    };
  }

  deleteSolutionListingDraftFromDB = async variables => {
    const client = getApolloClient();
    this.setState({
      saving: true
    });
    try {
      await client.mutate({
        mutation: deleteSolutionListingDraftMutation,
        variables: variables
      });
      await (this.props.onOperationComplete &&
        this.props.onOperationComplete());
      this.setState({
        saving: false
      });
    } catch (err) {
      console.error(
        `Problem to delete solution listing from DB, Error: ${err}`
      );
    } finally {
      this.setState({
        saving: false
      });
    }
  };

  onDelete = (immediatePreviousSolution, nonPublishedSolution) => {
    this.deleteSolutionListingDraftFromDB({
      id: nonPublishedSolution.id,
      listingVersion: nonPublishedSolution.listingVersion,
      developerEmail: nonPublishedSolution.developerId,
      orgId: nonPublishedSolution.orgId,
      appName: nonPublishedSolution.apigee_id,
      attributeName: 'solutionStatus',
      attributeValue: immediatePreviousSolution.status
    })
      .then(() => {
        this.setState({ deleteConfirmationOpen: false });
      })
      .catch(err => {
        console.error(
          `error in deleting solution listing from draft state: ${err}`
        );
      })
      .finally(() => {
        this.setState({ deleteConfirmationOpen: false });
        return {};
      });
  };

  isEditable = status => {
    return [
      SolutionStatus.REGISTERED,
      SolutionStatus.DRAFT,
      SolutionStatus.RECALLED,
      SolutionStatus.REJECTED,
      SolutionStatus.REVOKED
    ].includes(status);
  };

  handleChooseAnotherPaymentOption = e => {
    e.preventDefault();
    this.props.otherPaymentOptionRequest();
  };

  render() {
    const {
      nonPublishedSolution,
      publishedSolutions,
      lastPublishedSolution,
      immediatePreviousSolution,
      enablePublishToMarketplaceButton
    } = this.state;
    const publishButtonClassName = enablePublishToMarketplaceButton
      ? 'button-border'
      : null;

    const { entity } = this.props;

    if (this.state.launchDeveloperAgreement) {
      return (
        <DeveloperAgreement
          solution={
            this.props.solutions &&
            Array.isArray(this.props.solutions) &&
            this.props.solutions[this.props.solutions.length - 1]
          }
          onClose={this.onDeveloperAgreementClose.bind(this)}
          modifyAppActivationData={this.modifyAppActivationData}
          updateAppActivationData={true}
          entity={entity}
        />
      );
    }
    if (this.state.openDialogForPaymentCheckout) {
      return (
        <PaymentCheckoutDialog
          solution={
            this.props.solutions &&
            Array.isArray(this.props.solutions) &&
            this.props.solutions[this.props.solutions.length - 1]
          }
          orgAddress={this.props.entity.address}
          orgName={this.props.entity.name}
          userData={this.props.userData}
          onClose={this.onPaymentWindowClose.bind(this)}
        />
      );
    }
    let message = '';
    if (this.props?.entity?.address?.countryCode?.toLowerCase() === 'us') {
      if (
        nonPublishedSolution?.activateOnly !== false &&
        nonPublishedSolution?.stripePriceId
      ) {
        message = `Your App Listing Fee is $${
          nonPublishedSolution?.appPublishingFee
        }.`;
      } else if (
        nonPublishedSolution?.activateOnly !== false &&
        !nonPublishedSolution?.stripePriceId
      ) {
        message = `Your App Listing Fee is $0.00.`;
      }
    } else if (
      this.props?.entity?.address?.countryCode?.toLowerCase() === 'ca'
    ) {
      if (
        nonPublishedSolution?.activateOnly !== false &&
        nonPublishedSolution?.stripePriceId
      ) {
        message = `Your App Listing Fee is $${
          nonPublishedSolution?.appPublishingFee
        } CAD.`;
      } else if (
        nonPublishedSolution?.activateOnly !== false &&
        !nonPublishedSolution?.stripePriceId
      ) {
        message = `Your App Listing Fee is $0.00 CAD.`;
      }
    }
    message =
      message + `Are you sure you want to publish the app to Marketplace?`;

    return (
      <div>
        <div className="api-info-subtitle">
          <FormattedMessage
            id="ManageListing.helpText"
            defaultMessage={`Here you can manage the version of an app you currently have listed in Marketplace. You can preview, edit, recall, withdraw, or publish a new listing for your app in Marketplace.`}
          />
          &nbsp;
          <LearnMore
            url={`${
              environmentURLs.docs
            }docs/tutorials/app-lifecycle/managing-app-activations/#managing-the-listing-version-of-your-app`}
          />
        </div>
        <br />
        <FortellisDialog
          title={
            <FormattedMessage
              id="SolutionSubmissionForm.submitTitle"
              defaultMessage="Submit App listing for review"
            />
          }
          message={
            <FormattedMessage
              id="SolutionSubmissionForm.submitMessage"
              defaultMessage="Are you sure you want to submit your app listing for review?"
            />
          }
          acceptButtonName={
            <FormattedMessage
              id="SolutionSubmissionForm.submitAccept"
              defaultMessage="Submit"
            />
          }
          cancelButtonName={
            <FormattedMessage
              id="SolutionSubmissionForm.submitReject"
              defaultMessage="Cancel"
            />
          }
          open={this.state.solutionSubmissionConfirmation}
          onAccept={() => {
            this.setState({ solutionSubmissionConfirmation: false });
            this.submitSolution(this.state.solutionToSubmit);
          }}
          onCancel={() =>
            this.setState({ solutionSubmissionConfirmation: false })
          }
          acceptDataCy={'btn_dialog_submit_ok'}
          cancelDataCy={'btn_dialog_submit_cancel'}
        />
        {this.state.errorDuringthePayment && !this.state.showPublishingStepper && (
          <Toast
            content={
              this.state.toastMessage
                ? `${this.state.toastMessage}`
                : PaymentInfoConstants.GENERICERRORMESSAGEFORPAYMENTFAILURE
            }
            position={TOAST_POSITIONS.FIXED}
            style={{ marginTop: '70px', zIndex: '5' }}
            variant={this.state.errorVariant}
            visibilityDuration={this.state.errorVariantDuration}
            onVisibilityDurationEnd={() => {
              this.setState({ errorDuringthePayment: false });
            }}
            actions={[
              {
                onClick: () => {
                  this.setState({ errorDuringthePayment: false });
                },
                text: 'Dismiss'
              }
            ]}
          />
        )}
        {this.state.showPublishingStepper && (
          <LoadingStepper
            open={this.state.showPublishingStepper}
            title={`${this.props.intl.formatMessage(
              { id: 'ManageListing.loadingStepper.title' },
              { defaultMessage: 'Publishing App' }
            )}
                    `}
            steps={this.state.publishingSteps}
            activeStep={this.state.publishingActiveStep}
            finished={
              this.state.publishingActiveStep ===
              this.state.publishingSteps.length
            }
            customClassName="subscribe-stepper"
          />
        )}
        {this.state.saving ? (
          <LayoutGrid className="max-width-resp fm-solution-form-content">
            <LayoutGridCell span={12} className="save-loading-spinner">
              <Spinner />
            </LayoutGridCell>
          </LayoutGrid>
        ) : (
          <div>
            <FortellisDialog
              open={!!this.state.confirm}
              onAccept={this.onAccept}
              onCancel={this.onCancel}
              message={
                this.state.confirm ? this.state.confirm.message() : false
              }
              title={this.state.confirm ? this.state.confirm.title() : false}
              cancelButtonName={
                <FormattedMessage
                  id="ManageListing.editNewVersion.cancelButton"
                  defaultMessage="Cancel"
                />
              }
            />

            <PreviewSolution
              {...this.props}
              openDialog={this.state.openPreviewDialog}
              onClose={() => this.setState({ openPreviewDialog: false })}
              userPermissions={this.props.userPermissions}
              isPreview={true}
              listingVersion={this.state.previewListingVersion}
            />

            {nonPublishedSolution && (
              <Card
                key={`${nonPublishedSolution.id}${
                  nonPublishedSolution.listingVersion
                }`}
                className="non-published"
              >
                <div className="manage-listing-header">
                  <div className="solution-management-panel-header manage-listing-header-title">
                    <FormattedMessage
                      id="ManageListing.listingVersion"
                      defaultMessage="Listing Version"
                    />
                    : {nonPublishedSolution.listingVersion}
                    {(nonPublishedSolution.status === SolutionStatus.DRAFT ||
                      nonPublishedSolution.status ===
                        SolutionStatus.REGISTERED) && (
                      <FortellisBadge
                        verticalAlign="middle"
                        title={
                          <FormattedMessage
                            id="ManageListing.solution.status.draft"
                            defaultMessage="Draft"
                          />
                        }
                      />
                    )}
                  </div>

                  {nonPublishedSolution &&
                    nonPublishedSolution.status !== SolutionStatus.REGISTERED &&
                    nonPublishedSolution.status !== SolutionStatus.DRAFT && (
                      <div className="manage-listing-header-options verify-ach">
                        <Status
                          icon="lens"
                          status={
                            nonPublishedSolution
                              ? nonPublishedSolution.status
                              : nonPublishedSolution.status ||
                                SolutionStatus.REGISTERED
                          }
                        />
                        {!this.props.loading &&
                          !this.props.savedPaymentDetailsLoading &&
                          nonPublishedSolution.status ===
                            SolutionStatus.PUBLISH_PENDING &&
                          nonPublishedSolution.listingVersion > 1 &&
                          this.props.paymentSettingData &&
                          this.props.paymentSettingData.filter(
                            x => x.payment_type === 'stripe_bank' && !x.status
                          ).length > 0 && (
                            <div>
                              <VerifyAccount
                                solnId={nonPublishedSolution.id}
                                orgId={nonPublishedSolution.orgId}
                                loggedInEmail={nonPublishedSolution.developerId}
                                token={this.props.accessToken}
                                stripePriceId={
                                  nonPublishedSolution.stripePriceId
                                }
                                paymentSettingData={this.props.paymentSettingData?.filter(
                                  x =>
                                    x.payment_type === 'stripe_bank' &&
                                    this.props.savedPaymentDetails?.last4 ===
                                      x.last4
                                )}
                                savedSolutionPaymentDetails={
                                  this.props.savedPaymentDetails
                                }
                                bankApps={this.props.bankApps}
                              />
                            </div>
                          )}
                        {!this.props.loading &&
                          nonPublishedSolution.status ===
                            SolutionStatus.PUBLISH_PENDING &&
                          nonPublishedSolution.listingVersion > 1 &&
                          this.props.paymentSettingData &&
                          this.props.paymentSettingData.filter(
                            x => x.payment_type === 'stripe_bank' && !x.status
                          ).length === 0 && (
                            <div classname="verify-account">
                              <a
                                className="another-pay-option-anchor verify-account-link-container"
                                onClick={e =>
                                  this.handleChooseAnotherPaymentOption(e)
                                }
                              >
                                Choose payment option to publish
                              </a>
                            </div>
                          )}
                      </div>
                    )}

                  {nonPublishedSolution &&
                    nonPublishedSolution.status === SolutionStatus.DRAFT && (
                      <div
                        className="manage-listing-header-options"
                        style={{ marginTop: '27px' }}
                      >
                        <i
                          className="material-icons delete-review-icon"
                          onClick={() =>
                            this.setState({ deleteConfirmationOpen: true })
                          }
                        >
                          delete
                        </i>
                      </div>
                    )}
                </div>
                <div>
                  <FortellisDialog
                    title={
                      <FormattedMessage
                        id="ManageListing.deleteDraft"
                        defaultMessage="Delete draft?"
                      />
                    }
                    message={
                      <FormattedMessage
                        id="ManageListing.deleteDraftMessage"
                        defaultMessage="Your drafted listing version will be permanently removed from the site."
                      />
                    }
                    open={this.state.deleteConfirmationOpen}
                    acceptButtonName={
                      <FormattedMessage
                        id="ManageListing.deleteDraft.deleteAccept"
                        defaultMessage="DELETE"
                      />
                    }
                    cancelButtonName={
                      <FormattedMessage
                        id="ManageListing.deleteDraft.deleteReject"
                        defaultMessage="CANCEL"
                      />
                    }
                    onAccept={() =>
                      this.onDelete(
                        immediatePreviousSolution,
                        nonPublishedSolution
                      )
                    }
                    onCancel={() => {
                      this.setState({ deleteConfirmationOpen: false });
                    }}
                    acceptDataCy={'btn_dialog_delete_draft_ok'}
                    cancelDataCy={'btn_dialog_delete_draft_cancel'}
                  />
                </div>

                <LayoutGrid style={{ margin: 'unset' }}>
                  <LayoutGridCell span={4}>
                    <div className="solution-metric-label">
                      <FormattedMessage
                        id="ManageListing.card.solutionVersion"
                        defaultMessage="App Version"
                      />
                    </div>
                    <div className="solution-metric-value">
                      {nonPublishedSolution.overview &&
                      nonPublishedSolution.overview.version
                        ? nonPublishedSolution.overview.version
                        : '-'}
                    </div>
                  </LayoutGridCell>
                  <LayoutGridCell span={4}>
                    <div className="solution-metric-label">
                      <FormattedMessage
                        id="ManageListing.card.createdDate"
                        defaultMessage="Created date"
                      />
                    </div>
                    <div className="solution-metric-value">
                      {moment(nonPublishedSolution.registeredOn).format(
                        'DD MMM YYYY'
                      )}
                    </div>
                  </LayoutGridCell>
                  <LayoutGridCell span={4}>
                    <div className="solution-metric-label">
                      <FormattedMessage
                        id="ManageListing.card.createdBy"
                        defaultMessage="Created by"
                      />
                    </div>
                    <div className="solution-metric-value">
                      {nonPublishedSolution.developerName}
                    </div>
                  </LayoutGridCell>
                </LayoutGrid>

                <CardActions
                  style={{ paddingBottom: '16px', paddingLeft: '16px' }}
                >
                  <CardActionButtons>
                    {nonPublishedSolution.status &&
                      this.isEditable(nonPublishedSolution.status) && (
                        <Link
                          to={{
                            pathname: SolutionTypeURLMapperUtil.getSolutionListingDetailsURL(
                              nonPublishedSolution.solutionType,
                              nonPublishedSolution.id,
                              nonPublishedSolution.listingVersion,
                              this.props.activeMenuId
                            ),
                            params: {
                              activeMenuId:
                                nonPublishedSolution.listingVersion >
                                FortellisConstants.LISTING_VERSION.START
                                  ? 'listingVersionUpdates'
                                  : 'overview'
                            }
                          }}
                          onClick={() =>
                            this.logAmplitudeEvent(
                              AMPLITUDE_EVENTS.SUBMIT_FOR_REVIEW_IN_MANAGE_LISTING_VERSIONS,
                              nonPublishedSolution
                            )
                          }
                        >
                          <Button
                            data-cy={'btn_manage_listing_submit'}
                            className="button-border"
                          >
                            <FormattedMessage
                              id="SolutionSubmissionForm.button1"
                              defaultMessage="Submit for Review"
                            />
                          </Button>
                        </Link>
                      )}

                    {nonPublishedSolution.status &&
                      nonPublishedSolution.status ===
                        SolutionStatus.APPROVED && (
                        <Button
                          data-cy={'btn_manage_listing_publish'}
                          className={publishButtonClassName}
                          onClick={() => {
                            if (
                              nonPublishedSolution.payment_info &&
                              (nonPublishedSolution.payment_info.paymentMode ===
                                PAYMENTMODE_ONLINE ||
                                nonPublishedSolution.payment_info
                                  .paymentMode ===
                                  PAYMENTMODE_OFFLINE_AND_ONLINE) &&
                              !this.props.remittanceInfo
                            ) {
                              this.setState({ showPaymentWarning: true });
                            } else if (
                              nonPublishedSolution.listingVersion >
                              FortellisConstants.LISTING_VERSION.START
                            ) {
                              this.setState({ publishNewVersion: true });
                              this.solutionToPublish = nonPublishedSolution;
                            } else {
                              this.setState({ publishConfirmation: true });
                            }
                          }}
                          disabled={!enablePublishToMarketplaceButton}
                        >
                          <FormattedMessage
                            id="SolutionSubmissionForm.button3"
                            defaultMessage="Publish to Marketplace"
                          />
                        </Button>
                      )}

                    {nonPublishedSolution.status && (
                      <Button
                        data-cy={'btn_manage_listing_preview'}
                        onClick={() => {
                          this.setState({
                            openPreviewDialog: true,
                            previewListingVersion:
                              nonPublishedSolution.listingVersion
                          });
                          this.logAmplitudeEvent(
                            AMPLITUDE_EVENTS.PREVIEW_IN_MANAGE_LISTING_VERSIONS,
                            nonPublishedSolution
                          );
                        }}
                      >
                        <FormattedMessage
                          id="SolutionSubmissionForm.button6"
                          defaultMessage="Preview"
                        />
                      </Button>
                    )}

                    {nonPublishedSolution.status &&
                      this.isEditable(nonPublishedSolution.status) && (
                        <Link
                          to={{
                            pathname: SolutionTypeURLMapperUtil.getSolutionListingDetailsURL(
                              nonPublishedSolution.solutionType,
                              nonPublishedSolution.id,
                              nonPublishedSolution.listingVersion,
                              this.props.activeMenuId
                            ),
                            params: {
                              activeMenuId:
                                nonPublishedSolution.listingVersion >
                                FortellisConstants.LISTING_VERSION.START
                                  ? 'listingVersionUpdates'
                                  : 'overview'
                            }
                          }}
                        >
                          <Button>
                            <FormattedMessage
                              id="SolutionSubmissionForm.button7"
                              defaultMessage="Edit"
                            />
                          </Button>
                        </Link>
                      )}

                    <div>
                      <FortellisDialog
                        title={
                          <FormattedMessage
                            id="SolutionSubmissionForm.publishListingVersion"
                            defaultMessage="Publish Listing Version"
                          />
                        }
                        message={
                          <PublishMessage
                            solution={nonPublishedSolution}
                            prevListingVersion={
                              this.state.immediatePreviousSolution
                            }
                          />
                        }
                        open={this.state.publishNewVersion}
                        acceptButtonName={
                          <FormattedMessage
                            id="ManageListing.publishNewVersion.publishButton"
                            defaultMessage="Publish"
                          />
                        }
                        cancelButtonName={
                          <FormattedMessage
                            id="ManageListing.publishNewVersion.cancelButton"
                            defaultMessage="Cancel"
                          />
                        }
                        onAccept={() => {
                          this.setState({
                            publishNewVersion: false
                          });
                          if (
                            this.state.isRedLineContractSigned ||
                            this.state.skipAppPublisherTerms
                          ) {
                            this.onDeveloperAgreementClose({
                              status: 'accepted',
                              url: '',
                              publisherAgreementDetails: ''
                            });
                            return;
                          }
                          if (this.solutionToPublish.developerAgreement) {
                            this.onDeveloperAgreementClose({
                              status: 'accepted',
                              url: this.solutionToPublish.developerAgreement,
                              publisherAgreementDetails: this.solutionToPublish
                                .publisherAgreementDetails
                            });
                            return;
                          }
                          if (
                            this.state.immediatePreviousSolution &&
                            (this.state.immediatePreviousSolution
                              .stripePriceId ===
                              this.solutionToPublish.stripePriceId ||
                              this.solutionToPublish.activateOnly === false) &&
                            this.state.immediatePreviousSolution
                              .developerAgreement
                          ) {
                            this.onDeveloperAgreementClose({
                              status: 'accepted',
                              url: this.state.immediatePreviousSolution
                                .developerAgreement,
                              publisherAgreementDetails: this.state
                                .immediatePreviousSolution
                                .publisherAgreementDetails
                            });
                            this.modifyAppActivationData();
                            return;
                          } else {
                            this.setState({
                              launchDeveloperAgreement: true
                            });
                          }
                        }}
                        onCancel={() => {
                          this.setState({ publishNewVersion: false });
                        }}
                        acceptDataCy={'btn_dialog_publishLV_ok'}
                        cancelDataCy={'btn_dialog_publishLV_cancel'}
                      />
                    </div>
                    {this.state.publishConfirmation && (
                      <FortellisDialog
                        title="Publish to Marketplace"
                        message={message}
                        open={this.state.publishConfirmation}
                        acceptButtonName={
                          <FormattedMessage
                            id="Confirm.accept"
                            defaultMessage="Proceed"
                          />
                        }
                        cancelButtonName={
                          <FormattedMessage
                            id="Confirm.cancel"
                            defaultMessage="Cancel"
                          />
                        }
                        onAccept={() => {
                          this.solutionToPublish = nonPublishedSolution;
                          this.setState({
                            publishConfirmation: false
                          });
                          if (
                            this.state.isRedLineContractSigned ||
                            this.state.skipAppPublisherTerms
                          ) {
                            this.onDeveloperAgreementClose({
                              status: 'accepted',
                              url: ''
                            });
                            return;
                          }
                          if (this.solutionToPublish.developerAgreement) {
                            this.onDeveloperAgreementClose({
                              status: 'accepted',
                              url: this.solutionToPublish.developerAgreement,
                              publisherAgreementDetails: this.solutionToPublish
                                .publisherAgreementDetails
                            });
                            return;
                          }
                          this.setState({
                            launchDeveloperAgreement: true
                          });
                        }}
                        onCancel={() => {
                          this.setState({ publishConfirmation: false });
                        }}
                        acceptDataCy={'btn_dialog_publish_ok'}
                        cancelDataCy={'btn_dialog_publish_cancel'}
                      />
                    )}

                    {this.state.showPaymentWarning && (
                      <FortellisDialog
                        title="Online Payments"
                        message="You have agreed to use Online Payments for this App. Please complete the next steps to setup your Online Payment Account before Publishing to the Marketplace."
                        open={this.state.showPaymentWarning}
                        acceptButtonName={
                          <FormattedMessage
                            id="Confirm.acceptsWarning"
                            defaultMessage="Ok"
                          />
                        }
                        cancelButtonName={
                          <FormattedMessage
                            id="Confirm.cancel"
                            defaultMessage="Cancel"
                          />
                        }
                        onAccept={() => {
                          this.props.changeMenu(navConstants.SETUP_BILLING);
                          this.setState({
                            showPaymentWarning: false
                          });
                        }}
                        onCancel={() => {
                          this.setState({ showPaymentWarning: false });
                        }}
                      />
                    )}

                    {nonPublishedSolution.status &&
                      !this.isEditable(nonPublishedSolution.status) && (
                        <Link
                          onClick={() =>
                            this.logAmplitudeEvent(
                              AMPLITUDE_EVENTS.VIEW_IN_MANAGE_LISTING_VERSIONS,
                              nonPublishedSolution
                            )
                          }
                          to={{
                            pathname: SolutionTypeURLMapperUtil.getSolutionListingDetailsURL(
                              nonPublishedSolution.solutionType,
                              nonPublishedSolution.id,
                              nonPublishedSolution.listingVersion,
                              this.props.activeMenuId
                            ),
                            params: {
                              entity: entity
                            }
                          }}
                        >
                          <Button>
                            <FormattedMessage
                              id="SolutionSubmissionForm.button8"
                              defaultMessage="View"
                            />
                          </Button>
                        </Link>
                      )}

                    {nonPublishedSolution.status &&
                    (nonPublishedSolution.status === SolutionStatus.SUBMITTED ||
                      nonPublishedSolution.status ===
                        SolutionStatus.APPROVED) ? (
                      <span>
                        <Button onClick={this.onShowForRecall}>
                          <FormattedMessage
                            id="SolutionSubmissionForm.button4"
                            defaultMessage="Recall"
                          />
                        </Button>
                        {this.getRecallSolutionDialog()}
                      </span>
                    ) : null}
                  </CardActionButtons>
                </CardActions>
              </Card>
            )}

            {lastPublishedSolution && (
              <Card
                key={`${lastPublishedSolution.id}${
                  lastPublishedSolution.listingVersion
                }`}
                className="last-published"
              >
                <div className="manage-listing-header">
                  <div className="solution-management-panel-header manage-listing-header-title">
                    <FormattedMessage
                      id="ManageListing.listingVersion"
                      defaultMessage="Listing Version"
                    />
                    : {lastPublishedSolution.listingVersion}
                  </div>
                  <div className="manage-listing-header-options">
                    {lastPublishedSolution && lastPublishedSolution.status && (
                      <Status
                        icon="lens"
                        status={
                          lastPublishedSolution
                            ? lastPublishedSolution.status
                            : lastPublishedSolution.status ||
                              SolutionStatus.REGISTERED
                        }
                      />
                    )}
                  </div>
                </div>

                <LayoutGrid style={{ margin: 'unset' }}>
                  <LayoutGridCell span={4}>
                    <div className="solution-metric-label">
                      <FormattedMessage
                        id="ManageListing.card.solutionVersion"
                        defaultMessage="App Version"
                      />
                    </div>
                    <div className="solution-metric-value">
                      {lastPublishedSolution.overview &&
                      lastPublishedSolution.overview.version
                        ? lastPublishedSolution.overview.version
                        : '-'}
                    </div>
                  </LayoutGridCell>
                  <LayoutGridCell span={4}>
                    <div className="solution-metric-label">
                      <FormattedMessage
                        id="ManageListing.card.publishedDate"
                        defaultMessage="Published date"
                      />
                    </div>
                    <div className="solution-metric-value">
                      {lastPublishedSolution.status === SolutionStatus.PUBLISHED
                        ? moment(lastPublishedSolution.lastUpdatedOn).format(
                            'DD MMM YYYY'
                          )
                        : '-'}
                    </div>
                  </LayoutGridCell>
                  <LayoutGridCell span={4}>
                    <div className="solution-metric-label">
                      <FormattedMessage
                        id="ManageListing.card.publishedBy"
                        defaultMessage="Published by"
                      />
                    </div>
                    <div className="solution-metric-value">
                      {lastPublishedSolution.status === SolutionStatus.PUBLISHED
                        ? lastPublishedSolution.lastUpdatedBy
                        : '-'}
                    </div>
                  </LayoutGridCell>
                </LayoutGrid>

                <CardActions
                  style={{ paddingBottom: '16px', paddingLeft: '16px' }}
                >
                  <CardActionButtons>
                    <Link
                      to={{
                        pathname: SolutionTypeURLMapperUtil.getSolutionListingDetailsURL(
                          lastPublishedSolution.solutionType,
                          lastPublishedSolution.id,
                          lastPublishedSolution.listingVersion,
                          this.props.activeMenuId
                        ),
                        params: {
                          entity: entity
                        }
                      }}
                      onClick={() =>
                        this.logAmplitudeEvent(
                          AMPLITUDE_EVENTS.VIEW_IN_MANAGE_LISTING_VERSIONS,
                          lastPublishedSolution
                        )
                      }
                    >
                      <Button>
                        <FormattedMessage
                          id="SolutionSubmissionForm.button8"
                          defaultMessage="View"
                        />
                      </Button>
                    </Link>
                    {!nonPublishedSolution &&
                      this.state.certificationStatusFetched && (
                        <Button
                          onClick={() => {
                            this.setState({
                              editPublishedSolution: true
                            });
                            this.solutionToEdit = lastPublishedSolution;
                            this.logAmplitudeEvent(
                              AMPLITUDE_EVENTS.EDIT_IN_MANAGE_LISTING_VERSIONS,
                              lastPublishedSolution
                            );
                          }}
                        >
                          <FormattedMessage
                            id="SolutionSubmissionForm.button7"
                            defaultMessage="Edit"
                          />
                        </Button>
                      )}
                    <div>
                      <FortellisDialog
                        title={
                          <FormattedMessage
                            id="SolutionSubmissionForm.editListingVersion"
                            defaultMessage="Edit Listing Version"
                          />
                        }
                        message={
                          this.state.isEditDisabled ? (
                            <FormattedMessage
                              id="SolutionSubmissionForm.editDisable"
                              defaultMessage={` Your app is in ${
                                certificationStatusValues[
                                  this.state.latestCertificationStatus
                                ]
                              } state. You cannot create a new listing version until your app is certified again`}
                            />
                          ) : (
                            <FormattedMessage
                              id="SolutionSubmissionForm.editListingVersionConfirmationMessage"
                              defaultMessage="A New listing version will be created based on the published version. You can edit this independently and then submit for review"
                            />
                          )
                        }
                        open={this.state.editPublishedSolution}
                        acceptButtonName={
                          this.state.isEditDisabled ? (
                            <FormattedMessage
                              id="ManageListing.editNewVersion.OkButton"
                              defaultMessage="OK"
                            />
                          ) : (
                            <FormattedMessage
                              id="ManageListing.editNewVersion.continueButton"
                              defaultMessage="Continue"
                            />
                          )
                        }
                        cancelButtonName={
                          !this.state.isEditDisabled ? (
                            <FormattedMessage
                              id="ManageListing.editNewVersion.cancelButton"
                              defaultMessage="Cancel"
                            />
                          ) : null
                        }
                        onAccept={() => {
                          if (this.state.isEditDisabled) {
                            this.setState({ editPublishedSolution: false });
                          } else {
                            this.setState({
                              editPublishedSolution: false,
                              saving: true
                            });
                            this.props
                              .onEdit(this.solutionToEdit)
                              .then(() => {
                                this.setState({
                                  saving: false
                                });
                              })
                              .catch(() => {
                                this.setState({
                                  saving: false
                                });
                              });
                          }
                        }}
                        onCancel={() => {
                          this.setState({ editPublishedSolution: false });
                        }}
                        acceptDataCy={'btn_dialog_editLV_ok'}
                        cancelDataCy={'btn_dialog_editLV_cancel'}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        this.setState({
                          openPreviewDialog: true,
                          previewListingVersion:
                            lastPublishedSolution.listingVersion
                        });
                        this.logAmplitudeEvent(
                          AMPLITUDE_EVENTS.PREVIEW_IN_MANAGE_LISTING_VERSIONS,
                          lastPublishedSolution
                        );
                      }}
                    >
                      <FormattedMessage
                        id="SolutionSubmissionForm.button6"
                        defaultMessage="Preview"
                      />
                    </Button>

                    {lastPublishedSolution &&
                      lastPublishedSolution.status !==
                        SolutionStatus.WITHDRAWN && (
                        <span>
                          <Button
                            onClick={this.onShowForWithdraw}
                            data-cy="btn_manage_listing_withdraw"
                          >
                            <FormattedMessage
                              id="SolutionSubmissionForm.button9"
                              defaultMessage="Withdraw"
                            />
                          </Button>
                          {this.getWithdrawSolutionDialog()}
                        </span>
                      )}
                    {lastPublishedSolution.status ===
                      SolutionStatus.PUBLISHED && (
                      <a
                        href={`/solutions/${modifyStr(
                          this.props.entity.name
                            .replace(
                              /[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g,
                              '-'
                            )
                            .toLowerCase()
                        ).toLowerCase()}/${modifyStr(
                          lastPublishedSolution.overview.name
                            .replace(
                              /[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g,
                              '-'
                            )
                            .toLowerCase()
                        ).toLowerCase()}?id=${lastPublishedSolution.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="view_in_marketplace_link"
                        onClick={() =>
                          this.logAmplitudeEvent(
                            AMPLITUDE_EVENTS.VIEW_IN_MARKETPLACE_MANAGE_LISTING_VERSIONS,
                            lastPublishedSolution
                          )
                        }
                      >
                        <i className="material-icons external-link-icon">
                          open_in_new
                        </i>
                        VIEW IN MARKETPLACE
                      </a>
                    )}
                  </CardActionButtons>
                </CardActions>
              </Card>
            )}

            {publishedSolutions && publishedSolutions.length > 1 && (
              <div className="published-history">
                <FortellisTitle5
                  title={
                    <FormattedMessage
                      id="ManageListing.history.title"
                      defaultMessage="History"
                    />
                  }
                />
                <DataTable
                  sortable
                  className="table-list published-history-list"
                >
                  <DataTableHeader>
                    <DataTableRow className="lead-row">
                      <DataTableHeaderCell
                        style={{
                          width: '15%'
                        }}
                        className="leads-header-column leads-sort-header-column"
                      >
                        <FormattedMessage
                          id="ManageListing.listingVersion"
                          defaultMessage="Listing Version"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="leads-header-column"
                        style={{
                          width: '15%'
                        }}
                      >
                        <FormattedMessage
                          id="ManageListing.card.solutionVersion"
                          defaultMessage="App Version"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="leads-header-column"
                        style={{
                          width: '20%'
                        }}
                      >
                        <FormattedMessage
                          id="ManageListing.card.publishedDate"
                          defaultMessage="Published date"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="leads-header-column"
                        style={{
                          width: '25%'
                        }}
                      >
                        <FormattedMessage
                          id="ManageListing.card.publishedBy"
                          defaultMessage="Published by"
                        />
                      </DataTableHeaderCell>
                    </DataTableRow>
                  </DataTableHeader>
                  <DataTableBody>
                    {publishedSolutions
                      .sort((a, b) => b.listingVersion - a.listingVersion)
                      .map((solution, index) => {
                        return (
                          index !== 0 && (
                            <DataTableRow key={index}>
                              <DataTableCell
                                style={{
                                  textAlign: 'left',
                                  wordWrap: 'break-word'
                                }}
                              >
                                <FortellisTitle5
                                  title={solution.listingVersion}
                                />
                              </DataTableCell>
                              <DataTableCell
                                style={{
                                  textAlign: 'left',
                                  wordWrap: 'break-word'
                                }}
                              >
                                <FortellisSubtitle2
                                  subtitle={solution.overview.version}
                                />
                              </DataTableCell>
                              <DataTableCell
                                style={{
                                  textAlign: 'left',
                                  wordWrap: 'break-word'
                                }}
                              >
                                <FortellisSubtitle2
                                  subtitle={
                                    solution.lastUpdatedOn
                                      ? moment(solution.lastUpdatedOn).format(
                                          'DD MMM YYYY, hh:mm a'
                                        )
                                      : `-`
                                  }
                                />
                              </DataTableCell>
                              <DataTableCell
                                style={{
                                  textAlign: 'left',
                                  wordWrap: 'break-word'
                                }}
                              >
                                <FortellisSubtitle2
                                  subtitle={solution.lastUpdatedBy}
                                />
                              </DataTableCell>

                              <DataTableCell style={{ textAlign: 'left' }}>
                                <Link
                                  to={{
                                    pathname: SolutionTypeURLMapperUtil.getSolutionListingDetailsURL(
                                      solution.solutionType,
                                      solution.id,
                                      solution.listingVersion,
                                      this.props.activeMenuId
                                    ),
                                    params: {
                                      entity: entity
                                    }
                                  }}
                                  onClick={() =>
                                    this.logAmplitudeEvent(
                                      AMPLITUDE_EVENTS.VIEW_IN_MANAGE_LISTING_VERSIONS,
                                      lastPublishedSolution
                                    )
                                  }
                                >
                                  <Button
                                    className="button-right"
                                    compact={'true'}
                                    type="button"
                                  >
                                    <FormattedMessage
                                      id="SolutionSubmissionForm.button8"
                                      defaultMessage="View"
                                    />
                                  </Button>
                                </Link>
                              </DataTableCell>

                              <DataTableCell style={{ textAlign: 'left' }}>
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      openPreviewDialog: true,
                                      previewListingVersion:
                                        solution.listingVersion
                                    });
                                  }}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button6"
                                    defaultMessage="Preview"
                                  />
                                </Button>
                              </DataTableCell>
                            </DataTableRow>
                          )
                        );
                      })}
                  </DataTableBody>
                </DataTable>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const updateAppActivationData = gql`
  mutation($updatedSubscriptions: [updatedSubscriptionscmd]) {
    updateAppActivationData(
      command: { updatedSubscriptions: $updatedSubscriptions }
    )
  }
`;

const deleteSolutionListingDraftMutation = gql`
  mutation(
    $id: ID
    $listingVersion: String
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $attributeName: String!
    $attributeValue: String!
  ) {
    deleteSolutionListingDraft(
      command: {
        id: $id
        listingVersion: $listingVersion
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        attributeName: $attributeName
        attributeValue: $attributeValue
      }
    ) {
      id
      listingVersion
    }
  }
`;

const getProviders = gql`
  query($apiIds: [ID]) {
    providers(provides: $apiIds) {
      id
      name
      entityId
      productName
      api
      fqdn
      adminAPIUrl
      supportEmail
      termsDoc
      addendumDoc
      terms {
        coordinates {
          signatureCoordinates {
            page
            x
            y
          }
        }
        docSize {
          height
          width
        }
      }
    }
  }
`;

const createProductAndPriceInStripe = gql`
  mutation($solutionId: ID!, $solutionName: String!, $plans: [PlanInfoCmd]!) {
    createProdcutAndPlanInStripe(
      command: {
        solutionId: $solutionId
        solutionName: $solutionName
        plans: $plans
      }
    ) {
      message
    }
  }
`;

const updatePriceInStripeSubscription = gql`
  mutation(
    $orgId: String!
    $solutionId: String!
    $priceId: String!
    $userName: String!
    $userEmail: String!
    $amount: String!
  ) {
    updatePriceInStripeSubscription(
      command: {
        orgId: $orgId
        solutionId: $solutionId
        priceId: $priceId
        userName: $userName
        userEmail: $userEmail
        amount: $amount
      }
    ) {
      message
      status
    }
  }
`;

const mapStateToProps = state => {
  const { data } = state.verifyAccount;
  return {
    solnTobePublished: data.verified,
    otherPaymentOption: data.otherPaymentOption,
    achPaymentDetails: data.paymentDetails,
    activeStripeSubscriptions:
      state.stripeSubscriptionData.data.stripeSubscriptionData,
    paymentSettingData: data.paymentSettingData,
    loading: data.loading,
    savedPaymentDetails: state.savedPaymentDetails.savedPaymentDetails,
    savedPaymentDetailsLoading:
      state.savedPaymentDetails.savedPaymentDetailsLoading,
    bankApps: state.bankAppsDetails.bankApps,
    bankAppsLoading: state.bankAppsDetails.bankAppsLoading
  };
};
const mapDispatchToProps = dispatch => ({
  resetVerifyAccountFlag: () => dispatch(verifyAccount(false)),
  resetACHPaymentDetails: () => dispatch(setPaymentDetails({})),
  getPaymentSettingData: (email, countryCode, token) =>
    dispatch(getPaymentSetting({ email: email, countryCode, token: token })),
  resetOtherPaymentOptionFlag: () => dispatch(resetOtherPaymentOption(false)),
  getStripeSubscriptionData: (solnId, orgId, token) => {
    dispatch(getStripeSubscriptionInfo({ id: solnId, orgId, token }));
  },
  otherPaymentOptionRequest: () => {
    dispatch(otherPaymentOptionRequest(true));
  },
  getAllBanksAppsForUser: (orgId, token) => {
    dispatch(getAllBanksAppsForUser({ orgId, token }));
  },
  getSavedPaymentDetailsBySolutionId: (solnId, orgId, token) => {
    dispatch(getSavedPaymentDetailsBySolutionId({ solnId, orgId, token }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageListing);
