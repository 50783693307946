export const config = {
  formId: 'subscription_notifications',
  formTitle: 'Subscription Notifications',
  formValueType: 'Object',
  tabs: [
    {
      id: 'subscription_notifications',
      contents: [
        {
          id: 'email',
          style: 'subnotificationform',
          fields: [
            {
              formtitle: 'Email Notification',
              type: 'FortellisTitle3',
              style: 'subnotificationtitle'
            },
            {
              formtitle:
                'We will send new subscription emails to the address(es) listed below.',
              type: 'FormCaption',
              gridStyle: 'layoutcell',
              style: 'subnotificationcaption'
            },
            {
              formtitle: 'You can add upto 5 email addresses.',
              type: 'FormCaption',
              gridStyle: 'layoutcell',
              style: 'subnotificationcaption'
            },
            {
              name: 'email',
              label: 'Type email address and press enter',
              type: 'input_chipset',
              containerStyle: 'sub-notification-input-chipset',
              disabled: true
            }
          ]
        },
        {
          id: 'api',
          fields: [
            {
              formtitle: 'API Notification',
              type: 'FortellisTitle3',
              style: 'subnotificationtitle'
            },
            {
              formtitle:
                'By checking this item, we will notify the URL below about new subscriptions.',
              type: 'FormCaption',
              gridStyle: 'layoutcell',
              style: 'subnotificationcaption'
            },
            {
              id: 'apiNotification',
              name: 'apiNotification',
              labelDependsOn: 'apiNotification',
              label: function(apiNotification) {
                if (apiNotification) return 'API Notification Enabled';
                else return 'API Notification Disabled';
              },
              type: 'on_off_switch',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              isDisabled: true
            },
            {
              id: 'endpointUrl',
              label: 'Endpoint URL',
              name: 'endpointUrl',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              disabled: true
            }
          ]
        }
      ]
    }
  ],

  formFields: {
    subscription_notifications: {
      email: '',
      endpointUrl: '',
      apiNotification: false
    }
  },
  activeTabId: 'subscription_notifications'
};
