export const config = {
  formId: "registration_details",
  formTitle: "API Details",
  formValueType: "Object",
  tabs: [
    {
      id: "connectedApis",
      label: "APIs",
      contents: [
        {
          id: "connectedApis",
          /*
                      title: "APIs",
            */
          fields: [
            {
              name: "api_details",
              label: "Api Details",
              type: "list",
              style: "formtextfield",
              gridStyle: "layoutcell"
            }
          ]
        }
      ]
    },
    {
      id: "otherSpecifications",
      label: "Other Specification",
      contents: [
        {
          id: "otherSpecifications",
          /*
                      title: "Other Specification",
            */
          fields: [
            {
              name: "callbackUrl",
              label: "Callback URL",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "apiKey",
              label: "API Key",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "apiSecret",
              label: "API Secret",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "keyIssuedOn",
              label: "Key Issued",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "keyExpiresOn",
              label: "Expires",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    registration_details: {
      api_details: [],
      apiKey: "",
      apiSecret: "",
      keyIssuedOn: "",
      keyExpiresOn: "",
      callbackUrl: ""
    }
  },
  activeTabId: "connectedApis"
};
