const MINIMUM_FILTERS_TO_SHOW = 7;
const CATEGORIES_SECTION_TITLE = 'Categories';
const INDUSTRY_SECTION_TITLE = 'Industry';
const DISCOVER_BY_SECTION_TITLE = 'Discover by';
const PUBLISHERS_SECTION_TITLE = 'Publishers';
const INTEGRATIONS_SECTION_TITLE = 'Integrations';
const FREE_TRIAL_FILTER = 'Free Trial';
const NEWEST_FILTER = 'Newest';
const PRIVATE_FILTER = 'Private';
const PLUGIN = 'Plugin';
const FEATURED_FILTER = 'Featured Apps';
const ANON_DISCOVER_BY_FILTERS = [
  {
    name: FREE_TRIAL_FILTER,
    id: 1
  },
  {
    name: NEWEST_FILTER,
    id: 2
  },
  // },
  // {
  //   name: PLUGIN,
  //   id: 3
  // }
  {
    name: FEATURED_FILTER,
    id: 3
  }
];

const LOGGED_IN_DISCOVER_BY_FILTERS = [
  {
    name: FREE_TRIAL_FILTER,
    id: 1
  },
  {
    name: NEWEST_FILTER,
    id: 2
  },
  {
    name: FEATURED_FILTER,
    id: 3
  },
  {
    name: PRIVATE_FILTER,
    id: 4
  }
  // },
  // {
  //   name: PLUGIN,
  //   id: 4
  // }
];

const SEARCH_FILTER_URL_PARAMS = {
  'Discover by': 'Db',
  Categories: 'Cr',
  Publishers: 'Pub',
  Integrations: 'Int',
  Industry: 'Ind'
};

export {
  MINIMUM_FILTERS_TO_SHOW,
  CATEGORIES_SECTION_TITLE,
  DISCOVER_BY_SECTION_TITLE,
  PUBLISHERS_SECTION_TITLE,
  INTEGRATIONS_SECTION_TITLE,
  FREE_TRIAL_FILTER,
  NEWEST_FILTER,
  ANON_DISCOVER_BY_FILTERS,
  LOGGED_IN_DISCOVER_BY_FILTERS,
  PRIVATE_FILTER,
  PLUGIN,
  SEARCH_FILTER_URL_PARAMS,
  FEATURED_FILTER,
  INDUSTRY_SECTION_TITLE
};
