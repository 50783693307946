export class MenuDataConfig {
  static MenuData = {
    activeMenuId: "overview",
    contents: [
      {
        title: "Subscription Details",
        steps: [
          {
            id: "overview",
            label: "Overview",
            type: "simple"
          },
          {
            id: "api_configuration",
            label: "API Configuration",
            type: "simple"
          }
        ]
      }
    ]
  };
}

export default MenuDataConfig;
