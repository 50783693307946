import FortellisConstants from "../../components/common/FortellisConstants";

export class FormUrlValidation {
  static validateUrl(url) {
    if (
      url &&
      (url.toLowerCase().startsWith(FortellisConstants.HTTP_URL) ||
        url.toLowerCase().startsWith(FortellisConstants.HTTPS_URL))
    ) {
      return url;
    } else {
      return FortellisConstants.HTTP_URL + url;
    }
  }
}
