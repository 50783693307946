import React, { useEffect, useState } from 'react';
import { Checkbox } from 'cdk-radial';
import './CheckboxGroup.scss';
import CDKPublisherGroup from './CDKPublisherGroup';

const CheckboxGroup = ({
  checkedFilters,
  onFilterSelect,
  filterArray,
  itemsToShow,
  sectionTitle
}) => {
  const spaceSeparator = ' ';
  const cdkText = 'cdk';
  const cdkPublishers =
    sectionTitle === 'Publishers'
      ? filterArray.filter(
          publisher =>
            publisher.name &&
            publisher.name
              .trim()
              .toLowerCase()
              .split(spaceSeparator)[0] === cdkText
        )
      : [];
  const nonCDKPublishers =
    sectionTitle === 'Publishers'
      ? filterArray.filter(
          publisher =>
            !(
              publisher.name &&
              publisher.name
                .trim()
                .toLowerCase()
                .split(spaceSeparator)[0] === cdkText
            )
        )
      : filterArray;

  return (
    <>
      {sectionTitle === 'Publishers' && (
        <CDKPublisherGroup
          checkedFilters={checkedFilters}
          cdkPublishers={cdkPublishers}
          onFilterSelect={onFilterSelect}
          sectionTitle={sectionTitle}
        />
      )}
      {nonCDKPublishers.slice(0, itemsToShow).map(filter => {
        let isChechboxChecked;
        if (sectionTitle === 'Publishers') {
          isChechboxChecked =
            checkedFilters[sectionTitle] && filter.id
              ? !!checkedFilters[sectionTitle][filter.id]
              : false;
        } else {
          isChechboxChecked =
            checkedFilters[sectionTitle] && filter.name
              ? !!checkedFilters[sectionTitle][filter.name]
              : false;
        }
        return (
          <div key={filter.id} className="checkbox-holder-nl">
            <Checkbox
              name={filter.name}
              dataTestId={filter.name}
              data-qa={
                filter.name
                  ? `${filter.name.replace(/ /g, '_')}-checkbox`
                  : `${filter.friendlyName.replace(/ /g, '_')}-checkbox`
              }
              checked={isChechboxChecked}
              onChange={event => {
                if (event.target.target) {
                  onFilterSelect(event.target.checked, filter, sectionTitle);
                } else {
                  onFilterSelect(event.target.checked, filter, sectionTitle);
                }
              }}
              style={{ '--mdc-ripple-fg-size': '0px' }}
            />
            <label
              className="checkbox-label-nl"
              onClick={event => {
                onFilterSelect(!isChechboxChecked, filter, sectionTitle);
              }}
            >
              {filter.name ? filter.name : filter.friendlyName}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default CheckboxGroup;
