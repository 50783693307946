import React from 'react';
import ReviewDetails from './ReviewDetails';
import { Divider } from '@cdk-uip/react-divider';
import { Subheading2 } from '@cdk-uip/react-typography';
import '../../solution-details/SolutionDetails.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

export class ReviewDetailsWrapper extends React.Component {
  state = {};
  render() {
    const {
      today = [],
      yesterday = [],
      week = [],
      month = [],
      past = [],
      userData = {},
      getUpdatedReview
    } = this.props;
    return (
      <div className="review-details-sec">
        {today.length > 0 && (
          <div className="today-details-sec">
            <Subheading2 className="review__group">
              <FormattedMessage
                id="ReviewDetailsWrapper.reviewGroup1"
                defaultMessage="Today"
              />
            </Subheading2>
            <Divider />
            {today.map(review => (
              <ReviewDetails
                review={review}
                userData={userData}
                getUpdatedReview={getUpdatedReview}
                groupName={'today'}
                key={review.id}
              />
            ))}
          </div>
        )}
        {yesterday.length > 0 && (
          <div className="yestarday-details-sec">
            <Subheading2 className="review__group">
              <FormattedMessage
                id="ReviewDetailsWrapper.reviewGroup2"
                defaultMessage="Yesterday"
              />
            </Subheading2>
            <Divider />
            {yesterday.map(review => (
              <ReviewDetails
                review={review}
                userData={userData}
                getUpdatedReview={getUpdatedReview}
                groupName={'yesterday'}
                key={review.id}
              />
            ))}
          </div>
        )}
        {week.length > 0 && (
          <div className="week-details-sec">
            <Subheading2 className="review__group"> This week </Subheading2>
            <Divider />
            {week.map(review => (
              <ReviewDetails
                review={review}
                userData={userData}
                getUpdatedReview={getUpdatedReview}
                groupName={'week'}
                key={review.id}
              />
            ))}
          </div>
        )}
        {month.length > 0 && (
          <div className="month-details-list">
            <Subheading2 className="review__group">
              <FormattedMessage
                id="ReviewDetailsWrapper.reviewGroup3"
                defaultMessage="This month"
              />
            </Subheading2>
            <Divider />
            {month.map(review => (
              <ReviewDetails
                review={review}
                userData={userData}
                getUpdatedReview={getUpdatedReview}
                groupName={'month'}
                key={review.id}
              />
            ))}
          </div>
        )}
        {past.length > 0 && (
          <div className="past-details-list">
            <Subheading2 className="review__group">
              {' '}
              <FormattedMessage
                id="ReviewDetailsWrapper.reviewGroup3"
                defaultMessage="Older than a month"
              />{' '}
            </Subheading2>
            <Divider />
            {past.map(review => (
              <ReviewDetails
                review={review}
                userData={userData}
                getUpdatedReview={getUpdatedReview}
                groupName={'past'}
                key={review.id}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(ReviewDetailsWrapper);
