import React from 'react';
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter
} from '@cdk-uip/react-dialog';
import { Button } from '@cdk-uip/react-button';
import { RadioGroup, Radio } from '@cdk-uip/react-radio';

class ProviderPricing extends React.Component {
  onSave = (chosenPlan, subscriptionalPlan, transactionalPlan, freePlan) => {
    this.props.onClose();
    if (chosenPlan === 'Transactional') {
      this.props.onSavePlan(transactionalPlan);
    } else if (chosenPlan === 'Subscriptional') {
      this.props.onSavePlan(subscriptionalPlan);
    } else if (chosenPlan === 'Free') {
      this.props.onSavePlan(freePlan);
    } else {
      this.props.onSavePlan(null);
    }
    this.props.handleSelectedPlan(this.props.planProviderId, this.state.value);
    this.setState({ value: null });
  };

  getPrevSelectedValue = () => {
    return this.props.prevSelectedRatePlanValue && this.props.planProviderId
      ? this.props.prevSelectedRatePlanValue[this.props.planProviderId]
      : null;
  };

  constructor(props) {
    super(props);
    this.state = { value: null };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open === false && this.props.open === true) {
      this.setState({ value: this.getPrevSelectedValue() });
    }
  }

  render() {
    const ratePlans = this.props.ratePlans;
    let transactionalPlan = null,
      subscriptionalPlan = null,
      freePlan = null;

    ratePlans &&
      ratePlans.forEach(ratePlan => {
        if (ratePlan.visibility === 'public') {
          if (ratePlan.type === 'Tiered') {
            transactionalPlan = ratePlan;
          } else if (ratePlan.type === 'Subscription') {
            subscriptionalPlan = ratePlan;
          } else if (ratePlan.type === 'Free') {
            freePlan = ratePlan;
          }
        }
      });
    return (
      <div>
        <Dialog open={this.props.open} onCancel={this.props.onClose}>
          <DialogHeader>Choose Your Pricing Plan</DialogHeader>
          <DialogBody>
            {transactionalPlan && (
              <RadioGroup
                name="Transactional Plan"
                value={this.state.value}
                onChange={e => this.setState({ value: e.target.value })}
              >
                <Radio value="Transactional">
                  <div className={'ProviderPricing__radio'}>
                    <div>Transactional Plan</div>
                    {transactionalPlan.rates &&
                      transactionalPlan.rates.length &&
                      transactionalPlan.rates.map(rateItem => {
                        return (
                          <div
                            style={{ fontSize: '12px' }}
                            key={`transactional-plan-rate-${rateItem.id}`}
                          >
                            {rateItem.endUnit ? (
                              rateItem.startUnit > 0 ? (
                                <div>
                                  ${rateItem.rate}/Call (
                                  {rateItem.startUnit + 1} - {rateItem.endUnit}{' '}
                                  Calls/Month)
                                </div>
                              ) : (
                                <div>
                                  ${rateItem.rate}/Call ({rateItem.startUnit} -{' '}
                                  {rateItem.endUnit} Calls/Month)
                                </div>
                              )
                            ) : rateItem.startUnit > 0 ? (
                              <div>
                                ${rateItem.rate}/Call ({rateItem.startUnit + 1}{' '}
                                - Unlimited Calls/Month)
                              </div>
                            ) : (
                              <div>
                                ${rateItem.rate}/Call ({rateItem.startUnit} -{' '}
                                Unlimited Calls/Month)
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </Radio>
              </RadioGroup>
            )}
            {transactionalPlan && <br />}
            {subscriptionalPlan && (
              <RadioGroup
                name="Subscriptional Plan"
                value={this.state.value}
                onChange={e => this.setState({ value: e.target.value })}
              >
                <Radio value="Subscriptional">
                  <div className={'ProviderPricing__radio'}>
                    <div>Subscriptional Plan</div>
                    {subscriptionalPlan.freemiumUnit ? (
                      <div style={{ fontSize: '12px' }}>
                        Free upto {subscriptionalPlan.freemiumUnit} Calls, then
                        Flat Rate Monthly plan of $
                        {subscriptionalPlan.subscriptionPrice} per{' '}
                        {subscriptionalPlan.frequencyDuration}{' '}
                        {subscriptionalPlan.frequencyDurationType}
                      </div>
                    ) : (
                      <div style={{ fontSize: '12px' }}>
                        Flat Rate Monthly plan of $
                        {subscriptionalPlan.subscriptionPrice} per{' '}
                        {subscriptionalPlan.frequencyDuration}{' '}
                        {subscriptionalPlan.frequencyDurationType}
                      </div>
                    )}
                  </div>
                </Radio>
              </RadioGroup>
            )}
            {freePlan && (
              <RadioGroup
                name="Free Plan"
                value={this.state.value}
                onChange={e => this.setState({ value: e.target.value })}
              >
                <Radio value="Free">
                  <div className={'ProviderPricing__radio'}>
                    <div>Free Plan</div>
                    <div style={{ fontSize: '12px' }}>
                      There are unlimited free API calls per month with this
                      plan.
                    </div>
                  </div>
                </Radio>
              </RadioGroup>
            )}
            {!transactionalPlan && !subscriptionalPlan && !freePlan && (
              <div>
                The provider does not have pricing or its pricing is private at
                this time. You cannot consent this provider.
              </div>
            )}
          </DialogBody>
          {(transactionalPlan || subscriptionalPlan || freePlan) && (
            <DialogFooter>
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ value: null });
                  this.props.onClose();
                }}
                data-qa="cancel-btn"
                id="cancel-btn"
                isDefault
              >
                Cancel
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onSave(
                    this.state.value,
                    subscriptionalPlan,
                    transactionalPlan,
                    freePlan
                  );
                }}
                data-qa="choose-pricing-plan"
                id="choose-pricing-plan"
                disabled={!this.state.value}
                isDefault
              >
                Select
              </Button>
            </DialogFooter>
          )}
          {!transactionalPlan && !subscriptionalPlan && !freePlan && (
            <Button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.onSave(null, null, null);
              }}
              data-qa="okay-btn"
              id="okay-btn"
              isDefault
            >
              Okay
            </Button>
          )}
        </Dialog>
      </div>
    );
  }
}

export default ProviderPricing;
