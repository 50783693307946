import React from 'react';

import SelectComponent from '../../../../common/SelectComponent';
import { FortellisConstants } from '../../../../common/FortellisConstants';

export default class ListingFilters extends React.Component {
  state = {
    solutionVersionOptions: [],
    listingVersionOptions: []
  };

  componentDidMount() {
    this.updateFilterOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.optionMapping !== prevProps.optionMapping) {
      this.updateFilterOptions();
    }
  }

  updateFilterOptions(solutionVersion) {
    solutionVersion =
      solutionVersion ||
      this.props.selectedSolutionVersion ||
      FortellisConstants.SHOW_ALL;
    let prepareOptionEntry = (label, value) => {
      return { label: label, value: value };
    };
    let solutionVersionToListingVersionMap = this.props.optionMapping || {};
    solutionVersionToListingVersionMap[
      solutionVersion
    ] = solutionVersionToListingVersionMap[solutionVersion] || [
      FortellisConstants.SHOW_ALL
    ];
    this.setState({
      listingVersionOptions: solutionVersionToListingVersionMap[
        solutionVersion
      ].map(lv => prepareOptionEntry(lv, lv)),
      solutionVersionOptions: Object.keys(
        solutionVersionToListingVersionMap
      ).map(sv => prepareOptionEntry(sv, sv))
    });
  }

  onSolutionVersionChange(value) {
    this.updateFilterOptions(value);
    this.props.onFilterChange &&
      this.props.onFilterChange(value, this.props.selectedListingVersion);
  }

  onlistingVersionChange(value) {
    this.props.onFilterChange &&
      this.props.onFilterChange(this.props.selectedSolutionVersion, value);
  }

  render() {
    const { listingVersionOptions, solutionVersionOptions } = this.state;

    if (
      (!listingVersionOptions ||
        !listingVersionOptions.length ||
        !(listingVersionOptions.length > 1)) &&
      (!solutionVersionOptions ||
        !solutionVersionOptions.length ||
        !(solutionVersionOptions.length > 1))
    ) {
      return null;
    } else {
      return (
        <div className={this.props.className || ''}>
          {solutionVersionOptions &&
            solutionVersionOptions.length &&
            solutionVersionOptions.length > 1 && (
              <SelectComponent
                name="solutionVersion"
                label="App Version"
                toolTipText="Select App Version"
                value={
                  this.props.selectedSolutionVersion ||
                  FortellisConstants.SHOW_ALL
                }
                options={solutionVersionOptions || []}
                className="commonSelectfield"
                onSelectChange={this.onSolutionVersionChange.bind(this)}
              />
            )}

          <div className="whitespace-24px" />
          {listingVersionOptions &&
            listingVersionOptions.length &&
            listingVersionOptions.length > 1 && (
              <SelectComponent
                name="listing_version"
                label="Listing Version"
                toolTipText="Select Listing Version"
                value={
                  this.props.selectedListingVersion ||
                  FortellisConstants.SHOW_ALL
                }
                options={listingVersionOptions || []}
                className="commonSelectfield"
                onSelectChange={this.onlistingVersionChange.bind(this)}
              />
            )}
          <div className="whitespace-24px" />
        </div>
      );
    }
  }
}
