import React from 'react';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from '@cdk-uip/react-dialog';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import moment from 'moment';
import Review from '../../solution-details/Reviews/Review';
import Spinner from '../../common/Spinner';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { getApolloClient } from '../../../containers/GraphQLClient';
import '../../solution-details/SolutionDetails.scss';
import { FormattedMessage, injectIntl } from 'react-intl';

const charLimit = 210;
export class Response extends React.Component {
  state = {
    viewResponse: false,
    parentReview: '',
    responseText: '',
    readMoreClicked: false,
    readText: 'Read more',
    readMoreFlag: false
  };

  onCancel = () => {
    this.setState({ viewResponse: false });
  };

  viewParentHandler = async parentId => {
    const client = getApolloClient();
    this.setState({ viewResponse: true });
    this.setState({
      awaiting: true
    });
    let queryResponse = await client.query({
      query: gql`
        query($reviewId: ID) {
          review(id: $reviewId) {
            id
            solutionId
            authorName
            solutionId
            rating
            reviewText
            reviewTitle
            status
            parentId
            createdDate
          }
        }
      `,
      variables: { reviewId: parentId }
    });
    if (queryResponse && queryResponse.data && queryResponse.data.review) {
      await this.setState({
        parentReview: queryResponse.data.review,
        awaiting: false
      });
    }
  };

  componentDidMount() {
    if (
      this.props.response &&
      this.props.response.reviewText &&
      this.props.response.reviewText.length > charLimit
    ) {
      this.setState({
        responseText:
          this.props.response.reviewText.substring(0, charLimit) + '...  ',
        readMoreFlag: true
      });
    } else {
      this.setState({ responseText: this.props.response.reviewText });
    }
  }

  onReadMore = () => {
    if (this.state.readMoreClicked && this.props.response.reviewText) {
      this.setState({
        readText: 'Read more',
        responseText:
          this.props.response.reviewText.substring(0, charLimit) + '...  '
      });
    } else {
      this.setState({
        readText: 'Read less',
        responseText: this.props.response.reviewText
      });
    }
    this.setState({ readMoreClicked: !this.state.readMoreClicked });
  };

  render() {
    const { response } = this.props;
    return (
      <div className="response-container">
        <span className="review__replied">
          <FormattedMessage
            id="Response.message1"
            defaultMessage="Replied to"
          />
        </span>
        <span
          className="review__view-resp"
          onClick={() => this.viewParentHandler(response.parentId)}
        >
          <FormattedMessage
            id="Response.handlerMessage"
            defaultMessage="review,"
          />{' '}
        </span>
        <span className="review-date">
          {moment(response.createdDate).format('DD MMM YYYY')}
        </span>
        <div className="review-desc">
          {this.state.responseText}
          {this.state.readMoreFlag ? (
            <span onClick={this.onReadMore} className="review__read-more">
              {this.state.readText}
            </span>
          ) : (
            false
          )}
        </div>
        <Dialog open={this.state.viewResponse} onCancel={this.onCancel}>
          <DialogBody fullHeight className="resp_dialog">
            {this.state.awaiting ? (
              <Spinner />
            ) : (
              <LayoutGrid className="padding-less-grid">
                <LayoutGridCell span={12}>
                  {this.state.parentReview ? (
                    <Review review={this.state.parentReview} />
                  ) : (
                    false
                  )}
                </LayoutGridCell>
              </LayoutGrid>
            )}
          </DialogBody>
          <DialogFooter>
            <DialogFooterButton onClick={this.onCancel}>
              <FormattedMessage id="Response.button" defaultMessage="Close" />
            </DialogFooterButton>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default injectIntl(withApollo(Response));
