import React from 'react';
import { Headline4, Subheading1 } from '@cdk-uip/react-typography';

const FormContentTitle = ({ title, titleDependsOn, titleNotes }) => {
  const titleNote =
    !!titleNotes &&
    !!titleNotes.length &&
    titleNotes[titleNotes.length - 1]['commentText'];
  return (
    <div>
      {title && (
        <Headline4 className="form-content-label">
          {typeof title === 'function' ? title(titleDependsOn) : title}
        </Headline4>
      )}
      {titleNote && <Subheading1 className="notes">{titleNote}</Subheading1>}
    </div>
  );
};

export default FormContentTitle;
