export const categories = [
  {
    name: 'Fixed Ops',
    value: 'Fixed Ops'
  },
  {
    name: 'Modern Retail',
    value: 'Modern Retail'
  },
  {
    name: 'CRM',
    value: 'CRM'
  },
  {
    name: 'Business Office',
    value: 'Business Office'
  },
  {
    name: 'Intelligence',
    value: 'Intelligence'
  },
  {
    name: 'Parts',
    value: 'Parts'
  },
  {
    name: 'F&I',
    value: 'F&I'
  },
  {
    name: 'Marketing',
    value: 'Marketing'
  },
  {
    name: 'Heavy Truck',
    value: 'Heavy Truck'
  }
];

export const reviewStates = [
  {
    name: 'Submitted',
    selected: false
  },
  {
    name: 'Approved',
    selected: false
  },
  {
    name: 'Rejected',
    selected: false
  }
];
