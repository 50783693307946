import React from 'react';
import SubscriptionStatusColor from './SubscriptionStatusColor';

const SubscriptionStatus = ({ subscription }) => {
  let temp = subscription.status.split(' ');
  let result = '';
  for (let i of temp) {
    let initial = i.substr(0, 1).toUpperCase();
    initial += i.substr(1);
    initial += ' ';
    result += initial;
  }
  return (
    <div>
      <div className="inline-block">
        <div className="subscription-status-text">{subscription.status}</div>
        {/* to be used when extra info is needed below status
        <SubscriptionStatusInfo subscription={subscription}/>*/}
      </div>

      <div
        className="inline-block"
        style={{
          color: SubscriptionStatusColor[result.trim().replace(/ /g, '_')]
        }}
      >
        <i className="material-icons fm-status-indicator">lens</i>
      </div>
    </div>
  );
};

export default SubscriptionStatus;
