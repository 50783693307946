import React from 'react';
import axios from 'axios/index';
import CustomTabForm from '../CustomTabForm';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

//TODO - this can change when server side changes are implemented to actually call the API on subscription/provisioning
const API_REQUEST_BODY = {
  solutionInfo: {
    id: 'sample-id-for-testing',
    name: 'Sample Solution Name',
    planInfo: {
      name: 'Test Plan',
      description: 'Test Plan Description'
    }
  },
  subscriptionInfo: {
    id: 'sample-id-for-testing',
    subscribedBy: 'john.doe@test.com',
    subscribedOn: '2019-04-09T13:58:10.385Z',
    subscriberEntity: {
      id: 'sample-entity-id',
      name: 'Test Entity Name',
      address: {
        region: 'IL',
        city: 'Hoffman Estates',
        street: '1950 Hassell Road',
        countryCode: 'US',
        postalCode: '60169'
      },
      phoneNumber: '1234567899',
      website: 'www.testwebsite.test'
    }
  },
  notificationType: 'Subscription'
};

class FormAPIRequest extends React.Component {
  constructor(props) {
    super(props);

    this.configObj = {
      tabs: [
        {
          id: 'request',
          label: 'Request',
          contents: [
            {
              id: 'request',
              fields: [
                {
                  id: 'apiRequestBody',
                  name: 'apiRequestBody',
                  label: 'API Request',
                  type: 'json_text_area',
                  style: ['formtextfield', 'form-text-area'],
                  gridStyle: 'layoutCellList',
                  toolTipText: 'The body sent with the API POST request',
                  src: API_REQUEST_BODY
                },
                {
                  name: 'testButton',
                  label: 'Test',
                  type: 'button',
                  gridStyle: 'layoutCellList',
                  onBtnClick: async () => {
                    await this.testAPICall();
                  }
                }
              ]
            }
          ]
        },
        {
          id: 'response',
          label: 'Response',
          contents: [
            {
              id: 'response',
              fields: [
                {
                  id: 'apiResponse',
                  name: 'apiResponse',
                  label: 'API Response',
                  type: 'json_text_area',
                  style: ['formtextfield', 'form-text-area'],
                  gridStyle: 'layoutCellList',
                  toolTipText: 'The response sent by the API call',
                  src: {}
                }
              ]
            }
          ]
        }
      ],
      activeTabId: 'request'
    };

    this.state = {
      config: this.configObj
    };
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    prevState.config.tabs[0].contents[0].fields[1].isDisabled =
      prevProps.formFieldValues.endpointUrl &&
      prevProps.formFieldValues.endpointUrl.trim().length
        ? false
        : true;
    return {
      config: prevState.config
    };
  }

  onTabChange(activeTabId) {
    this.setState({
      config: Object.assign({}, this.state.config, { activeTabId })
    });
  }

  testAPICall = async () => {
    const { endpointUrl } = this.props.formFieldValues;
    if (endpointUrl) {
      const {
        auth: { accessToken }
      } = this.props;
      try {
        const apiResponse = await axios.post(endpointUrl, API_REQUEST_BODY, {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
            'Content-Type': 'application/json'
          }
        });

        if (apiResponse) {
          this.configObj.tabs[1].contents[0].fields[0].src = apiResponse;
          this.configObj.activeTabId = 'response';
          this.setState({ config: this.configObj });
        }
      } catch (err) {
        this.configObj.tabs[1].contents[0].fields[0].src = err;
        this.configObj.activeTabId = 'response';
        this.setState({ config: this.configObj });
      }
    }
  };

  render() {
    return (
      <CustomTabForm
        {...this.props}
        formConfig={this.state.config}
        isSolutionReview={true}
        onTabChange={this.onTabChange.bind(this)}
      />
    );
  }
}

export default withAuth(FormAPIRequest);
