import React from 'react';
import Dialog, {
  DialogFooter,
  DialogContent,
  DialogTitle
} from '@material/react-dialog';
import TextField, { Input } from '@material/react-text-field';
import Button from '@material/react-button';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

const MAXLENGTH = 40;

class ConsentTermsConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    const {
      auth: {
        userData: { name }
      }
    } = props;
    const firstName = (name && name.split(' ')[0].trim()) || '';
    const lastName = (name && name.split(' ')[1].trim()) || '';
    this.state = {
      name:
        firstName !== lastName
          ? firstName && lastName
            ? `${firstName} ${lastName}`
            : ''
          : firstName,
      title: '',
      companyName: '',
      cmfNumber: '',
      consentStatus: '',
      isFormError: false,
      consentFormErrorMessage: ''
    };
  }

  handleInputChange = e => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  onAccept = async () => {
    const { name, title, companyName } = this.state;

    this.setState(
      {
        name: name.trim(),
        title: title.trim(),
        companyName: companyName.trim()
      },
      () => {
        const nameLength = name.trim().length,
          titleLength = title.trim().length,
          companyNameLength = companyName.trim().length;

        let errorMessage = '';
        if (!nameLength || !titleLength || !companyNameLength) {
          errorMessage = 'Missing values for ';
          let emptyFields = [];
          if (!nameLength) {
            errorMessage = errorMessage + 'name, ';
            emptyFields.push(this.nameInput);
            this.setState({ name: '' });
          }
          if (!titleLength) {
            errorMessage = errorMessage + 'title, ';
            emptyFields.push(this.titleInput);
            this.setState({ title: '' });
          }
          if (!companyNameLength) {
            errorMessage = errorMessage + 'company name, ';
            emptyFields.push(this.companyNameInput);
            this.setState({ companyName: '' });
          }
          this.highlightFields(emptyFields);
        }
        if (errorMessage) {
          this.setState({
            isFormError: true,
            consentFormErrorMessage: errorMessage
          });
          return;
        }

        if (name && title && companyName) {
          const data = {
            consentStatus: 'Accepted',
            consentLink: this.props.consentLink,
            userName: this.state.name,
            userTitle: this.state.title,
            userCompany: this.state.companyName
          };
          this.props.onSubmit(data);
        }
      }
    );
  };

  //1. highlight fields that are empty
  //2. set focus on first empty field
  highlightFields(fields) {
    if (Array.isArray(fields) && fields.length > 0) {
      for (const field of fields) {
        this.changeFocus(field);
      }
      this.changeFocus(fields[0]);
    }
  }

  changeFocus(input) {
    if (!input) return;
    const inputElement = input.inputElement;
    if (inputElement) {
      inputElement.focus();
    }
  }

  componentDidMount() {
    //wait for input to be visible on the screen
    //do not move focus to Title if Name is empty
    if (this.state.name != '') {
      this.focusTimer = setTimeout(
        () => this.changeFocus(this.titleInput),
        300
      );
    }
  }

  componentWillUnmount() {
    //clear timeout in case user closes confirmation screen before timeout finishes
    clearTimeout(this.focusTimer);
  }

  render() {
    return (
      <div className="consent-dialog-confirmation">
        <Dialog
          open={true}
          className={'consent_confirmation_dialog'}
          onClose={this.props.onClose}
        >
          <DialogTitle>Accept Terms of Service</DialogTitle>
          <DialogContent>
            <div>Provide the following information to confirm acceptance.</div>
            {this.state.isFormError ? (
              <div style={{ margin: '5px 0px' }}>
                <span style={{ color: 'red' }}>
                  Error: {this.state.consentFormErrorMessage}
                </span>
              </div>
            ) : null}
            <div style={{ marginTop: '15px' }}>
              <div className={'consent-terms-confirmation-dialog__textField'}>
                <TextField label={'Name'}>
                  <Input
                    id={'name'}
                    required
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    ref={nameInput => (this.nameInput = nameInput)}
                    maxLength={MAXLENGTH}
                  />
                </TextField>
                <div className="character-remaining-div">
                  {this.state.name.length ? this.state.name.length : 0} /{' '}
                  {MAXLENGTH}
                </div>
              </div>
              <div className={'consent-terms-confirmation-dialog__textField'}>
                <TextField label={'Title'}>
                  <Input
                    id={'title'}
                    required
                    value={this.state.title}
                    onChange={this.handleInputChange}
                    ref={titleInput => (this.titleInput = titleInput)}
                    maxLength={MAXLENGTH}
                  />
                </TextField>
                <div className="character-remaining-div">
                  {this.state.title.length ? this.state.title.length : 0} /{' '}
                  {MAXLENGTH}
                </div>
              </div>
              <div className={'consent-terms-confirmation-dialog__textField'}>
                <TextField label={'Company Name'}>
                  <Input
                    id={'companyName'}
                    required
                    value={this.state.companyName}
                    onChange={this.handleInputChange}
                    ref={companyNameInput =>
                      (this.companyNameInput = companyNameInput)
                    }
                    maxLength={MAXLENGTH}
                  />
                </TextField>
                <div className="character-remaining-div">
                  {this.state.companyName.length
                    ? this.state.companyName.length
                    : 0}{' '}
                  / {MAXLENGTH}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogFooter>
            <Button onClick={() => this.props.onClose()}>Cancel</Button>
            <Button onClick={() => this.onAccept()}>Accept Terms</Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default withAuth(ConsentTermsConfirmationDialog);
