export const config = {
  formId: 'un_authorized_signon',
  formTitle: 'Enable unauthorized title',
  formValueType: 'Object',
  formNote:
    'By selecting below options, unauthorized title can subscribe the app',
  tabs: [
    {
      id: 'un_authorized_signon',
      label: 'Enable unauthorized title',
      contents: [
        {
          id: 'authorized_signon_contents',
          fields: [
            {
              name: 'un_authorized_signon',
              label: 'Enable unauthorized title',
              disabled: false,
              type: 'authorizedsignon',
              style: 'payment-options-style',
              gridStyle: 'layoutcell'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    un_authorized_signon: {
      isEnabled: '',
      signOnRole: ''
    }
  },
  activeTabId: 'un_authorized_signon'
};
