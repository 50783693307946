/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  SimpleTable,
  SimpleTableBody,
  SimpleTableContainer,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  SimpleTableWrapper,
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  THEMES,
  TOOLTIP_VERTICAL_ALIGNMENTS
} from 'cdk-radial';
import styled from 'styled-components';
import EmptyContent from '../../common/empty-content';
import SolnStatus from '../SolnStatus';
import SolutionStatus from '../../common/SolutionStatus';
import VerifyAccount from './solution-management/panels/verifyAccount/VerifyAccount';
import {
  otherPaymentOptionRequest,
  setPreviousRoute
} from '../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';
import { useSelector, useDispatch } from 'react-redux';
import SolutionStatusColor from '../../common/SolutionStatusColor';
import axios from 'axios';
import { PRODUCT } from '../../common/constants/MonetizationConstants';
import { getAllBanksAppsForUser } from '../../../redux/connectedComponents/verifyAccount/bankApps.slice';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const StickySimpleTable = styled(SimpleTable)`
  position: relative;
  overflow: auto;
`;

const StickySimpleTableHeader = styled(SimpleTableHeader)`
  position: sticky;
  top: 0;
`;

const StickySimpleTableContainer = styled(SimpleTableContainer)`
  max-height: 360px;
  overflow: auto;
`;

const cancelledRowStyle = {
  color: SolutionStatusColor.Cancelled,
  cursor: 'not-allowed'
};

const MySolutionListTable = ({
  data,
  handlerRowClick,
  entity,
  userData,
  tableType,
  token
}) => {
  const verifyAccountSliceData = useSelector(state => state.verifyAccount);
  const { paymentSettingData } = verifyAccountSliceData.data;
  const bankAppsSliceData = useSelector(state => state.bankAppsDetails);
  const { bankApps, bankAppsLoading } = bankAppsSliceData;
  const dispatch = useDispatch();
  const [
    savedSolutionPaymentDetails,
    setSavedSolutionPaymentDetails
  ] = useState([]);
  const [loadedData, setLoadedData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const location = useLocation();

  const handleChooseAnotherPaymentOption = e => {
    e.preventDefault();
    dispatch(setPreviousRoute(location.pathname));
    dispatch(otherPaymentOptionRequest(true));
  };

  const getSavedPaymentDetailsBySolutionId = async (solnId, orgId) => {
    let url = `${
      environmentURLs.paymentServiceUrl
    }/customer/solutionData?solutionId=${solnId}&orgId=${orgId}&productType=${
      PRODUCT.LISTING_FEE
    }`;
    try {
      setDataFetched(true);
      const response = await axios({
        method: 'GET',
        url: url,
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response?.data?.data.length > 0) {
        setSavedSolutionPaymentDetails(prevData => [
          ...prevData,
          response?.data?.data[0]
        ]);
        setDataFetched(false);
      }
    } catch (error) {
      setDataFetched(false);
      console.error(error);
      throw error;
    }
  };
  const customStatusRenderer = props => {
    const { status, id, stripePriceId } = props;
    const savedSolutionPaymentDetail = savedSolutionPaymentDetails.find(
      obj => obj?.solution_id === id
    );
    return (
      <div>
        <SolnStatus status={status} />
        {status === SolutionStatus.PUBLISH_PENDING &&
          !dataFetched &&
          paymentSettingData &&
          paymentSettingData.filter(
            x => x.payment_type === 'stripe_bank' && !x.status
          ).length > 0 && (
            <div className="my-soln-list-table-link">
              <VerifyAccount
                solnId={id}
                orgId={entity.id}
                loggedInEmail={userData.email}
                token={token}
                stripePriceId={stripePriceId}
                savedSolutionPaymentDetails={savedSolutionPaymentDetails.find(
                  obj => obj?.solution_id === id
                )}
                paymentSettingData={paymentSettingData.filter(
                  x =>
                    x.payment_type === 'stripe_bank' &&
                    savedSolutionPaymentDetail?.last4 === x.last4
                )}
                bankApps={bankApps}
              />
            </div>
          )}
        {status === SolutionStatus.PUBLISH_PENDING &&
          paymentSettingData &&
          paymentSettingData.filter(
            x => x.payment_type === 'stripe_bank' && !x.status
          ).length === 0 && (
            <div>
              <a
                className="another-pay-option-anchor"
                onClick={e => handleChooseAnotherPaymentOption(e)}
              >
                <span className="my-soln-list-table-link">Action Needed</span>
              </a>
            </div>
          )}
      </div>
    );
  };
  const customAppImageAndNameRenderer = props => {
    const { overview = {}, dnpAppName = '', status } = props;
    return (
      <div className="my-soln-list-app-logo-name-container">
        <div className="my-soln-list-app-logo-container">
          {overview.solutionLogo ? (
            <img
              src={overview.solutionLogo}
              alt="App Logo"
              className={`my-soln-list-app-logo ${
                status === SolutionStatus.CANCELLED ? 'greyed-logo' : ''
              }`}
            />
          ) : (
            <div className="my-soln-list-app-logo default-app-logo">
              {overview.name && overview.name[0].toUpperCase()}
            </div>
          )}
        </div>
        <div className="my-soln-list-app-name-container">
          <div className="my-soln-list-app-name-mp">{overview.name}</div>
          <div className="my-soln-list-app-name-dnp">
            On Developer Account: {dnpAppName ? dnpAppName : overview.name}
          </div>
        </div>
      </div>
    );
  };

  const rowClickHandler = (e, row) => {
    const { id, status } = row;
    if (status === SolutionStatus.CANCELLED) return;
    else {
      handlerRowClick(e, id);
    }
  };
  useEffect(() => {
    dispatch(getAllBanksAppsForUser({ orgId: entity.id, token }));
    const fetchDataForId = async id => {
      try {
        await getSavedPaymentDetailsBySolutionId(id, entity.id);
        setLoadedData(prevData => [...prevData, id]);
      } catch (error) {
        console.error(error);
      }
    };
    data.forEach(row => {
      if (!loadedData.includes(row.id)) {
        fetchDataForId(row.id);
      }
    });
  }, []);

  if (data.length === 0) {
    return <EmptyContent message={`No apps yet, Check back soon!`} />;
  }
  return (
    <SimpleTableWrapper
      data-testid="myApp-table-wrapper"
      id="mySolnListTableWrapper"
    >
      <StickySimpleTableContainer>
        <StickySimpleTable
          aria-label="Table"
          id="myAppsSolnTable"
          data-testid="myApp-table"
        >
          <SimpleTableHead className="my-soln-list-table-head">
            <SimpleTableRow className="my-soln-list-table-header-text">
              <StickySimpleTableHeader
                colSpan="1"
                width="25%"
                data-testid="myApp-table-header-col-app"
              >
                App
              </StickySimpleTableHeader>
              <StickySimpleTableHeader
                textAlign="left"
                colSpan="1"
                data-testid="myApp-table-header-col-description"
              >
                Description
              </StickySimpleTableHeader>
              <StickySimpleTableHeader
                textAlign="left"
                colSpan="1"
                width="15%"
                data-testid="myApp-table-header-col-activation"
              >
                App Activation
              </StickySimpleTableHeader>
              <StickySimpleTableHeader
                textAlign="center"
                colSpan="1"
                width="15%"
                data-testid="myApp-table-header-col-status"
              >
                Status
              </StickySimpleTableHeader>
              {tableType === 'inMarketplace' && (
                <StickySimpleTableHeader
                  textAlign="center"
                  colSpan="1"
                  width="15%"
                  data-testid="myApp-table-header-col-error"
                >
                  Errors
                </StickySimpleTableHeader>
              )}
            </SimpleTableRow>
          </SimpleTableHead>
          <SimpleTableBody>
            {data.map((row, index) => (
              <SimpleTableRow
                key={index}
                className="my-soln-list-table-row"
                data-testid={`myApp-table-data-row-${index}`}
                onClick={e => rowClickHandler(e, row)}
                style={
                  row.status === SolutionStatus.CANCELLED
                    ? cancelledRowStyle
                    : {}
                }
              >
                <SimpleTableData textAlign="left">
                  {customAppImageAndNameRenderer(row)}
                </SimpleTableData>
                <SimpleTableData
                  textAlign="left"
                  className="my-soln-list-table-text app-description"
                >
                  {row.overview.briefDescription}
                </SimpleTableData>
                <SimpleTableData
                  textAlign="left"
                  className="my-soln-list-table-text app-subscription-type"
                >
                  {row.app_subscriptions
                    ? row.app_subscriptions.appSubscriptionsType
                    : 'Automatic'}
                </SimpleTableData>
                <SimpleTableData textAlign="right">
                  {customStatusRenderer(row)}
                </SimpleTableData>
                {tableType === 'inMarketplace' && (
                  <SimpleTableData
                    textAlign="right"
                    className={
                      row.status === SolutionStatus.CANCELLED
                        ? ''
                        : 'my-apps-error-text'
                    }
                  >
                    {row.status === SolutionStatus.REJECTED
                      ? errorWithTooltip(row)
                      : '-'}
                  </SimpleTableData>
                )}
              </SimpleTableRow>
            ))}
          </SimpleTableBody>
        </StickySimpleTable>
      </StickySimpleTableContainer>
    </SimpleTableWrapper>
  );
};

const errorWithTooltip = props => {
  const { comments } = props;
  const tooltipText =
    comments && comments.length > 0
      ? comments[comments.length - 1].commentText
      : 'No comments';
  const tooltipProps = {
    id: 'error-tooltip',
    text: tooltipText,
    theme: THEMES.LIGHT
  };

  const ComponentToRender = props => {
    return (
      <div id="my-apps-error-tooltip" {...props}>
        {comments && comments.length > 0 ? 'View' : '-'}
      </div>
    );
  };
  const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);
  return (
    <ComponentWithTooltip
      horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.MIDDLE}
      marginAroundElement={8}
      verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.BOTTOM}
    />
  );
};

export default MySolutionListTable;
