import React from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import SubscriptionPlansList from './subscription-plans-list';
import { FormattedMessage, injectIntl } from 'react-intl';
import SubscriptionPageHeader from './common/subscription-page-header';
import EntityDetails from '../common/EntityDetails';

class PlanSubscription extends React.Component {
  render() {
    const { solution, selectedPlanId, entity } = this.props;
    return (
      <div>
        <div className="plans-pricing-entity-details-parent">
          <EntityDetails entityId={entity.id} />
        </div>
        <LayoutGrid className="fm-page-component-grid">
          <SubscriptionPageHeader
            title={
              <FormattedMessage
                id="MarketplaceAccount.subscriptions.planAndPricing"
                defaultMessage="Plan and Pricing"
              />
            }
          />
          <LayoutGridCell span={12}>
            <SubscriptionPlansList
              solution={solution}
              selectedPlanId={selectedPlanId}
              entity={entity}
            />
          </LayoutGridCell>
        </LayoutGrid>
      </div>
    );
  }
}

export default injectIntl(withAuth(PlanSubscription));
