import React from 'react';
import CogsLogo from './images/cogs.png';

const NullState = ({ text, customClass, width, height }) => {
  return (
    <div className={customClass ? `null-state ${customClass}` : 'null-state'}>
      <div className="null-state__icon">
        <img src={CogsLogo} alt="" width={width} height={height} />
      </div>
      <span className="null-state__text">{text}</span>
    </div>
  );
};

export default NullState;
