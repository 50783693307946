export const FortellisConstants = {
  FORTELLIS: 'fortellis',
  FORTELLIS_TYPE_URL: 'f',
  PARTNER_PROGRAM: 'partnerProgram',
  PARTNER_PROGRAM_TYPE_URL: 'p',
  LISTING_VERSION: {
    START: '1'
  },
  SHOW_ALL: 'All',
  DEFAULT_SOLUTION_NAME: 'New App',
  REACT_APP_SERVICE_URI: process.env.REACT_APP_SERVICE_URI || '',
  IFRAME_URL_VALIDATION_ENDPOINT: 'common/validateiframeurl',
  HTTP_URL: 'http://',
  HTTPS_URL: 'https://',
  DEFAULT_ORG_ID: '-1',
  PERSONAL_ACCOUNT: 'personal-account',
  CONSENT_TYPE_FOR_SOLUTION: 'solution_tnc',
  CONSENT_TYPE_FOR_API: 'api_tos',
  CONSENT_NOT_FOUND_MSG_PART1: 'Currently, There is no provider selected by ',
  CONSENT_NOT_FOUND_MSG_PART2: '. Please contact publisher.',
  NotAvailable: 'N/A',
  STRIPE_BANK_TYPE: 'stripe_bank',
  STRIPE_BANK_AUTH: 'stripe_auth',
  BANK_ACCOUNT: 'bank_account',
  BANK_CARD: 'card',
  MARKETPLACE_MYSOLUTIONS_LIST_ROUTE: '/marketplace-account/mysolutions/list'
};

export default FortellisConstants;
