import React from "react";
import { injectIntl } from "react-intl";
import EmptyContentImage from "./images/Empty State 2x.png";

class EmptyContent extends React.Component {
  render() {
    const { message } = this.props;
    return (
      <div className="empty-content-div">
        <img
          alt="Empty Content"
          className="empty-content-card-img"
          src={EmptyContentImage}
        />
        <div className="empty-content-card-message">{message}</div>
      </div>
    );
  }
}

export default injectIntl(EmptyContent);
