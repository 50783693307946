import React from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import FortellisTitle1 from '../common/FortellisTitle1';
import ImgSellIcon from '../common/images/HeroImageGroup 2@2x.png';
import EmptyContent from '../common/images/Empty State 2x.png';
import PublishedAppsIcon from '../common/images/published-apps-sell-page.png';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import './SellSolution.scss';
import SellSolution from '../../containers/SellSolutionContainer';
import LoadingSpinner from '../marketplace-account/loading-spinner';
import FeaturedListItemForSellPage from '../sell-in-mp/FeaturedListItemForSellPage';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import Button from '../common/Button';
const FEATURED_SOLUTIONS_LIMIT = 3;

class SellPage extends React.Component {
  state = {
    featuredSolutionsList: [],
    loading: true,
    solutionListUpdated: false
  };

  componentWillUpdate(nextProps) {
    if (!this.state.solutionListUpdated && !nextProps.loading) {
      this.setState({ featuredSolutionsList: nextProps.featuredSolutionsList });
      this.setState({ loading: nextProps.loading });
      this.setState({ solutionListUpdated: true });
    }
  }

  render() {
    const {
      auth: { userData },
      loading,
      entity
    } = this.props;

    const { featuredSolutionsList = [] } = this.state;

    const featuredSolutions = featuredSolutionsList.slice(
      0,
      FEATURED_SOLUTIONS_LIMIT
    );

    return (
      <div className="component-content">
        {/* Banner */}

        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="sell-solution-field-items">
            <div className="sell-banner">
              <div className="field-collection-view clearfix view-mode-full">
                <LayoutGrid className="fm-page-component-grid cards">
                  <LayoutGridCell span={12}>
                    <div className="banner-panel">
                      <div className="banner-title-panel">
                        <div className="banner-title">
                          <FortellisTitle1
                            title={
                              <FormattedMessage
                                id="SellSolution.title"
                                defaultMessage="Sell your app in the Fortellis Marketplace"
                              />
                            }
                          />
                        </div>
                      </div>

                      <div className="banner-img-panel">
                        <img
                          alt="Sell App"
                          src={ImgSellIcon}
                          height="500px"
                          className="banner-img"
                        />
                      </div>
                    </div>
                  </LayoutGridCell>
                </LayoutGrid>
              </div>
            </div>
            {/* Solution Type Picker Card */}

            <SellSolution userData={userData} entity={entity} />

            <div className="max-width-resp page-padding-common">
              <div className="sell-solution-section-header">
                <FormattedMessage
                  id="SellSolution.helpSection.title"
                  defaultMessage="How to Sell Your App"
                />
              </div>

              <div className="process-grid">
                <LayoutGrid inner>
                  <LayoutGridCell span={4}>
                    <div className="inline-block summary-sell-page">
                      <div className="icon-background-circle">
                        <span className="material-icons icon-sell-page">
                          edit
                        </span>
                      </div>
                      <div className="summary-title-sell-page">
                        Register for Fortellis Marketplace
                      </div>
                      <div className="summary-desc-sell-page">
                        <a
                          href={environmentURLs.registerYourOrgDocsURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="highlighted-text"
                            data-qa="register-your-org"
                          >
                            Register your organization
                          </span>
                        </a>
                        . Become a Marketplace seller.{' '}
                        <a
                          href={environmentURLs.accountsURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="highlighted-text" data-qa="sign-up">
                            Sign up
                          </span>
                        </a>{' '}
                        today!
                      </div>
                    </div>
                  </LayoutGridCell>
                  <LayoutGridCell span={4}>
                    <div className="inline-block summary-sell-page">
                      <div className="icon-background-circle">
                        <span className="material-icons icon-sell-page">
                          check_circle
                        </span>
                      </div>
                      <div className="summary-title-sell-page">
                        Build Your Product
                      </div>
                      <div className="summary-desc-sell-page">
                        Leverage existing{' '}
                        <a
                          href={environmentURLs.api_reference_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="highlighted-text"
                            data-qa="implement-your-apis"
                          >
                            APIs, implement your own
                          </span>
                        </a>{' '}
                        and{' '}
                        <a
                          href={environmentURLs.gettingStartedDocsURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="highlighted-text"
                            data-qa="build-apps"
                          >
                            build innovative apps
                          </span>
                          .
                        </a>
                      </div>
                    </div>
                  </LayoutGridCell>
                  <LayoutGridCell span={4}>
                    <div className="inline-block summary-sell-page">
                      <div className="icon-background-circle">
                        <span className="material-icons icon-sell-page">
                          local_offer
                        </span>
                      </div>
                      <div className="summary-title-sell-page">
                        Start Selling
                      </div>
                      <div className="summary-desc-sell-page">
                        {/*Maximize your listing's impact and{' '}*/}
                        {/*<a*/}
                        {/*href={*/}
                        {/*'https://docs.fortellis.io/docs/tutorials/solution-overview/getting-started/'*/}
                        {/*}*/}
                        {/*target="_blank"*/}
                        {/*rel="noopener noreferrer"*/}
                        {/*>*/}
                        {/*<span*/}
                        {/*className="highlighted-text"*/}
                        {/*data-qa="go-to-market"*/}
                        {/*>*/}
                        {/*Go-To-Market*/}
                        {/*</span>*/}
                        {/*</a>{' '}*/}
                        {/*benefits to sell on the{' '}*/}
                        Maximize your listing's impact and Go-To-Market benefits
                        to sell on the{' '}
                        <a
                          href={environmentURLs.marketplaceURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="highlighted-text"
                            data-qa="marketplace"
                          >
                            Marketplace
                          </span>
                          .
                        </a>
                      </div>
                    </div>
                  </LayoutGridCell>
                </LayoutGrid>
              </div>
            </div>

            <div className="field-collection-view clearfix view-mode-full">
              <LayoutGrid className="fm-page-component-grid cards">
                <LayoutGridCell span={12}>
                  <div className="banner-panel">
                    <div className="published-apps-panel">
                      <div className="published-apps-title">Published Apps</div>
                      <div className="published-apps-subtitle">
                        Check out some of our popular published applications.
                      </div>
                      <div className="featured-sol-grid">
                        <LayoutGrid inner>
                          {featuredSolutions && featuredSolutions.length > 0 ? (
                            featuredSolutions.map(solution => (
                              <LayoutGridCell key={solution.id} span={4}>
                                <div className="inline-block">
                                  <FeaturedListItemForSellPage
                                    solution={solution}
                                  />
                                </div>
                              </LayoutGridCell>
                            ))
                          ) : (
                            <LayoutGridCell span={12}>
                              <img
                                className="no-published-apps"
                                alt="No apps available yet."
                                src={EmptyContent}
                              />
                              <div className="empty-content-card-message-myown">
                                <FormattedMessage
                                  id="SolutionList.noItems"
                                  defaultMessage="No items to display"
                                />
                              </div>
                            </LayoutGridCell>
                          )}
                        </LayoutGrid>
                      </div>
                    </div>

                    <div className="banner-img-panel">
                      <img
                        alt="Published Apps"
                        src={PublishedAppsIcon}
                        className="published-apps-banner-img"
                      />
                    </div>
                  </div>
                </LayoutGridCell>
              </LayoutGrid>
            </div>

            {/*<div className="go-to-market-section">*/}
            {/*<div className="prospective-partner-txt">*/}
            {/*Prospective Partner Go To Market Guide*/}
            {/*</div>*/}
            {/*<div className="review-txt">*/}
            {/*Review Marketplace partner resources and guidance to build, list*/}
            {/*and promote your products.*/}
            {/*</div>*/}
            {/*<div className="btn-div">*/}
            {/*<Button*/}
            {/*className="view-go-to-market-guide"*/}
            {/*data-qa="view-go-to-market-guide-btn"*/}
            {/*>*/}
            {/*<div className="btn-txt">VIEW-GO-TO MARKET GUIDE</div>*/}
            {/*</Button>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(withAuth(SellPage));
