import { ProvisioningConstants } from '../common/ProvisioningConstants';
export const config = {
  formId: 'provisioning_interface',
  formTitle: 'Provisioning Options',
  formValueType: 'Object',
  formNote:
    'By selecting below option, you will do the provisioning outside the Fortellis.',
  tabs: [
    {
      id: 'provisioning_interface',
      label: 'Provisioning Options',
      contents: [
        {
          id: 'manual',
          fields: [
            {
              formtitle: 'Select Provisioning Option',
              type: 'FortellisTitle3',
              style: 'provisioningtitle'
            },
            {
              name: 'provisioningtype',
              label: 'Manual / Default Provisioning',
              type: 'radio',
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = { redirectURL: '', endpointUrl: '' };
                if (value) {
                  resetValue['provisioningtype'] =
                    ProvisioningConstants.type.MANUAL;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return formValue[name] === ProvisioningConstants.type.MANUAL;
              }
            },
            {
              formtitle:
                'By selecting the above option, you will do the provisioning outside Fortellis manually.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        },
        {
          id: 'apiendpoint',
          fields: [
            {
              name: 'provisioningtype',
              label: 'Provisioning via API Endpoint',
              type: 'radio',
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = { redirectURL: '' };
                if (value) {
                  resetValue['provisioningtype'] =
                    ProvisioningConstants.type.ENDPOINT_URL;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return (
                  formValue[name] === ProvisioningConstants.type.ENDPOINT_URL
                );
              }
            },
            {
              formtitle:
                'By selecting the above option, Fortellis will notify the endpoint when buyer starts provisioning.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption max-width-unset'
            },
            {
              id: 'endpointUrl',
              label: 'Endpoint URL',
              name: 'endpointUrl',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell layoutcellRadio',
              dependentOn: 'provisioningtype',
              show: function(provisioningtype) {
                return (
                  provisioningtype === ProvisioningConstants.type.ENDPOINT_URL
                );
              },
              validationType: 'end_point_url',
              validationErrorMessage: 'Please enter a valid URL.'
            },
            {
              type: 'api_request',
              gridStyle: 'layoutCellList layoutcellRadio',
              dependentOn: 'provisioningtype',
              show: function(provisioningtype) {
                return (
                  provisioningtype === ProvisioningConstants.type.ENDPOINT_URL
                );
              }
            }
          ]
        },
        {
          id: 'redirect_radio',
          fields: [
            {
              name: 'provisioningtype',
              label: 'Provisioning via Website URL',
              type: 'radio',
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = { endpointUrl: '' };
                if (value) {
                  resetValue['provisioningtype'] =
                    ProvisioningConstants.type.REDIRECT_URL;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return (
                  formValue[name] === ProvisioningConstants.type.REDIRECT_URL
                );
              },
              validationType: 'provisioning_via_website_url',
              validationErrorMessage: 'Please enter a valid URL.'
            },
            {
              name: 'redirectURL',
              label:
                'To capture additional information from the buyer you may create a website. Fortellis will redirect the buyer to specified URL when buyer starts provisioning.',
              type: 'text_field_payload',
              style: 'formtextfield relativeformtextfield',
              labelStyle: 'form-field-label-floatup',
              gridStyle: 'layoutcell layoutcellRadio',
              validationType: 'redirect_radio_url',
              validationErrorMessage:
                'Please enter a valid URL (e.g. https://marketplace.fortellis.io)',
              dependentOn: 'provisioningtype',
              disabledOn: 'provisioningtype',
              disabled: function(provisioningtype) {
                return (
                  provisioningtype !== ProvisioningConstants.type.REDIRECT_URL
                );
              },
              resetDependent: function(value) {}
            }
          ]
        },
        {
          id: 'ID',
          fields: [
            {
              name: 'subscriptionID',
              label: 'Subscription ID',
              type: 'radio',
              gridStyle: 'layoutcell',
              noLabel: true,
              dependentOn: 'provisioningtype',
              show: function(provisioningtype) {
                return (
                  provisioningtype === ProvisioningConstants.type.REDIRECT_URL
                );
              },
              markAsCheck: function(formValue, name) {
                if (
                  formValue['provisioningtype'] ===
                    ProvisioningConstants.type.REDIRECT_URL &&
                  formValue['entityID'] === false
                ) {
                  return ProvisioningConstants.type.SUBSCRIPTION_ID;
                } else {
                  return false;
                }
              },
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['entityID'] = false;
                }
                return resetValue;
              }
            },

            {
              name: 'entityID',
              label: 'Entity ID',
              type: 'radio',
              gridStyle: 'layoutcell',
              noLabel: true,
              dependentOn: 'provisioningtype',
              show: function(provisioningtype) {
                return (
                  provisioningtype === ProvisioningConstants.type.REDIRECT_URL
                );
              },
              markAsCheck: function(formValue, name) {
                if (
                  formValue['provisioningtype'] ===
                    ProvisioningConstants.type.REDIRECT_URL &&
                  formValue['subscriptionID'] === false
                ) {
                  return ProvisioningConstants.type.ENTITY_ID;
                } else {
                  return false;
                }
              },
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['subscriptionID'] = false;
                }
                return resetValue;
              }
            }
          ]
        },
        {
          id: 'Staticform',
          title: 'Communicate Provisioning Status',
          style: 'provisionstaticform',
          fields: [
            {
              name: 'endpointUrl',
              label: 'Fortellis UI for reporting provisioning status',
              type: 'static',
              style: '',
              value:
                'Developer can use UI control available in subscription details page to mark the provisioning status as complete.',
              showfieldvalue: true,
              labelstyle: 'relativeformlabel',
              gridStyle: 'layoutcell'
            },
            {
              name: 'endpointUrl',
              label: 'Fortellis endpoint for reporting provisioning status',
              type: 'static',
              style: '',
              value: 'https://api.fortellis.io/provisioningStatus',
              showfieldvalue: true,
              labelstyle: 'relativeformlabel',
              gridStyle: 'layoutcell'
            }
          ]
        }
      ]
    }
  ],

  formFields: {
    provisioning_interface: {
      redirectURL: '',
      provisioningtype: ProvisioningConstants.type.MANUAL,
      endpointUrl: '',
      apiRequestBody: '',
      apiResponse: '',
      entityID: false,
      subscriptionID: true
    }
  },
  activeTabId: 'provisioning_interface'
};
