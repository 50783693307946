import React from 'react';

import { FormattedMessage } from 'react-intl';
import NullState from '../common/NullState';

import {
  Card,
  CardHeader,
  CardMedia,
  CardSubtitle,
  CardTitle
} from '@cdk-uip/react-card';

import { Link } from 'react-router-dom';
import CogsLogo from '../common/images/cogs.png';
import SolutionVisibility from '../common/SolutionVisibility';
import SolutionListItemOrgDetails from './SolutionListItemOrgDetails';

const SolutionListItem = ({ solution }) => (
  <Link to={`/solutions/${solution.id}`}>
    {solution.overview ? (
      <Card className="solution-list-item">
        {solution.overview.solutionLogo ? (
          <CardMedia
            className="solution-icon"
            scale="square"
            style={{
              backgroundImage: `url(${
                solution.overview.solutionLogo
              }?${Math.random()})`
            }}
          />
        ) : (
          <img src={CogsLogo} alt="Solution Logo" className="solution-icon" />
        )}

        <CardHeader className="solution-list-item-text">
          <CardTitle>
            <div className="solution-title-div">
              <div className="solution-title">
                {solution.overview.name
                  ? solution.overview.name
                  : 'No App name to display...'}
              </div>
              <div className={'solution-title-spacer-div'} />
              <div className="private-solution-badge-container-storefront">
                {solution.solution_visibility &&
                solution.solution_visibility.solutionVisibilityType &&
                solution.solution_visibility.solutionVisibilityType ===
                  SolutionVisibility.PRIVATE ? (
                  <div
                    style={{ float: 'right' }}
                    className="private-badge-icon-div"
                  >
                    <i className="material-icons">lock</i>
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
          </CardTitle>
          <CardSubtitle className="solution-subtitle">
            <SolutionListItemOrgDetails
              orgId={solution.orgId}
              className={'solution-subtitle-orgName'}
            />
            {solution.overview.briefDescription
              ? solution.overview.briefDescription.length < 100
                ? solution.overview.briefDescription
                : solution.overview.briefDescription.substring(0, 100) + ' ...'
              : 'No Description to display...'}
          </CardSubtitle>
        </CardHeader>
      </Card>
    ) : (
      <Card className="solution-list-item">
        <NullState
          className="solution-icon"
          icon="widgets"
          text={
            <FormattedMessage
              id="AppListingInfo.noItems"
              defaultMessage="No App Listing Info to display"
            />
          }
        />
      </Card>
    )}
  </Link>
);

export default SolutionListItem;
