export const NotificationConstants = {
  type: {
    SUBSCRIPTION: "Subscription",
    PROVISIONING: "Provisioning"
  },
  status: {
    SUCCESSFUL: "Successful",
    FAILED: "Failed"
  }
};

export default NotificationConstants;
