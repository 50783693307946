import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { Subheading1, Title } from '@cdk-uip/react-typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import SolutionHeaderCommon from '../../../marketplace-account/my-solutions/solution-management/SolutionHeaderCommon';

const FormTitle = ({
  title,
  titleNotes,
  formAllValues,
  isSolutionReview,
  handlePreview,
  isFormInvalid
}) => {
  return (
    <div className="fm-page-title-grid title-content">
      <div>
        <SolutionHeaderCommon
          solution={formAllValues}
          isSolutionReview={isSolutionReview}
        />
      </div>

      <div className="form-title-container">
        <div className="form-title-text inline-block">
          <Title className="fm-page-content-title fm-page-solution-submission-title">
            {title}
          </Title>
        </div>

        <div className="form-title-action inline-block">
          {!isSolutionReview && (
            <Button
              className="button-right"
              type="button"
              onClick={() => {
                handlePreview && handlePreview();
              }}
              disabled={isFormInvalid}
              data-cy="btn_mp_sol_preview"
            >
              <FormattedMessage
                id="SolutionSubmissionForm.button6"
                defaultMessage="Preview"
              />
            </Button>
          )}
        </div>
      </div>

      {titleNotes && <Subheading1 className="notes">{titleNotes}</Subheading1>}
    </div>
  );
};

export default injectIntl(FormTitle);
