import React from 'react';
import './MediaGallery.scss';
import { Avatar, IconChevronLeft, IconChevronRight, Dialog } from 'cdk-radial';
import BackIcon from '../common/images/media_left_arrow.svg';
import ForwardIcon from '../common/images/media_right_arrow.svg';
import CloseIcon from '../common/images/close.svg';
import { injectIntl } from 'react-intl';

const YOUTUBE = 'youtube.com/watch?v=',
  VIEMO = 'vimeo.com/',
  BACK_ARW_ENB = 'videoCell__back-arrow',
  FORW_ARW_ENB = 'videoCell__next-arrow',
  YOUTUBE_SHORT_LINK = 'youtu.be/';

class GalleryVideos extends React.Component {
  state = {
    dialogOpen: false,
    embedUrl: '',
    title: '',
    page: 0,
    videos: [],
    showNavigation: true,
    showLeftNavArrow: false,
    showRighNavArrow: true,
    maxScrollLeftValue: -1
  };

  componentDidMount() {
    let {
      props: { videos }
    } = this;
    this.setState({ videos });
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateScrollWidthAndClientWidth(
      prevState.scrollWidth,
      prevState.clientWidth
    );
  }

  isValidUrl = string => {
    const URLRegex = /^(http|https):\/\/(www\.)?(?!\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,5}(:[-a-zA-Z0-9]{1,10})?(\/.*)?$/i;
    let regex = URLRegex;
    return regex.test(string);
  };

  updateScrollWidthAndClientWidth = (scrollWidth, clientWidth) => {
    let elmnt = document.getElementById('video-gallery-frame');
    if (!elmnt) {
      return null;
    }
    if (
      elmnt.scrollWidth !== scrollWidth ||
      elmnt.clientWidth !== clientWidth
    ) {
      this.setState({
        scrollWidth: elmnt.scrollWidth,
        clientWidth: elmnt.clientWidth
      });
      this.showNavigationHandler();
    }
  };

  showNavigationHandler = () => {
    let maxScrollLeftValue = this.state.scrollWidth - this.state.clientWidth;
    if (maxScrollLeftValue <= 0) {
      this.setState({ showNavigation: false });
    } else {
      this.setState({ maxScrollLeftValue: maxScrollLeftValue });
    }
  };

  rightArrowClickHandler = () => {
    let elmnt = document.getElementById('video-gallery-frame');
    elmnt.scrollLeft = elmnt.scrollLeft + elmnt.clientWidth;
  };

  leftArrowClickHandler = () => {
    let elmnt = document.getElementById('video-gallery-frame');
    elmnt.scrollLeft = elmnt.scrollLeft - elmnt.clientWidth;
  };

  onscroll = () => {
    let elmnt = document.getElementById('video-gallery-frame');
    if (elmnt.scrollLeft === 0) {
      this.setState({ showLeftNavArrow: false });
    } else {
      this.setState({ showLeftNavArrow: true });
    }

    if (
      Math.floor(elmnt.scrollLeft) >= Math.floor(this.state.maxScrollLeftValue)
    ) {
      this.setState({ showRighNavArrow: false });
    } else {
      this.setState({ showRighNavArrow: true });
    }
  };

  onClose = () => {
    this.setState({ dialogOpen: false, embedUrl: '', title: '' });
  };

  showPopUp = (url, title) => {
    let vid = '';
    if (url.includes(YOUTUBE)) {
      vid = url.split(YOUTUBE)[1];
      this.setState({
        embedUrl: `https://www.youtube.com/embed/${vid}?autoplay=1&rel=0`,
        title,
        dialogOpen: true
      });
    }
    if (url.includes(YOUTUBE_SHORT_LINK)) {
      vid = url.split(YOUTUBE_SHORT_LINK)[1];
      this.setState({
        embedUrl: `https://www.youtube.com/embed/${vid}?autoplay=1&rel=0`,
        title,
        dialogOpen: true
      });
    }
    if (url.includes(VIEMO)) {
      vid = url.split(VIEMO)[1];
      this.setState({
        embedUrl: `https://player.vimeo.com/video/${vid}?title=0&byline=0&portrait=0&transparent=0&autoplay=1`,
        title,
        dialogOpen: true
      });
    }
  };

  getDefaultImgComponent = url => {
    let vid = '';
    if (url && url.includes(YOUTUBE)) {
      vid = url.split(YOUTUBE)[1];
      return (
        <img
          className="video_img"
          alt={vid}
          src={`https://img.youtube.com/vi/${vid}/hqdefault.jpg`}
        />
      );
    }
    if (url && url.includes(YOUTUBE_SHORT_LINK)) {
      vid = url.split(YOUTUBE_SHORT_LINK)[1];
      return (
        <img
          className="video_img"
          alt={vid}
          src={`https://img.youtube.com/vi/${vid}/hqdefault.jpg`}
        />
      );
    }
    if (url && url.includes(VIEMO)) {
      vid = url.split(VIEMO)[1];
      return (
        <iframe
          className="video_img"
          title={vid}
          src={`https://player.vimeo.com/video/${vid}?title=0&byline=0&portrait=0&transparent=0&autoplay=0&background=1`}
          frameBorder="0"
        />
      );
    }
  };

  render() {
    const {
      props: { intl, isPreview },
      state: { videos, showNavigation, showLeftNavArrow, showRighNavArrow }
    } = this;
    return (
      <div className="videoCell__div" onScroll={this.onscroll}>
        {showNavigation && showLeftNavArrow && (
          <Avatar
            className={BACK_ARW_ENB}
            color="700"
            data-testid="left-avatar"
            icon={<IconChevronLeft />}
            size="small"
            src={BackIcon}
            variant="icon"
            onClick={this.leftArrowClickHandler}
          />
        )}
        <div id="video-gallery-frame" className="video-gallery-frame">
          <div className="video-gallery-images-container ">
            {videos.map(({ url, title, description }, key) => {
              return (
                url &&
                this.isValidUrl(url) && (
                  <div key={key} className="videoCell__mask">
                    <div className="videoCell__video-list">
                      <div className="play_button_wrapper">
                        <div
                          data-cy={`div_galleryvideos_playbutton_${key}`}
                          className="play_button"
                          onClick={() => this.showPopUp(url, title)}
                        >
                          <svg viewBox="0 0 200 200" alt="Play video">
                            <circle
                              cx="100"
                              cy="100"
                              r="90"
                              fill="rgba(144, 144, 144, 0.72)"
                              strokeWidth=""
                              stroke=""
                            />
                            <polygon
                              points="70, 55 70, 145 145, 100"
                              fill="rgba(255, 255, 255, 0.6)"
                            />
                          </svg>
                        </div>
                        <div className="video_wrapper">
                          {this.getDefaultImgComponent(url)}
                        </div>
                      </div>
                    </div>
                    <div className="videoCell__title">{title}</div>
                    <div className="videoCell__description">{description}</div>
                  </div>
                )
              );
            })}
          </div>
        </div>
        {showNavigation && showRighNavArrow && (
          <Avatar
            className={FORW_ARW_ENB}
            color="700"
            data-testid="right-avatar"
            icon={<IconChevronRight />}
            size="small"
            src={ForwardIcon}
            variant="icon"
            onClick={this.rightArrowClickHandler}
          />
        )}

        <div className="mdc_dilog_parent">
          {this.state.dialogOpen && !isPreview && (
            <Dialog
              isOpen={this.state.dialogOpen}
              disableOverlayClicked={true}
              className="videoCell_dilog_body"
              onClose={this.onClose}
              title={this.state.title}
            >
              <iframe
                style={{
                  height: '500px',
                  width: '100%'
                }}
                title={this.state.embedUrl}
                src={this.state.embedUrl}
                frameBorder="0"
              />
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(GalleryVideos);
