export const ApiConfigStatusForSubscriptionConstants = {
  NOT_STARTED: "Not Started",
  PENDING_CONFIGURATION: "Pending Configuration",
  ACTIVE: "Active",
  ACTIVATED: "Activated",
  EXPIRED: "Expired",
  COMPLETED: "Completed",
  PENDING_ACTIVATION: "Pending Activation",
  PENDING: "Pending",
  CANCELLED: "Cancelled"
};

export default ApiConfigStatusForSubscriptionConstants;
