const AppListingFeeConstants = {
  ListingFee: {
    NO_FEE: '0',
    DEFAULT: '129',
    CAD_DEFAULT: '175',
    CUSTOM: ''
  },
  Options: {
    NO_FEE: 'NoFee',
    DEFAULT: 'Default',
    CUSTOM: 'Custom'
  },
  Labels: {
    NO_FEE: '$0.00 - No Fee',
    DEFAULT: '$129.00 - Default',
    CUSTOM: 'Custom',
    CAD_DEFAULT: '$175.00 - Default'
  },
  Captions: {
    NO_FEE: 'Marketplace Listing Fee will be set to $0.00',
    DEFAULT: 'Marketplace Listing Fee will be set to $129.00',
    CAD_DEFAULT: 'Marketplace Listing Fee will be set to $175.00',
    CUSTOM: 'Marketplace Listing Fee will be set to the entered amount'
  }
};

export default AppListingFeeConstants;
