import React, { useState } from 'react';
import { Checkbox  } from 'cdk-radial';

const CheckboxWrapper = ({ isSelectorChecked, publisher, onFilterSelect, sectionTitle='Publishers', publisherUnchecked, publisherChecked, checkedFilters }) => {

  const [isPublisherChecked, setIsPublisherChecked] = useState(isSelectorChecked ? true :
    checkedFilters && checkedFilters[sectionTitle] && publisher.id
    ? !!checkedFilters[sectionTitle][publisher.id]
    : false
  );

  return (
    <Checkbox
        data-qa={'cdk-checkbox'}
        checked={isPublisherChecked}
        onChange={event => {
            let isChecked = event.target.checked;
            setIsPublisherChecked(isChecked);
            if(isChecked){
                publisherChecked();
                onFilterSelect(isChecked, publisher, sectionTitle);
            }
            else {
                publisherUnchecked(publisher);
            }
        }}
        style={{ marginLeft: '20px' }}
        label={publisher && publisher.name}
    />
  );
};

export default CheckboxWrapper;
