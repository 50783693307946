export const config = {
  formId: 'plans',
  formTitle: 'Plans',
  formValueType: 'Array',
  showDynamicLabel: true,
  deleteDependentOn: 'isTheFreeTrial',
  formNote:
    'These plans can be created for Marketplace subscription purposes only. Fortellis is currently not providing payment and billing services to the App Provider. App Providers need to take care of billing and payment collection outside of Fortellis. For Fortellis apps, at least one pricing plan is mandatory.',
  noFormFieldNote:
    'No Plans available. Please use add functionality to add new items.',
  haveDelete: true,
  tabs: [
    {
      id: 'plan',
      label: 'Plan',
      contents: [
        {
          id: 'plan',
          fields: [
            {
              name: 'isFreeTrialEnabled',
              labelDependsOn: 'isFreeTrialEnabled',
              label: function(isFreeTrialEnabled) {
                if (isFreeTrialEnabled) {
                  return 'Free Trial Plan Enabled';
                } else {
                  return 'Free Trial Plan Disabled';
                }
              },
              dependentOn: 'isTheFreeTrial',
              show: function(dependOn) {
                return dependOn;
              },
              type: 'on_off_switch',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText:
                'Enable the plan to allow specific duration of free trial of the app.'
            },
            {
              name: 'planName',
              label: 'Plan Name',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              maxLength: 20,
              toolTipText:
                'Specify a plan name, this will be shown to buyer during subscription as well as on the invoice for the subscription.'
            },
            {
              name: 'planDescription',
              label: 'Plan Description',
              type: 'text_area',
              style: 'formtextfield',
              gridStyle: 'layoutcell edition-description-area',
              maxLength: 256,
              toolTipText: 'Describe the intention and purpose of the plan.'
            },
            {
              multiple: true,
              dependentOn: 'isTheFreeTrial',
              show: function(dependOn) {
                return !dependOn;
              },
              multipleFieldGridStyle: 'multiple-field-form-div',
              multipleFields: [
                {
                  hasDependent: true,
                  dependentContent: {
                    show: function(dependOn) {
                      return true;
                    },
                    fields: [
                      {
                        multiple: true,
                        dependentOn: 'billingType',
                        multipleFields: [
                          {
                            name: 'billingType',
                            label: 'Billing Type',
                            type: 'select',
                            resetDependent: function(value) {
                              let resetValue = {};
                              if (
                                value === 'ONE TIME' ||
                                value === 'TRANSACTIONAL' ||
                                value === 'FREE'
                              ) {
                                resetValue['billingFrequency'] = null;
                                if (value === 'FREE') {
                                  resetValue['basePrice'] = 0;
                                }
                              } else {
                                resetValue['billingFrequency'] = 'MONTH';
                              }
                              return resetValue;
                            },
                            options: [
                              {
                                name: 'One Time',
                                value: 'ONE TIME'
                              },
                              {
                                name: 'Recurring',
                                value: 'RECURRING'
                              },
                              {
                                name: 'Transactional',
                                value: 'TRANSACTIONAL'
                              },
                              {
                                name: 'Free',
                                value: 'FREE'
                              }
                            ],
                            fieldStyle: 'dependent-field-div',
                            postText: '  ',
                            postTextStyle: 'select-field-post-text',
                            gridStyle:
                              'select-field-with-pre-post-text select-plan-field-dependent',
                            style: ['formSelectfield', 'select-multiple'],
                            toolTipText:
                              'This sets the number of invoices that will be generated for each subscription. Recurring type will generate one invoice every billing cycle and One Time type will generate only one invoice.'
                          },
                          {
                            name: 'billingFrequency',
                            label: 'Billing Frequency',
                            type: 'select',
                            style: ['formSelectfield', 'select-multiple'],
                            dependentOn: 'billingType',
                            show: function(dependOn) {
                              return dependOn === 'RECURRING';
                            },
                            options: [
                              {
                                name: 'Weekly',
                                value: 'WEEK'
                              },
                              {
                                name: 'Monthly',
                                value: 'MONTH'
                              },
                              {
                                name: 'Quarterly',
                                value: 'QUARTER'
                              },
                              {
                                name: 'Yearly',
                                value: 'YEAR'
                              }
                            ],
                            fieldStyle: 'dependent-field-div',
                            gridStyle:
                              'select-field-with-pre-post-text select-plan-field-dependent',
                            postTextStyle: 'select-field-post-text',
                            preText: '  ',
                            preTextStyle: 'text-field-pre-text',
                            toolTipText:
                              'This sets the cycle when billing runs and generates the invoice for the subscription.'
                          }
                        ]
                      },
                      {
                        multiple: true,
                        fieldStyle: 'dependent-field-div',
                        dependentOn: 'billingType',
                        show: dependOn =>
                          dependOn !== 'TRANSACTIONAL' && dependOn !== 'FREE',
                        multipleFields: [
                          {
                            name: 'basePrice',
                            label: 'Base Price',
                            fieldStyle: 'dependent-field-div',
                            preText: '',
                            preTextStyle: 'text-field-pre-text',
                            postTextStyle: 'text-field-post-text',
                            postText: function(dependOn) {
                              if (dependOn === 'RECURRING') {
                                return 'per {billingFrequency}';
                              } else {
                                return '';
                              }
                            },
                            type: 'text_field',
                            fieldType: 'number',
                            min: 0,
                            helperText: 'Value should not be negative',
                            style: '',
                            gridStyle: 'text-field-with-pre-post-text',
                            dependentOn: 'billingType',
                            toolTipText:
                              'Define the base price for the app that will be used for the invoice in every billing cycle. '
                          },
                          {
                            name: 'includedUnitQuantity',
                            label: 'Quantity',
                            type: 'text_field',
                            fieldType: 'number',
                            min: 0,
                            preText: '  includes  ',
                            preTextStyle: 'text-field-pre-text',
                            postText: '  ',
                            postTextStyle: 'text-field-post-text',
                            gridStyle: 'text-field-with-pre-post-text',
                            helperText: 'Quantity should not be negative',
                            toolTipText:
                              'Specify how much quantity is provided in this plan.'
                          },
                          {
                            name: 'includedUnitOfMeasure',
                            label: 'Unit of Measure',
                            type: 'text_field',
                            fieldStyle: 'dependent-field-div',
                            gridStyle: 'text-field-with-pre-post-text',
                            toolTipText:
                              'Define how you want to measure the units of app pricing.'
                          },
                          {
                            multiple: true,
                            title: 'Additional Charges',
                            titleStyle: 'pricing-dependent-filed-title',
                            fieldStyle: 'dependent-field-div',
                            dependentOn: 'billingType',
                            multipleFields: [
                              {
                                name: 'additionalUnitPrice',
                                label: 'Price',
                                type: 'text_field',
                                fieldType: 'number',
                                min: 0,
                                helperText: 'Value should not be negative',
                                postText: 'per ',
                                postTextStyle: 'text-field-post-text',
                                gridStyle: 'text-field-with-pre-post-text',
                                toolTipText:
                                  'Specify the price for the additional quantity. This charge will be used to calculate the final subscription charges for the buyer, based on the quantity selected by buyer.'
                              },
                              {
                                name: 'additionalUnitOfMeasure',
                                label: 'Unit of Measure',
                                type: 'text_field',
                                postTextStyle: 'text-field-post-text',
                                postText: function(dependOn) {
                                  if (dependOn === 'RECURRING') {
                                    return 'per {billingFrequency}';
                                  } else {
                                    return '';
                                  }
                                },
                                gridStyle: 'text-field-with-pre-post-text',
                                toolTipText:
                                  'Specify the price for the additional quantity. This charge will be used to calculate the final subscription charges for the buyer, based on the quantity selected by buyer.'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        multiple: true,
                        fieldStyle: 'dependent-field-div',
                        dependentOn: 'billingType',
                        show: dependOn => dependOn === 'TRANSACTIONAL',
                        multipleFields: [
                          {
                            multiple: true,
                            fieldStyle: 'dependent-field-div',
                            dependentOn: 'billingType',
                            multipleFields: [
                              {
                                name: 'includedTransactionalFee',
                                label: 'Transactional Fee',
                                type: 'text_field',
                                fieldType: 'number',
                                min: 0,
                                helperText: 'Value should not be negative',
                                gridStyle: 'text-field-with-pre-post-text',
                                toolTipText:
                                  'Specify transactional fee amount need to be paid'
                              },
                              {
                                name: 'transactionalTypeOptions',
                                label: 'Type',
                                type: 'select',
                                style: [
                                  'formSelectfield',
                                  'select-multiple',
                                  'select-transactionalType'
                                ],
                                gridStyle: 'select-field-with-pre-post-text',
                                options: [
                                  {
                                    name: '%',
                                    value: '%'
                                  },
                                  {
                                    name: '$',
                                    value: '$'
                                  }
                                ],
                                preText: ' ',
                                preTextStyle: 'text-field-pre-text',
                                postText: ' per ',
                                postTextStyle: 'text-field-post-text'
                              },
                              {
                                name: 'includedTransactionalType',
                                label: 'Transactional Type',
                                type: 'text_field',
                                gridStyle: 'text-field-with-pre-post-text',
                                toolTipText:
                                  'Specify type of transactional fee for this plan'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            },
            {
              multiple: true,
              dependentOn: 'isTheFreeTrial',
              show: function(dependOn) {
                return dependOn;
              },
              multipleFieldGridStyle: 'multiple-field-form-div',
              multipleFields: [
                {
                  name: 'freeTrialPeriod',
                  label: 'Free Trial Period',
                  datacy: 'lbl_plans_free_ftp',
                  type: 'text_field',
                  fieldType: 'number',
                  min: 0,
                  helperText: 'Value should not be negative',
                  postText: '   ',
                  postTextStyle: 'text-field-post-text',
                  gridStyle: 'text-field-with-pre-post-text',
                  toolTipText: 'Define the duration of the free trial.'
                },
                {
                  name: 'freeTrialPeriodType',
                  type: 'select',
                  style: [
                    'formSelectfield',
                    'select-multiple',
                    'select-freeeTiral'
                  ],
                  gridStyle: 'select-field-with-pre-post-text',
                  postText: '   ',
                  postTextStyle: 'select-field-post-text',
                  options: [
                    {
                      name: 'Days',
                      value: 'Day'
                    },
                    {
                      name: 'Months',
                      value: 'Month'
                    },
                    {
                      name: 'Weeks',
                      value: 'Week'
                    },
                    {
                      name: 'Years',
                      value: 'Year'
                    }
                  ]
                },
                {
                  name: 'includedUnitQuantity',
                  label: 'Quantity',
                  datacy: 'lbl_plans_free_quantity',
                  type: 'text_field',
                  fieldType: 'number',
                  min: 0,
                  preText: '  includes  ',
                  preTextStyle: 'text-field-pre-text',
                  postText: '  ',
                  postTextStyle: 'text-field-post-text',
                  gridStyle: 'text-field-with-pre-post-text',
                  helperText: 'Quantity should not be negative',
                  toolTipText:
                    'Specify how much quantity is provided in this plan.'
                },
                {
                  name: 'includedUnitOfMeasure',
                  label: 'Unit of Measure',
                  datacy: 'lbl_plans_free_measure',
                  type: 'text_field',
                  fieldStyle: 'dependent-field-div',
                  gridStyle: 'text-field-with-pre-post-text',
                  toolTipText:
                    'Define how you want to measure the units of app pricing. '
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 'features',
      label: 'Features',
      contents: [
        {
          id: 'features',
          fields: [
            {
              name: 'features',
              note: 'Select the features to include in this plan.',
              noContentNote:
                'Add app features in the Features section and then select the features to include in this plan.',
              type: 'check_box_group',
              style: 'formtextfield',
              gridStyle: 'layoutCellList',
              options: 'featureArray'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    create_plan: {
      id: null,
      isTheFreeTrial: false,
      isFreeTrialEnabled: false,
      position: 1,
      planName: 'New Plan',
      planDescription: '',
      freeTrialPeriod: 0,
      freeTrialPeriodType: 'Days',
      transactionalTypeOptions: '$',
      billingType: 'RECURRING',
      billingFrequency: 'MONTH',
      basePrice: null,
      includedUnitOfMeasure: null,
      includedUnitQuantity: null,
      includedTransactionalType: '',
      includedTransactionalFee: null,
      additionalUnitPrice: null,
      additionalUnitOfMeasure: null,
      features: [],
      dynamic: true,
      setLabelFrom: 'planName'
    }
  },
  formValues: {
    featureArray: []
  },
  activeTabId: 'plan'
};
