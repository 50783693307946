import * as SolutionFormData from '../../solution_data_object';

const getSubset = (keys, obj) =>
  keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});

export class FormUtils {
  static mapFormValuesForSubmit(
    formValues,
    formStaticValues,
    formConfig,
    solutionData,
    shouldValidate
  ) {
    let dataObject = solutionData.dataObject;
    Object.assign(dataObject, formStaticValues);
    let configKeys = Object.keys(formConfig);
    configKeys.forEach(key => {
      let extractedData = {};
      if (formConfig[key].formValueType === 'Object') {
        extractedData = this.extractObjectDataByKey(
          formValues,
          key,
          Object.keys(dataObject[key])
        );
      }
      if (formConfig[key].formValueType === 'Array') {
        extractedData = this.extractArrayDataByKey(
          formValues,
          key,
          solutionData.arrayObjKeys[key]
        );
      }
      extractedData.then(result => {
        Object.assign(dataObject, result);
      });
    });

    if (formValues.solutionType !== solutionData.solutionType) {
      dataObject.solutionType = formValues.solutionType;
    }
    return Promise.resolve(dataObject);
  }

  static extractObjectDataByKey(formValues, key, keys) {
    let data = {};
    if (formValues[key] && formValues[key][key]) {
      if (key === 'comments') {
        data = getSubset(keys, formValues[key]);
      } else {
        data[key] = getSubset(keys, formValues[key][key]);
      }
    }
    return Promise.resolve(data);
  }

  static extractArrayDataByKey(formValues, key, keys) {
    let data = {};
    data[key] = [];
    let arrayValues = Object.values(formValues[key] ? formValues[key] : {});
    arrayValues.forEach(value => {
      data[key].push(getSubset(keys, value));
    });
    return Promise.resolve(data);
  }

  static mapFormValuesForView(solutionData, formConfig) {
    let formValuesFinal = {};
    for (let key in solutionData) {
      if (Object.keys(formConfig).includes(key)) {
        if (formConfig[key].formValueType === 'Object') {
          let formValueObj = {};
          let formFields = JSON.parse(
            JSON.stringify(formConfig[key].formFields[key])
          );
          formValueObj[key] = Object.assign(formFields, solutionData[key]);
          formValuesFinal[key] = formValueObj;
        }
        if (formConfig[key].formValueType === 'Array') {
          formValuesFinal[key] = this.setDataToFormArrayDynamic(
            Object.values(formConfig[key].formFields)[0],
            solutionData[key]
          );

          let insert = formConfig[key].insert;
          if (insert) {
            let data = [];
            let arrayValues = Object.values(
              formValuesFinal[key] ? formValuesFinal[key] : {}
            );
            arrayValues.forEach(value => {
              data.push(getSubset(SolutionFormData.arrayObjKeys[key], value));
            });
            formConfig[insert.in][insert.of][insert.to] = data;
          }
        }
      } else {
        formValuesFinal[key] = solutionData[key];
      }
    }
    return formValuesFinal;
  }

  static setDataToFormArrayDynamic(formFields, formData) {
    let data = {};
    let index = 0;

    formData.forEach(dataItem => {
      let defaultFields = JSON.parse(JSON.stringify(formFields));
      data[index] = Object.assign(defaultFields, dataItem);
      index++;
    });
    return data;
  }

  static initializeStaticFormFields(staticData, formFieldValues, developerId) {
    Object.assign(
      staticData,
      getSubset(Object.keys(staticData), formFieldValues)
    );
    staticData.developerId = developerId;
    return staticData;
  }

  static updateFormFieldValue(
    activeMenuId,
    nestedActiveMenuId,
    formValueFinal,
    formFragmentValue,
    formConfig
  ) {
    if (!formValueFinal[activeMenuId]) {
      formValueFinal[activeMenuId] = {};
    }
    formValueFinal[activeMenuId][nestedActiveMenuId] = formFragmentValue;
    let insert = formConfig[activeMenuId].insert;
    if (insert) {
      let dependentValue = JSON.parse(JSON.stringify(formFragmentValue));
      delete dependentValue.dynamic;
      delete dependentValue.setLabelFrom;

      formConfig[insert.in][insert.of][insert.to] = formConfig[insert.in][
        insert.of
      ][insert.to].filter(item => {
        return item.id !== dependentValue.id;
      });
      formConfig[insert.in][insert.of][insert.to].push(dependentValue);

      for (let key in formValueFinal[insert.in]) {
        if (formValueFinal[insert.in].hasOwnProperty(key)) {
          let isDeleted = false;
          formValueFinal[insert.in][key][activeMenuId] = formValueFinal[
            insert.in
          ][key][activeMenuId].filter(item => {
            if (item.id === dependentValue.id) {
              isDeleted = true;
              return false;
            } else {
              return true;
            }
          });
          if (isDeleted) {
            formValueFinal[insert.in][key][activeMenuId].push(dependentValue);
          }
        }
      }
    }

    return formValueFinal;
  }

  static initializeFormValueForFirstLoad(
    formValueFinal,
    activeMenuId,
    nestedActiveMenuId,
    value
  ) {
    if (!formValueFinal[activeMenuId]) {
      formValueFinal[activeMenuId] = {};
    }
    if (!formValueFinal[activeMenuId][nestedActiveMenuId]) {
      formValueFinal[activeMenuId][nestedActiveMenuId] = value;
    }
    return formValueFinal;
  }

  static deleteFormItem(
    formValueFinal,
    activeMenuId,
    nestedActiveMenuId,
    formConfig
  ) {
    let formValue = formValueFinal;
    let formFields = formValue[activeMenuId][nestedActiveMenuId];

    let activeFormConfig = formConfig[activeMenuId];
    let insert = activeFormConfig.insert;
    if (insert) {
      formConfig[insert.in][insert.of][insert.to] = formConfig[insert.in][
        insert.of
      ][insert.to].filter(item => {
        return item.id !== formFields.id;
      });
      for (let key in formValue[insert.in]) {
        formValue[insert.in][key][activeMenuId] = formValue[insert.in][key][
          activeMenuId
        ].filter(item => {
          return item.id !== formFields.id;
        });
      }
    }

    delete formValue[activeMenuId][nestedActiveMenuId];
    return formValue;
  }

  static reorderFormItems(
    formValueFinal,
    activeMenuId,
    newSequence,
    stepIdOne,
    stepIdTwo
  ) {
    let temp = JSON.parse(
      JSON.stringify(formValueFinal[activeMenuId][stepIdOne])
    );
    formValueFinal[activeMenuId][stepIdOne] = JSON.parse(
      JSON.stringify(formValueFinal[activeMenuId][stepIdTwo])
    );
    formValueFinal[activeMenuId][stepIdTwo] = temp;
    return formValueFinal;
  }
}

export default FormUtils;
