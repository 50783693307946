import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PRODUCT } from '../../../components/common/constants/MonetizationConstants';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';

export const initialState = {
  paymentsInfo: [],
  paymentsInfoLoading: false,
  deletionPending: false,
  deleteResponse:[]
};

export const getPaymentInfoBySolutionId = createAsyncThunk(
  'paymentInfoBySolutionId',
  async ({ solnId,token }) => {
    const url = `${environmentURLs.paymentServiceUrl}/app/${solnId}/payment-info?productType=${PRODUCT.LISTING_FEE}`;
    try {
      const response = await axios({
        method: 'GET',
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response?.data?.length > 0) {
        return (
            response.data
)
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const deleteUnVerifiedBankAccount = createAsyncThunk(
  'deleteUnVerifiedBank',
  async ({ solnId, orgId, token }) => {
    const url = `${environmentURLs.paymentServiceUrl}/app/payment-method`;
    try {
      const response = await axios({
        method: "PATCH",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
        data: {
          solutionId: solnId,
          orgId: orgId,
          productType: PRODUCT.LISTING_FEE,
        },
      });
      if (response?.data?.length > 0) {
        return (
            response.data
)
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const paymentsInfoSlice = createSlice({
  name: 'paymentInfoBySolutionId',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getPaymentInfoBySolutionId.fulfilled]: (state, action) => {
      state.paymentsInfo = action.payload;
      state.paymentsInfoLoading = false;
    },
    [getPaymentInfoBySolutionId.pending]: (state) => {
      state.paymentsInfo = null;
      state.paymentsInfoLoading = true;
    },
    [getPaymentInfoBySolutionId.rejected]: (state) => {
      state.paymentsInfo = null;
      state.paymentsInfoLoading = false;
    },
    [deleteUnVerifiedBankAccount.fulfilled]: (state, action) => {
      state.deleteResponse= action.payload;
      state.deletionPending = false;
    },
    [deleteUnVerifiedBankAccount.pending]: (state) => {
      state.deleteResponse = null;
      state.deletionPending= true;
    },
    [deleteUnVerifiedBankAccount.rejected]: (state) => {
      state.deleteResponse = null;
      state.deletionPending = false;
    },
  },
});

export default paymentsInfoSlice.reducer;