export const config = {
  formId: 'solution_notes',
  formTitle: 'App Notes',
  formValueType: 'Object',
  tabs: [
    {
      id: 'solution_notes',
      label: 'Additional Details',
      contents: [
        {
          id: 'solution_notes',
          fields: [
            {
              name: 'reviewersComments',
              label: 'Additional information needed for the review process',
              type: 'text_area',
              maxLength: 200,
              validationType: 'reviewers_comments_notes',
              validationErrorMessage:
                'Please enter string only for solution notes',
              style: ['formtextfield', 'form-text-area'],
              toolTipText:
                'Provide additional instructions or notes to Fortellis team that will help in review of the app. Buyer will not see this information.'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    solution_notes: {
      reviewersComments: ''
    }
  },
  activeTabId: 'solution_notes'
};
