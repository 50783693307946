import React from 'react';
import SolutionCard from './SolutionCard';
import { SecondaryButton, Grid, Row, Column } from 'cdk-radial';
import './SolutionCardGroup.scss';
import Loading from '../../common/Loading';
import { useSelector, useDispatch } from 'react-redux';
import {
  selector,
  showMoreInProgressIncomplete,
  showMoreInProgressComplete
} from '../../../redux/connectedComponents/solutionCardGroup/solutionCardGroup.slice';

const NEWEST_APPS = 'Newest Apps';

const SolutionCardGroup = props => {
  let { solutionArray, title, auth, orgMapObj, worksWithValueMapObj } = props;
  const showMoreInProgress = useSelector(selector);
  const dispatch = useDispatch();

  const fetchMoreSolutions = () => {
    dispatch(showMoreInProgressIncomplete());
    props.moreSolutions(auth, () => {
      dispatch(showMoreInProgressComplete());
    });
  };
  return (
    <div>
      {props.title !== '' && (
        <div className="solution-card-group__title_div">{title}</div>
      )}
      <Grid data-testid="solutions-grid">
        <Row style={{ paddingBottom: '15px', marginLeft: '15px' }}>
          {solutionArray.map(solution => (
            <Column
              xl={4}
              l={4}
              m={12}
              s={12}
              xs={12}
              style={{ paddingBottom: '25px' }}
              key={`solution-${solution.id}`}
            >
              <SolutionCard
                solutionProviderName={
                  solution.orgId ? orgMapObj[solution.orgId] : null
                }
                solution={solution}
                worksWithValueMapObj={worksWithValueMapObj}
                auth={auth}
              />
            </Column>
          ))}
        </Row>
      </Grid>
      {!props.solutionArray.length && (
        <div className="error_div">
          <strong>
            <span>No apps available to show for selected filter criteria</span>
            <br />
            <br />
            <span>please try again with other filter criteria</span>
          </strong>
        </div>
      )}
      {props.title != NEWEST_APPS && showMoreInProgress && (
        <div className="solution-card-group__showMoreButton_div">
          <div className={'solution-card-group__loading_div'}>
            <Loading loaderClass={'loader'} circleClass={'showmore-loading'} />
          </div>
        </div>
      )}
      {props.showMore &&
      props.solutionArray.length &&
      props.solutionArray.length % 9 == 0 ? (
        <div className="solution-card-group__showMoreButton_div">
          <SecondaryButton
            text="Show More"
            data-testid="show_more"
            onClick={() => fetchMoreSolutions()}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SolutionCardGroup;
