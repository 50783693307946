import React from 'react';

import { FormattedMessage } from 'react-intl';
import Spinner from '../common/Spinner';
import EmptyContent from '../common/images/Empty State 2x.png';

import SolutionListItem from './SolutionListItem';

import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import gql from 'graphql-tag';
import { query } from './../../containers/SolutionListContainer';
import { withApollo } from 'react-apollo';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import StoreListingAPI from '../../api/StoreListingAPI';

class SolutionSection extends React.Component {
  state = {
    heading: '',
    solutions: [],
    offset: null,
    fetchingMoreSolutions: false,
    loading: true,
    searchingSolutions: false,
    selectedCategory: null
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      heading,
      solutions,
      pageInfo,
      loading,
      searchingSolutions,
      selectedCategory
    } = nextProps;
    this.setState({
      heading: heading,
      solutions: solutions,
      offset: pageInfo ? pageInfo.offset : null,
      loading: loading,
      searchingSolutions: searchingSolutions,
      selectedCategory: selectedCategory
    });
  }

  fetchMoreSolution = async () => {
    this.setState({ fetchingMoreSolutions: true });

    if (this.props.auth.isAuthenticated) {
      const response = await this.props.client.query({
        query: gql`
          ${query}
        `,
        variables: {
          sortBy: 'name',
          offset: this.state.offset,
          category:
            this.state.selectedCategory === -1
              ? null
              : this.state.selectedCategory
        }
      });

      if (
        response &&
        response.data &&
        response.data.paginatedSolutionCatalogList
      ) {
        const {
          solutions,
          pageInfo
        } = response.data.paginatedSolutionCatalogList;
        let currentSolutions = this.state.solutions;
        this.setState({
          solutions: currentSolutions.concat(solutions),
          offset: pageInfo ? pageInfo.offset : null
        });
      }
      this.setState({ fetchingMoreSolutions: false });
    } else {
      let category =
        this.state.selectedCategory === -1
          ? 'all'
          : this.state.selectedCategory;
      if (category == null) {
        category = 'all';
      }
      StoreListingAPI.anonymous
        .getAllSolutions(category, this.state.offset)
        .then(response => {
          const {
            solutions,
            pageInfo
          } = response.data.data.paginatedSolutionCatalogList;
          let currentSolutions = this.state.solutions;
          this.setState({
            solutions: currentSolutions.concat(solutions),
            offset: pageInfo ? pageInfo.offset : null,
            fetchingMoreSolutions: false
          });
        })
        .catch(err => {});
    }
  };

  componentDidMount() {
    const {
      heading,
      solutions,
      pageInfo,
      loading,
      searchingSolutions,
      selectedCategory
    } = this.props;
    this.setState({
      heading: heading,
      solutions: solutions,
      offset: pageInfo ? pageInfo.offset : null,
      loading: loading,
      searchingSolutions: searchingSolutions,
      selectedCategory: selectedCategory
    });
  }

  render() {
    const {
      solutions,
      loading,
      searchingSolutions,
      heading,
      offset,
      fetchingMoreSolutions
    } = this.state;
    return (
      <LayoutGrid
        nested
        className="solution-section-container max-width-resp overflow-x-unset"
      >
        <LayoutGridCell span={12}>
          <div className="solution-section-heading">{heading}</div>
        </LayoutGridCell>

        {loading || searchingSolutions ? (
          <LayoutGridCell span={12}>
            <div>
              <Spinner />
              <div
                style={{ textAlign: 'center' }}
                className="custom-font--grid"
              >
                <strong>Loading all solutions...</strong>
              </div>
            </div>
          </LayoutGridCell>
        ) : solutions.length > 0 ? (
          solutions.map(solution => (
            <LayoutGridCell key={solution.id} span={6}>
              <SolutionListItem solution={solution} />
            </LayoutGridCell>
          ))
        ) : (
          <LayoutGridCell span={12}>
            <div style={{ padding: '5px' }}>
              <img
                style={{ width: '100%' }}
                alt="No apps available yet."
                src={EmptyContent}
              />
              <div className="empty-content-card-message-myown">
                <FormattedMessage
                  id="SolutionList.noItems"
                  defaultMessage="No items to display!"
                />
              </div>
            </div>
          </LayoutGridCell>
        )}
        {!loading && offset && !fetchingMoreSolutions && !searchingSolutions ? (
          <LayoutGridCell className="show-more-solutions-div" span={12}>
            <div
              className="show-more-solutions-text-div"
              onClick={this.fetchMoreSolution}
            >
              <FormattedMessage
                id="SolutionSection.showMoreSolutions"
                defaultMessage="SHOW MORE APPS"
              />
            </div>
          </LayoutGridCell>
        ) : !loading && offset && fetchingMoreSolutions ? (
          <LayoutGridCell span={12}>
            <div className="show-more-solutions-text-div">
              <Spinner />
            </div>
          </LayoutGridCell>
        ) : (
          false
        )}
      </LayoutGrid>
    );
  }
}
SolutionSection.displayName = 'SolutionSection';
export default withApollo(withAuth(SolutionSection));
