import React, { Component } from 'react';
import { Dialog, DialogHeader, DialogBody } from '@cdk-uip/react-dialog';
import '../UserConsentDialog.scss';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@cdk-uip/react-icon';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';
import Loading from '../Loading';
// import { FillablePanel } from '@cdkglobal/output-services-viewer-dev-strictmode';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

export const consentStatusConst = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

//STATUS CONSTANTS
const CREATING_SIGNING_SESSION = 'Creating Signing Session';
const SAVING_CONSENT = 'Saving Consent';

const ATTEMPTS = 5;

class ElectronicConsentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signingUrl: '',
      loading: false,
      displayTerms: true,
      spinnerText: CREATING_SIGNING_SESSION,
      signedDocumentUrl: ''
    };
    this.getSigningURL = this.getSigningURL.bind(this);
    this.saveAddendumAPITermsToS3AndGetUrl = this.saveAddendumAPITermsToS3AndGetUrl.bind(
      this
    );
    this.updateViewerMediaAndType = this.updateViewerMediaAndType.bind(this);
  }

  client = getApolloClient();

  /**
   * generate a random uuid
   * Returns:
   *  random uuid
   */
  Id() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  saveFileToS3AndGetUrl = async n => {
    console.log('saving solution terms to s3');
    try {
      let fileKey = 'app-terms/' + this.Id();
      let signedDocumentS3Data = await this.client.query({
        query: getSignedConsentS3Url,
        variables: {
          id: this.Id(),
          electronicSigningFetchDocumentAPI: this.state.signedDocumentUrl,
          fileKey: fileKey
        }
      });

      if (
        !signedDocumentS3Data ||
        !signedDocumentS3Data.data ||
        !signedDocumentS3Data.data.signedConsentS3Url ||
        !signedDocumentS3Data.data.signedConsentS3Url.s3url
      ) {
        console.error(
          'error in response of getSignedConsentS3Url query',
          signedDocumentS3Data
        );
        if (n === 0) {
          console.error('retrying attempts exhausted');
          this.props.onClose({
            status: 'declined'
          });
        } else {
          this.saveFileToS3AndGetUrl(n - 1);
        }
      } else {
        console.log('signed solution terms saved');
        const signedDocumentS3Url =
          signedDocumentS3Data.data.signedConsentS3Url.s3url;
        this.props.onSubmit({
          consentStatus: consentStatusConst.ACCEPTED,
          signedTermsAndConditions: signedDocumentS3Url
        });
      }
    } catch (e) {
      console.error('retrying as error in querying getSignedConsentS3Url', e);
      return this.saveFileToS3AndGetUrl(n - 1);
    }
  };

  saveAddendumAPITermsToS3AndGetUrl = async n => {
    console.log(`saving ${this.props.type} to s3`);
    try {
      let consentLink = this.props.consentLink;
      let entityName = this.props.entity ? this.props.entity.name : '';
      let fileKey;
      if (this.props.type === 'addendum') {
        fileKey = consentLink
          ? `addendums/${consentLink.slice(consentLink.lastIndexOf('/') + 1)}`
          : '';
      } else {
        fileKey =
          consentLink && entityName
            ? `api-terms/${entityName}/${consentLink.slice(
                consentLink.lastIndexOf('/') + 1
              )}`
            : '';
      }
      let signedDocumentS3Data = await this.client.query({
        query: getSignedConsentS3Url,
        variables: {
          id: this.Id(),
          electronicSigningFetchDocumentAPI: this.state.signedDocumentUrl,
          fileKey: fileKey
        }
      });

      if (
        !signedDocumentS3Data ||
        !signedDocumentS3Data.data ||
        !signedDocumentS3Data.data.signedConsentS3Url ||
        !signedDocumentS3Data.data.signedConsentS3Url.s3url
      ) {
        console.error(
          `error in response of getSignedConsentS3Url query for ${
            this.props.type
          }`
        );
        if (n === 0) {
          console.error('retrying attempts exhausted');
          this.props.onClose({
            status: 'declined'
          });
        } else {
          this.saveAddendumAPITermsToS3AndGetUrl(n - 1);
        }
      } else {
        console.log(`signed ${this.props.type} terms saved`);
        const signedDocumentS3Url =
          signedDocumentS3Data.data.signedConsentS3Url.s3url;
        this.props.onSubmit({
          consentStatus: consentStatusConst.ACCEPTED,
          signedTermsAndConditions: signedDocumentS3Url
        });
      }
    } catch (e) {
      console.error(
        `error in response of getSignedConsentS3Url query for ${
          this.props.type
        }`,
        e
      );
      return this.saveAddendumAPITermsToS3AndGetUrl(n - 1);
    }
  };

  /**
   * urlRedirect is called whenever url of the iframe changes
   * @param e - synthetic event object, current url of the iframe
   * contentWindow can only be accessed on 'e' if the current url is from the same domain,
   * otherwise, it results into an error
   *
   * we need iframe to be redirected back to marketplace after signing ceremony is completed or failed
   * after signing ceremony, iframe will be redirected back to marketplace, and the url will contain "status" queryparam with value accepted or declined
   */
  urlRedirect = async e => {
    try {
      let iframeWindowLocation = e.target.contentWindow.location;
      if (
        iframeWindowLocation.search
          .toLowerCase()
          .includes('event=signing_complete')
      ) {
        this.setState(
          { loading: true, signingUrl: '', spinnerText: SAVING_CONSENT },
          async () => {
            if (this.props.type === 'solution terms') {
              this.saveFileToS3AndGetUrl(ATTEMPTS);
            } else {
              this.saveAddendumAPITermsToS3AndGetUrl(ATTEMPTS);
            }
          }
        );
      } else {
        console.error('signing not complete');
        this.props.onClose({
          status: 'declined'
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * generates signing url by making graphql query to developerAgreementSigningUrl
   * @params: none
   * @returns: response object for the query
   * @throws: error if query fails
   */
  getSigningURL = async data => {
    const { signatureCoordinates } = this.props;
    try {
      let { name, email } = this.props.auth.userData;
      let fileName = this.props.consentLink
        ? this.props.consentLink.slice(
            this.props.consentLink.lastIndexOf('/') + 1
          )
        : '';
      let termsSigningUrl = await this.client.query({
        query: getTermsSigningUrl,
        variables: {
          id: this.Id(),
          firstName: name.split(' ')[0].trim(),
          lastName: name.split(' ')[1].trim(),
          email: email,
          solutionTerms: data.media,
          fileName: fileName,
          signatureCoordinates: signatureCoordinates,
          redirectUrl: environmentURLs.signing_redirect_url
        }
      });

      return termsSigningUrl;
    } catch (e) {
      console.error(e);
      this.setState({
        spinner: false,
        error: true
      });
      throw new Error('error in querying solutionTermsSigningUrl', e);
    }
  };

  fillablepdfCallback = data => {};

  updateViewerMediaAndType = data => {
    this.setState({
      loading: true,
      displayTerms: false
    });
    this.getSigningURL(data)
      .then(links => {
        if (
          !links ||
          !links.data ||
          !links.data.solutionTermsSigningUrl ||
          !links.data.solutionTermsSigningUrl.links ||
          typeof !links.data.solutionTermsSigningUrl.links === Array ||
          !links.data.solutionTermsSigningUrl.links.length > 0
        ) {
          console.error('error in response of solutionTermsSigningUrl query');
          return;
        }
        const url = links.data.solutionTermsSigningUrl.links[0].href;
        const signedDocumentUrl =
          links.data.solutionTermsSigningUrl.links[1].href;
        this.setState({
          loading: false,
          signingUrl: url,
          signedDocumentUrl: signedDocumentUrl
        });
      })
      .catch(e => {
        console.error(`error in fetching signing url: ${e}`);
        this.setState({
          spinner: false,
          error: true
        });
      });
  };

  render() {
    return (
      <div className={'electronic-consent-dialog'}>
        <Dialog open={true} id="consent-dialog_dialog">
          <DialogHeader className={'consent-dialog__dialogHeader'}>
            <div>
              <FormattedMessage
                id="consent-dialog.DialogHeader"
                defaultMessage="Terms and Conditions"
              />
              <div className={'consent-dialog_controlButtons'}>
                <Icon
                  className="material-icons consent-dialog_closeButton"
                  onClick={() => this.props.onClose()}
                  style={{ visibility: this.state.closeButton }}
                >
                  clear
                </Icon>
              </div>
            </div>
          </DialogHeader>
          <DialogBody id="consent-dialog_body" scrollable fullHeight>
            <div className={'consent-dialog__dialogBody'}>
              {this.state.loading && (
                <div className={'consent-dialog__spinner'}>
                  <div>{this.state.spinnerText}</div>
                  <Loading loaderClass={'loading'} />
                </div>
              )}
              {this.state.signingUrl && (
                <iframe
                  onLoad={this.urlRedirect}
                  className={'consent-dialog__frame'}
                  src={this.state.signingUrl}
                />
              )}
              {/* {this.props.terms && this.state.displayTerms && (
                <div>
                  <FillablePanel
                    strictMode
                    className={'react-pdf__Document'}
                    stream={this.props.terms}
                    fillablepdfCallback={data => this.fillablepdfCallback(data)}
                    updateViewerMediaAndType={data =>
                      this.updateViewerMediaAndType(data)
                    }
                  />
                </div>
              )} */}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    );
  }
}

const getTermsSigningUrl = gql`
  query(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $solutionTerms: String
    $fileName: String
    $signatureCoordinates: String
    $redirectUrl: String
  ) {
    solutionTermsSigningUrl(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      solutionTerms: $solutionTerms
      fileName: $fileName
      signatureCoordinates: $signatureCoordinates
      redirectUrl: $redirectUrl
    ) {
      signingTransactionId
      links {
        href
        rel
        mediaType
        method
      }
    }
  }
`;

const getSignedConsentS3Url = gql`
  query(
    $id: String
    $electronicSigningFetchDocumentAPI: String
    $fileKey: String
    $type: String
  ) {
    signedConsentS3Url(
      id: $id
      electronicSigningFetchDocumentAPI: $electronicSigningFetchDocumentAPI
      fileKey: $fileKey
      type: $type
    ) {
      s3url
    }
  }
`;

export default withAuth(ElectronicConsentDialog);
