import React from 'react';
import FormBuilder from './FormBuilder';
import FormContentTitle from './FormComponents/FormContentTitle';
import Spinner from '../../common/Spinner';

export class FormContent extends React.Component {
  render() {
    const {
      contents,
      formFieldValues,
      formStaticValues,
      formAllValues
    } = this.props;
    if (!contents || !formFieldValues) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    const discardComment =
      !!formAllValues &&
      formAllValues.developerName &&
      `${'Submitted for review by user'} ${formAllValues.developerName}`;

    let formVal =
      !!formStaticValues &&
      !!formStaticValues.comments &&
      formStaticValues.comments.comments;

    formVal =
      !!formStaticValues &&
      !!formStaticValues.comments &&
      (formVal || formStaticValues.comments);

    const titleNotes =
      !!formVal &&
      formVal.filter(
        f => f.action === 'Submitted' && f.commentText !== discardComment
      );

    return contents && contents.length > 0
      ? contents.map((content, index) => {
          return (
            <div key={index}>
              {index !== 0 && <span className="form-content-divider"> </span>}
              <div className={`formContent ${content.style}`}>
                {content.title && (
                  <FormContentTitle
                    title={content.title}
                    titleDependsOn={
                      this.props.formFieldValues[content.titleDependsOn]
                    }
                    titleNotes={
                      content.id === 'featuredImage' ||
                      content.id === 'galleryImages'
                        ? ''
                        : titleNotes
                    }
                  />
                )}
                <FormBuilder
                  {...this.props}
                  key={index}
                  content={content}
                  formStaticValues={this.props.formStaticValues}
                  formFieldValuesAll={this.props.formFieldValuesAll}
                  formFieldValues={this.props.formFieldValues}
                  formValueCount={this.props.formValueCount}
                  formValues={this.props.formValues}
                  onSave={this.props.onSave}
                />
              </div>
            </div>
          );
        })
      : false;
  }
}

export default FormContent;
