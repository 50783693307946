import React from 'react';
import SolutionLogo from '../../common/images/cogs.png';
import Spinner from '../../common/Spinner';

class SolutionDetailsCell extends React.Component {
  render() {
    const { solution, loading } = this.props;
    return loading ? (
      <Spinner />
    ) : (
      <div className="admin-leads-solution-div">
        {solution && solution.overview && solution.overview.solutionLogo ? (
          <img
            alt="App Logo"
            className="admin-leads-solution-logo"
            src={`${solution.overview.solutionLogo}?${Math.random()}`}
          />
        ) : (
          <img
            alt="App Logo"
            className="admin-leads-solution-logo"
            src={SolutionLogo}
          />
        )}
        <div className="admin-leads-solution-name-div">
          <span className="admin-leads-solution-name">
            {solution && solution.overview && solution.overview.name}
          </span>
        </div>
      </div>
    );
  }
}

export default SolutionDetailsCell;
