import React from 'react';
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter
} from '@cdk-uip/react-dialog';
import { Button } from '@cdk-uip/react-button';

class AcceptedPricing extends React.Component {
  render() {
    const selectedPlan = this.props.selectedPlan;

    return (
      <Dialog open={this.props.open} onCancel={this.props.onClose}>
        <DialogHeader>Here's Your Pricing Plan Details</DialogHeader>
        <DialogBody>
          {selectedPlan && selectedPlan.ratePlan.type === 'Tiered' ? (
            <div>
              <div style={{ color: 'black' }}>Transactional Plan</div>
              {selectedPlan.ratePlan.rates &&
                selectedPlan.ratePlan.rates.length &&
                selectedPlan.ratePlan.rates.map(rateItem => {
                  return (
                    <div style={{ fontSize: '12px', color: 'black' }}>
                      {rateItem.endUnit ? (
                        rateItem.startUnit > 0 ? (
                          <div>
                            ${rateItem.rate}/Call ({rateItem.startUnit + 1} -{' '}
                            {rateItem.endUnit} Calls/Month)
                          </div>
                        ) : (
                          <div>
                            ${rateItem.rate}/Call ({rateItem.startUnit} -{' '}
                            {rateItem.endUnit} Calls/Month)
                          </div>
                        )
                      ) : rateItem.startUnit > 0 ? (
                        <div>
                          ${rateItem.rate}/Call ({rateItem.startUnit + 1} -{' '}
                          Unlimited Calls/Month)
                        </div>
                      ) : (
                        <div>
                          ${rateItem.rate}/Call ({rateItem.startUnit} -{' '}
                          Unlimited Calls/Month)
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          ) : selectedPlan && selectedPlan.ratePlan.type === 'Subscription' ? (
            <div>
              <div style={{ color: 'black' }}>Subscription Plan</div>
              {selectedPlan.ratePlan.freemiumUnit ? (
                <div style={{ fontSize: '12px', color: 'black' }}>
                  Free upto {selectedPlan.ratePlan.freemiumUnit} Calls, then
                  Flat Rate Monthly plan of $
                  {selectedPlan.ratePlan.subscriptionPrice} per{' '}
                  {selectedPlan.ratePlan.frequencyDuration}{' '}
                  {selectedPlan.ratePlan.frequencyDurationType}
                </div>
              ) : (
                <div style={{ fontSize: '12px', color: 'black' }}>
                  Flat Rate Monthly plan of $
                  {selectedPlan.ratePlan.subscriptionPrice} per{' '}
                  {selectedPlan.ratePlan.frequencyDuration}{' '}
                  {selectedPlan.ratePlan.frequencyDurationType}
                </div>
              )}
            </div>
          ) : (
            <div>
              <div style={{ color: 'black' }}>Free Plan</div>
              <div style={{ fontSize: '12px', color: 'black' }}>
                There are unlimited free API calls per month with this plan.
              </div>
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.props.onClose();
            }}
            data-qa="close-btn"
            id="close-btn"
            isDefault
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default AcceptedPricing;
