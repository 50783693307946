import React, { useState, useEffect } from 'react';
import SolutionList from '../components/storefront/SolutionList';
import StoreListingAPI from '../api/StoreListingAPI';

function SolutionListAnonContainer(props) {
  const [loading, setLoading] = useState(false);
  const [anonSolutions, setSolutions] = useState([]);
  const [isDataFetched, setDataFetched] = useState(false);
  const [featuredSolutions, setFeaturedSolutions] = useState([]);
  const getSolutionsForAnon = () => {
    setLoading(true);
    StoreListingAPI.anonymous
      .getAllSolutions('all', null)
      .then(apiResponse => {
        setSolutions(apiResponse.data.data.paginatedSolutionCatalogList);
        setFeaturedSolutions(apiResponse.data.data.featuredSolutions);
        setLoading(false);
      })
      .catch(err => {});
  };
  useEffect(() => {
    if (!isDataFetched) {
      getSolutionsForAnon();
      setDataFetched(true);
    }
  });

  return (
    <SolutionList
      list={anonSolutions}
      featuredSolutionsList={featuredSolutions}
      loading={loading}
    />
  );
}
SolutionListAnonContainer.displayName = 'SolutionListAnonContainer';
export default SolutionListAnonContainer;
