import React, { useState, useEffect } from 'react';
import FilterGroup from './filters-and-sections/FilterGroup';
import ChipFieldSet from './filters-and-sections/ChipFieldSet';
import {
  CATEGORIES_SECTION_TITLE,
  DISCOVER_BY_SECTION_TITLE,
  PUBLISHERS_SECTION_TITLE,
  FREE_TRIAL_FILTER,
  NEWEST_FILTER,
  LOGGED_IN_DISCOVER_BY_FILTERS,
  ANON_DISCOVER_BY_FILTERS,
  PRIVATE_FILTER,
  PLUGIN,
  INTEGRATIONS_SECTION_TITLE,
  SEARCH_FILTER_URL_PARAMS,
  FEATURED_FILTER,
  INDUSTRY_SECTION_TITLE
} from './filters-and-sections/constants';
import SolutionsWithState from './solutionCard-and-solutionSection/SolutionsWithState';
import StoreListingAPI from '../../api/StoreListingAPI';
import LandingPageHero from './heroComponent-and-searchBox/LandingPageHero';
import LandingPageSearchBox from './heroComponent-and-searchBox/LandingPageSearchBox';
import { getApolloClient } from '../../containers/GraphQLClient';
import gql from 'graphql-tag';

import '@cdk-uip/icons';
import './heroComponent-and-searchBox/heroComponent-and-searchBox.scss';
import './NewLandingPage.scss';
import './solutionCard-and-solutionSection/SolutionCardGroup.scss';
import SkeletonWithState from './solutionCard-and-solutionSection/SkeletonWithState';

//redux imports
import { useSelector, useDispatch } from 'react-redux';
import {
  saveNewestAppsToStore,
  saveDiscoverMoreAppsToStore,
  displayShowMore,
  hideShowMore,
  newestAppsSelector,
  discoverMoreAppsSelector,
  showMoreSelector,
  featuredAppsSelector,
  saveFeaturedAppsToStore
} from '../../redux/connectedComponents/landingPage/landingPage.slice';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../utils/amplitude-constants';
import { sendAmplitudeData } from '../../utils/amplitude';

let localAuth = false;

const NewLandingPage = ({
  filterSections,
  auth,
  entity,

  ...otherProps
}) => {
  //redux bindings
  const newestApps = useSelector(newestAppsSelector);
  const discoverApps = useSelector(discoverMoreAppsSelector);
  const showMore = useSelector(showMoreSelector);
  const featuredApps = useSelector(featuredAppsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    localAuth = auth.isAuthenticated;

    const timer = setTimeout(() => {
      //check if apps are found in the store
      //if yes, then fetch apps from the store
      if (
        Array.isArray(newestApps) &&
        !newestApps.length &&
        window.location.search === ''
      ) {
        getNewestApps(auth);
      } else if (
        Array.isArray(newestApps) &&
        newestApps.length &&
        window.location.search === ''
      ) {
        setLoading(false);
      }
      if (
        Array.isArray(featuredApps) &&
        !featuredApps.length &&
        window.location.search === ''
      ) {
        getFeaturedApps(auth);
      }
      if (Array.isArray(discoverApps) && !discoverApps.length) {
        getDiscoverApps(auth);
      } else {
        setDiscoverAppsLoading(false);
      }
      getProvidersData(auth);
      getWorksWithData(auth);
      handleShowPrivateFilterinDiscoverBySection(auth);
    }, 500);
    return () => clearTimeout(timer);
  }, [auth.isAuthenticated]);

  const handleSearchParams = () => {
    window.sessionStorage.setItem('searchKey', window.location.search);
    const url = new URL(window.location);
    let filterSectionsObj = {};
    let SEARCH_FILTER_URL_PARAMS_REVERSED = {};
    let updatedPanels = openPanels;
    let filtersPresent = false;

    for (let key in SEARCH_FILTER_URL_PARAMS)
      SEARCH_FILTER_URL_PARAMS_REVERSED[SEARCH_FILTER_URL_PARAMS[key]] = key;

    for (const [key, value] of url.searchParams) {
      if (key !== 'Sr') {
        filterSectionsObj[SEARCH_FILTER_URL_PARAMS_REVERSED[key]] = {};
        let filterValues = value.split(',');

        if (key === 'Pub') {
          filterSections[SEARCH_FILTER_URL_PARAMS_REVERSED[key]] &&
            filterSections[SEARCH_FILTER_URL_PARAMS_REVERSED[key]]
              .filterArray &&
            filterSections[
              SEARCH_FILTER_URL_PARAMS_REVERSED[key]
            ].filterArray.map(filterObj => {
              if (filterValues.includes(filterObj.id)) {
                filterSectionsObj[SEARCH_FILTER_URL_PARAMS_REVERSED[key]][
                  filterObj.id
                ] = filterObj;
                if (
                  !openPanels[
                    filterSections[SEARCH_FILTER_URL_PARAMS_REVERSED[key]]
                  ]
                )
                  updatedPanels[
                    filterSections[
                      SEARCH_FILTER_URL_PARAMS_REVERSED[key]
                    ].sectionTitle
                  ] = true;
              }
            });
        } else if (key === 'Db' || 'Cr' || 'Int' || 'Ind') {
          filterSections[SEARCH_FILTER_URL_PARAMS_REVERSED[key]] &&
            filterSections[SEARCH_FILTER_URL_PARAMS_REVERSED[key]]
              .filterArray &&
            filterSections[
              SEARCH_FILTER_URL_PARAMS_REVERSED[key]
            ].filterArray.map(filterObj => {
              if (filterValues.includes(filterObj.name)) {
                filtersPresent = true;
                filterSectionsObj[SEARCH_FILTER_URL_PARAMS_REVERSED[key]][
                  filterObj.name
                ] = filterObj;
                if (
                  !openPanels[
                    filterSections[SEARCH_FILTER_URL_PARAMS_REVERSED[key]]
                  ]
                )
                  updatedPanels[
                    filterSections[
                      SEARCH_FILTER_URL_PARAMS_REVERSED[key]
                    ].sectionTitle
                  ] = true;
              }
            });
        }
      } else if (key === 'Sr') {
        setSearchText(value);
        if (value !== '') {
          setEnterKeyPress(true);
          setSearchTextPresent(true);
        }
      }
    }
    setOpenPanels({ ...updatedPanels });
    setCheckedOptions({ ...filterSectionsObj });

    if (!searchTextPresent && !filtersPresent) getNewestApps(auth);
  };

  const handleShowPrivateFilterinDiscoverBySection = authObj => {
    if (authObj.isAuthenticated) {
      filterSections[
        DISCOVER_BY_SECTION_TITLE
      ].filterArray = LOGGED_IN_DISCOVER_BY_FILTERS;
      setShowPrivateFilter(true);
    } else {
      filterSections[
        DISCOVER_BY_SECTION_TITLE
      ].filterArray = ANON_DISCOVER_BY_FILTERS;
      setShowPrivateFilter(false);
    }
  };

  const getStorefrontFilterValues = async () => {
    try {
      const client = getApolloClient();
      let queryResponse = await client.query({
        query: gql`
          query($filter: String!) {
            getStorefrontFilterValues(filterType: $filter) {
              name
              id
            }
          }
        `,
        variables: {
          filter: 'solutionProviders'
        }
      });
      return queryResponse;
    } catch (error) {
      console.log('Error while fetching App Providers!');
    }
  };

  const getWorksWithDataValues = async () => {
    try {
      const client = getApolloClient();
      let queryResponse = await client.query({
        query: gql`
          query {
            getWorksWithDataValues {
              friendlyName
              id
            }
          }
        `
      });
      return queryResponse;
    } catch (error) {
      console.log('Error while fetching Works With Values!');
    }
  };

  const getWorksWithData = async authObj => {
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      //authenticated user
      queryResponse = await getWorksWithDataValues();
    } else {
      //anon call
      try {
        let responseObj = await StoreListingAPI.anonymous.getWorksWithValues();
        if (responseObj && responseObj.data) {
          queryResponse = responseObj.data;
        }
      } catch (error) {
        console.log('Error occured while fetching works with data!', error);
      }
    }
    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.getWorksWithDataValues
    ) {
      filterSections[
        INTEGRATIONS_SECTION_TITLE
      ].filterArray = queryResponse.data.getWorksWithDataValues.map(
        worksWithValueObj => {
          worksWithValueObj['name'] = worksWithValueObj.friendlyName;
          return worksWithValueObj;
        }
      );
      makeWorksWithProvidersMapObject(
        queryResponse.data.getWorksWithDataValues
      );
      window.onpopstate = e => {
        window.sessionStorage.setItem('backButtonClicked', true);
      };
      let backButtonClicked = window.sessionStorage.getItem(
        'backButtonClicked'
      );
      if (
        window.location.search !== '' &&
        filterSections[PUBLISHERS_SECTION_TITLE].filterArray.length &&
        !backButtonClicked
      )
        handleSearchParams();
      window.sessionStorage.removeItem('backButtonClicked');
    } else {
      console.log('Error while fetching the works with values!');
    }
  };

  const getProvidersData = async authObj => {
    setProvidersLoading(true);
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      //authenticated user
      queryResponse = await getStorefrontFilterValues();
    } else {
      //anon service call
      let filterType = 'solutionProviders';
      try {
        let responseObj = await StoreListingAPI.anonymous.getSolutionProviders(
          filterType
        );
        queryResponse = responseObj.data;
      } catch (error) {
        console.log('Error Ocuurred while fetching providers data!', error);
      }
    }
    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.getStorefrontFilterValues
    ) {
      filterSections[PUBLISHERS_SECTION_TITLE].filterArray =
        queryResponse.data.getStorefrontFilterValues;
      if (
        window.location.search !== '' &&
        filterSections[INTEGRATIONS_SECTION_TITLE].filterArray.length
      )
        handleSearchParams();
      makeSolutionProvidersMapObject(
        queryResponse.data.getStorefrontFilterValues
      );
    } else {
      console.log('Error while fetching the App Providers data.');
    }
    setProvidersLoading(false);
  };

  const getFeaturedApps = async authObj => {
    setLoading(true);
    let searchParams = {
      searchOnly: false,
      size: 3,
      isFeatured: true
    };
    let filterParams = {
      order: 'desc',
      sortBy: 'lastUpdatedOn'
    };
    let queryResponse = {};
    try {
      if (authObj.isAuthenticated) {
        let responseObj = await getSolution(searchParams, filterParams);
        queryResponse =
          responseObj &&
          responseObj.data &&
          responseObj.data.searchStoreFrontSolution
            ? responseObj.data.searchStoreFrontSolution
            : null;
      } else {
        //anon call for featured solutions
        let payload = {
          ...searchParams,
          ...filterParams
        };
        //  try {
        let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
          payload
        );
        queryResponse = responseObj.data ? responseObj.data : null;
      }
      setIsErrorOccurred(false);
    } catch (error) {
      console.log('Error occurred while fetching data!', error);
      setIsErrorOccurred(true);
    }
    if (queryResponse && queryResponse.data) {
      dispatch(saveFeaturedAppsToStore(queryResponse.data));
      setLoading(false);
    } else {
      console.log('Error while fetching the Newest Apps');
    }
  };

  const getNewestApps = async authObj => {
    setLoading(true);

    let searchParams = {
      searchOnly: false,
      size: 3
    };
    let filterParams = {
      order: 'desc',
      sortBy: 'lastUpdatedOn'
    };
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      //authenticated user
      let responseObj = await getSolution(searchParams, filterParams);
      queryResponse =
        responseObj &&
        responseObj.data &&
        responseObj.data.searchStoreFrontSolution
          ? responseObj.data.searchStoreFrontSolution
          : null;
    } else {
      //anon call
      let payload = {
        ...searchParams,
        ...filterParams
      };
      try {
        let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
          payload
        );
        queryResponse = responseObj.data ? responseObj.data : null;
        setIsErrorOccurred(false);
      } catch (error) {
        console.log('Error occurred while fetching data!', error);
        setIsErrorOccurred(true);
      }
    }
    if (queryResponse && queryResponse.data) {
      dispatch(saveNewestAppsToStore(queryResponse.data));

      if (showMore) {
        dispatch(displayShowMore(showMore));
      } else {
        dispatch(hideShowMore(showMore));
      }

      setLoading(false);
    } else {
      console.log('Error while fetching the Newest Apps');
    }
  };

  const getDiscoverAppsWrapper = async (authObj, localAuth = null) => {
    if (localAuth === null) {
      getDiscoverApps(authObj);
    } else {
      authObj.isAuthenticated = localAuth;
      getDiscoverApps(authObj);
    }
  };

  const getDiscoverApps = async authObj => {
    //check if apps are found in the store
    if (Array.isArray(discoverApps) && !discoverApps.length) {
      let searchParams = {
        searchOnly: false,
        size: 9
      };
      let filterParams = {};
      let queryResponse = {};
      if (authObj.isAuthenticated) {
        //authenticated user
        let responseObj = await getSolution(searchParams, filterParams);
        queryResponse =
          responseObj &&
          responseObj.data &&
          responseObj.data.searchStoreFrontSolution
            ? responseObj.data.searchStoreFrontSolution
            : null;
      } else {
        //anon call
        let payload = {
          ...searchParams,
          ...filterParams
        };
        try {
          let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
            payload
          );
          queryResponse = responseObj.data ? responseObj.data : null;
          setIsErrorOccurred(false);
        } catch (error) {
          console.log('Error occurred while fetching data!', error);
          setIsErrorOccurred(true);
        }
      }
      if (queryResponse && queryResponse.data) {
        queryResponse.page * queryResponse.pageSize < queryResponse.total
          ? dispatch(displayShowMore())
          : dispatch(hideShowMore());

        dispatch(saveDiscoverMoreAppsToStore(queryResponse.data));
        if (showMore) {
          dispatch(displayShowMore(showMore));
        } else {
          dispatch(hideShowMore(showMore));
        }
        setFrom(queryResponse.page * queryResponse.pageSize);
        setDiscoverAppsLoading(false);
      } else {
        console.log('Error while fetching the Discover Apps');
      }
    }
  };

  const paginatedQueryForDiscoverApps = async (authObj, progressHandler) => {
    let searchParams = {
      searchOnly: false,
      size: 9,
      from: from
    };
    let filterParams = {};
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      //authenticated user
      let responseObj = await getSolution(
        searchParams,
        filterParams,
        progressHandler
      );
      queryResponse =
        responseObj &&
        responseObj.data &&
        responseObj.data.searchStoreFrontSolution
          ? responseObj.data.searchStoreFrontSolution
          : null;
    } else {
      //anon call
      let payload = {
        ...searchParams,
        ...filterParams
      };
      try {
        let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
          payload
        );
        queryResponse = responseObj.data;
        setIsErrorOccurred(false);
        if (typeof progressHandler === 'function') {
          progressHandler();
        }
      } catch (error) {
        console.log('Error occurred while fetching data!', error);
        setIsErrorOccurred(true);
        if (typeof progressHandler === 'function') {
          progressHandler();
        }
      }
    }
    if (queryResponse && queryResponse.data) {
      queryResponse.page * queryResponse.pageSize < queryResponse.total
        ? dispatch(displayShowMore())
        : dispatch(hideShowMore());

      let apps = discoverApps.concat(queryResponse.data);
      dispatch(saveDiscoverMoreAppsToStore(apps));
      if (showMore) {
        dispatch(displayShowMore(showMore));
      } else {
        dispatch(hideShowMore(showMore));
      }
      setFrom(queryResponse.page * queryResponse.pageSize);
    } else {
      console.log('Error while fetching more Discover Apps');
    }
  };

  const getSearchSolutionsWrapper = async (
    searchTextValue,
    authObj,
    localAuth = null
  ) => {
    if (localAuth === null) {
      getSearchSolutions(searchTextValue, authObj);
    } else {
      authObj.isAuthenticated = localAuth;
      getSearchSolutions(searchTextValue, authObj);
    }
  };

  const getSearchSolutions = async (searchTextValue, authObj) => {
    let searchParams = {
      searchText: searchTextValue,
      searchOnly: false,
      size: 9
    };
    let filterParams = generateQueryParametersForFilters();

    setLoading(true);
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      //authenticated user
      let responseObj = await getSolution(searchParams, filterParams);
      queryResponse =
        responseObj &&
        responseObj.data &&
        responseObj.data.searchStoreFrontSolution
          ? responseObj.data.searchStoreFrontSolution
          : null;
    } else {
      //anon call
      let payload = {
        ...searchParams,
        ...filterParams
      };
      try {
        let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
          payload
        );
        queryResponse = responseObj.data;
        setIsErrorOccurred(false);
      } catch (error) {
        console.log('Error occurred while fetching data!', error);
        setIsErrorOccurred(true);
      }
    }
    if (queryResponse && queryResponse.data) {
      queryResponse.page * queryResponse.pageSize < queryResponse.total
        ? dispatch(displayShowMore())
        : dispatch(hideShowMore());
      setSearchSolutions(queryResponse.data);
      setFrom(queryResponse.page * queryResponse.pageSize);
      setTotal(queryResponse.total);
      setLoading(false);
    } else {
      console.log('Error while fetching the Search Solutions');
    }
  };

  const paginatedQuery = async (authObj, progressHandler) => {
    dispatch(hideShowMore());
    let searchParams = {
      searchText: searchText,
      searchOnly: false,
      size: 9,
      from: from
    };
    let filterParams = generateQueryParametersForFilters();
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      //authenticated user
      let responseObj = await getSolution(
        searchParams,
        filterParams,
        progressHandler
      );
      queryResponse =
        responseObj &&
        responseObj.data &&
        responseObj.data.searchStoreFrontSolution
          ? responseObj.data.searchStoreFrontSolution
          : null;
    } else {
      //anon call
      let payload = {
        ...searchParams,
        ...filterParams
      };
      try {
        let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
          payload
        );
        queryResponse = responseObj.data;
        setIsErrorOccurred(false);
        if (typeof progressHandler === 'function') {
          progressHandler();
        }
      } catch (error) {
        console.log('Error occurred while fetching data!', error);
        setIsErrorOccurred(true);
        if (typeof progressHandler === 'function') {
          progressHandler();
        }
      }
    }
    if (queryResponse && queryResponse.data) {
      queryResponse.page * queryResponse.pageSize < queryResponse.total
        ? dispatch(displayShowMore())
        : dispatch(hideShowMore());
      setSearchSolutions(searchSolutions.concat(queryResponse.data));
      setFrom(queryResponse.page * queryResponse.pageSize);
      setTotal(queryResponse.total);
      setLoading(false);
    } else {
      console.log('Error while fetching more Search Solutions');
    }
  };

  const makeSolutionProvidersMapObject = solutionProvidersList => {
    var mapObj = {};
    solutionProvidersList.forEach(orgObj => {
      mapObj[orgObj['id']] = orgObj['name'];
    });

    setOrgMapObj(mapObj);
  };

  const makeWorksWithProvidersMapObject = worksWithValuesList => {
    let mapObj = {};
    worksWithValuesList.forEach(worksWithValueObj => {
      mapObj[worksWithValueObj['id']] = worksWithValueObj['friendlyName'];
    });

    setWorksWithValueMapObj(mapObj);
  };

  const [orgMapObj, setOrgMapObj] = useState({});
  const [worksWithValueMapObj, setWorksWithValueMapObj] = useState({});

  const [checkedFilters, setCheckedOptions] = useState({});
  const getDefaultOpenSections = () => {
    let defaultOpenSections = {};
    Object.values(filterSections).forEach(section => {
      if (section.isExpanded) {
        defaultOpenSections[section.sectionTitle] = section.isExpanded;
      }
    });
    return defaultOpenSections;
  };
  const [openPanels, setOpenPanels] = useState(() => getDefaultOpenSections());

  const onClearAll = () => {
    setCheckedOptions({});
    const url = new URL(window.location);
    const searchParam = url.searchParams.get('Sr');
    const searchString = String(window.location);
    const index = searchString.indexOf('?');
    const updatedUrl = searchString.substring(0, index);
    const newUrl = new URL(updatedUrl);
    if (searchParam !== null) newUrl.searchParams.set('Sr', searchParam);
    window.history.pushState({}, '', newUrl);
    window.sessionStorage.setItem('searchKey', window.location.search);
  };

  const handlePanelToggle = sectionTitle => {
    const isOpen = openPanels[sectionTitle];
    setOpenPanels({ ...openPanels, [sectionTitle]: !isOpen });
  };

  const addUrlSearchParams = (sectionTitle, name) => {
    const url = new URL(window.location);
    let searchParams = window.location.search;

    if (searchParams.includes(SEARCH_FILTER_URL_PARAMS[sectionTitle])) {
      let searchString = url.searchParams.get(
        SEARCH_FILTER_URL_PARAMS[sectionTitle]
      );
      searchString = searchString + ',' + name;
      url.searchParams.set(
        SEARCH_FILTER_URL_PARAMS[sectionTitle],
        searchString
      );
    } else {
      url.searchParams.set(SEARCH_FILTER_URL_PARAMS[sectionTitle], name);
    }

    window.history.pushState({}, '', url);
    window.sessionStorage.setItem('searchKey', window.location.search);
  };

  const removeUrlSearchParams = (
    sectionTitle,
    name,
    cdkPublishers,
    removeAllPublishers
  ) => {
    const url = new URL(window.location);
    let searchString = url.searchParams.get(
      SEARCH_FILTER_URL_PARAMS[sectionTitle]
    );

    if (sectionTitle === 'Publishers' && removeAllPublishers) {
      let publishers = searchString.split(',') || [];
      let newSearchString =
        publishers &&
        publishers.filter(id => !cdkPublishers.hasOwnProperty(id));
      if (newSearchString.length) {
        url.searchParams.set(
          SEARCH_FILTER_URL_PARAMS[sectionTitle],
          newSearchString
        );
      } else {
        url.searchParams.delete(SEARCH_FILTER_URL_PARAMS[sectionTitle]);
      }
    } else {
      if (searchString && searchString.includes(',')) {
        let commaAppendedNameFirst = ',' + name;
        let commaAppendedNameSecond = name + ',';
        if (searchString.includes(commaAppendedNameFirst)) {
          let newSearchString = searchString.replace(
            commaAppendedNameFirst,
            ''
          );
          url.searchParams.set(
            SEARCH_FILTER_URL_PARAMS[sectionTitle],
            newSearchString
          );
        } else if (searchString.includes(commaAppendedNameSecond)) {
          let newSearchString = searchString.replace(
            commaAppendedNameSecond,
            ''
          );
          url.searchParams.set(
            SEARCH_FILTER_URL_PARAMS[sectionTitle],
            newSearchString
          );
        } else {
          let newSearchString = searchString.replace(name, '');
          url.searchParams.set(
            SEARCH_FILTER_URL_PARAMS[sectionTitle],
            newSearchString
          );
        }
      } else {
        url.searchParams.delete(SEARCH_FILTER_URL_PARAMS[sectionTitle]);
      }
    }
    window.history.pushState({}, '', url);
    window.sessionStorage.setItem('searchKey', window.location.search);
  };

  const onFilterSelect = (
    checked,
    filterObj,
    sectionTitle,
    isClubbedPublishers,
    removeAllPublishers
  ) => {
    const isChecked = checked ? filterObj : false;
    {
      sectionTitle === 'Publishers'
        ? isClubbedPublishers
          ? setCheckedOptions({
              ...checkedFilters,
              [sectionTitle]: {
                ...checkedFilters[sectionTitle],
                ...filterObj
              }
            })
          : setCheckedOptions({
              ...checkedFilters,
              [sectionTitle]: {
                ...checkedFilters[sectionTitle],
                [filterObj.id]: isChecked
              }
            })
        : setCheckedOptions({
            ...checkedFilters,
            [sectionTitle]: {
              ...checkedFilters[sectionTitle],
              [filterObj.name]: isChecked
            }
          });
    }

    if (sectionTitle === 'Publishers') {
      if (checked) {
        if (isClubbedPublishers) {
          for (let key in filterObj) {
            addUrlSearchParams(sectionTitle, key);
          }
        } else {
          addUrlSearchParams(sectionTitle, filterObj.id);
        }
      } else {
        removeUrlSearchParams(
          sectionTitle,
          filterObj.id,
          filterObj,
          removeAllPublishers
        );
        if (!newestApps.length && window.location.search === '')
          getNewestApps(auth);
      }
    } else {
      if (checked) {
        addUrlSearchParams(sectionTitle, filterObj.name);
      } else {
        removeUrlSearchParams(sectionTitle, filterObj.name);
        const url = new URL(window.location);
        const discoverByParams = url.searchParams.get('Db');
        const filterValues = discoverByParams && discoverByParams.split(',');
        if (
          (!newestApps.length && window.location.search === '') ||
          (filterValues &&
            filterValues.includes('Private') &&
            filterValues.length === 1 &&
            !auth.isAuthenticated)
        )
          getNewestApps(auth);
      }
    }
    const amplitudeData = {
      ...checkedFilters,
      [sectionTitle]: {
        ...checkedFilters[sectionTitle],
        [filterObj.name]: isChecked
      }
    };
    logAmplitudeEvent(sectionTitle, amplitudeData);
  };

  const logAmplitudeEvent = (event, data) => {
    let amplitudeEvent, selectedFilters;
    let amplitudeData = {};
    const obj = data[event];

    selectedFilters =
      event !== PUBLISHERS_SECTION_TITLE
        ? Object.keys(obj)
            .filter(key => obj[key] !== false)
            .map(key => key)
        : [];
    switch (event) {
      case (event = INDUSTRY_SECTION_TITLE):
        amplitudeEvent = AMPLITUDE_EVENTS.SEARCH_BY_INDUSTRY;
        break;
      case (event = DISCOVER_BY_SECTION_TITLE):
        amplitudeEvent = AMPLITUDE_EVENTS.DISCOVER_BY_SEARCH;
        break;
      case (event = CATEGORIES_SECTION_TITLE):
        amplitudeEvent = AMPLITUDE_EVENTS.SEARCH_BY_CATEGORY;
        break;
      case (event = PUBLISHERS_SECTION_TITLE):
        amplitudeEvent = AMPLITUDE_EVENTS.SEARCH_BY_PUBLISHER;
        const filteredObj = {};
        for (const key in obj) {
          const entry = obj[key];
          if (entry !== false && !(entry.name && obj[entry.name] === false)) {
            filteredObj[key] = entry;
          }
        }
        selectedFilters = Object.values(filteredObj)
          .map(obj => obj.name)
          .filter(name => name !== undefined);
        break;
      case (event = INTEGRATIONS_SECTION_TITLE):
        amplitudeEvent = AMPLITUDE_EVENTS.SEARCH_BY_API_INTEGRATION;
        break;
    }
    amplitudeData[AMPLITUDE_PROPERTIES.SELECTED_FILTERS] = selectedFilters;
    amplitudeData[AMPLITUDE_PROPERTIES.ORG_ID] =
      entity && entity.id ? entity.id : 'NA';
    amplitudeData[AMPLITUDE_PROPERTIES.ORG_NAME] =
      entity && entity.name ? entity.name : 'NA';
    sendAmplitudeData(amplitudeEvent, amplitudeData);
  };

  const onChipRemove = (chip, sectionTitle) => {
    {
      sectionTitle === 'Publishers'
        ? setCheckedOptions({
            ...checkedFilters,
            [sectionTitle]: {
              ...checkedFilters[sectionTitle],
              [chip.id]: false
            }
          })
        : setCheckedOptions({
            ...checkedFilters,
            [sectionTitle]: {
              ...checkedFilters[sectionTitle],
              [chip.label]: false
            }
          });
    }

    if (sectionTitle === 'Publishers') {
      removeUrlSearchParams(sectionTitle, chip.id);
    } else {
      removeUrlSearchParams(sectionTitle, chip.label);
      const url = new URL(window.location);
      const discoverByParams = url.searchParams.get('Db');
      const filterValues = discoverByParams && discoverByParams.split(',');
      if (
        (!newestApps.length && window.location.search === '') ||
        (filterValues &&
          filterValues.includes('Private') &&
          filterValues.length === 1 &&
          !auth.isAuthenticated)
      )
        getNewestApps(auth);
    }
    window.sessionStorage.setItem('searchKey', window.location.search);
  };

  useEffect(() => {
    let selectedChips = getSelectedOptions();
    const isFiltersSelected = selectedChips.length > 0 ? true : false;
    const discTimer = setTimeout(() => {
      if (searchText !== '' || isFiltersSelected) {
        getSearchSolutionsWrapper(searchText, auth, localAuth);
      }
    }, 500);
    return () => clearTimeout(discTimer);
  }, [checkedFilters]);

  const getSelectedOptions = () => {
    const selectedChips = [];
    for (let [section, sectionValue] of Object.entries(checkedFilters)) {
      for (let [chip, value] of Object.entries(sectionValue)) {
        if (!!value) {
          selectedChips.push({
            label: `${value.name}`,
            id: `${value.id}`,
            section: section
          });
        }
      }
    }
    return selectedChips;
  };
  var selectedOptionsArray = getSelectedOptions();

  const generateQueryParametersForFilters = () => {
    let queryParamObj = {};
    //1.Categories
    let selectedCategories = [];
    if (checkedFilters && checkedFilters[CATEGORIES_SECTION_TITLE]) {
      let catergoriesSectionObject = checkedFilters[CATEGORIES_SECTION_TITLE];
      let interactedFilters = Object.keys(
        checkedFilters[CATEGORIES_SECTION_TITLE]
      );
      interactedFilters.forEach(filterName => {
        if (catergoriesSectionObject[filterName]) {
          selectedCategories.push(filterName);
        }
      });
    }
    queryParamObj['selectedCategories'] = selectedCategories;
    //2.providers
    let selectedProviders = [];
    if (checkedFilters && checkedFilters[PUBLISHERS_SECTION_TITLE]) {
      let providersSectionObject = checkedFilters[PUBLISHERS_SECTION_TITLE];
      let interactedFilters = Object.keys(
        checkedFilters[PUBLISHERS_SECTION_TITLE]
      );
      interactedFilters.forEach(filterName => {
        if (providersSectionObject[filterName]) {
          selectedProviders.push(providersSectionObject[filterName].id);
        }
      });
    }
    queryParamObj['selectedProviders'] = selectedProviders;

    //4.Works With Values
    let selectedWorksWithValues = [];
    if (checkedFilters && checkedFilters[INTEGRATIONS_SECTION_TITLE]) {
      let worksWithValuesSectionObject =
        checkedFilters[INTEGRATIONS_SECTION_TITLE];
      let interactedFilters = Object.keys(
        checkedFilters[INTEGRATIONS_SECTION_TITLE]
      );
      interactedFilters.forEach(filterName => {
        if (worksWithValuesSectionObject[filterName]) {
          selectedWorksWithValues.push(
            worksWithValuesSectionObject[filterName].id
          );
        }
      });
    }
    queryParamObj['selectedWorksWith'] = selectedWorksWithValues;

    let selectedDomains = [];
    if (checkedFilters && checkedFilters[INDUSTRY_SECTION_TITLE]) {
      let domainsSectionObject = checkedFilters[INDUSTRY_SECTION_TITLE];
      let interactedFilters = Object.keys(
        checkedFilters[INDUSTRY_SECTION_TITLE]
      );
      interactedFilters.forEach(filterName => {
        if (domainsSectionObject[filterName]) {
          selectedDomains.push(filterName);
        }
      });
    }
    queryParamObj['selectedDomains'] = selectedDomains;

    //3.discover-by: Free trail & Newest cases.
    if (checkedFilters && checkedFilters[DISCOVER_BY_SECTION_TITLE]) {
      let discoverbySectionObject = checkedFilters[DISCOVER_BY_SECTION_TITLE];
      if (discoverbySectionObject[FREE_TRIAL_FILTER]) {
        queryParamObj['isFreeTrial'] = true;
      }
      if (discoverbySectionObject[NEWEST_FILTER]) {
        queryParamObj['sortBy'] = 'lastUpdatedOn';
        queryParamObj['order'] = 'desc';
      }
      if (discoverbySectionObject[PRIVATE_FILTER]) {
        queryParamObj['isPrivate'] = true;
      }
      if (discoverbySectionObject[PLUGIN]) {
        queryParamObj['isPlugin'] = true;
      }
      if (discoverbySectionObject[FEATURED_FILTER]) {
        queryParamObj['isFeatured'] = true;
      }
    }
    return queryParamObj;
  };

  /**
   * generate a random uuid
   * Returns:
   *  random uuid
   */
  const getRandomId = () => {
    return (
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  };

  const getSolution = async (
    searchQueryParams,
    filterQueryParams,
    progressHandler
  ) => {
    try {
      const client = getApolloClient();
      let queryResponse = await client.query({
        query: gql`
          query(
            $id: String
            $size: Int
            $from: Int
            $sortBy: String
            $order: String
            $searchText: String
            $selectedCategories: [String]
            $selectedProviders: [String]
            $selectedDomains: [String]
            $searchOnly: Boolean
            $isFreeTrial: Boolean
            $isPrivate: Boolean
            $selectedWorksWith: [String]
            $isPlugin: Boolean
            $isFeatured: Boolean
          ) {
            searchStoreFrontSolution(
              filters: {
                id: $id
                size: $size
                from: $from
                sortBy: $sortBy
                order: $order
                searchText: $searchText
                selectedCategories: $selectedCategories
                selectedProviders: $selectedProviders
                selectedDomains: $selectedDomains
                searchOnly: $searchOnly
                isFreeTrial: $isFreeTrial
                isPrivate: $isPrivate
                selectedWorksWith: $selectedWorksWith
                isPlugin: $isPlugin
                isFeatured: $isFeatured
              }
            ) {
              page
              pageSize
              total
              data {
                id
                orgId
                extensibilityType
                lastUpdatedOn
                activateOnly
                plans {
                  isFreeTrialEnabled
                }
                solution_visibility {
                  solutionVisibilityType
                }
                overview {
                  name
                  category
                  domain
                  briefDescription
                  solutionLogo
                  featuredImage
                  publisherName
                }
                worksWithIds
                isFeatured
              }
            }
          }
        `,
        variables: {
          id: getRandomId(),
          ...searchQueryParams,
          ...filterQueryParams
        }
      });
      setIsErrorOccurred(false);
      if (typeof progressHandler === 'function') {
        progressHandler();
      }
      return queryResponse;
    } catch (error) {
      console.log('Error Ocuurred!', error);
      setIsErrorOccurred(true);
      if (typeof progressHandler === 'function') {
        progressHandler();
      }
    }
  };

  const [enterKeyPress, setEnterKeyPress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [providersLoading, setProvidersLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchSolutions, setSearchSolutions] = useState([]);
  const [discoverAppsLoading, setDiscoverAppsLoading] = useState(true);
  const [from, setFrom] = useState(0);
  const [total, setTotal] = useState(0);
  const [solutionClicked, setSolutionClicked] = useState(false);
  const [showPrivateFilter, setShowPrivateFilter] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const [searchTextPresent, setSearchTextPresent] = useState(false);

  let searchKey = window.sessionStorage.getItem('searchKey');
  if (searchKey !== null) {
    const newUrl = new URL(window.location);
    let searchParams = new URLSearchParams(searchKey);
    for (const [key, value] of searchParams) {
      newUrl.searchParams.set(key, value);
    }
    window.history.pushState({}, '', newUrl);
  }

  return (
    <div>
      <div className="max-width-resp-landing-page">
        <LandingPageHero
          auth={auth}
          title1="Discover "
          title2="Fortellis Apps"
          title3="that drive​ results for your dealership"
        />
        <LandingPageSearchBox
          data-qa="landing_page_search_box"
          setEnterKeyPress={setEnterKeyPress}
          setSearchText={e => {
            setSearchText(e);
            sendAmplitudeData(AMPLITUDE_EVENTS.SEARCH_BY_TEXT, {
              [AMPLITUDE_PROPERTIES.SEARCH_TEXT]: e,
              [AMPLITUDE_PROPERTIES.ORG_ID]: entity.id || 'NA',
              [AMPLITUDE_PROPERTIES.ORG_NAME]: entity.name || 'NA'
            });
          }}
          getSearchSolutions={getSearchSolutions}
          selectedOptionsArray={selectedOptionsArray}
          getDiscoverApps={getDiscoverApps}
          auth={auth}
          setSolutionClicked={setSolutionClicked}
          searchTextPresent={searchTextPresent}
          searchText={searchText}
        />
        <div className="cards-filters-container-landing-page">
          <div className="filters-section-container-nl">
            <FilterGroup
              filterSections={filterSections}
              checkedFilters={checkedFilters}
              onFilterSelect={onFilterSelect}
              openPanels={openPanels}
              onPanelToggle={handlePanelToggle}
              showPrivateFilter={showPrivateFilter}
            />
          </div>
          <div className="cards-section-container-nl">
            <ChipFieldSet
              onClearAll={onClearAll}
              onChipRemove={onChipRemove}
              chips={selectedOptionsArray}
              total={total}
              loading={loading}
              searchText={searchText}
            />
            {loading || providersLoading ? (
              isErrorOccurred ? (
                <div>
                  <div className="error_div">
                    <strong>
                      <span>Something went wrong. Check back soon!</span>
                      <br />
                      <br />
                      <span>If not logged in, try logging in!</span>
                    </strong>
                  </div>
                </div>
              ) : (
                <SkeletonWithState />
              )
            ) : (
              <SolutionsWithState
                enterKeyPress={enterKeyPress}
                loading={loading}
                selectedOptionsArray={getSelectedOptions()}
                discoverAppsLoading={discoverAppsLoading}
                discoverApps={discoverApps}
                newestApps={newestApps}
                paginatedQueryForDiscoverApps={paginatedQueryForDiscoverApps}
                paginatedQuery={paginatedQuery}
                showMore={showMore}
                searchText={searchText}
                searchSolutions={searchSolutions}
                auth={auth}
                orgMapObj={orgMapObj}
                solutionClicked={solutionClicked}
                worksWithValueMapObj={worksWithValueMapObj}
                featuredApps={featuredApps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLandingPage;
