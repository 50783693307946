export const modifyStr = str => {
  let length = str && str.length;
  let modifiedStr = '';
  for (var i = 0; i < length; i++) {
    let prevChar = i === 0 ? '' : str.charAt(i - 1);
    let currChar = str.charAt(i);
    if (prevChar !== '-') {
      modifiedStr += currChar;
    } else if (currChar !== '-') {
      modifiedStr += currChar;
    }
  }

  let modifiedStrLength = modifiedStr.length;
  //remove trailing hypen
  if (modifiedStr.charAt(modifiedStrLength - 1) === '-') {
    modifiedStr = modifiedStr.substring(0, modifiedStrLength - 1);
  }

  return modifiedStr;
};
