import SolutionStatus from '../common/SolutionStatus';

export const config = {
  formId: 'partner_program_registration_details',
  formTitle: 'API Integration',
  formValueType: 'Object',
  tabs: [
    {
      id: 'connectedApis',
      label: 'APIs',
      contents: [
        {
          id: 'connectedApis',
          fields: [
            {
              type: 'multiple_add_form',
              name: 'partner_api_details',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: function(status) {
                return (
                  !status ||
                  status === SolutionStatus.REGISTERED ||
                  status === SolutionStatus.RECALLED ||
                  status === SolutionStatus.REJECTED ||
                  status === SolutionStatus.REVOKED ||
                  status === SolutionStatus.DRAFT
                );
              },
              addLinkText: 'ADD APPLICATION INTEGRATION',
              formDefaultValue: {
                id: '',
                applicationName: '',
                description: '',
                dataProvider: ''
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: 'applicationName',
                        label: 'Application Name',
                        type: 'text_field',
                        maxLength: 40,
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        toolTipText:
                          'Business Application name that your app will connect. Eg: DMS , Websites'
                      },
                      {
                        name: 'description',
                        label: 'Description',
                        type: 'text_field',
                        maxLength: 128,
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        toolTipText:
                          'Describe how your app integrates with this application'
                      },
                      {
                        name: 'dataProvider',
                        label: 'Providers',
                        type: 'text_field',
                        maxLength: 40,
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        toolTipText:
                          'Specify the names of data provider or app provider that is supported by this app'
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    partner_program_registration_details: {
      partner_api_details: []
    }
  },
  activeTabId: 'connectedApis'
};
