import React from 'react';

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';

import { Title } from '@cdk-uip/react-typography';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { Card, CardText } from '@cdk-uip/react-card';
import { Button } from '@cdk-uip/react-button';
import { Chip } from '@cdk-uip/react-chip';
import { PersistentSearchBox } from '@cdk-uip/react-persistent-search-box';
import Spinner from '../../common/Spinner';
import SolutionLeadTable from './SolutionLeadTable';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import moment from 'moment';
import EmptyContent from '../../common/images/Empty State 2x.png';
import { FormattedMessage } from 'react-intl';

export class SolutionLeadsForAdmin extends React.Component {
  state = {
    tabIndex: 1,
    loadMore: false,
    solutionLeads: [],
    offset: '',
    fromDate: null,
    sortCol: 1,
    leadGenerationDateDescSortOrder: true,
    fetchingData: true,
    sorting: false,
    value: '',
    results: [],
    resultsToShow: [],
    selectedSolutionId: '',
    filterBySolution: false,
    fetchingSolutionLogo: false
  };

  handleChange = async e => {
    await this.setState({ value: e.target.value });
  };

  handleClear = () => {
    this.setState({ value: '', results: [], resultsToShow: [] });
    this.setState({ selectedSolutionId: '' });
    this.setState({ solutionLeads: [] });
    this.setState({ offset: '' });
    this.setState({ filterBySolution: true });
  };

  handleFetch = async value => {
    const client = getApolloClient();
    if (!value || value === '') {
      this.handleClear();
    } else {
      this.setState({
        resultsToShow: ['Loading...']
      });
      let results = [];
      let resultsToShow = [];
      if (value) {
        value = value.toLowerCase();
        let solutionListResponse = await client.query({
          query: getSolutionList,
          variables: {
            status: 'Published',
            searchText: value
          }
        });
        if (
          solutionListResponse &&
          solutionListResponse.data &&
          solutionListResponse.data.solutionList
        ) {
          solutionListResponse.data.solutionList.forEach(item => {
            results[item.overview.name] = item.id;
            resultsToShow.push(item.overview.name);
          });
        }
      }
      this.setState({
        resultsToShow: resultsToShow
      });
      this.setState({
        results: results
      });
    }
  };

  handleBack() {
    this.setState({ value: '', results: [], resultsToShow: [] });
    this.setState({ selectedSolutionId: '' });
    this.setState({ solutionLeads: [] });
    this.setState({ offset: '' });
    this.setState({ filterBySolution: true });
  }

  handleClick = async (value, index) => {
    this.setState({ value: value });
    this.setState({ selectedSolutionId: this.state.results[value] });
    this.setState({ solutionLeads: [] });
    this.setState({ offset: '' });
    this.setState({ filterBySolution: true });
  };

  filterLeads = async tabIndex => {
    await this.setState({
      fromDate:
        tabIndex === 1
          ? null
          : tabIndex === 2
          ? moment()
              .startOf('day')
              .toISOString()
          : tabIndex === 3
          ? moment()
              .day('Sunday')
              .toISOString()
          : tabIndex === 4
          ? moment()
              .startOf('month')
              .toISOString()
          : null
    });
    await this.setState({ solutionLeads: [], offset: '' });
    await this.fetchLeads();
    await this.setState({ fetchingData: false });
  };

  fetchLeads = async () => {
    let leadSolIds = [];
    let allLeads = [];
    await this.setState({ fetchingData: true });
    let leadsResponse = undefined;
    const client = getApolloClient();
    if (this.state.selectedSolutionId && this.state.selectedSolutionId !== '') {
      leadsResponse = await client.query({
        query: getLeadsBySolution,
        variables: {
          limit: 8,
          offset: this.state.offset,
          fromDate: this.state.fromDate,
          leadGenerationDateDescSortOrder: this.state
            .leadGenerationDateDescSortOrder,
          solutionId: this.state.selectedSolutionId
        }
      });
    } else {
      leadsResponse = await client.query({
        query: getLeads,
        variables: {
          limit: 8,
          offset: this.state.offset,
          fromDate: this.state.fromDate,
          leadGenerationDateDescSortOrder: this.state
            .leadGenerationDateDescSortOrder
        }
      });
    }

    if (
      leadsResponse &&
      leadsResponse.data &&
      leadsResponse.data.solutionLeads &&
      leadsResponse.data.solutionLeads.leads
    ) {
      let currLeads = this.state.solutionLeads;
      allLeads = [...currLeads, ...leadsResponse.data.solutionLeads.leads];
      leadSolIds = [
        ...currLeads.map(m => m.solutionId),
        ...leadsResponse.data.solutionLeads.leads.map(m => m.solutionId)
      ];

      if (currLeads && currLeads.length > 0) {
        await this.setState({
          solutionLeads: currLeads.concat(
            leadsResponse.data.solutionLeads.leads
          )
        });
      } else {
        await this.setState({
          solutionLeads: leadsResponse.data.solutionLeads.leads
        });
      }

      await this.setState({
        offset: leadsResponse.data.solutionLeads.pageInfo
          ? leadsResponse.data.solutionLeads.pageInfo.offset
          : null
      });
    }
    await this.setState({ fetchingData: false });

    if (leadSolIds.length > 0) {
      this.fetchSolutionLogo(leadSolIds, allLeads);
    }
  };

  fetchSolutionLogo = async (leadSolIds, leads) => {
    const client = getApolloClient();
    this.setState({ fetchingSolutionLogo: true });
    let queryResponse = await client.query({
      query: fetchSolutionLogo,
      variables: { solutionIds: leadSolIds }
    });

    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.solutionLists
    ) {
      const solLists = queryResponse.data.solutionLists;
      leads.forEach(ld => {
        const solution = solLists.find(sol => sol.id === ld.solutionId);
        if (!!solution) {
          ld.solution = solution;
        }
      });

      this.setState({
        solutionLeads: leads,
        fetchingSolutionLogo: false
      });
    }
  };

  onSort = async () => {
    this.setState({ sorting: true });
    await this.setState({
      solutionLeads: [],
      offset: '',
      leadGenerationDateDescSortOrder: !this.state
        .leadGenerationDateDescSortOrder
    });
    await this.fetchLeads();
    this.setState({ sorting: false });
  };

  sortDirection(col) {
    return this.state.sortCol === col
      ? this.state.leadGenerationDateDescSortOrder
        ? 'desc'
        : 'asc'
      : 'none';
  }

  componentDidUpdate = async () => {
    if (this.state.filterBySolution) {
      this.setState({ filterBySolution: false });
      this.fetchLeads();
    }
  };
  componentDidMount() {
    this.fetchLeads();
  }

  render() {
    return (
      <div className="component-content">
        <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
          <LayoutGridCell span={12} className="myaccount-component-content">
            <LayoutGrid className="fm-page-component-grid">
              <LayoutGridCell
                span={12}
                className="fm-page-content-title-cell max-width-resp"
              >
                <Title className="fm-page-content-title">
                  <span>
                    {' '}
                    <FormattedMessage
                      id="ExchangeHeaderResponsive.adminAccount.menu3"
                      defaultMessage="Leads"
                    />
                  </span>
                </Title>
              </LayoutGridCell>
            </LayoutGrid>
          </LayoutGridCell>
          <LayoutGridCell span={12}>
            <div className={'max-width-resp'}>
              <Card>
                <CardText className="lead-card-text">
                  <div className="lead-card-filters-div">
                    <div className="lead-card-chips-div">
                      <Chip
                        className={
                          this.state.tabIndex === 1
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={() => {
                          this.setState({ tabIndex: 1 });
                          this.filterLeads(1);
                        }}
                      >
                        All
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 2
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ tabIndex: 2 });
                          this.filterLeads(2);
                        }}
                      >
                        Today
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 3
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ tabIndex: 3 });
                          this.filterLeads(3);
                        }}
                      >
                        This Week
                      </Chip>
                      <Chip
                        className={
                          this.state.tabIndex === 4
                            ? 'category-chip category-chip-selected'
                            : 'category-chip'
                        }
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ tabIndex: 4 });
                          this.filterLeads(4);
                        }}
                      >
                        This Month
                      </Chip>
                    </div>
                    <div className="lead-card-search-box-div">
                      <PersistentSearchBox
                        label="Search by App"
                        value={this.state.value}
                        results={this.state.resultsToShow}
                        className="lead-card-search-box"
                        onChange={e => {
                          this.handleChange(e);
                        }}
                        onClear={() => {
                          this.handleClear();
                        }}
                        onFetch={value => {
                          this.handleFetch(value);
                        }}
                        onBack={() => {
                          this.handleBack();
                        }}
                        onClick={(value, index) => {
                          this.handleClick(value, index);
                        }}
                      />
                    </div>
                  </div>
                  {this.state.sorting ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    <LayoutGridCell span={12}>
                      {this.state.solutionLeads.length > 0 ? (
                        <div className="admin-lead-table max-width-resp">
                          <DataTable sortable className={'table-list'}>
                            <DataTableHeader>
                              <DataTableRow>
                                <DataTableHeaderCell
                                  sortable
                                  sortDirection={this.sortDirection(1)}
                                  onClick={() => this.onSort(1)}
                                  style={{ textAlign: 'left', width: '15%' }}
                                >
                                  Date
                                </DataTableHeaderCell>
                                <DataTableHeaderCell
                                  nonNumeric
                                  style={{ textAlign: 'left', width: '20%' }}
                                >
                                  App
                                </DataTableHeaderCell>
                                <DataTableHeaderCell
                                  style={{ textAlign: 'left', width: '15%' }}
                                >
                                  Lead
                                </DataTableHeaderCell>
                                <DataTableHeaderCell
                                  style={{ textAlign: 'left', width: '15%' }}
                                >
                                  Email
                                </DataTableHeaderCell>
                                <DataTableHeaderCell
                                  style={{ textAlign: 'left', width: '30%' }}
                                >
                                  Message
                                </DataTableHeaderCell>
                              </DataTableRow>
                            </DataTableHeader>
                            <DataTableBody>
                              {this.state.solutionLeads.length > 0 &&
                                this.state.solutionLeads.map(
                                  (lead, leadIdx) => {
                                    return (
                                      <SolutionLeadTable
                                        key={leadIdx}
                                        customClassName={
                                          'table-solution-lead-list'
                                        }
                                        solutionLead={lead}
                                        rowKey={leadIdx}
                                      />
                                    );
                                  }
                                )}
                              {this.state.fetchingData && (
                                <DataTableRow className="lead-row">
                                  <DataTableCell colSpan={5}>
                                    <div>
                                      <Spinner />
                                    </div>
                                  </DataTableCell>
                                </DataTableRow>
                              )}
                              {this.state.offset && (
                                <DataTableRow className="lead-row">
                                  <DataTableCell colSpan={5}>
                                    <Button
                                      className={'show-more-leads'}
                                      onClick={this.fetchLeads}
                                    >
                                      Show More Leads
                                    </Button>
                                  </DataTableCell>
                                </DataTableRow>
                              )}
                            </DataTableBody>
                          </DataTable>
                        </div>
                      ) : (
                        <div className="empty-content-div">
                          {this.state.fetchingData ? (
                            <div>
                              <Spinner />
                            </div>
                          ) : (
                            <div>
                              <img
                                alt="Empty Content"
                                className="empty-content-card-img"
                                src={EmptyContent}
                              />
                              <div className="empty-content-card-message">
                                No leads to show.
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </LayoutGridCell>
                  )}
                </CardText>
              </Card>
            </div>
          </LayoutGridCell>
        </LayoutGrid>
      </div>
    );
  }
}

const getLeads = gql`
  query(
    $limit: Float
    $offset: String
    $fromDate: String
    $leadGenerationDateDescSortOrder: Boolean
  ) {
    solutionLeads(
      filter: {
        limit: $limit
        offset: $offset
        fromDate: $fromDate
        leadGenerationDateDescSortOrder: $leadGenerationDateDescSortOrder
      }
    ) {
      leads {
        leadGenerationDate
        leadEmail
        userName
        message
        solutionId
      }
      pageInfo {
        offset
      }
    }
  }
`;

const getLeadsBySolution = gql`
  query(
    $limit: Float
    $offset: String
    $fromDate: String
    $leadGenerationDateDescSortOrder: Boolean
    $solutionId: ID
  ) {
    solutionLeads(
      filter: {
        limit: $limit
        offset: $offset
        fromDate: $fromDate
        leadGenerationDateDescSortOrder: $leadGenerationDateDescSortOrder
        solutionId: $solutionId
      }
    ) {
      leads {
        leadGenerationDate
        leadEmail
        userName
        message
        solutionId
      }
      pageInfo {
        offset
      }
    }
  }
`;
const getSolutionList = gql`
  query($status: String, $searchText: String) {
    solutionList(status: $status, searchText: $searchText) {
      id
      overview {
        name
      }
    }
  }
`;

const fetchSolutionLogo = gql`
  query($solutionIds: [ID]) {
    solutionLists(ids: $solutionIds) {
      id
      developerName
      overview {
        name
        solutionLogo
      }
    }
  }
`;

export default SolutionLeadsForAdmin;
