import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { Select } from '@cdk-uip/react-select';
import Spinner from '../../../common/Spinner';
import ValidationUtils from '../utils/ValidationUtils';

export class FormSelect extends React.Component {
  state = {
    value: this.props.value ? this.props.value : '',
    options: [],
    isValid: true,
    outOfFocus: false
  };

  constructor(props) {
    super(props);
    this.validationUtils = new ValidationUtils();
  }

  componentDidMount() {
    const { formFieldValues, fieldProperties } = this.props;
    if (
      !formFieldValues[fieldProperties.name] ||
      formFieldValues[fieldProperties.name] === '' ||
      (typeof formFieldValues[fieldProperties.name] === 'string' &&
        formFieldValues[fieldProperties.name].trim() === '')
    ) {
      this.validate();
    }
  }

  handleSelectChange = (value, name) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = value;
    this.props.onChange(this.resetDependent(value, formFieldValues));
    this.setState({ value: value });
    let isValid = this.validate();
    this.setState({
      isValid: isValid
    });
  };

  validate() {
    const formFieldValues = this.props.formFieldValues;
    let isValid = this.validationUtils.validate(
      this.props.fieldProperties,
      formFieldValues
    );
    this.props.validate &&
      this.props.validate(
        isValid,
        this.props.fieldProperties.name,
        this.props.activeFormId,
        this.props.activeTabId,
        this.props.activeSubFormId
      );
    return isValid;
  }

  resetDependent = (value, formFieldValues) => {
    if (this.props.fieldProperties.resetDependent) {
      let resetValues = this.props.fieldProperties.resetDependent(value);
      if (resetValues) {
        Object.keys(resetValues).forEach(key => {
          formFieldValues[key] = resetValues[key];
        });
      }
    }
    return formFieldValues;
  };

  generateOptionsFromCount = count => {
    let options = [];
    for (let i = 1; i <= count; i++) {
      options.push({
        name: i.toString(),
        value: i.toString()
      });
    }
    if (parseInt(this.props.value, 10) > this.props.formValueCount) {
      this.handleSelectChange(
        this.props.formValueCount.toString(),
        this.props.fieldProperties.name
      );
    } else if (this.state.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
    return options;
  };

  componentWillMount() {
    let options = this.props.fieldProperties.options;
    if (this.props.fieldProperties.optionFromCount) {
      options = this.generateOptionsFromCount(this.props.formValueCount);
    }
    this.setState({ options: options });
  }

  render() {
    const { fieldProperties } = this.props;
    if (
      this.props.formValueCount &&
      parseInt(this.state.value, 10) > this.props.formValueCount
    ) {
      return (
        <div className="save-loading-spinner">
          <Spinner />
        </div>
      );
    }
    const randomNumber = Math.random();

    return (
      <div className="form-field-with-tooltip">
        <Select
          className={this.props.fieldProperties.style}
          name={this.props.fieldProperties.name}
          id={this.props.fieldProperties.name}
          label={
            <span className="form-field-label">
              {this.props.fieldProperties.label}
              {this.props.fieldProperties.toolTipText && (
                <span>
                  <i
                    id={`tooltip_${
                      this.props.fieldProperties.name
                    }${randomNumber}`}
                    className="material-icons help-label-icon"
                  >
                    help
                  </i>
                </span>
              )}
            </span>
          }
          value={this.state.value}
          invalid={!this.state.isValid}
          disabled={this.props.fieldProperties.isDisabled}
          required={this.props.fieldProperties.required}
          onChange={event => {
            this.handleSelectChange(
              event.target.value,
              this.props.fieldProperties.name
            );
          }}
          onBlur={event => {
            this.handleSelectChange(
              event.target.value,
              this.props.fieldProperties.name
            );
            this.setState({ outOfFocus: true });
          }}
          onFocus={event => {
            this.handleSelectChange(
              event.target.value,
              this.props.fieldProperties.name
            );
            this.setState({ outOfFocus: false });
          }}
        >
          {this.state.options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </Select>
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${this.props.fieldProperties.name}${randomNumber}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>

        <div
          className={
            'mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg form-text-area-error'
          }
        >
          {!this.state.outOfFocus &&
            !this.state.isValid &&
            fieldProperties.validationErrorMessage}
        </div>
      </div>
    );
  }
}

export default FormSelect;
