import React from "react";
import { injectIntl } from "react-intl";

const color = {
  pending_activation: "#F3C118",
  pending_approval: "#F3C118",
  pending: "#F3C118",
  pending_configuration: "#F3C118",
  active: "#1e8700",
  activated: "#1e8700",
  not_started: "#BDBDBD",
  expired: "#CC0000",
  cancelled: "#BDBDBD",
  completed: "#1e8700"
};

class MySubscriptionStatus extends React.Component {
  render() {
    const { status, statusText } = this.props;
    return (
      <div>
        <span className="subscription-card-status-text">
          {statusText ? statusText : status}
        </span>
        <span
          style={{
            color:
              color[
                status
                  ? status.toLowerCase().replace(/ /g, "_")
                  : "pending_activation"
              ]
          }}
        >
          <i className="material-icons subscription-card-status-indicator">
            lens
          </i>
        </span>
      </div>
    );
  }
}

export default injectIntl(MySubscriptionStatus);
