import React from 'react';
import Dialog, {
  DialogFooter,
  DialogContent,
  DialogTitle
} from '@material/react-dialog';
import Button from '@material/react-button';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { TextArea } from '@cdk-uip/react-text-area';

const MAXLENGTH = 1000;

class ConsentTermsRejectionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      isFormError: false,
      consentFormErrorMessage: ''
    };
  }

  handleInputChange = e => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  onReject = async () => {
    const { reason } = this.state;
    let errorMessage = '';
    if (!reason) {
      errorMessage = 'Missing values for ';
      let emptyFields = [];
      if (!reason) {
        errorMessage = errorMessage + 'reason';
        emptyFields.push(this.nameInput);
      }
      this.highlightFields(emptyFields);
    }
    if (errorMessage) {
      this.setState({
        isFormError: true,
        consentFormErrorMessage: errorMessage
      });
    }

    if (reason) {
      const data = {
        consentStatus: 'Rejected',
        rejectionReason: reason
      };
      this.props.onSubmit(data);
    }
  };

  //1. highlight fields that are empty
  //2. set focus on first empty field
  highlightFields(fields) {
    if (Array.isArray(fields) && fields.length > 0) {
      for (const field of fields) {
        this.changeFocus(field);
      }
      this.changeFocus(fields[0]);
    }
  }

  changeFocus(input) {
    if (!input) return;
    const inputElement = input.inputElement;
    if (inputElement) {
      inputElement.focus();
    }
  }

  render() {
    return (
      <div className="consent-dialog-confirmation">
        <Dialog
          open={true}
          className={'consent_confirmation_dialog'}
          onClose={this.props.onClose}
        >
          <DialogTitle>Reason for Rejection</DialogTitle>
          <DialogContent>
            {this.state.isFormError ? (
              <div style={{ margin: '5px 0px' }}>
                <span style={{ color: 'red' }}>
                  Error: {this.state.consentFormErrorMessage}
                </span>
              </div>
            ) : null}
            <div>
              <div>
                <TextArea
                  id={'reason'}
                  required
                  value={this.state.reason}
                  onChange={this.handleInputChange}
                  ref={reasonInput => (this.reasonInput = reasonInput)}
                  maxLength={MAXLENGTH}
                />
                <div className="character-remaining-div">
                  {this.state.reason.length ? this.state.reason.length : 0} /{' '}
                  {MAXLENGTH}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogFooter>
            <Button onClick={() => this.props.onClose()}>Cancel</Button>
            <Button onClick={this.onReject}>Reject Terms</Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default withAuth(ConsentTermsRejectionDialog);
