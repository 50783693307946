import React from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import { FormattedMessage, injectIntl } from 'react-intl';

import { TextArea, TextButton, Dialog, BUTTON_VARIANTS } from 'cdk-radial';

class AddReviewResponse extends React.Component {
  state = {
    openReviewResponseInputs: false,
    submittedDialogOpen: false,
    reviewResponse: {
      parentId: this.props.parentId,
      authorName: this.props.authorName,
      authorEmail: this.props.authorEmail,
      solutionId: this.props.solutionId,
      reviewText: null
    }
  };

  openSubmittedDialog = () => {
    this.setState({ submittedDialogOpen: true });
  };
  closeSubmittedDialog = () => {
    this.setState({ submittedDialogOpen: false });
    this.props.onReviewResponseSubmitted(this.props.reviewId);
  };
  onSubmit = () => {
    this.callCreateReviewResponseMutation(this.state.reviewResponse).then(
      response => {
        if (!response.errors) {
          this.setState({ openReviewResponseInputs: false });
          this.openSubmittedDialog();
        }
      }
    );
  };

  callCreateReviewResponseMutation = async reviewResponse => {
    const client = getApolloClient();
    return await client.mutate({
      mutation: createReviewResponseMutation,
      variables: { reviewResponse: reviewResponse }
    });
  };

  onCancel = () => {
    let reviewResponseData = JSON.parse(
      JSON.stringify(this.state.reviewResponse)
    );
    reviewResponseData.reviewText = null;
    this.setState({
      openReviewResponseInputs: false,
      reviewResponse: reviewResponseData
    });
  };

  render() {
    return (
      <div id="add-review-response">
        {this.state.openReviewResponseInputs ? (
          <div className="review-form">
            <TextArea
              dataTestId="review-response-textarea"
              label="Type your review below"
              rows="5"
              maxLength=""
              value={
                this.state.reviewResponse.reviewText
                  ? this.state.reviewResponse.reviewText
                  : ''
              }
              onChange={e => {
                let reviewResponseData = JSON.parse(
                  JSON.stringify(this.state.reviewResponse)
                );
                reviewResponseData.reviewText =
                  e.target.value === '' ? null : e.target.value;
                this.setState({ reviewResponse: reviewResponseData });
              }}
            />
            <TextButton
              style={{ float: 'right', margin: '10px' }}
              dataTestId="review-response-submit-button"
              iconColor="primary"
              onClick={this.onSubmit}
              text="SUBMIT"
            />
            <TextButton
              style={{ float: 'right', margin: '10px' }}
              dataTestId="review-response-cancel-button"
              iconColor="primary"
              onClick={this.onCancel}
              text="CANCEL"
            />
          </div>
        ) : (
          !this.props.isPreview && (
            <div
              className="add-review-response-link"
              onClick={() => {
                this.setState({ openReviewResponseInputs: true });
              }}
            >
              <FormattedMessage
                id="AddReviewResponse.text1"
                defaultMessage="Reply to this Review"
              />
            </div>
          )
        )}

        <Dialog
          buttonsProps={[
            {
              id: 'add-review-response-confirm-button',
              onClick: this.closeSubmittedDialog,
              text: 'OK',
              variant: BUTTON_VARIANTS.TEXT
            }
          ]}
          id="add-review-response-dialog"
          isOpen={this.state.submittedDialogOpen}
          hideHeader
        >
          <div>
            <span className="review-delete-subheading">Reply submitted</span>
            <span className="review-delete-text">
              Your reply will be posted after the moderator has approved it..
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const createReviewResponseMutation = gql`
  mutation($reviewResponse: createReviewResponseCmd) {
    createReviewResponse(command: $reviewResponse) {
      id
      authorName
      authorEmail
      solutionId
      reviewText
      createdDate
      status
    }
  }
`;

export default injectIntl(withApollo(AddReviewResponse));
