import React from "react";
import { injectIntl } from "react-intl";
import { CardMedia } from "@cdk-uip/react-card";

class MySubscriptionListItemHeaderLogo extends React.Component {
  render() {
    const { image } = this.props;
    return (
      <CardMedia
        className="subscription-card-title-logo"
        scale="square"
        style={{
          backgroundImage: `url(${image})`
        }}
      />
    );
  }
}

export default injectIntl(MySubscriptionListItemHeaderLogo);
