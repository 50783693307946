import React from 'react';

import { Chip, ChipSet } from '@cdk-uip/react-chips';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';

class SolutionCategoryFilterChips extends React.Component {
  state = {
    selectedCategory: -1
  };

  render() {
    const { categoryFilters } = this.props;
    return (
      <LayoutGrid className="max-width-resp">
        <LayoutGridCell
          style={{ margin: 'auto', textAlign: 'center' }}
          span={12}
        >
          <ChipSet choise={'true'}>
            {categoryFilters.map((category, index) => {
              return (
                <Chip
                  key={category.name}
                  className={
                    this.state.selectedCategory === category.name
                      ? 'category-chip category-chip-selected'
                      : 'category-chip'
                  }
                  selected={this.state.selectedCategory === category.name}
                  onClick={e => {
                    e.preventDefault();
                    if (this.state.selectedCategory === category.name) {
                      this.setState({ selectedCategory: -1 });
                      this.props.filterSelectionChange(null);
                    } else {
                      this.setState({ selectedCategory: category.name });
                      this.props.filterSelectionChange(category.name);
                    }
                  }}
                >
                  {category.name}
                </Chip>
              );
            })}
          </ChipSet>
        </LayoutGridCell>
      </LayoutGrid>
    );
  }
}

export default SolutionCategoryFilterChips;
