import React from 'react';
import { CaptionText, Body2 } from '@cdk-uip/react-typography';

const FormStaticField = ({ fieldProperties, formFieldValues }) => (
  <div className="review-fields-div">
    <CaptionText
      className={`review-fields-label ${fieldProperties.labelstyle}`}
      data-cy={`lbl_${fieldProperties.label}`}
    >
      {fieldProperties.label}
    </CaptionText>
    <Body2 className={`${fieldProperties.style}  review-fields-value`}>
      {fieldProperties.showfieldvalue
        ? fieldProperties.value
        : typeof fieldProperties.value === 'function'
        ? fieldProperties.value(formFieldValues[fieldProperties.name])
        : formFieldValues[fieldProperties.name]}
    </Body2>
  </div>
);
export default FormStaticField;
