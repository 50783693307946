const AMPLITUDE_PROPERTIES = {
  ORG_ID: 'Org ID',
  ORG_NAME: 'Org Name',
  APP_ID: 'App ID',
  APP_NAME: 'App Name',
  API_ID: 'API ID',
  API_NAME: 'API Name',
  APP_LISTING_VERSION: 'App Listing Version',
  APP_LISTING_STATUS: 'App Listing Status',
  SUBSCRIPTION_ID: 'Subscription ID',
  ENTITY_ID: 'Entity ID',
  ENTITY_NAME: 'Entity Name',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  VISIBILITY: 'Visibility',
  APP_ACTIVATION: 'App Activation',
  PRICING_PLAN_ID: 'Pricing Plan ID',
  PRICING_PLAN_NAME: 'Pricing Plan Name',
  PRIVATE_SOLUTION_USER_EMAILS: 'Private Solution User Emails',
  SHARE_APP_URL: 'Share App URL',
  SEND_EMAIL_FLAG: 'Send Email Flag',
  SEARCH_TEXT: 'Search Text',
  SELECTED_FILTERS: 'Selected Filters'
};

const AMPLITUDE_EVENTS = {
  VIEW_APP_IN_MARKETPLACE: 'View App in Marketplace',
  VIEW_IN_MANAGE_LISTING_VERSIONS: 'View in Manage Listing Versions',
  EDIT_IN_MANAGE_LISTING_VERSIONS: 'Edit in Manage Listing Versions',
  PREVIEW_IN_MANAGE_LISTING_VERSIONS: 'Preview in Manage Listing Versions',
  SUBMIT_FOR_REVIEW_IN_MANAGE_LISTING_VERSIONS:
    'Submit for Review in Manage Listing Versions',
  WITHDRAWN_IN_MANAGE_LISTING_VERSIONS: 'Withdrawn in Manage Listing Versions',
  RECALL_IN_MANAGE_LISTING_VERSIONS: 'Recall in Manage Listing Versions',
  VIEW_IN_MARKETPLACE_MANAGE_LISTING_VERSIONS:
    'View in Marketplace Manage Listing Versions',
  CANCEL_ACTIVATION: 'Cancel Activation',
  DOWNLOAD_REPORT_IN_UTILIZATION: 'Download Report in Utilization',
  FILTER_CHANGE_IN_UTILIZATION: 'Filter Change in Utilization',
  SAVE_APP_LISTING: 'Save App Listing',
  PREVIEW_APP_LISTING: 'Preview App Listing',
  SUBMIT_APP_LISTING: 'Submit App Listing',
  PREVIEW_IN_CREATE_AN_APP_LISTING: 'Preview In Create an APP Listing',
  SAVE_IN_CREATE_AN_APP_LISTING: 'Save In Create an APP Listing',
  CANCEL_IN_CREATE_AN_APP_LISTING: 'Cancel In Create An APP Listing',
  SUBMIT_FOR_REVIEW_IN_CREATE_AN_APP_LISTING:
    'Submit For Review in Create An APP Listing',
  PRIVATE_SOLUTION_USER_EMAILS: 'Private Solution User Emails',
  REGISTER_TODAY: 'Register Today',
  SEARCH_BY_CATEGORY: 'Search By Category',
  SEARCH_BY_INDUSTRY: 'Search By Industry',
  SEARCH_BY_PUBLISHER: 'Search By Publisher',
  SEARCH_BY_API_INTEGRATION: 'Search By API Integration',
  DISCOVER_BY_SEARCH: 'Discover by Search',
  SEARCH_BY_TEXT: 'Search By Text',
  VIEW_APP: 'View App',
  ACTIVATE_AN_APP: 'Activate an App',
  SUBSCRIBE_TO_APP: 'Subscribe to App',
  SUBSCRIBE_TO_APP_PLAN: 'Subscribe to App Plan',
  VIEW_APP_OVERVIEW: 'View App Overview',
  VIEW_APP_INTEGRATIONS: 'View App Integrations',
  VIEW_APP_DOCUMENTATION: 'View App Documentation',
  VIEW_APP_PRICING: 'View App Pricing',
  VIEW_APP_REVIEWS: 'View App Reviews',
  APP_REVIEW: 'App Review',
  CONTACT_PUBLISER: 'Contact Publisher',
  VIEW_APP_PUBLISHER_WEBSITE: 'View App Publisher Website',
  VIEW_API: 'View API',
  CONTACTED_APP_PUBLISHER: 'Contacted App Publisher',
  APP_REVIEW_SUBMITTED: 'App Review Submitted',
  APP_REVIEW_SUBMISSION_SUCCESSFUL: 'App Review Submission Successful'
};

export { AMPLITUDE_PROPERTIES, AMPLITUDE_EVENTS };
