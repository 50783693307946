import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import NotFound from './common/NotFound';
import ErrorBoundary from './common/ErrorBoundary';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import SolutionList from '../containers/SolutionListContainer';
import MySolution from './marketplace-account/my-solutions/MySolution';
import MySolutionsView from './marketplace-account/my-solutions/MySolutionsView';
import ReviewSolutions from './admin-account/solution-moderation/SolutionsForAdmin';
import FakeLogin from './login/FakeLogin';
import AdminReview from './admin-account/review-moderation/AdminReview';
import SolutionDetailsScreen from '../containers/SolutionDetailsScreenContainer';
import AuthFooter from './common/footer/AuthFooter';
import SolutionLeadsForAdmin from './admin-account/admin-leads/SolutionLeadsForAdmin';
import SolutionSubscription from '../containers/SolutionSubscriptionContainer';
import ScrollToTop from './common/ScrollToTop';
import ApiConfigForSolution from '../containers/ApiConfigForSolutionContainer';
import BetaUserAccess from './admin-account/marketplace-access/marketplace-user-access';
import CustomPrivateRoute from './common/CustomPrivateRoute';
import { BrowserRouter as Router } from 'react-router-dom';
import GraphQLClient from '../containers/GraphQLClient';

import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import mktlocaleData from '../components/locales/data.json';
import MySubscriptions from './marketplace-account/MySubscriptions';
import MyProducts from './marketplace-account/my-solutions/MyProducts';
import SolutionManagement from '../containers/SolutionManagementContainer';
import SellPage from './sell/SellPageContainer';
import AllSubscriptions from './admin-account/all-subscriptions/AllSubscriptions';
import Breadcrumbs from './common/breadcrumbs/Breadcrumbs';
import routes from './Routes';
import SubscriptionManagement from './marketplace-account/my-solutions/solution-management/subscription-management-developer/SubscriptionManagement';

import EntityProvider from './entity-context/EntityProvider';
import AuthHeader from './common/header/AuthHeader';
import AuthSessionRefreshDialog from './common/sessionRefresh/AuthSessionRefreshDialog';
import { LocaleProvider, LocaleConsumer } from '@fortellis/locale';

import footerLocaleData from '@fortellis/footer/locales/data.js';
import headerLocaleData from '@fortellis/header/locales/data.json';
import FortellisConstants from './common/FortellisConstants';
import { default as SolutionAnonList } from '../containers/SolutionListAnonContainer';
import SolutionDetailsAnonScreen from '../containers/SolutionDetailsScreenAnonContainer';
import NewLandingPageContainer from '../components/new-landing-page/NewLandingPageContainer';
import SellInMarketplaceScreen from './sell-in-mp/SellInMarketplaceScreen';
import StripeRedirect from '../components/StripeRedirect';
import SubscriptionsData from './admin-account/subscriptions-data/SubscriptionsData';
import { ThemeProvider } from 'styled-components';
import { fortellisTheme } from 'cdk-radial';
import {
  getAuthContext,
  HideSignOut,
  withAuth
} from '@cdk-prod/fortellis-auth-context';
import TermsDialog from './terms/TermsDialog';
import { setAmplitudeUserProperties } from '../utils/amplitude';
import { AhaWidget } from '@cdk-prod/fortellis-aha-widget';

const localeData = {
  en: { ...mktlocaleData.en, ...headerLocaleData.en, ...footerLocaleData.en },
  fr: { ...mktlocaleData.fr, ...headerLocaleData.fr, ...footerLocaleData.fr }
};

addLocaleData([...en, ...fr]);

const AppContext = React.createContext();
export const AppContextConsumer = AppContext.Consumer;

const env = {
  issuer: process.env.REACT_APP_AUTH_ISSUER,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false
};

const applicationId = process.env.REACT_APP_PLATFORM_APPLICATIONID_FORTELLIS;
const isAhaEnabled =
  process.env.REACT_APP_FEATURE_AHA_WIDGET_ENABLED === 'true';

const AhaWidgetWithAuth = withAuth(AhaWidget);

class Root extends React.Component {
  state = {
    permissions: null,
    entity: '',
    selectedLang: 'en',
    mobile: false
  };

  breadcrumbRef = React.createRef();

  componentDidMount() {
    this.mounted = true;
    this.updateMobile();
    window.addEventListener('resize', this.updateMobile);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updateMobile);
  }

  updateMobile = () => {
    if (!this.mounted) return;

    if (window.innerWidth <= 1039) {
      if (!this.state.mobile) {
        this.setState({ mobile: true });
      }
    } else {
      if (this.state.mobile) this.setState({ mobile: false });
    }
  };

  setPermissionsAndEntity = (
    permissions,
    entity = { id: FortellisConstants.DEFAULT_ORG_ID }
  ) => {
    this.setState({
      permissions: permissions,
      entity: entity
    });
    setAmplitudeUserProperties('Organization ID', this.state.entity.id);
  };

  checkIfUserPermissionsIncludeElement = currentElement => {
    return this.state.permissions.includes(currentElement);
  };

  doesPermissionsInclude = requiredPermissionsArray => {
    if (this.state.permissions && this.state.permissions.length > 0) {
      return requiredPermissionsArray.every(
        this.checkIfUserPermissionsIncludeElement
      );
    } else {
      return false;
    }
  };

  onAuthenticated = isAuthenticated => {
    this.setState({
      isAuthenticated: isAuthenticated
    });
  };

  render() {
    const { permissions, entity, isAuthenticated } = this.state;
    const isPermissionReceived = !!permissions;
    //way to pass permissions to your components
    //use render instead component like this ->
    //render={(props) => <SolutionDetailsScreen userPermissions={permissions} {...props}/>}

    if (window.location.pathname === '/signingPreviewComplete') {
      return (
        <div className={'App__signingPreviewComplete'}>
          <strong>Signing/Preview Complete</strong>
        </div>
      );
    }

    if (window.location.pathname === '/billingRedirect') {
      return <StripeRedirect />;
    }

    return (
      <>
        <ThemeProvider theme={fortellisTheme}>
          <LocaleProvider>
            <LocaleConsumer>
              {localeContext => (
                <IntlProvider
                  locale={localeContext.locale}
                  messages={localeData[localeContext.locale]}
                >
                  <ScrollToTop>
                    <Security
                      oktaAuth={this.props.oktaAuth}
                      restoreOriginalUri={this.props.restoreOriginalUri}
                    >
                      <GraphQLClient>
                        <ErrorBoundary>
                          <div>
                            <EntityProvider>
                              <HideSignOut />
                              {isAhaEnabled && (
                                <AhaWidgetWithAuth
                                  applicationId={applicationId}
                                />
                              )}
                              <AuthSessionRefreshDialog />
                              <AuthHeader
                                mobile={this.state.mobile}
                                onPermissionReception={
                                  this.setPermissionsAndEntity
                                }
                                onAuthenticated={this.onAuthenticated}
                              />
                              <TermsDialog />

                              <div ref={this.breadcrumbRef}>
                                <Breadcrumbs
                                  isPermissionReceived={isPermissionReceived}
                                  routes={routes}
                                  separatorIcon="keyboard_arrow_right"
                                />
                              </div>
                              <div
                                className={
                                  isAuthenticated
                                    ? 'appjs-page-container'
                                    : 'appjs-page-container-anon'
                                }
                              >
                                <AppContext.Provider
                                  value={{
                                    refs: {
                                      breadcrumbRef: this.breadcrumbRef
                                    }
                                  }}
                                >
                                  <Switch>
                                    {/* Use Route for openly accessible pages
                                      PrivateRoute for pages requiring authentication
                                      CustomPrivateRoute for pages requiring special permissions
                                    */}

                                    <Route
                                      path={routes['home'].path}
                                      exact
                                      render={props => (
                                        <NewLandingPageContainer
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                    />

                                    <Route
                                      exact
                                      path={'/login/callback'}
                                      component={LoginCallback}
                                    />

                                    <Route
                                      path={routes['old-landing-page'].path}
                                      exact
                                      component={
                                        isAuthenticated
                                          ? SolutionList
                                          : SolutionAnonList
                                      }
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Browse']
                                      )}
                                      canRequestAccess={true}
                                    />

                                    <Route
                                      path={routes['marketplace-account'].path}
                                      exact
                                      render={() => <Redirect to={`/`} />}
                                    />

                                    {/* sequence of Routes with same base path matters*/}
                                    <Route
                                      path={routes['all-solutions'].path}
                                      exact
                                      render={() => <Redirect to={`/`} />}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['plan-and-pricing'].path}
                                      render={props => (
                                        <SolutionSubscription
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Subscribe']
                                      )}
                                    />

                                    <Route
                                      path={routes['solution-details'].path}
                                      render={props =>
                                        isAuthenticated ? (
                                          <SolutionDetailsScreen
                                            userPermissions={permissions}
                                            entity={entity}
                                            {...props}
                                          />
                                        ) : (
                                          <SolutionDetailsAnonScreen
                                            userPermissions={permissions}
                                            entity={entity}
                                            {...props}
                                          />
                                        )
                                      }
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Browse']
                                      )}
                                    />

                                    <Route
                                      path={routes['solution-details-old'].path}
                                      render={props =>
                                        isAuthenticated ? (
                                          <SolutionDetailsScreen
                                            userPermissions={permissions}
                                            entity={entity}
                                            isOldUrl={true}
                                            {...props}
                                          />
                                        ) : (
                                          <SolutionDetailsAnonScreen
                                            userPermissions={permissions}
                                            entity={entity}
                                            isOldUrl={true}
                                            {...props}
                                          />
                                        )
                                      }
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Browse']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['admin-solutions'].path}
                                      render={props => (
                                        <ReviewSolutions
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Admin']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['admin-subscriptions'].path}
                                      component={AllSubscriptions}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Admin']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['admin-leads'].path}
                                      component={SolutionLeadsForAdmin}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Admin']
                                      )}
                                      canRequestAccess={false}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['solution-reviews'].path}
                                      component={AdminReview}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Admin']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['subscriptions-data'].path}
                                      component={SubscriptionsData}
                                      render={SubscriptionsData}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Admin']
                                      )}
                                    />

                                    {/*test in prod where there is a subscription for entity*/}
                                    <CustomPrivateRoute
                                      path={routes['configure-apis'].path}
                                      render={props => (
                                        <ApiConfigForSolution
                                          {...props}
                                          userPermissions={permissions}
                                          entity={entity}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Subscribe', 'ConfigureSubscription']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={
                                        routes[
                                          'manage-listing-complete-provisioning'
                                        ].path
                                      }
                                      exact={true}
                                      component={SubscriptionManagement}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit', 'CompleteProvisioning']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={
                                        routes['manage-listing-subscriptions']
                                          .path
                                      }
                                      exact={true}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                      render={props => (
                                        <SolutionManagement
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['manage-listing'].path}
                                      exact={true}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                      render={props => (
                                        <SolutionManagement
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      exact={true}
                                      path={routes['manage-listing-view'].path}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                      render={props => (
                                        <MySolutionsView
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={
                                        routes[
                                          'solution-submission-listing-version'
                                        ].path
                                      }
                                      render={props => (
                                        <MySolution
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['solution-submission'].path}
                                      render={props => (
                                        <MySolution
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                    />

                                    <CustomPrivateRoute
                                      path={
                                        routes['solution-submission-with-type']
                                          .path
                                      }
                                      render={props => (
                                        <MySolution
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                    />
                                    <CustomPrivateRoute
                                      path={
                                        routes['solution-submission-preview']
                                          .path
                                      }
                                      render={props => (
                                        <MySolution
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                    />
                                    <CustomPrivateRoute
                                      path={routes['my-subscriptions'].path}
                                      render={props => (
                                        <MySubscriptions
                                          userPermissions={permissions}
                                          entity={entity}
                                          {...props}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Subscribe']
                                      )}
                                      canRequestAccess={true}
                                    />

                                    <CustomPrivateRoute
                                      path={
                                        routes['solution-details-with-type']
                                          .path
                                      }
                                      render={props => (
                                        <MyProducts
                                          userPermissions={permissions}
                                          {...props}
                                          entity={entity}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                      canRequestAccess={true}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['my-solutions'].path}
                                      render={props => (
                                        <MyProducts
                                          userPermissions={permissions}
                                          {...props}
                                          entity={entity}
                                        />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                      canRequestAccess={true}
                                    />

                                    <CustomPrivateRoute
                                      path={routes['beta-access-approval'].path}
                                      component={BetaUserAccess}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Admin']
                                      )}
                                    />
                                    <CustomPrivateRoute
                                      path="/sell-rj4KHvaurUXMkdH8Tg"
                                      render={props => (
                                        <SellPage {...props} entity={entity} />
                                      )}
                                      isPermissionsReceived={
                                        isPermissionReceived
                                      }
                                      isAccessible={this.doesPermissionsInclude(
                                        ['Submit']
                                      )}
                                      canRequestAccess={false}
                                    />
                                    {process.env.NODE_ENV === 'development' ? (
                                      <Route
                                        path={routes['fake-login'].path}
                                        component={FakeLogin}
                                      />
                                    ) : (
                                      false
                                    )}
                                    <SecureRoute
                                      path="*"
                                      component={NotFound}
                                    />
                                  </Switch>
                                </AppContext.Provider>
                              </div>
                            </EntityProvider>
                            <div className="clear-both" />
                            <AuthFooter mobile={this.state.mobile} />
                          </div>
                        </ErrorBoundary>
                      </GraphQLClient>
                    </Security>
                  </ScrollToTop>
                </IntlProvider>
              )}
            </LocaleConsumer>
          </LocaleProvider>
        </ThemeProvider>
      </>
    );
  }
}

const RootWithRouterAccess = withRouter(
  getAuthContext({ WrappedComponent: Root, env })
);

class App extends React.Component {
  render() {
    return (
      <Router>
        <RootWithRouterAccess />
      </Router>
    );
  }
}

export default App;
