export const ProvisioningConstants = {
  status: {
    NOT_STARTED: 'Not Started',
    PENDING: 'Pending',
    COMPLETED: 'Completed'
  },
  type: {
    REDIRECT_URL: 'redirectURL',
    ENDPOINT_URL: 'endpointUrl',
    MANUAL: 'manual',
    SUBSCRIPTION_ID: 'subscriptionID',
    ENTITY_ID: 'entityID'
  }
};

export default ProvisioningConstants;
