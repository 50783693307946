import React from "react";
import { injectIntl } from "react-intl";

class MySubscriptionFreeLabel extends React.Component {
  render() {
    return (
      <div className="subscription-card-title-label">
        <span className="subscription-card-title-label-text">Free Trial</span>
      </div>
    );
  }
}

export default injectIntl(MySubscriptionFreeLabel);
