import React from 'react';
import { Avatar } from '@cdk-uip/react-avatar';
import BackIcon from '../common/images/media_left_arrow.svg';
import ForwardIcon from '../common/images/media_right_arrow.svg';
import Card from '@material/react-card';
import { injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import { getApolloClient } from '../../containers/GraphQLClient';
import Spinner from '../common/Spinner';
import StoreListingAPI from '../../api/StoreListingAPI';
import './extensibility.scss';

const BACK_ARW_ENB = 'extensibility_back-arrow',
  FORW_ARW_ENB = 'extensibility_next-arrow';

export const searchExtensibilitySolution = gql`
  query($solutionId: ID!) {
    searchExtensibilitySolution(filters: { solutionId: $solutionId }) {
      data {
        id
        orgId
        overview {
          name
          category
          version
          briefDescription
          detailedDescription
          publisherName
          solutionLogo
        }
        solution_visibility {
          solutionVisibilityType
        }
      }
    }
  }
`;

class ExtensibilityApps extends React.Component {
  state = {
    showNavigation: true,
    showLeftNavArrow: false,
    showRighNavArrow: true,
    maxScrollLeftValue: -1
  };

  componentDidUpdate(prevProps, prevState) {
    this.updateScrollWidthAndClientWidth(
      prevState.scrollWidth,
      prevState.clientWidth
    );
  }

  updateScrollWidthAndClientWidth = (scrollWidth, clientWidth) => {
    let elmnt = document.getElementById('extensibility-row');
    if (!elmnt) {
      return null;
    }
    if (
      elmnt.scrollWidth !== scrollWidth ||
      elmnt.clientWidth !== clientWidth
    ) {
      this.setState({
        scrollWidth: elmnt.scrollWidth,
        clientWidth: elmnt.clientWidth
      });
      this.showNavigationHandler();
    }
  };

  showNavigationHandler = () => {
    let maxScrollLeftValue = this.state.scrollWidth - this.state.clientWidth;
    if (maxScrollLeftValue <= 0) {
      this.setState({ showNavigation: false });
    } else {
      this.setState({
        showNavigation: true,
        maxScrollLeftValue: maxScrollLeftValue
      });
    }
  };

  rightArrowClickHandler = () => {
    let elmnt = document.getElementById('extensibility-row');
    elmnt.scrollLeft = elmnt.scrollLeft + elmnt.clientWidth;
  };

  leftArrowClickHandler = () => {
    let elmnt = document.getElementById('extensibility-row');
    elmnt.scrollLeft = elmnt.scrollLeft - elmnt.clientWidth;
  };

  onscroll = () => {
    let elmnt = document.getElementById('extensibility-row');
    if (elmnt.scrollLeft === 0) {
      this.setState({ showLeftNavArrow: false });
    } else {
      this.setState({ showLeftNavArrow: true });
    }

    if (
      Math.floor(elmnt.scrollLeft) >= Math.floor(this.state.maxScrollLeftValue)
    ) {
      this.setState({ showRighNavArrow: false });
    } else {
      this.setState({ showRighNavArrow: true });
    }
  };

  getApps = async () => {
    let { isAuthenticated, solutionId } = this.props;
    if (isAuthenticated) {
      let client = getApolloClient();
      return await client.query({
        query: searchExtensibilitySolution,
        variables: {
          solutionId: solutionId
        }
      });
    } else {
      let { data } = await StoreListingAPI.anonymous.getExtensibilityApp(
        solutionId
      );
      return data;
    }
  };

  async componentDidMount() {
    let { data: { searchExtensibilitySolution } = {} } = await this.getApps();
    this.setState({
      apps: searchExtensibilitySolution
        ? searchExtensibilitySolution.data
        : null
    });
  }

  render() {
    let {
      apps,
      showNavigation,
      showLeftNavArrow,
      showRighNavArrow
    } = this.state;
    return (
      <div className="extensibility" onScroll={this.onscroll}>
        {showNavigation && showLeftNavArrow && (
          <Avatar className="extensibility_navigation-arrow">
            <img
              src={BackIcon}
              className={BACK_ARW_ENB}
              onClick={this.leftArrowClickHandler}
            />
          </Avatar>
        )}
        <div id="extensibility-row" className="extensibility-grid">
          <div className="extensibility-row">
            {!apps && (
              <div>
                <Spinner />
              </div>
            )}
            {apps &&
              apps.map(solution => (
                <Card
                  className="extensibility-card__component"
                  data-qa="solution_card"
                >
                  <div className="extensibility-card__top_div">
                    <div className="extensibility-card__logo_freeTrial_div">
                      {solution.overview.solutionLogo ? (
                        <img
                          src={`${
                            solution.overview.solutionLogo
                          }?${Math.random()}`}
                          className="extensibility-card__logo"
                          alt=""
                        />
                      ) : (
                        <img
                          className="extensibility-card__default_logo"
                          alt=""
                        />
                      )}

                      {solution.solution_visibility &&
                      solution.solution_visibility.solutionVisibilityType ===
                        'Private' ? (
                        <div className="extensibility-card__private-badge-icon-div">
                          <i className="material-icons">lock</i>
                        </div>
                      ) : (
                        false
                      )}
                    </div>

                    <div className="extensibility-card__solutionTitle_div">
                      {solution.overview.name}
                    </div>

                    {true ? (
                      <div className="extensibility-card__solutionDeveloper_div">
                        {`By cdk`}
                      </div>
                    ) : (
                      <div className="extensibility-card__solutionDeveloper_div" />
                    )}

                    <div className="extensibility-card__solutionDesciption_div">
                      {solution.overview.briefDescription}
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </div>
        {showNavigation && showRighNavArrow && (
          <Avatar className="extensibility_navigation-arrow">
            <img
              src={ForwardIcon}
              className={FORW_ARW_ENB}
              onClick={this.rightArrowClickHandler}
            />
          </Avatar>
        )}
      </div>
    );
  }
}

export default injectIntl(ExtensibilityApps);
