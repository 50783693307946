import React from 'react';
import { Chip } from 'cdk-radial';
import './ChipFieldSet.scss';
import NoResults from './NoResults';

const ChipFieldSet = ({
  chips,
  onChipRemove,
  onClearAll,
  total,
  searchText,
  loading,
  ...others
}) => {
  let resultText = searchText !== '' ? `Results for ${searchText}` : 'Results';
  return (
    <div className="chips-container-nl">
      {!loading && total > 0 && (chips.length > 0 || searchText !== '') ? (
        <span className="results-span-nl">{`${resultText} (${total})`}</span>
      ) : (
        false
      )}

      {!loading && total === 0 && searchText === '' && chips.length > 0 ? (
        <span className="results-span-nl">{`${resultText} (${total})`}</span>
      ) : (
        false
      )}

      {!loading &&
        chips.map((chip, index) => {
          return (
            <Chip
              className="chip-nl"
              key={`chip-${chip.label}-${index}`}
              id={`chip-${chip.label}-${index}`}
              label={chip.label}
              onRemove={() => {
                onChipRemove(chip, chip.section);
              }}
            />
          );
        })}

      {!loading && chips.length > 1 ? (
        <span className="clear-all-nl" onClick={onClearAll}>
          Clear All
        </span>
      ) : (
        false
      )}

      {!loading && total === 0 && searchText !== '' ? (
        <NoResults searchText={searchText} />
      ) : (
        false
      )}
    </div>
  );
};

export default ChipFieldSet;
