class ValidationUtils {
  validate = (
    fieldProperties,
    formFieldValues,
    formValueCount,
    formFieldValuesAll,
    isDuplicateSolution
  ) => {
    const URLRegex = /^(http|https):\/\/(www\.)?(?!\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,7}(:[-a-zA-Z0-9]{1,10})?(\/.*)?$/i;

    if (fieldProperties.validationType === 'non_zero_positive_number') {
      var imagePositionIndexCount = 0;
      formFieldValuesAll.overview.overview.galleryImages.map(galleryImage => {
        if (
          galleryImage.imagePositionIndex ===
          formFieldValues[fieldProperties.name]
        )
          imagePositionIndexCount++;
      });
      return (
        formFieldValues[fieldProperties.name] > 0 &&
        formFieldValues[fieldProperties.name] <= formValueCount &&
        imagePositionIndexCount === 1
      ); //true - meaning valid, false - meaning invalid
    }

    if (fieldProperties.validationType === 'support_center_url') {
      if (
        !formFieldValues.supportCenterUrl ||
        formFieldValues.supportCenterUrl === ''
      ) {
        return true;
      }
      let regex = URLRegex;
      return regex.test(formFieldValues.supportCenterUrl);
    }
    if (fieldProperties.validationType === 'phone_number') {
      if (!formFieldValues.phoneNumber || formFieldValues.phoneNumber === '') {
        return true;
      }
      let regex = /^[0-9]{10}$/;
      return regex.test(formFieldValues.phoneNumber);
    }

    if (fieldProperties.validationType === 'email_address') {
      if (formFieldValues.emailAddress === '') {
        return true;
      }
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(formFieldValues.emailAddress);
    }

    if (fieldProperties.validationType === 'support_email_address') {
      if (
        !formFieldValues.emailAddress ||
        formFieldValues.emailAddress === ''
      ) {
        return false;
      } else {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(formFieldValues.emailAddress);
      }
    }

    if (
      fieldProperties.validationType ===
      'subscription_notification_email_address'
    ) {
      if (!formFieldValues.email || formFieldValues.email === '') {
        return false;
      } else {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(formFieldValues.email);
      }
    }

    if (
      fieldProperties.validationType === 'outage_notification_email_address'
    ) {
      if (
        !formFieldValues.outageNotificationsEmail ||
        formFieldValues.outageNotificationsEmail === ''
      ) {
        return false;
      } else {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(formFieldValues.outageNotificationsEmail);
      }
    }

    if (fieldProperties.validationType === 'privacy_policy_url') {
      if (
        !formFieldValues.privacyPolicyUrl ||
        formFieldValues.privacyPolicyUrl === ''
      ) {
        return true;
      } else {
        let regex = URLRegex;
        return regex.test(formFieldValues.privacyPolicyUrl);
      }
    }

    if (fieldProperties.validationType === 'support_community_url') {
      if (
        !formFieldValues.supportCommunityUrl ||
        formFieldValues.supportCommunityUrl === ''
      ) {
        return true;
      }
      let regex = URLRegex;
      return regex.test(formFieldValues.supportCommunityUrl);
    }

    if (fieldProperties.validationType === 'terms_and_conditions_url') {
      if (
        !formFieldValues.termsAndConditionsUrl ||
        formFieldValues.termsAndConditionsUrl === ''
      ) {
        return true;
      }
      let regex = URLRegex;
      return regex.test(formFieldValues.termsAndConditionsUrl);
    }

    if (fieldProperties.validationType === 'redirect_radio_url') {
      if (!formFieldValues.redirectURL) {
        return true;
      }
      let regex = /((?:https?:\/\/)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;
      return regex.test(formFieldValues.redirectURL);
    }

    if (fieldProperties.validationType === 'solution_name') {
      if (!formFieldValues.name || formFieldValues.name.trim() === '') {
        return false;
      } else if (isDuplicateSolution) {
        return false;
      } else {
        return true;
      }
    }
    if (fieldProperties.validationType === 'copyright') {
      if (
        formFieldValues.copyright &&
        formFieldValues.copyright.trim() !== ''
      ) {
        let regex = /^[a-zA-Z\d ]+$/;
        return regex.test(formFieldValues.copyright);
      }
    }
    if (fieldProperties.validationType === 'reviewers_comments_notes') {
      if (
        formFieldValues.reviewersComments &&
        formFieldValues.reviewersComments.trim() !== ''
      ) {
        let regex = /^[a-zA-Z\d-_?.,;:{}()!¡¿'"。、·\.{}\[\]\\\/  ]+$/;
        return regex.test(formFieldValues.reviewersComments);
      }
    }

    if (fieldProperties.validationType === 'category') {
      if (fieldProperties.required === true) {
        if (
          !formFieldValues.category ||
          formFieldValues.category.trim() === ''
        ) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }

    if (fieldProperties.validationType === 'searchKeywords') {
      let errorCount = 0;
      let regex = /^(([0-9a-zA-Z ][0-9a-zA-Z-_ ]*)([,][0-9a-zA-Z ][0-9a-zA-Z-_ ]*)*)$/;
      if (fieldProperties.required === true) {
        if (
          Array.isArray(formFieldValues.searchKeywords) &&
          formFieldValues.searchKeywords.length > 0
        ) {
          formFieldValues.searchKeywords.map(item => {
            if (item && item !== '') {
              let result = regex.test(item);
              if (result == false) {
                errorCount = errorCount + 1;
              }
            } else {
              errorCount = errorCount + 1;
            }
          });
          return errorCount == 0 ? true : false;
        }
      }
    }

    if (fieldProperties.validationType === 'version') {
      if (!formFieldValues.version || formFieldValues.version === '') {
        return false;
      }
      let regex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
      return regex.test(formFieldValues.version);
    }

    if (fieldProperties.validationType === 'publisherName') {
      if (
        !formFieldValues.publisherName ||
        formFieldValues.publisherName.trim() === ''
      ) {
        return false;
      }
      return true;
    }

    if (fieldProperties.validationType === 'feature_title') {
      if (!formFieldValues.title || formFieldValues.title.trim() === '') {
        return false;
      }
      return true;
    }

    if (fieldProperties.validationType === 'feature_description') {
      if (
        !formFieldValues.description ||
        formFieldValues.description.trim() === ''
      ) {
        return false;
      }
      return true;
    }

    if (fieldProperties.validationType === 'solution_brief_description') {
      if (
        !formFieldValues.briefDescription ||
        formFieldValues.briefDescription.trim() === ''
      ) {
        return false;
      }
      return true;
    }

    if (fieldProperties.validationType === 'solution_detailed_description') {
      if (fieldProperties.required === true) {
        if (
          !formFieldValues.detailedDescription ||
          formFieldValues.detailedDescription.trim() === ''
        ) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }

    if (fieldProperties.validationType === 'publisher_website_url') {
      if (fieldProperties.required === true) {
        if (!formFieldValues.website || formFieldValues.website === '') {
          return false;
        } else {
          let regex = URLRegex;
          return regex.test(formFieldValues.website);
        }
      } else {
        if (!formFieldValues.website || formFieldValues.website === '') {
          return true;
        } else {
          let regex = URLRegex;
          return regex.test(formFieldValues.website);
        }
      }
    }

    if (fieldProperties.validationType === 'solution_website_url') {
      if (fieldProperties.required === true) {
        if (
          !formFieldValues.solutionWebsite ||
          formFieldValues.solutionWebsite === ''
        ) {
          return false;
        } else {
          let regex = URLRegex;
          return regex.test(formFieldValues.solutionWebsite);
        }
      } else {
        if (
          !formFieldValues.solutionWebsite ||
          formFieldValues.solutionWebsite === ''
        ) {
          return true;
        } else {
          let regex = URLRegex;
          return regex.test(formFieldValues.solutionWebsite);
        }
      }
    }

    if (fieldProperties.validationType === 'privacy_policy_url') {
      if (
        !formFieldValues.privacyPolicyUrl ||
        formFieldValues.privacyPolicyUrl === ''
      ) {
        return true;
      } else {
        let regex = URLRegex;
        return regex.test(formFieldValues.privacyPolicyUrl);
      }
    }

    if (fieldProperties.validationType === 'end_point_url') {
      if (!formFieldValues.endpointUrl || formFieldValues.endpointUrl === '') {
        return true;
      }
      let regex = URLRegex;
      return regex.test(formFieldValues.endpointUrl);
    }

    if (fieldProperties.validationType === 'tutorials_and_documentation_url') {
      if (!formFieldValues.url || formFieldValues.url === '') {
        return true;
      }
      let regex = URLRegex;
      return regex.test(formFieldValues.url);
    }

    if (fieldProperties.validationType === 'video_url') {
      if (!formFieldValues.url || formFieldValues.url === '') {
        return false;
      }
      let regex = URLRegex;
      return regex.test(formFieldValues.url);
    }

    if (fieldProperties.validationType === 'solution_logo') {
      if (
        !formFieldValues.solutionLogo ||
        formFieldValues.solutionLogo === '' ||
        formFieldValues.solutionLogo === null
      ) {
        return false;
      }
      return true;
    }

    return true;
  };
}

export default ValidationUtils;
