export class SideNavUtils {
  static getInitialMenu(formValueFinal, menu) {
    let menuObj = menu;
    menu.contents.forEach((content, contentIndex) => {
      const showMenuItem = content.showIf
        ? content.showIf({
            solutiondata: formValueFinal
          })
        : true;
      showMenuItem &&
        content.steps.forEach(step => {
          if (step.type === 'expansion') {
            let values = formValueFinal[step.id];
            for (let valueIndex in values) {
              if (values.hasOwnProperty(valueIndex)) {
                let dynamicMenuLabel = {
                  id: valueIndex,
                  label: values[valueIndex].title
                    ? values[valueIndex].title
                    : values[valueIndex].planName,
                  type: 'dynamic'
                };
                menuObj[step.id].push(dynamicMenuLabel);
                //unshift adds to beginning of array(array gets reversed)
              }
            }
            if (window.location.pathname.toUpperCase().indexOf('ADMIN') < 0) {
              menuObj[step.id].push(menuObj[step.id][0]);
              menuObj[step.id].shift();
            }
          }
        });
      if (!showMenuItem) {
        menuObj.contents = menuObj.contents.filter(
          item => item.title !== content.title
        );
      }
    });
    return menuObj;
  }

  static changeNestedMenuLabel(menu, activeMenuId, dynamicMenuLabelId, label) {
    menu[activeMenuId].forEach((dynamicMenuItem, index) => {
      if (
        dynamicMenuItem.id === dynamicMenuLabelId &&
        menu[activeMenuId][index].type === 'dynamic'
      ) {
        menu[activeMenuId][index].label = label;
      }
    });
    return menu;
  }

  static createDynamicMenuItem(
    menu,
    nestedActiveMenuId,
    formFields,
    activeMenuId
  ) {
    let dynamicMenuLabel = {
      id: '',
      label: '',
      type: 'dynamic'
    };

    dynamicMenuLabel.label = formFields[formFields.setLabelFrom];

    if (menu[activeMenuId].length > 1) {
      dynamicMenuLabel.id =
        menu[activeMenuId][
          menu[activeMenuId].length > 1 ? menu[activeMenuId].length - 2 : 0
        ].id + 1;
    } else {
      dynamicMenuLabel.id = 1;
    }
    let createItem = menu[activeMenuId][menu[activeMenuId].length - 1];
    menu[menu.activeMenuId].splice(menu[activeMenuId].length - 1, 1);
    menu[activeMenuId].push(dynamicMenuLabel);
    menu[activeMenuId].push(createItem);
    menu.nestedActiveMenuId = dynamicMenuLabel.id;
    return menu;
  }

  static deleteMenuItem(menu) {
    menu[menu.activeMenuId] = menu[menu.activeMenuId].filter(item => {
      return item.id !== menu.nestedActiveMenuId;
    });
    if (menu[menu.activeMenuId].length > 1) {
      menu.nestedActiveMenuId = menu[menu.activeMenuId][0].id;
    }
    return menu;
  }
}
