import React from 'react';
import { featuresShape } from '../solution-submission/shapes';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ImageContainer169 from '../common/ImageContainer169';
import EmptyContentImage from '../common/images/Empty_State_16x9_2x.png';
import { FormUrlValidation } from '../../containers/utils/FormUrlValidation';

class Features extends React.Component {
  static propTypes = {
    features: PropTypes.arrayOf(featuresShape).isRequired
  };

  state = {
    index: 0
  };

  render() {
    const { intl, features, highlights, overview, activateOnly } = this.props;
    return (
      <div>
        {highlights && highlights.highlight_list.length > 0 && (
          <div className="highlights-container">
            <div className="highlights-header">Highlights</div>
            <div className="highlight-list">
              <ul>
                {highlights.highlight_list.map((highlight, highlightIdx) => (
                  <li
                    data-cy={`li_highlight_${highlightIdx}`}
                    key={highlightIdx}
                  >
                    {highlight.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {activateOnly === false && features && features.length > 0 && (
          <div>
            <div className="feature-title"> Additional Features</div>

            {/*keeping the expansion thing commented, may be used in mobile version of this page*/}
            {/*   <ExpansionPanelGroup className="features-expansion">
                  {features.length > 0
                      ? features.map((feature, featureIdx) => (
                          <ExpansionPanel
                              key={featureIdx}
                              expanded={this.state.index === featureIdx}
                          >
                            <ExpansionPanelHeader
                                onClick={() => {
                                  this.setState(prevState => ({
                                    index:
                                        prevState.index === featureIdx ? -1 : featureIdx
                                  }));
                                }}
                            >
                              <ExpansionPanelHeaderTitle className="feature-list-title">
                                {feature.title}
                              </ExpansionPanelHeaderTitle>
                            </ExpansionPanelHeader>
                            <ExpansionPanelContent
                                expanded={this.state.index === featureIdx}
                            >
                              <ExpansionPanelContentBody>
                                <LayoutGrid className="feature-content-body">
                                  <LayoutGridCell span={3}>
                                    {feature.featureImage ? (
                                        <ImageContainer169 src={feature.featureImage} />
                                    ) : (
                                        <img
                                            src={EmptyContentImage}
                                            alt={intl.formatMessage({
                                              id: "Features.img.alt",
                                              defaultMessage: "Feature"
                                            })}
                                            className="image-container-image"
                                        />
                                    )}
                                  </LayoutGridCell>
                                  <LayoutGridCell span={9}>
                                    <Subheading1 className="sol-details__sol-descrip">
                                      {feature.description}
                                    </Subheading1>
                                  </LayoutGridCell>
                                </LayoutGrid>
                              </ExpansionPanelContentBody>
                            </ExpansionPanelContent>
                          </ExpansionPanel>
                      ))
                      : false}
                </ExpansionPanelGroup>*/}

            {features.length > 0
              ? features.map((feature, featureIdx) => (
                  <div className="feature-list_feature" key={featureIdx}>
                    <div className="feature-list-img-container">
                      {feature.featureImage ? (
                        <ImageContainer169
                          src={feature.featureImage}
                          alt={intl.formatMessage({
                            id: 'Features.img.alt',
                            defaultMessage: 'Feature'
                          })}
                          className="feature-list-img-shadow"
                        />
                      ) : (
                        <ImageContainer169
                          src={EmptyContentImage}
                          alt={intl.formatMessage({
                            id: 'Features.img.alt',
                            defaultMessage: 'Feature'
                          })}
                          className="feature-list-img-shadow"
                        />
                      )}
                    </div>
                    <div className="feature-list-description-column">
                      <div className="feature-list-feature-title">
                        {feature.title}
                      </div>
                      <div className="feature-list-feature-description">
                        {feature.description}
                      </div>
                    </div>
                    <div className="clear-both" />
                  </div>
                ))
              : false}
          </div>
        )}
        {overview && overview.solutionWebsite && (
          <div>
            <div
              data-cy="div_solution_description_title_learnmore"
              className="title-header"
            >
              Learn more
            </div>
            <div className="overview-links">
              <span>
                <a
                  href={FormUrlValidation.validateUrl(overview.solutionWebsite)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {overview.name}{' '}
                  <i className="material-icons external-link-icon">
                    open_in_new
                  </i>
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(Features);
