import React from 'react';
import './SolutionCardGroup.scss';
import SkeletonCardGroup from './SkeletonCardGroup';

const SkeletonWithState = props => {
  return (
    <div>
      <div>
        <SkeletonCardGroup
          solutionArray={new Array(3).fill(0)}
          title="Featured Apps"
        />

        <div className="solution-card-group__big_ruler" />
        <SkeletonCardGroup
          solutionArray={new Array(3).fill(0)}
          title="Newest Apps"
        />

        <div className="solution-card-group__big_ruler" />
        <SkeletonCardGroup
          solutionArray={new Array(9).fill(0)}
          title="Discover More Apps"
        />
      </div>
    </div>
  );
};

export default SkeletonWithState;
