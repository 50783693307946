import React from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import SubscribedPlanDetails from './subscribed-plan-details';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Subheading2, Title } from '@cdk-uip/react-typography';
import { Link } from 'react-router-dom';
import EntityDetails from '../../common/EntityDetails';

class PlanSubscribed extends React.Component {
  render() {
    const { solution, plan, subscriptionDetails, entity } = this.props;
    return (
      <LayoutGrid className="fm-page-component-grid">
        <LayoutGridCell span={12}>
          <Title className="fm-page-content-title">
            <span>
              <FormattedMessage
                id="MarketplaceAccount.subscriptions.subscriptionSuccessful"
                defaultMessage="Subscription Successful"
              />
            </span>
          </Title>
          <Subheading2 className="fm-page-content-title-note plan-content-note">
            You’re almost done! A few more things need to happen before solution
            can be activated. You can{' '}
            <Link
              to={`/marketplace-account/mysubscriptions/${
                subscriptionDetails.subscriptionId
              }/apiconfig`}
            >
              configure API access
            </Link>{' '}
            now or do it later from{' '}
            <Link to={`/marketplace-account/mysubscriptions`}>
              My Subscriptions
            </Link>
            .
          </Subheading2>
        </LayoutGridCell>
        <LayoutGridCell span={12}>
          <div>
            <EntityDetails entityId={entity.id} />
          </div>
          <div className="subscribed-plan-details-parent">
            <SubscribedPlanDetails
              solution={solution}
              plan={plan}
              subscriptionDetails={subscriptionDetails}
            />
          </div>
        </LayoutGridCell>
      </LayoutGrid>
    );
  }
}

export default injectIntl(withAuth(PlanSubscribed));
