import React from 'react';
import FormTitle from './FormComponents/FormTitle';
import FormDeleteLabel from './FormComponents/FormDeleteLabel';
import FormTabs from './FormTabs';
import FormTabContent from './FormTabContent';

export class CustomTabForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabId: '',
      formId: this.props.formConfig.formId,
      activeSubFormId: this.props.activeSubFormId
    };
  }

  onTabChange = activeTabId => {
    this.props.onTabChange && this.props.onTabChange(activeTabId);
    this.setState({ activeTabId: activeTabId });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.formConfig.formId !== prevState.formId ||
      nextProps.formConfig.activeTabId !== prevState.activeTabId
    ) {
      return {
        activeTabId: nextProps.formConfig.activeTabId,
        formId: nextProps.formConfig.formId
      };
    }
    if (nextProps.activeSubFormId !== prevState.activeSubFormId) {
      return {
        activeSubFormId: nextProps.activeSubFormId
      };
    }
    return null;
  }

  componentDidMount() {
    this.setState({ activeTabId: this.props.formConfig.activeTabId });
  }

  render() {
    return (
      <div>
        <FormTitle
          title={this.props.formConfig.formTitle}
          titleNotes={this.props.formConfig.formNote}
          formAllValues={this.props.formAllValues}
          isSolutionReview={this.props.isSolutionReview}
          handlePreview={this.props.handlePreview}
          isFormInvalid={this.props.isFormInvalid}
        />

        {this.props.formConfig.showDynamicLabel && (
          <FormDeleteLabel
            show={this.props.formConfig.showDynamicLabel}
            disableDelete={
              this.props.formConfig.deleteDependentOn
                ? this.props.formFieldValues[
                    this.props.formConfig.deleteDependentOn
                  ]
                : false
            }
            label={
              this.props.formFieldValues[
                this.props.formFieldValues.setLabelFrom
              ]
            }
            onDelete={this.props.onDelete}
            activeFormLabel={this.props.formConfig.formTitle.slice(
              0,
              this.props.formConfig.formTitle.length - 1
            )}
          />
        )}

        {this.props.formConfig.tabs &&
          this.props.formConfig.tabs.length > 1 && (
            <FormTabs
              tabs={this.props.formConfig.tabs}
              activeTabId={this.state.activeTabId}
              onTabChange={this.onTabChange.bind(this)}
            />
          )}

        <FormTabContent
          {...this.props}
          tabs={this.props.formConfig.tabs}
          activeTabId={this.state.activeTabId}
          formStaticValues={this.props.formStaticValues}
          formFieldValuesAll={this.props.formAllValues}
          formFieldValues={this.props.formFieldValues}
          formValueCount={this.props.formValueCount}
          formValues={this.props.formConfig.formValues}
          noFormFieldNote={this.props.formConfig.noFormFieldNote}
          onSave={this.props.onSave}
        />
      </div>
    );
  }
}

export default CustomTabForm;
