import React from 'react';
import SolutionItemBreadcrumb from './common/breadcrumbs/SolutionItemBreadcrumb';
import SolutionItemCatalogBreadcrumb from './common/breadcrumbs/SolutionItemCatalogBreadcrumb';
import { EntityBreadcrumb } from './common/breadcrumbs/EntityBreadcrumb';
import { FormattedMessage } from 'react-intl';
import { AppTypeBreadcrumb } from './common/breadcrumbs/AppTypeBreadcrumb';

const routes = {
  home: {
    path: '/',
    breadcrumb: 'Marketplace',
    disableBreadcrumb: true
  },
  'old-landing-page': {
    path: '/storefront-X4K8ZIMeFnfZUptO'
  },
  'marketplace-account': {
    path: '/marketplace-account'
  },
  'manage-listing-complete-provisioning': {
    path:
      '/marketplace-account/mysolutions/:type/:solutionId/solution-management/manage/subscriptions/:subscriptionId/:entityId',
    breadcrumb: props => <EntityBreadcrumb {...props} />
  },
  'manage-listing-subscriptions': {
    path:
      '/marketplace-account/mysolutions/:type/:id/solution-management/manage/subscriptions',
    breadcrumb: props => <AppTypeBreadcrumb {...props} />,
    disableBreadcrumb: true
  },
  'manage-listing': {
    path:
      '/marketplace-account/mysolutions/:type/:id/solution-management/:activeMenuId',
    breadcrumb: props => <SolutionItemBreadcrumb {...props} />
  },
  'manage-listing-view': {
    path:
      '/marketplace-account/mysolutions/:type/:id/solution-management/manageListing/:listingVersion',
    breadcrumb: props => {
      return <span>Listing Version: {props.match.params.listingVersion}</span>;
    }
  },
  'solution-details-with-type': {
    path: '/marketplace-account/mysolutions/list/:type'
  },
  'solution-submission-with-type': {
    path: '/marketplace-account/mysolutions/list/:type'
  },
  'solution-submission': {
    path: '/marketplace-account/mysolutions/list/:type/:id',
    breadcrumb: props => {
      return <SolutionItemBreadcrumb {...props} />;
    }
  },
  'solution-submission-preview': {
    path: '/marketplace-account/mysolutions/preview/:type/:id/:version',
    breadcrumb: props => {
      return <SolutionItemBreadcrumb {...props} />;
    }
  },
  'solution-submission-listing-version': {
    path: '/marketplace-account/mysolutions/list/:type/:id/:version',
    breadcrumb: props => <SolutionItemBreadcrumb {...props} />
  },
  'my-solutions': {
    path: '/marketplace-account/mysolutions',
    breadcrumb: 'My Apps'
  },
  'my-subscriptions': {
    path: '/marketplace-account/mysubscriptions',
    breadcrumb: 'Subscriptions'
  },
  'configure-apis': {
    path: '/marketplace-account/mysubscriptions/:subscriptionId/apiconfig',
    breadcrumb: "Configure API's"
  },
  'plan-and-pricing': {
    path: '/solutions/:solutionId/plans/:selectedPlanId/subscription',
    breadcrumb: 'Plan and Pricing'
  },
  /*  payment: {
    path: "/solutions/payment",
    breadcrumb: "Payment"
  },*/
  'all-solutions': {
    path: '/solutions',
    breadcrumb: 'Apps',
    disableBreadcrumb: true
  },
  'solution-details': {
    path: '/solutions/:publisherName/:appName'
  },
  'solution-details-old': {
    path: '/solutions/:id',
    breadcrumb: props => <SolutionItemCatalogBreadcrumb {...props} />
  },
  'admin-solutions': {
    path: '/admin/solutions',
    disableBreadcrumb: true
  },
  'admin-subscriptions': {
    path: '/admin/subscriptions',
    breadcrumb: 'Subscriptions'
  },
  'admin-leads': {
    path: '/admin/leads',
    breadcrumb: (
      <FormattedMessage
        id="ExchangeHeaderResponsive.adminAccount.menu3"
        defaultMessage="Leads"
      />
    )
  },
  'solution-reviews': {
    path: '/admin/review',
    breadcrumb: (
      <FormattedMessage
        id="ExchangeHeaderResponsive.adminAccount.menu2"
        defaultMessage="User Reviews"
      />
    )
  },
  'subscriptions-data': {
    path: '/admin/subscriptions-data',
    breadcrumb: (
      <FormattedMessage
        id="ExchangeHeaderResponsive.adminAccount.menu"
        defaultMessage="Subscriptions Data"
      />
    )
  },
  'beta-access-approval': {
    path: '/admin/betaUser049bc449',
    breadcrumb: 'Beta Access Approval'
  },
  'fake-login': {
    path: '/fake-login'
  }
};

export default routes;
