import React from 'react';

import { Card, CardMedia } from '@cdk-uip/react-card';

import Spinner from '../../common/Spinner';
import SolutionLogo from '../../common/images/cogs.png';
import FreeTrialFlag from '../../common/FreeTrialFlag';
import mapKeys from 'lodash/mapKeys';
import { FormattedMessage } from 'react-intl';
import ApiConfigConstants from '../../common/constants/ApiConfigConstants';

class SolutionStripForApiConfig extends React.Component {
  componentWillReceiveProps(newProps) {
    if (this.props.loading && !newProps.loading) {
      this.props.setRegistrationDetails(
        mapKeys(newProps.solution.registration_details.api_details, function(
          value
        ) {
          return value.name;
        })
      );
    }
  }

  render() {
    let {
      solution,
      loading,
      entity,
      solutionSubscriptions: { plan = {} }
    } = this.props;
    return (
      <div>
        {!loading ? (
          <Card className="solution-strip">
            {solution && solution.overview && solution.overview.solutionLogo ? (
              <CardMedia
                className="solution-strip-logo"
                scale="square"
                style={{
                  backgroundImage: `url(${
                    solution.overview.solutionLogo
                  }?${Math.random()})`
                }}
              />
            ) : (
              <CardMedia
                className="solution-strip-logo"
                scale="square"
                style={{
                  backgroundImage: `url(${SolutionLogo})`
                }}
              />
            )}

            <div className="solution-strip-content">
              <div className="solution-strip-content-left">
                <div style={{ display: 'inline-block' }}>
                  <div className="solution-strip-name">
                    {solution && solution.overview && solution.overview.name
                      ? solution.overview.name
                      : 'No app name to display...'}
                  </div>
                  <div className="solution-strip-company">
                    {solution &&
                    solution.overview &&
                    solution.overview.publisherName
                      ? solution.overview.publisherName
                      : 'Company name not available.'}
                  </div>
                </div>
                {solution &&
                  solution.plans &&
                  solution.plans.map(solutionPlan => {
                    return solutionPlan.id === plan.id &&
                      solutionPlan.planName ===
                        ApiConfigConstants.FREE_TRAIL ? (
                      <FreeTrialFlag verticalAlign="top" />
                    ) : null;
                  })}
              </div>

              {entity ? (
                <div className="solution-strip-content-right">
                  <div className="solution-strip-entity-label">
                    <FormattedMessage
                      id="SolutionApiConfig.entityName"
                      defaultMessage="Entity Name:"
                    />
                  </div>
                  <div className="solution-strip-entity-name">
                    {entity.name}
                  </div>
                </div>
              ) : null}
            </div>
          </Card>
        ) : (
          <div>
            <Spinner />
            <div style={{ textAlign: 'center' }}>
              {' '}
              <strong>Loading...</strong>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SolutionStripForApiConfig;
