import AppSubscriptions from '../../common/AppSubscriptions';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';

export const config = {
  formId: 'app_subscriptions',
  formTitle: 'App Activation',
  formValueType: 'Object',
  tabs: [
    {
      id: 'app_subscriptions_tab',
      contents: [
        {
          id: 'app_subscriptions_section',
          fields: [
            {
              formtitle:
                'Choose how the app will be enabled when a user subscribes.',
              type: 'FormCaption',
              gridStyle: 'layoutcell',
              style: 'appsubscriptionstitle'
            },
            {
              formtitle: `Learn more about `,
              type: 'FormCaptionWithAnchorText',
              gridStyle: 'layoutcell',
              style: 'appsubscriptionstitle',
              linkText: 'App Activation.',
              link: environmentURLs.appSubscriptionActivationInfoPageLink,
              linkStyle: 'app-subscriptions-title-anchor'
            },
            {
              formtitle: 'Activation Type',
              type: 'FortellisTitle3',
              style: 'provisioningtitle'
            },
            {
              name: 'appSubscriptionsType',
              label: 'Auto',
              type: 'radio',
              gridStyle: 'solution-access-type-container',
              disabled: true,
              markAsCheck: function(formValue, name) {
                return formValue[name] === AppSubscriptions.AUTOMATIC;
              },
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['appSubscriptionsType'] =
                    AppSubscriptions.AUTOMATIC;
                }
                return resetValue;
              }
            },
            {
              formtitle:
                'When a user subscribes to the app, they will automatically be able to connect and install the app',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'appsubscriptionscaption'
            },
            {
              name: 'appSubscriptionsType',
              label: 'Manual',
              type: 'radio',
              gridStyle: 'solution-access-type-container',
              disabled: true,
              markAsCheck: function(formValue, name) {
                return formValue[name] === AppSubscriptions.MANUAL;
              },
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['appSubscriptionsType'] = AppSubscriptions.MANUAL;
                }
                return resetValue;
              }
            },
            {
              formtitle:
                'When a user subscribes to the app, the publisher will have to manually confirm the subscription',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'appsubscriptionscaption'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    app_subscriptions: {
      appSubscriptionsType: AppSubscriptions.AUTOMATIC
    }
  },
  activeTabId: 'app_subscriptions_tab'
};
