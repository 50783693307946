import React from "react";
import { injectIntl } from "react-intl";
import { CardText } from "@cdk-uip/react-card";
import MySubscriptionListItemContentTitle from "./my-subscription-list-item-content-title";
import MySubscriptionListItemContentSteps from "./my-subscription-list-item-content-steps";

class MySubscriptionListItemInProcessContent extends React.Component {
  render() {
    const { subscription, apiConfigurationStatus } = this.props;
    return (
      <CardText className="subscription-card-content">
        <MySubscriptionListItemContentTitle subscription={subscription} />
        <MySubscriptionListItemContentSteps
          {...this.props}
          apiConfigurationStatus={apiConfigurationStatus}
          subscription={subscription}
        />
      </CardText>
    );
  }
}

export default injectIntl(MySubscriptionListItemInProcessContent);
