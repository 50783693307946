import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withApollo } from 'react-apollo';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import gql from 'graphql-tag';
import { getApolloClient } from '../../containers/GraphQLClient';
import moment from 'moment';
import ProvisioningConstants from '../common/ProvisioningConstants';
import ApiConfigStatusForSubscriptionConstants from '../common/ApiConfigStatusForSubscriptionConstants';
import ApiConnectionStatusConstants from '../common/ApiConnectionStatusConstants';
import SolutionTypeURLMapperUtil from '../common/SolutionTypeURLMapperUtil';
import NotificationConstants from '../common/NotificationConstants';
import LoadingStepper from '../common/LoadingStepper';
import ConsentTypeConstants from '../common/constants/ConsentTypeConstants';

const createSubscription = gql`
  mutation(
    $solutionId: ID!
    $listingVersion: String!
    $entityId: ID!
    $userId: String
    $firstName: String
    $lastName: String
    $status: String
    $plan: PlanInfoCmd
    $termsAndConditions: String
    $subscriptionCreatedOn: String
    $userProvisioningStatus: String
    $provisioningInitiatedOn: String
  ) {
    createSolutionSubscription(
      command: {
        solutionId: $solutionId
        listingVersion: $listingVersion
        entityId: $entityId
        userId: $userId
        firstName: $firstName
        lastName: $lastName
        status: $status
        plan: $plan
        termsAndConditions: $termsAndConditions
        reminderSentOn: null
        userProvisioningStatus: $userProvisioningStatus
        apiActivationStatus: "Not Started"
        subscriptionStartDate: null
        subscriptionEndDate: null
        subscriptionCreatedOn: $subscriptionCreatedOn
        provisioningInitiatedOn: $provisioningInitiatedOn
      }
    ) {
      id
      subscriptionId
      userId
      dateOfPurchase
    }
  }
`;

const getEntityDetailsFromPlatform = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      name
      phone
      website
      address {
        region
        city
        street
        countryCode
        postalCode
      }
    }
  }
`;

const subscriptionBuyerEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $solutionName: String
    $solutionId: String
    $publisher: String
    $publisherEmail: String
    $publisherPhone: String
    $buyerName: String
    $purchaseDate: String
    $planName: String
    $planDetails: String
    $subscriptionId: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
          publisher: $publisher
          publisherEmail: $publisherEmail
          publisherPhone: $publisherPhone
          subscriptionDetails: {
            buyerName: $buyerName
            purchaseDate: $purchaseDate
            planName: $planName
            planDetails: $planDetails
            subscriptionId: $subscriptionId
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;

const subscriptionDeveloperEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $solutionName: String
    $solutionId: String
    $solutionType: String
    $buyerName: String
    $subscriptionId: String
    $entityId: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
          solutionType: $solutionType
          subscriptionDetails: {
            buyerName: $buyerName
            subscriptionId: $subscriptionId
            entityId: $entityId
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;

const notifyUrlMutation = gql`
  mutation($endpointUrl: String!, $payload: String!) {
    notifyUrl(command: { endpointUrl: $endpointUrl, payload: $payload }) {
      status
    }
  }
`;

class SubscriptionPlanActions extends React.Component {
  state = {
    entity: undefined,
    isSubscribing: false,
    subscribed: false,
    cancel: false,
    response: null,
    subscribingStepsArray: [],
    subscribingActiveStep: 0,
    finished: false
  };

  async componentDidMount() {
    const client = getApolloClient();
    const { entity } = this.props;
    let entityDetailResponse = await client.query({
      query: getEntityDetailsFromPlatform,
      variables: { id: entity.id }
    });
    if (
      entityDetailResponse.data &&
      entityDetailResponse.data.entitiesFromPlatform
    ) {
      this.setState({
        entity: entityDetailResponse.data.entitiesFromPlatform,
        subscribingStepsArray: [
          'Creating Subscription',
          'Notifying App Provider',
          `Sending Email Confirmation to ${
            entityDetailResponse.data.entitiesFromPlatform.name
          }`
        ]
      });
    }
  }

  /*  //simulation code for development time testing
  spinnerProgress = () => {
    this.setState({ subscribingActiveStep: 0 });
          setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),2000);
    if (
      this.props.solution.provisioning_interface.provisioningtype ===
        ProvisioningConstants.type.ENDPOINT_URL &&
      this.props.solution.provisioning_interface.endpointUrl
    ) {
            setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),4000);
    }
          setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),6000);
    setTimeout(async () => await this.setState({ finished: true }), 6500);
    this.forceUpdate();
  };

  subscribe = async () => {
    await this.setState({ isSubscribing: true });
    this.spinnerProgress();
    setTimeout(async () =>  this.setState({ isSubscribing: false }), 6500);
  };*/

  sendSubscriptionNotification = async (
    createSolutionSubscriptionResponse,
    entity,
    client
  ) => {
    let solution = this.props.solution;
    if (
      createSolutionSubscriptionResponse.data.createSolutionSubscription &&
      createSolutionSubscriptionResponse.data.createSolutionSubscription
        .subscriptionId &&
      solution.subscription_notifications &&
      solution.subscription_notifications.apiNotification &&
      solution.subscription_notifications.endpointUrl
    ) {
      let payload = {
        solutionInfo: {
          id: solution.id,
          name: solution.overview.name,
          planInfo: {
            name: this.props.plan.planName,
            description: this.props.plan.planDescription
          }
        },
        subscriptionInfo: {
          id:
            createSolutionSubscriptionResponse.data.createSolutionSubscription
              .subscriptionId,
          subscribedBy:
            createSolutionSubscriptionResponse.data.createSolutionSubscription
              .userId,
          subscribedOn:
            createSolutionSubscriptionResponse.data.createSolutionSubscription
              .dateOfPurchase,
          subscriberEntity: {
            id: entity.id,
            name: entity.name,
            address: {
              region: entity.address.region,
              city: entity.address.city,
              street: entity.address.street,
              countryCode: entity.address.countryCode,
              postalCode: entity.address.postalCode
            },
            phoneNumber: entity.phone,
            website: entity.website
          }
        },
        notificationType: NotificationConstants.type.SUBSCRIPTION
      };

      let stringifiedPayload = JSON.stringify(payload);

      await client.mutate({
        mutation: notifyUrlMutation,
        variables: {
          endpointUrl: solution.subscription_notifications.endpointUrl,
          payload: stringifiedPayload
        }
      });
    }
  };

  subscribe = async () => {
    const client = getApolloClient();
    try {
      const { entity } = this.state;
      await this.setState({ isSubscribing: true, subscribingActiveStep: 0 });
      this.forceUpdate();

      //STEP 1 - CREATING SUBSCRIPTION
      let buyerName = this.props.auth.userData.name.split(' ');
      let buyerEmail = this.props.auth.userData.email;
      let response = await client.mutate({
        mutation: createSubscription,
        variables: {
          solutionId: this.props.solution.id,
          listingVersion: this.props.solution.listingVersion
            ? this.props.solution.listingVersion
            : '1',
          firstName: buyerName[0] || null,
          lastName: buyerName[1] || null,
          entityId: entity.id,
          userId: buyerEmail,
          status: 'Pending Activation',
          userProvisioningStatus:
            this.props.solution &&
            this.props.solution.provisioning_interface &&
            this.props.solution.provisioning_interface.provisioningtype ===
              ProvisioningConstants.type.MANUAL
              ? ApiConnectionStatusConstants.PENDING
              : ApiConfigStatusForSubscriptionConstants.NOT_STARTED,
          provisioningInitiatedOn:
            this.props.solution &&
            this.props.solution.provisioning_interface &&
            this.props.solution.provisioning_interface.provisioningtype ===
              ProvisioningConstants.type.MANUAL
              ? moment().toISOString()
              : null,
          plan: this.props.plan,
          termsAndConditions: this.props.solution.overview
            .termsAndConditionsUrl,
          subscriptionCreatedOn: moment().toISOString(),
          subscribedSource: ConsentTypeConstants.CONSENTED_SOURCE
        }
      });

      if (
        response &&
        response.data &&
        response.data.createSolutionSubscription &&
        response.data.createSolutionSubscription.subscriptionId
      ) {
        this.setState({ response: response.data.createSolutionSubscription });

        //STEP 2 - NOTIFYING SOLUTION PROVIDER
        await this.setState(prevState => ({
          subscribingActiveStep: prevState.subscribingActiveStep + 1
        }));

        //NOTIFYING SOLUTION PROVIDER STEP - subcription endpoint notification
        await this.sendSubscriptionNotification(response, entity, client);

        //NOTIFYING SOLUTION PROVIDER STEP - email notification to solution developer
        let emails = [];
        emails.push(this.props.solution.developerId);
        if (this.props.solution.subscription_notifications.email != null) {
          emails.push(this.props.solution.subscription_notifications.email);
        }
        emails.forEach(email => {
          client.mutate({
            mutation: subscriptionDeveloperEmailMutation,
            variables: {
              emailAddress: email,
              emailSubject: this.props.plan.planName
                ? 'New Subscription - ' + this.props.plan.planName
                : 'New Subscription',
              firstName: this.props.solution.developerName.split(' ').length
                ? this.props.solution.developerName.split(' ')[0]
                : ' ',
              emailType: 'solutionSubscriptionDeveloperEmail_v2',
              solutionName: this.props.solution.overview.name,
              solutionId: this.props.solution.id || ' ',
              solutionType:
                SolutionTypeURLMapperUtil.getSolutionTypeURL(
                  this.props.solution.solutionType
                ) || ' ',
              buyerName: entity.name || ' ',
              subscriptionId:
                response &&
                response.data &&
                response.data.createSolutionSubscription
                  ? response.data.createSolutionSubscription.subscriptionId
                  : ' ',
              entityId: this.props.entity.id ? this.props.entity.id : ' '
            }
          });
        });

        //STEP 3 - SUBSCRIPTION CONFIRMATION EMAIL NOTIFICATION TO SOLUTION SUBSCRIBER
        await this.setState(prevState => ({
          subscribingActiveStep: prevState.subscribingActiveStep + 1
        }));

        await client.mutate({
          mutation: subscriptionBuyerEmailMutation,
          variables: {
            emailAddress: buyerEmail,
            emailSubject: this.props.plan.planName
              ? 'New Subscription - ' + this.props.plan.planName
              : 'New Subscription',
            firstName: this.props.auth.userData.name || ' ',
            emailType: 'solutionSubscriptionBuyerEmail',
            solutionName: this.props.solution.overview.name,
            solutionId: this.props.solution.id || ' ',
            publisher: this.props.solution.overview.publisherName
              ? this.props.solution.overview.publisherName
              : ' ',
            publisherEmail: this.props.solution.support.emailAddress
              ? this.props.solution.support.emailAddress
              : ' ',
            publisherPhone: this.props.solution.support.phoneNumber
              ? this.props.solution.support.phoneNumber
              : ' ',
            buyerName: entity.name || ' ',
            purchaseDate: this.state.response.dateOfPurchase
              ? moment(this.state.response.dateOfPurchase).format('DD MMM YYYY')
              : ' ',
            planName: (this.props.plan && this.props.plan.planName) || ' ',
            planDetails: this.props.plan.includedUnitQuantity
              ? this.props.plan.includedUnitQuantity +
                ' ' +
                this.props.plan.includedUnitOfMeasure
              : ' ',
            subscriptionId:
              response &&
              response.data &&
              response.data.createSolutionSubscription
                ? response.data.createSolutionSubscription.subscriptionId
                : ' '
          }
        });

        await this.setState({ finished: true });
        this.forceUpdate();
      }

      this.setState({ isSubscribing: false, subscribed: true });
    } catch (e) {
      console.error(e);
    }
  };

  cancel = () => {
    this.setState({ cancel: true });
  };

  render() {
    const { solution, plan, isAccepted } = this.props;

    if (this.state.subscribed && this.state.response) {
      return (
        <Redirect
          to={{
            pathname: `/solutions/${solution.id}/plans/${plan.id}/confirmation`,
            state: {
              solution: solution,
              plan: plan,
              subscriptionDetails: this.state.response
            }
          }}
        />
      );
    }

    if (this.state.cancel) {
      return <Redirect to={`/solutions/${solution.id}`} />;
    }
    return (
      <div className="plan-action-panel">
        <Button onClick={this.cancel}>
          <FormattedMessage
            id="MarketplaceAccount.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
        <Button primary raised disabled={!isAccepted} onClick={this.subscribe}>
          <FormattedMessage
            id="MarketplaceAccount.subscribeButton"
            defaultMessage="Subscribe"
          />
        </Button>

        {this.state.isSubscribing ? (
          <LoadingStepper
            open={this.state.isSubscribing}
            title={`${this.props.intl.formatMessage(
              { id: 'SubscriptionPlanActions.loadingStepper.title' },
              { defaultMessage: 'Subscribing to ' }
            )}
                    ${solution.overview.name}`}
            steps={this.state.subscribingStepsArray}
            activeStep={this.state.subscribingActiveStep}
            finished={this.state.finished}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}

export default injectIntl(withApollo(withAuth(SubscriptionPlanActions)));
