import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

class SolutionDetails extends React.Component {
  state = {
    displayReadMore: false,
    displayReadLess: false,
    charLimit: 640
  };

  componentDidMount() {
    if (this.props.overview && this.props.overview.detailedDescription) {
      if (
        this.props.overview.detailedDescription.length > this.state.charLimit
      ) {
        this.setState({
          displayReadMore: true,
          displayReadLess: false
        });
      }
    }
  }

  getDescriptionToDisplay = detailedDescription => {
    if (this.state.displayReadMore && !this.state.displayReadLess) {
      return detailedDescription.substring(0, this.state.charLimit) + "...";
    } else {
      return detailedDescription;
    }
  };

  onReadMoreClick = () => {
    this.setState({
      displayReadMore: false,
      displayReadLess: true
    });
  };

  onReadLessClick = () => {
    this.setState({
      displayReadMore: true,
      displayReadLess: false
    });
  };

  render() {
    const { overview } = this.props;
    const { displayReadMore, displayReadLess } = this.state;
    return (
      <div>
        <div>
          {overview &&
            overview.briefDescription && (
              <div className="solution-details-overview-briefDescription">
                {overview.briefDescription}
              </div>
            )}

          {overview &&
            overview.detailedDescription && (
              <div className="solution-details-overview-detailedDescription">
                {overview.detailedDescription.length > this.state.charLimit
                  ? this.getDescriptionToDisplay(overview.detailedDescription)
                  : overview.detailedDescription}

                {displayReadMore && (
                  <div>
                    <span
                      className="read-more-less-text"
                      onClick={this.onReadMoreClick}
                      id="ReadMoreDescription"
                    >
                      <FormattedMessage
                        id="ReadMore"
                        defaultMessage="Read more"
                      />
                    </span>
                  </div>
                )}

                {displayReadLess && (
                  <div>
                    <span
                      className="read-more-less-text"
                      onClick={this.onReadLessClick}
                      id="ReadLessDescription"
                    >
                      <FormattedMessage
                        id="ReadLess"
                        defaultMessage="Read less"
                      />
                    </span>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}
export default injectIntl(SolutionDetails);
