import React, { Fragment } from 'react';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { Button } from '@cdk-uip/react-button';
import SolutionLogo from './SolutionLogo';
import Review from '../../solution-details/Reviews/Review';
import Response from './Response';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { getApolloClient } from '../../../containers/GraphQLClient';
import Spinner from '../../common/Spinner';
import '../../solution-details/SolutionDetails.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import SolutionTypeURLMapperUtil from '../../common/SolutionTypeURLMapperUtil';

export class ReviewDetails extends React.Component {
  state = {
    reviewing: false
  };

  onReviewHandler = async (reviewId, status) => {
    const client = getApolloClient();
    this.setState({ reviewing: true });
    await client
      .mutate({
        mutation: reviewsApproval,
        variables: {
          reviewId: reviewId,
          status: status,
          reviewedBy: this.props.userData.email
        }
      })
      .then(() => {
        this.setState({ reviewing: false });
        this.props
          .getUpdatedReview(reviewId, this.props.groupName, status)
          .then(async () => {
            await client
              .query({
                query: solutionDetails,
                variables: {
                  solutionId: this.props.review.solutionId
                }
              })
              .then(async solutionResponse => {
                await client
                  .mutate({
                    mutation: reviewCustomerEmailMutation,
                    variables: {
                      emailAddress: this.props.userData.email,
                      emailSubject: `Your Review of ${
                        solutionResponse.data.solutionDetails[0].overview.name
                      } is Approved`,
                      emailType: 'solutionUserReviewCustomerEmail',
                      firstName: this.props.userData.name,
                      solutionName:
                        solutionResponse.data.solutionDetails[0].overview.name,
                      solutionType:
                        SolutionTypeURLMapperUtil.getSolutionTypeURL(
                          solutionResponse.data.solutionDetails[0].solutionType
                        ) || ' ',
                      solutionId: this.props.review.solutionId
                    }
                  })
                  .then(async () => {
                    await client.mutate({
                      mutation: reviewDeveloperEmailMutation,
                      variables: {
                        emailAddress:
                          solutionResponse.data.solutionDetails[0].developerId,
                        emailSubject: `New Review Added for ${
                          solutionResponse.data.solutionDetails[0].overview.name
                        }`,
                        emailType: 'solutionUserReviewDeveloperEmail',
                        firstName:
                          solutionResponse.data.solutionDetails[0]
                            .developerName,
                        solutionName:
                          solutionResponse.data.solutionDetails[0].overview
                            .name,
                        solutionType:
                          SolutionTypeURLMapperUtil.getSolutionTypeURL(
                            solutionResponse.data.solutionDetails[0]
                              .solutionType
                          ) || ' ',
                        solutionId: this.props.review.solutionId,
                        publisher:
                          solutionResponse.data.solutionDetails[0].overview
                            .publisherName || ' ',
                        reviewerName: this.props.userData.name
                      }
                    });
                  });
              });
          });
      });
  };

  render() {
    const { review = {}, userData = {} } = this.props;
    return (
      <React.Fragment>
        {this.state.reviewing && <Spinner />}
        {!this.state.reviewing && (
          <div className="review-details-contianer">
            <LayoutGrid className="padding-less-grid">
              <LayoutGridCell span={3}>
                <SolutionLogo
                  {...this.props}
                  solution={review.solution}
                  loading={false}
                />
              </LayoutGridCell>
              <LayoutGridCell
                className="review-status-sec"
                span={
                  review.status === 'Submitted' || review.status === 'Approved'
                    ? 6
                    : 8
                }
              >
                {review.parentId ? (
                  <Response response={review} userData={userData} />
                ) : (
                  <Review review={review} charLimit={210} />
                )}
              </LayoutGridCell>
              <LayoutGridCell
                span={3}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {review.status && (
                  <Fragment>
                    {review.status === 'Submitted' && (
                      <div className="admin-review-actions">
                        <Button
                          onClick={() =>
                            this.onReviewHandler(review.id, 'Rejected')
                          }
                          className="review__reject"
                          data-cy={'btn_review_details_reject'}
                        >
                          {' '}
                          <FormattedMessage
                            id="ReviewDetails.button1"
                            defaultMessage="Reject"
                          />{' '}
                        </Button>
                        <Button
                          onClick={() =>
                            this.onReviewHandler(review.id, 'Approved')
                          }
                          className="review__approve fmk-cover__button--highlighted"
                          unelevated
                          data-cy={'btn_review_details_approve'}
                        >
                          {' '}
                          <FormattedMessage
                            id="ReviewDetails.button2"
                            defaultMessage="Approve"
                          />{' '}
                        </Button>
                      </div>
                    )}
                    {review.status === 'Approved' && (
                      <div className="admin-review-actions">
                        <Button
                          onClick={() =>
                            this.onReviewHandler(review.id, 'Deleted')
                          }
                          className="review__approve"
                          style={{ margin: 'auto', width: '50%' }}
                          data-cy={'btn_review_details_delete'}
                        >
                          {' '}
                          <FormattedMessage
                            id="ReviewDetails.button3"
                            defaultMessage="Delete"
                          />{' '}
                        </Button>
                      </div>
                    )}
                  </Fragment>
                )}
              </LayoutGridCell>
            </LayoutGrid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const reviewsApproval = gql`
  mutation($reviewId: ID!, $status: String!, $reviewedBy: String!) {
    updateReviewForAdmin(
      command: { id: $reviewId, status: $status, reviewedBy: $reviewedBy }
    ) {
      id
      status
      reviewedBy
    }
  }
`;

const reviewCustomerEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $solutionName: String
    $solutionType: String
    $solutionId: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionType: $solutionType
          solutionId: $solutionId
        }
      }
    ) {
      emailResponse
    }
  }
`;

const reviewDeveloperEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $solutionName: String
    $solutionType: String
    $solutionId: String
    $publisher: String
    $reviewerName: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionType: $solutionType
          solutionId: $solutionId
          publisher: $publisher
          reviewerName: $reviewerName
        }
      }
    ) {
      emailResponse
    }
  }
`;

export const solutionDetails = gql`
  query($solutionId: ID) {
    solutionDetails(id: $solutionId) {
      developerId
      developerName
      solutionType
      overview {
        name
        publisherName
      }
    }
  }
`;

export default injectIntl(withApollo(ReviewDetails));
