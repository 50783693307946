import React from 'react';
import './MediaGallery.scss';
import {
  MediaCard,
  IconClose,
  Dialog,
  Avatar,
  IconChevronLeft,
  IconChevronRight
} from 'cdk-radial';
import BackIcon from '../common/images/media_left_arrow.svg';
import ForwardIcon from '../common/images/media_right_arrow.svg';
import { injectIntl } from 'react-intl';

class GalleryImages extends React.Component {
  state = {
    dialogOpen: false,
    images: [],
    showNavigation: true,
    showLeftNavArrow: false,
    showRighNavArrow: true,
    scrollWidth: 1,
    clientWidth: 0,
    maxScrollLeftValue: -1
  };

  componentDidMount() {
    let {
      props: { images }
    } = this;
    if (!images) {
      return;
    }
    this.setState({ images });
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateScrollWidthAndClientWidth(
      prevState.scrollWidth,
      prevState.clientWidth
    );
  }

  updateScrollWidthAndClientWidth = (scrollWidth, clientWidth) => {
    let elmnt = document.getElementById('image-gallery-frame');
    if (!elmnt) {
      return;
    }
    if (
      elmnt.scrollWidth !== scrollWidth ||
      elmnt.clientWidth !== clientWidth
    ) {
      this.setState({
        scrollWidth: elmnt.scrollWidth,
        clientWidth: elmnt.clientWidth
      });
      this.showNavigationHandler();
    }
  };

  showNavigationHandler = () => {
    let maxScrollLeftValue = this.state.scrollWidth - this.state.clientWidth;
    if (maxScrollLeftValue <= 0) {
      this.setState({ showNavigation: false });
    } else {
      this.setState({ maxScrollLeftValue: maxScrollLeftValue });
    }
  };

  rightArrowClickHandler = () => {
    let elmnt = document.getElementById('image-gallery-frame');
    elmnt.scrollLeft = elmnt.scrollLeft + elmnt.clientWidth;
  };

  leftArrowClickHandler = () => {
    let elmnt = document.getElementById('image-gallery-frame');
    elmnt.scrollLeft = elmnt.scrollLeft - elmnt.clientWidth;
  };

  onscroll = () => {
    let elmnt = document.getElementById('image-gallery-frame');

    if (elmnt.scrollLeft === 0) {
      this.setState({ showLeftNavArrow: false });
    } else {
      this.setState({ showLeftNavArrow: true });
    }

    if (
      Math.floor(elmnt.scrollLeft) === Math.floor(this.state.maxScrollLeftValue)
    ) {
      this.setState({ showRighNavArrow: false });
    } else {
      this.setState({ showRighNavArrow: true });
    }
  };

  onClose = () => {
    this.setState({ dialogOpen: false });
  };

  onImageClickHandler = imageURL => {
    this.setState({ dialogOpen: true });
    this.setState({ imageClicked: imageURL });
  };

  render() {
    const {
      props: { intl, isPreview },
      state: { images, showNavigation, showLeftNavArrow, showRighNavArrow }
    } = this;
    return (
      <div className="image-gallery" onScroll={this.onscroll}>
        {showNavigation && showLeftNavArrow && (
          <Avatar
            className="image-gallery-frame_navigation-arrow-wrapper"
            style={{ float: 'left', left: '-10px' }}
            color="700"
            data-testid="left-avatar"
            icon={<IconChevronLeft />}
            size="small"
            src={BackIcon}
            variant="icon"
            onClick={this.leftArrowClickHandler}
          />
        )}

        <div id="image-gallery-frame" className="image-gallery-frame">
          <div className="image-gallery-images-container">
            {images.map((galleryImage, key) => {
              return (
                <div
                  data-cy={`div_image_gallery_image_${key}`}
                  key={key}
                  className="image-gallery-image"
                  style={{ backgroundImage: `url(${galleryImage})` }}
                  onClick={() => this.onImageClickHandler(galleryImage)}
                />
              );
            })}
          </div>
        </div>

        {showNavigation && showRighNavArrow && (
          <Avatar
            className="image-gallery-frame_navigation-arrow-wrapper"
            style={{ float: 'right', right: '-10px' }}
            color="700"
            data-testid="right-avatar"
            icon={<IconChevronRight />}
            size="small"
            src={ForwardIcon}
            variant="icon"
            onClick={this.rightArrowClickHandler}
          />
        )}

        {this.state.dialogOpen && !isPreview && (
          <Dialog
            isOpen={this.state.dialogOpen}
            disableOverlayClicked={true}
            className="imageGallery_dialog"
            onClose={this.onClose}
            // onClick={this.onClose}
            title="Gallery"
          >
            <img
              alt="App Logo"
              className="maximize_img"
              src={this.state.imageClicked}
            />
          </Dialog>
        )}
      </div>
    );
  }
}

export default injectIntl(GalleryImages);
