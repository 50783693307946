import React from 'react';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import StoreListingAPI from '../../../api/StoreListingAPI';

const solutionQuery = gql`
  query($id: ID, $listingVersion: String) {
    solutionCatalogList(id: $id, listingVersion: $listingVersion) {
      id
      overview {
        name
      }
    }
  }
`;

class SolutionItemCatalogBreadcrumb extends React.Component {
  state = {
    data: ''
  };

  getSolutionData = async (id, listingVersion) => {
    const {
      auth: { isAuthenticated, loginPending }
    } = this.props;
    if (!loginPending && isAuthenticated) {
      const client = getApolloClient();
      let data = await client.query({
        query: solutionQuery,
        variables: {
          id: id,
          listingVersion: listingVersion
        }
      });
      return data;
    } else {
      if (id) {
        return StoreListingAPI.anonymous.getSolutionById(id).then(response => {
          return response ? response.data : null;
        });
      }
    }
  };

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(_prevProps) {
    const {
      auth: { isAuthenticated }
    } = this.props;
    if (_prevProps.auth.isAuthenticated !== isAuthenticated) {
      let id = new URLSearchParams(
        this.props && this.props.location && this.props.location.search
      ).get('id');
      window.scrollTo(0, 0);
      let data = this.getSolutionData(
        id ? id : this.props.match.params.id,
        this.props.match.params.listingVersion
      );
      data.then(response => {
        let data = '';
        if (
          response &&
          response.data &&
          response.data.solutionCatalogList &&
          response.data.solutionCatalogList[0]
        ) {
          if (response.data.solutionCatalogList[0]['overview']) {
            data = response.data.solutionCatalogList[0]['overview'].name || '';
          }
        }
        this.setState({ data: data });
      });
    }
  }

  render() {
    return <span>{this.state.data}</span>;
  }
}

export default withAuth(SolutionItemCatalogBreadcrumb);
