import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Subheading2, Title } from '@cdk-uip/react-typography';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import moment from 'moment';
import { Button } from '@cdk-uip/react-button';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import Spinner from '../../common/Spinner';
import { Select } from '@cdk-uip/react-select';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import EmptyContent from '../../common/images/Empty State 2x.png';
import MarketplaceRole from './marketplace-role';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import {
  AutoExpansionPanelGroup,
  AutoExpansionPanel,
  ExpansionPanelHeader,
  ExpansionPanelHeaderTitle,
  ExpansionPanelContent,
  ExpansionPanelContentBody,
  ExpansionPanelHeaderDescription
} from '@cdk-uip/react-expansion-panel';

const BetaRequestStatus = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
};

export class MarketplaceUserAccess extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fetchingUsers: false,
      fetchingPendingUsers: false,
      users: [],
      pendingUsers: [],
      userIdDescSortOrder: true,
      sorting: false,
      sortCol: 0,
      pendingUsersSortAscending: false,
      activeUsersSortAscending: false
    };
  }
  sendAccessApprovalEmail = user => {
    const client = getApolloClient();
    client.mutate({
      mutation: sendEmailMutation,
      variables: {
        emailAddress: user.developerEmail.toLowerCase().trim(),
        emailSubject: 'Your Beta access has been approved',
        firstName: user.developerName,
        emailType: 'marketplaceBetaAccessApprovedEmail'
      }
    });
  };

  grantAccess = async (user, index) => {
    const client = getApolloClient();
    this.setState({ loading: true });
    const {
      auth: { userData }
    } = this.props;
    let betaUserResponse = await client.mutate({
      mutation: changeRequestStatusMutation,
      variables: {
        developerEmail: user.developerEmail,
        modifierName: userData.name,
        modifierEmail: userData.email,
        requestStatus: BetaRequestStatus.APPROVED
      }
    });

    let response = await client.mutate({
      mutation: updateUsersMutation,
      variables: {
        developerEmail: user.developerEmail.toLowerCase().trim(),
        developerName: user.developerName
          ? user.developerName.trim()
          : user.developerName,
        role: user.role,
        modifierName: userData.name,
        modifierEmail: userData.email
      }
    });
    if (
      betaUserResponse &&
      betaUserResponse.data &&
      response &&
      response.data &&
      response.data.grantAccess &&
      response.data.grantAccess.developerEmail
    ) {
      this.sendAccessApprovalEmail(user);

      this.removeUserFromPendingUsersState(index);

      this.addUserToUsersState(user, userData);

      this.setState({ loading: false });
    }
  };

  removeUserFromPendingUsersState = index => {
    const pendingUsersCopy = [...this.state.pendingUsers];
    pendingUsersCopy.splice(index, 1);
    this.setState({
      pendingUsers: pendingUsersCopy
    });
  };

  addUserToUsersState = (user, userData) => {
    const matchedIndex = this.state.users.findIndex(
      u => u.developerEmail === user.developerEmail.toLowerCase().trim()
    );
    if (matchedIndex !== -1) {
      let usersCopy = [...this.state.users];
      usersCopy[matchedIndex] = {
        ...usersCopy[matchedIndex],
        ...{
          role: user.role,
          modifiedBy: {
            modifierName: userData.name,
            modifierEmail: userData.email
          },
          modifiedOn: moment().toISOString()
        }
      };
      this.setState({
        users: usersCopy
      });
    } else {
      const updatedUsers = [
        ...this.state.users,
        {
          developerEmail: user.developerEmail.toLowerCase().trim(),
          developerName: user.developerName,
          role: user.role,
          modifiedBy: {
            modifierName: userData.name,
            modifierEmail: userData.email
          },
          modifiedOn: moment().toISOString()
        }
      ];
      this.setState({
        users: updatedUsers
      });
    }
  };

  rejectAccess = async (user, index) => {
    const client = getApolloClient();
    this.setState({ loading: true });
    const {
      auth: { userData }
    } = this.props;
    let betaUserResponse = await client.mutate({
      mutation: changeRequestStatusMutation,
      variables: {
        developerEmail: user.developerEmail,
        modifierName: userData.name,
        modifierEmail: userData.email,
        requestStatus: BetaRequestStatus.REJECTED
      }
    });

    if (betaUserResponse && betaUserResponse.data) {
      //update state
      let pendingUsersCopy = [...this.state.pendingUsers];
      pendingUsersCopy[index] = {
        ...pendingUsersCopy[index],
        requestStatus: BetaRequestStatus.REJECTED
      };
      this.setState({
        pendingUsers: pendingUsersCopy
      });
      this.setState({ loading: false });
    }
  };

  revokeAccess = async (user, index) => {
    const client = getApolloClient();
    this.setState({ loading: true });
    const {
      auth: { userData }
    } = this.props;
    let response = await client.mutate({
      mutation: updateUsersMutation,
      variables: {
        developerEmail: user.developerEmail.toLowerCase().trim(),
        developerName: user.developerName ? user.developerName.trim() : '',
        role: MarketplaceRole.REVOKED,
        modifierName: userData.name,
        modifierEmail: userData.email
      }
    });

    if (response && response.data) {
      //update state
      let usersCopy = [...this.state.users];
      usersCopy[index] = {
        ...usersCopy[index],
        role: MarketplaceRole.REVOKED
      };
      this.setState({
        users: usersCopy
      });
      this.setState({ loading: false });
    }
  };

  handleInputChange = (event, index) => {
    const target = event.target;
    const value = target.value;
    this.setState({
      pendingUsers: this.state.pendingUsers.map((pendingUser, puIndex) => {
        if (index === puIndex) {
          return {
            ...pendingUser,
            role: value
          };
        }
        return pendingUser;
      })
    });
  };

  onPendingUsersSort = async (sortCol = 0) => {
    const pendingUsersSortAscending =
      this.state.sortCol === sortCol
        ? !this.state.pendingUsersSortAscending
        : true;
    let pendingUsersToSort = [...this.state.pendingUsers];
    let sortedPendingUsers = pendingUsersToSort.sort((a, b) => {
      if (sortCol === 0) {
        if (!a.developerName) {
          return 1;
        }
        if (!b.developerName) {
          return -1;
        }
        return (
          a.developerName.localeCompare(b.developerName) *
          (pendingUsersSortAscending ? 1 : -1)
        );
      }
      return 0;
    });
    this.setState({
      pendingUsersSortAscending,
      pendingUsers: [...sortedPendingUsers]
    });
  };

  onActiveUsersSort = async (sortCol = 0) => {
    const activeUsersSortAscending =
      this.state.sortCol === sortCol
        ? !this.state.activeUsersSortAscending
        : true;
    let activeUsersToSort = [...this.state.users];
    let sortedActiveUsers = activeUsersToSort.sort((a, b) => {
      if (sortCol === 0) {
        if (!a.developerName) {
          return 1;
        }
        if (!b.developerName) {
          return -1;
        }
        return (
          a.developerName.localeCompare(b.developerName) *
          (activeUsersSortAscending ? 1 : -1)
        );
      }
      return 0;
    });
    this.setState({
      activeUsersSortAscending,
      users: [...sortedActiveUsers]
    });
  };

  fetchUser = async () => {
    const client = getApolloClient();
    this.setState({ fetchingUsers: true });
    let response = await client.query({
      query: getUsers
    });
    if (response && response.data && response.data.users) {
      await this.setState({ users: response.data.users });
      await this.onActiveUsersSort(0);
    }
    await this.setState({ fetchingUsers: false });
  };

  fetchPendingUsers = async () => {
    const client = getApolloClient();
    this.setState({ fetchingPendingUsers: true });
    let response = await client.query({
      query: getPendingUsers
    });

    if (response && response.data && response.data.pendingBetaAccessRequests) {
      this.setState({ pendingUsers: response.data.pendingBetaAccessRequests });
      await this.onPendingUsersSort(0);
    }
    await this.setState({ fetchingPendingUsers: false });
  };

  pendingUsersSortDirection(col) {
    return this.state.sortCol === col
      ? this.state.pendingUsersSortAscending
        ? 'asc'
        : 'desc'
      : 'none';
  }

  activeUsersSortDirection(col) {
    return this.state.sortCol === col
      ? this.state.activeUsersSortAscending
        ? 'asc'
        : 'desc'
      : 'none';
  }

  componentDidMount() {
    this.fetchUser();
    this.fetchPendingUsers();
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="component-content">
        {this.state.fetchingUsers || this.state.loading ? (
          <LayoutGrid className="max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="save-loading-spinner">
              <Spinner />
            </LayoutGridCell>
          </LayoutGrid>
        ) : (
          <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="myaccount-component-content">
              <LayoutGrid className="fm-page-component-grid">
                <LayoutGridCell
                  span={12}
                  className="fm-page-content-title-cell max-width-resp"
                >
                  <Title className="fm-page-content-title">
                    <span>
                      <FormattedMessage
                        id="BetaUserApproval.title"
                        defaultMessage="Beta Access"
                      />
                    </span>
                  </Title>
                  <Subheading2 className="fm-page-content-title-note">
                    <FormattedMessage
                      id="BetaUserApproval.description"
                      defaultMessage="View users, Approve or Reject access and send email confirmation to Marketplace User"
                    />
                  </Subheading2>
                </LayoutGridCell>
              </LayoutGrid>
            </LayoutGridCell>

            <LayoutGridCell span={12} className="user-data-grid">
              <div className="max-width-resp">
                <AutoExpansionPanelGroup>
                  <AutoExpansionPanel>
                    <ExpansionPanelHeader>
                      <ExpansionPanelHeaderTitle>
                        Pending Requests
                      </ExpansionPanelHeaderTitle>
                      <ExpansionPanelHeaderDescription>
                        Accept or Reject user access request
                      </ExpansionPanelHeaderDescription>
                    </ExpansionPanelHeader>
                    <ExpansionPanelContent>
                      <ExpansionPanelContentBody>
                        {this.state.fetchingPendingUsers ? (
                          <div>
                            <Spinner />
                          </div>
                        ) : this.state.pendingUsers &&
                          this.state.pendingUsers.length > 0 ? (
                          <div className={'max-width-resp'}>
                            <DataTable
                              sortable
                              className={'table-list'}
                              style={{
                                tableLayout: 'fixed',
                                width: '100%',
                                marginTop: '20px',
                                whiteSpace: 'normal'
                              }}
                            >
                              <DataTableHeader>
                                <DataTableRow className="lead-row">
                                  <DataTableHeaderCell
                                    nonNumeric
                                    sortable
                                    sortDirection={this.pendingUsersSortDirection(
                                      0
                                    )}
                                    onClick={() => this.onPendingUsersSort(0)}
                                    style={{
                                      width: '13%',
                                      cursor: 'pointer'
                                    }}
                                    className="leads-header-column leads-sort-header-column"
                                  >
                                    Name
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '20%'
                                    }}
                                  >
                                    Email
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '10%'
                                    }}
                                  >
                                    Status
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '10%'
                                    }}
                                  >
                                    Requested on
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '10%'
                                    }}
                                  >
                                    Updated by
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '10%'
                                    }}
                                  >
                                    Updated on
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '13%'
                                    }}
                                  >
                                    Role
                                  </DataTableHeaderCell>
                                </DataTableRow>
                              </DataTableHeader>
                              <DataTableBody>
                                {this.state.pendingUsers &&
                                  this.state.pendingUsers.map(
                                    (pendingUser, index) => {
                                      return (
                                        <DataTableRow key={index}>
                                          <DataTableCell
                                            style={{
                                              textAlign: 'left',
                                              wordWrap: 'break-word'
                                            }}
                                          >
                                            {pendingUser.developerName}
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{
                                              textAlign: 'left',
                                              wordWrap: 'break-word'
                                            }}
                                          >
                                            {pendingUser.developerEmail}
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{
                                              textAlign: 'left',
                                              wordWrap: 'break-word'
                                            }}
                                          >
                                            {pendingUser.requestStatus}
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{
                                              textAlign: 'left',
                                              wordWrap: 'break-word'
                                            }}
                                          >
                                            {pendingUser.requestDate
                                              ? moment(
                                                  pendingUser.requestDate
                                                ).format('DD MMM YYYY, hh:mm a')
                                              : `-`}
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{
                                              textAlign: 'left',
                                              wordWrap: 'break-word'
                                            }}
                                          >
                                            {pendingUser.requestProcessedBy &&
                                            pendingUser.requestProcessedBy
                                              .modifierName
                                              ? pendingUser.requestProcessedBy
                                                  .modifierName
                                              : null}
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{
                                              textAlign: 'left',
                                              wordWrap: 'break-word'
                                            }}
                                          >
                                            {pendingUser.requestProcessedOn
                                              ? moment(
                                                  pendingUser.requestProcessedOn
                                                ).format('DD MMM YYYY, hh:mm a')
                                              : '-'}
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{ textAlign: 'left' }}
                                          >
                                            <Select
                                              label="Select Role"
                                              value={pendingUser.role}
                                              name="role"
                                              onChange={e => {
                                                this.handleInputChange(
                                                  e,
                                                  index
                                                );
                                              }}
                                              required
                                            >
                                              <option value="beta-developer">
                                                {
                                                  MarketplaceRole[
                                                    'beta-developer'
                                                  ]
                                                }
                                              </option>
                                              <option value="beta-buyer">
                                                {MarketplaceRole['beta-buyer']}
                                              </option>
                                            </Select>
                                          </DataTableCell>
                                          <DataTableCell
                                            style={{ textAlign: 'left' }}
                                          >
                                            <Button
                                              className="button-right"
                                              disabled={!pendingUser.role}
                                              compact={'true'}
                                              type="button"
                                              primary
                                              onClick={e => {
                                                this.grantAccess(
                                                  pendingUser,
                                                  index
                                                );
                                              }}
                                            >
                                              Approve
                                            </Button>
                                          </DataTableCell>

                                          <DataTableCell
                                            style={{ textAlign: 'left' }}
                                          >
                                            <Button
                                              compact={'true'}
                                              disabled={
                                                pendingUser.requestStatus ===
                                                BetaRequestStatus.REJECTED
                                              }
                                              onClick={e => {
                                                this.rejectAccess(
                                                  pendingUser,
                                                  index
                                                );
                                              }}
                                            >
                                              Reject
                                            </Button>
                                          </DataTableCell>
                                        </DataTableRow>
                                      );
                                    }
                                  )}
                              </DataTableBody>
                            </DataTable>
                          </div>
                        ) : (
                          <div className="empty-content-div">
                            <img
                              alt="Empty Content"
                              className="empty-content-card-img"
                              src={EmptyContent}
                            />
                            <div className="empty-content-card-message">
                              No pending requests yet, Check back soon!
                            </div>
                          </div>
                        )}
                      </ExpansionPanelContentBody>
                    </ExpansionPanelContent>
                  </AutoExpansionPanel>
                </AutoExpansionPanelGroup>

                <AutoExpansionPanelGroup>
                  <AutoExpansionPanel>
                    <ExpansionPanelHeader>
                      <ExpansionPanelHeaderTitle>
                        Active Users
                      </ExpansionPanelHeaderTitle>
                      <ExpansionPanelHeaderDescription>
                        View or Revoke access to current users
                      </ExpansionPanelHeaderDescription>
                    </ExpansionPanelHeader>
                    <ExpansionPanelContent>
                      <ExpansionPanelContentBody>
                        {this.state.fetchingUsers ? (
                          <div>
                            <Spinner />
                          </div>
                        ) : this.state.users && this.state.users.length > 0 ? (
                          <div className={'max-width-resp'}>
                            <DataTable
                              sortable
                              className={'table-list'}
                              style={{
                                tableLayout: 'fixed',
                                width: '100%',
                                marginTop: '20px',
                                whiteSpace: 'normal'
                              }}
                            >
                              <DataTableHeader>
                                <DataTableRow className="lead-row">
                                  <DataTableHeaderCell
                                    nonNumeric
                                    sortable
                                    sortDirection={this.activeUsersSortDirection(
                                      0
                                    )}
                                    onClick={() => this.onActiveUsersSort(0)}
                                    style={{
                                      width: '20%',
                                      cursor: 'pointer'
                                    }}
                                    className="leads-header-column leads-sort-header-column"
                                  >
                                    Name
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell
                                    className="leads-header-column"
                                    style={{
                                      width: '20%'
                                    }}
                                  >
                                    Email
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell className="leads-header-column">
                                    Role
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell className="leads-header-column">
                                    Updated on
                                  </DataTableHeaderCell>
                                  <DataTableHeaderCell className="leads-header-column">
                                    Updated by
                                  </DataTableHeaderCell>
                                </DataTableRow>
                              </DataTableHeader>
                              <DataTableBody>
                                {this.state.users &&
                                  this.state.users.map((user, index) => {
                                    return (
                                      <DataTableRow key={index}>
                                        <DataTableCell
                                          style={{
                                            textAlign: 'left',
                                            wordWrap: 'break-word'
                                          }}
                                        >
                                          {user.developerName}
                                        </DataTableCell>
                                        <DataTableCell
                                          style={{
                                            textAlign: 'left',
                                            wordWrap: 'break-word'
                                          }}
                                        >
                                          {user.developerEmail}
                                        </DataTableCell>
                                        <DataTableCell
                                          style={{
                                            textAlign: 'left',
                                            wordWrap: 'break-word'
                                          }}
                                        >
                                          {MarketplaceRole[user.role]}
                                        </DataTableCell>
                                        <DataTableCell
                                          style={{
                                            textAlign: 'left',
                                            wordWrap: 'break-word'
                                          }}
                                        >
                                          {user.modifiedOn
                                            ? moment(user.modifiedOn).format(
                                                'DD MMM YYYY, hh:mm a'
                                              )
                                            : '-'}
                                        </DataTableCell>
                                        <DataTableCell
                                          style={{
                                            textAlign: 'left',
                                            wordWrap: 'break-word'
                                          }}
                                        >
                                          {user.modifiedBy
                                            ? user.modifiedBy.modifierName
                                            : ''}
                                        </DataTableCell>
                                        <DataTableCell
                                          style={{
                                            textAlign: 'left',
                                            wordWrap: 'break-word'
                                          }}
                                        >
                                          <Button
                                            compact={'true'}
                                            disabled={
                                              user.role ===
                                              MarketplaceRole.REVOKED
                                            }
                                            onClick={e => {
                                              this.revokeAccess(user, index);
                                            }}
                                          >
                                            Revoke
                                          </Button>
                                        </DataTableCell>
                                      </DataTableRow>
                                    );
                                  })}
                              </DataTableBody>
                            </DataTable>
                          </div>
                        ) : (
                          <div className="empty-content-div">
                            <img
                              alt="Empty Content"
                              className="empty-content-card-img"
                              src={EmptyContent}
                            />
                            <div className="empty-content-card-message">
                              No active users yet, Check back soon!
                            </div>
                          </div>
                        )}
                      </ExpansionPanelContentBody>
                    </ExpansionPanelContent>
                  </AutoExpansionPanel>
                </AutoExpansionPanelGroup>
              </div>
            </LayoutGridCell>
          </LayoutGrid>
        )}
      </div>
    );
  }
}

const sendEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: { emailType: $emailType }
      }
    ) {
      emailResponse
    }
  }
`;

const updateUsersMutation = gql`
  mutation(
    $developerEmail: String
    $developerName: String
    $role: String
    $modifierName: String
    $modifierEmail: String
  ) {
    grantAccess(
      command: {
        developerEmail: $developerEmail
        developerName: $developerName
        role: $role
        modifiedBy: {
          modifierName: $modifierName
          modifierEmail: $modifierEmail
        }
      }
    ) {
      developerEmail
      role
      modifiedOn
    }
  }
`;

const changeRequestStatusMutation = gql`
  mutation(
    $developerEmail: String
    $modifierName: String
    $modifierEmail: String
    $requestStatus: String
  ) {
    updateBetaAccessRequest(
      command: {
        developerEmail: $developerEmail
        requestProcessedBy: {
          modifierName: $modifierName
          modifierEmail: $modifierEmail
        }
        requestStatus: $requestStatus
      }
    ) {
      developerEmail
    }
  }
`;

const getUsers = gql`
  {
    users {
      developerEmail
      developerName
      role
      modifiedBy {
        modifierName
        modifierEmail
      }
      modifiedOn
    }
  }
`;

const getPendingUsers = gql`
  {
    pendingBetaAccessRequests {
      developerEmail
      requestDate
      developerName
      requestStatus
      requestProcessedBy {
        modifierName
        modifierEmail
      }
      requestProcessedOn
    }
  }
`;

const MarketplaceUserAccessComp = injectIntl(
  withApollo(
    compose(graphql(sendEmailMutation, { name: 'saveLeadDetails' }))(
      MarketplaceUserAccess
    )
  )
);

class MarketplaceUserAccessContainer extends React.Component {
  render() {
    return this.props.auth.userData.sub ? (
      <MarketplaceUserAccessComp {...this.props} />
    ) : (
      <></>
    );
  }
}
export default withAuth(MarketplaceUserAccessContainer);
