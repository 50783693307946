import React, { Component } from 'react';
import { Dialog, DialogHeader, DialogBody, DialogFooter, DialogFooterButton } from "@cdk-uip/react-dialog"
import { TextField, TextFieldHelperText } from '@cdk-uip/react-text-field';
import { TextArea } from "@cdk-uip/react-text-area"
import './DeniedActivationDialog.scss';
const DIALOG_HEADER = "Reason for denied activation";
const DIALOG_HELP_TEXT = "This reason will be sent to the subscriber";
const CANCEL = "CANCEL";
const SEND = "SEND";

class DeniedActivationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value:""
        };
    }

    render() {
        const { open, handleCancel, selectedDeniedSubscriptionId, handleSend } = this.props;
        return (
            <Dialog
                open={open}
                onCancel={handleCancel}
            >
                <DialogHeader className="denied-activation-dialog-header">{DIALOG_HEADER}</DialogHeader>
                <DialogBody>
                    <TextArea
                        style={{paddingTop: '30px'}}
                        className="denied-activation-dialog-textArea"
                        value={this.state.value}
                        onChange={e => this.setState({ value: e.target.value })}
                        label={DIALOG_HEADER}
                        dense
                        required
                    />
                    <div className="denied-activation-dialog-help-text">{DIALOG_HELP_TEXT}</div>
                    <DialogFooter>
                        <DialogFooterButton onClick={handleCancel}>
                            {CANCEL}
                        </DialogFooterButton>
                        <DialogFooterButton onClick={()=>handleSend(selectedDeniedSubscriptionId, this.state.value)}>
                            {SEND}
                        </DialogFooterButton>
                    </DialogFooter>
                </DialogBody>
            </Dialog>
        )
    }
}

export default DeniedActivationDialog;