import { UtilizationPanel } from '@cdk-prod/fortellis-app-utilization-component';
import gql from 'graphql-tag';
import { getApolloClient } from './GraphQLClient';
import { fortellisTheme } from 'cdk-radial';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import localeData from '@cdk-prod/fortellis-app-utilization-component/src/locales/data.json';
import React from 'react';

const moment = require('moment');

const locale = 'en';
const messages = localeData['en'];

const UtilizationContainer = props => {
  const { solutionId, startDate, endDate, solutions, entity } = props;

  const getUtilizationQuery = gql`
    query($solutionId: String, $startDate: String, $endDate: String) {
      getUtilization(
        solutionId: $solutionId
        startDate: $startDate
        endDate: $endDate
      ) {
        utilizationByAPI {
          appId
          apiId
          apiType
          apiName
          apiOrgName
          apiOrgId
          transactionCount
          pricingPlans {
            id
            type
            name
            startDate
            endDate
            transactionCount
            containers {
              name
              description
              isFreeContainer
              quota
              endPoints {
                route
                verb
                summary
                transactionCount
              }
            }
          }
        }
      }
    }
  `;

  const getUtilizationData = async ({ solutionId, startDate, endDate }) => {
    try {
      const client = getApolloClient();
      const response = await client.query({
        query: getUtilizationQuery,
        variables: {
          solutionId: solutionId,
          startDate: moment(startDate)
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(endDate)
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
        }
      });
      return response;
    } catch (error) {
      console.error('Error fetching utilization data:', error);
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <ThemeProvider theme={fortellisTheme}>
        <UtilizationPanel
          solutionId={solutionId}
          getUtilizationData={getUtilizationData}
          startDate={startDate}
          endDate={endDate}
          organization={entity}
          apps={solutions}
        />
      </ThemeProvider>
    </IntlProvider>
  );
};

export default UtilizationContainer;
