import React, { useState, useEffect } from 'react';
import ReviewTab from '../components/solution-details/Reviews/ReviewsTab';
import StoreListingAPI from '../api/StoreListingAPI';

function ReviewAnonTab(props) {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState(null);
  const [isDataFetched, setDataFetched] = useState(false);
  const [reviewsFetched, setReviewsFetched] = useState(false);
  function processData({ data }) {
    return {
      reviews:
        data.publishedReviewsForUser && data.publishedReviewsForUser.reviews
          ? data.publishedReviewsForUser.reviews
          : [],
      offset:
        data.publishedReviewsForUser &&
        data.publishedReviewsForUser.pageInfo &&
        data.publishedReviewsForUser.pageInfo.offset
          ? data.publishedReviewsForUser.pageInfo.offset
          : null,
      numberOfReviews:
        data.getRatingStatistics && data.getRatingStatistics.numberOfReviews
          ? data.getRatingStatistics.numberOfReviews
          : null,
      loading: data.loading
    };
  }
  async function getReviewsForAnon() {
    setLoading(true);
    let fetchedReviews = await StoreListingAPI.anonymous.getReviewsForSolution(
      props.solutionId,
      true
    );
    setReviews(processData(fetchedReviews.data));
    setLoading(false);
    setReviewsFetched(true);
  }

  useEffect(() => {
    if (!isDataFetched) {
      getReviewsForAnon();
      setDataFetched(true);
    }
  });

  //need to check below code
  return reviewsFetched ? (
    <ReviewTab {...reviews} loading={loading} />
  ) : (
    <div />
  );
}

export default ReviewAnonTab;
