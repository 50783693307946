import SolutionsListForAdmin from '../components/admin-account/solution-moderation/SolutionsListForAdmin';
import SolutionStatus from './../../src/components/common/SolutionStatus';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const queryAdminSolution = gql`
  query($status: String) {
    solutionList(status: $status) {
      id
      listingVersion
      status
      submittedOn
      developerName
      developerId
      solutionType
      orgId
      dnpAppName
      overview {
        name
        version
        solutionLogo
      }
      comments {
        commentText
      }
    }
    solutionCount {
      submitted
      inProgress
      published
    }
  }
`;
export const mapDataToProps = ({ data }) => ({
  list: data.solutionList || [],
  solutionCount: data.solutionCount,
  loading: data.loading
});

export default graphql(queryAdminSolution, {
  props: mapDataToProps,
  options: () => ({
    variables: {
      status: SolutionStatus.SUBMITTED
    }
  })
})(SolutionsListForAdmin);
