import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { SecureRoute } from '@okta/okta-react';
import { injectIntl } from 'react-intl';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';
import { SolutionSubmissionComponent } from '@cdk-prod/marketplace-solution-submission-component';
import { getApolloClient } from '../../../containers/GraphQLClient';
import SolutionStatus from '../../common/SolutionStatus';
import gql from 'graphql-tag';
import SolutionTypeURLMapperUtil from '../../common/SolutionTypeURLMapperUtil';
import FortellisConstants from '../../common/FortellisConstants';
import moment from 'moment';
import { sendAmplitudeData } from '../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../utils/amplitude-constants';

class MySolution extends React.Component {
  state = {
    submitForReview: false
  };
  onPreviewHandler = e => {
    this.logAmplitudeEvent(
      AMPLITUDE_EVENTS.PREVIEW_IN_CREATE_AN_APP_LISTING,
      e
    );
    const {
      match: { params }
    } = this.props;
    const url = `${
      environmentURLs.marketplaceURL
    }marketplace-account/mysolutions/preview/${params.type}/${params.id}/${
      e.listingVersion
    }`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  onSaveHandler = e => {
    this.logAmplitudeEvent(AMPLITUDE_EVENTS.SAVE_IN_CREATE_AN_APP_LISTING, e);
  };
  onSubmitForReviewHandler = async e => {
    const {
      code,
      developerId,
      developerName,
      submittedOn,
      solutionName,
      status,
      isRejected
    } = e;
    const formStaticValues = {
      developerId: developerId,
      developerName: developerName
    };

    if (code === 200 || code === '200') {
      this.setState({ submitForReview: true });
      await this._sendEmailForStatus(
        formStaticValues,
        `Your Fortellis App Has Been Submitted`,
        isRejected ? `solutionResubmittedEmail` : `solutionPendingReviewEmail`,
        solutionName,
        status,
        submittedOn
      );
    }
    this.logAmplitudeEvent(
      AMPLITUDE_EVENTS.SUBMIT_FOR_REVIEW_IN_CREATE_AN_APP_LISTING,
      e
    );
  };

  onCancelHandler = e => {
    this.logAmplitudeEvent(AMPLITUDE_EVENTS.CANCEL_IN_CREATE_AN_APP_LISTING, e);
    let {
      props: {
        history: { push },
        match: { params }
      }
    } = this;
    const { status } = e;
    if (status === SolutionStatus.DRAFT) {
      push('/marketplace-account/mysolutions');
    } else {
      push(
        `/marketplace-account/mysolutions/${params.type}/${
          params.id
        }/solution-management/insights`
      );
    }
  };

  _sendEmailForStatus = async (
    formStaticValues,
    emailSubject,
    emailType,
    solutionName,
    solutionStatus,
    updatedOn
  ) => {
    const {
      auth: { userData }
    } = this.props;
    const client = getApolloClient();
    let userNameData = formStaticValues.developerName.split(' ');
    let loggedinUserData = userData ? userData.name.split(' ') : [];

    await client.mutate({
      mutation: sent_status_email,
      variables: {
        emailAddress: formStaticValues.developerId,
        emailSubject: emailSubject,
        emailType: emailType,
        solutionName: solutionName,
        solutionReviewComments: ``,
        firstName:
          userNameData && userNameData.length > 0 ? userNameData[0] : ``,
        lastName: userNameData && userNameData.length > 1 ? userNameData[1] : ``
      }
    });
    let adminIds = environmentURLs.fortellisAdmin.split(',');
    await adminIds.map(adminId => {
      return client.mutate({
        mutation: sent_status_email,
        variables: {
          emailAddress: adminId,
          emailSubject: 'Fortellis App Has Been ' + solutionStatus,
          emailType: 'solutionOperationsAdminEmail',
          solutionName: solutionName,
          solutionStatus: solutionStatus,
          solutionReviewComments: ``,
          firstName:
            loggedinUserData && loggedinUserData.length > 0
              ? loggedinUserData[0]
              : ``,
          lastName:
            loggedinUserData && loggedinUserData.length > 1
              ? loggedinUserData[1]
              : ``,
          updatedOn: updatedOn ? moment(updatedOn).format('DD MMM YYYY') : ''
        }
      });
    });
  };

  logAmplitudeEvent = (event, e) => {
    const amplitudeProperties = {
      [AMPLITUDE_PROPERTIES.ORG_ID]: this.props.entity?.id,
      [AMPLITUDE_PROPERTIES.ORG_NAME]: this.props.entity?.name,
      [AMPLITUDE_PROPERTIES.APP_ID]: this.props.match.params?.id,
      [AMPLITUDE_PROPERTIES.APP_LISTING_VERSION]: e?.listingVersion,
      [AMPLITUDE_PROPERTIES.VISIBILITY]:
        e?.solutionVisibility?.solutionVisibilityType,
      [AMPLITUDE_PROPERTIES.APP_ACTIVATION]:
        e?.appSubscriptions?.appSubscriptionsType,
      [AMPLITUDE_PROPERTIES.APP_LISTING_STATUS]: e?.status,
      [AMPLITUDE_PROPERTIES.APP_NAME]: e?.solutionName,
      [AMPLITUDE_PROPERTIES.PRIVATE_SOLUTION_USER_EMAILS]:
        e?.solutionVisibility?.privateSolutionUsers
    };

    const filteredAmplitudeProperties = Object.fromEntries(
      Object.entries(amplitudeProperties).filter(
        ([_, value]) => value !== undefined
      )
    );
    sendAmplitudeData(event, filteredAmplitudeProperties);
  };

  render() {
    const INSIGHTS = 'insights';
    const {
      auth: { userData, accessToken },
      entity: { id },
      match: { params }
    } = this.props;
    const entityName = (this.props.entity && this.props.entity.name) || '';
    let activateOnly = true;
    if (environmentURLs.env === 'prod') {
      if (entityName.toLowerCase().includes('cdk global')) {
        activateOnly = false;
      }
    } else {
      if (entityName.toLowerCase().includes('cdk')) {
        activateOnly = false;
      }
    }
    // whitelisting for certain org
    const SubscriptionOrgs = process.env.REACT_APP_SUBSCRIPTION_ORGS || '';
    if (SubscriptionOrgs.split('|').indexOf(entityName) >= 0) {
      activateOnly = false;
    }

    const objSolnSubmissionParam = {
      orgId: id,
      solnId: params.id,
      type: params.type,
      listingVersion: params.listingVersion,
      token: accessToken,
      serviceUrl: `${environmentURLs.marketplaceURL}graphql`,
      user: {
        email: userData.email,
        name: userData.name
      },
      activateOnly: activateOnly,
      environmentURLs,
      featureGates: {}
    };
    if (this.state.submitForReview) {
      return (
        <Redirect
          to={{
            pathname: SolutionTypeURLMapperUtil.getSolutionListingURL(
              FortellisConstants.FORTELLIS,
              params.id,
              INSIGHTS
            )
          }}
        />
      );
    } else {
      return (
        <Switch>
          <SecureRoute
            path="/marketplace-account/mysolutions/:type/:id/solution-management/:activeMenuId/:listingVersion"
            exact
            render={() =>
              this.props.match.params && this.props.match.params.id ? (
                <SolutionSubmissionComponent
                  objSolnSubmission={objSolnSubmissionParam}
                  onPreviewHandler={this.onPreviewHandler}
                  onSubmitForReviewHandler={this.onSubmitForReviewHandler}
                  onCancelHandler={this.onCancelHandler}
                  onSaveHandler={this.onSaveHandler}
                />
              ) : (
                <Redirect to={`/marketplace-account/mysolutions`} />
              )
            }
          />
        </Switch>
      );
    }
  }
}

export const sent_status_email = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $solutionName: String
    $solutionReviewComments: String
    $firstName: String!
    $lastName: String
    $solutionStatus: String
    $updatedOn: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionReviewComments: $solutionReviewComments
          solutionStatus: $solutionStatus
          updatedOn: $updatedOn
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;
class MySolutionContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <MySolution {...this.props} />
    ) : (
      <></>
    );
  }
}
export default injectIntl(withAuth(MySolutionContainer));
