import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  showMoreInProgress: false
};

const solutionCardGroupSlice = createSlice({
  name: 'solutionCardGroup',
  initialState: initialState,
  reducers: {
    showMoreInProgressIncomplete: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.showMoreInProgress = true;
    },
    showMoreInProgressComplete: state => {
      state.showMoreInProgress = false;
    }
  }
});

export const {
  showMoreInProgressIncomplete,
  showMoreInProgressComplete
} = solutionCardGroupSlice.actions;

export const selector = state =>
  (state &&
    state.solutionCardGroup &&
    state.solutionCardGroup.showMoreInProgress) ||
  false;

export default solutionCardGroupSlice.reducer;
