import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const FreeTrialFlag = ({ verticalAlign }) => {
  return (
    <div
      className="free-trial-indicator-text"
      style={{ verticalAlign: verticalAlign }}
    >
      <FormattedMessage
        id="FreeTrialFlag.freetrial"
        defaultMessage="Free Trial"
      />
    </div>
  );
};

export default injectIntl(FreeTrialFlag);
