import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import { Header } from '@fortellis/header';
import config from './config';
import './header.scss';
import { LocaleConsumer } from '@fortellis/locale/dist/LocaleContext';

function AuthHeader({
  auth,
  entityContext,
  localeContext,
  location,
  mobile,
  onPermissionReception,
  onAuthenticated,
  ...props
}) {
  const { entities, entitiesIndex, entitiesLoaded } = entityContext;
  const [userPermissionsReceived, setUserPermissionsReceived] = useState(false);
  const [isAuthenticated, setAuthentication] = useState(false);
  // const classes = classnames({
  //   'frc-header--cover': location.pathname === '/'
  // });

  //called when component will update or mount
  useEffect(() => {
    if (!isAuthenticated && auth.isAuthenticated) {
      setAuthentication(true);
      onAuthenticated(true);
    }
  });

  async function fetchUserPermissions(email) {
    const queryResponse = await props.client.query({
      query: gql`
        query($developerEmail: String) {
          userPermissions(developerEmail: $developerEmail) {
            permissions
          }
        }
      `,
      variables: { developerEmail: email }
    });

    if (queryResponse && queryResponse.data) {
      setUserPermissionsReceived(true);
      onPermissionReception(
        queryResponse.data.userPermissions.permissions,
        entities[entitiesIndex]
      );
    } else {
      setUserPermissionsReceived(false);
      onPermissionReception([], '');
    }
  }
  if (entitiesLoaded)
    if (auth.isAuthenticated && auth.userData && !userPermissionsReceived) {
      fetchUserPermissions(auth.userData.email);
    }

  return (
    <LocaleConsumer>
      {localeContext => (
        <Header
          // className={classes}
          mobile={mobile}
          authenticated={auth.isAuthenticated}
          entityContext={entityContext}
          localeContext={localeContext}
          login={auth.login}
          logout={auth.logout}
          nav={config.nav}
          routerLink={Link}
          selectedKey="marketplace"
          signup={config.signup.url}
          user={auth.userData}
        />
      )}
    </LocaleConsumer>
  );
}

AuthHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withApollo(withAuth(withEntityContext(AuthHeader))));
