import SolutionStatus from '../common/SolutionStatus';

export const config = {
  formId: 'features',
  formTitle: 'Features',
  formValueType: 'Array',
  showDynamicLabel: true,
  noFormFieldNote:
    'No Features available. Please use add functionality to add new items.',
  haveDelete: true,
  tabs: [
    {
      id: 'feature_information',
      label: 'Feature Information',
      contents: [
        {
          id: 'feature_information',
          fields: [
            {
              name: 'title',
              label: 'Title',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              required: true,
              validationType: 'feature_title',
              validationErrorMessage: 'Feature title is required.',
              maxLength: 40,
              toolTipText: 'Provide the name or title of the feature.'
            },
            {
              name: 'description',
              label: 'Description',
              type: 'text_area',
              style: ['formtextfield', 'form-text-area'],
              gridStyle: 'layoutCellList',
              required: true,
              validationType: 'feature_description',
              validationErrorMessage: 'Feature description is required.',
              maxLength: 1024,
              toolTipText: 'Describe the feature.'
            },
            {
              name: 'featureImage',
              label: 'Image',
              note: 'Recommended aspect ratio - 16:9 and size - 922x518.',
              /*
                                            note:
                                              "For the highest quality image, we recommend uploading a high-resolution image (at least 236X236).",
                              */
              type: 'drop_box_s3_uploader',
              nameFrom: 'id',
              style: 'gallery-image',
              imageStyle: 'gallery-drop-box-image',
              deleteIconStyle:
                'drop-box-text-with-features-gallery-image-delete',
              uploadText: 'Click to upload',
              iconSize: '24px',
              gridStyle: 'layoutCellList',
              disabled: function(status) {
                return (
                  status &&
                  status !== SolutionStatus.REGISTERED &&
                  status !== SolutionStatus.RECALLED &&
                  status !== SolutionStatus.REVOKED &&
                  status !== SolutionStatus.DRAFT &&
                  status !== SolutionStatus.REJECTED
                );
              },
              toolTipText:
                'Provide an image to represent the feature like screenshot or some infographic. File extensions allowed .jpg, jpeg & .png. Max file size 5 MB.'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    create_feature: {
      id: '',
      title: 'New Feature',
      description: '',
      featureImage: '',
      dynamic: true,
      setLabelFrom: 'title'
    }
  },
  insert: {
    in: 'plans',
    to: 'featureArray',
    of: 'formValues'
  },
  activeTabId: 'feature_information'
};
