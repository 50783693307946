import React from "react";
import { Title, Subheading2 } from "@cdk-uip/react-typography";
import { LayoutGridCell } from "@cdk-uip/react-layout-grid";
import { injectIntl } from "react-intl";

class SubscriptionPageHeader extends React.Component {
  render() {
    const { title, titleNote } = this.props;
    return (
      <LayoutGridCell span={12}>
        <Title className="fm-page-content-title">
          <span>{title}</span>
        </Title>
        {titleNote && (
          <Subheading2 className="fm-page-content-title-note plan-content-note">
            {titleNote}
          </Subheading2>
        )}
      </LayoutGridCell>
    );
  }
}

export default injectIntl(SubscriptionPageHeader);
