import React from 'react';

import Spinner from '../common/Spinner';
import EmptyContent from '../common/images/Empty State 2x.png';

import FeaturedListItem from './FeaturedListItem';

import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';

class FeaturedOfferings extends React.Component {
  render() {
    const { featuredApps, loading } = this.props;
    return (
      <div className="featured-offerings">
        <LayoutGrid className="featured-section-grid max-width-resp">
          <LayoutGridCell style={{ marginBottom: '7px' }} span={12}>
            <div className="featured-offerings-title">
              <FormattedMessage
                id="FeaturedOfferings.title"
                defaultMessage="FEATURED OFFERINGS"
              />
            </div>
            <div className="featured-offerings-subtitle">
              <FormattedHTMLMessage
                id="FeaturedOfferings.subtitle"
                defaultMessage="See what developers are doing with Fortellis"
              />
            </div>
          </LayoutGridCell>

          {loading ? (
            <LayoutGridCell span={12}>
              <div>
                <Spinner />
                <div
                  style={{ color: '#ffffff ' }}
                  className="custom-font--grid"
                >
                  {' '}
                  Loading Featured Offerings...
                </div>
              </div>
            </LayoutGridCell>
          ) : featuredApps && featuredApps.length > 0 ? (
            featuredApps.map(solution => (
              <LayoutGridCell key={solution.id} span={4}>
                <FeaturedListItem solution={solution} />
              </LayoutGridCell>
            ))
          ) : (
            <LayoutGridCell span={12}>
              <div style={{ padding: '5px 79px' }}>
                <img
                  style={{ width: '100%' }}
                  alt="No apps available yet."
                  src={EmptyContent}
                />
                <div className="empty-content-card-message-myown">
                  <FormattedMessage
                    id="SolutionList.noItems"
                    defaultMessage="No items to display!"
                  />
                </div>
              </div>
            </LayoutGridCell>
          )}
        </LayoutGrid>
      </div>
    );
  }
}

export default injectIntl(FeaturedOfferings);
