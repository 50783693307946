import React from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from './Spinner';
import { Dialog, DialogHeader, DialogBody } from '@cdk-uip/react-dialog';
import SolutionDetailsScreen from '../solution-details/SolutionDetailsScreen';
import SolutionStatus from './SolutionStatus';

class PreviewSolution extends React.Component {
  state = {
    openPreviewDialog: false,
    galleryImagesLoaded: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && nextProps.openDialog) {
      return { openPreviewDialog: true };
    }
    return null;
  }

  onClosePreviewDialog = () => {
    this.setState({ openPreviewDialog: false });
    this.props.onClose && this.props.onClose();
  };

  componentDidMount() {
    this.props.dataRefetch && this.props.dataRefetch();

    this.timer = setTimeout(
      () => this.setState({ galleryImagesLoaded: true }),
      3500
    );
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidUpdate() {
    if (this.props?.solution?.status === SolutionStatus.CANCELLED)
      this.props.history.push('/marketplace-account/mysolutions');
  }

  render() {
    if (this.props.isPreviewInNewTab) {
      return (
        <div>
          {!this.state.galleryImagesLoaded ? (
            <div style={{ height: '600px' }}>
              <Spinner />
            </div>
          ) : (
            <SolutionDetailsScreen {...this.props} />
          )}
        </div>
      );
    } else {
      return (
        <Dialog
          open={this.state.openPreviewDialog}
          onCancel={this.onClosePreviewDialog}
          id="preview-dialog"
        >
          <DialogHeader>
            <FormattedMessage
              id="ManageListing.preview.DialogHeader"
              defaultMessage="Preview"
            />
            <i
              className="material-icons"
              onClick={this.onClosePreviewDialog}
              style={{ float: 'right', cursor: 'pointer' }}
            >
              clear
            </i>
          </DialogHeader>
          <DialogBody scrollable fullHeight>
            {!this.state.galleryImagesLoaded ? (
              <div style={{ height: '600px' }}>
                <Spinner />
              </div>
            ) : (
              <SolutionDetailsScreen {...this.props} />
            )}
          </DialogBody>
        </Dialog>
      );
    }
  }
}

export default PreviewSolution;
