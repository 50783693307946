import { getApolloClient } from "../containers/GraphQLClient";
import gql from "graphql-tag";
import { environmentURLs } from "../components/common/environment/CaptureEnvironment";

const marketplaceBetaAccessEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $leadEmail: String!
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: { emailType: $emailType, leadEmail: $leadEmail }
      }
    ) {
      emailResponse
    }
  }
`;

const saveMarketplaceBetaAccessMutation = gql`
  mutation($developerEmail: String, $developerName: String) {
    createBetaAccessRequest(
      command: {
        developerEmail: $developerEmail
        developerName: $developerName
      }
    ) {
      developerEmail
      requestDate
      requestExists
    }
  }
`;

export const sendMarketplaceBetaAccessEmail = async userData => {
  let adminIds = environmentURLs.fortellisAdmin.split(",");
  const client = getApolloClient();
  await adminIds.map(adminId => {
    return client.mutate({
      mutation: marketplaceBetaAccessEmailMutation,
      variables: {
        emailAddress: adminId,
        emailSubject: environmentURLs.betaAccessEmailSubject,
        firstName: userData.name,
        emailType: "marketplaceBetaAccessEmail",
        leadEmail: userData.email
      }
    });
  });
};

export const saveMarketplaceBetaAccessRequest = async userData => {
  const client = getApolloClient();
  return await client.mutate({
    mutation: saveMarketplaceBetaAccessMutation,
    variables: {
      developerEmail: userData.email,
      developerName: userData.name
    }
  });
};
