export const ErrorCodes = {
  SOLUTION_NOT_FOUND: 'SOLUTION_NOT_FOUND',
  SOLUTION_NOT_ACCESSIBLE: 'SOLUTION_NOT_ACCESSIBLE',
  SOLUTION_WITH_SAME_NAME_ALREADY_EXISTS:
    'Solution with same name already exists.',
  UTILIZATION_REPORT_NOT_FOUND: 'UTILIZATION_REPORT_NOT_FOUND',
  UNABLE_TO_FETCH_ORG_APPS_FROM_SOLUTION_SERVICE:
    'Unable to fetch org apps from solution service'
};

export default ErrorCodes;
