import ReviewTab from '../components/solution-details/Reviews/ReviewsTab';

import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withCommand from './withCommand';

export const getReviewsForSolutionQuery = gql`
  query($solutionId: ID, $createdDateDescendingSortOrder: Boolean) {
    publishedReviewsForUser(
      filter: {
        solutionId: $solutionId
        createdDateDescendingSortOrder: $createdDateDescendingSortOrder
      }
    ) {
      reviews {
        id
        authorName
        authorEmail
        solutionId
        entityId
        reviewedBy
        rating
        reviewTitle
        reviewText
        status
        createdDate
        parentId
        childId
        children {
          id
          authorName
          authorEmail
          solutionId
          reviewedBy
          reviewText
          status
          parentId
          createdDate
        }
        listingVersion
        solutionVersion
      }
      pageInfo {
        offset
      }
    }
    getRatingStatistics(solutionId: $solutionId) {
      averageRating
      numberOfReviews
    }
  }
`;

const deleteReviewMutation = gql`
  mutation($id: ID!) {
    deleteReview(command: { id: $id }) {
      id
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  reviews:
    data.publishedReviewsForUser && data.publishedReviewsForUser.reviews
      ? data.publishedReviewsForUser.reviews
      : [],
  offset:
    data.publishedReviewsForUser &&
    data.publishedReviewsForUser.pageInfo &&
    data.publishedReviewsForUser.pageInfo.offset
      ? data.publishedReviewsForUser.pageInfo.offset
      : null,
  numberOfReviews:
    data.getRatingStatistics && data.getRatingStatistics.numberOfReviews
      ? data.getRatingStatistics.numberOfReviews
      : null,
  loading: data.loading
});

export default compose(
  graphql(getReviewsForSolutionQuery, {
    props: mapDataToProps,
    options: ({ solutionId }) => ({
      variables: {
        solutionId: solutionId,
        createdDateDescendingSortOrder: true
      }
    })
  }),
  compose(
    graphql(deleteReviewMutation, {
      props: ({ mutate }) => ({
        deleteReview: id =>
          mutate({
            variables: {
              id: id
            },
            update: (cache, { data }) => {
              const deletedId = data['deleteReview']['id'];
              const solId = Array.from(cache.watches)[1].variables.solutionId;

              const existingCacheData = cache.readQuery({
                query: getReviewsForSolutionQuery,
                variables: {
                  solutionId: solId,
                  createdDateDescendingSortOrder: true
                }
              });

              const changedCache = existingCacheData['publishedReviewsForUser'][
                'reviews'
              ].filter(f => f.id != deletedId);

              existingCacheData['publishedReviewsForUser'][
                'reviews'
              ] = changedCache;
              existingCacheData['getRatingStatistics']['numberOfReviews'] =
                changedCache.length;
              existingCacheData['getRatingStatistics']['averageRating'] =
                changedCache.map(m => m.rating).reduce((s, i) => s + i) /
                changedCache.length;

              cache.writeQuery({
                query: getReviewsForSolutionQuery,
                data: existingCacheData,
                variables: {
                  solutionId: solId,
                  createdDateDescendingSortOrder: true
                }
              });
            }
          })
      })
    }),
    withCommand({ name: 'deleteReview' })
  )
)(ReviewTab);
