import React, { Component } from 'react';
import FeaturedOfferings from './FeaturedOfferings';
import SolutionSection from './SolutionSection';
import SolutionCategoryFilterChips from './SolutionCategoryFilterChips';

import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';

import Hero from '../common/Hero';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

import * as SolutionCategories from '../../components/solution-submission/solution_categories';
import {
  fetchPublishedSolutionsQuery,
  query
} from '../../containers/SolutionListContainer';
import StoreListingAPI from '../../api/StoreListingAPI';
import SellInMarketplaceCTA from './SellInMarketplaceCTA';

const FEATURED_SOLUTIONS_LIMIT = 3;
class SolutionList extends Component {
  state = {
    searchText: null,
    list: [],
    featuredSolutionsList: [],
    loading: true,
    solutionListUpdated: false,
    categoryFilters: [...SolutionCategories.categories],
    selectedCategory: null,
    offset: null,
    searchingSolutions: false
  };

  componentWillUpdate(nextProps) {
    if (!this.state.solutionListUpdated && !nextProps.loading) {
      this.setState({ list: nextProps.list });
      this.setState({ featuredSolutionsList: nextProps.featuredSolutionsList });
      this.setState({ loading: nextProps.loading });
      this.setState({ searchingSolutions: nextProps.searchingSolutions });
      this.setState({ solutionListUpdated: true });
    }
  }

  searchImplementation = searchText => {
    if (searchText) {
      let searchTextLowerCase = searchText.toLowerCase();
      this.setState({ searchText: searchTextLowerCase });
      if (this.props.auth && this.props.auth.isAuthenticated) {
        this.fetchSearchResults(searchTextLowerCase);
      } else {
        this.fetchSearchResultsForAnonymous(searchTextLowerCase);
      }
    } else {
      this.reloadSolutionsList();
    }
  };

  reloadSolutionsList = async () => {
    this.setState({
      searchText: '',
      loading: true
    });
    if (!this.props.auth.isAuthenticated) {
      StoreListingAPI.anonymous
        .getAllSolutions('all', null)
        .then(response => {
          this.setState({
            list: response.data.data.paginatedSolutionCatalogList,
            loading: false
          });
        })
        .catch(err => {});
    } else {
      let response = await this.props.client.query({
        query: gql`
          ${query}
        `
      });
      if (
        response &&
        response.data &&
        response.data.paginatedSolutionCatalogList
      ) {
        this.setState({
          list: response.data.paginatedSolutionCatalogList,
          loading: false
        });
      }
    }
  };

  fetchSearchResults = async searchText => {
    this.setState({
      loading: true,
      selectedCategory: -1
    });
    let queryResponse = await this.props.client.query({
      query: gql`
        query($status: String, $searchText: String) {
          solutionCatalogList(status: $status, searchText: $searchText) {
            id
            listingVersion
            developerId
            status
            overview {
              name
              briefDescription
              solutionLogo
              category
              featuredImage
            }
            solution_visibility {
              solutionVisibilityType
              privateSolutionUsers
            }
          }
        }
      `,
      variables: { status: 'Published', searchText: searchText }
    });

    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.solutionCatalogList
    ) {
      this.setState({
        list: { solutions: queryResponse.data.solutionCatalogList },
        loading: false
      });
    }
  };

  fetchSearchResultsForAnonymous = async searchText => {
    this.setState({
      loading: true
    });
    let queryResponse = await StoreListingAPI.anonymous.searchSolution(
      searchText
    );
    this.setState({
      list: { solutions: queryResponse.data.data.solutionCatalogList },
      loading: false
    });
  };

  filterSelectionChange = async selectedCategory => {
    this.setState({ searchingSolutions: true });
    const response = await this.props.client.query({
      query: gql`
        ${fetchPublishedSolutionsQuery}
      `,
      variables: { sortBy: 'name', category: selectedCategory }
    });
    if (
      response &&
      response.data &&
      response.data.paginatedSolutionCatalogList
    ) {
      this.setState({
        list: response.data.paginatedSolutionCatalogList
      });
    }
    this.setState({
      searchingSolutions: false,
      selectedCategory: selectedCategory
    });
  };

  filterSelectionAnonymous = async selectedCategory => {
    this.setState({ searchingSolutions: true });
    if (selectedCategory == null) {
      selectedCategory = 'all';
    }

    StoreListingAPI.anonymous
      .getAllSolutions(selectedCategory, null)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.data.paginatedSolutionCatalogList
        ) {
          this.setState({
            list: response.data.data.paginatedSolutionCatalogList
          });
        }
        this.setState({
          searchingSolutions: false,
          selectedCategory: selectedCategory
        });
      })
      .catch(err => {});
  };

  getFeaturedSolutionsList = (
    allSolutionsList = [],
    featuredSolutionsList = []
  ) => {
    if (featuredSolutionsList.length < 3) {
      const nonFeatureSolutionList = allSolutionsList.filter(
        solution =>
          !featuredSolutionsList.some(
            featuredSolution => featuredSolution.id === solution.id
          )
      );
      return featuredSolutionsList.concat(
        nonFeatureSolutionList.slice(
          0,
          FEATURED_SOLUTIONS_LIMIT - featuredSolutionsList.length
        )
      );
    }
    return featuredSolutionsList;
  };

  render() {
    const {
      list,
      loading,
      featuredSolutionsList = [],
      searchingSolutions,
      selectedCategory
    } = this.state;
    let solutionDetails = [];
    let pageInfoDetail;
    if (list) {
      const { solutions, pageInfo } = list;
      if (solutions) {
        solutionDetails = solutions;
      }
      if (pageInfo) {
        pageInfoDetail = pageInfo;
      }
    }

    const selectedCategoryFilters = this.state.categoryFilters
      .filter(category => category.selected === true)
      .map(category => category.name);

    const displayList = solutionDetails.filter(
      solution =>
        !selectedCategoryFilters.length ||
        selectedCategoryFilters.includes(solution.overview.category)
    );
    const featuredSolution = featuredSolutionsList.slice(
      0,
      FEATURED_SOLUTIONS_LIMIT
    );

    return (
      <div className="component-content">
        <Hero
          superTitle="FORTELLIS"
          title="MARKETPLACE"
          subTitle="LET'S INNOVATE."
          searchPlaceholder="Search the app you need"
          onSearchTextChange={this.searchImplementation}
        />

        <div>
          {this.state.searchText ? (
            false
          ) : (
            <FeaturedOfferings
              loading={loading}
              featuredApps={featuredSolution}
            />
          )}

          <div className="bg-whitesmoke" style={{ paddingBottom: '72px' }}>
            {this.state.searchText ? (
              false
            ) : (
              <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <SolutionCategoryFilterChips
                  categoryFilters={this.state.categoryFilters}
                  filterSelectionChange={
                    this.props.auth.isAuthenticated
                      ? this.filterSelectionChange
                      : this.filterSelectionAnonymous
                  }
                  selectedCategory={selectedCategory}
                />
              </div>
            )}
            {this.state.searchText ? (
              <SolutionSection
                heading={
                  <FormattedMessage
                    id="SolutionList.heading1"
                    defaultMessage="Search Results"
                  />
                }
                loading={loading}
                solutions={displayList}
              />
            ) : (
              false
            )}

            {this.state.searchText ? (
              false
            ) : (
              <SolutionSection
                heading={
                  <FormattedMessage
                    id="SolutionList.heading3"
                    defaultMessage="All Apps"
                  />
                }
                loading={loading}
                solutions={displayList}
                pageInfo={pageInfoDetail}
                searchingSolutions={searchingSolutions}
                selectedCategory={selectedCategory}
              />
            )}
          </div>
        </div>
        {this.props.auth.isAuthenticated ? <SellInMarketplaceCTA /> : null}
      </div>
    );
  }
}

export default withApollo(injectIntl(withAuth(SolutionList)));
