import React from 'react';
import SolutionCardGroup from './SolutionCardGroup';
import Spinner from '../../common/Spinner';
import './SolutionCardGroup.scss';

const SolutionsWithState = props => {
  let {
    enterKeyPress,
    loading,
    selectedOptionsArray,
    newestApps,
    discoverAppsLoading,
    discoverApps,
    paginatedQueryForDiscoverApps,
    showMore,
    searchText,
    searchSolutions,
    paginatedQuery,
    auth,
    orgMapObj,
    solutionClicked,
    worksWithValueMapObj,
    featuredApps
  } = props;
  return (
    <div>
      {!enterKeyPress &&
        !solutionClicked &&
        !loading &&
        selectedOptionsArray.length === 0 && (
          <div>
            {/* section for featured apps */}
            {featuredApps &&
              Array.isArray(featuredApps) &&
              featuredApps.length > 0 && (
                <>
                  <SolutionCardGroup
                    solutionArray={featuredApps}
                    orgMapObj={orgMapObj}
                    worksWithValueMapObj={worksWithValueMapObj}
                    title="Featured Apps"
                  />
                  <div className="solution-card-group__big_ruler" />
                </>
              )}
            <SolutionCardGroup
              solutionArray={newestApps}
              orgMapObj={orgMapObj}
              worksWithValueMapObj={worksWithValueMapObj}
              title="Newest Apps"
            />
            <div className="solution-card-group__big_ruler" />
            {discoverAppsLoading ? (
              <div>
                <Spinner />
                <div className="spinner_div">
                  <strong>Loading Apps</strong>
                </div>
              </div>
            ) : (
              <SolutionCardGroup
                solutionArray={discoverApps}
                title="Discover More Apps"
                moreSolutions={paginatedQueryForDiscoverApps}
                showMore={showMore}
                auth={auth}
                orgMapObj={orgMapObj}
                worksWithValueMapObj={worksWithValueMapObj}
              />
            )}
          </div>
        )}

      {enterKeyPress &&
        !solutionClicked &&
        !loading &&
        searchText === '' &&
        selectedOptionsArray.length === 0 && (
          <div>
            <SolutionCardGroup
              solutionArray={newestApps}
              orgMapObj={orgMapObj}
              title="Newest Apps"
              worksWithValueMapObj={worksWithValueMapObj}
            />

            <div className="solution-card-group__big_ruler" />
            {discoverAppsLoading ? (
              <div>
                <Spinner />
                <div className="spinner_div">
                  <strong>Loading Apps</strong>
                </div>
              </div>
            ) : (
              <SolutionCardGroup
                solutionArray={discoverApps}
                title="Discover More Apps"
                moreSolutions={paginatedQueryForDiscoverApps}
                showMore={showMore}
                auth={auth}
                orgMapObj={orgMapObj}
                worksWithValueMapObj={worksWithValueMapObj}
              />
            )}
          </div>
        )}

      {(enterKeyPress || solutionClicked) && !loading && searchText !== '' && (
        <SolutionCardGroup
          solutionArray={searchSolutions}
          searchText={searchText}
          moreSolutions={paginatedQuery}
          showMore={showMore}
          title=""
          auth={auth}
          orgMapObj={orgMapObj}
          worksWithValueMapObj={worksWithValueMapObj}
        />
      )}

      {!enterKeyPress &&
        !loading &&
        searchText === '' &&
        selectedOptionsArray.length > 0 && (
          <SolutionCardGroup
            solutionArray={searchSolutions}
            searchText={searchText}
            moreSolutions={paginatedQuery}
            showMore={showMore}
            title=""
            auth={auth}
            orgMapObj={orgMapObj}
            worksWithValueMapObj={worksWithValueMapObj}
          />
        )}
    </div>
  );
};

export default SolutionsWithState;
