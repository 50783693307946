import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Checkbox } from '@cdk-uip/react-checkbox';
import { FormField } from '@cdk-uip/react-form-field';
import isEmpty from 'lodash/isEmpty';

class SubscriptionPlanContentTermsAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccepted: false
    };
  }
  onAccept = async () => {
    let isAccepted = !this.state.isAccepted;
    await this.setState({ isAccepted });
    await this.props.onAcceptAction(isAccepted);
  };

  getErrorMessage = () => {
    return (
      <div>
        <div className="plan-terms-title">
          <FormattedMessage
            id="MarketplaceAccount.subscriptions.termsAndConditionsError"
            defaultMessage="Terms and condition not specified by the developer, which is required for subscription. Please contact app developer."
          />
        </div>
        <br />
        <div className="plan-terms-title-notes">
          <div>
            <span className="sub_error_span">Name</span>:
            <span className="sub_error_span_text">{`${(this.props.solution &&
              this.props.solution.developerName) ||
              '-'}`}</span>
          </div>
          <div>
            <span className="sub_error_span">Email</span>:
            <a
              href={`mailto:${this.props.solution.developerId}`}
              className="sub_error_span_text fortellis-link"
            >{`${(this.props.solution && this.props.solution.developerId) ||
              '-'}`}</a>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { plan = {}, solution } = this.props;
    let isTermsUrlEmpty = isEmpty(solution.overview.termsAndConditionsUrl);
    return (
      <div className="plan-action-terms">
        {isTermsUrlEmpty && this.getErrorMessage()}
        {!isTermsUrlEmpty && (
          <div>
            <div className="plan-terms-title">
              <FormattedMessage
                id="MarketplaceAccount.subscriptions.termsAndConditions"
                defaultMessage="Terms and Conditions"
              />
            </div>
            <div className="plan-terms-title-notes">
              <div className="plan-term-text">
                <FormattedMessage
                  id="MarketplaceAccount.subscriptions.termsAndConditionsNote1"
                  defaultMessage="You must agree to the"
                />
                &nbsp;
                <span onClick={this.props.onShow} className="plan-terms-link">
                  <FormattedMessage
                    id="MarketplaceAccount.subscriptions.termsAndConditions"
                    defaultMessage="Terms and Conditions"
                  />
                  <i className="material-icons external-link-icon">
                    open_in_new
                  </i>
                </span>
                &nbsp;
                <FormattedMessage
                  id="MarketplaceAccount.subscriptions.termsAndConditionsNote2"
                  defaultMessage="before subscribing to this plan."
                />
              </div>
            </div>
            <div>
              <div className="plan-action-button-terms">
                <FormField
                  id={`plan-terms-accept-checkbox-${plan.planName || ''}`}
                  className="plan-terms-accept-checkbox"
                >
                  <Checkbox
                    checked={this.state.isAccepted}
                    onChange={this.onAccept}
                  />
                  <label className="plan-terms-accept-checkbox-label">
                    <FormattedMessage
                      id="MarketplaceAccount.subscriptions.termsAndConditionsAcceptMsg"
                      defaultMessage="I agree to the terms and conditions."
                    />
                  </label>
                </FormField>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SubscriptionPlanContentTermsAction);
