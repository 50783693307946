import React from 'react';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton,
  DialogHeader
} from '@cdk-uip/react-dialog';

class FortellisDialog extends React.Component {
  render() {
    const {
      title,
      message,
      open,
      acceptButtonName,
      cancelButtonName,
      onAccept,
      onCancel,
      customClassName,
      hideFooter,
      acceptDataCy = 'dialog_box_btn_accept',
      cancelDataCy = 'dialog_box_btn_cancel'
    } = this.props;
    return (
      <div
        className={`default-fortellis-dialog ${
          customClassName ? customClassName : ''
        }`}
      >
        <Dialog open={open} onAccept={onAccept} onCancel={onCancel}>
          {title && <DialogHeader>{title}</DialogHeader>}
          <DialogBody>{message}</DialogBody>
          <DialogFooter>
            {cancelButtonName && (
              <DialogFooterButton data-cy={cancelDataCy} cancel>
                {cancelButtonName}
              </DialogFooterButton>
            )}
            {hideFooter ? null : (
              <DialogFooterButton data-cy={acceptDataCy} accept>
                {acceptButtonName ? acceptButtonName : 'OK'}
              </DialogFooterButton>
            )}
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default FortellisDialog;
