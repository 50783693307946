import React from 'react';
import gql from 'graphql-tag';
import { Avatar } from '@cdk-uip/react-avatar';

class EntityWithAvatar extends React.Component {
  state = {
    avatarInitials: '',
    entity: null,
    error: null
  };

  async componentDidMount() {
    let entityDetails = this.props.entityDetails;
    let entityName = '';
    entityDetails.forEach(item => {
      if (item.id && item.id === this.props.entityId) {
        entityName = item.name;
      }
    });
    if (!entityName) {
      return;
    }
    this.props.onEntityReception(this.props.entityId || {});
    let avatarInitials = '';
    let splittedEntity = entityName.split(' ');
    splittedEntity.forEach(split => {
      avatarInitials = avatarInitials + split.charAt(0);
    });
    avatarInitials = avatarInitials.substring(0, 2);
    this.setState({ entityName: entityName, avatarInitials: avatarInitials });
  }

  render() {
    const { avatarInitials, entityName } = this.state;
    return (
      <div style={{ display: 'inline-block' }}>
        <Avatar className="entity-default-avatar">
          {avatarInitials ? avatarInitials : ''}
        </Avatar>
        <div style={{ display: 'inline-block' }}>
          {entityName ? entityName : '-'}
        </div>
      </div>
    );
  }
}

const getEntityByIdQuery = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      name
      website
      phone
      type
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
    }
  }
`;

export default EntityWithAvatar;
