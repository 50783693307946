import React from 'react';
import { injectIntl } from 'react-intl';
import { CardText } from '@cdk-uip/react-card';
import moment from 'moment';
import { Divider } from '@cdk-uip/react-divider';
import { FormattedMessage } from 'react-intl';

import MySubscriptionListItemActions from './my-subscription-list-item-actions';

class MySubscriptionListItemContent extends React.Component {
  render() {
    const { subscription } = this.props;
    let plan = subscription.plan;
    return (
      <div className="subscription-billing-card-parent-bg">
        <div className="subscription-billing-card-parent">
          <CardText className="subscription-billing-card-content">
            <div className="subscription-card-content-activated">
              <div className="plan-name">
                <div className="plan-details-label">
                  <FormattedMessage
                    id="SubscriptionManagement.BillingDetails.DatatableHeader.planName"
                    defaultMessage="Plan Name"
                  />
                </div>
                <div className="plan-details-value">
                  <div className="subscription-card-title-label">
                    <span
                      className="subscription-card-title-label-text"
                      style={{ 'margin-left': '0px' }}
                    >
                      {plan.planName}
                    </span>
                  </div>
                  {/* {plan.planName} */}
                </div>
              </div>
              <div className="plan-dates">
                <div className="plan-details-label">
                  <FormattedMessage
                    id="SubscriptionManagement.BillingDetails.DatatableHeader.billingStartDate"
                    defaultMessage="Billing Start Date"
                  />
                </div>
                <div className="plan-details-value-date">
                  {!!subscription.billingStartsOn
                    ? moment(subscription.billingStartsOn).format('DD MMM YYYY')
                    : 'NA'}
                </div>
              </div>
              <div className="plan-dates">
                <div className="plan-details-label">
                  <FormattedMessage
                    id="SubscriptionManagement.BillingDetails.DatatableHeader.billingEndDate"
                    defaultMessage="Billing End Date"
                  />
                </div>
                <div className="plan-details-value-date">
                  {!!subscription.billingEndsOn
                    ? moment(subscription.billingEndsOn).format('DD MMM YYYY')
                    : 'NA'}
                </div>
              </div>
              <div className="plan-price">
                <div className="plan-details-label">
                  <FormattedMessage
                    id="SubscriptionManagement.BillingDetails.DatatableHeader.price"
                    defaultMessage="Price"
                  />
                </div>
                <div className="plan-details-value">
                  <span className="plan-details-price-digit">
                    {plan.billingType === 'TRANSACTIONAL' &&
                      plan.transactionalTypeOptions === '%' &&
                      `${plan.includedTransactionalFee || 0.0} ${
                        plan.transactionalTypeOptions
                      }`}
                    {plan.billingType === 'TRANSACTIONAL' &&
                      plan.transactionalTypeOptions === '$' &&
                      `${
                        plan.transactionalTypeOptions
                      } ${plan.includedTransactionalFee || 0.0}`}
                    {plan.basePrice && `$${plan.basePrice.toFixed(2)}`}
                    {!plan.basePrice &&
                      !plan.includedTransactionalFee &&
                      `$0.00`}
                  </span>
                  <span className="plan-details-price-per">
                    {plan.isTheFreeTrial && ' / month'}
                    {plan.billingType === 'RECURRING' &&
                      plan.billingFrequency &&
                      ` / ${plan.billingFrequency.toLowerCase()}`}
                    {plan.billingType === 'TRANSACTIONAL' &&
                      plan.includedTransactionalType &&
                      `/ ${plan.includedTransactionalType}`}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="subscription-billing-card-divider">
                <Divider />
              </div>
              <div>
                <MySubscriptionListItemActions subscription={subscription} />
              </div>
            </div>
          </CardText>
        </div>
      </div>
    );
  }
}

export default injectIntl(MySubscriptionListItemContent);
