import React from 'react';
import AddReview from './AddReview';
import Reviews from './Reviews';
import EmptyContent from './../../common/empty-content';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import PermissionConstants from '../../common/constants/PermissionConstants';
import { Loader } from 'cdk-radial';

class ReviewsTab extends React.Component {
  state = {
    reviews: [],
    reviewsLoaded: false
  };

  setReviews = reviews => {
    this.setState({
      reviews: reviews,
      reviewsLoaded: true
    });
  };

  deleteReviewFromParent = reviewId => {
    //search from parent and delete
    let reviewsCopy = JSON.parse(JSON.stringify(this.state.reviews));
    this.state.reviews.forEach((review, index) => {
      if (review.id === reviewId) {
        reviewsCopy.splice(index, 1);
      } else if (review.children && review.children.id === reviewId) {
        reviewsCopy[index].children = null;
      }
    });
    this.setReviews(reviewsCopy.length > 0 ? reviewsCopy : null);
  };

  onReviewResponseSubmitted = reviewId => {
    //search from parent and add submitted status to children
    let reviewsCopy = JSON.parse(JSON.stringify(this.state.reviews));
    this.state.reviews.forEach((review, index) => {
      if (review.id === reviewId) {
        let child = {};
        child.status = 'Submitted';
        reviewsCopy[index].children = child;
      }
    });
    this.setReviews(reviewsCopy);
  };

  onAddReviewToSolution = review => {
    let reviewsCopy = JSON.parse(JSON.stringify(this.state.reviews));
    reviewsCopy.push({ ...review, id: '0', status: 'Submitted' });
    this.setReviews(reviewsCopy);
  };

  render() {
    const {
      orgId,
      numberOfReviews,
      loading,
      userPermissions,
      deleteReview,
      isPreview,
      entity
    } = this.props;

    const {
      auth: { isAuthenticated, userData }
    } = this.props;

    if (this.props.reviews.length > 0 && !this.state.reviewsLoaded) {
      this.setReviews(this.props.reviews);
    }

    const { reviews } = this.state;

    return (
      <div>
        {!isPreview &&
        (isAuthenticated && entity && entity.id !== orgId) &&
        userPermissions &&
        userPermissions.includes(PermissionConstants.ADD_REVIEW) ? (
          <AddReview
            solutionId={this.props.solutionId}
            userPermissions={userPermissions}
            listingVersion={
              this.props.listingVersion ? this.props.listingVersion : '1'
            }
            solutionVersion={this.props.solutionVersion}
            solutionName={this.props.solutionName}
            numberOfReviews={numberOfReviews}
            reviewsForSolution={reviews}
            onAddReviewToSolution={this.onAddReviewToSolution}
            entity={entity}
          />
        ) : null}

        {!loading && numberOfReviews < 1 ? (
          <div data-cy="review_empty_card_message">
            <EmptyContent
              message={
                <FormattedMessage
                  id="ReviewsTab.noReviewsMsg"
                  defaultMessage="No reviews yet, Check back soon!"
                />
              }
            />
          </div>
        ) : (
          false
        )}

        <div style={{ paddingLeft: '47px', paddingRight: '24px' }}>
          {loading && (
            <div>
              <Loader label="Loading reviews..." className="spinner" />
            </div>
          )}

          {!loading && reviews ? (
            <Reviews
              {...this.props}
              userPermissions={userPermissions}
              userData={userData}
              orgId={orgId}
              reviewsForSolution={reviews}
              deleteReview={deleteReview}
              deleteReviewFromParent={this.deleteReviewFromParent}
              onReviewResponseSubmitted={this.onReviewResponseSubmitted}
              isPreview={isPreview}
            />
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(withAuth(ReviewsTab));
