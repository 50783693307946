import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import SubscriptionCancelDialog from '../../marketplace-account/subscriptions/subscription-cancel-dialog';

const cancelSolutionSubscription = gql`
  mutation($id: ID!, $subscriptionId: ID, $status: String!) {
    cancelSolutionSubscription(
      command: { id: $id, subscriptionId: $subscriptionId, status: $status }
    ) {
      id
      subscriptionId
      status
    }
  }
`;

class SubscribedPlanActions extends React.Component {
  state = {
    redirectToConfiguration: false,
    canceled: false,
    cancelSubscription: false
  };
  redirectToApiConfig = () => {
    this.setState({ redirectToConfiguration: true });
  };
  onCancelSubscription = async () => {
    const client = getApolloClient();
    try {
      await client.mutate({
        mutation: cancelSolutionSubscription,
        variables: {
          id: this.props.subscriptionDetails.id,
          subscriptionId: this.props.subscriptionDetails.subscriptionId,
          status: 'Cancelled'
        }
      });
    } catch (e) {
      console.error(e);
    }
    this.setState({ canceled: true });
  };
  onCloseCancelSubscription = () => {
    this.setState({ cancelSubscription: false });
  };

  render() {
    const { solution, subscriptionDetails } = this.props;
    if (this.state.redirectToConfiguration) {
      return (
        <Redirect
          to={`/marketplace-account/mysubscriptions/${
            subscriptionDetails.subscriptionId
          }/apiconfig`}
        />
      );
    }
    if (this.state.canceled) {
      return <Redirect to={`/marketplace-account/mysubscriptions`} />;
    }
    return (
      <div className="plan-action-panel">
        <SubscriptionCancelDialog
          solutionName={solution.overview.name}
          open={this.state.cancelSubscription}
          onClose={this.onCloseCancelSubscription}
          onApprove={this.onCancelSubscription}
        />
        <Button
          id="skip-for-now-button"
          onClick={() => {
            this.props.history.push(`/solutions`);
          }}
        >
          <FormattedMessage
            id="MarketplaceAccount.subscriptions.skipForNow"
            defaultMessage="Skip for now"
          />
        </Button>
        <Button
          id="Configure-api-access-button"
          primary
          raised
          onClick={() => {
            this.props.history.push(
              `/marketplace-account/mysubscriptions/${
                subscriptionDetails.subscriptionId
              }/apiconfig`
            );
          }}
        >
          <FormattedMessage
            id="MarketplaceAccount.subscriptions.configureApiAccess"
            defaultMessage="Configure api access"
          />
        </Button>
      </div>
    );
  }
}

export default injectIntl(withRouter(SubscribedPlanActions));
