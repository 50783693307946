import {
  CATEGORIES_SECTION_TITLE,
  DISCOVER_BY_SECTION_TITLE,
  PUBLISHERS_SECTION_TITLE,
  FREE_TRIAL_FILTER,
  NEWEST_FILTER,
  INTEGRATIONS_SECTION_TITLE,
  INDUSTRY_SECTION_TITLE
} from '../filters-and-sections/constants';

const filtersObject = {
  [INDUSTRY_SECTION_TITLE]: {
    sectionTitle: INDUSTRY_SECTION_TITLE,
    id: 1,
    filterArray: [
      {
        name: 'Automotive',
        id: 1
      },
      {
        name: 'Heavy Truck',
        id: 2
      },
      {
        name: 'PowerSports',
        id: 3
      }
    ],
    isExpanded: true
  },
  [DISCOVER_BY_SECTION_TITLE]: {
    sectionTitle: DISCOVER_BY_SECTION_TITLE,
    id: 2,
    filterArray: [
      {
        name: FREE_TRIAL_FILTER,
        id: 1
      },
      {
        name: NEWEST_FILTER,
        id: 2
      }
    ],
    isExpanded: true
  },
  [CATEGORIES_SECTION_TITLE]: {
    sectionTitle: CATEGORIES_SECTION_TITLE,
    id: 3,
    filterArray: [
      {
        name: 'Fixed Ops',
        id: 1
      },
      {
        name: 'Modern Retail',
        id: 2
      },
      {
        name: 'CRM',
        id: 3
      },
      {
        name: 'Business Office',
        id: 4
      },
      {
        name: 'Intelligence',
        id: 5
      },
      {
        name: 'Parts',
        id: 6
      },
      {
        name: 'F&I',
        id: 7
      },
      {
        name: 'Marketing',
        id: 8
      },
      {
        name: 'Heavy Truck',
        id: 9
      }
    ],
    isExpanded: false
  },
  [PUBLISHERS_SECTION_TITLE]: {
    sectionTitle: PUBLISHERS_SECTION_TITLE,
    id: 4,
    filterArray: [],
    isExpanded: false
  },
  [INTEGRATIONS_SECTION_TITLE]: {
    sectionTitle: INTEGRATIONS_SECTION_TITLE,
    id: 5,
    filterArray: [],
    isExpanded: false
  }
};

export { filtersObject };
