import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { TextField, TextFieldHelperText } from '@cdk-uip/react-text-field';
import ValidationUtils from '../utils/ValidationUtils';

export class SearchField extends React.Component {
  state = {
    isDisabled: false,
    isSearchKeywordInValid: null,
    isValid: true
  };
  constructor(props) {
    super(props);
    this.validationUtils = new ValidationUtils();
  }
  validate(value) {
    let isValid = this.validationUtils.validate(
      this.props.fieldProperties,
      value
    );
    this.props.validate &&
      this.props.validate(
        isValid,
        this.props.fieldProperties.name,
        this.props.activeFormId,
        this.props.activeTabId,
        this.props.activeSubFormId
      );
    return isValid;
  }

  handleInputChange = ({ target: { value = '', name } }) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = value
      .split(',')
      .map(keyword => keyword.replace(/^\s+/g, ''));
    let isValid = this.validate(formFieldValues);
    this.setState({
      isValid: isValid
    });
    this.props.onChange(formFieldValues);
  };

  render() {
    const { fieldProperties, disabledOn } = this.props;
    let value = this.props.formFieldValues[fieldProperties.name]
      ? this.props.formFieldValues[fieldProperties.name]
      : [];
    return (
      <div className="form-field-with-tooltip">
        <TextField
          className={fieldProperties.style}
          name={fieldProperties.name}
          id={fieldProperties.name}
          type={fieldProperties.fieldType}
          min={fieldProperties.min}
          label={
            <span className="form-field-label">
              {fieldProperties.label}
              {fieldProperties.toolTipText && (
                <span>
                  <i
                    id={`tooltip_${fieldProperties.name}`}
                    className="material-icons help-label-icon"
                  >
                    help
                  </i>
                </span>
              )}
            </span>
          }
          value={value
            .map(keyword => {
              if (keyword === '') {
                return keyword;
              }
              return ' ' + keyword;
            })
            .join(',')}
          onChange={this.handleInputChange}
          disabled={
            typeof fieldProperties.disabled === 'function'
              ? fieldProperties.disabled(disabledOn)
              : fieldProperties.disabled
          }
          maxLength={fieldProperties.maxLength}
          required={fieldProperties.required}
        />
        {!this.state.isValid ? (
          <p className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg">
            {'Please enter Search Keywords and separate with a comma.'}
          </p>
        ) : null}
        {fieldProperties.maxLength && (
          <div className="character-remaining-div">
            {this.props.value ? this.props.value.length : 0} /{' '}
            {fieldProperties.maxLength}
          </div>
        )}
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${fieldProperties.name}`}
        >
          <span>{fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}

export default SearchField;
