// Constants
const DOCUSIGN_HEIGHT = 1030;
const FIELD_HEIGHT = 28;
const DOCUMENT_DEFAULT_HEIGHT = 792;
const DOCUMENT_DEFAULT_WIDTH = 612;

/*
 * The following functions were added to help handle the inconsistencies between
 * the coordinate positions and the end positions on docusign. They were calculated
 * by comparing coordinate positions between our site and docusign to find the difference.
 * As of writing this I was unable to find any actual documentation or issues relating to
 * docusign handling coordinates, but these equations position our fields correctly (within a
 * small margin of error).
 */
function yMod(y) {
  // The distance between our y value and where it will be positioned on docusign
  const yModValue = 0.00000167 * Math.pow(y, 2) - 0.084 * y + 10.7;
  return y - yModValue;
}

function xMod(x) {
  // The distance between our x value and where it will be positioned on docusign
  const xModValue = -0.00002 * Math.pow(x, 2) - 0.052 * x + 27.4;
  return x - xModValue;
}

function convertCoordToDocusign(coordinate, documentSize) {
  if (
    !coordinate ||
    !coordinate.x ||
    isNaN(Number(coordinate.x)) ||
    !coordinate.y ||
    isNaN(Number(coordinate.y))
  ) {
    throw Error(
      'Invalid input, coordinate param should contain x, y attributes with type:Number'
    );
  }
  // Validate if documentSize param exists
  if (documentSize) {
    if (
      !documentSize.width ||
      isNaN(Number(documentSize.width)) ||
      !documentSize.height ||
      isNaN(Number(documentSize.height))
    ) {
      throw Error(
        'Invalid input, documentSize param should contain height, width attributes with type:Number'
      );
    }
  }
  let docSize = documentSize || {};
  //Sep 15, 2020 - Assign default height width values since this data won't be available on older documents.
  if (!docSize.width) {
    docSize.width = DOCUMENT_DEFAULT_WIDTH;
  }
  if (!docSize.height) {
    docSize.height = DOCUMENT_DEFAULT_HEIGHT;
  }
  const calcDocusignWidth = DOCUSIGN_HEIGHT * (docSize.width / docSize.height);
  const x = calcDocusignWidth * (coordinate.x / docSize.width) + 30;
  const y =
    DOCUSIGN_HEIGHT * (coordinate.y / docSize.height) - FIELD_HEIGHT / 2;
  return {
    x: xMod(x), // For some reason docusign is always approx 20 px off of x location
    y: yMod(y),
    page: coordinate.page
  };
}

export { convertCoordToDocusign };
