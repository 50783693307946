import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import Rating from './Rating';
import PermissionConstants from '../../common/constants/PermissionConstants';
import { AMPLITUDE_EVENTS } from '../../../utils/amplitude-constants';

class ReviewStats extends React.Component {
  state = {};

  render() {
    const {
      averageRating,
      numberOfReviews,
      orgId,
      entity,
      tabSelect,
      userPermissions,
      createAndSubmitAmplitudeData
    } = this.props;

    return (
      <div
        style={{
          marginTop: '24px',
          textAlign: 'center',
          marginBottom: '26px'
        }}
      >
        {averageRating ? (
          <div
            className="tab-select"
            style={{ cursor: 'pointer' }}
            onClick={() => tabSelect(4)}
          >
            <div className="rating-number">
              {(Math.round(averageRating * 10) / 10).toFixed(1)}
            </div>
            <div>
              <span className="number-of-reviews">{numberOfReviews}</span>
              <span className="number-of-reviews-text">
                <FormattedMessage
                  id="PurchaseSolution.noOfReviewsText"
                  defaultMessage="Customer reviews"
                />
              </span>
            </div>
            <Rating
              rating={Math.round(averageRating)}
              customClass="profile-rating-colored"
              disabled={true}
            />
          </div>
        ) : (
          <div
            className="tab-select"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              createAndSubmitAmplitudeData(AMPLITUDE_EVENTS.VIEW_APP_REVIEWS);
              tabSelect(4);
            }}
          >
            <Rating deadRating={true} customClass="profile-rating-dead" />
            {this.props &&
            this.props.auth &&
            this.props.auth.isAuthenticated &&
            entity &&
            orgId !== entity.id &&
            userPermissions &&
            userPermissions.includes(PermissionConstants.ADD_REVIEW) ? (
              <div className="be-the-first">
                <FormattedMessage
                  id="PurchaseSolution.beTheFirst"
                  defaultMessage="Be the first to review"
                />
              </div>
            ) : (
              <div>
                <span className="number-of-reviews">0</span>
                <span className="number-of-reviews-text">
                  <FormattedMessage
                    id="PurchaseSolution.noOfReviewsText"
                    defaultMessage="Customer reviews"
                  />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(withAuth(ReviewStats));
