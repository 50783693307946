import React from 'react';
import { Input } from 'cdk-radial';
import './sp_textfield.scss';

const SPTextField = ({
  id = 'someId',
  label = 'Enter Text Here',
  value = '',
  onChange = val => console.log(val),
  required = false,
  maxLength,
  className,
  onBlur,
  invalid,
  helperText,
  dataQA
}) => {
  return (
    <div className={`sp_text-field ${className ? className : ''}`}>
      <Input
        label={label}
        value={value}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
        required={required}
        maxLength={maxLength}
        dataTestId={dataQA}
        invalid={invalid}
        isRequired
        helperText={helperText ? helperText : null}
      />
      {maxLength && (
        <div className="character-counter">
          {value ? value.length : 0} / {maxLength}
        </div>
      )}
    </div>
  );
};
export default SPTextField;
