import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import MySolutionsList from '../../containers/MySolutionsListContainer';
import './SellSolution.scss';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import { Button } from '@cdk-uip/react-button';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import FortellisTitle2 from '../common/FortellisTitle2';
import FortellisSubtitle1 from '../common/FortellisSubtitle1';
import LoadingSpinner from '../marketplace-account/loading-spinner';
import SolutionStatus from '../common/SolutionStatus';
import FortellisConstants from '../common/FortellisConstants';
import EntityRegistrationConfirmation from '../common/EntityRegistrationConfirmation';
import { withRouter } from 'react-router-dom';

class SellSolution extends Component {
  state = {
    registeredSolutionsExist: false,
    openSolutionRegistrationDialog: false
  };

  redirectToSolutionCreation = () => {
    window.location = environmentURLs.developerAppsAddSolution;
  };

  redirectToInMarketSolutionCreation = () => {
    this.props.history.push(
      `/marketplace-account/mysolutions/list/${
        FortellisConstants.PARTNER_PROGRAM_TYPE_URL
      }`
    );
  };

  handleRegisterInMarketSolution = () => {
    const { entity } = this.props;
    if (this.props.auth.isAuthenticated) {
      if (
        !entity ||
        !entity.id ||
        entity.id === FortellisConstants.DEFAULT_ORG_ID ||
        entity.id === FortellisConstants.PERSONAL_ACCOUNT
      ) {
        this.setState({ showEntityRegistrationPopup: true });
      } else {
        this.redirectToInMarketSolutionCreation();
      }
    } else {
      this.props.auth.login({ return_url: window.location.pathname });
    }
  };

  componentWillReceiveProps(nextProps) {
    let registeredSolutionsExist, reducedSolutionList;
    if (nextProps.list) {
      registeredSolutionsExist = nextProps.list.find(item => {
        return !item.solutiondata;
      });
    }

    if (nextProps.solutionList) {
      reducedSolutionList = nextProps.solutionList.filter(
        item => item.id !== nextProps.list.find(l => l.id === item.id)
      );
    }

    if (
      registeredSolutionsExist ||
      (reducedSolutionList && reducedSolutionList.length)
    ) {
      this.setState({ registeredSolutionsExist: true });
    }
  }

  render() {
    const {
      loading,
      auth: { userData },
      entity
    } = this.props;

    return (
      <div className="max-width-resp page-padding-common">
        {loading ? (
          <LoadingSpinner />
        ) : (
          this.state.registeredSolutionsExist && (
            <div className="registered-solution-list-container">
              <MySolutionsList
                userData={userData}
                showSolutionType={SolutionStatus.DRAFT}
                showTitle
                blankEmptyContent
                entity={entity}
              />
            </div>
          )
        )}

        <div className="solution-registration-options">
          <div className="fortellis-solution-registration">
            <FortellisTitle2
              title={
                <FormattedMessage
                  id="SellFortellisSolution.title"
                  defaultMessage="Register and Build solutions using Fortellis APIs"
                />
              }
            />
            <FortellisSubtitle1
              subtitle={
                <FormattedMessage
                  id="SellFortellisSolution.subtitle"
                  defaultMessage="Register your app and receive an API key to build an app. Once your app is approved by Fortellis, start selling your app by publishing it in the Fortellis Marketplace."
                />
              }
            />

            <Button
              className="button-hero register-button fmk-cover__button--highlighted"
              unelevated
              onClick={this.redirectToSolutionCreation}
            >
              <FormattedMessage
                id="SellFortellisSolution.action.registerNewSolution"
                defaultMessage="Register a New App"
              />
            </Button>
          </div>

          <div className="solution-registration-divider">
            <div className="divider-line" />
            <div className="divider-text-parent">
              <div className="divider-text">
                <FormattedMessage
                  id="SellSolution.choice.title"
                  defaultMessage="OR"
                />
              </div>
            </div>
          </div>
          <EntityRegistrationConfirmation
            openDialog={this.state.showEntityRegistrationPopup}
            onClose={() =>
              this.setState({ showEntityRegistrationPopup: false })
            }
          />
          <div className="partner-solution-registration">
            <FortellisTitle2
              title={
                <FormattedMessage
                  id="SellPartnerSolution.title"
                  defaultMessage="In-Market solutions"
                />
              }
            />
            <FortellisSubtitle1
              subtitle={
                <FormattedMessage
                  id="SellPartnerSolution.subtitle"
                  defaultMessage="Submit your solutions for approval which are already built. Once your app is approved by Fortellis, start selling your app by publishing it in the Fortellis Marketplace"
                />
              }
            />
            <Button
              className="button-hero register-button fmk-cover__button--highlighted"
              unelevated
              onClick={this.handleRegisterInMarketSolution}
            >
              <FormattedMessage
                id="SellPartnerSolution.action.submitSolution"
                defaultMessage="Register an In-market App"
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(withAuth(SellSolution)));
