export const config = {
  formId: 'data_rights',
  formTitle: 'User Data Rights Enablement',
  formValueType: 'Object',
  formNote:
    'Selecting this option will allow Marketplace to get Data Rights Agreement digitally signed at the time of subscription',
  tabs: [
    {
      id: 'data_rights',
      label: 'User Data Rights Enablement',
      contents: [
        {
          id: 'captureDataRightsConsent',
          fields: [
            {
              formtitle: 'Select Enablement Option',
              type: 'FortellisTitle3',
              style: 'provisioningtitle'
            },
            {
              name: 'captureDataRightsConsent',
              label: 'Capture Data Rights Consent',
              type: 'radio',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['captureDataRightsConsent'] = true;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return formValue[name] === true;
              }
            },
            {
              formtitle: 'Capture data rights consent from subscriber',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        },
        {
          id: 'skipDataRightsConsent',
          fields: [
            {
              name: 'captureDataRightsConsent',
              label: 'Skip Data Rights Consent',
              type: 'radio',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['captureDataRightsConsent'] = false;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return formValue[name] === false;
              }
            },
            {
              formtitle: 'Do not capture data rights consent from subscriber',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    data_rights: {
      captureDataRightsConsent: ''
    }
  },
  activeTabId: 'data_rights'
};
