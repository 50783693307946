import React from "react";

import { SearchBox } from "@cdk-uip/react-search-box";

/**
 * For component doc visit:
 * http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react/#searchbox
 */
export default class SearchComponent extends React.Component {
  onFetch(val) {
    this.props.onChange && this.props.onChange(val);
  }

  onClear() {
    this.props.onChange && this.props.onChange("");
    this.props.onClear && this.props.onClear();
  }

  render() {
    return (
      <SearchBox
        onClear={this.onClear.bind(this)}
        onFetch={this.onFetch.bind(this)}
        {...this.props}
      />
    );
  }
}
