import React from 'react';
import { injectIntl } from 'react-intl';

class SubscriptionContactDetails extends React.Component {
  render() {
    const { contactNote, solution } = this.props;
    return (
      <div>
        {solution && (
          <div>
            <div className="plan-content-includes-list-item">
              {contactNote ? contactNote : null}
            </div>
            <div className="plan-content-contact-title-div">
              <span className="plan-content-contact-title">
                App Publisher:{' '}
              </span>
              <span className="plan-content-contact-title-name">
                {solution.overview.publisherName}
              </span>
            </div>
            <div className="plan-content-contact-details">
              <div>
                Email:{' '}
                <a href={`mailto:${solution.support.emailAddress}`}>
                  {solution.support.emailAddress}
                </a>
              </div>
              <div>
                Phone:{' '}
                <a href={`mailto:${solution.support.phoneNumber}`}>
                  {solution.support.phoneNumber}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SubscriptionContactDetails);
