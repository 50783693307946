/**
 * Check whether a feature gate is enabled.
 * @param org {object} the body for the current org
 * @param feature {string} the name of the feature gate
 * @returns {boolean} `true` only if the feature gate is both set and equal
 * to the string `"enabled"`; otherwise `false`
 */
export function isEnabled(org, feature) {
  if (org && org.featureGates) {
    try {
      const orgFeatures = JSON.parse(org.featureGates);
      return orgFeatures[feature] === 'enabled';
    } catch (err) {
      return false;
    }
  }
  return false;
}

/**
 * Check whether a feature gate is explicitly disabled.
 * @param org {object} the body for the current org
 * @param feature {string} the name of the feature gate
 * @returns {boolean} `true` only if the feature gate is both set and equal
 * to the string `"disabled"`; otherwise `false`
 */
export function isDisabled(org, feature) {
  if (org && org.featureGates) {
    try {
      const orgFeatures = JSON.parse(org.featureGates);
      return orgFeatures[feature] === 'disabled';
    } catch (err) {
      return false;
    }
  }
  return false;
}

export const Features = Object.freeze({
  NEW_UTILIZATION: 'newUtilizationUI',
  UTILIZATION_PREVIOUS_MONTH: 'utilizationPreviousMonth'
});
