import React from 'react';
import { Heading, HEADING_TYPES } from 'cdk-radial';
import { Divider } from 'cdk-radial';

class HeadlineAndDivider extends React.Component {
  render() {
    return (
      <div className="solution-left-panel-header" data-cy="hd_lbl_static_text">
        <Heading className="purchase-sol__details">
          {this.props.headline}
        </Heading>
        <Divider className="appDetails-left-side-section-divider" />
      </div>
    );
  }
}

export default HeadlineAndDivider;
