import React, { useState, useEffect } from 'react';
import { Dialog } from 'cdk-radial';
import {
  Button,
  MaskableFormattedInput,
  Loader,
  Column,
  Grid,
  Row,
  Toast,
  TextButton,
  DropdownMenu
} from 'cdk-radial';
import { AiFillBank } from 'react-icons/ai';
import { getApolloClient } from '../../../../../../containers/GraphQLClient';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import {
  verifyAccount,
  otherPaymentOptionRequest,
  getPaymentSetting,
  setPaymentDetails
} from '../../../../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';
import PaymentInfoConstants from '../../../../../admin-account/solution-moderation/PaymentInfoConstants';
import gql from 'graphql-tag';
import _ from 'lodash';
import './verifyAccount.scss';
import { PRODUCT } from '../../../../../common/constants/MonetizationConstants';

const VerifyAccount = ({
  solnId,
  orgId,
  loggedInEmail,
  token,
  stripePriceId,
  savedSolutionPaymentDetails,
  paymentSettingData,
  bankApps,
  isPaymentEdit,
  setIsVerifyOpen,
  isVerifyOpen,
  setPaymentMethodRefresh,
  paymentMethodRefresh,
  onPaymentUpdateSuccess,
  setShowEdit
}) => {
  const dispatch = useDispatch();
  // const verifyAccountSliceData = useSelector(state => state.verifyAccount);
  // const { paymentSettingData } = verifyAccountSliceData.data;
  const [formData, updateFormData] = useState({
    last4: savedSolutionPaymentDetails?.last4,
    microDeposit1: 0,
    microDeposit2: 0
  });
  const [status, setStatus] = useState('');
  const [isLoadingToVerifyAccount, setIsLoadingToVerifyAccount] = useState(
    false
  );
  const [isFailedToVerifyAccount, setIsFailedToVerifyAccount] = useState(false);
  const [formError, setFormError] = useState({});
  const [isMicroDepositDialogOpen, setIsMicroDepositDialogOpen] = useState(
    false
  );
  const [toastMessage, setToastMessage] = useState(
    'The amounts entered do not match the amounts that were deposited.'
  );
  const [alternatePaymentOption, setAlternatePaymentOption] = useState(false);
  const [isACHBlocked, setIsACHBlocked] = useState(false);
  const STRIPE_BANK = 'stripe_bank';
  const ACTIVE = 'active';
  const INACTIVE = 'inactive';
  const VERIFIED = 'verified';
  const INCOMPLETE = 'incomplete';
  const [paymentPendingText, setPaymentPendingText] = useState('');
  const [enableOkButton, setEnableOkButton] = useState(false);

  useEffect(() => {
    if (status && isMicroDepositDialogOpen) {
      setIsMicroDepositDialogOpen(false);
    }
    setIsFailedToVerifyAccount(false);
    updateFormData({
      last4: savedSolutionPaymentDetails?.last4,
      microDeposit1: 0,
      microDeposit2: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicroDepositDialogOpen, status]);

  useEffect(() => {
    setIsFailedToVerifyAccount(false);
    setIsLoadingToVerifyAccount(false);
  }, [formData]);

  useEffect(() => {
    getPaymentPendingText(solnId);
  }, [bankApps, paymentSettingData]);

  const accountSelectionHandler = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setFormError({
      ...formError,
      [e.target.name]: null
    });
  };

  const handleDialogClick = e => {
    e.stopPropagation();
  };
  const getPaymentPendingText = async solnId => {
    if (Array.isArray(bankApps) && bankApps.length > 0) {
      const savedBankLast4 = paymentSettingData?.map(b => b.last4);
      const bankAppIds = bankApps.map(a => a.solution_id);
      let foundBankacc = false;
      bankApps.forEach(i => {
        if (bankAppIds?.includes(solnId) && savedBankLast4?.includes(i.last4)) {
          foundBankacc = true;
        }
      });
      if (foundBankacc) {
        setPaymentPendingText('Verify Account');
      } else {
        setPaymentPendingText('Choose another payment option');
      }
    }
  };

  const handleChange = e => {
    // line no 73 - e.target.value.split('.')
    // as we are using masked input
    // so $0. is masked so we are spliting here to get only decimal
    const value = e.target.value;
    const invalidMicroDeposits = ['$0.00', '$0.0'];
    if (invalidMicroDeposits.includes(value)) {
      updateFormData({
        ...formData,
        [e.target.name]: value === '$0.0' ? value : ''
      });
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: value
      });
    }
    setFormError({
      ...formError,
      [e.target.name]: null
    });
  };

  const checkIsFormValid = () => {
    let isFormValid = true,
      formErrorValues = {};

    _.map(formData, (value, key) => {
      if (!value) {
        isFormValid = false;
        formErrorValues[key] = 'Please enter value';
      }
      if (key === 'last4' && value === 'XXXX') {
        isFormValid = false;
        formErrorValues[key] = 'Please select value';
      }
    });
    return { isFormValid, formErrorValues };
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    let { isFormValid, formErrorValues } = checkIsFormValid(formData);
    let { last4, microDeposit1, microDeposit2 } = formData;
    const customerRef =
      paymentSettingData.length > 0 &&
      paymentSettingData.filter(x => x.last4 === last4)[0].customer_reference;
    if (isFormValid) {
      setIsLoadingToVerifyAccount(true);
      const response = await verifyCustomerBankAccount(
        orgId,
        solnId,
        customerRef,
        last4,
        microDeposit1.split('.')[1].trim(),
        microDeposit2.split('.')[1].trim()
      );
      if (response && response.status && response.status === VERIFIED) {
        if (isPaymentEdit) {
          const updatePaymentMethod = await updatePaymentMethodOnVerify(
            solnId,
            orgId,
            last4,
            STRIPE_BANK,
            loggedInEmail.toLowerCase()
          );
          if (
            updatePaymentMethod.status === '200' ||
            updatePaymentMethod.status === 200
          ) {
            setStatus('verified');
            setIsLoadingToVerifyAccount(false);
            setIsFailedToVerifyAccount(false);
            onPaymentUpdateSuccess({
              status: updatePaymentMethod?.status,
              message: updatePaymentMethod?.message
            });
            setIsVerifyOpen(false);
          } else {
            setIsLoadingToVerifyAccount(false);
            setIsFailedToVerifyAccount(false);
          }
        } else {
          const subscriptionResponse = await initiateSubscriptionForISV(
            solnId,
            stripePriceId,
            last4,
            STRIPE_BANK,
            customerRef
          );
          if (
            subscriptionResponse.status === '200' ||
            subscriptionResponse.status === 200
          ) {
            if (subscriptionResponse.subscriptionStatus === ACTIVE) {
              // we will publish the app as subscription has created successfully in stripe
              setStatus('verified');
              dispatch(
                setPaymentDetails({
                  paymentMethod: 'bankACH',
                  last4: last4,
                  invoiceURL: subscriptionResponse.invoiceURL,
                  microDepositVerification: true
                })
              );
              dispatch(verifyAccount(true));
              setIsLoadingToVerifyAccount(false);
              setIsFailedToVerifyAccount(false);
            } else if (subscriptionResponse.subscriptionStatus === INCOMPLETE) {
              // Here stripe fails to process the payment/subscription
              setStatus('');
              setIsLoadingToVerifyAccount(false);
              setIsFailedToVerifyAccount(true);
              setToastMessage(PaymentInfoConstants.SUBSFAILEDMESSAGE);
              setAlternatePaymentOption(true);
            }
          } else {
            // Here some error with diffrent status code other than 200
            setStatus('');
            setIsLoadingToVerifyAccount(false);
            setIsFailedToVerifyAccount(true);
            setToastMessage(PaymentInfoConstants.ANOTHERPAYOPTION);
            setAlternatePaymentOption(true);
          }
        }
      } else {
        //Here handling error when microdeposits amount will be wrong
        setStatus('');
        setIsLoadingToVerifyAccount(false);
        setIsFailedToVerifyAccount(true);
        if (
          response.message.includes(
            PaymentInfoConstants.MULTIPLEATTEMPTIDENTIFIER
          )
        ) {
          if (isPaymentEdit) {
            setToastMessage(
              PaymentInfoConstants.EDITPAYMENTMULTIATTEMPTFAILURETOAST
            );
            setEnableOkButton(true);
          } else {
            setAlternatePaymentOption(true);
            setToastMessage(response.message);
          }
          setIsACHBlocked(true);
        } else {
          setToastMessage(response.message);
        }
      }
    } else {
      setFormError(formErrorValues);
    }
    return false;
  };
  const handleChooseAnotherPaymentOption = e => {
    e.preventDefault();
    dispatch(otherPaymentOptionRequest(true));
    //dispatch(getPaymentSetting({ email: loggedInEmail, token: token }));
    setStatus('');
    setIsLoadingToVerifyAccount(false);
    setIsFailedToVerifyAccount(true);
    setIsMicroDepositDialogOpen(false);
  };
  const verifyCustomerBankAccount = async (
    orgId,
    solnId,
    customerRef,
    last4,
    amountFirst,
    amountSecond
  ) => {
    const client = getApolloClient();
    let response = await client.query({
      query: verifyBankAccount,
      variables: {
        orgId: orgId,
        solnId: solnId,
        customerRef: customerRef,
        last4: last4,
        amountFirst: amountFirst,
        amountSecond: amountSecond,
        productType: PRODUCT.LISTING_FEE
      },
      fetchPolicy: 'no-cache' // disabling cache for verify account graphql
    });
    if (!response.errors) return response.data.verifyBankAccount;
    return null;
  };
  const initiateSubscriptionForISV = async (
    solnId,
    priceId,
    last4,
    paymentType,
    customerRef
  ) => {
    const client = getApolloClient();
    let response = await client.query({
      query: initiateSubscription,
      variables: {
        solutionId: solnId,
        priceId,
        last4,
        paymentType,
        productType: PRODUCT.LISTING_FEE,
        couponId: '',
        customerRef
      }
    });
    if (!response.errors) return response.data.initiateSubscription;
    return null;
  };

  const updatePaymentMethodOnVerify = async (
    solnId,
    orgId,
    last4,
    paymentType,
    userEmail
  ) => {
    const client = getApolloClient();
    let response = await client.mutate({
      mutation: updatePaymentMethodOfAppOnVerify,
      variables: {
        solutionId: solnId,
        orgId,
        last4,
        paymentType,
        productType: PRODUCT.LISTING_FEE,
        userEmail
      },
      fetchPolicy: 'no-cache' // disabling cache for verify account graphql
    });
    if (!response.errors) return response.data.updatePaymentMethodOfAppOnVerify;
    return null;
  };

  const accountVerifyHandler = e => {
    if (paymentPendingText === 'Choose another payment option') {
      setIsMicroDepositDialogOpen(false);
      handleChooseAnotherPaymentOption(e);
    } else {
      setIsMicroDepositDialogOpen(true);
    }
  };
  const handClose = () => {
    let country = 'US';
    if (isPaymentEdit && setIsVerifyOpen) {
      setPaymentMethodRefresh(!paymentMethodRefresh);
      setIsVerifyOpen(false);
    } else if (paymentSettingData && paymentSettingData.length)
      country = paymentSettingData[0].country;
    let currentUser = jwtDecode(token)?.sub?.toLowerCase();
    dispatch(
      getPaymentSetting({
        email: currentUser,
        countryCode: country,
        token: token
      })
    );
    setIsMicroDepositDialogOpen(false);
  };

  return (
    <div className="verify-account">
      {!isPaymentEdit && (
        <a
          onClick={e => {
            accountVerifyHandler(e);
          }}
          className="verify-account-link-container"
        >
          {paymentPendingText}
        </a>
      )}
      <Dialog
        id="dialog-micro_deposit"
        isOpen={isMicroDepositDialogOpen || isVerifyOpen}
        onClose={handClose}
        title="Verify Bank Account"
        onClick={handleDialogClick}
        style={{ width: '540px' }}
      >
        <Grid>
          <Row>
            Please select the account last4 digit which you want to verify.
          </Row>
          <Row>
            <DropdownMenu
              required
              enableCustomValidation
              hasError={formError.last4}
              isDisabled={true}
              label="Select Last4 Digit of ACH"
              className={'verify-ach-account-dropdown'}
              value={{
                value: `XXXX-XXXX-XXXX-${savedSolutionPaymentDetails?.last4}`,
                label: `XXXX-XXXX-XXXX-${savedSolutionPaymentDetails?.last4}`
              }}
              onChange={({ value }) => {
                accountSelectionHandler({ target: { name: 'last4', value } });
              }}
              options={
                paymentSettingData &&
                paymentSettingData
                  .filter(x => x.payment_type === 'stripe_bank' && !x.status)
                  .map(type => ({
                    value: type.last4,
                    label: type.last4,
                    icon: <AiFillBank />
                  }))
              }
            />
          </Row>
          <Row>
            <Column l={12} xs={12}>
              <div className="micro_deposit_label_text">
                Enter the two amounts that were deposited into your account.
              </div>
            </Column>
          </Row>
          {!isFailedToVerifyAccount &&
            renderInputsRow(formError, formData, handleChange, isACHBlocked)}
          {isFailedToVerifyAccount &&
            renderInputsRow(formError, formData, handleChange, isACHBlocked)}
          {isFailedToVerifyAccount && (
            <Row>
              <Toast
                content={toastMessage}
                variant={'negative'}
                style={{ padding: '0px 10px 5px 0px', zIndex: '5' }}
              />
            </Row>
          )}
          {isLoadingToVerifyAccount && (
            <Row>
              <div className="verify_account_actions">
                <Loader
                  data-testid="loader"
                  hideLabel={false}
                  label="Loading..."
                  spinnerClassName=""
                  variant="inline"
                />
              </div>
            </Row>
          )}
          {!isLoadingToVerifyAccount && (
            <Row>
              {enableOkButton ? (
                <div className="verify_account_actions">
                  <Button
                    className="verify_button"
                    onClick={() => {
                      setShowEdit(true);
                      setIsVerifyOpen(false);
                    }}
                    text="Add a New Payment Method"
                  />
                </div>
              ) : alternatePaymentOption ? (
                <div className="verify_account_actions">
                  <Button
                    className="verify_button"
                    onClick={handleChooseAnotherPaymentOption}
                    text="Choose another payment option"
                  />
                </div>
              ) : (
                <div className="verify_account_actions">
                  <Button
                    isDisabled={
                      !formData.microDeposit1 ||
                      formData.microDeposit1 === '$0.0' ||
                      (!formData.microDeposit2 ||
                        formData.microDeposit2 === '$0.0') ||
                      !formData.last4
                    }
                    className="verify_button"
                    onClick={handleFormSubmit}
                    text="Verify"
                  />
                </div>
              )}
            </Row>
          )}
        </Grid>
      </Dialog>
    </div>
  );
};
const renderInputsRow = (formError, formData, handleChange, isACHBlocked) => {
  return (
    <Row>
      <Column l={6} xs={6}>
        <div className="micro_deposit_inlineInput-left">
          <MaskableFormattedInput
            type="text"
            required
            enableCustomValidation
            hasError={formError.microDeposit1}
            label="Deposit-1"
            name="microDeposit1"
            fullWidth
            value={formData.microDeposit1 || ''}
            onChange={handleChange}
            mask="$0.99"
            isDisabled={isACHBlocked}
          />
        </div>
      </Column>
      <Column l={6} xs={6}>
        <div className="micro_deposit_inlineInput-right">
          <MaskableFormattedInput
            type="text"
            required
            enableCustomValidation
            hasError={formError.microDeposit2}
            label="Deposit-2"
            name="microDeposit2"
            fullWidth
            value={formData.microDeposit2 || ''}
            onChange={handleChange}
            mask="$0.99"
            isDisabled={isACHBlocked}
          />
        </div>
      </Column>
    </Row>
  );
};

export const verifyBankAccount = gql`
  query(
    $orgId: String
    $solnId: String
    $customerRef: String
    $last4: String
    $amountFirst: String
    $amountSecond: String
    $productType: String
  ) {
    verifyBankAccount(
      orgId: $orgId
      solnId: $solnId
      customerRef: $customerRef
      last4: $last4
      amountFirst: $amountFirst
      amountSecond: $amountSecond
      productType: $productType
    ) {
      status
      customer
      type
      message
    }
  }
`;
export const getPaymentSettingData = gql`
  query($emailId: String, $countryCode: String) {
    getPaymentSettingData(emailId: $emailId, countryCode: $countryCode) {
      customer_login_email
      payment_type
      customer_reference
      payment_type_reference
      status
      last4
    }
  }
`;
export const initiateSubscription = gql`
  query(
    $solutionId: String
    $priceId: String
    $last4: String
    $paymentType: String
    $productType: String
    $couponId: String
    $customerRef: String
  ) {
    initiateSubscription(
      solutionId: $solutionId
      priceId: $priceId
      last4: $last4
      paymentType: $paymentType
      productType: $productType
      couponId: $couponId
      customerRef: $customerRef
    ) {
      status
      message
      verified
      subscriptionStatus
      invoiceURL
    }
  }
`;
const updatePaymentMethodOfAppOnVerify = gql`
  mutation(
    $solutionId: String
    $orgId: String
    $last4: String
    $paymentType: String
    $productType: String
    $userEmail: String
  ) {
    updatePaymentMethodOfAppOnVerify(
      solutionId: $solutionId
      orgId: $orgId
      last4: $last4
      paymentType: $paymentType
      productType: $productType
      userEmail: $userEmail
    ) {
      message
      status
    }
  }
`;

export default VerifyAccount;
