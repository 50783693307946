import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { Document, Page, pdfjs } from 'react-pdf';
import { DialogSpinnerWithText } from '../../../common/Spinner';
// import { FillablePanel } from '@cdkglobal/output-services-viewer-dev-strictmode';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../../containers/GraphQLClient';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { environmentURLs } from '../../../common/environment/CaptureEnvironment';
import signatureIcon from './utils/signature.png';
import clearIcon from './utils/clear.png';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const DOCUSIGN_HEIGHT = 1030;
const FIELD_HEIGHT = 50;
const PREVIEWFILE = 'preview.pdf';

/*
 * The following functions were added to help handle the inconsistencies between
 * the coordinate positions and the end positions on docusign. They were calculated
 * by comparing coordinate positions between our site and docusign to find the difference.
 * As of writing this I was unable to find any actual documentation or issues relating to
 * docusign handling coordinates, but these equations position our fields correctly (within a
 * small margin of error).
 */
function yMod(y) {
  // The distance between our y value and where it will be positioned on docusign
  const yModValue = 0.00000167 * Math.pow(y, 2) - 0.084 * y + 10.7;
  return y - yModValue;
}

function xMod(x) {
  // The distance between our x value and where it will be positioned on docusign
  const xModValue = -0.00002 * Math.pow(x, 2) - 0.052 * x + 27.4;
  return x - xModValue;
}

export function convertCoordToDocusign(coordinate, docSize) {
  docSize.width = docSize.width - 160;
  docSize.height = docSize.height - 90;
  const calcDocusignWidth = DOCUSIGN_HEIGHT * (docSize.width / docSize.height);
  const x = calcDocusignWidth * ((coordinate.x - 80) / docSize.width) + 30;
  const y =
    DOCUSIGN_HEIGHT * ((coordinate.y + 5) / docSize.height) - FIELD_HEIGHT / 2;
  return {
    ...coordinate,
    // For some reason docusign is always approx 20 px off of x location
    x: xMod(x),
    y: yMod(y)
  };
}

class CaptureCoordinates extends React.Component {
  constructor(props) {
    super(props);
    this.documentArea = null;
    this.state = {
      numPages: null,
      pageNumber: 1,
      moving: false,
      activeFieldId: '',
      activeSignatureFieldId: '',
      currentPage: 1,
      disableScrollUp: true,
      disableScrollDown: false
    };
    this.calculateCoordinates = this.calculateCoordinates.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    //this.addTextField = this.addTextField.bind(this);
    this.addSignatureField = this.addSignatureField.bind(this);
    this.move = this.move.bind(this);
    this.moveSignatureField = this.moveSignatureField.bind(this);
    this.fieldDrop = this.fieldDrop.bind(this);
    this.signatureFieldDrop = this.signatureFieldDrop.bind(this);
    this.client = getApolloClient();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages,
      disableScrollDown: numPages === 1 ? true : false
    });
  };

  /**
   * create pdf file url from blob, store the url in state
   * the url will be used by pdf component
   */
  componentDidMount() {
    const objectURL = window.URL.createObjectURL(this.props.file);
    let base64_file;
    this.toBase64(this.props.file).then(res => {
      res = 'data:application/pdf;base64,' + res;
      this.setState({
        base64File: res
      });
    });
    this.setState({
      file: objectURL
    });

    //preload SVGs
    let clearSvg = new Image();
    clearSvg.src = clearIcon;
    let signatureSvg = new Image();
    signatureSvg.src = signatureIcon;
  }

  /**
   * convert file stream to base64 object
   * Params:
   *  1. file - File object
   * Returns:
   *  1. base64 encoded object of the stream
   */
  toBase64(file) {
    //https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(reader.result.replace('data:application/pdf;base64,', ''));
      reader.onerror = error => reject(error);
    });
  }

  /**
   * calculate cordinates of the mouse within document area
   *  Params:
   *  1. clientX - standard clientX property of the event object
   *  2. clientY - standard clientY property of the event object
   *  Returns:
   *  1. x - horizontal location of the field relative to the document area
   *  2  y - vertial location of the field relative to the document area
   */
  calculateCoordinates(clientX, clientY) {
    let rect = this.documentArea.getBoundingClientRect();
    return {
      x: clientX - Math.round(rect.left),
      y: clientY - Math.round(rect.top)
    };
  }

  /**
   * calculate cordinates of the mouse on screen
   *  Params:
   *  1. x - horizontal location of the field relative to the document area
   *  2. y - vertial location of the field relative to the document area
   *  Returns:
   *  1. left - horizontal location of the field on screen
   *  2  top - vertical location of the field on screen
   */
  calculateCoordinatesOnScreen(x, y) {
    let rect = this.documentArea.getBoundingClientRect();
    return {
      left: x + Math.round(rect.left),
      top: y + Math.round(rect.top)
    };
  }

  /**
   * generate a random uuid
   * Returns:
   *  random uuid
   */
  Id() {
    return (
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  }

  /**
   * delete field from the page and update state
   *  Params:
   *  1. id - unique identifier of the field to be deleted
   */
  deleteField(id) {
    let { currentPage } = this.state;
    let fields = this.state['page-' + currentPage + '-textFields'];
    fields = fields.filter(field => field.id !== id);

    this.setState({
      ['page-' + currentPage + '-textFields']: fields
    });
  }

  /**
   * delete signature field from the page and update state
   *  Params:
   *  1. id - unique identifier of the field to be deleted
   */
  deleteSignatureField(id) {
    let { currentPage } = this.state;
    let fields = this.state['page-' + currentPage + '-signatureFields'];
    fields = fields.filter(field => field.id !== id);

    this.setState({
      ['page-' + currentPage + '-signatureFields']: fields
    });

    this.removeListeners();
  }

  /**
   * field style
   *  Params:
   *  1. field style
   */
  fieldStyle(e) {
    return {
      top: e.clientY + 10 + 'px',
      left: e.clientX + 10 + 'px'
    };
  }

  createDeleteButton(id) {
    return (
      <button
        onClick={() => this.deleteSignatureField(id)}
        className={'capture-coordinates__delete_button'}
      >
        <img src={clearIcon} alt="Clear" />
      </button>
    );
  }

  /**
   * create a React element for text field, add event listeners to field
   * element props -
   *              1. dynamic ref
   *              2. key
   *              3. style
   *  children:
   *        1. button - to delete the field
   */
  /*addTextField(e) {
    let { currentPage } = this.state;
    let fields = this.state['page-' + currentPage + '-textFields'] || [];
    let id = this.Id();
    let element = React.createElement(
      'div',
      {
        ref: e => (this[id + '_ref'] = e),
        key: id,
        style: this.fieldStyle(e)
      },
      'TEXT FIELD',
      <button
        onClick={() => this.deleteField(id)}
        style={{
          height: '100%',
          background: '#CC0000',
          color: 'white',
          marginLeft: '5px'
        }}
      >
        X
      </button>
    );

    fields.push({
      id: id,
      element: element
    });

    this.setState({
      moving: true,
      activeFieldId: id,
      ['page-' + currentPage + '-textFields']: fields
    });
    this.documentArea.addEventListener('mousemove', this.move, false);
    this.documentArea.addEventListener('mousedown', this.fieldDrop, false);
  }*/

  /**
   * create a React element for signature field, add event listeners to signature field
   * element props -
   *              1. dynamic ref
   *              2. key
   *              3. style
   *  children:
   *        1. button - to delete the field
   */
  addSignatureField(e) {
    let { currentPage } = this.state;
    let signatureFields =
      this.state['page-' + currentPage + '-signatureFields'] || [];
    let id = this.Id();
    let element = React.createElement(
      'div',
      {
        ref: e => (this[id + '_ref'] = e),
        key: id,
        className: 'capture-coordinates__field',
        style: this.fieldStyle(e)
      },
      this.createDeleteButton(id),
      <div className={'capture-coordinates__field_container'}>
        <div className={'capture-coordinates__sign'}>Sign</div>
        <div className={'capture-coordinates__sign_icon'}>
          <img src={signatureIcon} alt="Signature" />
        </div>
      </div>
    );

    signatureFields.push({
      id: id,
      element: element
    });

    this.setState({
      moving: true,
      activeSignatureFieldId: id,
      ['page-' + currentPage + '-signatureFields']: signatureFields
    });
    this.documentArea.addEventListener(
      'mousemove',
      this.moveSignatureField,
      false
    );
    this.documentArea.addEventListener(
      'mousedown',
      this.signatureFieldDrop,
      false
    );
  }

  /**
   * event listener for text field
   *  Params:
   *  1. e - standard mouseMove event
   */
  move(e) {
    let newX, newY, field;
    let { currentPage } = this.state;
    newX = e.pageX + 10;
    newY = e.pageY - 10;
    field = this.state['page-' + currentPage + '-textFields'].filter(field => {
      return field.id === this.state.activeFieldId;
    });
    this[field[0].id + '_ref'].style.left = newX + 'px';
    this[field[0].id + '_ref'].style.top = newY + 'px';
  }

  /**
   * event listener for text fields being dropped
   */
  fieldDrop() {
    let field, left, top;
    let { currentPage } = this.state;
    let fields = this.state['page-' + currentPage + '-textFields'];
    field = fields.filter(field => {
      return field.id === this.state.activeFieldId;
    });

    left = this[field[0].id + '_ref'].style.left;
    top = this[field[0].id + '_ref'].style.top;
    let { x, y } = this.calculateCoordinates(parseInt(left), parseInt(top));

    field[0].x = x;
    field[0].y = y;
    field[0].page = this.state.currentPage;

    this[field[0].id + '_ref'].style.border = '1px solid';
    this.setState({
      ['page-' + currentPage + '-textFields']: fields
    });

    this.removeListeners();
  }

  /**
   * event listener for signature field
   * Params:
   *  1. e - standard mouseMove event
   */
  moveSignatureField(e) {
    let newX, newY, field;
    let { currentPage } = this.state;
    newX = e.pageX + 5;
    newY = e.pageY - 5;
    field = this.state['page-' + currentPage + '-signatureFields'].filter(
      field => {
        return field.id === this.state.activeSignatureFieldId;
      }
    );
    this[field[0].id + '_ref'].style.left = newX + 'px';
    this[field[0].id + '_ref'].style.top = newY + 'px';
  }

  /**
   * event listener for signature fields being dropped
   */
  signatureFieldDrop() {
    let field, left, top;
    let { currentPage } = this.state;
    let signatureFields = this.state[
      'page-' + currentPage + '-signatureFields'
    ];
    field = signatureFields.filter(field => {
      return field.id === this.state.activeSignatureFieldId;
    });

    left = this[field[0].id + '_ref'].style.left;
    top = this[field[0].id + '_ref'].style.top;
    let { x, y } = this.calculateCoordinates(parseInt(left), parseInt(top));

    field[0].x = x;
    field[0].y = y;
    field[0].page = this.state.currentPage;

    this.setState({
      ['page-' + currentPage + '-signatureFields']: signatureFields
    });

    this.removeListeners();
  }

  async saveCoordinates() {
    this.setState({
      showSpinner: false,
      highlightSignatureRequired: false
    });

    let file;
    try {
      file = await this.toBase64(this.props.file);
    } catch (e) {
      //error
    }

    /*example state
      state : {
       page-1-textFields: [
         {
           id: '',
           element: '',
           x: '',
           y: ''
         },
         {
           id: '',
           element: '',
           x: '',
           y: ''
         }
       ],
       page-2-textFields: [
         {
           id: '',
           element: '',
           x: '',
           y: ''
         },
         {
           id: '',
           element: '',
           x: '',
           y: ''
         }
       ]
      }*/

    let textFields = Object.keys(this.state)
      .filter(key => key.includes('-textFields'))
      .reduce((fieldsArray, key) => {
        if (this.state[key] && this.state[key].length > 0) {
          this.state[key].map(field => {
            fieldsArray.push(field);
          });
        }
        return fieldsArray;
      }, []);

    let signatureFields = Object.keys(this.state)
      .filter(key => key.includes('-signatureFields'))
      .reduce((fieldsArray, key) => {
        if (this.state[key] && this.state[key].length > 0) {
          this.state[key].map(field => {
            fieldsArray.push(field);
          });
        }
        return fieldsArray;
      }, []);

    if (
      Object.keys(textFields).length === 0 &&
      Object.keys(signatureFields).length === 0
    ) {
      this.setState({ highlightSignatureRequired: true });
      return;
    }

    //collect coordinates from each field
    let text_fields =
      textFields.length > 0 &&
      textFields.map(field => {
        return {
          x: field.x,
          y: field.y,
          page: field.page
        };
      });

    let signature_fields =
      signatureFields.length > 0 &&
      signatureFields.map(field => {
        let width = this.documentArea.offsetWidth;
        let height = this.documentArea.offsetHeight;
        let coordinates = convertCoordToDocusign(
          { x: field.x, y: field.y },
          { width: width, height: height }
        );
        console.log('signature blocks', coordinates);
        return {
          x: coordinates.x,
          y: coordinates.y,
          page: field.page
        };
      });

    this.setState({ signature_fields: signature_fields });

    /*this.removeListeners();

    this.setState({
      nextView: true
    });*/

    this.setState({ showSpinner: true });
    this.getSigningURL()
      .then(links => {
        this.setState({ showSpinner: false });

        if (
          !links &&
          !links.data &&
          !links.data.solutionTermsSigningUrl &&
          !links.data.solutionTermsSigningUrl.links &&
          !Array.isArray(links.data.solutionTermsSigningUrl.links) &&
          !links.data.solutionTermsSigningUrl.links.length > 0
        ) {
          console.error('error in response of solutionTermsSigningUrl query');
          return;
        }
        const url = links.data.solutionTermsSigningUrl.links[0].href;
        this.props.onClose(url, JSON.stringify(signature_fields));
      })
      .catch(e => {
        console.error(`error in fetching signing url: ${e}`);
        this.props.onClose();
      });
  }

  /**
   * generate a random uuid
   * Returns:
   *  random uuid
   */
  Id() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  /**
   * generates signing url by making graphql query to solutionTermsSigningUrl
   * @params: none
   * @returns: response object for the query
   * @throws: error if query fails
   */
  async getSigningURL() {
    try {
      let { name, email } = this.props.auth.userData;
      let solutionTermsSigningUrl = await this.client.query({
        query: getSolutionTermsSigningUrl,
        variables: {
          id: this.Id(),
          firstName: name.split(' ')[0].trim(),
          lastName: name.split(' ')[1].trim(),
          email: email,
          solutionTerms: this.state.fileWithData,
          signatureCoordinates: JSON.stringify(this.state.signature_fields),
          redirectUrl: environmentURLs.signing_redirect_url,
          fileName: PREVIEWFILE
        }
      });
      return solutionTermsSigningUrl;
    } catch (e) {
      console.error(e);
      throw new Error('error in querying solutionTermsSigningUrl', e);
    }
  }

  /**
   * navigate page
   * Params:
   *  1. scroll - scrolling UP or Down
   */
  setPage(scroll) {
    let { currentPage, disableScrollUp, disableScrollDown } = this.state;
    if (scroll === 'UP') {
      currentPage = --currentPage;
      disableScrollDown = false;
      if (currentPage === 1) {
        disableScrollUp = true;
      }
    } else {
      currentPage = ++currentPage;
      disableScrollUp = false;
      if (currentPage === this.state.numPages) {
        disableScrollDown = true;
      }
    }

    this.setState(
      {
        currentPage: currentPage,
        disableScrollUp: disableScrollUp,
        disableScrollDown: disableScrollDown
      },
      () => {
        //restore fields on the page
        let fields = this.state['page-' + currentPage + '-textFields'];
        if (fields && fields.length > 0) {
          fields.forEach(field => {
            let { left, top } = this.calculateCoordinatesOnScreen(
              field.x,
              field.y
            );
            this[field.id + '_ref'].style.left = left + 'px';
            this[field.id + '_ref'].style.top = top + 'px';
            this[field.id + '_ref'].style.border = '1px solid';
          });
        }

        let signatureFields = this.state[
          'page-' + currentPage + '-signatureFields'
        ];
        if (signatureFields && signatureFields.length > 0) {
          signatureFields.forEach(signatureField => {
            let { left, top } = this.calculateCoordinatesOnScreen(
              signatureField.x,
              signatureField.y
            );
            this[signatureField.id + '_ref'].style.left = left + 'px';
            this[signatureField.id + '_ref'].style.top = top + 'px';
            this[signatureField.id + '_ref'].style.border = '1px solid';
          });
        }
      }
    );
  }

  /**
   * remove listeners
   */
  removeListeners() {
    this.documentArea.removeEventListener('mousemove', this.move);
    this.documentArea.removeEventListener('mousedown', this.fieldDrop);
    this.documentArea.removeEventListener('mousemove', this.moveSignatureField);
    this.documentArea.removeEventListener('mousedown', this.signatureFieldDrop);
  }

  fillablepdfCallback() {}

  updateViewerMediaAndType(data) {
    this.setState({
      fileWithData: data.media,
      nextView: true
    });
  }

  render() {
    const { numPages, currentPage } = this.state;
    const signatureButtonClassNames = this.state.highlightSignatureRequired
      ? 'capture-coordinates-button float-left capture-coordinates-button__vibrate_signature_coordinates'
      : 'capture-coordinates-button float-left';

    if (this.state.showSpinner) {
      return (
        <DialogSpinnerWithText
          open={this.state.showSpinner}
          label={'Loading Preview...'}
        />
      );
    }

    if (this.state.nextView) {
      return (
        <div className={'fullscreen-children-alignment'}>
          {this.state.moving &&
            this.state[`page-${currentPage}-textFields`] &&
            this.state[`page-${currentPage}-textFields`].length > 0 &&
            this.state[`page-${currentPage}-textFields`].map(
              field => field.element
            )}

          {this.state.moving &&
            this.state[`page-${currentPage}-signatureFields`] &&
            this.state[`page-${currentPage}-signatureFields`].length > 0 &&
            this.state[`page-${currentPage}-signatureFields`].map(
              field => field.element
            )}

          <div className={'capture-coordinates-header-container'}>
            <div
              className={
                'capture-coordinates-fields-container terms-of-use-header-text'
              }
            >
              Add Fields:
              <span className={'capture-coordinates__buttons'}>
                {/*<Button
                className={'capture-coordinates-button float-left'}
                onClick={e => this.addTextField(e)}
              >
                <i className="material-icons terms-of-use-icon">title</i>
                &nbsp; Text
              </Button>*/}
                <Button
                  className={signatureButtonClassNames}
                  onClick={e => this.addSignatureField(e)}
                >
                  <i className={'material-icons terms-of-use-icon'}>edit</i>
                  &nbsp; Signature
                </Button>
                <Button
                  className={'capture-coordinates-no-border-button float-left'}
                  onClick={() => this.saveCoordinates()}
                >
                  <i className={'material-icons terms-of-use-icon'}>forward</i>
                  &nbsp; Next
                </Button>
              </span>
            </div>
          </div>
          <div className={'capture-coordinates-pdf-container'}>
            <div className={'capture-coordinates-pdf-body'}>
              <div ref={e => (this.documentArea = e)}>
                <Document
                  file={this.state.fileWithData}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={this.state.currentPage} />
                </Document>
              </div>
              <div className={'capture-coordinates__navigation_container'}>
                <div
                  className={'capture-coordinates__navigation_container_div'}
                >
                  <button
                    onClick={() => this.setPage('UP')}
                    className={'capture-coordinates__page_navigator_button'}
                    disabled={this.state.disableScrollUp}
                  >
                    <i className="material-icons">arrow_drop_up</i>
                  </button>
                  <div className={'capture-coordinates__currentPage'}>
                    {this.state.currentPage}/{numPages}
                  </div>
                  <button
                    onClick={() => this.setPage('DOWN')}
                    className={'capture-coordinates__page_navigator_button'}
                    disabled={this.state.disableScrollDown}
                  >
                    <i className="material-icons">arrow_drop_down</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // else {
    //   if (this.state.base64File) {
    //     return (
    //       <div>
    //         <div
    //           className={
    //             'capture-coordinates__fillable_panel react-pdf__Document'
    //           }
    //         >
    //           <FillablePanel
    //             className={'capture-coordinates-pdf-body'}
    //             stream={this.state.base64File}
    //             fillablepdfCallback={data => this.fillablepdfCallback(data)}
    //             updateViewerMediaAndType={data =>
    //               this.updateViewerMediaAndType(data)
    //             }
    //           />
    //         </div>
    //       </div>
    //     );
    //   }
    // }

    return (
      <DialogSpinnerWithText
        open={this.state.showSpinner}
        label={'Loading...'}
      />
    );
  }
}

const getSolutionTermsSigningUrl = gql`
  query(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $solutionTerms: String
    $signatureCoordinates: String
    $redirectUrl: String
    $fileName: String
  ) {
    solutionTermsSigningUrl(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      solutionTerms: $solutionTerms
      signatureCoordinates: $signatureCoordinates
      redirectUrl: $redirectUrl
      fileName: $fileName
    ) {
      signingTransactionId
      links {
        href
        rel
        mediaType
        method
      }
    }
  }
`;

export default withAuth(CaptureCoordinates);
