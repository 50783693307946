import React, { useEffect, useState } from 'react';
import {
  SearchInput,
  INPUT_SIZES,
  Popup,
  ListWrapper,
  ListItem,
  ListItemText,
  ListItemPrimaryText
} from 'cdk-radial';
import StoreListingAPI from '../../../api/StoreListingAPI';
import './heroComponent-and-searchBox.scss';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';

const LandingPageSearchBox = props => {
  let {
    setSearchText,
    setEnterKeyPress,
    getSearchSolutions,
    auth,
    setSolutionClicked,
    selectedOptionsArray,
    getDiscoverApps,
    searchTextPresent,
    searchText
  } = props;
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (searchTextPresent) setValue(searchText);
  }, [searchTextPresent]);

  const handleChange = value => {
    setValue(value);
    if (value !== '') handleFetch(value, auth);
    if (value === '') {
      setResults([]);
      setSearchText('');
      setEnterKeyPress(false);
      setSolutionClicked(false);
      if (selectedOptionsArray.length !== 0) {
        getSearchSolutions('', auth);
      } else {
        getDiscoverApps(auth);
      }
      deleteSearchParam();
    }
  };

  const handleSearchItemClick = value => {
    setResults([]);
    setValue(value);
    setSearchText(value);
    getSearchSolutions(value, auth);
    setSolutionClicked(true);
    updateSearchParam(value);
  };

  const keyPressed = e => {
    if (e.key === 'Enter' && value !== "") {
      setEnterKeyPress(true);
      setSearchText(value);
      getSearchSolutions(value, auth);
      updateSearchParam(value);
    }
  };

  const updateSearchParam = value => {
    const url = new URL(window.location);
    url.searchParams.set('Sr', value);
    window.history.pushState({}, '', url);
    window.sessionStorage.setItem("searchKey",window.location.search);
  };

  const deleteSearchParam = () => {
    const url = new URL(window.location);
    let searchParams = window.location.search;
    if (searchParams.includes('Sr')) {
      url.searchParams.delete('Sr');
      window.history.pushState({}, '', url);
    }
    window.sessionStorage.setItem("searchKey",window.location.search);
  };

  const handleFetch = async (value, authObj) => {
    let queryResponse = {};
    if (authObj.isAuthenticated) {
      const client = getApolloClient();
      let responseObj = await client.query({
        query: gql`
          query(
            $size: Int
            $from: Int
            $sortBy: String
            $order: String
            $searchText: String
            $selectedCategories: [String]
            $selectedProviders: [String]
            $searchOnly: Boolean
          ) {
            searchStoreFrontSolution(
              filters: {
                size: $size
                from: $from
                sortBy: $sortBy
                order: $order
                searchText: $searchText
                selectedCategories: $selectedCategories
                selectedProviders: $selectedProviders
                searchOnly: $searchOnly
              }
            ) {
              page
              pageSize
              total
              data {
                overview {
                  name
                }
              }
            }
          }
        `,
        variables: {
          searchText: value,
          searchOnly: true,
          size: 10,
          order: 'desc',
          sortBy: 'lastUpdatedOn'
        }
      });
      queryResponse =
        responseObj &&
        responseObj.data &&
        responseObj.data.searchStoreFrontSolution
          ? responseObj.data.searchStoreFrontSolution
          : null;
    } else {
      let payload = {
        searchText: value,
        searchOnly: true,
        size: 10,
        order: 'desc',
        sortBy: 'lastUpdatedOn'
      };
      let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
        payload
      );
      queryResponse = responseObj.data ? responseObj.data : null;
    }

    let responseSolutionList = queryResponse && queryResponse.data;
    setResults(
      responseSolutionList &&
        responseSolutionList.map((solution, index) => solution.overview.name)
    );
  };

  return (
    <div className="search_box_div">
      <SearchInput
        clearButtonLabel="clearButtonLabel"
        id="search-input-expanded"
        isAlwaysExpanded
        name="search-input-expanded"
        onChange={handleChange}
        onFocus={() => {}}
        onBlur={() => {}}
        placeholder="Search"
        size={INPUT_SIZES.LARGE}
        value={value}
        style={{ width: '98%', marginLeft: '1%' }}
        onKeyPress={e => {
          keyPressed(e);
        }}
      />
      {results && results.length ? (
        <Popup
          dataTestId="searchBarPopup"
          isOpen={results && results.length >= 1 ? true : false}
          onOpen={() => {}}
          onClose={() => {
            setResults([]);
          }}
          wrappedComponentContent={<div />}
          style={{
            maxWidth: '95%',
            width: 'inherit',
            marginLeft: '2.4%',
            background: '#ffffff',
            marginTop: '2px'
          }}
        >
          <ListWrapper className="search_box_list_wrapper">
            {results.map(item => (
              <ListItem
                className="search_box_list_item"
                onClick={() => handleSearchItemClick(item)}
              >
                <ListItemText>
                  <ListItemPrimaryText>{item}</ListItemPrimaryText>
                </ListItemText>
              </ListItem>
            ))}
          </ListWrapper>
        </Popup>
      ) : null}
    </div>
  );
};

export default LandingPageSearchBox;
