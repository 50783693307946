import React from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import MySolutionsList from '../../../containers/MySolutionsListContainer';
import { Column, Grid, Row, Card } from 'cdk-radial';
import { FormattedMessage, injectIntl } from 'react-intl';
import FortellisTitle3 from '../../common/FortellisTitle3';
import SolutionStatus from '../../common/SolutionStatus';

class MyProducts extends React.Component {
  render() {
    const {
      auth: { userData },
      entity
    } = this.props;

    return (
      <div className="component-content in-developement-sol-loading">
        <Grid className="my-soln-page-content-grid max-width-resp page-padding-common">
          <Row id="rowMyAppsTitle">
            <Column span={12}>
              <h2 className="my-soln-page-title">My Apps</h2>
            </Column>
          </Row>
          <Row id="rowMyAppsTitleNote">
            <Column span={12}>
              <div className="my-soln-page-content-title-note">
                <FormattedMessage
                  id="MyProducts.description"
                  defaultMessage="The apps and other apps you developed to offer in the Fortellis Marketplace. You can review and edit information for apps in the list, or start the process for new solution by registering it."
                />
              </div>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <div className="soln-type-text-header">
                <FortellisTitle3
                  style="soln-type-text-header"
                  title={
                    <FormattedMessage
                      id="MyProducts.inDevelopmentSolutions.title"
                      defaultMessage="In Development"
                    />
                  }
                />
              </div>
            </Column>
          </Row>
          <Row>
            <Column>
              <div className="max-width-resp">
                <MySolutionsList
                  userData={userData}
                  entity={entity}
                  showSolutionType={SolutionStatus.DRAFT}
                />
              </div>
            </Column>
          </Row>
          <Row className="max-width-resp mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
            <Column span={12}>
              <div className="soln-type-text-header">
                <FortellisTitle3
                  style="soln-type-text-header"
                  title={
                    <FormattedMessage
                      id="MyProducts.inMarketplaceSolutions.title"
                      defaultMessage="In Marketplace"
                    />
                  }
                />
              </div>
              <div className="max-width-resp solution-type-list">
                <MySolutionsList
                  userData={userData}
                  entity={entity}
                  showSolutionType={SolutionStatus.PUBLISHED}
                />
              </div>
            </Column>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(withAuth(withEntityContext(MyProducts)));
