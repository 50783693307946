import React from 'react';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { PrimaryButton, SecondaryButton, BUTTON_SIZES } from 'cdk-radial';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import { sendAmplitudeData } from '../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../utils/amplitude-constants';
import '@cdk-uip/icons';
import './../heroComponent-and-searchBox/heroComponent-and-searchBox.scss';

const NewHero = props => {
  return (
    <div className="landing-page_hero">
      <div className="max-width-resp">
        <LayoutGrid className="landing-page_grid">
          <LayoutGridCell span={12}>
            <div className="landing-page_title_div">
              <div className="landing-page_hero_title1">
                {props.title1}
                <div className="landing-page_hero_title2">{props.title2}</div>
                <br />
                {props.title3}
              </div>
            </div>

            <div className="landing-page-hero_button_div">
              {!props.auth.isAuthenticated && (
                <a
                  href={environmentURLs.accountsURL}
                  onClick={() => {
                    sendAmplitudeData(AMPLITUDE_EVENTS.REGISTER_TODAY);
                  }}
                >
                  <PrimaryButton
                    text="Register Today"
                    dataTestId="register_today_button"
                  />
                </a>
              )}
            </div>
          </LayoutGridCell>
        </LayoutGrid>
      </div>
    </div>
  );
};

export default NewHero;
