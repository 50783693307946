import React from 'react';
import { Column, Grid, Row } from 'cdk-radial';

import './SolutionCardGroup.scss';
import SolutionSkeleton from './SolutionSkeleton';

const SkeletonCardGroup = props => {
  let { title, solutionArray } = props;
  return (
    <div>
      <div className="solution-card-group__title_div">{title}</div>
      <Grid>
        <Row style={{ paddingBottom: '15px', marginLeft: '15px' }}>
          {solutionArray &&
            Array.isArray(solutionArray) &&
            solutionArray.map(() => (
              <Column
                xl={4}
                l={4}
                m={12}
                s={12}
                xs={12}
                style={{ paddingBottom: '25px' }}
              >
                <SolutionSkeleton />
              </Column>
            ))}
        </Row>
      </Grid>
    </div>
  );
};

export default SkeletonCardGroup;
