import React from 'react';
import Review from './Review';
import ReviewResponse from './ReviewResponse';
import AddReviewResponse from './AddReviewResponse';

import { withApollo } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import ListingFilters from '../../marketplace-account/my-solutions/solution-management/panels/ListingFilters';
import { FortellisConstants } from '../../common/FortellisConstants';

class Reviews extends React.Component {
  state = {
    selectedSolutionVersion: FortellisConstants.SHOW_ALL,
    selectedListingVersion: FortellisConstants.SHOW_ALL,
    solutionVersionToListingVersionMap: {},
    reviewsForSolution: this.props.reviewsForSolution
  };

  componentWillReceiveProps(np) {
    if (!np.reviewsForSolution || !np.reviewsForSolution.length) {
      return;
    }
    this.populateFilterOptions(np.reviewsForSolution);
    this.setState({ reviewsForSolution: np.reviewsForSolution });
  }

  populateFilterOptions(reviews) {
    reviews = reviews || [];
    let solutionVersionToListingVersionMap = {
      All: [FortellisConstants.SHOW_ALL]
    };
    reviews.map(review => {
      if (review.solutionVersion !== null) {
        solutionVersionToListingVersionMap[
          review.solutionVersion
        ] = solutionVersionToListingVersionMap[review.solutionVersion] || [
          FortellisConstants.SHOW_ALL
        ];
        solutionVersionToListingVersionMap[review.solutionVersion].push(
          review.listingVersion ? review.listingVersion : '1'
        );
      }
      return solutionVersionToListingVersionMap;
    });
    solutionVersionToListingVersionMap[
      FortellisConstants.SHOW_ALL
    ] = Object.values(solutionVersionToListingVersionMap).reduce(
      (listingVersionValuesA, listingVersionValuesB) =>
        Array.from(
          new Set([...listingVersionValuesA, ...listingVersionValuesB])
        )
    );
    this.setState({
      solutionVersionToListingVersionMap: solutionVersionToListingVersionMap
    });
  }

  onFilterChange(solutionVersion, listingVersion) {
    this.setState({
      selectedSolutionVersion: solutionVersion,
      selectedListingVersion: listingVersion
    });
    let filteredReviews = [];
    let reviewsForSolution = this.props.reviewsForSolution || [];
    reviewsForSolution.map(review => {
      if (
        solutionVersion &&
        solutionVersion !== FortellisConstants.SHOW_ALL &&
        solutionVersion !== review.solutionVersion
      ) {
        return null;
      }
      if (
        listingVersion &&
        listingVersion !== FortellisConstants.SHOW_ALL &&
        listingVersion !== review.listingVersion
      ) {
        return null;
      }
      if (review.status.toUpperCase() != 'APPROVED') {
        return null;
      }
      filteredReviews.push(review);
      return review;
    });
    this.setState({ reviewsForSolution: filteredReviews });
  }

  render() {
    const {
      userData,
      userPermissions,
      orgId,
      deleteReview,
      deleteReviewFromParent,
      onReviewResponseSubmitted,
      showFilters,
      isPreview,
      entity
    } = this.props;
    return (
      <div>
        {!showFilters ||
        (this.props.reviewsForSolution &&
          !this.props.reviewsForSolution.length) ? null : (
          <div>
            <div className="review-filter-container">
              <ListingFilters
                optionMapping={this.state.solutionVersionToListingVersionMap}
                selectedSolutionVersion={this.state.selectedSolutionVersion}
                selectedListingVersion={this.state.selectedListingVersion}
                onFilterChange={this.onFilterChange.bind(this)}
                className="review-filters"
              />
            </div>
            <hr />
          </div>
        )}
        <div>
          {!!this.state.reviewsForSolution &&
            this.state.reviewsForSolution
              .filter(f => f.status === 'Approved')
              .map((review, index) => (
                <div key={index}>
                  <Review
                    review={review}
                    userData={userData}
                    deleteReview={deleteReview}
                    deleteReviewFromParent={deleteReviewFromParent}
                    charLimit={350}
                    isPreview={isPreview}
                  />

                  {review.children && review.children.status === 'Approved' ? (
                    <ReviewResponse
                      reviewResponse={review.children}
                      deleteReview={deleteReview}
                      deleteReviewFromParent={deleteReviewFromParent}
                    />
                  ) : (
                    <div>
                      {userPermissions &&
                      userPermissions.includes('RespondToReview') &&
                      entity &&
                      entity.id === orgId ? (
                        review.children &&
                        review.children.status === 'Submitted' ? (
                          <div className="submitted-msg">
                            <FormattedMessage
                              id="Reviews.submittedMsg"
                              defaultMessage="Submitted response is under moderation and will be published after approval"
                            />
                          </div>
                        ) : (
                          <AddReviewResponse
                            userPermissions={userPermissions}
                            parentId={review.id}
                            authorName={userData.name}
                            authorEmail={userData.email}
                            solutionId={review.solutionId}
                            reviewId={review.id}
                            onReviewResponseSubmitted={
                              onReviewResponseSubmitted
                            }
                            isPreview={isPreview}
                          />
                        )
                      ) : (
                        false
                      )}
                    </div>
                  )}

                  <br />
                </div>
              ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(withApollo(Reviews));
