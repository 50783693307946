export const config = {
  formId: "highlights",
  formTitle: "Highlights",
  formValueType: "Object",
  tabs: [
    {
      id: "highlights",
      label: "Highlights",
      contents: [
        {
          id: "highlights",
          fields: [
            {
              type: "multiple_add_form",
              name: "highlight_list",
              multiFormGridStyle: "multiple-add-form-div",
              showAddLink: false,
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: "description",
                        label: "Highlight",
                        type: "static",
                        style: ["formtextfield", "form-text-area"],
                        gridStyle: "layoutcell"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    highlights: {
      highlight_list: []
    }
  },
  activeTabId: "highlights"
};
