import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import { Menu, MenuAnchor, MenuItem } from '@cdk-uip/react-menu';
import { Button } from '@cdk-uip/react-button';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { IconButton } from '@cdk-uip/react-icon-button';
import DeniedActivationDialog from './DeniedActivationDialog';
import Spinner from '../../../../../common/Spinner';
import ActionSnackBar from '../../../../../solution-submission/ActionSnackBar';
import gql from 'graphql-tag';
import './AppActivationSubscriptions.scss';
import { getApolloClient } from '../../../../../../containers/GraphQLClient';
import AppSubscriptionsConstants from '../../../../../common/AppSubscriptions';
const ACTIVE = 'Active';
const NO_SUBSCRIBERS = 'This application has no subscribers.';
class AppActivationSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingSubscriptionsMapping: [],
      menuIndex: -1,
      deniedMessages: {},
      deniedActivationDialog: false,
      selectedDeniedSubscriptionId: '',
      saving: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      const pendingSubscriptionsMapping = [];
      this.props.appSubscribers.forEach(subscription => {
        if (
          subscription.appSubscriptionActivationType === 'Manual' &&
          subscription.appSubscriptionActivationState === 'Pending'
        ) {
          pendingSubscriptionsMapping.push({
            id: subscription.id,
            selectedMenuItem: -1,
            menuIndex: -1,
            subscriptionData: subscription
          });
        }
      });
      this.setState({
        pendingSubscriptionsMapping: pendingSubscriptionsMapping
      });
    }
  }

  getSnackBarMessage = (approvedRecords, deniedRecords) => {
    const { solution } = this.props;
    let message = '';
    const appName =
      (solution && solution.overview && solution.overview.name) || '';
    const approvedSubsriberText =
      approvedRecords.length > 1 ? 'subscribers were' : 'subscriber was';
    const deniedSubscriberText =
      deniedRecords.length > 1 ? 'subscribers were' : 'subscriber was';

    if (approvedRecords.length && deniedRecords.length) {
      message = `${
        approvedRecords.length
      } ${approvedSubsriberText} approved and ${
        deniedRecords.length
      } ${deniedSubscriberText} denied for ${appName}`;
    } else if (approvedRecords.length) {
      message = `${
        approvedRecords.length
      } ${approvedSubsriberText} approved for ${appName}`;
    } else if (deniedRecords.length) {
      message = `${
        deniedRecords.length
      } ${deniedSubscriberText} denied for ${appName}`;
    }
    return message;
  };

  handleSave = async () => {
    let approved = [];
    let denied = [];
    const { solution } = this.props;
    const client = getApolloClient();
    this.state.pendingSubscriptionsMapping.forEach(subscription => {
      if (subscription.selectedMenuItem === 0) {
        approved.push({
          id: subscription.id,
          subscriptionEntityId: subscription.subscriptionData.entityId,
          solutionId: solution.id,
          entityId: solution.orgId
        });
      } else if (subscription.selectedMenuItem === 1) {
        denied.push({
          id: subscription.id,
          reasonForRejection: this.state.deniedMessages[subscription.id],
          subscriptionEntityId: subscription.subscriptionData.entityId,
          solutionId: solution.id,
          entityId: solution.orgId
        });
      }
    });
    if (approved.length || denied.length) {
      this.setState({ saving: true });
      await client.mutate({
        mutation: updateMetadataSubscriptions,
        variables: {
          denied: denied,
          approved: approved
        }
      });
      this.setState({
        showSnackBar: true,
        snackBarMessage: this.getSnackBarMessage(approved, denied)
      });
      this.sendEmails(this.state.pendingSubscriptionsMapping);
      await this.props.fetchSolutionSubscriptions();
      this.props.refreshPendingAppActivations();
      this.setState({ saving: false });
    }
  };

  handleCancel = () => {
    this.setState({ deniedActivationDialog: false });
  };

  handleSend = (id, rejectionMessage) => {
    if (rejectionMessage !== '') {
      const deniedMessages = this.state.deniedMessages;
      deniedMessages[id] = rejectionMessage;
      const pendingSubscription = this.state.pendingSubscriptionsMapping.find(
        subscription => subscription.id === id
      );
      pendingSubscription.selectedMenuItem = 1;
      pendingSubscription.reasonForRejection = rejectionMessage;
      const pendingSubscriptionsMapping = this.state.pendingSubscriptionsMapping.filter(
        subscription => subscription.id !== id
      );
      pendingSubscriptionsMapping.push(pendingSubscription);
      this.setState({
        deniedActivationDialog: true,
        open: false,
        selectedDeniedSubscriptionId: id,
        pendingSubscriptionsMapping: pendingSubscriptionsMapping,
        deniedMessages: deniedMessages,
        deniedActivationDialog: false
      });
    }
  };

  handleSelection = (selected, id, index) => {
    const pendingSubscription = this.state.pendingSubscriptionsMapping.find(
      subscription => subscription.id === id
    );
    if (selected !== 1) {
      this.setState({ selected, open: false });
      pendingSubscription.selectedMenuItem = selected;
      pendingSubscription.menuIndex = index;
      delete pendingSubscription.reasonForRejection;
      const deniedMessages = this.state.deniedMessages;
      delete deniedMessages[id];
      const pendingSubscriptionsMapping = this.state.pendingSubscriptionsMapping.filter(
        subscription => subscription.id !== id
      );
      pendingSubscriptionsMapping.push(pendingSubscription);
      this.setState({
        pendingSubscriptionsMapping: pendingSubscriptionsMapping,
        deniedMessages: deniedMessages
      });
    } else if (selected === 1) {
      pendingSubscription.menuIndex = index;
      const pendingSubscriptionsMapping = this.state.pendingSubscriptionsMapping.filter(
        subscription => subscription.id !== id
      );
      pendingSubscriptionsMapping.push(pendingSubscription);
      this.setState({
        deniedActivationDialog: true,
        open: false,
        selectedDeniedSubscriptionId: id,
        pendingSubscriptionsMapping: pendingSubscriptionsMapping
      });
    }
  };

  sendEmails = pendingSubscriptionsMapping => {
    const client = getApolloClient();
    const { solution, userData } = this.props;

    // separating out approved & denied records.
    const approvedSubscriptionRecords = pendingSubscriptionsMapping.filter(
      subscriptionRecord => subscriptionRecord.selectedMenuItem === 0
    );
    const deniedSubscriptionRecords = pendingSubscriptionsMapping.filter(
      subscriptionRecord => subscriptionRecord.selectedMenuItem === 1
    );

    const approvedEmailPromises = approvedSubscriptionRecords.map(
      subscriptionRecord => {
        const emailTo =
          (subscriptionRecord.subscriptionData &&
            subscriptionRecord.subscriptionData.email) ||
          '';
        const emailType = 'appSubscriptionActivationApprovedEmail';
        const activateOnly =
          solution && solution.activateOnly === false ? false : true;
        const subject = activateOnly
          ? 'Your Activation is Approved'
          : 'Your Subscription is Approved';

        let planName = '';
        const planId =
          subscriptionRecord.subscriptionData &&
          subscriptionRecord.subscriptionData.selectedPlanId;
        if (solution && solution.plans && solution && solution.plans.length) {
          let filterdPlans = solution.plans.filter(plan => plan.id === planId);
          if (filterdPlans.length) {
            planName = filterdPlans[0].planName;
          }
        }

        const appPublisherName = (userData && userData.name) || '';
        const aapPublisherEmail = (userData && userData.email) || '';
        const appName =
          (solution && solution.overview && solution.overview.name) || '';

        const template = {
          emailType,
          subscriptionDetails: {
            buyerName:
              (subscriptionRecord.subscriptionData &&
                subscriptionRecord.subscriptionData.firstName) ||
              '',
            buyerLastName:
              (subscriptionRecord.subscriptionData &&
                subscriptionRecord.subscriptionData.lastName) ||
              '',
            purchaseDate:
              (subscriptionRecord.subscriptionData &&
                subscriptionRecord.subscriptionData.createdOn) ||
              '',
            dealerLocation:
              (subscriptionRecord.subscriptionData &&
                subscriptionRecord.subscriptionData.name) ||
              '',
            planName: planName,
            activateOnly: activateOnly
          },
          appDetails: {
            appName: appName
          },
          publisher: appPublisherName,
          publisherEmail: aapPublisherEmail
        };

        let emailsForSubscriptionNotificationsFormatted = [];
        let emailsForSubscriptionNotifications = [];

        if (
          subscriptionRecord.subscriptionData &&
          subscriptionRecord.subscriptionData.emailsForSubscriptionNotifications
        ) {
          emailsForSubscriptionNotifications =
            subscriptionRecord.subscriptionData
              .emailsForSubscriptionNotifications;
        }

        if (
          Array.isArray(emailsForSubscriptionNotifications) &&
          emailsForSubscriptionNotifications.length > 0
        ) {
          emailsForSubscriptionNotificationsFormatted = emailsForSubscriptionNotifications.map(
            emailId => ({ email: emailId })
          );
        }

        return client.mutate({
          mutation: sendEmailCSMG,
          variables: {
            to: [{ email: emailTo }],
            cc: emailsForSubscriptionNotificationsFormatted,
            subject,
            withTemplate: template
          }
        });
      }
    );

    const deniedEmailPromises = deniedSubscriptionRecords.map(
      subscriptionRecord => {
        const emailTo =
          (subscriptionRecord.subscriptionData &&
            subscriptionRecord.subscriptionData.email) ||
          '';
        const emailType = 'appSubscriptionActivationDeniedEmail';
        const activateOnly =
          solution && solution.activateOnly === false ? false : true;
        const subject = activateOnly
          ? 'Your Activation is Denied'
          : 'Your Subscription is Denied';

        const appPublisherName = (userData && userData.name) || '';
        const aapPublisherEmail = (userData && userData.email) || '';
        const appName =
          (solution && solution.overview && solution.overview.name) || '';

        const template = {
          emailType,
          subscriptionDetails: {
            buyerName:
              (subscriptionRecord.subscriptionData &&
                subscriptionRecord.subscriptionData.firstName) ||
              '',
            dealerLocation:
              (subscriptionRecord.subscriptionData &&
                subscriptionRecord.subscriptionData.name) ||
              '',
            reasonForDenial:
              this.state.deniedMessages[subscriptionRecord.id] || '',
            activateOnly
          },
          appDetails: {
            appName: appName
          },
          publisher: appPublisherName,
          publisherEmail: aapPublisherEmail
        };

        let emailsForSubscriptionNotificationsFormatted = [];
        let emailsForSubscriptionNotifications = [];

        if (
          subscriptionRecord.subscriptionData &&
          subscriptionRecord.subscriptionData.emailsForSubscriptionNotifications
        ) {
          emailsForSubscriptionNotifications =
            subscriptionRecord.subscriptionData
              .emailsForSubscriptionNotifications;
        }

        if (
          Array.isArray(emailsForSubscriptionNotifications) &&
          emailsForSubscriptionNotifications.length > 0
        ) {
          emailsForSubscriptionNotificationsFormatted = emailsForSubscriptionNotifications.map(
            emailId => ({ email: emailId })
          );
        }

        return client.mutate({
          mutation: sendEmailCSMG,
          variables: {
            to: [{ email: emailTo }],
            cc: emailsForSubscriptionNotificationsFormatted,
            subject,
            withTemplate: template
          }
        });
      }
    );

    const allEmails = [...approvedEmailPromises, ...deniedEmailPromises];
    Promise.all(allEmails);
  };

  render() {
    const { appSubscribers, loading, solution, publishedSolution } = this.props;
    const isAppAuto =
      publishedSolution &&
      publishedSolution.app_subscriptions &&
      publishedSolution.app_subscriptions.appSubscriptionsType &&
      publishedSolution.app_subscriptions.appSubscriptionsType ===
        AppSubscriptionsConstants.AUTOMATIC;

    return (
      <div className="app-activation-subscription-div">
        <div className="app-activation-subscriptions-header">
          <FormattedMessage
            id="AppActivation.applicationSubscribers"
            defaultMessage={`Application Subscribers`}
          />
        </div>
        <hr className="app-activation-ruler" />
        <DeniedActivationDialog
          open={this.state.deniedActivationDialog}
          handleCancel={this.handleCancel}
          selectedDeniedSubscriptionId={this.state.selectedDeniedSubscriptionId}
          handleSend={this.handleSend}
        />
        {!loading && !this.state.saving ? (
          appSubscribers && appSubscribers.length ? (
            <div className="app-activation-subscriptions">
              <DataTable className="table-list">
                <DataTableHeader>
                  <DataTableRow>
                    <DataTableHeaderCell>
                      <div className="app-activation-subscriber-title">
                        <FormattedMessage
                          id="AppActivation.subscriber"
                          defaultMessage="Subscriber"
                        />
                      </div>
                    </DataTableHeaderCell>
                    <DataTableHeaderCell>
                      <div className="app-activation-date-title">
                        <FormattedMessage
                          id="AppActivation.dateSubscribed"
                          defaultMessage="Date Subscribed"
                        />
                      </div>
                    </DataTableHeaderCell>
                    <DataTableHeaderCell>
                      <FormattedMessage
                        id="AppActivation.status"
                        defaultMessage="Status"
                      />
                    </DataTableHeaderCell>
                  </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                  {appSubscribers &&
                    appSubscribers.map((subscription, index) => {
                      let id = subscription.id;
                      const pendingSubscription =
                        this.state.pendingSubscriptionsMapping &&
                        this.state.pendingSubscriptionsMapping.find(
                          subscription => subscription.id === id
                        );
                      return (
                        <DataTableRow key={index}>
                          <DataTableCell>
                            <div className="app-activation-subscriber-text">
                              {subscription.name}
                            </div>
                          </DataTableCell>
                          <DataTableCell>
                            <div className="app-activation-date-text">
                              {subscription.createdOn}
                            </div>
                          </DataTableCell>
                          <DataTableCell>
                            <div className="app-activation-status-div">
                              {subscription.appSubscriptionActivationState ===
                              'Denied' ? (
                                <>
                                  <i
                                    className="material-icons info-icon"
                                    style={{
                                      fontSize: '16px',
                                      marginTop: '4px'
                                    }}
                                    id={`tooltip${index}`}
                                  >
                                    info
                                  </i>
                                  <Tooltip
                                    htmlFor={`tooltip${index}`}
                                    style={{ marginBottom: '22px' }}
                                  >
                                    {subscription.reasonForRejection}
                                  </Tooltip>
                                </>
                              ) : null}
                              <div className="app-activation-status-text">
                                {subscription.appSubscriptionActivationState !==
                                'Pending'
                                  ? subscription.appSubscriptionActivationState
                                    ? subscription.appSubscriptionActivationState
                                    : ACTIVE
                                  : null}
                                {subscription.appSubscriptionActivationState !==
                                'Pending' ? (
                                  subscription.appSubscriptionActivationState ===
                                  'Denied' ? (
                                    <span className="app-activation-denied-status">
                                      <i className="material-icons fm-status-indicator">
                                        lens
                                      </i>
                                    </span>
                                  ) : (
                                    <span className="app-activation-active-status">
                                      <i className="material-icons fm-status-indicator">
                                        lens
                                      </i>
                                    </span>
                                  )
                                ) : //PENDING CASE
                                isAppAuto ? (
                                  <div className="app-activation-status-text">
                                    {'Pending'}
                                    <span className="app-activation-pending-status">
                                      <i
                                        style={{ color: 'gold' }}
                                        className="material-icons fm-status-indicator"
                                      >
                                        lens
                                      </i>
                                    </span>
                                  </div>
                                ) : (
                                  <MenuAnchor>
                                    <Button
                                      style={{
                                        backgroundColor: '#fafafa',
                                        minWidth: '122px',
                                        boxShadow:
                                          'inset 0 -1px 0 0 rgb(0 0 0 / 42%)',
                                        color: isAppAuto
                                          ? '#c6c6c6'
                                          : 'rgba(0,0,0,0.87)'
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          open: true,
                                          menuIndex: index
                                        })
                                      }
                                      disabled={isAppAuto}
                                    >
                                      {pendingSubscription
                                        ? pendingSubscription.selectedMenuItem !==
                                          -1
                                          ? pendingSubscription.selectedMenuItem ===
                                            1
                                            ? 'DENIED'
                                            : 'APPROVE'
                                          : 'PENDING'
                                        : null}
                                      <IconButton>arrow_drop_down</IconButton>
                                    </Button>
                                    <Menu
                                      open={
                                        this.state.open &&
                                        index === this.state.menuIndex
                                      }
                                      onCancel={() =>
                                        this.setState({ open: false })
                                      }
                                      onSelected={selected =>
                                        this.handleSelection(
                                          selected,
                                          id,
                                          index
                                        )
                                      }
                                    >
                                      <MenuItem>Approve</MenuItem>
                                      <MenuItem>Deny</MenuItem>
                                    </Menu>
                                  </MenuAnchor>
                                )}
                              </div>
                            </div>
                          </DataTableCell>
                        </DataTableRow>
                      );
                    })}
                </DataTableBody>
              </DataTable>
              {publishedSolution &&
              publishedSolution.app_subscriptions &&
              publishedSolution.app_subscriptions.appSubscriptionsType ===
                'Manual' ? (
                <Button
                  className="app-activation-save-btn"
                  compact={'true'}
                  unelevated
                  onClick={this.handleSave}
                >
                  SAVE
                </Button>
              ) : null}
            </div>
          ) : (
            <div className="app-activation-no-subscriber">{NO_SUBSCRIBERS}</div>
          )
        ) : (
          <Spinner />
        )}

        <ActionSnackBar
          show={!!this.state.showSnackBar}
          message={this.state.snackBarMessage}
          close={() => {
            this.setState({ showSnackBar: false, snackBarMessage: '' });
          }}
        />
      </div>
    );
  }
}

const updateMetadataSubscriptions = gql`
  mutation(
    $denied: [approveSubscriptionscmd]
    $approved: [approveSubscriptionscmd]
  ) {
    updateMetadataSubscriptions(
      command: { denied: $denied, approved: $approved }
    )
  }
`;

const sendEmailCSMG = gql`
  mutation(
    $to: [csmsgEmailInput]
    $cc: [csmsgEmailInput]
    $subject: String!
    $withTemplate: withTemplateCmd
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        cc: $cc
        subject: $subject
        withTemplate: $withTemplate
      }
    ) {
      emailResponse
    }
  }
`;

export default AppActivationSubscriptions;
