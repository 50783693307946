import React from "react";
import { injectIntl } from "react-intl";
import SubscriptionPlanContentHeader from "../subscription-plan-content-header";
import SubscriptionPlanContactDetails from "./subscription-plan-content-contact-details";
import SubscribedPlanContentPricing from "./subscribed-plan-content-pricing";
import SubscribedPlanTerms from "./subscribed-plan-terms";

class SubscribedPlanContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.onShow = () => {
      this.setState({ open: true });
    };

    this.onClose = () => {
      this.setState({ open: false });
    };
  }
  render() {
    const { solution, plan } = this.props;
    return (
      <section className="plan-content-section">
        <div className="plan-content-feature-div">
          <SubscriptionPlanContentHeader solution={solution} />
          <SubscriptionPlanContactDetails
            solution={solution}
            plan={plan}
            onShow={this.onShow}
          />
        </div>
        <SubscribedPlanContentPricing plan={plan} />
        <SubscribedPlanTerms
          solution={solution}
          plan={plan}
          open={this.state.open}
          onClose={this.onClose}
        />
      </section>
    );
  }
}

export default injectIntl(SubscribedPlanContent);
