import React from 'react';
import { FormattedMessage } from 'react-intl';
import { environmentURLs } from './environment/CaptureEnvironment';
import FortellisDialog from './FortellisDialog';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-jsonly-layout-grid';
import { Button } from '@cdk-uip/react-button';

class EntityRegistrationConfirmation extends React.Component {
  state = {
    showEntityRegistrationPopup: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && nextProps.openDialog) {
      return { showEntityRegistrationPopup: true };
    }
    return null;
  }

  handlePopupCancel = () => {
    this.setState({ showEntityRegistrationPopup: false });
    this.props.onClose && this.props.onClose();
  };

  navigateToEntityRegistrationURL = () => {
    window.location = environmentURLs.orgManagement;
  };

  render() {
    const { isPopup } = this.props;
    return (
      <div>
        {isPopup === false ? (
          <div className="entity-reg-confirmation-page-container">
            <LayoutGrid className="max-width-resp entity-reg-confirmation-page-content-text">
              <LayoutGridCell span={12}>
                <div>
                  <FormattedMessage
                    id="SubscribeSolution.entityRegistration.title"
                    defaultMessage="You are not associated to an organization"
                  />
                </div>
                <div className="entity-registration-message">
                  <FormattedMessage
                    id="SubscribeSolution.entityRegistration.message"
                    defaultMessage="To register a app, subscribe to apps or view subscriptions, you must be associated with an organization. Please select an organization or register a new organization."
                  />
                </div>
                <Button
                  primary
                  id="entityRegistration"
                  onClick={() => this.navigateToEntityRegistrationURL()}
                  className="entity-registration-redirect-btn"
                >
                  <FormattedMessage
                    id="SubscribeSolution.entityRegistration.acceptButton"
                    defaultMessage="REGISTER"
                  />
                </Button>
              </LayoutGridCell>
            </LayoutGrid>
          </div>
        ) : (
          <FortellisDialog
            title={
              <FormattedMessage
                id="SubscribeSolution.entityRegistration.title"
                defaultMessage="You are not associated to an organization"
              />
            }
            message={
              <FormattedMessage
                id="SubscribeSolution.entityRegistration.message"
                defaultMessage="To register a app, subscribe to apps or view subscriptions, you must be associated with an organization. Please select an organization or register a new organization."
              />
            }
            acceptButtonName={
              <FormattedMessage
                id="SubscribeSolution.entityRegistration.acceptButton"
                defaultMessage="REGISTER"
              />
            }
            cancelButtonName={
              <FormattedMessage
                id="SubscribeSolution.entityRegistration.cancelButton"
                defaultMessage="NOT NOW"
              />
            }
            open={this.state.showEntityRegistrationPopup}
            onAccept={() => {
              this.setState({ showEntityRegistrationPopup: false });
              this.navigateToEntityRegistrationURL();
            }}
            onCancel={() => this.handlePopupCancel()}
          />
        )}
      </div>
    );
  }
}

export default EntityRegistrationConfirmation;
