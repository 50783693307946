import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PRODUCT } from '../../../components/common/constants/MonetizationConstants';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';

export const initialState = {
  bankApps: [],
  bankAppsLoading: false,
};

export const getAllBanksAppsForUser = createAsyncThunk(
  'getAllBanksAppsForUser',
  async ({ orgId, token }) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${environmentURLs.paymentServiceUrl}/organization/${orgId}/apps`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response?.data?.data && Array.isArray(response?.data?.data)) {
        const filterData = response?.data?.data.filter(
          (i) => i.product_type === PRODUCT.LISTING_FEE
        );
        return filterData;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const bankAppsSlice = createSlice({
  name: 'bankApps',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAllBanksAppsForUser.fulfilled]: (state, action) => {
      state.bankApps = action.payload;
      state.bankAppsLoading = false;
    },
    [getAllBanksAppsForUser.pending]: (state) => {
      state.bankApps = [];
      state.bankAppsLoading = true;
    },
    [getAllBanksAppsForUser.rejected]: (state) => {
      state.bankApps = [];
      state.bankAppsLoading = false;
    },
  },
});

export default bankAppsSlice.reducer;