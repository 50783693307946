import React from "react";

const FortellisBadge = ({ verticalAlign, title }) => {
  return (
    <div className="fortellis-badge" style={{ verticalAlign: verticalAlign }}>
      {title}
    </div>
  );
};

export default FortellisBadge;
