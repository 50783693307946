export const PermissionConstants = {
  BROWSE: 'Browse',
  SUBSCRIBE: 'Subscribe',
  CONTACT_PUBLISHER: 'ContactPublisher',
  ADD_REVIEW: 'AddReview',
  SUBMIT: 'Submit',
  PUBLISH: 'Publish',
  RECALL: 'Recall',
  WITHDRAW: 'Withdraw',
  RESPOND_TO_REVIEW: 'RespondToReview',
  CONFIGURE_SUBSCRIPTION: 'ConfigureSubscription',
  COMPLETE_PROVISIONING: 'CompleteProvisioning'
};

export default PermissionConstants;
