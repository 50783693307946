import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';
import axios from 'axios';

export const initialState = {
  apiDetails: {
    acceptedRatePlans: [],
    loadingAcceptedRatePlans: false
  }
};

export const fetchAcceptedRatePlans = createAsyncThunk(
  'accepteptedRatePlan',
  async ({ entityId, accessToken }, thunkAPI) => {
    let returnData = [];
    const localState = thunkAPI.getState();

    try {
      const response = await axios.get(
        `${
          environmentURLs.providerServiceURL
        }accepted-rate-plans?orgId=${entityId}`,
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : ''
          }
        }
      );
      returnData = response.data;
    } catch (error) {
      console.error('call to accepted-rate-plan failed');
    }

    return returnData;
  }
);

const appSubmissionSlice = createSlice({
  name: 'appSubmission',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchAcceptedRatePlans.fulfilled]: (state, action) => {
      state.apiDetails.acceptedRatePlans = action.payload;
      state.apiDetails.loadingAcceptedRatePlans = false;
    },
    [fetchAcceptedRatePlans.pending]: (state, action) => {
      state.apiDetails.loadingAcceptedRatePlans = true;
    },
    [fetchAcceptedRatePlans.rejected]: (state, action) => {
      state.apiDetails.acceptedRatePlans = [];
      state.apiDetails.loadingAcceptedRatePlans = false;
    }
  }
});

export default appSubmissionSlice.reducer;
