export const PaymentInfoConstants = {
  paymentOption: {
    STRIPE: 'stripe',
    CDK: 'cdk'
  },
  paymentMode: {
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    OFFLINE_AND_ONLINE: 'Offline,Online'
  },
  ONLINE_PAYMENT: 'Online Payment',
  REVENUE_SHARE: 'Revenue Share',
  Stripe: 'Stripe',
  Cdk: 'CDK',
  OFFLINE_PAYMENT: 'Offline Payment',
  ACHNEEDTOVERIFYMESSAGE: `Your app will be published once your account is verified. You can verify in 2-3 business days, after the micro deposits post to the account you provided.`,
  PAYMENTSUCCESSMESSAGE: `Success, your app is published`,
  GENERICERRORMESSAGEFORPAYMENTFAILURE: `Facing issues while processing this payment request so we will not able to publish this apps right now.`,
  SUBSFAILEDMESSAGE: `We are unable to start subscription on your current payment method
             However, you have the option to choose another payment method to publish this app`,
  ANOTHERPAYOPTION: `We are unable to start the subscription using the current payment method. Choose another payment method to publish this app.`,
  MULTIPLEATTEMPTIDENTIFIER: '3 times',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  DECLINED: 'declined',
  CARD: 'card',
  ACTIVE: 'active',
  BANKACH: 'bankACH',
  INCOMPLETE: 'incomplete',
  PAYMENTUPDATESUCCESSFUL:'Payment method updated successfully',
  PAYMENTUPDATESUCCESSTOAST:'Your new payment method has been successfully updated.',
  EDITPAYMENTMULTIATTEMPTFAILURETOAST:'You have tried to verify this account 3 times and failed. For security reasons this account is locked. To verify this account, contact Fortellis Support.',
  DELETEBANKTOAST: 'Your payment method has been successfully deleted.',
  BANK_VERIFICATION_FAILED: 'verification_failed'
};

export default PaymentInfoConstants;
