import React, { useState, useEffect } from 'react';
import '../UserConsentDialog.scss';
import Button from '@material/react-button';
import { Dialog, DialogBody, DialogHeader } from '@cdk-uip/react-dialog';
import { Icon } from '@cdk-uip/react-icon';
import ConsentTermsConfirmationDailog from './ConsentTermsConfirmationDialog';
import ConsentTermsRejectionDialog from './ConsentTermsRejectionDialog';
import Loading from '../Loading';

const LOADING_MSG = 'Loading';
const SAVING_MSG = 'Saving';
const OneClickConsentDialog = props => {
  const [isRaised, setIsRaised] = useState(false);
  const [openBuyerDetailsPopUp, setOpenBuyerDetailsPopUp] = useState(false);
  const [openRejectionPopUp, setOpenRejectionPopUp] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.consentLink) setLoading(false);
  }, [props.consentLink]);

  return (
    <div className={'consent-dialog'}>
      <Dialog
        open={true}
        onClose={() => props.onClose(false)}
        id="consentDialogId"
        className="dialog_transform"
      >
        <div className={'consent_dialog_title_div'}>
          <div onClick={() => props.onClose(false)}>
            <i className={'material-icons consent-dialog_back_btn'}>
              arrow_back
            </i>
          </div>
          <div className={'consent-dialog_title'}>Terms and Conditions</div>
        </div>
        <div className={'consent-pdf-container'}>
          {loading ? (
            <div className={'consent-dialog__spinner'}>
              <div>{LOADING_MSG}</div>
              <Loading loaderClass={'loading'} />
            </div>
          ) : props.saving ? (
            <div className={'consent-dialog__spinner'}>
              <div>{SAVING_MSG}</div>
              <Loading loaderClass={'loading'} />
            </div>
          ) : (
            props.consentLink && (
              <embed
                src={
                  props.consentLink.substring(0, 6) === 'https:'
                    ? props.consentLink
                    : `data:application/pdf;base64,${props.consentLink}`
                }
                style={
                  (openBuyerDetailsPopUp || openRejectionPopUp) &&
                  !!document.documentMode //document.documentMode returns some value only if the userAgent is IE browser otherwise it returns undefined..
                    ? { width: '100%', height: '100%', display: 'none' }
                    : { width: '100%', height: '100%' }
                }
              />
            )
          )}
        </div>
        <div className={'consent-dialog-footer__container'}>
          <div className={'consent-dialog_footer'}>
            <div className={'consent-dialog_footer_text'}>Do you accept?</div>
            <div>
              <Button
                className={'consent-dialog_button button-border'}
                onClick={() => {
                  setIsRaised(false);
                  if (props.isPublisherAgreement) {
                    props.onClose();
                    return;
                  }
                  setOpenRejectionPopUp(true);
                }}
                raised={!isRaised}
              >
                No
              </Button>
              <Button
                className={'consent-dialog_button button-border'}
                onClick={() => {
                  setIsRaised(true);
                  if (props.isPublisherAgreement) {
                    props.onSubmit();
                    return;
                  }
                  setOpenBuyerDetailsPopUp(true);
                }}
                raised={isRaised}
                disabled={!props.consentLink}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {openBuyerDetailsPopUp && (
        <ConsentTermsConfirmationDailog
          onClose={() => {
            setOpenBuyerDetailsPopUp(false);
          }}
          consentLink={props.consentLink}
          onSubmit={props.onSubmit}
        />
      )}
      {openRejectionPopUp && (
        <ConsentTermsRejectionDialog
          onClose={() => {
            setOpenRejectionPopUp(false);
          }}
          onSubmit={props.onSubmit}
        />
      )}
    </div>
  );
};

export default OneClickConsentDialog;
