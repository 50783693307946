import React, { Component } from "react";

const withCommand = ({ name = "mutate" } = {}) => WrappedComponent =>
  class extends Component {
    state = { loading: false, error: null, result: null };
    constructor() {
      super();
      this.handleCommand = this.handleCommand.bind(this);
    }
    handleCommand() {
      this.setState({
        loading: true,
        error: null,
        result: null
      });
      const command = this.props[name];
      return command(...arguments)
        .then(result => {
          this.setState({
            loading: false,
            error: null,
            result: result
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            error: err,
            result: null
          });
        });
    }

    render() {
      const props = {
        ...this.props,
        [name]: {
          execute: this.handleCommand,
          loading: this.state.loading,
          error: this.state.error,
          result: this.state.result
        }
      };
      return <WrappedComponent {...props} />;
    }
  };

export default withCommand;
