import React, { useEffect, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  Radio,
  Input,
  INPUT_SIZES,
  Button,
  BUTTON_VARIANTS,
  IconSearch,
  INPUT_ICON_POSITIONS,
  ListItem,
  ListItemPrimaryText,
  ListItemText,
  ListWrapper,
  Popup,
  Loader
} from 'cdk-radial';
import './styles/subscriptionsData.scss';
import {
  getSearchSuggestionsByApp,
  getSearchSuggestionsByOrg,
  getSubscriptionDataForAdminForSolutionId,
  getSubscriptionDataForAdminForEntityId,
  getSubscriptionDataForAdminForCmfNumber,
  getSubscriptionDataForAdminForSubscriptionId,
  getSubscriptionDataForAdminForDms,
  clearSubscriptionData,
  getConsentData
} from '../../../redux/connectedComponents/adminSubscriptionData/subscriptionData.slice';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionDetailsTable from './SubscriptionDetailsTable';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import { SEARCH_TYPE } from './constants';

const uuidEx = /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i;
const cNumberEx = /^C\d+/i;
const ipEx = /^((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4][0-9]|25[0-5])$/;

function SubscriptionData() {
  const state = useSelector(state => state.subscriptionData);
  const {
    searchResultsByApp,
    searchResultsByOrg,
    fetchingSearchResultsByApp,
    fetchingSearchResultsByOrg,
    noResultsFoundByApp,
    noResultsFoundByOrg,
    fetchingSubscriptionDetailsBySolutionId,
    fetchingSubscriptionDetailsByEntityId,
    fetchingSubscriptionDetailsByCmf,
    fetchingSubscriptionDetailsBySubscriptionId,
    fetchingSubscriptionDetailsByDms,
    subscriptionDetailsBySolutionId,
    subscriptionDetailsByEntityId,
    subscriptionDetailsByCmf,
    subscriptionDetailsBySubscriptionId,
    subscriptionDetailsByDms,
    consentData,
    fetchingConsentData,
    errorFetchingConsentData,
    consentDataSuccessfullyfetched
  } = state;

  const dispatch = useDispatch();

  const [searchOption, setSearchOption] = useState(SEARCH_TYPE.APP);
  const [searchInput, setSearchInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [tableData, setTableData] = useState([]);

  const searchInputIsValid = useMemo(() => {
    if (searchOption === SEARCH_TYPE.DMS) {
      return cNumberEx.test(searchInput) || ipEx.test(searchInput);
    }
    return searchInput;
  }, [searchOption, searchInput]);

  const searchInputIsId = uuidEx.test(searchInput);

  useEffect(() => {
    if (
      tableData?.length &&
      !consentData?.length &&
      !fetchingConsentData &&
      !consentDataSuccessfullyfetched &&
      !errorFetchingConsentData &&
      (searchOption === SEARCH_TYPE.ORG || searchOption === SEARCH_TYPE.DMS) &&
      !fetchingSubscriptionDetailsByEntityId &&
      !fetchingSubscriptionDetailsByDms
    ) {
      let solutionIds = [];
      let entities = [];
      tableData.forEach(subscription => {
        const { subscriptionData = {} } = subscription;
        solutionIds.push(subscriptionData.solutionId);
        entities.push(subscriptionData.entityId);
      });
      solutionIds = Array.from(new Set(solutionIds));
      entities = Array.from(new Set(entities));
      dispatch(getConsentData({ solutionIds, entities }));
    }
  }, [tableData, consentData, fetchingConsentData, searchOption]);

  useEffect(() => {
    if (searchOption === SEARCH_TYPE.APP) {
      setTableData(subscriptionDetailsBySolutionId);
    } else if (searchOption === SEARCH_TYPE.ORG) {
      setTableData(subscriptionDetailsByEntityId);
    } else if (searchOption === SEARCH_TYPE.SUB) {
      setTableData(subscriptionDetailsBySubscriptionId);
    } else if (searchOption === SEARCH_TYPE.CMF) {
      setTableData(subscriptionDetailsByCmf);

      // try to infer org name
      const subDetailSample = subscriptionDetailsByCmf.find(
        s => s.subscriptionData.entityName
      );
      if (subDetailSample) {
        setSearchName(prev => ({
          name: subDetailSample.subscriptionData.entityName,
          id: prev.id
        }));
      }
    } else if (searchOption === SEARCH_TYPE.DMS) {
      setTableData(subscriptionDetailsByDms);
    }
  }, [
    searchOption,
    subscriptionDetailsBySolutionId,
    subscriptionDetailsByEntityId,
    subscriptionDetailsByCmf,
    subscriptionDetailsBySubscriptionId,
    subscriptionDetailsByDms
  ]);

  useEffect(() => {
    setIsOpen(true);
  }, [searchResultsByApp, searchResultsByOrg]);

  const onRadioChange = event => {
    setSearchOption(event.target.value);
    setSearchInput('');
    setSearchType('');
    setSearchName({});
    dispatch(clearSubscriptionData());
  };

  const handleInputChange = event => {
    setSearchInput(event.target.value);
  };

  const onSearchButton = () => {
    if (searchOption === SEARCH_TYPE.APP) {
      dispatch(getSearchSuggestionsByApp({ appName: searchInput }));
    } else if (searchOption === SEARCH_TYPE.ORG) {
      dispatch(getSearchSuggestionsByOrg({ orgName: searchInput }));
    } else if (searchOption === SEARCH_TYPE.CMF) {
      setIsOpen(false);
      setSearchType(searchOption);
      setSearchName({ id: searchInput });
      dispatch(
        getSubscriptionDataForAdminForCmfNumber({
          cmfNumber: searchInput
        })
      );
    } else if (searchOption === SEARCH_TYPE.SUB) {
      setSearchType(searchOption);
      setSearchName({ id: searchInput, name: SEARCH_TYPE.SUB });
      dispatch(
        getSubscriptionDataForAdminForSubscriptionId({
          subscriptionId: searchInput
        })
      );
    } else if (searchOption === SEARCH_TYPE.DMS) {
      const input = searchInput.toUpperCase();
      let cNumber, ipAddress;

      setSearchInput(input);
      setSearchType(searchOption);
      setSearchName({ name: input });

      if (cNumberEx.test(input)) {
        cNumber = input;
      } else if (ipEx.test(input)) {
        ipAddress = input;
      }

      dispatch(getSubscriptionDataForAdminForDms({ cNumber, ipAddress }));
    }
  };

  const fetchsubscriptionDetails = (id, name) => {
    setIsOpen(false);
    setSearchInput(name);
    setSearchType(searchOption);
    setSearchName({ id, name });
    if (searchOption === SEARCH_TYPE.APP) {
      dispatch(getSubscriptionDataForAdminForSolutionId({ solutionId: id }));
    } else if (searchOption === SEARCH_TYPE.ORG) {
      dispatch(getSubscriptionDataForAdminForEntityId({ entityId: id }));
    }
  };

  const getSearchResults = (
    searchResultsByApp,
    searchResultsByOrg,
    fetchingSearchResultsByApp,
    fetchingSearchResultsByOrg,
    noResultsFoundByApp,
    noResultsFoundByOrg,
    fetchingSubscriptionDetails
  ) => {
    let searchPlaceholder;
    let searchResults = [];
    let noResultsFound = false;
    let fetchingSearchResults = false;
    let searchButtonText;

    if (searchOption === SEARCH_TYPE.APP) {
      searchPlaceholder = 'Search for an app';
      searchResults = searchResultsByApp;
      noResultsFound = noResultsFoundByApp;
      fetchingSearchResults = fetchingSearchResultsByApp;
      searchButtonText = 'Search';
    } else if (searchOption === SEARCH_TYPE.ORG) {
      searchPlaceholder = 'Search for an org by name, or enter an org ID';
      searchResults = searchResultsByOrg;
      noResultsFound = noResultsFoundByOrg;
      fetchingSearchResults = fetchingSearchResultsByOrg;
      searchButtonText = searchInputIsId ? 'Find By ID' : 'Search';
    } else if (searchOption === SEARCH_TYPE.CMF) {
      searchPlaceholder = 'Enter a CMF number';
      searchResults = [];
      noResultsFound = false;
      fetchingSearchResults = false;
      searchButtonText = 'Get Subscriptions';
    } else if (searchOption === SEARCH_TYPE.SUB) {
      searchPlaceholder = 'Enter a subscription ID';
      searchResults = [];
      noResultsFound = false;
      fetchingSearchResults = false;
      searchButtonText = 'Get Subscriptions';
    } else if (searchOption === SEARCH_TYPE.DMS) {
      searchPlaceholder =
        'Enter a DMS CNumber or IP address (e.g. C178306, 207.187.64.17)';
      searchResults = [];
      noResultsFound = false;
      fetchingSearchResults = false;
      searchButtonText = 'Get Subscriptions';
    }

    return (
      <form
        className="subscription-search-bar"
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onSearchButton();
        }}
      >
        <div>
          <Input
            id="subscription-data-search"
            onChange={handleInputChange}
            placeholder={searchPlaceholder}
            value={searchInput}
            hideLablse={true}
            size={INPUT_SIZES.SMALL}
            icon={<IconSearch />}
            iconPosition={INPUT_ICON_POSITIONS.LEADING}
            hasClearButton={true}
            onInputClear={() => setSearchInput('')}
            dataTestId="search-input"
          />

          {searchResults.length ? (
            <div className="search-results-container">
              <Popup
                style={{ background: '#fff', zIndex: '5' }}
                dataTestId="popup"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => {}}
                wrappedComponentContent={<div />}
              >
                <ListWrapper
                  style={{ width: '100%' }}
                  className={isOpen ? 'search-results-popup' : null}
                >
                  {searchResults.map(item => (
                    <ListItem
                      key={item.id}
                      onClick={() =>
                        fetchsubscriptionDetails(item.id, item.name)
                      }
                    >
                      <ListItemText>
                        <ListItemPrimaryText>{item.name}</ListItemPrimaryText>
                      </ListItemText>
                    </ListItem>
                  ))}
                </ListWrapper>
              </Popup>
            </div>
          ) : null}
          {!searchResults.length && noResultsFound && searchInput ? (
            <ListWrapper
              style={{ width: '100%' }}
              className={isOpen ? 'search-results-popup' : null}
            >
              <ListItem>
                <ListItemText>
                  <ListItemPrimaryText>No Results Found</ListItemPrimaryText>
                </ListItemText>
              </ListItem>
            </ListWrapper>
          ) : null}
        </div>
        <Button
          text={searchButtonText}
          variant={BUTTON_VARIANTS.PRIMARY}
          isDisabled={
            !searchInputIsValid ||
            fetchingSearchResults ||
            fetchingSubscriptionDetails
          }
          isLoading={fetchingSearchResults}
          type="submit"
        />
      </form>
    );
  };

  return (
    <div className="component-content">
      <div className="fm-page-content-grid max-width-resp page-padding-common">
        <a className="redirect-to-admin" href={environmentURLs.adminAccount}>
          <span className="redirect-icon">
            <i className="material-icons">keyboard_arrow_left</i>
          </span>
          <span className="redirect-to-admin-text">Back to Home</span>
        </a>
        <div className="subscriptions-data-page-title">
          <FormattedMessage
            id="AllSubscriptions.page.title"
            defaultMessage="Subscriptions"
          />
        </div>
        <div className="search-subscriptions">
          <div className="subscription-search-radio-buttons">
            <span>Search By:</span>
            <Radio
              id="subscription-search-option-app"
              name="subscription-search-option"
              checked={searchOption === SEARCH_TYPE.APP}
              label="App"
              onChange={onRadioChange}
              value={SEARCH_TYPE.APP}
            />
            <Radio
              id="subscription-search-option-org"
              name="subscription-search-option"
              checked={searchOption === SEARCH_TYPE.ORG}
              label="Organization"
              onChange={onRadioChange}
              value={SEARCH_TYPE.ORG}
            />
            <Radio
              id="subscription-search-option-sub"
              name="subscription-search-option"
              checked={searchOption === SEARCH_TYPE.SUB}
              label="Subscription"
              onChange={onRadioChange}
              value={SEARCH_TYPE.SUB}
            />
            <Radio
              id="subscription-search-option-cmf"
              name="subscription-search-option"
              checked={searchOption === SEARCH_TYPE.CMF}
              label="CMF"
              onChange={onRadioChange}
              value={SEARCH_TYPE.CMF}
            />
            <Radio
              id="subscription-search-option-dms"
              name="subscription-search-option"
              checked={searchOption === SEARCH_TYPE.DMS}
              label="DMS"
              onChange={onRadioChange}
              value={SEARCH_TYPE.DMS}
            />
          </div>
          {getSearchResults(
            searchResultsByApp,
            searchResultsByOrg,
            fetchingSearchResultsByApp,
            fetchingSearchResultsByOrg,
            noResultsFoundByApp,
            noResultsFoundByOrg,
            fetchingSubscriptionDetailsBySolutionId ||
              fetchingSubscriptionDetailsByEntityId ||
              fetchingSubscriptionDetailsByCmf ||
              fetchingSubscriptionDetailsBySubscriptionId ||
              fetchingSubscriptionDetailsByDms
          )}
        </div>
        <div>
          {searchType ? (
            fetchingSubscriptionDetailsBySolutionId ||
            fetchingSubscriptionDetailsByEntityId ||
            fetchingSubscriptionDetailsBySubscriptionId ||
            fetchingSubscriptionDetailsByCmf ||
            fetchingSubscriptionDetailsByDms ? (
              <div className="fetch-subscriptions-loader">
                <Loader
                  style={{ stroke: '#5e2368' }}
                  label="Fetching Subscriptions"
                />
              </div>
            ) : (
              <SubscriptionDetailsTable
                searchType={searchType}
                searchName={searchName}
                tableData={tableData}
                fetchingConsentData={fetchingConsentData}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withRouter(SubscriptionData);
