import SolutionVisibility from '../../common/SolutionVisibility';

export const config = {
  formId: 'solution_visibility',
  formTitle: 'Visibility',
  formValueType: 'Object',
  formNote: 'By selecting below option, you can control who can see your app.',
  tabs: [
    {
      id: 'solution_visibility_tab',
      contents: [
        {
          id: 'solution_visibility_section',
          fields: [
            {
              name: 'solutionVisibilityType',
              label: 'Public',
              type: 'radio',
              disabled: true,
              gridStyle: 'solution-access-type-container',
              markAsCheck: function(formValue, name) {
                return formValue[name] === SolutionVisibility.PUBLIC;
              },
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['solutionVisibilityType'] =
                    SolutionVisibility.PUBLIC;
                }
                return resetValue;
              }
            },
            {
              formtitle:
                'By selecting the above option, you want your app to be visible to all users.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            },
            {
              name: 'solutionVisibilityType',
              label: 'Private',
              type: 'radio',
              disabled: true,
              gridStyle: 'solution-access-type-container',
              markAsCheck: function(formValue, name) {
                return formValue[name] === SolutionVisibility.PRIVATE;
              },
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['solutionVisibilityType'] =
                    SolutionVisibility.PRIVATE;
                }
                return resetValue;
              }
            },
            {
              formtitle:
                'By selecting the above option, you want your app to be visible only to specific users.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            },
            {
              name: 'privateSolutionUsers',
              label:
                'Enter Email Address of people who can view/subscribe your app.',
              type: 'input_chipset',
              containerStyle: 'sub-input-chipset',
              disabled: true
            }
          ]
        }
      ]
    }
  ],

  formFields: {
    solution_visibility: {
      solutionVisibilityType: SolutionVisibility.PUBLIC,
      privateSolutionUsers: []
    }
  },
  activeTabId: 'solution_visibility_tab'
};
