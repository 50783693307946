import axios from 'axios/index';
import gql from 'graphql-tag';
import { Component } from 'react';
import { getApolloClient } from '../../../../containers/GraphQLClient';

export class UploadImageUtils extends Component {
  uploadImage = async (file, fileName) => {
    let imageUrl = '';
    return this.s3Sign({
      filename: fileName,
      fileType: file.type,
      operation: 'putObject'
    })
      .then(response => {
        const { signedRequest, url } = response.data.signS3;
        imageUrl = url.toString();
        return this.uploadToS3(file, signedRequest);
      })
      .then(() => {
        return imageUrl;
      })
      .catch(err => {
        return Promise.reject({ message: 'Image upload failed' });
      });
  };

  deleteSolutionLogoFromS3(fileName) {
    return this.s3Sign({
      filename: fileName,
      fileType: `image/jpeg`,
      operation: 'deleteObject'
    })
      .then(response => {
        const { signedRequest } = response.data.signS3;
        return this.deleteFromS3(signedRequest);
      })
      .then(resp => {
        return resp;
      })
      .catch(error => {
        return Promise.reject({ message: 'Image deletion failure' });
      });
  }

  deleteImage = async (fileName, solutionId, listingVersion) => {
    let options = listingVersion
      ? {
          id: solutionId,
          listingVersion: listingVersion
        }
      : { id: solutionId };
    if (listingVersion === null) {
      return this.deleteSolutionLogoFromS3(fileName);
    } else {
      await this.deleteSolutionLogoFromDB(options);
      return this.deleteSolutionLogoFromS3(fileName);
    }
  };

  uploadToS3 = (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type
      }
    };
    return Promise.resolve(axios.put(signedRequest, file, options));
  };

  deleteFromS3 = signedRequest => {
    const options = {};
    return Promise.resolve(axios.delete(signedRequest, options));
  };

  s3Sign = variables => {
    const client = getApolloClient();
    return client.mutate({
      mutation: s3SignMutation,
      variables: variables
    });
  };

  deleteSolutionLogoFromDB = async variables => {
    const client = getApolloClient();
    return await client
      .mutate({
        mutation: deleteSolutionLogoMutation,
        variables: variables
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return '';
      });
  };
}

const s3SignMutation = gql`
  mutation($filename: String!, $fileType: String!, $operation: String!) {
    signS3(filename: $filename, fileType: $fileType, operation: $operation) {
      url
      signedRequest
    }
  }
`;

const deleteSolutionLogoMutation = gql`
  mutation($id: ID!, $listingVersion: String) {
    deleteSolutionLogo(id: $id, listingVersion: $listingVersion) {
      id
      listingVersion
    }
  }
`;

export default UploadImageUtils;
