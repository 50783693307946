export const SolutionStatusColor = {
  Registered: '#8A8D92',
  Submitted: '#074FAF',
  In_Review: '#074FAF',
  Approved: '#09AD00',
  Published: '#09AD00',
  Rejected: '#CC0000',
  Recalled: '#8A8D92',
  Revoked: '#EB0000',
  Withdrawn: '#8A8D92',
  Not_Approved: '#EB0000',
  Draft: '#074FAF',
  Publish_Pending: '#FA9C19',
  Cancelled: '#8A8D92'
};

export default SolutionStatusColor;
