import React from 'react';
import { Button } from 'cdk-radial';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

const SellPageHero = props => {
  const {
    auth: { isAuthenticated }
  } = props;
  return (
    <div className="sell-in-mp-hero-bg sell-in-mp-hero-container">
      <div className="max-width-resp-sell-in-mp-hero">
        <div className="inline-block sell-hero-col-left">
          <div className="sell-hero-col-left-inner">
            <div className="sell-on-mp-title">
              Sell on <span className="sell-on-mp-title-bold">Marketplace</span>
            </div>
            <div>
              {isAuthenticated ? (
                <a href={environmentURLs.developerAppsRegisterSolution}>
                  <Button
                    dataTestId="create-an-app__hero-button"
                    className="create-an-app"
                    text="Create An App"
                  />
                </a>
              ) : (
                <a href={environmentURLs.accountsURL}>
                  <Button
                    dataTestId="create-an-account__hero-button"
                    className="create-an-app"
                    text="Create An Account"
                  />
                </a>
              )}

              <a
                href={`${
                  environmentURLs.docs
                }docs/general/overview/getting-started/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  dataTestId="learn-more__hero-button"
                  outlined
                  className="learn-more"
                  text="Learn More"
                  variant="secondary"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withAuth(SellPageHero);
