import React from "react";

export class FormPrePostText extends React.Component {
  getTextValue = (text, dependentOn) => {
    if (text instanceof Function) {
      let dependentValue = this.props.formFieldValues[dependentOn]
        ? this.props.formFieldValues[dependentOn]
        : this.props.formFieldValuesAll[dependentOn];
      let textVal = text(dependentValue);
      if (
        textVal &&
        textVal.lastIndexOf("{") > -1 &&
        textVal.lastIndexOf("}") > -1
      ) {
        let textVariable = textVal.substring(
          textVal.lastIndexOf("{") + 1,
          textVal.lastIndexOf("}")
        );
        let value = this.props.formFieldValues[textVariable]
          ? this.props.formFieldValues[textVariable]
          : this.props.formFieldValuesAll[textVariable];
        if (value && value !== "") {
          textVal = textVal
            .replace("{" + textVariable + "}", value)
            .toLowerCase();
          return textVal;
        }
      }
      return "";
    } else {
      return text;
    }
  };

  render() {
    return this.props.text ? (
      <div className={this.props.style}>
        {this.getTextValue(this.props.text, this.props.dependentOn)}
      </div>
    ) : (
      false
    );
  }
}

export default FormPrePostText;
