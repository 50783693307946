import React from 'react';
import { supportShape } from '../solution-submission/shapes';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormUrlValidation } from '../../containers/utils/FormUrlValidation';

class Support extends React.Component {
  static propTypes = {
    support: supportShape
  };

  state = {
    phoneNumber: this.props.support ? this.props.support.phoneNumber : false
  };

  formatPhonenumber(phoneNumber) {
    let phone = phoneNumber;
    if (phoneNumber && !phoneNumber.match(/\(/g)) {
      phone =
        '(' +
        phoneNumber.slice(0, 3) +
        ') ' +
        phoneNumber.slice(3, 6) +
        '-' +
        phoneNumber.slice(6);
    }
    return phone;
  }

  componentDidMount() {
    let phone = this.props.support ? this.props.support.phoneNumber : false;
    if (phone && !phone.match(/\(/g)) {
      phone =
        '(' +
        phone.slice(0, 3) +
        ') ' +
        phone.slice(3, 6) +
        '-' +
        phone.slice(6);
    }
    this.setState({ phoneNumber: phone });
  }

  render() {
    const { support } = this.props;
    return support ? (
      <div className="solution-details-support-section">
        {support.tutorials_and_documentation &&
          support.tutorials_and_documentation.length > 0 && (
            <div className="tutorial-docs-section">
              <div className="title">
                <FormattedMessage
                  id="Support.heading1"
                  defaultMessage="Tutorials and Documentation"
                />
              </div>
              {support.tutorials_and_documentation.map(
                (tutorial, tutorialIdx) => (
                  <div className="document-list-item" key={tutorialIdx}>
                    <a
                      href={FormUrlValidation.validateUrl(tutorial.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      {tutorial.title}&nbsp;
                      <i className="material-icons external-link-icon">
                        open_in_new
                      </i>
                    </a>
                    <div className="description">{tutorial.purpose}</div>
                  </div>
                )
              )}
            </div>
          )}

        {(support.supportCenterUrl ||
          support.emailAddress ||
          support.phoneNumber) && (
          <div className="solution-support-section">
            <div className="header">
              <FormattedMessage
                id="Support.heading2"
                defaultMessage="App Support"
              />
            </div>

            {support.supportMessage && (
              <div className="support-note">{support.supportMessage}</div>
            )}

            <table className="support-details">
              {support.supportCenterUrl && (
                <tr>
                  <td
                    data-cy="td_support-details_supportURL"
                    className="support-details-item"
                  >
                    <FormattedMessage
                      id="Support.table.supportUrl"
                      defaultMessage="Support URL"
                    />
                  </td>
                  <td>
                    <a
                      className="link"
                      href={FormUrlValidation.validateUrl(
                        support.supportCenterUrl
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-cy="td_value_sol_supportURL"
                    >
                      Visit App Support{' '}
                      <i className="material-icons external-link-icon">
                        open_in_new
                      </i>
                    </a>
                  </td>
                </tr>
              )}

              {support.phoneNumber && (
                <tr>
                  <td
                    data-cy="td_support-details_phone"
                    className="support-details-item"
                  >
                    <FormattedMessage
                      id="Support.table.phone"
                      defaultMessage="Phone"
                    />
                  </td>
                  <td>
                    <div className="phone">
                      {this.formatPhonenumber(support.phoneNumber)}
                    </div>
                  </td>
                </tr>
              )}

              {support.emailAddress && (
                <tr>
                  <td
                    data-cy="td_support-details_email"
                    className="support-details-item"
                  >
                    <FormattedMessage
                      id="Support.table.email"
                      defaultMessage="Email"
                    />
                  </td>
                  <td>
                    <a
                      data-cy="a_support-details_email"
                      href={'mailto:' + support.emailAddress}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      {support.emailAddress}
                    </a>
                  </td>
                </tr>
              )}
            </table>
          </div>
        )}

        {support.supportCommunityUrl && (
          <div className="support-community">
            <FormattedMessage
              id="Support.community.text1"
              defaultMessage="Take the advantage of"
            />{' '}
            <a
              data-cy="a_support-details_supportcommunityUrl"
              className="url"
              href={FormUrlValidation.validateUrl(support.supportCommunityUrl)}
              target="_blank"
              rel="noopener noreferrer"
            >
              support community{' '}
              <i className="material-icons external-link-icon">open_in_new</i>
            </a>{' '}
            <FormattedMessage
              id="Support.community.text2"
              defaultMessage="for additional help."
            />
          </div>
        )}
      </div>
    ) : (
      false
    );
  }
}

export default injectIntl(Support);
