import React from 'react';
import SolutionStatus from '../../../common/SolutionStatus';
import SolutionStatusColor from '../../../common/SolutionStatusColor';
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText
} from '@cdk-uip/react-jsonly-card';
import moment from 'moment';
import { TextArea } from '@cdk-uip/react-text-area';

export class SolutionStatusHistoryCard extends React.Component {
  render() {
    let commentText = this.props.commentText || '';

    return (
      <Card>
        <CardHeader>
          <CardTitle className="status_history_title">
            <span>
              {this.props.action && (
                <i
                  style={{
                    color:
                      SolutionStatusColor[this.props.action.replace(/ /g, '_')]
                  }}
                  className="material-icons status_history_oval"
                >
                  {'lens'}
                </i>
              )}
            </span>
            <span style={{ paddingLeft: '12px', verticalAlign: 'top' }}>
              {this.props.action}
            </span>
          </CardTitle>
          <CardSubtitle className="status_history_optional">
            {(this.props.action === SolutionStatus.SUBMITTED ||
              this.props.action === SolutionStatus.PUBLISHED ||
              this.props.action === SolutionStatus.REGISTERED) &&
            this.props.actor
              ? this.props.actor
              : 'Fortellis'}
            ,{' '}
            {!!this.props.commentDate
              ? moment(this.props.commentDate).format('DD MMM YYYY hh:mm A')
              : ''}
          </CardSubtitle>
        </CardHeader>
        <textarea
          disabled={true}
          style={{ height: commentText.split('\n').length > 3 ? '80px' : '' }}
          className={'status_history_card_text'}
          value={commentText}
        />
      </Card>
    );
  }
}

export default SolutionStatusHistoryCard;
