import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { TextField, TextFieldHelperText } from '@cdk-uip/react-text-field';
import ValidationUtils from '../utils/ValidationUtils';
import { getApolloClient } from '../../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import Spinner from '../../../common/Spinner';

const getSolutionList = gql`
  query($orgId: String) {
    solutionList(orgId: $orgId) {
      id
      listingVersion
      overview {
        name
      }
    }
  }
`;

export class FormTextField extends React.Component {
  state = {
    isDisabled: false,
    isValid: true,
    outOfFocus: false,
    isDuplicateSolution: false,
    isLoading: false
  };

  constructor(props) {
    super(props);
    this.validationUtils = new ValidationUtils();
  }

  componentDidMount() {
    const { formFieldValues = {}, fieldProperties = {} } = this.props;
    if (
      !formFieldValues[fieldProperties.name] ||
      formFieldValues[fieldProperties.name] === '' ||
      (typeof formFieldValues[fieldProperties.name] === 'string' &&
        formFieldValues[fieldProperties.name].trim() === '')
    ) {
      this.validate();
    }
  }

  handleInputChange = event => {
    const {
      formFieldValues = {},
      onChange = () => false,
      fieldProperties = {}
    } = this.props;

    formFieldValues[event.target.name] = event.target.value;
    this.setState({ isDuplicateSolution: false });
    let isValid = this.validate();
    onChange(formFieldValues);
    this.isValidSoulutionName(event);
    this.setState({
      isValid: isValid
    });
  };

  isValidSoulutionName = async event => {
    if (event.target.name === 'name') {
      let isValid = true;
      this.setState({ isLoading: true });
      let isSolutionExists = false;
      const {
        formFieldValues = {},
        entityId = '',
        formFieldValuesAll = {}
      } = this.props;
      formFieldValues[event.target.name] = event.target.value;
      const client = getApolloClient();
      let solutionListResponse = await client.query({
        query: getSolutionList,
        variables: {
          orgId: entityId
        }
      });
      if (solutionListResponse) {
        solutionListResponse.data.solutionList.forEach(item => {
          if (item.overview.name == formFieldValues.name) {
            isSolutionExists = true;
          }
        });
      }
      isValid = this.validate();
      if (isSolutionExists) {
        isValid = false;
      }
      this.setState({
        isDuplicateSolution: isSolutionExists,
        isValid: isValid,
        isLoading: false
      });
    }
  };

  validate() {
    const {
      fieldProperties = {},
      formValueCount = 0,
      formFieldValuesAll = {},
      formFieldValues = {}
    } = this.props;
    let isValid = this.validationUtils.validate(
      fieldProperties,
      formFieldValues,
      formValueCount,
      formFieldValuesAll,
      this.state.isDuplicateSolution
    );
    this.props.validate &&
      this.props.validate(
        isValid,
        this.props.fieldProperties.name,
        this.props.activeFormId,
        this.props.activeTabId,
        this.props.activeSubFormId
      );
    return isValid;
  }

  render() {
    const {
      fieldProperties = {},
      disabledOn,
      formFieldValues = {}
    } = this.props;
    const randomNumber = Math.random();
    return (
      <div className="form-field-with-tooltip">
        <div className="text-field-with-spinner">
          <TextField
            className={fieldProperties.style}
            name={fieldProperties.name}
            id={fieldProperties.name || 'textField_' + randomNumber}
            type={fieldProperties.fieldType}
            min={fieldProperties.min}
            label={
              <span
                className="form-field-label"
                data-cy={fieldProperties.datacy}
              >
                {fieldProperties.label}
                {fieldProperties.toolTipText && (
                  <span>
                    <i
                      id={`tooltip_${fieldProperties.name}${randomNumber}`}
                      className="material-icons help-label-icon"
                    >
                      help
                    </i>
                  </span>
                )}
              </span>
            }
            value={
              formFieldValues[fieldProperties.name]
                ? formFieldValues[fieldProperties.name]
                : ''
            }
            onChange={this.handleInputChange}
            onBlur={e => {
              this.handleInputChange(e);
              this.isValidSoulutionName(e);
              this.setState({ outOfFocus: true });
            }}
            onFocus={e => {
              this.handleInputChange(e);
              this.setState({ outOfFocus: false });
            }}
            onKeyPress={event => {
              const regex = new RegExp('^[0-9]+$');
              if (fieldProperties.fieldType === 'number') {
                let key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }
            }}
            helperText={
              this.state.isDuplicateSolution ? (
                <div className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg form-text-area-error">
                  App name must be unique.
                </div>
              ) : (
                fieldProperties.validationErrorMessage && (
                  <TextFieldHelperText persistent validation>
                    {!this.state.outOfFocus &&
                      !this.state.isValid &&
                      fieldProperties.validationErrorMessage}
                  </TextFieldHelperText>
                )
              )
            }
            invalid={this.state.outOfFocus && !this.state.isValid}
            disabled={
              typeof fieldProperties.disabled === 'function'
                ? fieldProperties.disabled(disabledOn)
                : fieldProperties.disabled
            }
            maxLength={fieldProperties.maxLength}
            required={fieldProperties.required}
          />
          <div>
            {this.state.isLoading ? (
              <span>
                <Spinner />
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        {fieldProperties.maxLength && (
          <div className="character-remaining-div">
            {this.props.value ? this.props.value.length : 0} /{' '}
            {fieldProperties.maxLength}
          </div>
        )}
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${fieldProperties.name}${randomNumber}`}
        >
          <span>{fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}

export default FormTextField;
