import React from 'react';
import { Column, Grid, Row } from 'cdk-radial';
import GalleryImages from './GalleryImages';
import SolutionDetails from './SolutionDetails';
import WhatsNew from './WhatsNew';
import GalleryVideos from './GalleryVideos';
import ExtensibilityApps from './ExtensibilityApp';
import { injectIntl } from 'react-intl';

class SolutionDescription extends React.Component {
  state = {
    allGalleryImages: []
  };

  componentDidMount() {
    const { overview } = this.props;
    this.updateGalleryImages(overview);
  }

  updateGalleryImages = overview => {
    let allGalleryImages = [];
    if (overview && overview.featuredImage) {
      allGalleryImages.push(overview.featuredImage);
    }
    if (
      overview &&
      overview.galleryImages &&
      overview.galleryImages.length > 0
    ) {
      let nonNullImagesWithPosition = overview.galleryImages.filter(
        galleryImage => {
          return (
            galleryImage.galleryImage &&
            galleryImage.imagePositionIndex &&
            galleryImage.imagePositionIndex > 0
          );
        }
      );
      let sortedNonNullImagesWithPosition = nonNullImagesWithPosition.sort(
        function(a, b) {
          return a.imagePositionIndex - b.imagePositionIndex;
        }
      );
      sortedNonNullImagesWithPosition.forEach(img =>
        allGalleryImages.push(img.galleryImage)
      );
      let nonNullImagesWithoutPosition = overview.galleryImages.filter(
        galleryImage =>
          galleryImage.galleryImage && !galleryImage.imagePositionIndex
      );
      nonNullImagesWithoutPosition.forEach(img =>
        allGalleryImages.push(img.galleryImage)
      );
    }
    this.setState({ allGalleryImages });
  };
  render() {
    const {
      overview,
      listingVersionUpdates,
      lastUpdatedOn,
      extensibilityType,
      isAuthenticated,
      solutionId,
      isPreview
    } = this.props;
    const { allGalleryImages } = this.state;
    return (
      <Grid>
        {overview &&
          (overview.briefDescription || overview.detailedDescription) && (
            <Row>
              <Column>
                <SolutionDetails overview={overview} />
              </Column>
            </Row>
          )}

        {listingVersionUpdates && (
          <Row>
            <Column>
              <WhatsNew
                overview={overview}
                listingVersionUpdates={listingVersionUpdates}
                lastUpdatedOn={lastUpdatedOn}
              />
            </Column>
          </Row>
        )}

        {overview && overview.videos && overview.videos.length > 0 && (
          <Row>
            <Column>
              <div
                data-cy="div_solution_description_title_videos"
                className="title-header"
              >
                Videos
              </div>
              <GalleryVideos videos={overview.videos} isPreview={isPreview} />
            </Column>
          </Row>
        )}

        {allGalleryImages.length > 0 && (
          <Row>
            <Column>
              <div
                data-cy="div_solution_description_title_gallery"
                className="title-header"
              >
                Gallery
              </div>
              <GalleryImages
                images={allGalleryImages.length ? allGalleryImages : []}
                isPreview={isPreview}
              />
            </Column>
          </Row>
        )}

        {extensibilityType && (
          <>
            <Row>
              <Column>
                <hr />
              </Column>
            </Row>
            <Row>
              <Column>
                <div
                  data-cy="div_solution_description_title_extensibilityapps"
                  className="title-header"
                >
                  Works with
                </div>
                <ExtensibilityApps
                  extensibilityType={extensibilityType}
                  isAuthenticated={isAuthenticated}
                  solutionId={solutionId}
                />
              </Column>
            </Row>
          </>
        )}
      </Grid>
    );
  }
}

export default injectIntl(SolutionDescription);
