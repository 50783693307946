import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { EntityProvider as FrcEntityProvider } from '@cdk-prod/fortellis-entity-context';
import { useOktaAuth } from '@okta/okta-react';

function getEnv() {
  let appHost = window.location.host.toLowerCase();
  if (appHost.indexOf('localhost') > -1) {
    return 'dev';
  }
  if (appHost.indexOf('marketplace-dev') > -1) {
    return 'dev';
  }
  return 'prod';
}

function EntityProvider({ auth, children }) {
  const { oktaAuth } = useOktaAuth();

  async function updateEntity(entityId) {
    await oktaAuth.signInWithRedirect({
      state: `${entityId !== 'personal-account' ? entityId : '*'}`
    });
  }

  return (
    <FrcEntityProvider
      accessToken={auth.accessToken || ''}
      authenticated={auth.isAuthenticated}
      //env={getEnv()}
      userId={(auth.userData && auth.userData.sub) || ''}
      //secure={false} // this is for local dev only
      updateEntity={updateEntity}
    >
      {children}
    </FrcEntityProvider>
  );
}

EntityProvider.propTypes = {
  auth: PropTypes.object.isRequired
};

export default withAuth(EntityProvider);
