import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';
import axios from 'axios';
export const initialState = {
  data: {
    verified: false,
    reloadPyamentSetting: false,
    paymentSettingData: [],
    otherPaymentOption: false,
    paymentDetails: {},
    loading: false,
    previousRoute: ''
  }
};
export const getPaymentSetting = createAsyncThunk(
  'getPaymentSetting',
  async ({ email, countryCode, token }) => {
    const endpoint = `${environmentURLs.marketplaceURL}graphql`;
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const graphqlQuery = {
      query: `
       query($emailId: String,$countryCode: String) {
       getPaymentSettingData(emailId: $emailId,countryCode:$countryCode) {
       customer_login_email
       payment_type
       customer_reference
       payment_type_reference
       status
       last4
       country
      }
    }`,
      fetchPolicy: 'no-cache',
      variables: { emailId: email, countryCode }
    };

    const response = await axios({
      url: endpoint,
      method: 'post',
      headers: headers,
      data: graphqlQuery
    });
    return response.data.data.getPaymentSettingData;
  }
);

const verifyAccountSlice = createSlice({
  name: 'verifyAccount',
  initialState: initialState,
  reducers: {
    verifyAccount: (state, action) => {
      state.data.verified = action.payload;
    },
    reloadPaymentSettingData: (state, action) => {
      state.data.reloadPyamentSetting = action.payload;
    },
    otherPaymentOptionRequest: (state, action) => {
      state.data.otherPaymentOption = action.payload;
    },
    resetOtherPaymentOption: (state, action) => {
      state.data.otherPaymentOption = action.payload;
    },
    setPaymentDetails: (state, action) => {
      state.data.paymentDetails = action.payload;
    },
    setPreviousRoute: (state, action) => {
      state.data.previousRoute = action.payload;
    }
  },
  extraReducers: {
    [getPaymentSetting.fulfilled]: (state, action) => {
      const results = action.payload;
      state.data.paymentSettingData = results;
      state.data.loading = false;
    },
    [getPaymentSetting.pending]: state => {
      state.data.paymentSettingData = [];
      state.data.loading = true;
    },
    [getPaymentSetting.rejected]: state => {
      state.data.paymentSettingData = [];
    }
  }
});

export const {
  verifyAccount,
  reloadPaymentSettingData,
  otherPaymentOptionRequest,
  resetOtherPaymentOption,
  setPaymentDetails,
  setPreviousRoute
} = verifyAccountSlice.actions;

export default verifyAccountSlice.reducer;
