import React from 'react';
import {
  Column,
  Grid,
  Row,
  MediaCard,
  ListItem,
  ListItemText
} from 'cdk-radial';
import PlanFeatures from './PlanFeatures';
import { injectIntl } from 'react-intl';
class PlansAndPricing extends React.Component {
  render() {
    const {
      solution,
      existingSubscriptionByEntity,
      isPreview,
      showSubscribeButton
    } = this.props;

    let plans = this.props.plans;
    let sortedPlans = [];
    plans.forEach(plan => {
      plan.isTheFreeTrial
        ? plan.isFreeTrialEnabled && sortedPlans.unshift(plan)
        : sortedPlans.push(plan);
    });
    return (
      <div>
        {sortedPlans.length > 0 && (
          <Grid>
            <Row>
              {sortedPlans.map((plan, planIdx) =>
                plan.isTheFreeTrial && !plan.isFreeTrialEnabled ? (
                  false
                ) : (
                  <Column>
                    <MediaCard className="pricing-media-card">
                      <ListItem
                        key={planIdx}
                        className="purchase-sol__plans-list-item"
                      >
                        <ListItemText>
                          <PlanFeatures
                            {...this.props}
                            isPreview={isPreview}
                            existingSubscriptionByEntity={
                              existingSubscriptionByEntity
                            }
                            plan={plan}
                            solution={solution}
                            showSubscribeButton={showSubscribeButton}
                          />
                        </ListItemText>
                      </ListItem>
                    </MediaCard>
                  </Column>
                )
              )}
            </Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default injectIntl(PlansAndPricing);
