import React from 'react';
import SubscribeSolution from './SubscribeSolution';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import FortellisConstants from '../common/FortellisConstants';
import { Button } from '@cdk-uip/react-button';
import {
  Card,
  ListWrapper,
  ListItem,
  ListItemGraphic,
  TextButton,
  IconArrowForward,
  IconButton,
  BUTTON_SIZES
} from 'cdk-radial';
import IconCheckCircleLogo from '../common/images/IconCheckCircle.jpg';
class PlanFeatures extends React.Component {
  render() {
    const {
      intl,
      plan,
      solution,
      existingSubscriptionByEntity,
      isPreview,
      entity,
      auth: { isAuthenticated },
      showSubscribeButton
    } = this.props;
    return (
      <div>
        <Card className="purchase-sol__card">
          <div className="editionFea__name">{plan.planName}</div>
          <div className="editionFea__Price">
            {plan.billingType === 'TRANSACTIONAL'
              ? plan.includedTransactionalFee
                ? plan.transactionalTypeOptions === '$'
                  ? `${plan.transactionalTypeOptions}${
                      plan.includedTransactionalFee
                    }${plan.includedTransactionalFee % 1 === 0 ? `.00` : ''}`
                  : `${plan.includedTransactionalFee}${
                      plan.transactionalTypeOptions
                    }`
                : null
              : plan.basePrice ||
                (plan.isTheFreeTrial && plan.isFreeTrialEnabled) ||
                plan.billingType === 'FREE'
              ? `$${plan.basePrice ? plan.basePrice : '0'}${
                  plan.basePrice % 1 === 0 ? `.00` : ''
                }`
              : null}
            {plan.isTheFreeTrial && plan.isFreeTrialEnabled ? (
              plan.freeTrialPeriod ? (
                <span className="editionFea__freeTrial">
                  <br /> Try {plan.freeTrialPeriod} {plan.freeTrialPeriodType}{' '}
                  Free
                </span>
              ) : null
            ) : plan.basePrice ||
              (plan.isTheFreeTrial && plan.isFreeTrialEnabled) ||
              plan.includedTransactionalFee ? (
              <span className="editionFea__frequency">
                {plan.billingType === 'TRANSACTIONAL' ? (
                  plan.includedTransactionalType ? (
                    `/${plan.includedTransactionalType}`
                  ) : null
                ) : plan.billingType.toLowerCase() === 'one time' ? (
                  <span>
                    <br /> One Time Fee{' '}
                  </span>
                ) : (
                  `/${plan.billingFrequency.toLowerCase()}`
                )}
                <br />{' '}
              </span>
            ) : null}
          </div>

          {plan.includedUnitQuantity &&
            plan.includedUnitOfMeasure &&
            plan.billingType !== 'TRANSACTIONAL' && (
              <div className="editionFea__details">
                <span className="edition-features__subheading">
                  <FormattedHTMLMessage
                    id="EditionFeatures.span"
                    defaultMessage="Includes up to "
                  />
                  {plan.includedUnitQuantity} {plan.includedUnitOfMeasure}
                </span>
                <br />
              </div>
            )}

          {!isPreview &&
            showSubscribeButton &&
            (!isAuthenticated || !existingSubscriptionByEntity ? (
              solution &&
              solution.solutionType !== FortellisConstants.PARTNER_PROGRAM && (
                <SubscribeSolution
                  {...this.props}
                  solution={solution}
                  plan={plan}
                  isPreview={isPreview}
                  isFreeTrial={plan.isTheFreeTrial}
                  styleClass="subscribe-button"
                  message="Subscribe"
                  solutionId={solution.id}
                  planId={plan.id}
                  solutionName={
                    solution.overview ? solution.overview.name : null
                  }
                />
              )
            ) : existingSubscriptionByEntity &&
              plan.id === existingSubscriptionByEntity.plan.id ? (
              <div className="subscription-action-disable-message">
                You have already subscribed to this plan.
              </div>
            ) : (
              <div className="subscription-action-disable-message">
                You have already subscribed to{' '}
                {existingSubscriptionByEntity.plan.planName} plan of this app.
              </div>
            ))}
          {plan.features && plan.features.length ? (
            <div>
              <span className="edition-feature_sub-heading">
                Included Features
              </span>
              <ListWrapper className="plans-and-pricing-feature-list">
                {plan.features &&
                  plan.features.map(feature => (
                    <ListItem
                      key={feature.id}
                      className="edition-feature__lists"
                    >
                      <ListItemGraphic>
                        <img
                          src={IconCheckCircleLogo}
                          alt={intl.formatMessage({
                            id: 'EditionFeatures.checkIcon.alt',
                            defaultMessage: 'check'
                          })}
                          style={{ width: '25px', height: '25px' }}
                        />
                      </ListItemGraphic>
                      {feature.title}
                    </ListItem>
                  ))}
              </ListWrapper>
              <ListItem className="view-feature-details">
                <TextButton
                  dataTestId="view-more"
                  onClick={() => {
                    this.props.tabSelect(0);
                  }}
                  text={'Features Details'}
                />
                <IconArrowForward
                  style={{ fill: '#6b2673', cursor: 'pointer' }}
                  onClick={() => {
                    this.props.tabSelect(0);
                  }}
                />
              </ListItem>
            </div>
          ) : null}
          {isAuthenticated && existingSubscriptionByEntity && (
            <Button
              primary
              raised
              outlined={true}
              className="manage-subscription-top"
              onClick={() => {
                this.props.history.push(`/marketplace-account/mysubscriptions`);
              }}
              disabled={isPreview}
            >
              <FormattedMessage
                id="ManageSubscription.button"
                defaultMessage="Manage Subscription"
              />
            </Button>
          )}
        </Card>
      </div>
    );
  }
}

export default injectIntl(withAuth(PlanFeatures));
