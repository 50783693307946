import React from "react";
import { Avatar } from "@cdk-uip/react-avatar";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";

class Hero extends React.Component {
  state = {
    searchText: ""
  };

  resetSearchText() {
    this.setState({ searchText: "" });
    this.props.onSearchTextChange("");
  }

  onSearchTextChange(event) {
    this.setState({ searchText: event.target.value });
  }

  callSearchImplementation() {
    if (this.state.searchText && this.state.searchText !== "") {
      this.props.onSearchTextChange(this.state.searchText);
    }
  }
  onEnterKeyPressInSearch = event => {
    let code = event.keyCode || event.which;
    if (code === 13 && this.state.searchText && this.state.searchText !== "") {
      //13 is the enter keycode
      this.props.onSearchTextChange(this.state.searchText);
    }
  };

  render() {
    return (
      <div className="hero">
        <div className="max-width-resp">
          <LayoutGrid className="hero-grid">
            <LayoutGridCell span={12}>
              <div className="hero-title">{this.props.title}</div>

              <div className="hero-subtitle">{this.props.subTitle}</div>

              <div className="search-box-div">
                <input
                  ref="herosearchinput"
                  type="text"
                  value={this.state.searchText}
                  className="hero-search-input"
                  placeholder={this.props.searchPlaceholder}
                  onChange={e => this.onSearchTextChange(e)}
                  onKeyPress={e => this.onEnterKeyPressInSearch(e)}
                />

                <Avatar
                  onClick={() => this.callSearchImplementation()}
                  className="search-icon-button"
                >
                  <i className="material-icons">search</i>
                </Avatar>

                {this.state.searchText ? (
                  <Avatar
                    onClick={() => this.resetSearchText()}
                    className="reset-icon-button"
                  >
                    <i className="material-icons" id="reset-search-text">
                      clear
                    </i>
                  </Avatar>
                ) : (
                  false
                )}
              </div>
            </LayoutGridCell>
          </LayoutGrid>
        </div>
      </div>
    );
  }
}

export default Hero;
