import React from "react";
import { injectIntl } from "react-intl";
import MySubscriptionStatus from "./my-subscription-status";

class MySubscriptionListItemContentStepHeader extends React.Component {
  render() {
    const { title, status } = this.props;
    return (
      <section className="expansion-header-section">
        <span className="panel-title">{title}</span>
        <span className="panel-description">
          {status && <MySubscriptionStatus status={status} />}
        </span>
      </section>
    );
  }
}

export default injectIntl(MySubscriptionListItemContentStepHeader);
