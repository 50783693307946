import React from 'react';
import { Snackbar } from '@cdk-uip/react-snackbar';

const ActionSnackBar = ({ show, message, close, dataQA, ...rest }) => (
  <Snackbar
    className="form-action-snackbar"
    show={show}
    message={message}
    timeout={3000}
    actionText="CLOSE"
    onAction={close}
    data-qa={dataQA}
    {...rest}
  />
);

export default ActionSnackBar;
