import React from 'react';
import {
  ExpansionPanelHeaderTitle,
  ExpansionPanelHeaderDescription
} from '@cdk-uip/react-expansion-panel';
import { injectIntl } from 'react-intl';

class SubscriptionPlanHeader extends React.Component {
  render() {
    const {
      plan: {
        planName,
        freeTrialPeriod,
        freeTrialPeriodType,
        basePrice,
        includedTransactionalFee,
        transactionalTypeOptions,
        isTheFreeTrial,
        billingType,
        billingFrequency,
        includedTransactionalType
      }
    } = this.props;

    const transactionalFee = () => {
      if (includedTransactionalFee && transactionalTypeOptions) {
        if (transactionalTypeOptions === '%') {
          return `${includedTransactionalFee.toFixed(
            2
          )} ${transactionalTypeOptions}`;
        }

        if (transactionalTypeOptions === '$') {
          return `${transactionalTypeOptions} ${includedTransactionalFee.toFixed(
            2
          )}`;
        }

        return '$0.00';
      }
      return '$0.00';
    };

    return (
      <section className="plan-list-header">
        <ExpansionPanelHeaderTitle>{planName}</ExpansionPanelHeaderTitle>
        {isTheFreeTrial && (
          <ExpansionPanelHeaderDescription className="plan-title-note">
            {`Expires in ${freeTrialPeriod} ${(freeTrialPeriodType &&
              freeTrialPeriodType.toLowerCase()) ||
              ''}`}
          </ExpansionPanelHeaderDescription>
        )}
        <ExpansionPanelHeaderDescription>
          <div>
            <span className="plan-price-title">
              {(basePrice && `$${basePrice.toFixed(2)}`) || transactionalFee()}
            </span>
            <span className="plan-price-title-postfix">
              {isTheFreeTrial && '/ month'}
              {billingType &&
                billingType === 'RECURRING' &&
                billingFrequency &&
                `/ ${billingFrequency.toLowerCase()}`}
              {billingType &&
                billingType === 'TRANSACTIONAL' &&
                includedTransactionalType &&
                `/ ${includedTransactionalType}`}
            </span>
          </div>
        </ExpansionPanelHeaderDescription>
      </section>
    );
  }
}

export default injectIntl(SubscriptionPlanHeader);
