import SellSolution from '../components/sell/SellSolution';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const query = gql`
  query($orgId: String) {
    userApps(orgId: $orgId) {
      id
      name
      description
      category
      solutiondata {
        id
      }
    }
    solutionList(orgId: $orgId) {
      id
      listingVersion
      status
      solutionType
      overview {
        name
        briefDescription
        solutionLogo
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  list: data.userApps || [],
  solutionList: data.solutionList || [],
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps,
  options: ({ entity }) => ({
    variables: {
      orgId: entity.id
    }
  })
})(SellSolution);
