import React, { Component } from 'react';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormDeleteIcon from './FormDeleteIcon';
import { DisplayContextConsumer } from '../../SolutionSubmissionForm';
import UploadDocUtils from '../utils/UploadDocUtils';
import { Dialog, DialogBody } from '@cdk-uip/react-dialog';
import Spinner from '../../../common/Spinner';
import axios from 'axios';

/* this component is used to show a dot loader (...)*/
function DotLoader() {
  return <span className="dot-loader__consent" />;
}
let consentField = null;

class FormUploadTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSummaryFromProps: false
    };
    this.uploadDocUtils = new UploadDocUtils();
  }

  viewTerms({ s3Url }) {
    //create signed s3 url
    this.uploadDocUtils
      .s3Sign({
        filename: s3Url && s3Url.slice(s3Url.indexOf('/tnc') + 1),
        fileType: 'application/pdf',
        operation: 'getObject'
      })
      .then(res => {
        this.setState({
          open: true,
          signedS3Url: res && res.data && res.data.signS3.signedRequest
        });
      });
  }

  onClose() {
    this.setState({
      open: false
    });
  }

  createDeleteButton(context) {
    if (this.props.isSolutionReview) {
      const { formAllValues } = this.props;
      const s3Url = formAllValues.overview.overview.termsAndConditionsUrl;
      const fileName = s3Url.split('/')[s3Url.split('/').length - 1];
      return (
        <div>
          <Button
            className="button-right"
            compact={'true'}
            type="button"
            onClick={() => this.viewTerms({ s3Url })}
            outlined={true}
          >
            <FormattedMessage
              id="PDFView.viewButton"
              defaultMessage="View Document"
            />
          </Button>
          <Dialog open={this.state.open} onCancel={() => this.onClose()}>
            <embed
              title={fileName}
              width="100%"
              height="800px"
              src={this.state.signedS3Url}
            />
          </Dialog>
        </div>
      );
    }

    return (
      <FormDeleteIcon
        confirmMessageTitle={
          <FormattedMessage
            id="MultipleAddFormBuilder.deleteTitle"
            defaultMessage="Delete Item"
          />
        }
        confirmMessage={
          <FormattedMessage
            id="MultipleAddFormBuilder.deleteMessage"
            defaultMessage="This action cannot be undone. Are you sure you want to delete this item?"
          />
        }
        onDelete={() => {
          this.state.showSummaryFromProps
            ? this.deleteTerms()
            : context.hideTermsTypes();
        }}
        deleteIconStyle="delete-icon"
      />
    );
  }

  createDataTable(context) {
    const consentTermType = context.termsSummary.type
      ? context.termsSummary.type
      : this.state.consentType
      ? this.state.consentType
      : 'One-Click Acceptance';

    return (
      <TableContainer style={{ marginTop: '16px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Consent Type</TableCell>
              <TableCell>Document</TableCell>
              {/*<TableCell>Last Modified</TableCell>*/}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className="consentType__cell"
              >
                {this.state.termTypeloading ? <DotLoader /> : consentTermType}
              </TableCell>
              <TableCell>{context.termsSummary.fileName}</TableCell>
              {/*<TableCell>{context.termsSummary.lastModified}</TableCell>*/}
              <TableCell>{this.createDeleteButton(context)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  async deleteTerms() {
    try {
      this.setState({ deleteInProgress: true });
      const termsAndConditionsUrl = await this.deleteSolutionTerms();
      this.setState({ showSummaryFromProps: false });

      const { solution } = this.props;
      solution[0].solutiondata.overview.termsAndConditionsUrl = null;
      this.props.onChange(solution);

      //clean up form
      this.props.cleanupFormOnTermsDelete();
    } catch (e) {
      console.error('failed to delete terms', e);
    }
  }

  deleteSolutionTerms = () => {
    const { solution } = this.props;
    const { orgId, id, listingVersion } = solution[0].solutiondata;
    const fileName = this.getFileName();
    let filePath = `tnc/${orgId}/${id}/${listingVersion}/${fileName}`;
    return new Promise((resolve, reject) => {
      this.uploadDocUtils
        .deleteDoc(filePath, 'application/pdf')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          this.setState({ deleteInProgress: false });
        });
    });
  };

  componentDidMount() {
    const { solution, formAllValues, isSolutionReview } = this.props;
    if (
      (solution &&
        Array.isArray(solution) &&
        solution.length > 0 &&
        solution[0].solutiondata &&
        solution[0].solutiondata.overview &&
        solution[0].solutiondata.overview.termsAndConditionsUrl) ||
      (formAllValues &&
        formAllValues.overview &&
        formAllValues.overview.overview &&
        formAllValues.overview.overview.termsAndConditionsUrl)
    ) {
      if (isSolutionReview || this.getFileName().length > 0) {
        //solution contains the uploaded doc.. hitting the s3 url for getting the metadata..
        const s3Url = formAllValues.overview.overview.termsAndConditionsUrl;
        const consentTermsType = this.getConsentTermsType(s3Url);
      }
      this.setState({ showSummaryFromProps: true });
    }
  }

  async getConsentTermsType(consentLink) {
    this.setState({
      termTypeloading: true
    });
    if (!consentField) {
      if (consentLink) {
        await axios(consentLink, {
          method: 'HEAD'
        })
          .then(response => {
            if (response.headers.hasOwnProperty('x-amz-meta-termstype')) {
              console.log(
                `solution terms have '${
                  response.headers['x-amz-meta-termstype']
                }' type`
              );
              consentField = response.headers['x-amz-meta-termstype'];
              this.setState({
                termTypeloading: false,
                consentType: response.headers['x-amz-meta-termstype']
              });
            } else {
              console.log(
                'solution terms are not having metadata about x-amz-meta-termstype',
                response
              );
              this.setState({
                termTypeloading: false
              });
            }
          })
          .catch(error => {
            consentField = null;
            this.setState({
              termTypeloading: false
            });
            console.log('inside catch block! some error occured!');
            console.error(error);
          });
      }
    } else {
      this.setState({
        termTypeloading: false,
        consentType: consentField
      });
    }
  }

  getFileName() {
    const { solution, formAllValues } = this.props;
    let termsAndConditionsUrl;
    if (
      solution &&
      Array.isArray(solution) &&
      solution.length > 0 &&
      solution[0].solutiondata &&
      solution[0].solutiondata.overview &&
      solution[0].solutiondata.overview.termsAndConditionsUrl
    ) {
      termsAndConditionsUrl =
        solution[0].solutiondata.overview.termsAndConditionsUrl;
    } else if (
      formAllValues &&
      formAllValues.overview &&
      formAllValues.overview.overview &&
      formAllValues.overview.overview.termsAndConditionsUrl
    ) {
      termsAndConditionsUrl =
        formAllValues.overview.overview.termsAndConditionsUrl;
    }

    if (termsAndConditionsUrl) {
      return termsAndConditionsUrl.split('/')[
        termsAndConditionsUrl.split('/').length - 1
      ];
    } else {
      return '';
    }
  }

  render() {
    return (
      <DisplayContextConsumer>
        {context => {
          return (
            <div>
              <div className={'terms-of-use-upload-title'}>
                Subscribers must accept a terms of use agreement before
                subscribing to your app.
              </div>
              {context &&
                context.termsSummary &&
                context.termsSummary.showSummary &&
                this.createDataTable(context)}

              {this.state.showSummaryFromProps &&
                this.createDataTable({
                  termsSummary: { fileName: this.getFileName() }
                })}

              <Dialog open={this.state.deleteInProgress}>
                <DialogBody className={'file-upload-spinner'}>
                  Deleting document. Please wait
                </DialogBody>
                <Spinner />
              </Dialog>

              {context &&
                context.termsSummary &&
                !context.termsSummary.showSummary &&
                !this.state.showSummaryFromProps && (
                  <Button
                    className="submission-action-button-margin terms-of-use-show-types-button"
                    type="button"
                    onClick={() => context.showTermsTypes()}
                  >
                    <i className="material-icons" style={{ fontSize: '17px' }}>
                      add
                    </i>
                    <span>&nbsp;TERMS OF USE</span>
                  </Button>
                )}
            </div>
          );
        }}
      </DisplayContextConsumer>
    );
  }
}

export default FormUploadTerms;
