import React from "react";
import {
  AutoExpansionPanelGroup,
  AutoExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelHeader,
  ExpansionPanelContentBody,
  ExpansionPanelContentActions
} from "@cdk-uip/react-expansion-panel";
import { injectIntl } from "react-intl";
import SubscriptionPlanHeader from "./subscription-plan-header";
import SubscriptionPlanContent from "./subscription-plan-content";
import SubscriptionPlanActions from "./subscription-plan-actions";

class SubscriptionPlansList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedPlanId: null,
      selectedPlanId: this.props.selectedPlanId
    };
    this.onTermsActions = isAccepted => {
      if (isAccepted && this.state.selectedPlanId === isAccepted) {
        this.setState({ open: false, acceptedPlanId: isAccepted });
      } else if (isAccepted == null) {
        this.setState({ open: false, acceptedPlanId: null });
      }
    };
  }

  render() {
    const { solution } = this.props;
    let plans = solution.plans;
    let sortedPlans = [];
    plans.forEach(plan => {
      plan.isTheFreeTrial
        ? plan.isFreeTrialEnabled && sortedPlans.unshift(plan)
        : sortedPlans.push(plan);
    });

    return (
      sortedPlans &&
      sortedPlans.length > 0 && (
        <AutoExpansionPanelGroup>
          {sortedPlans.map((plan, planIdx) => {
            return (
              <AutoExpansionPanel
                expanded={plan.id === this.state.selectedPlanId}
                key={planIdx}
                className="subscription-plan-details"
              >
                <ExpansionPanelHeader
                  onClick={() => {
                    this.setState({
                      selectedPlanId:
                        this.state.selectedPlanId === plan.id ? null : plan.id
                    });
                  }}
                >
                  <SubscriptionPlanHeader plan={plan} />
                </ExpansionPanelHeader>
                <ExpansionPanelContent className="subscription-plan-details-content-panel">
                  <ExpansionPanelContentBody>
                    <SubscriptionPlanContent
                      solution={solution}
                      plan={plan}
                      isAccepted={plan.id === this.state.acceptedPlanId}
                      onTermsActions={this.onTermsActions}
                    />
                  </ExpansionPanelContentBody>
                  <ExpansionPanelContentActions>
                    {plan.isTheFreeTrial && (
                      <SubscriptionPlanActions
                        solution={solution}
                        plan={plan}
                        isAccepted={plan.id === this.state.acceptedPlanId}
                        entity={this.props.entity}
                      />
                    )}
                  </ExpansionPanelContentActions>
                </ExpansionPanelContent>
              </AutoExpansionPanel>
            );
          })}
        </AutoExpansionPanelGroup>
      )
    );
  }
}

export default injectIntl(SubscriptionPlansList);
