import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const PrivateSolutionBadge = () => {
  return (
    <div className="private-solution-badge">
      <div className="private-badge-icon-div">
        <i className="material-icons">lock</i>
      </div>
      <div className="private-badge-text">
        <FormattedMessage
          id="PrivateSolutionBadge.private"
          defaultMessage="Private"
        />
      </div>
    </div>
  );
};

export default injectIntl(PrivateSolutionBadge);
