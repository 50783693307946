import AppSubscriptions from '../common/AppSubscriptions';
import { ProvisioningConstants } from '../common/ProvisioningConstants';
import SolutionVisibility from '../common/SolutionVisibility';

export const dataObject = {
  id: '',
  listingVersion: '',
  apigee_id: '',
  developerId: '',
  activateOnly: '',
  developerName: '',
  status: '',
  submittedOn: null,
  lastUpdatedBy: null,
  lastUpdatedOn: null,
  comments: [],
  registeredOn: null,
  solutionType: null,
  listingVersionUpdates: {
    description: '',
    updates: []
  },
  overview: {
    name: '',
    category: '',
    applicationArea: '',
    searchKeywords: [],
    version: '',
    briefDescription: '',
    detailedDescription: '',
    publisherName: '',
    website: '',
    solutionWebsite: '',
    outageNotificationsEmail: '',
    copyright: '',
    privacyPolicyUrl: '',
    termsAndConditionsUrl: '',
    solutionLogo: '',
    videos: [],
    featuredImage: '',
    galleryImages: []
  },
  highlights: {
    highlight_list: []
  },
  support: {
    tutorials_and_documentation: [],
    supportMessage: '',
    supportCenterUrl: '',
    phoneNumber: '',
    emailAddress: '',
    supportCommunityUrl: ''
  },
  termsOfUse: {},
  features: [],
  plans: [],
  worksWithIds: [],
  registration_details: {
    api_details: [],
    apiKey: '',
    apiSecret: '',
    keyIssuedOn: '',
    keyExpiresOn: '',
    callbackUrl: ''
  },
  solution_visibility: {
    solutionVisibilityType: SolutionVisibility.PUBLIC,
    privateSolutionUsers: []
  },
  app_subscriptions: {
    appSubscriptionsType: AppSubscriptions.AUTOMATIC
  },
  partner_program_registration_details: {
    partner_api_details: []
  },
  solution_notes: {
    reviewersComments: ''
  },
  provisioning_interface: {
    endpointUrl: '',
    redirectURL: '',
    provisioningtype: ProvisioningConstants.type.MANUAL,
    entityID: false,
    subscriptionID: true
  },
  subscription_notifications: {
    email: '',
    endpointUrl: '',
    apiNotification: false
  },
  subscription_management: {
    allowSubscriptions: ''
  },
  data_rights: {
    captureDataRightsConsent: ''
  }
};

export const arrayObjKeys = {
  features: ['id', 'title', 'description', 'featureImage'],
  plans: [
    'id',
    'isTheFreeTrial',
    'isFreeTrialEnabled',
    'position',
    'planName',
    'planDescription',
    'freeTrialPeriod',
    'freeTrialPeriodType',
    'billingType',
    'transactionalTypeOptions',
    'includedTransactionalType',
    'includedTransactionalFee',
    'billingFrequency',
    'basePrice',
    'includedUnitOfMeasure',
    'includedUnitQuantity',
    'additionalUnitPrice',
    'additionalUnitOfMeasure',
    'features'
  ]
};
