export const SolutionStatus = {
  REGISTERED: 'Registered',
  SUBMITTED: 'Submitted',
  RECALLED: 'Recalled',
  IN_REVIEW: 'In Review',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PUBLISHED: 'Published',
  WITHDRAWN: 'Withdrawn',
  REVOKED: 'Revoked',
  DRAFT: 'Draft',
  NOT_APPROVED: 'Not Approved',
  PUBLISH_PENDING: 'Publish Pending',
  CANCELLED: 'Cancelled'
};

export const templateConfig = (
  emailType = '',
  solutionName = '',
  solutionType = '',
  solutionId = '',
  developerEmail = '',
  solutionReviewComments = '',
  publisher = '',
  publisherEmail = '',
  publisherPhone = '',
  leadName = '',
  leadEmail = '',
  leadMessage = '',
  reviewerName = '',
  solutionStatus = '',
  subscriptionDetails = null,
  updatedOn = '',
  solutionSupportEmail = '',
  providerId = '',
  providerName = '',
  entityId = '',
  organizationName = '',
  currentdate = '',
  cmf = '',
  userEmail = '',
  consentType = '',
  apiList = '',
  appDetails = null,
  apiDetails = []
) => ({
  emailType: emailType,
  solutionName: solutionName,
  solutionType: solutionType,
  solutionId: solutionId,
  developerEmail: developerEmail,
  solutionReviewComments: solutionReviewComments,
  publisher: publisher,
  publisher: publisherEmail,
  publisherPhone: publisherPhone,
  leadName: leadName,
  leadMessage: leadMessage,
  leadEmail: leadEmail,
  reviewerName: reviewerName,
  solutionId: solutionId,
  solutionName: solutionName,
  updatedOn: updatedOn,
  providerName: providerName,
  providerId: providerId,
  subscriptionDetails: subscriptionDetails,
  solutionStatus: solutionStatus,
  solutionSupportEmail: solutionSupportEmail,
  entityId: entityId,
  apiList: apiList,
  userEmail: userEmail,
  organizationName: organizationName,
  currentdate: currentdate,
  cmf: cmf,
  consentType: consentType,
  appDetails: appDetails,
  apiDetails: apiDetails
});

export const attachmentConfig = (
  attachmentType = 'application/pdf',
  fileName = '',
  disposition = 'attachment',
  cid = 'pdf',
  content = '',
  fileUrl = ''
) => ({
  mimeType: attachmentType,
  fileName: fileName,
  disposition: disposition,
  cid: cid,
  content: content,
  fileUrl: fileUrl
});

export const toAddressConfig = (name = '', emailAdd = '') => ({
  name: name,
  email: emailAdd
});

export const subscriptionDetailsConfig = (
  buyerName = '',
  buyerEmail = '',
  purchaseDate = '',
  planName = '',
  planDetails = '',
  subscriptionStartDate = '',
  subscriptionEndDate = '',
  subscriptionId = '',
  entityId = '',
  comment = '',
  customerPhone = '',
  companyName = '',
  subscriberOrganization = '',
  subscriberName = ''
) => ({
  buyerName: buyerName,
  buyerEmail: buyerEmail,
  purchaseDate: purchaseDate,
  planName: planName,
  planDetails: planDetails,
  subscriptionStartDate: subscriptionStartDate,
  subscriptionEndDate: subscriptionEndDate,
  subscriptionId: subscriptionId,
  entityId: entityId,
  comment: comment,
  customerPhone: customerPhone,
  companyName: companyName,
  subscriberOrganization: subscriberOrganization,
  subscriberName: subscriberName
});

export const appDetailsConfig = (
  appName = '',
  appId = '',
  appOrgName = '',
  appOrgId = '',
  appTermsConsentType = ''
) => ({
  appName: appName,
  appId: appId,
  appOrgName: appOrgName,
  appOrgId: appOrgId,
  appTermsConsentType: appTermsConsentType
});

export const apiDetailsConfig = (
  apiName = '',
  apiOrgName = '',
  apiOrgId = '',
  apiConsentType = ''
) => ({
  apiName: apiName,
  apiOrgName: apiOrgName,
  apiOrgId: apiOrgId,
  apiConsentType: apiConsentType
});

export const emailType_internal_alert_app_publisher_signed_api_terms =
  'internalAlertAppPublisherSignedApiTerms';
export const subject_internal_alert_app_publisher_signed_api_terms =
  'Internal Alert - App Publisher Signed API Terms';

export const emailType_internal_alert_app_publisher_disabled_an_api =
  'internalAlertAppPublisherDisabledAnApi';
export const subject_internal_alert_app_publisher_disabled_an_api =
  'Internal Alert - App Publisher Disabled An API';

export default SolutionStatus;
