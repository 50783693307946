import React from 'react';
import moment from 'moment';
import FortellisDialog from '../../common/FortellisDialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

class ReviewResponse extends React.Component {
  state = {
    deleteConfirmationOpen: false
  };

  render() {
    const {
      reviewResponse,
      deleteReview,
      deleteReviewFromParent,
      auth: { isAuthenticated, userData }
    } = this.props;

    return (
      <div className="review-response-container">
        <div>
          <span className="response-by">
            <FormattedMessage
              id="ReviewResponse.text"
              defaultMessage="Response By Publisher"
            />
          </span>
          {'   '}
          <span className="review-date">
            {moment(reviewResponse.createdDate).format('DD MMM YYYY')}
          </span>
          {isAuthenticated &&
            userData &&
            reviewResponse.authorEmail === userData.email && (
              <span
                onClick={() => this.setState({ deleteConfirmationOpen: true })}
                className="delete-review-icon"
              >
                <i className="material-icons">delete</i>
              </span>
            )}
        </div>
        <div className="review-desc">{reviewResponse.reviewText}</div>

        <FortellisDialog
          title="Delete review?"
          message={
            <FormattedMessage
              id="ReviewResponse.deleteReviewText"
              defaultMessage="Your review will be permanently removed from the site."
            />
          }
          open={this.state.deleteConfirmationOpen}
          acceptButtonName="DELETE"
          cancelButtonName="CANCEL"
          onAccept={() =>
            deleteReview.execute(reviewResponse.id).then(() => {
              deleteReviewFromParent(reviewResponse.id);
            })
          }
          onCancel={() => {
            this.setState({ deleteConfirmationOpen: false });
          }}
        />
      </div>
    );
  }
}

export default injectIntl(withAuth(ReviewResponse));
export { ReviewResponse as PureReviewResponse }; // pure component. used in tests
