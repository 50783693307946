import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Dialog, Link, IconButton, IconFileDownload } from 'cdk-radial';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import './styles/subscriptionsData.scss';
import UploadDocUtils from '../../solution-submission/common-form-api/utils/UploadDocUtils';
import Loading from '../../common/Loading';
import {
  PrivacyProtectionOnApiIndicator,
  PrivacyProtectionInfoIndicator
} from './PrivacyProtectionIndicator';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';

const App_NAME = 'App Name';
const APP_ID = 'App Id';
const APP_PROVIDER = 'App Provider';
const ENTITY_NAME = 'Organization Name';
const ENTITY_ADDRESS = 'Organization Address';
const ENTITY_ID = 'Organization Id';
const STORE_ID = 'Store Id';
const SUBSCRIPTION_ID = 'Subscription Id';
const SUBSCRIPTION_STATUS = 'Status';
const CMF_NUMBER = 'CMF Number';
const APIS = 'APIs';
const CONSENTED_BY = 'Consented By';
const SUBSCRIPTION_DATE = 'Subscribed On';
const DOCUMENTS = 'Documents (PDF)';
const Business_Title = 'Business Title';
const ENABLE_ONLY = 'Enable Only';
const IP_ADDRESS = 'IP Address';
const CNUMBER = 'CNumber';
const DMS_ACCOUNTS_BY_API = 'Dealer Selected DMS Accounts by API';
const CPP_OPT_OUT = 'CPP Opt Out';

function SubscriptionDetailsDialog({
  setOpenDialog,
  data,
  isOpen,
  searchType,
  fetchingConsentData
}) {
  const uploadDocUtils = new UploadDocUtils();

  const {
    consents = [],
    solution = {},
    subscriptionData = {},
    metadata = {}
  } = data;

  const [isDownloading, setIsDownloading] = useState(false);

  const [fieldsDialogOpen, setFieldsDialogOpen] = useState(false);
  const [fieldsDialogApi, setFieldsDialogApi] = useState({});
  const [cppData, setCppData] = useState(null);
  const [fetchingCppData, setFetchingCppData] = useState(false);

  const doesAppHasCpp = solution?.registration_details?.api_details?.filter(
    item => item.privacyProtection
  ).length
    ? true
    : false;

  const { solutionId = '', entityId = '' } = subscriptionData;

  useEffect(() => {
    const getCpp = async () => {
      try {
        setFetchingCppData(true);
        const responseObj = await getApolloClient().query({
          query: gql`
            query($solutionId: String, $entityId: String) {
              getCpp(solutionId: $solutionId, entityId: $entityId) {
                consumerId
                entityId
                providerId
                cppFlag
              }
            }
          `,
          variables: { solutionId, entityId }
        });
        return responseObj?.data?.getCpp;
      } catch (err) {
        setCppData([]);
      }
    };
    if (doesAppHasCpp)
      getCpp().then(cppData => {
        setFetchingCppData(false);
        setCppData(cppData);
      });
  }, []);

  const closeDialog = () => {
    setFieldsDialogOpen(false);
    setOpenDialog(false);
  };

  const downloadButton = (consents, entityName, appName) => {
    const documents = [];
    consents.length &&
      consents.map(consent => {
        if (consent.consentType === 'solution_tnc') {
          if (consent.signedTermsAndConditions) {
            documents.push({
              url: consent.signedTermsAndConditions,
              type: 'signedTerms'
            });
          } else {
            documents.push({ url: consent.documentLink, type: 'appTerms' });
          }
        } else if (consent.consentType === 'api_data') {
          if (
            consent.customConsentForm &&
            !documents.some(custom => custom === consent.customConsentForm)
          ) {
            documents.push({
              url: consent.customConsentForm,
              type: 'customConsentForm'
            });
          } else if (
            !consent.customConsentForm &&
            !consent.signedTermsAndConditions &&
            consent.documentLink
          ) {
            documents.push({ url: consent.documentLink, type: 'addendum' });
          } else if (
            !consent.customConsentForm &&
            consent.signedTermsAndConditions
          ) {
            documents.push({
              url: consent.signedTermsAndConditions,
              type: 'signedTerms'
            });
          }
        } else if (consent.consentType === 'payments' && consent.documentLink) {
          const documentLink = consent.documentLink.split('+').join(' ');
          documents.push({ url: documentLink, type: 'monetizationAgreement' });
        } else if (
          consent.consentType === 'data_rights' &&
          consent.signedTermsAndConditions
        ) {
          documents.push({
            url: consent.signedTermsAndConditions,
            type: 'dataRights'
          });
        }
      });

    return (
      <div>
        {!!documents.length ? (
          <IconButton
            className="accordion_download-button"
            icon={<IconFileDownload />}
            onClick={function() {
              setIsDownloading(true);
              onDownloadClick(documents, entityName, appName);
            }}
            text={'action'}
          />
        ) : (
          'N/A'
        )}
      </div>
    );
  };

  const onDownloadClick = async (documents, entityName, appName) => {
    const signedDocumentUrls = [];

    for (var item of documents) {
      if (item.type !== 'addendum') {
        const url = item.url;
        let fileName;
        if (item.type === 'datarights') {
          fileName = url.split('.com/')[1];
        } else {
          fileName = url && url.slice(url.indexOf('.com/') + 5);
        }
        await uploadDocUtils
          .s3Sign({
            filename: fileName,
            fileType: 'application/pdf',
            operation: 'getObject',
            termsType: item.type
          })
          .then(res => {
            const s3Url =
              (res && res.data && res.data.signS3.signedRequest) || null;
            signedDocumentUrls.push(s3Url);
          });
      } else {
        signedDocumentUrls.push(item.url);
      }
    }

    var zip = new JSZip();
    var count = 0;
    var zipFilename = '';
    if (searchType === 'app') {
      zipFilename = `${entityName}.zip`;
    } else {
      zipFilename = `${appName}.zip`;
    }
    signedDocumentUrls.forEach(function(document) {
      var filename = document.substring(document.lastIndexOf('/') + 1).trim();
      filename = filename.split('%20').join(' ');
      if (filename.includes('?')) {
        const name = filename.split('?');
        filename = name[0];
      }
      JSZipUtils.getBinaryContent(document, function(err, data) {
        if (err) {
          setIsDownloading(false);
          throw err;
        }
        zip.file(filename, data, {
          binary: true
        });
        count++;
        if (count === documents.length) {
          zip
            .generateAsync({
              type: 'blob'
            })
            .then(function(blob) {
              setIsDownloading(false);
              saveAs(blob, zipFilename);
            });
        }
      });
    });
  };

  const getAPINames = data => {
    return (
      data &&
      data.length &&
      data.map(item => (
        <div>
          {item.displayName}{' '}
          <Link
            href="#"
            onClick={e => {
              // eslint-disable-next-line no-unused-expressions
              e?.preventDefault();
              if (item.fields?.length) {
                setFieldsDialogApi(item);
                setFieldsDialogOpen(true);
              }
            }}
            text={`(${item.fields?.length ?? 0} fields)`}
            isDisabled={!item.fields?.length}
          />{' '}
          {item.privacyProtection && (
            <div className="subscription_details-privacy-protection">
              <PrivacyProtectionOnApiIndicator />
            </div>
          )}
        </div>
      ))
    );
  };

  const getCppInfo = data => {
    if (!data || !data.length) return null;

    return data.map(item => {
      if (item.privacyProtection) {
        const cpp =
          cppData &&
          cppData.filter(cppItem => item.id.includes(cppItem.providerId));
        return (
          <div className="subscription_cpp_info">
            {`${item.displayName} => `}
            {fetchingCppData ? (
              <Loading circleClass="subscription_loader" />
            ) : cpp?.[0]?.cppFlag ? (
              'True'
            ) : (
              'False'
            )}
          </div>
        );
      }
      return null;
    });
  };

  const getConsentByNames = data => {
    const consentedBy = [];
    data &&
      data.length &&
      data.map(consent => {
        if (
          consent.name &&
          (!consentedBy.length || !consentedBy.includes(consent.name))
        ) {
          consentedBy.push(consent.name);
        }
      });
    if (consentedBy.length === 1) {
      return consentedBy[0];
    } else if (consentedBy.length > 1) {
      return consentedBy.map(item => <div>{item}</div>);
    } else if (consentedBy.length === 0) {
      return 'NA';
    }
  };

  const getBusinessTitles = data => {
    const businessTitles = [];
    data &&
      data.length &&
      data.map(consent => {
        let businessTitle =
          consent.businessTitle && consent.businessTitle.toUpperCase();
        if (
          businessTitle &&
          (!businessTitles.length || !businessTitles.includes(businessTitle))
        ) {
          businessTitles.push(businessTitle);
        }
      });
    if (businessTitles.length === 1) {
      return businessTitles[0];
    } else if (businessTitles.length > 1) {
      return businessTitles.map(item => <div>{item}</div>);
    } else if (businessTitles.length === 0) {
      return 'NA';
    }
  };

  const apiNamesById = {};
  solution &&
    solution.registration_details &&
    solution.registration_details.api_details &&
    solution.registration_details.api_details.length &&
    solution.registration_details.api_details.forEach(apiDetail => {
      if (apiDetail && apiDetail.id)
        apiNamesById[apiDetail.id] =
          apiDetail.displayName || apiDetail.name || apiDetail.id;
    });

  const getDealerSelectedDMSAccountsByAPI = data => {
    let selectedDmsByApi = {};
    data &&
      data.entityIdList &&
      data.entityIdList.length &&
      data.entityIdList.forEach(item => {
        if (item && (item.dmsAccount || item.departments)) {
          const dmsString =
            'departments' in item && item.departments.length
              ? item.departments.map(d => `${d.name} (${d.id})`).join(',')
              : item.dmsAccount;

          if (typeof item.api === 'string' && item.api) {
            if (item.api in selectedDmsByApi)
              selectedDmsByApi[item.api] += `, ${dmsString}`;
            else selectedDmsByApi[item.api] = dmsString;
          } else if (item.api && Array.isArray(item.api) && item.api.length) {
            item.api.forEach(API => {
              if (API in selectedDmsByApi)
                selectedDmsByApi[API] += `, ${dmsString}`;
              else selectedDmsByApi[API] = dmsString;
            });
          }
        }
      });
    if (Object.keys(selectedDmsByApi).length)
      return Object.keys(selectedDmsByApi).map(apiId => {
        return (
          <div>{`${apiNamesById[apiId]} => ${selectedDmsByApi[apiId]}`}</div>
        );
      });
    else return 'NA';
  };

  const getAppName = () => {
    let appName = 'NA';
    if (solution) {
      if (solution.overview && solution.overview.name) {
        appName = solution.overview.name;
      } else {
        appName = solution.name;
      }
    }
    return appName;
  };

  return (
    <div>
      <Dialog
        className="subscription_details-dialog"
        id="dialog-2"
        isOpen={isOpen}
        onClose={closeDialog}
        title="Subscription Details"
      >
        <div className="subscription_body-grid">
          <div>{App_NAME}</div>
          <div>{getAppName()}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{APP_ID}</div>
          <div>
            {(solution && solution.id) || subscriptionData.solutionId || 'NA'}
          </div>
        </div>
        <div className="subscription_body-grid">
          <div>{APP_PROVIDER}</div>
          <div>{(solution && solution.orgName) || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{ENTITY_NAME}</div>
          <div>{subscriptionData.entityName || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{ENTITY_ADDRESS}</div>
          <div>{subscriptionData.orgAddress || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{ENTITY_ID}</div>
          <div>{subscriptionData.entityId || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{STORE_ID}</div>
          <div>{subscriptionData.storeId || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{SUBSCRIPTION_ID}</div>
          <div>{subscriptionData.subscriptionId || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{SUBSCRIPTION_DATE}</div>
          <div>
            {moment(subscriptionData.created)
              .utc()
              .format('DD MMM YYYY, hh:mm a') || 'NA'}
          </div>
        </div>
        <div
          className="subscription_body-grid"
          style={{ textTransform: 'capitalize' }}
        >
          <div>{SUBSCRIPTION_STATUS}</div>
          <div>{subscriptionData.status}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{CMF_NUMBER}</div>
          <div>{(metadata && metadata.entitycmfNumber) || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{DMS_ACCOUNTS_BY_API}</div>
          <div>{getDealerSelectedDMSAccountsByAPI(metadata)}</div>
        </div>
        {doesAppHasCpp && (
          <div className="subscription_body-grid">
            <div className="subscription_cpp_info">
              {CPP_OPT_OUT}
              <span className="subscription_cpp_info_icon">
                <PrivacyProtectionInfoIndicator />
              </span>
            </div>
            <div style={{ display: 'grid' }}>
              {getCppInfo(solution?.registration_details?.api_details)}
            </div>
          </div>
        )}
        <div className="subscription_body-grid">
          <div>{CNUMBER}</div>
          <div>{(metadata && metadata.cNumber) || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{IP_ADDRESS}</div>
          <div>{(metadata && metadata.ipAddress) || 'NA'}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{APIS}</div>
          <div>
            {getAPINames(
              solution &&
                solution.registration_details &&
                solution &&
                solution.registration_details.api_details
            )}
          </div>
        </div>
        <div className="subscription_body-grid">
          <div>{CONSENTED_BY}</div>
          <div>{getConsentByNames(consents)}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{Business_Title}</div>
          <div>{getBusinessTitles(consents)}</div>
        </div>
        <div className="subscription_body-grid">
          <div>{ENABLE_ONLY}</div>
          <div>
            {solution && solution.activateOnly !== false ? 'YES' : 'NO'}
          </div>
        </div>
        <div className="subscription_body-grid">
          <div>{DOCUMENTS}</div>
          <div>
            {!isDownloading && !fetchingConsentData ? (
              downloadButton(
                consents,
                subscriptionData.entityName,
                solution && solution.overview && solution.overview.name
              )
            ) : (
              <Loading loaderClass={''} circleClass={'subscription_loader'} />
            )}
          </div>
        </div>

        <Dialog
          id="subscription_details-fields-dialog"
          className="subscription_details-fields-dialog"
          title={`Fields for ${fieldsDialogApi.displayName}`}
          isOpen={fieldsDialogOpen}
          onClose={() => setFieldsDialogOpen(false)}
        >
          <ul>
            {fieldsDialogApi.fields?.map(field => <li key={field}>{field}</li>)}
          </ul>
        </Dialog>
      </Dialog>
    </div>
  );
}

export default SubscriptionDetailsDialog;
