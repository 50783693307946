import React from 'react';
import {
  AutoExpansionPanel,
  AutoExpansionPanelGroup,
  ExpansionPanelContent,
  ExpansionPanelHeader
} from '@cdk-uip/react-expansion-panel';
import { CardSubtitle, CardTitle } from '@cdk-uip/react-card';
import { Avatar } from '@cdk-uip/react-avatar';
import Spinner from './Spinner';
import PhoneNumber from './PhoneNumber';
import MySubscriptionStatus from '../marketplace-account/subscriptions/my-subscription-status';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import moment from 'moment';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withApollo } from 'react-apollo';

const getLogoLabel = name => {
  let firstChars = name.split(' ').map(item => {
    return item.charAt(0);
  });
  if (firstChars.length) {
    if (firstChars.length > 1) {
      return firstChars[0] + firstChars[1];
    }
    return firstChars[0];
  }
  return '';
};

const entityFromPlatform = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      website
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
      phone
      name
      type
    }
  }
`;

class EntityDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: null
    };
  }

  fetchEntityFromPlatform(entityId) {
    this.props.client
      .query({
        query: entityFromPlatform,
        variables: { id: entityId }
      })
      .then(response => {
        if (!response.errors && response.data.entitiesFromPlatform) {
          this.setState({ entity: response.data.entitiesFromPlatform });
        }
      })
      .catch(error => {
        this.setState({ error: 'Entity Not found in platform ' + error });
      });
  }

  componentDidMount() {
    if (!this.props.entity && this.props.entityId) {
      this.fetchEntityFromPlatform(this.props.entityId);
    }
  }

  render() {
    let { entity, status } = this.props;
    entity = entity || this.state.entity;
    if (!entity) {
      return <Spinner />;
    }
    if (this.state.error) {
      return <div />;
    }
    return (
      <AutoExpansionPanelGroup>
        <AutoExpansionPanel expanded={false}>
          <ExpansionPanelHeader className="subscription-entity-expansion-panel-header">
            {entity.name ? (
              <Avatar className="subscription-entity-expansion-panel-header-logo">
                {getLogoLabel(entity.name)}
              </Avatar>
            ) : null}
            <div className="subscription-entity-expansion-panel-header-title">
              <div className="subscription-entity-expansion-panel-header-title-info">
                <CardTitle className="subscription-card-title">
                  {entity.name}
                </CardTitle>
                <CardSubtitle className="subscription-card-sub-title">
                  {`Registered: ${moment(entity.created).format(
                    'DD MMM YYYY hh:mm A'
                  )}`}
                </CardSubtitle>
                <CardSubtitle className="subscription-card-sub-title">
                  {`Entity Id: ${entity.id}`}
                </CardSubtitle>
              </div>
              {status ? (
                <div className="pull-right subscription-entity-expansion-panel-header-status">
                  <MySubscriptionStatus status={status} />
                </div>
              ) : null}
            </div>
          </ExpansionPanelHeader>
          <ExpansionPanelContent>
            <div className="subscription-entity-expansion-panel-body">
              <div className="subscription-entity-expansion-panel-body-title">
                <FormattedMessage
                  id="SubscriptionEntity.title"
                  defaultMessage="Entity Information"
                />
              </div>

              <div className="subscription-entity-expansion-panel-body-fields">
                <div className="subscription-entity-expansion-panel-body-field">
                  <div className="subscription-entity-expansion-panel-body-field-title">
                    <FormattedMessage
                      id="SubscriptionEntity.phone"
                      defaultMessage="Phone "
                    />
                  </div>
                  <div className="subscription-entity-expansion-panel-body-field-content">
                    <PhoneNumber value={entity.phone || '-'} />
                  </div>
                </div>

                <div className="subscription-entity-expansion-panel-body-field">
                  <div className="subscription-entity-expansion-panel-body-field-title">
                    <FormattedMessage
                      id="SubscriptionEntity.website"
                      defaultMessage="Website "
                    />
                  </div>
                  <div className="subscription-entity-expansion-panel-body-field-content-url">
                    {entity.website || '-'}
                  </div>
                </div>
              </div>

              <div className="subscription-entity-expansion-panel-body-fields">
                <div className="subscription-entity-expansion-panel-body-field">
                  <div className="subscription-entity-expansion-panel-body-field-title">
                    <FormattedMessage
                      id="SubscriptionEntity.address"
                      defaultMessage="Address "
                    />
                  </div>
                  <div className="subscription-entity-expansion-panel-body-field-content">
                    {entity.address
                      ? entity.address.street +
                        ' ' +
                        entity.address.city +
                        ' ' +
                        entity.address.region +
                        ' ' +
                        entity.address.postalCode +
                        ' ' +
                        entity.address.countryCode
                      : '-'}
                  </div>
                </div>
              </div>
            </div>
          </ExpansionPanelContent>
        </AutoExpansionPanel>
      </AutoExpansionPanelGroup>
    );
  }
}

export default withAuth(withApollo(EntityDetails));
