import React from 'react';
import { Button } from '@cdk-uip/react-button';
import PropTypes from 'prop-types';

export default function FullScreenOverlay(props) {
  const handleClose = () => {
    if (props.onClose && typeof props.onClose === 'function') {
      props.onClose();
    }
  };

  return (
    <div>
      <Dialog open={props.open}>
        <AppBar {...props}>
          <Button
            className={props.backButtonStyleClassName}
            onClick={() => handleClose()}
          >
            <i className={`material-icons`}>arrow_back</i>
          </Button>
          <div className={props.titleStyleClassName}>{props.title}</div>
        </AppBar>
        <div className={'fullScreenOverlay_content'}>
          {React.cloneElement(props.children, { ...props })}
        </div>
      </Dialog>
    </div>
  );
}

const Dialog = props => {
  return (
    props.open && (
      <div>
        <div className={'fullScreenOverlay_dialog'}>{props.children}</div>
      </div>
    )
  );
};

const AppBar = props => {
  return (
    <div
      className={'fullScreenOverlay_appBar'}
      style={{ background: props.headerBackground }}
    >
      {props.children}
    </div>
  );
};

FullScreenOverlay.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func
};

FullScreenOverlay.defaultProps = {
  backButtonStyleClassName: 'fullScreenOverlay_backButton',
  titleStyleClassName: 'fullScreenOverlay_title',
  headerBackground: '#904778'
};
