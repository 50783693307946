import React, { Component } from 'react';

import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DataTable, DataTableRow } from '@cdk-uip/react-data-table';
import { DataTableBody } from '@cdk-uip/react-data-table';
import { DataTableCell } from '@cdk-uip/react-data-table';
import { DataTableHeader } from '@cdk-uip/react-data-table';
import { DataTableHeaderCell } from '@cdk-uip/react-data-table';
import { Card } from '@cdk-uip/react-card';
import { Button } from '@cdk-uip/react-button';
import { Select } from '@cdk-uip/react-select';
import { StatusIndicatorInline } from '@cdk-uip/react-status-indicator';
import SolutionStripForApiConfig from '../../../containers/SolutionStripForApiConfigContainer';
import Spinner from '../../common/Spinner';
import ApiConnectionStatusConstants from '../../common/ApiConnectionStatusConstants';
import EntityRegistrationConfirmation from '../../common/EntityRegistrationConfirmation';
import FortellisConstants from '../../common/FortellisConstants';
import SolutionListingAPI from '../../../api/StoreListingAPI';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

import UserConsentDialog, {
  consentStatusConst,
  consentTypeConst
} from '../../common/UserConsentDialog';
import axios from 'axios';
import FortellisDialogSpinner from '../../common/FortellisDialogSpinner';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import ActionSnackBar from '../../solution-submission/ActionSnackBar';
import FortellisDialog from '../../common/FortellisDialog';
import NotFound from '../../common/NotFound';
import ConsentTypeConstants from '../../common/constants/ConsentTypeConstants';
import moment from 'moment';

const entityFromPlatform = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      website
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
      phone
      name
      type
    }
  }
`;

//Constants
const ADDENDUM = 'addendum';

class ApiConfigForSolution extends Component {
  state = {
    apiListUpdated: false,
    entityFromPlatform: null,
    providersForApis: null,
    selectedConnection: null,
    failedToEnableConnection: false,
    savingConsentAcceptance: false,
    providersExist: false,
    providersLoading: true
  };

  fetchEntityFromPlatform(entityId) {
    let client = getApolloClient();
    client
      .query({
        query: entityFromPlatform,
        variables: { id: entityId }
      })
      .then(response => {
        if (!response.errors && response.data.entitiesFromPlatform) {
          this.setState({
            entityFromPlatform: response.data.entitiesFromPlatform
          });
        }
      })
      .catch(error => {
        console.log('Entity Not found in platform');
      });
  }

  componentDidMount() {
    this.props.entity &&
      this.fetchEntityFromPlatform(this.props.entity.id, this.props.solutionId);
  }

  setRegistrationDetails(registrationDetails) {
    this.setState({ registrationDetails });
  }

  getProvidersForApi = (apis, solutionId) => {
    let client = getApolloClient();
    client
      .query({
        query: providersForApis,
        variables: { provides: apis, solutionId: solutionId }
      })
      .then(async response => {
        if (!response.errors && response.data.providers) {
          const apiProviderMapping = [];
          let listingVersion = '1';
          const solutionId = this.props.solutionId;
          let solution = response.data.solutionCatalogList
            ? response.data.solutionCatalogList[0]
            : {};
          if (solution) {
            listingVersion = solution.listingVersion;
          }

          if (solution.id === null) {
            const client = getApolloClient();
            let queryResponse = await client.query({
              query: solutionDetails,
              variables: { solutionId: solutionId }
            });

            if (
              queryResponse &&
              queryResponse.data &&
              queryResponse.data.solutionList
            ) {
              queryResponse.data.solutionList.forEach(solution => {
                if (
                  solution.listingVersion &&
                  solution.listingVersion > listingVersion &&
                  solution.status === 'Withdrawn'
                ) {
                  listingVersion = solution.listingVersion;
                }
              });
            }
          }
          const consentType = FortellisConstants.CONSENT_TYPE_FOR_API;
          const auth = this.props.auth;

          let solutionsConsent = await SolutionListingAPI.anonymous.getSolutionsConsentedProvider(
            solutionId,
            listingVersion,
            consentType,
            auth
          );

          for (let [key, value] of Object.entries(solutionsConsent.data)) {
            if (
              (solution && value.listingVersion !== listingVersion) ||
              value.status !== 'accepted'
            ) {
              delete solutionsConsent.data[key];
            }
          }

          const solutionConsentedData = solutionsConsent.data;
          response.data.providers.forEach(provider => {
            Object.keys(solutionsConsent.data).map(key => {
              const consent = solutionConsentedData[key];
              if (provider.id === consent.apiProviderId) {
                apiProviderMapping.push(provider);
              }
            });
          });
          this.setState({
            providersForApis: apiProviderMapping,
            providersExist:
              apiProviderMapping && apiProviderMapping.length ? true : false,
            loading: false,
            providersLoading: false
          });
        }
      })
      .catch(error => {
        console.log('Provider not found');
      });
  };

  componentWillUpdate(nextProps) {
    if (
      !this.state.apiListUpdated &&
      nextProps.APIsRequiredForSolution &&
      nextProps.APIsRequiredForSolution.length
    ) {
      let apiIdArray = nextProps.APIsRequiredForSolution.map(
        connection => connection.api
      );
      let solutionId = this.props.solutionId ? this.props.solutionId : '';
      if (apiIdArray && solutionId) {
        this.getProvidersForApi(apiIdArray, solutionId);
      }

      let APIsRequiredForSolution = JSON.parse(
        JSON.stringify(nextProps.APIsRequiredForSolution)
      );

      this.sanitiseAPIsRequiredForSolution(APIsRequiredForSolution);

      this.setState({ apiListUpdated: true });
    }
  }

  sanitiseAPIsRequiredForSolution = connections => {
    let APIsRequiredForSolution = JSON.parse(JSON.stringify(connections));
    APIsRequiredForSolution.forEach(connection => {
      if (connection.providerId) {
        connection.selectedProvider = connection.providerId;
      }
    });
    this.setState({
      APIsRequiredForSolution: APIsRequiredForSolution
    });
  };

  callEnableConnectionMutation = async enableCmd => {
    const client = getApolloClient();
    return await client.mutate({
      mutation: enableConnectionMutation,
      variables: { enableCmd: enableCmd }
    });
  };

  callDisableConnectionMutation = async connectionId => {
    const client = getApolloClient();
    console.log('disable connectionId: ' + connectionId);
    return await client.mutate({
      mutation: disableConnectionMutation,
      variables: { connectionId: connectionId }
    });
  };

  updateConnectionInState = (connection, loading) => {
    let connectionsCopy = JSON.parse(
      JSON.stringify(this.state.APIsRequiredForSolution)
    );
    connectionsCopy.forEach(ap => {
      if (ap.api === connection.api) {
        ap.loading = loading;
      }
    });

    this.setState({
      APIsRequiredForSolution: connectionsCopy
    });
  };

  refetchData = async () => {
    this.setState({ apiListUpdated: false });
    await this.props.dataRefetch();
  };

  onEnableConnectionClick = connection => {
    let filteredProviders = this.state.providersForApis.filter(
      provider => provider.id === connection.selectedProvider
    );
    let selectedProvider =
      filteredProviders && filteredProviders.length
        ? filteredProviders[0]
        : null;
    this.setState({
      isConsentDialogOpen: true,
      selectedConnection: connection,
      selectedProvider: selectedProvider
    });
  };

  saveConsents = async (solution, consentData) => {
    const {
      auth: { accessToken }
    } = this.props;

    let filteredProviders = this.state.providersForApis.filter(
      provider => provider.id === this.state.selectedConnection.selectedProvider
    );
    let selectedProvider =
      filteredProviders && filteredProviders.length
        ? filteredProviders[0]
        : null;
    //STEP 1 - SAVING CONSENT
    const listingVersion =
      solution && solution.listingVersion ? solution.listingVersion : '1';
    let createConsentPayload = {
      documentLink:
        selectedProvider && selectedProvider.addendumDoc
          ? selectedProvider.addendumDoc
          : '',
      listingVersion: listingVersion,
      solutionVersion: solution.overview.version
        ? solution.overview.version
        : null,
      api: this.state.selectedConnection.api,
      apiProviderId: this.state.selectedConnection.selectedProvider,

      acceptedName: consentData.userName ? consentData.userName : null,
      acceptedTitle: consentData.userTitle ? consentData.userTitle : null,
      acceptedCompany: consentData.userCompany ? consentData.userCompany : null,
      reasonForRejection: consentData.rejectionReason
        ? consentData.rejectionReason
        : null,

      status: consentData.consentStatus
        ? consentData.consentStatus.toLowerCase()
        : null,
      consentType: consentTypeConst.API_DATA.toLowerCase(),
      subscriptionStatus: consentData.subscriptionStatus
        ? consentData.subscriptionStatus
        : null,
      consentedSource: ConsentTypeConstants.CONSENTED_SOURCE,
      signedTermsAndConditions: consentData.signedTermsAndConditions
        ? consentData.signedTermsAndConditions
        : null
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    };

    let consentResponse = await axios.post(
      `${environmentURLs.consentServiceBaseUrl}/solutions/${
        solution.id
      }/${listingVersion}/consents`,
      createConsentPayload,
      { headers: headers }
    );

    if (consentResponse.data) {
      consentResponse.data.signedTermsAndConditions =
        consentData.signedTermsAndConditions;
    }
    return consentResponse;
  };

  getConsentType = async consentLink => {
    try {
      const response = await axios.head(consentLink, {
        headers: {
          'Content-type': 'application/json'
        }
      });
      if (response.headers.hasOwnProperty('x-amz-meta-signature-coordinates')) {
        return 'Electronic Signature attached';
      } else {
        return 'One Click attached';
      }
    } catch (e) {
      console.error('something went wrong while getting app terms metadata!');
      return 'N/A';
    }
  };

  getAppOrgName = async entityId => {
    const client = getApolloClient();
    try {
      let response = await client.query({
        query: gql`
          query($id: ID) {
            entitiesFromPlatform(id: $id) {
              name
            }
          }
        `,
        variables: {
          id: entityId
        }
      });
      if (!response.errors && response.data.entitiesFromPlatform) {
        return response.data.entitiesFromPlatform.name;
      }
    } catch (err) {
      console.error(err);
      console.error('Entity not present!');
      return null;
    }
  };

  enableConnection = signedTermsUrl => {
    const { selectedConnection } = this.state;
    this.setState({ isConsentDialogOpen: false });
    this.updateConnectionInState(selectedConnection, true);

    this.callEnableConnectionMutation({
      connectionId: selectedConnection.connectionId,
      providerId: selectedConnection.selectedProvider
    })
      .then(async response => {
        if (!response.errors) {
          this.refetchData().then(async () => {
            const client = getApolloClient();
            try {
              const {
                auth: { accessToken }
              } = this.props;
              let response = await axios.get(
                `${environmentURLs.providerServiceURL}/admin/providers/${
                  this.state.selectedProvider.id
                }`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken ? `Bearer ${accessToken}` : ''
                  }
                }
              );
              let attachment = null;

              let fileName = null;
              fileName = this.state.selectedProvider.productName
                ? this.state.selectedProvider.productName
                : 'Integration Addendum';

              if (signedTermsUrl) {
                attachment = {
                  mimeType: 'application/pdf',
                  fileName: `${fileName}.pdf`,
                  disposition: 'attachment',
                  cid: 'pdf',
                  fileUrl: signedTermsUrl
                };
              } else {
                attachment = {
                  mimeType: 'application/pdf',
                  fileName: `${fileName}.pdf`,
                  disposition: 'attachment',
                  cid: 'pdf',
                  fileUrl: this.state.selectedProvider.termsDoc
                };
              }

              //to get the Consent Terms type of the solution
              let appTermsConsentType = 'N/A';
              if (
                this.props.subscriptionFromMarketplace &&
                this.props.subscriptionFromMarketplace.termsAndConditions
              ) {
                const termsUrl = this.props.subscriptionFromMarketplace
                  .termsAndConditions;
                appTermsConsentType = await this.getConsentType(termsUrl);
              }

              //to get the orgname of app developers entity
              let appOrganisationName = '';
              if (
                this.props.subscriptionFromMarketplace &&
                this.props.subscriptionFromMarketplace.solution &&
                this.props.subscriptionFromMarketplace.solution.orgId
              ) {
                const appEntityId = this.props.subscriptionFromMarketplace
                  .solution.orgId;
                appOrganisationName = await this.getAppOrgName(appEntityId);
              }
              await client.mutate({
                mutation: providerEmailForApiActivation,
                variables: {
                  to: [
                    {
                      name: response.data.supportEmail,
                      email: response.data.supportEmail
                    }
                  ],
                  emailSubject:
                    'Action Required: Your API has a Pending Activation Request',
                  subscriberOrganization: this.props.entity.name,
                  emailType: 'apiProviderEmailForActivatingApi',
                  entityId: this.props.entity.id,
                  subscriberName: this.props.entity.name,
                  firstName: this.props.auth.userData.name.split(' ')[0],
                  subscriptionId: this.props.subscriptionFromMarketplace
                    .subscriptionId,
                  buyerEmail: this.props.auth.userData.preferred_username,
                  cmf: 'N/A',
                  appName: this.props.subscriptionFromMarketplace.solution
                    .overview.name,
                  appId: this.props.subscriptionFromMarketplace.solution.id,
                  appOrgName: appOrganisationName,
                  appPublisherName: this.props.subscriptionFromMarketplace
                    .solution.overview.publisherName,
                  appOrgId: this.props.subscriptionFromMarketplace.solution
                    .orgId,
                  appTermsConsentType: appTermsConsentType, //Value taken from earlier..
                  apis: [
                    {
                      apiName: this.state.selectedConnection.apiDisplayName,
                      apiConsentType: !!signedTermsUrl
                        ? 'Electronic Signature attached'
                        : 'One Click',
                      apiImplementationName: this.state.selectedProvider
                        .productName
                        ? this.state.selectedProvider.productName
                        : 'Product Name'
                    }
                  ],
                  attachments: !!signedTermsUrl ? attachment : null //only send the attachment when it's of electronic consent type. otherwise don't send.
                }
              });

              console.log('Calling internalBuyerEnabledAnApi!');

              let internalEmailForConnectionActivationVars = {
                emailSubject: 'API connection activation request',
                emailType: 'internalBuyerEnabledAnApi',
                currentdate: moment().format('DD MM YYYY'),
                subscriberOrganization: this.props.entity.name,
                entityId: this.props.entity.id,
                cmf: null,
                subscriberName: this.props.auth.userData.name,
                buyerEmail: this.props.auth.userData.email,
                buyerName: this.props.auth.userData.name,
                appName: !!this.props.subscriptionFromMarketplace.solution
                  .overview.name
                  ? this.props.subscriptionFromMarketplace.solution.overview
                      .name
                  : '',
                appId: this.props.solutionId,
                appOrgName: appOrganisationName, //Value taken from earlier..
                appOrgId: !!this.props.subscriptionFromMarketplace.solution
                  .orgId
                  ? this.props.subscriptionFromMarketplace.solution.orgId
                  : '',
                appTermsConsentType: appTermsConsentType, //Value taken from earlier..
                subscriptionId:
                  this.props.subscriptionFromMarketplace.subscriptionId || '',
                apis: [
                  {
                    apiName: !!this.state.selectedProvider.api
                      ? this.state.selectedProvider.api
                      : '',
                    apiOrgName: !!this.state.selectedProvider.name
                      ? this.state.selectedProvider.name
                      : '',
                    apiOrgId: !!this.state.selectedProvider.entityId
                      ? this.state.selectedProvider.entityId
                      : '',
                    apiConsentType: !!signedTermsUrl
                      ? 'Electronic Signature attached'
                      : 'One Click attached',
                    apiImplementationName: this.state.selectedProvider
                      .productName
                      ? this.state.selectedProvider.productName
                      : this.state.selectedProvider.api
                  }
                ],
                attachments: attachment
              };

              await client.mutate({
                mutation: internalBuyerEnabledAnApi,
                variables: internalEmailForConnectionActivationVars
              });
            } catch (err) {
              //handle error??
              console.log('Error in sending the email', err);
            } finally {
              this.updateConnectionInState(selectedConnection, false);
            }
          });
        }
      })
      .catch(error => {
        this.updateConnectionInState(selectedConnection, false);
        this.setState({ failedToEnableConnection: true });
      });
  };

  onConsentResponse = async data => {
    const {
      subscriptionFromMarketplace: { solution }
    } = this.props;
    const { selectedConnection } = this.state;
    this.setState({ isConsentDialogOpen: false });
    if (data) {
      if (data.consentStatus === consentStatusConst.ACCEPTED) {
        //SAVE CONSENT ACCEPTANCE, call enable connection mutation
        this.setState({ savingConsentAcceptance: true });
        this.updateConnectionInState(selectedConnection, true);
        let consentResponse = await this.saveConsents(solution, data);
        if (consentResponse && consentResponse.status === 200) {
          await this.enableConnection(
            consentResponse.data.signedTermsAndConditions
          );
          this.setState({ savingConsentAcceptance: false });
        }
      } else {
        //SAVE CONSENT REJECTION
        this.setState({ savingConsentRejection: true });
        let consentResponse = await this.saveConsents(solution, data);
        if (consentResponse && consentResponse.status === 200) {
          this.setState({ isConsentRejectionSaved: true });
        }
        this.setState({ savingConsentRejection: false });
      }
    }
  };

  render() {
    const {
      loading,
      solutionId,
      entity,
      subscriptionFromMarketplace,
      subscriberEntityId,
      auth: { userData }
    } = this.props;
    const {
      entityFromPlatform,
      providersForApis,
      selectedProvider
    } = this.state;

    if (entity && subscriberEntityId !== entity.id && !loading) {
      return <NotFound />;
    }

    let solution;
    if (subscriptionFromMarketplace) {
      solution = subscriptionFromMarketplace.solution;
    }
    return (
      <div className="component-content">
        <div
          className="max-width-resp page-padding-common"
          style={{ minHeight: '300px', padding: '0px 24px 100px 24px' }}
        >
          {!entity ||
          !entity.id ||
          entity.id === FortellisConstants.DEFAULT_ORG_ID ||
          entity.id === FortellisConstants.PERSONAL_ACCOUNT ? (
            <EntityRegistrationConfirmation isPopup={false} />
          ) : loading || !entityFromPlatform ? (
            <div>
              <Spinner />
              <div style={{ textAlign: 'center' }}>
                {' '}
                <strong>Loading...</strong>
              </div>
            </div>
          ) : (
            <div>
              <div className="page-title">Configure APIs</div>
              <SolutionStripForApiConfig
                setRegistrationDetails={this.setRegistrationDetails.bind(this)}
                solutionId={solutionId}
                entity={this.state.entityFromPlatform}
                entityId={entity.id}
              />
              <div className="api-config-description">
                To activate this app, select the provider you want to use for
                each API. Be sure select API providers compatible with your
                current software systems. Please contact app provider if your
                preferred API provider is not in the list.
                <div className="api-config-description">
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    NOTE:
                  </span>{' '}
                  If connection is activated you can not perform any action.
                </div>
              </div>
              <Card>
                <DataTable>
                  {!this.state.providersLoading ? (
                    this.state.providersExist ? (
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell
                            className="apiconfig-table-header"
                            nonNumeric
                          >
                            API Name
                          </DataTableHeaderCell>
                          <DataTableHeaderCell
                            className="apiconfig-table-header"
                            nonNumeric
                          >
                            Provider
                          </DataTableHeaderCell>
                          <DataTableHeaderCell
                            className="apiconfig-table-header"
                            nonNumeric
                          >
                            Action
                          </DataTableHeaderCell>
                          <DataTableHeaderCell
                            className="apiconfig-table-header"
                            nonNumeric
                            style={{ textAlign: 'right' }}
                          >
                            Status
                          </DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                    ) : (
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell
                            className="apiconfig-table-header"
                            nonNumeric
                          >
                            There are no api providers for this app
                          </DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                    )
                  ) : null}
                  <DataTableBody>
                    {this.state.APIsRequiredForSolution &&
                      this.state.APIsRequiredForSolution.sort((a, b) => {
                        var x =
                          a && a.apiDisplayName
                            ? a.apiDisplayName.toLowerCase()
                            : undefined;
                        var y =
                          b && b.apiDisplayName
                            ? b.apiDisplayName.toLowerCase()
                            : undefined;
                        if (x > y) return 1;
                        if (x < y) return -1;
                        return 0;
                      }).map(connection => {
                        let isOptionalAPI = false;
                        if (
                          this.state.registrationDetails &&
                          this.state.registrationDetails[connection.api] &&
                          this.state.registrationDetails[connection.api]
                            .isOptional
                        ) {
                          isOptionalAPI = true;
                        }
                        const providers =
                          providersForApis &&
                          providersForApis.filter(
                            provider => provider.api === connection.api
                          );
                        return (
                          providers &&
                          providers.length > 0 && (
                            <DataTableRow key={connection.api}>
                              <DataTableCell nonNumeric>
                                {connection && connection.apiDisplayName
                                  ? connection.apiDisplayName
                                  : 'NA'}
                              </DataTableCell>
                              <DataTableCell nonNumeric>
                                <Select
                                  disabled={
                                    connection.status ===
                                      ApiConnectionStatusConstants.PENDING ||
                                    connection.status ===
                                      ApiConnectionStatusConstants.ACTIVE
                                  }
                                  label="Select Provider"
                                  value={connection.selectedProvider}
                                  className="api-config-select"
                                  id="api-config-select"
                                  onChange={e => {
                                    let connectionsCopy = JSON.parse(
                                      JSON.stringify(
                                        this.state.APIsRequiredForSolution
                                      )
                                    );
                                    connectionsCopy.forEach(ap => {
                                      if (ap.api === connection.api) {
                                        ap.selectedProvider = e.target.value;
                                      }
                                    });

                                    this.setState({
                                      APIsRequiredForSolution: connectionsCopy
                                    });
                                  }}
                                >
                                  {providers.map(provider => (
                                    <option
                                      key={provider.id}
                                      value={provider.id}
                                    >
                                      {`${provider.name}`}
                                    </option>
                                  ))}
                                </Select>
                              </DataTableCell>
                              <DataTableCell nonNumeric>
                                {connection.status ===
                                ApiConnectionStatusConstants.INACTIVE ? (
                                  <Button
                                    disabled={
                                      connection.loading ||
                                      !connection.selectedProvider
                                    }
                                    outlined={true}
                                    id="enableApi"
                                    onClick={() => {
                                      this.onEnableConnectionClick(connection);
                                    }}
                                  >
                                    <FormattedMessage
                                      id="ApiConfigForSolution.button.enable"
                                      defaultMessage="Enable"
                                    />
                                  </Button>
                                ) : connection.status ===
                                  ApiConnectionStatusConstants.PENDING ? (
                                  <Button
                                    outlined={true}
                                    disabled={connection.loading}
                                    onClick={() => {
                                      this.updateConnectionInState(
                                        connection,
                                        true
                                      );
                                      this.callDisableConnectionMutation(
                                        connection.connectionId
                                      )
                                        .then(response => {
                                          if (!response.errors) {
                                            this.refetchData().then(() =>
                                              this.updateConnectionInState(
                                                connection,
                                                false
                                              )
                                            );
                                          }
                                        })
                                        .catch(err => {
                                          this.updateConnectionInState(
                                            connection,
                                            false
                                          );
                                        });
                                    }}
                                    id="cancelApi"
                                    className={this.props.customClassName}
                                  >
                                    <FormattedMessage
                                      id="ApiConfigForSolution.button.cancel"
                                      defaultMessage="Cancel"
                                    />
                                  </Button>
                                ) : connection.status ===
                                  ApiConnectionStatusConstants.ACTIVE ? (
                                  <span>NA</span>
                                ) : (
                                  <Button
                                    outlined={true}
                                    disabled={connection.loading}
                                    onClick={() => {
                                      this.updateConnectionInState(
                                        connection,
                                        true
                                      );
                                      this.callDisableConnectionMutation(
                                        connection.connectionId
                                      ).then(response => {
                                        if (!response.errors) {
                                          this.refetchData().then(() =>
                                            this.updateConnectionInState(
                                              connection,
                                              false
                                            )
                                          );
                                        }
                                      });
                                    }}
                                    style={{ display: 'none' }}
                                    id="disableApi"
                                    className={this.props.customClassName}
                                  >
                                    <FormattedMessage
                                      id="ApiConfigForSolution.button.disable"
                                      defaultMessage="Disable"
                                    />
                                  </Button>
                                )}
                              </DataTableCell>
                              <DataTableCell
                                className="uppercase_text"
                                style={{ textAlign: 'right' }}
                                nonNumeric
                              >
                                {connection.status ===
                                ApiConnectionStatusConstants.INACTIVE ? (
                                  <span>
                                    {connection.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        Inactive
                                        <StatusIndicatorInline
                                          type="inactive"
                                          className="api-enablement-status-indicator"
                                        />
                                      </span>
                                    )}
                                  </span>
                                ) : connection.status ===
                                  ApiConnectionStatusConstants.PENDING ? (
                                  <span>
                                    {connection.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        Pending Approval
                                        <StatusIndicatorInline
                                          type="warning"
                                          className="api-enablement-status-indicator"
                                        />
                                      </span>
                                    )}
                                  </span>
                                ) : connection.status ===
                                  ApiConnectionStatusConstants.ACTIVE ? (
                                  <span>
                                    {connection.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        Active
                                        <StatusIndicatorInline
                                          type="positive"
                                          className="api-enablement-status-indicator"
                                        />
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    {connection.loading ? (
                                      <Spinner />
                                    ) : (
                                      <span>
                                        Declined
                                        <StatusIndicatorInline
                                          type="negative"
                                          className="api-enablement-status-indicator"
                                        />
                                      </span>
                                    )}
                                  </span>
                                )}
                              </DataTableCell>
                            </DataTableRow>
                          )
                        );
                      })}
                  </DataTableBody>
                </DataTable>
              </Card>
            </div>
          )}
        </div>

        {this.state.isConsentDialogOpen &&
          (selectedProvider && selectedProvider.addendumDoc ? (
            <UserConsentDialog
              open={this.state.isConsentDialogOpen}
              title={'Data Integration Addendum'}
              description={() => (
                <div>
                  Please review and accept the addendum to Master Service
                  Agreement for&nbsp;
                  <strong>
                    {selectedProvider && selectedProvider.productName
                      ? selectedProvider.productName
                      : 'Provider'}
                  </strong>
                </div>
              )}
              submitButtonName="Submit"
              cancelButtonName="Cancel"
              acceptMessage="I accept the addendum to terms"
              rejectMessage="I reject the addendum to terms for"
              scrollInstruction="You need to scroll to bottom to accept the addendum to terms."
              preFilledName={userData.name}
              onSubmit={data => {
                this.onConsentResponse(data);
              }}
              onCancel={error => {
                this.setState({
                  isConsentDialogOpen: false,
                  consentError: error || false
                });
              }}
              consentLink={
                selectedProvider && selectedProvider.addendumDoc
                  ? selectedProvider.addendumDoc
                  : 'https://www.termsfeed.com/blog/wp-content/uploads/2019/04/terms-and-conditions-template.pdf'
              }
              provider={selectedProvider}
              type={ADDENDUM}
              entity={this.props.entity}
            />
          ) : (
            <FortellisDialog
              title={'Data Integration Confirmation'}
              message={`This action will allow
                      ${
                        solution && solution.overview && solution.overview.name
                          ? solution.overview.name
                          : 'the App'
                      }
                      to access
                      ${
                        selectedProvider && selectedProvider.api
                          ? selectedProvider.api
                          : 'the API'
                      }
                      via
                      ${
                        selectedProvider && selectedProvider.productName
                          ? selectedProvider.productName
                          : 'the selected Provider'
                      }.
                      Do you want to proceed?`}
              open={this.state.isConsentDialogOpen}
              acceptButtonName={'Continue'}
              cancelButtonName={'Cancel'}
              onAccept={this.enableConnection}
              onCancel={() => {
                this.setState({ isConsentDialogOpen: false });
              }}
            />
          ))}

        {this.state.consentError && (
          <ActionSnackBar
            show={true}
            message="Unable to determine consent type"
            close={() => {
              this.setState({ consentError: false });
            }}
          />
        )}

        {this.state.isConsentRejectionSaved && (
          <ActionSnackBar
            show={this.state.isConsentRejectionSaved}
            message="Consent Rejected"
            close={() => {
              this.setState({ isConsentRejectionSaved: false });
            }}
          />
        )}

        {this.state.failedToEnableConnection && (
          <ActionSnackBar
            show={this.state.failedToEnableConnection}
            message="Enable connection failed. Please contact the app publisher."
            close={() => {
              this.setState({ failedToEnableConnection: false });
            }}
          />
        )}

        {this.state.savingConsentRejection ? (
          <FortellisDialogSpinner open={this.state.savingConsentRejection} />
        ) : null}
        {this.state.savingConsentAcceptance ? (
          <FortellisDialogSpinner open={this.state.savingConsentAcceptance} />
        ) : null}
      </div>
    );
  }
}

const enableConnectionMutation = gql`
  mutation($enableCmd: enableConnectionCmd) {
    enableConnection(command: $enableCmd) {
      connectionId
      providerId
      api
      subscriptionId
      entityId
      solutionId
      status
    }
  }
`;

const providerEmailForApiActivation = gql`
  mutation(
    $to: [csmsgEmailInput]
    $emailSubject: String!
    $subscriberOrganization: String
    $emailType: String!
    $entityId: String
    $subscriberName: String
    $subscriptionId: String
    $buyerEmail: String
    $appName: String
    $appOrgName: String
    $appOrgId: String
    $appTermsConsentType: String
    $buyerName: String
    $apis: [apiDetailCmd]
    $attachments: [csmsgEmailAttachmentInput]
    $firstName: String!
    $appPublisherName: String
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        subject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          appDetails: {
            appName: $appName
            appOrgName: $appOrgName
            appOrgId: $appOrgId
            appTermsConsentType: $appTermsConsentType
            appPublisherName: $appPublisherName
          }
          apiDetails: $apis
          subscriptionDetails: {
            buyerName: $buyerName
            subscriberOrganization: $subscriberOrganization
            entityId: $entityId
            subscriberName: $subscriberName
            buyerEmail: $buyerEmail
            subscriptionId: $subscriptionId
          }
        }
        attachments: $attachments
      }
    ) {
      emailResponse
    }
  }
`;

const disableConnectionMutation = gql`
  mutation($connectionId: ID) {
    disableConnection(connectionId: $connectionId) {
      connectionId
      providerId
      api
      subscriptionId
      entityId
      solutionId
      status
    }
  }
`;

const providersForApis = gql`
  query($provides: [ID], $solutionId: ID) {
    providers(provides: $provides) {
      id
      entityId
      name
      api
      status
      productName
      termsDoc
      addendumDoc
      addendum {
        coordinates {
          signatureCoordinates {
            page
            x
            y
          }
        }
        docSize {
          height
          width
        }
      }
    }
    solutionCatalogList(id: $solutionId) {
      id
      orgId
      listingVersion
      developerId
      listingVersion
      developerName
      status
      overview {
        name
        category
        applicationArea
        version
        briefDescription
        detailedDescription
        publisherName
        privacyPolicyUrl
        termsAndConditionsUrl
        solutionLogo
      }
    }
  }
`;

const solutionDetails = gql`
  query($solutionId: ID) {
    solutionList(id: $solutionId) {
      id
      listingVersion
      orgId
      status
    }
  }
`;

const internalBuyerEnabledAnApi = gql`
  mutation(
    $emailSubject: String!
    $subscriberOrganization: String
    $emailType: String!
    $entityId: String
    $subscriberName: String
    $buyerEmail: String
    $subscriptionId: String
    $appName: String
    $appId: String
    $appOrgName: String
    $appOrgId: String
    $appTermsConsentType: String
    $buyerName: String
    $apis: [apiDetailCmd]
    $attachments: [csmsgEmailAttachmentInput]
  ) {
    sendEmailCSMG(
      command: {
        subject: $emailSubject
        withTemplate: {
          emailType: $emailType
          appDetails: {
            appName: $appName
            appId: $appId
            appOrgName: $appOrgName
            appOrgId: $appOrgId
            appTermsConsentType: $appTermsConsentType
          }
          apiDetails: $apis
          subscriptionDetails: {
            buyerName: $buyerName
            subscriberOrganization: $subscriberOrganization
            entityId: $entityId
            subscriptionId: $subscriptionId
            subscriberName: $subscriberName
            buyerEmail: $buyerEmail
          }
        }
        attachments: $attachments
      }
    ) {
      emailResponse
    }
  }
`;

export default withApollo(injectIntl(withAuth(ApiConfigForSolution)));
