import React from "react";
import ReactJson from "react-json-view";

export class FormJsonTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.reactJsonProps = {
      src: this.props.fieldProperties.src,
      theme: "rjv-default",
      name: null,
      displayDataTypes: false,
      displayObjectSize: false,
      enableClipboard: false,
      style: {
        fontFamily: "Roboto",
        fontSize: "12px",
        height: "200px",
        overflow: "auto"
      }
    };
  }

  render() {
    return <ReactJson {...this.reactJsonProps} />;
  }
}

export default FormJsonTextArea;
