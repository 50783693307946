import React, { Component } from 'react';
import { SecureRoute } from '@okta/okta-react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import UnauthorizedPage from './UnauthorizedPage';
import Spinner from './Spinner';

class CustomPrivateRoute extends Component {
  render() {
    window.scrollTo(0, 0);

    const {
      auth: { isAuthenticated },
      isAccessible,
      isPermissionsReceived
    } = this.props;

    return isAuthenticated ? (
      isPermissionsReceived ? (
        isAccessible ? (
          //pass all props passed from CustomPrivateRoute
          <SecureRoute {...this.props} />
        ) : (
          <div className="unauthorized-page-container">
            <UnauthorizedPage />
          </div>
        )
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )
    ) : (
      //redirects to login if not authenticated
      <SecureRoute {...this.props} />
    );
    //redirects to login if not authenticated
  }
}

export default withAuth(CustomPrivateRoute);
