import React from 'react';
import moment from 'moment';

import Rating from './Rating';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { BUTTON_VARIANTS, Dialog } from 'cdk-radial';

class Review extends React.Component {
  state = {
    deleteConfirmationOpen: false,
    reviewText: '',
    readMoreClicked: false,
    readText: 'Read more',
    readMoreFlag: false
  };

  componentDidMount() {
    if (
      this.props.review &&
      this.props.review.reviewText &&
      this.props.review.reviewText.length > this.props.charLimit
    ) {
      this.setState({
        reviewText:
          this.props.review.reviewText.substring(0, this.props.charLimit) +
          '...  ',
        readMoreFlag: true
      });
    } else {
      this.setState({ reviewText: this.props.review.reviewText });
    }
  }

  onReadMore = () => {
    if (this.state.readMoreClicked && this.props.review.reviewText) {
      this.setState({
        readText: 'Read more',
        reviewText:
          this.props.review.reviewText.substring(0, this.props.charLimit) +
          '...  '
      });
    } else {
      this.setState({
        readText: 'Read less',
        reviewText: this.props.review.reviewText
      });
    }
    this.setState({ readMoreClicked: !this.state.readMoreClicked });
  };

  render() {
    const { props = {} } = this;
    const {
      review = {},
      deleteReview,
      deleteReviewFromParent,
      isPreview,
      auth = {}
    } = props;
    const { isAuthenticated = false, userData = {} } = auth;
    return (
      <div className="review-container">
        <div style={{ marginBottom: '8px' }}>
          <Rating
            rating={review.rating}
            disabled={true}
            customClass="review-rating"
          />
          <div className="reviewer-mail">{review.authorName} </div>
          {'   '}
          <div className="review-date">
            {moment(review.createdDate).format('DD MMM YYYY')}
          </div>
          {isAuthenticated &&
            userData &&
            review.authorEmail === userData.email &&
            !isPreview && (
              <span
                onClick={() => this.setState({ deleteConfirmationOpen: true })}
                className="delete-review-icon"
              >
                <i className="material-icons">delete</i>
              </span>
            )}
        </div>
        <div className="review-headline">{review.reviewTitle}</div>
        <div className="review-desc">
          {this.state.reviewText}
          {this.state.readMoreFlag ? (
            <span
              onClick={this.onReadMore}
              className="review__read-more"
              id="review__read-more"
            >
              {this.state.readText}
            </span>
          ) : (
            false
          )}
        </div>

        <Dialog
          buttonsProps={[
            {
              id: 'dialog-cancel-button',
              onClick: () => {
                this.setState({ deleteConfirmationOpen: false });
              },
              text: 'CANCEL',
              variant: BUTTON_VARIANTS.TEXT
            },
            {
              id: 'dialog-delete-button',
              onClick: () =>
                deleteReview.execute(review.id).then(() => {
                  deleteReviewFromParent(review.id);
                  this.setState({ deleteConfirmationOpen: false });
                }),
              text: 'DELETE',
              variant: BUTTON_VARIANTS.PRIMARY
            }
          ]}
          id="delete-dialog"
          isOpen={this.state.deleteConfirmationOpen}
          hideHeader
        >
          <div>
            <span className="review-delete-subheading">Delete review?</span>
            <span className="review-delete-text">
              Your review will be permanently removed from the site.
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withAuth(Review);
export { Review as PureReview }; // pure component. used in tests
