import React from "react";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import Spinner from "../common/Spinner";

class LoadingSpinner extends React.Component {
  render() {
    return (
      <LayoutGrid className="max-width-resp fm-solution-form-content">
        <LayoutGridCell span={12} className="save-loading-spinner">
          <Spinner />
        </LayoutGridCell>
      </LayoutGrid>
    );
  }
}

export default LoadingSpinner;
