import FortellisConstants from './FortellisConstants';

class SolutionTypeURLMapperUtil {
  static getSolutionTypeURL(solutionType) {
    switch (solutionType) {
      case FortellisConstants.FORTELLIS:
        return FortellisConstants.FORTELLIS_TYPE_URL;
      case FortellisConstants.PARTNER_PROGRAM:
        return FortellisConstants.PARTNER_PROGRAM_TYPE_URL;
      default:
        return FortellisConstants.FORTELLIS_TYPE_URL;
    }
  }

  static getSolutionTypeName(solutionTypeUrl) {
    switch (solutionTypeUrl) {
      case FortellisConstants.FORTELLIS_TYPE_URL:
        return FortellisConstants.FORTELLIS;
      case FortellisConstants.PARTNER_PROGRAM_TYPE_URL:
        return FortellisConstants.PARTNER_PROGRAM;
      default:
        //do nothing
        return FortellisConstants.FORTELLIS;
    }
  }

  static getSolutionFormURL(type, id, listingVersion) {
    if (id && !listingVersion) {
      return `/marketplace-account/mysolutions/list/${
        type
          ? SolutionTypeURLMapperUtil.getSolutionTypeURL(type)
          : FortellisConstants.FORTELLIS_TYPE_URL
      }/${id}`;
    } else if (id && listingVersion) {
      return `/marketplace-account/mysolutions/list/${
        type
          ? SolutionTypeURLMapperUtil.getSolutionTypeURL(type)
          : FortellisConstants.FORTELLIS_TYPE_URL
      }/${id}/${listingVersion}`;
    }
  }

  static getSolutionListingURL(type, id, activeMenuId) {
    return `/marketplace-account/mysolutions/${
      type
        ? SolutionTypeURLMapperUtil.getSolutionTypeURL(type)
        : FortellisConstants.FORTELLIS_TYPE_URL
    }/${id}/solution-management/${activeMenuId}`;
  }

  static getSolutionListingDetailsURL(type, id, listingVersion, activeMenuId) {
    return `/marketplace-account/mysolutions/${
      type
        ? SolutionTypeURLMapperUtil.getSolutionTypeURL(type)
        : FortellisConstants.FORTELLIS_TYPE_URL
    }/${id}/solution-management/${activeMenuId}/${listingVersion}`;
  }

  static getSolutionPreviewURL(type, id, listingVersion) {
    return `/marketplace-account/mysolutions/${
      type
        ? SolutionTypeURLMapperUtil.getSolutionTypeURL(type)
        : FortellisConstants.FORTELLIS_TYPE_URL
    }/${id}/solution-management/${listingVersion}/preview`;
  }
}

export default SolutionTypeURLMapperUtil;
