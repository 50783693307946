import React, { Component } from 'react';
import AppActivationHeader from './app-activation-items/AppActivationHeader';
import AppActivationSubscriptions from './app-activation-items/AppActivationSubscriptions';
import AppSubscriptionsConstants from '../../../../common/AppSubscriptions';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import moment from 'moment';
const SUBSCRIBED = 'subscribed';
const UNSUBSCRIBED = 'unsubscribed';

class AppActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appSubscribers: []
    };
  }

  async componentDidMount() {
    await this.fetchSolutionSubscriptions();
  }

  Id() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  fetchSolutionSubscriptions = async () => {
    const { solutionId, publishedSolution } = this.props;
    const client = getApolloClient();
    this.setState({ loading: true });
    let getMetadataSubscriptionsResponse = await client.query({
      query: getSubscriptionDetails,
      variables: {
        solutionId: solutionId,
        randomId: this.Id()
      }
    });
    const subscriptions =
      getMetadataSubscriptionsResponse.data.getMetadataSubscriptions;
    let subscribedRecords = [];
    if (
      publishedSolution &&
      publishedSolution.app_subscriptions &&
      publishedSolution.app_subscriptions.appSubscriptionsType
    ) {
      if (
        publishedSolution.app_subscriptions.appSubscriptionsType === 'Manual'
      ) {
        subscribedRecords =
          subscriptions &&
          subscriptions.filter(
            subscription =>
              subscription.appSubscriptionActivationType ===
                AppSubscriptionsConstants.MANUAL &&
              (subscription.status !== UNSUBSCRIBED ||
                (subscription.appSubscriptionActivationState === 'Pending' &&
                  subscription.requested_status === 'subscribe' &&
                  subscription.status === UNSUBSCRIBED))
          );
      } else {
        subscribedRecords =
          subscriptions &&
          subscriptions.filter(
            subscription =>
              subscription.appSubscriptionActivationType ===
                AppSubscriptionsConstants.AUTOMATIC &&
              subscription.status === SUBSCRIBED
          );
      }
    } else {
      subscribedRecords =
        subscriptions &&
        subscriptions.filter(
          subscription => subscription.status === SUBSCRIBED
        );
    }
    const entityIds =
      subscribedRecords &&
      subscribedRecords.map(subscription => subscription.entityId);
    let entityResponse = await client.query({
      query: getEntitiesQuery,
      variables: { id: entityIds }
    });
    const entityNames = entityResponse.data.subscribedEntitiesFromPlatfom;
    const subscriptionRecordsWithEntityNames = [];
    if (entityNames && entityNames.length) {
      subscribedRecords &&
        subscribedRecords.forEach(subscription => {
          const record = entityNames.find(
            ({ id }) => id === subscription.entityId
          );
          let dateCreated = moment(subscription.createdOn).format(
            'MMM D, YYYY'
          );
          if (record && record.name)
            subscriptionRecordsWithEntityNames.push({
              ...subscription,
              name: record.name,
              createdOn: dateCreated
            });
        });
    }
    this.setState({
      appSubscribers: subscriptionRecordsWithEntityNames,
      loading: false
    });
    this.props.refreshPendingAppActivations();
  };

  render() {
    const { loading, appSubscribers } = this.state;
    const { solution } = this.props;
    return (
      <div>
        <AppActivationHeader {...this.props} />
        <AppActivationSubscriptions
          {...this.props}
          appSubscribers={appSubscribers}
          loading={loading}
          solution={solution}
          fetchSolutionSubscriptions={this.fetchSolutionSubscriptions}
        />
      </div>
    );
  }
}

const getEntitiesQuery = gql`
  query($id: [ID]) {
    subscribedEntitiesFromPlatfom(id: $id) {
      id
      name
      website
      phone
      type
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
    }
  }
`;

const getSubscriptionDetails = gql`
  query($solutionId: ID, $randomId: ID) {
    getMetadataSubscriptions(solutionId: $solutionId, randomId: $randomId) {
      id
      createdOn
      entityId
      appSubscriptionActivationType
      appSubscriptionActivationState
      status
      firstName
      lastName
      selectedPlanId
      email
      reasonForRejection
      requested_status
      emailsForSubscriptionNotifications
    }
  }
`;

export default AppActivation;
