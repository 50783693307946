import React, { useEffect, useState } from 'react';
import { TextField } from '@cdk-uip/react-text-field';
import { Radio } from '@cdk-uip/react-radio';
import FormCaption from '../../solution-submission/common-form-api/FormComponents/FormCaption';
import CONSTANTS from './AppListingFeeConstants';

const errorMessage = 'Enter valid price > 0';

function FormCaptionFormField(props) {
  return (
    <div className="formFields">
      <div className="layoutcell layoutcellRadio">
        <div className="layoutcell layoutcellRadio">
          <FormCaption title={props.title} style={props.style} />
        </div>
      </div>
    </div>
  );
}

function NoFeeControl({
  listingFeeOption,
  handleRadioChange,
  isDisabled,
  address
}) {
  const countryCode = address?.countryCode;
  return (
    <>
      <div className="formFields">
        <div className="layoutcell">
          <div className="layoutcell">
            <div>
              <Radio
                className="radio-item-radio"
                checked={listingFeeOption === CONSTANTS.Options.NO_FEE}
                disabled={isDisabled}
                onChange={event => {
                  handleRadioChange(
                    event,
                    CONSTANTS.Options.NO_FEE,
                    countryCode
                  );
                }}
              />
              <label className="radio-item-label">
                {CONSTANTS.Labels.NO_FEE}
              </label>
            </div>
          </div>
        </div>
        <FormCaptionFormField title={CONSTANTS.Captions.NO_FEE} />
      </div>
    </>
  );
}

function DefaultFeeControl({
  listingFeeOption,
  handleRadioChange,
  isDisabled,
  address
}) {
  const countryCode = address?.countryCode;
  return (
    <div className="formFields">
      <div className="layoutcell">
        <div className="layoutcell">
          <div>
            <Radio
              className="radio-item-radio"
              checked={listingFeeOption === CONSTANTS.Options.DEFAULT}
              disabled={isDisabled}
              onChange={event => {
                handleRadioChange(
                  event,
                  CONSTANTS.Options.DEFAULT,
                  countryCode
                );
              }}
            />
            <label className="radio-item-label">
              {countryCode?.toLowerCase() === 'us' && CONSTANTS.Labels.DEFAULT}
              {countryCode?.toLowerCase() === 'ca' &&
                CONSTANTS.Labels.CAD_DEFAULT}
            </label>
          </div>
        </div>
      </div>
      {countryCode?.toLowerCase() === 'us' && (
        <FormCaptionFormField title={CONSTANTS.Captions.DEFAULT} />
      )}
      {countryCode?.toLowerCase() === 'ca' && (
        <FormCaptionFormField title={CONSTANTS.Captions.CAD_DEFAULT} />
      )}
    </div>
  );
}

function CustomFeeControl({
  listingFeeOption,
  handleRadioChange,
  isDisabled,
  appPublishingFee,
  handleInputChange,
  error,
  address
}) {
  const countryCode = address?.countryCode;
  return (
    <>
      <div className="formFields">
        <div className="layoutcell">
          <div className="layoutcell">
            <div>
              <Radio
                className="radio-item-radio"
                checked={listingFeeOption === CONSTANTS.Options.CUSTOM}
                disabled={isDisabled}
                onChange={event => {
                  handleRadioChange(
                    event,
                    CONSTANTS.Options.CUSTOM,
                    countryCode
                  );
                }}
              />
              <label className="radio-item-label">
                {CONSTANTS.Labels.CUSTOM}
              </label>
            </div>
          </div>
        </div>
        <FormCaptionFormField title={CONSTANTS.Captions.CUSTOM} />
      </div>
      {listingFeeOption === CONSTANTS.Options.CUSTOM && (
        <div className="admin-control-additional-info-container">
          <div className="catalog-number-info-container">
            <div className="catalog-number-textfield">
              <TextField
                prefix={'$ '}
                value={appPublishingFee}
                disabled={isDisabled}
                onChange={handleInputChange}
              />
              {error && (
                <div className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function AdminControlListingFee(props) {
  const {
    status,
    isCdkApp = false,
    listingFeeWaived = false
  } = props.formStaticValues;
  const [address, setAddress] = useState(props?.formFieldValuesAll?.address);
  const [listingFeeOption, setListingFeeOption] = useState();
  const [appPublishingFee, setAppPublishingFee] = useState(
    props.formFieldValues.appPublishingFee
  );
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    (status !== 'In Review' && status !== 'Approved') || listingFeeWaived
  );

  const handleRadioChange = (e, option, countryCode) => {
    setListingFeeOption(option);
    const formFieldValues = props.formFieldValues;
    if (option === CONSTANTS.Options.NO_FEE) {
      setAppPublishingFee(CONSTANTS.ListingFee.NO_FEE);
      formFieldValues.appPublishingFee = CONSTANTS.ListingFee.NO_FEE;
    } else if (option === CONSTANTS.Options.DEFAULT) {
      let listing_fee_amount =
        countryCode === 'US'
          ? CONSTANTS.ListingFee.DEFAULT
          : countryCode === 'CA'
          ? CONSTANTS.ListingFee.CAD_DEFAULT
          : '';
      setAppPublishingFee(listing_fee_amount);
      formFieldValues.appPublishingFee = listing_fee_amount;
    } else {
      setAppPublishingFee('');
      formFieldValues.appPublishingFee = '';
    }
  };

  const handleInputChange = event => {
    const re = /^\d*(\.\d{0,2})?$/;
    if (re.test(event.target.value)) {
      props.formFieldValues.appPublishingFee = event.target.value;
      setAppPublishingFee(event.target.value);
    }
  };

  useEffect(() => {
    const listingFee = props.formFieldValues.appPublishingFee;
    const countryCode = address?.countryCode?.toLowerCase();
    if (listingFee) {
      if (parseFloat(listingFee) === parseFloat(CONSTANTS.ListingFee.NO_FEE))
        setListingFeeOption(CONSTANTS.Options.NO_FEE);
      else if (
        parseFloat(listingFee) === parseFloat(CONSTANTS.ListingFee.DEFAULT) &&
        countryCode === 'us'
      )
        setListingFeeOption(CONSTANTS.Options.DEFAULT);
      else if (
        parseFloat(listingFee) ===
          parseFloat(CONSTANTS.ListingFee.CAD_DEFAULT) &&
        countryCode === 'ca'
      )
        setListingFeeOption(CONSTANTS.Options.DEFAULT);
      else setListingFeeOption(CONSTANTS.Options.CUSTOM);
    } else {
      if (isCdkApp || isDisabled) {
        props.formFieldValues.appPublishingFee = CONSTANTS.ListingFee.NO_FEE;
        setListingFeeOption(CONSTANTS.Options.NO_FEE);
        setAppPublishingFee(CONSTANTS.ListingFee.NO_FEE);
      } else {
        if (countryCode === 'us') {
          props.formFieldValues.appPublishingFee = CONSTANTS.ListingFee.DEFAULT;
          setListingFeeOption(CONSTANTS.Options.DEFAULT);
          setAppPublishingFee(CONSTANTS.ListingFee.DEFAULT);
        }
        if (countryCode === 'ca') {
          props.formFieldValues.appPublishingFee =
            CONSTANTS.ListingFee.CAD_DEFAULT;
          setListingFeeOption(CONSTANTS.Options.DEFAULT);
          setAppPublishingFee(CONSTANTS.ListingFee.CAD_DEFAULT);
        }
      }
    }
  }, []);

  useEffect(() => {
    const regex = /^(?=.*[1-9])\d*(?:\.\d{1,2})?$/;
    if (
      listingFeeOption === CONSTANTS.Options.CUSTOM &&
      !regex.test(appPublishingFee)
    )
      setError(true);
    else setError(false);
  }, [appPublishingFee, listingFeeOption]);

  return (
    <div>
      {address?.countryCode === 'CA' && (
        <div className="no-listing-fee-head">All values are in CAD</div>
      )}
      <NoFeeControl
        listingFeeOption={listingFeeOption}
        handleRadioChange={handleRadioChange}
        isDisabled={isDisabled}
        address={address}
      />
      <span class="form-content-divider"> </span>
      <DefaultFeeControl
        listingFeeOption={listingFeeOption}
        handleRadioChange={handleRadioChange}
        isDisabled={isDisabled}
        address={address}
      />
      <span class="form-content-divider"> </span>
      <CustomFeeControl
        listingFeeOption={listingFeeOption}
        handleRadioChange={handleRadioChange}
        isDisabled={isDisabled}
        appPublishingFee={appPublishingFee}
        handleInputChange={handleInputChange}
        error={error}
        address={address}
      />
    </div>
  );
}

export default AdminControlListingFee;
