import React from "react";
import { Tab, Tabs } from "@cdk-uip/react-tabs";

export class FormTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabId: this.props.activeTabId
    };
    this.onChange = activeTabId => {
      this.setState({ activeTabId: activeTabId });
      this.props.onTabChange(activeTabId);
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeTabId !== prevState.activeTabId) {
      return {
        activeTabId: nextProps.activeTabId
      };
    }
    return null;
  }

  render() {
    return this.props.tabs && this.props.tabs.length > 1 ? (
      <div className="tabCell">
        <Tabs
          value={this.state.activeTabId}
          onChange={value => this.onChange(value)}
        >
          {this.props.tabs.map((tab, tabIndex) => {
            return (
              <Tab key={tabIndex} value={tab.id}>
                {tab.label}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    ) : (
      false
    );
  }
}

export default FormTabs;
