import React from 'react';
import { Subheading1, Subheading2 } from '@cdk-uip/react-typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import EmptyContent from '../../../common/empty-content';

const FormImage = ({ fieldProperties, formFieldValues }) => (
  <div>
    {fieldProperties.label && (
      <Subheading2
        style={{ fontWeight: 'bold' }}
        className="form-field-dropbox-title"
      >
        {fieldProperties.label}
      </Subheading2>
    )}
    {fieldProperties.note && (
      <Subheading1 className="notes">{fieldProperties.note}</Subheading1>
    )}
    {formFieldValues[fieldProperties.name] ? (
      <img
        alt={fieldProperties.label}
        src={`${formFieldValues[fieldProperties.name]}?${Math.random()}`}
        className={fieldProperties.style}
      />
    ) : (
      <EmptyContent
        message={
          <FormattedMessage
            id="ManageListing.viewSolution.overview.noImages"
            defaultMessage="No Images"
          />
        }
      />
    )}
  </div>
);

export default injectIntl(FormImage);
