import React from 'react';
import { CircularProgress } from '@cdk-uip/react-jsonly-circular-progress';
import { Fluid, FluidItem } from '@cdk-uip/react-jsonly-fluid';
import { Dialog } from '@cdk-uip/react-dialog';

const Spinner = ({ inline, customClass }) =>
  inline ? (
    <CircularProgress className={customClass} />
  ) : (
    <Fluid halign="center" valign="middle" className={`spinner ${customClass}`}>
      <FluidItem>
        <CircularProgress />
      </FluidItem>
    </Fluid>
  );

export const DialogSpinnerWithText = ({ open, label }) => (
  <Dialog open={true} id="dialogSpinnerWithText">
    <Fluid halign="center" valign="middle" className={'spinnerWithText'}>
      <FluidItem>
        <div>
          <CircularProgress />
          <h3 className={'spinnerWithText__h3'}>{label}</h3>
        </div>
      </FluidItem>
    </Fluid>
  </Dialog>
);

export const DialogSpinnerWithCenteredText = ({ open, label }) => (
  <Dialog open={true} id="dialogSpinnerWithText">
    <Fluid halign="center" valign="middle" className={'spinnerWithText'}>
      <FluidItem>
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
          <div style={{ textAlign: 'center' }}>
            <strong>{label}</strong>
          </div>
        </div>
      </FluidItem>
    </Fluid>
  </Dialog>
);

export default Spinner;
