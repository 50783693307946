import SellPage from '../../components/sell/SellPage';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const query = gql`
  query {
    featuredSolutions {
      id
      isFeatured
      listingVersion
      developerId
      orgId
      status
      solution_visibility {
        solutionVisibilityType
        privateSolutionUsers
      }
      overview {
        name
        briefDescription
        solutionLogo
        category
        featuredImage
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  featuredSolutionsList: data.featuredSolutions || [],
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps
})(SellPage);
