import React from 'react';
import PropTypes from 'prop-types';
import SolutionStatusColor from '../common/SolutionStatusColor';
import SolutionStatus from '../common/SolutionStatus';

const Status = ({ icon, status, certificationStatus }) => {
  function replaceRejectedWithNotApproved(status) {
    if (status && status === SolutionStatus.REJECTED) {
      return SolutionStatus.NOT_APPROVED;
    }
    return status;
  }
  const certificationStatusValues = {
    'ready-to-certify': 'Ready to Certify',
    'in-certification': 'In Certification',
    certified: 'Certified',
    'payment-pending': 'Certification Payment Pending'
  };
  function formatCertificationStatus(certificationStatus) {
    if (certificationStatus && certificationStatusValues[certificationStatus]) {
      return certificationStatusValues[certificationStatus] + ' - ';
    }
  }
  return (
    <div>
      {certificationStatus ? (
        <span>{`${formatCertificationStatus(
          certificationStatus
        )} ${replaceRejectedWithNotApproved(status)}`}</span>
      ) : (
        <span>{`${replaceRejectedWithNotApproved(status)}`}</span>
      )}
      {status && (
        <span style={{ color: SolutionStatusColor[status.replace(/ /g, '_')] }}>
          <i className="material-icons fm-status-indicator">{icon}</i>
        </span>
      )}
    </div>
  );
};

Status.propTypes = {
  icon: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default Status;
