import React from 'react';
import { Subheading1, Subheading2 } from '@cdk-uip/react-typography';
import FormPrePostText from './FieldPrePostText';
import FormStaticField from './FormStaticField';
import FormTextField from './FormTextField';
import SearchField from './SearchField';
import FormTextArea from './FormTextArea';
import FormSelect from './FormSelect';
import FormButton from './FormButton';
import FormCheckBox from './FormCheckBox';
import FormOnOffSwitch from './FormOnOffSwitch';
import FormCheckBoxGroup from './FormCheckBoxGroup';
import FormDropBoxS3Upload from './FormDropBoxS3Upload';
import FormS3FileUpload from './FormS3FileUpload';
import FromDropBox from './FormDropBox';
import FormAPIRequest from './FormAPIRequest';
import FormImage from './FormImage';
import MultipleAddFormBuilder from '../MultipleAddFormBuilder';
import Spinner from '../../../common/Spinner';
import FormBuilder from '../FormBuilder';
import SolutionStatusHistory from './SolutionStatusHistory';
import FormFieldDemoField from './FormFieldDemoField';
import FormRadio from './FormRadio';
import FormRadioOption from './FormRadio-options';

import FormTextFieldWithPayload from './FormTextFieldWithPayload';
import FormTextFieldWithToggle from './FormTextFieldWithToggle';
import FortellisTitle3 from '../../../common/FortellisTitle3';
import FormCaption from './FormCaption';
import InputChipset from './InputChipset';
import SearchBoxWithChipSet from './SearchBoxWithChipSet';
import FormJsonTextArea from './FormJsonTextArea';
import PDFViewer from './PDFViewer';
import FormUploadTerms from './FormUploadFile';
import FormCaptionWithAnchorTagText from './FormCaptionWithAnchorTagText';

export class FormField extends React.Component {
  render() {
    const mainFieldProps = this.props.fieldProperties;
    let fields = [];
    if (mainFieldProps.multiple) {
      fields = mainFieldProps.multipleFields;
    } else {
      fields.push(mainFieldProps);
    }

    return (
      <div className={mainFieldProps.multipleFieldGridStyle}>
        {mainFieldProps.title && (
          <Subheading2
            className={
              mainFieldProps.titleStyle
                ? mainFieldProps.titleStyle
                : 'text-field-title'
            }
          >
            {mainFieldProps.title}
          </Subheading2>
        )}
        {mainFieldProps.titleNote && (
          <Subheading1
            className={
              mainFieldProps.titleNoteStyle
                ? mainFieldProps.titleNoteStyle
                : 'text-field-title'
            }
          >
            {mainFieldProps.titleNote}
          </Subheading1>
        )}
        {this.props.deleting ? (
          <div>
            <Spinner />
          </div>
        ) : (
          fields.map((field, fieldIndex) => {
            return (
              <span key={fieldIndex}>
                {(!field.show ||
                  field.show(
                    this.props.formFieldValues[field.dependentOn]
                  )) && (
                  <div className={field.gridStyle}>
                    <FormPrePostText
                      text={field.preText}
                      dependentOn={
                        this.props.dependentOn
                          ? this.props.dependentOn
                          : mainFieldProps.dependentOn
                      }
                      formFieldValuesAll={this.props.formFieldValuesAll}
                      formFieldValues={this.props.formFieldValues}
                      style={field.preTextStyle}
                    />
                    <div className={field.gridStyle}>
                      {field.type === 'static' && (
                        <FormStaticField
                          fieldProperties={field}
                          formFieldValues={this.props.formFieldValues}
                        />
                      )}

                      {field.type === 'static-demo-field' && (
                        <FormFieldDemoField fieldProperties={field} />
                      )}

                      {field.type === 'text_field' && (
                        <FormTextField
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          disabledOn={
                            this.props.formFieldValues[field.disabledOn]
                          }
                          disabled={this.props.disabled}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'input_chipset' && (
                        <InputChipset
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          disabledOn={
                            this.props.formFieldValues[field.disabledOn]
                          }
                          disabled={this.props.disabled}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'searchbox_chipset' && (
                        <SearchBoxWithChipSet
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'text_field_payload' && (
                        <FormTextFieldWithPayload
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          disabledOn={
                            this.props.formFieldValues[field.disabledOn]
                          }
                          disabled={this.props.disabled}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'text_field_with_toggle' && (
                        <FormTextFieldWithToggle
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          disabledOn={
                            this.props.formFieldValues[field.disabledOn]
                          }
                          disabled={this.props.disabled}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'search_field' && (
                        <SearchField
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          disabledOn={
                            this.props.formFieldValues[field.disabledOn]
                          }
                          disabled={this.props.disabled}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'text_area' && (
                        <FormTextArea
                          {...this.props}
                          fieldProperties={field}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'json_text_area' && (
                        <FormJsonTextArea
                          {...this.props}
                          fieldProperties={field}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'select' && (
                        <FormSelect
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          formValueCount={this.props.formValueCount}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'button' && (
                        <FormButton
                          fieldProperties={field}
                          formFieldValues={this.props.formFieldValues}
                          onClick={field && field.onBtnClick}
                        />
                      )}

                      {field.type === 'on_off_switch' && (
                        <FormOnOffSwitch
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          labelDependsOn={
                            this.props.formFieldValues[field.labelDependsOn]
                          }
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'check_box' && (
                        <FormCheckBox
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'radio' && (
                        <FormRadio
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'radio-option' && (
                        <FormRadioOption
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'check_box_group' && (
                        <FormCheckBoxGroup
                          fieldProperties={field}
                          options={this.props.formValues[field.options]}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'drop_box_s3_uploader' && (
                        <FormDropBoxS3Upload
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                          delete={this.props.delete}
                          submitClicked={this.props.submitClicked}
                        />
                      )}
                      {field.type === 'drop_box_s3_file_uploader' && (
                        <FormS3FileUpload
                          {...this.props}
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                          delete={this.props.delete}
                        />
                      )}
                      {field.type === 'pdf_viewer' && (
                        <PDFViewer
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                          delete={this.props.delete}
                        />
                      )}
                      {field.type === 'drop_box' && (
                        <FromDropBox
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}
                      {field.type === 'static_image' && (
                        <FormImage
                          fieldProperties={field}
                          formFieldValues={this.props.formFieldValues}
                        />
                      )}
                      {field.type === 'multiple_add_form' && (
                        <MultipleAddFormBuilder
                          {...this.props}
                          fieldProperties={field}
                          formValues={this.props.formValues}
                          formFieldValuesAll={this.props.formFieldValuesAll}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          dependentOn={mainFieldProps.dependentOn}
                          onChange={this.props.onChange}
                        />
                      )}
                      {field.type === 'status_cards' && (
                        <SolutionStatusHistory
                          fieldProperties={field}
                          formValues={this.props.formValues}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          dependentOn={mainFieldProps.dependentOn}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'FortellisTitle3' && (
                        <FortellisTitle3
                          style={field.style}
                          title={field.formtitle}
                        />
                      )}

                      {field.type === 'FormCaption' && (
                        <FormCaption
                          style={field.style}
                          title={field.formtitle}
                        />
                      )}

                      {field.type === 'FormCaptionWithAnchorText' && (
                        <FormCaptionWithAnchorTagText
                          style={field.style}
                          title={field.formtitle}
                          linkText={field.linkText}
                          link={field.link}
                          linkStyle={field.linkStyle}
                        />
                      )}

                      {field.type === 'api_request' && (
                        <FormAPIRequest
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          value={this.props.formFieldValues[field.name]}
                          onChange={this.props.onChange}
                        />
                      )}

                      {field.type === 'termsUpload' && (
                        <FormUploadTerms
                          fieldProperties={field}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          onChange={this.props.onChange}
                          value={this.props.formFieldValues}
                          {...this.props}
                        />
                      )}

                      {field.multiple && (
                        <FormField
                          fieldProperties={field}
                          formValues={this.props.formValues}
                          formFieldValuesAll={this.props.formFieldValuesAll}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          formValueCount={this.props.formValueCount}
                          onChange={this.props.onChange}
                        />
                      )}
                    </div>
                    <FormPrePostText
                      text={field.postText}
                      dependentOn={
                        this.props.dependentOn
                          ? this.props.dependentOn
                          : mainFieldProps.dependentOn
                      }
                      formFieldValuesAll={this.props.formFieldValuesAll}
                      formFieldValues={this.props.formFieldValues}
                      style={field.postTextStyle}
                    />
                  </div>
                )}
                {field.hasDependent
                  ? field.dependentContent.show &&
                    field.dependentContent.show(
                      this.props.formFieldValues[field.name]
                    ) && (
                      <div>
                        {field.dependentNote && (
                          <Subheading1 className="dependentNote">
                            {field.dependentNote}
                          </Subheading1>
                        )}
                        <div className="dependent-div">
                          {field.dependentContent.title && (
                            <Subheading2>
                              {field.dependentContent.title(
                                this.props.formFieldValues[field.name]
                              )}
                            </Subheading2>
                          )}
                          <FormBuilder
                            key={fieldIndex}
                            content={field.dependentContent}
                            formFieldValuesAll={this.props.formFieldValuesAll}
                            formStaticValues={this.props.formStaticValues}
                            formFieldValues={this.props.formFieldValues}
                            formValues={this.props.formValues}
                            onSave={this.props.onChange}
                          />
                        </div>
                      </div>
                    )
                  : false}
              </span>
            );
          })
        )}
      </div>
    );
  }
}

export default FormField;
