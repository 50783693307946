import React from 'react';
import { Switch } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { SecureRoute } from '@okta/okta-react';

import SolutionsListForAdmin from '../../../containers/SolutionsListForAdminContainer';
import SolutionReviewForm from '../../../containers/SolutionReviewFormContainer';

class SolutionsForAdmin extends React.Component {
  render() {
    const {
      auth: { userData, accessToken },
      userPermissions
    } = this.props;
    let match;
    if (this.props && this.props.location && this.props.location.state) {
      match = {
        params: {
          id: this.props.location.state.solutionId,
          listingVersion: this.props.location.state.listingVersion
        }
      };
    }
    return (
      <Switch>
        <SecureRoute
          path="/admin/solutions"
          exact
          render={() =>
            this.props.location.state &&
            this.props.location.state.solutionId ? (
              <SolutionReviewForm
                solutionId={this.props.location.state.solutionId}
                listingVersion={this.props.location.state.listingVersion}
                userData={userData}
                commentOfInReview={this.props.location.state.commentOfInReview}
                userPermissions={userPermissions}
                match={match}
              />
            ) : (
              <SolutionsListForAdmin
                userData={userData}
                accessToken={accessToken}
              />
            )
          }
        />
      </Switch>
    );
  }
}

class SolutionsForAdminContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SolutionsForAdmin {...this.props} />
    ) : (
      <></>
    );
  }
}
export default withAuth(SolutionsForAdminContainer);
