const COUNTRY_CODE = {
  USA: 'US',
  CANADA: 'CA'
};

const CURRENCY = {
  [COUNTRY_CODE.USA]: 'USD',
  [COUNTRY_CODE.CANADA]: 'CAD'
};

const PRODUCT = {
  LISTING_FEE: 'listingFee',
  CERTIFICATION_FEE: 'certificationFee',
  RECERTIFICATION_FEE: 'recertificationFee'
};

const PRODUCT_NAME = {
  [PRODUCT.LISTING_FEE]: 'Listing Fee',
  [PRODUCT.CERTIFICATION_FEE]: 'Certification Fee',
  [PRODUCT.RECERTIFICATION_FEE]: 'Recertification Fee'
};

export { COUNTRY_CODE, PRODUCT, CURRENCY, PRODUCT_NAME };
