import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import MySubscriptionsList from '../components/marketplace-account/MySubscriptionsList';

export const query = gql`
  query($entityId: ID) {
    solutionSubscriptions(entityId: $entityId) {
      id
      solutionId
      subscriptionId
      entityId
      userId
      status
      listingVersion
      dateOfPurchase
      userProvisioningStatus
      apiActivationStatus
      subscriptionStartDate
      subscriptionEndDate
      reminderSentOn
      provisioningInitiatedOn
      plan {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      solution {
        id
        developerName
        developerId
        overview {
          name
          publisherName
          solutionLogo
        }
        support {
          phoneNumber
          emailAddress
        }
        provisioning_interface {
          endpointUrl
          redirectURL
          provisioningtype
        }
        subscription_notifications {
          email
          endpointUrl
          apiNotification
        }
        registration_details {
          api_details {
            id
            name
            isOptional
          }
        }
      }
      billingStartsOn
      billingEndsOn
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  list: data.solutionSubscriptions || [],
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps,
  options: ({ entity }) => ({
    variables: {
      entityId: entity.id
    }
  })
})(MySubscriptionsList);
