import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton,
  DialogHeader
} from '@cdk-uip/react-dialog';
import Spinner from '../../common/Spinner';

class SubscriptionCancelDialog extends React.Component {
  state = {
    canceling: false
  };
  render() {
    const { solutionName } = this.props;
    return this.state.canceling ? (
      <Dialog open={this.props.open} className="cancel-subscription-dialog">
        <DialogHeader>
          <span className="plan-terms-dialog-header">Cancel subscription</span>
        </DialogHeader>
        <DialogBody className="plan-terms-dialog-body-container">
          <Spinner />
        </DialogBody>
      </Dialog>
    ) : (
      <Dialog open={this.props.open} className="cancel-subscription-dialog">
        <DialogHeader>
          <span className="plan-terms-dialog-header">Cancel subscription</span>
        </DialogHeader>
        <DialogBody className="plan-terms-dialog-body-container">
          <div className="subscription-cancel-dialog-text">
            Are you sure you want to cancel your subscription to
            <span className="subscription-cancel-solution-name">
              {' '}
              {solutionName}
            </span>
            ? Cancelling your subscription means you loose access to the app and
            you are no longer billed for the subscription.
          </div>
        </DialogBody>
        <DialogFooter>
          <DialogFooterButton onClick={this.props.onClose}>
            Do Not Cancel
          </DialogFooterButton>
          <DialogFooterButton
            onClick={() => {
              this.setState({ canceling: true });
              this.props.onApprove();
            }}
          >
            Cancel Subscription
          </DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default injectIntl(SubscriptionCancelDialog);
