import React, { Component, Fragment } from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import APIError from '../components/common/APIError';
import ErrorCodes from '../components/common/ErrorCodes';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

const ignoreErrorList = [ErrorCodes.SOLUTION_NOT_FOUND];
let isErrorInIgnoreList = message => {
  return ignoreErrorList.includes(message);
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql'
});

let token = '',
  apollo_client = null;
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

let notify = false;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  notify && notify({ graphQLErrors, networkError });
});

export const getApolloClient = () => apollo_client;

class GraphQLClient extends Component {
  state = { errors: false };
  componentDidMount() {
    notify = this.onError;
  }
  componentWillUnmount() {
    notify = false;
  }
  onError = ({ graphQLErrors, networkError }) => {
    const gql_errors = [];
    let {
      state: { errors }
    } = this;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (!isErrorInIgnoreList(message)) {
          gql_errors.push({
            message: message,
            locations: locations,
            path: path
          });
        }
      });
    }
    if (networkError) {
      gql_errors.push({ networkError: networkError });
    }
    if (!errors) {
      this.setState({ errors: gql_errors });
    }
  };
  onDismiss = () => {
    this.setState({ errors: false });
    window.location.reload();
  };
  render() {
    const { errors } = this.state;
    let {
      props: {
        auth: { accessToken = null }
      }
    } = this;
    token = accessToken;
    apollo_client = new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache({ addTypename: false })
    });
    return (
      <ApolloProvider client={apollo_client}>
        <Fragment>
          {this.props.children}
          {errors && accessToken !== null && (
            <APIError error={errors} dismiss={this.onDismiss} />
          )}
        </Fragment>
      </ApolloProvider>
    );
  }
}
export default withAuth(GraphQLClient);
