import React, { Component } from "react";
import PropTypes from "prop-types";

export const ErrorDisplay = ({ error, info }) => (
  <div>
    <h1>Uncaught Error:</h1>
    <pre>{error.stack || error.message}</pre>
    <pre>{info}</pre>
  </div>
);

ErrorDisplay.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  info: PropTypes.string
};

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { catched: false };
  }
  componentDidCatch(error, info) {
    this.setState({ catched: { error, info } });
  }
  render() {
    const { catched } = this.state;
    if (catched) {
      const { error, info } = catched;
      return (
        <ErrorDisplay
          error={error}
          info={
            info ? `The above error occurred in: ${info.componentStack}` : null
          }
        />
      );
    }
    return this.props.children;
  }
}
