export const config = {
  formId: 'appPublishingFee',
  formTitle: 'Marketplace Listing Fee',
  formValueType: 'Object',
  formNote:
    'By filling the following information you can configure the monthly recurring Marketplace Listing Fee',
  tabs: [
    {
      id: 'appPublishingFee',
      label: 'Marketplace Listing Fee',
      contents: [
        {
          id: 'appPublishingFee',
          fields: [
            {
              formtitle: 'Select Marketplace Listing Fee Option',
              type: 'FortellisTitle3',
              style: 'provisioningtitle'
            },
            {
              name: 'appPublishingFee',
              disabled: false,
              type: 'adminControlListingFee',
              style: 'additional-info-style',
              gridStyle: 'layoutcell'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    appPublishingFee: {
      appPublishingFee: ''
    }
  },
  activeTabId: 'appPublishingFee'
};
