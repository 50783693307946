import SolutionStatusHistoryCard from "../components/solution-submission/common-form-api/FormComponents/SolutionStatusHistoryCard";

import { graphql } from "react-apollo";
import gql from "graphql-tag";

export const query = gql`
  query($userEmail: String) {
    userPermissions(developerEmail: $userEmail) {
      permissions
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  permissions:
    data.userPermissions && data.userPermissions.permissions
      ? data.userPermissions.permissions
      : null,
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps,
  options: ({ userEmail }) => ({
    variables: {
      userEmail: userEmail
    }
  })
})(SolutionStatusHistoryCard);
