import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { TextArea } from '@cdk-uip/react-text-area';
import ValidationUtils from '../utils/ValidationUtils';

export class FormTextArea extends React.Component {
  state = {
    isValid: true,
    outOfFocus: false
  };

  constructor(props) {
    super(props);
    this.validationUtils = new ValidationUtils();
  }

  componentDidMount() {
    const { formFieldValues, fieldProperties } = this.props;
    if (
      !formFieldValues[fieldProperties.name] ||
      formFieldValues[fieldProperties.name] === '' ||
      (typeof formFieldValues[fieldProperties.name] === 'string' &&
        formFieldValues[fieldProperties.name].trim() === '')
    ) {
      this.validate();
    }
  }

  handleInputChange = event => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[event.target.name] = event.target.value;
    let isValid = this.validate();
    this.props.onChange(formFieldValues);
    this.setState({
      isValid: isValid
    });
  };

  validate() {
    const formFieldValues = this.props.formFieldValues;
    let isValid = this.validationUtils.validate(
      this.props.fieldProperties,
      formFieldValues
    );
    this.props.validate &&
      this.props.validate(
        isValid,
        this.props.fieldProperties.name,
        this.props.activeFormId,
        this.props.activeTabId,
        this.props.activeSubFormId
      );
    return isValid;
  }

  render() {
    const { fieldProperties } = this.props;
    const randomNumber = Math.random();
    return (
      <div className="form-field-with-tooltip">
        <TextArea
          className={this.props.fieldProperties.style}
          name={this.props.fieldProperties.name}
          id={this.props.fieldProperties.name}
          rows={
            this.props.fieldProperties.rows
              ? this.props.fieldProperties.rows
              : 1
          }
          label={
            <span className="form-field-label">
              {this.props.fieldProperties.label}
              {this.props.fieldProperties.toolTipText && (
                <span>
                  <i
                    id={`tooltip_${
                      this.props.fieldProperties.name
                    }${randomNumber}`}
                    className="material-icons help-label-icon"
                  >
                    help
                  </i>
                </span>
              )}
            </span>
          }
          value={this.props.value ? this.props.value : ''}
          maxLength={this.props.fieldProperties.maxLength}
          onChange={this.handleInputChange}
          onBlur={e => {
            this.handleInputChange(e);
            this.setState({ outOfFocus: true });
          }}
          onFocus={e => {
            this.handleInputChange(e);
            this.setState({ outOfFocus: false });
          }}
          invalid={this.state.outOfFocus && !this.state.isValid}
          disabled={this.props.fieldProperties.isDisabled}
          required={this.props.fieldProperties.required}
        />
        {this.props.fieldProperties.maxLength && (
          <div className="character-remaining-div">
            {this.props.value ? this.props.value.length : 0} /{' '}
            {this.props.fieldProperties.maxLength}
          </div>
        )}
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${this.props.fieldProperties.name}${randomNumber}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>
        <div
          className={
            'mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg' +
            (this.state.outOfFocus ? ' form-text-area-error' : '')
          }
        >
          {!this.state.outOfFocus &&
            !this.state.isValid &&
            fieldProperties.validationErrorMessage}
        </div>
      </div>
    );
  }
}

export default FormTextArea;
