const REMITTANCE = 'Remittance';
const SETUP_BILLING = 'setupBilling';

const remittanceTabConfig = {
  title: REMITTANCE,
  steps: [
    {
      id: SETUP_BILLING,
      label: 'Accounts',
      type: 'simple'
    },
    {
      id: 'PaymentHistory',
      label: 'Payment History',
      type: 'simple'
    },
    {
      id: 'PaymentMethod',
      label: 'Payment Method',
      type: 'simple'
    }
  ]
};

const navConstants = {
  REMITTANCE,
  SETUP_BILLING,
  remittanceTabConfig
};

export default navConstants;
