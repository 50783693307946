export const config = {
  formId: "listingVersionUpdates",
  formTitle: "What's New",
  formValueType: "Object",
  formNote: "Add whats new in this listing version",
  tabs: [
    {
      id: "listingVersionUpdates",
      label: "What's New",
      contents: [
        {
          id: "listingVersionUpdates",
          fields: [
            {
              name: "description",
              label: "Description",
              maxLength: 512,
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              type: "multiple_add_form",
              name: "updates",
              multiFormGridStyle: "multiple-add-form-div",
              showAddLink: false,
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: "updateText",
                        label: "Highlight Point",
                        maxLength: 126,
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    listingVersionUpdates: {
      description: "",
      updates: []
    }
  },
  activeTabId: "listingVersionUpdates"
};
