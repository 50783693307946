import React, { useState, useEffect } from 'react';
import Spinner from '../../../../common/Spinner';
import { FormattedMessage } from 'react-intl';
import EmptyContent from '../../../../common/empty-content';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import {
  DateRangePicker,
  INPUT_SIZES,
  Table,
  TABLE_LAYOUT_OPTIONS,
  TextButton,
  IconFileDownload,
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  THEMES
} from 'cdk-radial';
import moment from 'moment';
import LearnMore from '../../../../common/LearnMore/LearnMore';
import { environmentURLs } from '../../../../common/environment/CaptureEnvironment';
import {
  COUNTRY_CODE,
  CURRENCY,
  PRODUCT_NAME
} from '../../../../common/constants/MonetizationConstants';
const STRIPE_BANK = 'stripe_bank';
const PAYMENT_METHOD = {
  BANK: 'Bank Account',
  CARD: 'Credit Card'
};
const INFO_MESSAGE =
  'By default, payment transactions for the last 90 days for the account are displayed. Specify the start and end dates for the duration you want to review the payment history.';
const DOCS_URL = `${
  environmentURLs.docs
}docs/tutorials/app-lifecycle/app-listing-fee`;

const getInCurrency = value => {
  const dollarValue =
    value &&
    parseFloat(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol'
    });
  return dollarValue;
};

export default function PaymentHistory(props) {
  const countryCode = props.entity?.address?.countryCode;
  const filteredListings = props.solutions.filter(
    sol => sol.activateOnly !== false
  );
  const createdOn = filteredListings.length && filteredListings[0].registeredOn;
  const newStart = createdOn ? new Date(createdOn) : new Date();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [from, setFrom] = useState(moment(newStart, 'YYYY-MM-DD'));
  const [to, setTo] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [filterMessage, setFilterMessage] = useState(
    'Last 90 days transactions'
  );
  const [isDataOnLoad, setIsDataOnLoad] = useState(false);
  const currencyMessage = `All monetary values are listed in ${
    CURRENCY[countryCode]
  }`;

  const customCellRenderer = gridParams => {
    const { colDef, data } = gridParams;
    const { field } = colDef;
    return (
      <div className="payment-history-caption payment-history-text-wrap">
        {data[field]}
      </div>
    );
  };

  const customLinkRenderer = gridParams => {
    const { invoiceUrl, invoiceReference } = gridParams?.data;
    return (
      <div className="payment-history-text-wrap">
        <a
          className="solution-common-header-link"
          target="_blank"
          href={invoiceUrl}
        >
          {invoiceReference}
        </a>
      </div>
    );
  };

  const totalWithTaxTooltip = gridParams => {
    const { salesTax, gst, pst, qst, amount, total } = gridParams?.data;
    const tooltipText = (
      <div>
        <div>Amount: {amount}</div>
        {countryCode === COUNTRY_CODE.USA && <div>Sales Tax: {salesTax}</div>}
        {countryCode === COUNTRY_CODE.CANADA && (
          <>
            <div>GST: {gst}</div>
            <div>PST: {pst}</div>
            <div>QST: {qst}</div>
          </>
        )}
      </div>
    );
    const tooltipProps = {
      id: 'amountAndtaxes',
      text: tooltipText,
      theme: THEMES.LIGHT
    };

    const ComponentToRender = props => {
      return (
        <div
          id="amount-and-taxes"
          className="payment-history-amount-tooltip"
          {...props}
        >
          {total}
        </div>
      );
    };
    const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);
    return (
      <ComponentWithTooltip
        horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.RIGHT}
        marginAroundElement={8}
        verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.MIDDLE}
      />
    );
  };

  const blockedDateRanges = [
    { endDate: moment().add(1, 'year'), startDate: moment().add(1, 'day') },
    {
      endDate: moment(newStart).subtract(1, 'day'),
      startDate: moment(newStart).subtract(1, 'year')
    }
  ];

  const canadaTaxColumns = [
    {
      cellRendererFramework: customCellRenderer,
      field: 'gst',
      headerName: 'GST',
      minWidth: 100,
      width: 100,
      hide: true
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'pst',
      headerName: 'PST',
      minWidth: 100,
      width: 100,
      hide: true
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'qst',
      headerName: 'QST',
      minWidth: 80,
      width: 80,
      hide: true
    }
  ];

  const usTaxColumns = [
    {
      cellRendererFramework: customCellRenderer,
      field: 'salesTax',
      headerName: 'Sales Tax',
      minWidth: 100,
      width: 100,
      hide: true
    }
  ];

  const columns = [
    {
      cellRendererFramework: customCellRenderer,
      field: 'description',
      headerName: 'Description',
      minWidth: 138,
      width: 138,
      hide: false
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      minWidth: 116,
      width: 116,
      hide: false
    },
    {
      cellRendererFramework: customLinkRenderer,
      field: 'invoiceReference',
      headerName: 'Invoice Reference',
      minWidth: 136,
      width: 136,
      hide: false
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'invoiceUrl',
      headerName: 'Invoice URL',
      minWidth: 10,
      width: 10,
      hide: true
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'paymentType',
      headerName: 'Payment Method',
      minWidth: 134,
      width: 134,
      hide: false
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'last4',
      headerName: 'Payment Info',
      minWidth: 110,
      width: 110,
      hide: false,
      wrapHeaderText: true,
      autoHeaderHeight: true
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'status',
      headerName: 'Status',
      minWidth: 69,
      width: 69,
      hide: false
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'currency',
      headerName: 'Currency',
      minWidth: 10,
      width: 10,
      hide: true
    },
    {
      cellRendererFramework: customCellRenderer,
      field: 'amount',
      headerName: 'Amount',
      minWidth: 120,
      width: 120,
      hide: true
    },
    ...(countryCode === COUNTRY_CODE.CANADA ? canadaTaxColumns : usTaxColumns),
    {
      cellRendererFramework: totalWithTaxTooltip,
      field: 'total',
      headerName: 'Total Amount w/Taxes',
      minWidth: 162,
      width: 162,
      hide: false
    }
  ];

  const handleDateChange = ({
    endDate: momentEndDateObject,
    startDate: momentStartDateObject
  }) => {
    setFrom(momentStartDateObject);
    setTo(momentEndDateObject);
    fetchData(momentStartDateObject, momentEndDateObject);
  };

  const endDateInputProps = {
    size: INPUT_SIZES.LARGE,
    isDisabled: createdOn ? false : true
  };
  const startDateInputProps = {
    size: INPUT_SIZES.LARGE,
    isDisabled: createdOn ? false : true
  };

  async function fetchData(startDate, endDate) {
    const newEndDate = moment(endDate).add(1, 'day');
    setLoading(true);
    const client = getApolloClient();
    let response = await client.query({
      query: getAppPaymentHistory,
      variables: {
        solnId: props.solutions[0].id,
        startDate: startDate?._d.toDateString(),
        endDate: newEndDate?._d.toDateString()
      }
    });
    if (response.data.getAppPaymentHistory) {
      let isv = response.data.getAppPaymentHistory.filter(
        payment => payment.remittance_org_id === 'fortellis_platform'
      );
      setPaymentHistory(isv);
      isv.length > 0 ? setIsDataOnLoad(true) : setIsDataOnLoad(false);
      isv.length > 1
        ? setFilterMessage(`${isv.length} Results found`)
        : setFilterMessage(`${isv.length} Result found`);
    } else {
      setPaymentHistory([]);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (createdOn) {
      fetchData(from, to);
    }
  }, []);

  useEffect(() => {
    let colData = [];
    let row = {};
    if (paymentHistory && paymentHistory.length) {
      paymentHistory.forEach(item => {
        row = {
          description: PRODUCT_NAME[item.product_type],
          invoiceDate: moment(item.created.split('T')[0]).format('DD MMM YYYY'),
          invoiceReference: item.invoice_reference,
          invoiceUrl: item.invoice_url,
          amount: getInCurrency(item.taxable_amount),
          salesTax: getInCurrency(item.tax_paid),
          gst: getInCurrency(item.gst_tax_amount),
          pst: getInCurrency(item.pst_tax_amount),
          qst: getInCurrency(item.qst_tax_amount),
          total:
            item.totalcharge !== '0'
              ? getInCurrency(item.totalcharge)
              : getInCurrency(
                  parseFloat(item.taxable_amount) + parseFloat(item.tax_paid)
                ),
          status:
            item.status && item.status[0].toUpperCase() + item.status.slice(1),
          currency: item.currency,
          last4: `****${item.last4}`,
          paymentType:
            item.payment_type === STRIPE_BANK
              ? PAYMENT_METHOD.BANK
              : PAYMENT_METHOD.CARD
        };
        colData.push(row);
      });
      setColumnData(colData);
    }
  }, [paymentHistory]);

  const handleGridReady = gridParams => {
    setGridApi(gridParams.api);
  };

  const handleDownloadClick = () => {
    const params = {
      fileName: `Payment History - ${props.solutions[0].overview.name}.csv`,
      skipHeader: false,
      allColumns: true
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <React.Fragment>
      <div className="api-info-subtitle">
        <FormattedMessage
          id="PaymentHistory.helpText"
          defaultMessage={INFO_MESSAGE}
        />
        &nbsp;
        <LearnMore url={DOCS_URL} />
        <br />
        <br />
      </div>
      {createdOn ? (
        loading ? (
          <div className="payment_loading">
            <Spinner />
            <div>Loading...</div>
          </div>
        ) : (
          <div data-testid="root-div">
            {isDataOnLoad && (
              <div css="min-height: 350px;">
                <div css="max-width: 350px;">
                  <DateRangePicker
                    allowDaysInPast={true}
                    blockedDateRanges={blockedDateRanges}
                    endDate={to}
                    endDateInputName="end-date-input-customized"
                    endDateInputProps={endDateInputProps}
                    endDateLabel="End Date"
                    highlightToday
                    id="date-range-picker-customized"
                    data-testid="date-range-picker-customized"
                    onDatesChange={handleDateChange}
                    startDate={from}
                    startDateInputName="start-date-input-customized"
                    startDateInputProps={startDateInputProps}
                    startDateLabel="Start Date"
                  />
                </div>
              </div>
            )}
            {paymentHistory.length > 0 ? (
              <>
                <div className="remittance-date-range">
                  <div className="row-item  payment-history-text-container">
                    <div className="payment-history-title">{filterMessage}</div>
                    <div className="payment-history-caption">
                      {currencyMessage}
                    </div>
                  </div>
                  <div className="row-item download_icons">
                    <TextButton
                      data-cy="download_csv_btn"
                      id="downloadCsv"
                      onClick={handleDownloadClick}
                      icon={<IconFileDownload />}
                      text="CSV"
                      data-testid="downloadCsv"
                    />
                  </div>
                </div>
                <div data-testid="payment-history-table-div">
                  <Table
                    columns={columns}
                    data={columnData}
                    rowHeight={48}
                    isSearchable={false}
                    isDownloadable={false}
                    isPrintable={false}
                    resizableHeaders={true}
                    showNoRowsOverlay={false}
                    showFooter={false}
                    hideTableTitle={true}
                    hideBorder={true}
                    onGridReady={handleGridReady}
                    dataTestId="payment-history-table"
                    customDownloadHandler={() =>
                      handleDownloadClick(columnData)
                    }
                    hasPagination={columnData.length > 10 ? true : false}
                    tableLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
                    id="payment-history-table"
                  />
                </div>
              </>
            ) : (
              renderEmptyContent()
            )}
          </div>
        )
      ) : (
        renderEmptyContent(
          'There is no payment history available for this app.'
        )
      )}
    </React.Fragment>
  );
}

const renderEmptyContent = text => {
  return (
    <EmptyContent
      message={
        <FormattedMessage
          id="App.PaymentHistory.Empty"
          defaultMessage={
            text ?? 'No payment history available for this date range!'
          }
        />
      }
    />
  );
};

export const getAppPaymentHistory = gql`
  query($solnId: String, $startDate: String, $endDate: String) {
    getAppPaymentHistory(
      solnId: $solnId
      startDate: $startDate
      endDate: $endDate
    ) {
      app_name
      customer_org_name
      totalcharge
      fortellisfee
      balancetotal
      plan_name
      created
      order_total_amount
      taxable_amount
      tax_rate
      tax_paid
      modified_by_user_email
      modified_by_user_login
      remittance_org_id
      receipt_url
      invoice_reference
      invoice_url
      status
      gst_tax_amount
      pst_tax_amount
      qst_tax_amount
      currency
      product_type
      last4
      payment_type
    }
  }
`;
