import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

class SubscribedPlanContentPricing extends React.Component {
  render() {
    const {
      plan: {
        includedUnitQuantity,
        includedUnitOfMeasure = '',
        basePrice = 0.0,
        includedTransactionalFee,
        transactionalTypeOptions
      }
    } = this.props;

    const transactionalFee = () => {
      if (includedTransactionalFee && transactionalTypeOptions) {
        if (transactionalTypeOptions === '%') {
          return `${includedTransactionalFee.toFixed(
            2
          )} ${transactionalTypeOptions}`;
        }

        if (transactionalTypeOptions === '$') {
          return `${transactionalTypeOptions} ${includedTransactionalFee.toFixed(
            2
          )}`;
        }

        return '$0.00';
      }
      return '$0.00';
    };

    return (
      <div className="plan-content-pricing-div">
        <div className="plan-content-pricing-details">
          <div className="plan-content-pricing-details-includes">
            <div className="plan-pricing-include-title">
              <FormattedMessage
                id="MarketplaceAccount.subscriptions.trialPlan.pricingNote"
                defaultMessage="Trial period begins after app provisioning is complete."
              />
            </div>
            <div className="plan-pricing-include-items">
              <div className="plan-pricing-include-item">
                <span className="plan-pricing-include-item-title">
                  {`Number of ${includedUnitOfMeasure &&
                    includedUnitOfMeasure.toLowerCase()}`}
                </span>
                <span className="plan-pricing-include-item-count">
                  {includedUnitQuantity}
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="plan-content-pricing-details-final">
            <div className="plan-checkout-amount">
              {(basePrice && `$${basePrice.toFixed(2)}`) || transactionalFee()}
            </div>
            <div className="plan-checkout-title">
              <FormattedMessage
                id="MarketplaceAccount.subscriptions.total"
                defaultMessage="Total"
              />
            </div>
          </div>
        </div>
        <div className="plan-pricing-div-note">
          <FormattedMessage
            id="MarketplaceAccount.subscriptions.confirm.pricingNote"
            defaultMessage="Configure APIs access now or do it later from My Subscriptions."
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(SubscribedPlanContentPricing);
