import React from "react";
import {
  AutoExpansionPanelGroup,
  AutoExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelHeader,
  ExpansionPanelContentBody,
  ExpansionPanelContentActions
} from "@cdk-uip/react-expansion-panel";
import { injectIntl } from "react-intl";
import SubscriptionPlanHeader from "../subscription-plan-header";
import SubscribedPlanContent from "./subscribed-plan-content";
import SubscribedPlanActions from "./subscribed-plan-actions";

class SubscribedPlanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true
    };
  }

  render() {
    const { solution, plan, subscriptionDetails } = this.props;
    return (
      <AutoExpansionPanelGroup>
        <AutoExpansionPanel
          expanded={this.state.expand}
          className="subscription-plan-details subscribed-plan-details"
        >
          <ExpansionPanelHeader
            onClick={() => {
              this.setState({
                expand: true
              });
            }}
            className="subscribed-plan-header"
          >
            <SubscriptionPlanHeader plan={plan} />
          </ExpansionPanelHeader>
          <ExpansionPanelContent className="subscription-plan-details-content-panel">
            <ExpansionPanelContentBody>
              <SubscribedPlanContent solution={solution} plan={plan} />
            </ExpansionPanelContentBody>
            <ExpansionPanelContentActions>
              <SubscribedPlanActions
                solution={solution}
                subscriptionDetails={subscriptionDetails}
              />
            </ExpansionPanelContentActions>
          </ExpansionPanelContent>
        </AutoExpansionPanel>
      </AutoExpansionPanelGroup>
    );
  }
}

export default injectIntl(SubscribedPlanDetails);
