import React from 'react';
import { Dialog } from '@cdk-uip/react-dialog';
import { Button } from '@cdk-uip/react-button';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import './DealerSuiteSubsribeDialog.scss';
import fortellisLogo from '../../assets/fortellisLogo.png';
import cdkLogo from './cdkLogo.png';

class DealerSuiteLoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const redirectToDealerSuiteSolution = () => {
      const {
        solution: { id: solutionId } = {},
        plan: { id: planId } = {},
        solutionName
      } = this.props;

      const dealerPortalUrl = `${
        environmentURLs.dealerSuiteLogin
      }/${solutionId}/${planId}`;

      window.open(dealerPortalUrl);
    };
    const redirectToFortellisLogin = () => {
      this.props.auth.login({ return_url: window.location.pathname });
    };
    const { open, onAccept, onCancel, customClassName } = this.props;
    return (
      <div
        className={`ds-login-dialog ${customClassName ? customClassName : ''}`}
      >
        <Dialog open={open} onAccept={onAccept} onCancel={onCancel}>
          <div className="dialogs-with-no-scroll">
            <div className="cdk-logo-horiz-reg-rgb">
              <img alt="CDK LOGO" src={cdkLogo} className="cdkLogo" />
            </div>
            <div className="ds-content-dialogue">
              <FormattedMessage
                id="DealerSuite.messagePart1"
                defaultMessage="Subscribe using your existing DealerSuite or"
              />
              <br />
              <FormattedMessage
                id="DealerSuite.messagePart1"
                defaultMessage="CDK Connect credentials."
              />
            </div>
            <div className="common-button-margin">
              <Button
                className="ds-button-blue"
                onClick={redirectToDealerSuiteSolution}
                style={{ backgroundColor: '#0E79CC' }}
              >
                <span className="ds-button-blue-text">
                  SUBSCRIBE AS A CDK CUSTOMER
                </span>
              </Button>
            </div>
            <div className="line-margin">
              <div className="separator">or</div>
            </div>
            <div>
              <img
                alt="FORTELLIS LOGO"
                src={fortellisLogo}
                className="ds-fortellis-logo"
              />
            </div>
            <div className="ds-content">
              <FormattedMessage
                id="DealerSuite.fortellisMessage"
                defaultMessage="Subscribe by signing in with your Fortellis ID."
              />
            </div>
            <div>
              <Button
                className="ds-button-content"
                onClick={redirectToFortellisLogin}
                style={{ backgroundColor: '#904778' }}
              >
                <span className="ds-button-blue-text">
                  SIGN IN TO FORTELLIS
                </span>
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default injectIntl(withRouter(withAuth(DealerSuiteLoginDialog)));
