import React from "react";
import { injectIntl } from "react-intl";
import { CardHeader } from "@cdk-uip/react-card";
import SolutionLogo from "../../common/images/cogs.png";
import MySubscriptionFreeLabel from "./my-subscription-free-label";
import MySubscriptionStatus from "./my-subscription-status";
import MySubscriptionListItemHeaderLogo from "./my-subscription-list-item-header-logo";
import MySubscriptionListItemHeaderTitle from "./my-subscription-list-item-header-title";
import ApiConfigStatusForSubscriptionConstants from "../../common/ApiConfigStatusForSubscriptionConstants";

class MySubscriptionListItemHeader extends React.Component {
  render() {
    const { subscription, status } = this.props;
    return subscription.solution && subscription.solution.overview ? (
      <CardHeader className="subscription-card-header">
        <div className="subscription-card-title-div">
          {subscription.solution.overview.solutionLogo ? (
            <MySubscriptionListItemHeaderLogo
              image={`${subscription.solution.overview
                .solutionLogo}?${Math.random()}`}
            />
          ) : (
            <MySubscriptionListItemHeaderLogo image={SolutionLogo} />
          )}

          <MySubscriptionListItemHeaderTitle
            name={
              subscription &&
              subscription.solution &&
              subscription.solution.overview
                ? subscription.solution.overview.name
                : null
            }
            dateOfPurchase={
              subscription && subscription.dateOfPurchase
                ? subscription.dateOfPurchase
                : null
            }
          />

          {status !== ApiConfigStatusForSubscriptionConstants.ACTIVE &&
            status !== ApiConfigStatusForSubscriptionConstants.EXPIRED &&
            status !== ApiConfigStatusForSubscriptionConstants.CANCELLED && (
              <MySubscriptionFreeLabel />
            )}
        </div>
        {status ? (
          <div className="subscription-card-status-div">
            <MySubscriptionStatus status={status} />
          </div>
        ) : null}
      </CardHeader>
    ) : (
      false
    );
  }
}

export default injectIntl(MySubscriptionListItemHeader);
