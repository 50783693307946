import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CardText } from '@cdk-uip/react-card';
import moment from 'moment';

const subscriptionManagementBillingDetails = props => {
  const { plan, billingStartsOn, billingEndsOn } = props;
  return (
    <div className="subscription-management-billing-details">
      <div className="subscription-management-billing-details-content">
        <CardText className="subscription-billing-card-content">
          <div className="subscription-card-content-activated">
            <div className="plan-name">
              <div className="plan-details-label">
                <FormattedMessage
                  id="SubscriptionManagement.BillingDetails.DatatableHeader.planName"
                  defaultMessage="Plan Name"
                />
              </div>
              <div className="plan-details-value">
                <div className="subscription-card-title-label">
                  {plan?.planName ? (
                    <span
                      className="subscription-card-title-label-text"
                      style={{ 'margin-left': '0px' }}
                    >
                      {plan.planName}
                    </span>
                  ) : (
                    <span className="plan-details-value-date"> NA </span>
                  )}
                </div>
              </div>
            </div>
            <div className="plan-dates">
              <div className="plan-details-label">
                <FormattedMessage
                  id="SubscriptionManagement.BillingDetails.DatatableHeader.billingStartDate"
                  defaultMessage="Billing Start Date"
                />
              </div>
              <div className="plan-details-value-date">
                {!!billingStartsOn
                  ? moment(billingStartsOn).format('DD MMM YYYY')
                  : 'NA'}
              </div>
            </div>
            <div className="plan-dates">
              <div className="plan-details-label">
                <FormattedMessage
                  id="SubscriptionManagement.BillingDetails.DatatableHeader.billingEndDate"
                  defaultMessage="Billing End Date"
                />
              </div>
              <div className="plan-details-value-date">
                {!!billingEndsOn
                  ? moment(billingEndsOn).format('DD MMM YYYY')
                  : 'NA'}
              </div>
            </div>
            <div className="plan-price">
              <div className="plan-details-label">
                <FormattedMessage
                  id="SubscriptionManagement.BillingDetails.DatatableHeader.price"
                  defaultMessage="Price"
                />
              </div>
              <div className="plan-details-value">
                <span className="plan-details-price-digit">
                  {plan.billingType === 'TRANSACTIONAL' &&
                    plan.transactionalTypeOptions === '%' &&
                    `${plan.includedTransactionalFee || 0.0} ${
                      plan.transactionalTypeOptions
                    }`}
                  {plan.billingType === 'TRANSACTIONAL' &&
                    plan.transactionalTypeOptions === '$' &&
                    `${
                      plan.transactionalTypeOptions
                    } ${plan.includedTransactionalFee || 0.0}`}
                  {plan.basePrice ? `$${plan.basePrice.toFixed(2)}` : null}
                  {!plan.basePrice && !plan.includedTransactionalFee && `$0.00`}
                </span>
                <span className="plan-details-price-per">
                  {plan.isTheFreeTrial && ' / month'}
                  {plan.billingType === 'RECURRING' &&
                    plan.billingFrequency &&
                    ` / ${plan.billingFrequency.toLowerCase()}`}
                  {plan.billingType === 'TRANSACTIONAL' &&
                    plan.includedTransactionalType &&
                    `/ ${plan.includedTransactionalType}`}
                </span>
              </div>
            </div>
          </div>
        </CardText>
        <div>
          <div className="subscription-management-complete-message-divider">
            <div className="provisioning-billing-details-container">
              <div className="subscription-management-card-item-body">
                {props.completedByMessage}
              </div>
              {props.comment ? (
                <div className="subscription-provisioning-completion-comment">
                  <div className="subscription-entity-expansion-panel-body-field-title">
                    <FormattedMessage
                      id="SubscriptionEntity.phone"
                      defaultMessage="Comment "
                    />
                  </div>
                  <div className="subscription-entity-expansion-panel-body-field-content">
                    {props.comment}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default subscriptionManagementBillingDetails;
