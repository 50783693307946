import React from 'react';
import { injectIntl } from 'react-intl';
import { withApollo } from 'react-apollo';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { withRouter } from 'react-router-dom';
import { Title } from '@cdk-uip/react-typography';
import { CardHeader, Card } from '@cdk-uip/react-card';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import {
  DataTable,
  DataTableRow,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell
} from '@cdk-uip/react-data-table';
import { StatusIndicatorInline } from '@cdk-uip/react-status-indicator';

import MySubscriptionListItemHeaderTitle from '../../../subscriptions/my-subscription-list-item-header-title';
import MySubscriptionListItemHeaderLogo from '../../../subscriptions/my-subscription-list-item-header-logo';
import ApiConnectionStatusConstants from '../../../../common/ApiConnectionStatusConstants';
import SolutionLogo from '../../../../common/images/cogs.png';
import Spinner from '../../../../common/Spinner';
import EntityDetails from '../../../../common/EntityDetails';
import SubscriptionPlanDetails from './SubscriptionPlanDetails';

export const getProviders = gql`
  query($subscriptionId: ID) {
    providers {
      id
      name
      entityId
      productName
      api
      fqdn
      adminAPIUrl
    }
    getSubscription(subscriptionId: $subscriptionId) {
      subscriptionId
      entityId
      solutionId
      status
      connections {
        api
        solutionId
        connectionId
        providerId
        subscriptionId
        entityId
        status
      }
    }
  }
`;

class SubscriptionApiConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProviders: null,
      error: null,
      allAPIs: null,
      allConnections: null
    };
  }

  async fetchProviders(subscriptionId) {
    let response = await this.props.client.query({
      query: getProviders,
      variables: { subscriptionId: subscriptionId }
    });
    if (!response.errors && response.data && response.data.providers) {
      const {
        providers,
        getSubscription: { connections } = {}
      } = response.data;
      this.setState({
        allProviders: providers,
        allAPIs: providers,
        allConnections: connections
      });
    } else {
      this.setState({ error: 'Problem while fetching providers' });
    }
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.subscriptionId
    ) {
      this.fetchProviders(this.props.match.params.subscriptionId);
    }
  }

  getAPIName(apiName) {
    if (!this.state.allConnections || !this.state.allConnections.length) {
      return '';
    }

    const apis = this.state.allConnections.filter(
      apiDetails =>
        (apiDetails.api && apiDetails.api.toLowerCase()) ===
        apiName.toLowerCase()
    );

    return (apis.length && apis[0].api) || '';
  }

  getProviderName(providerId, entityID) {
    if (!this.state.allProviders || !this.state.allProviders.length) {
      return '';
    }
    let providers = this.state.allProviders.filter(
      provider => provider.id === providerId
    );
    if (!providers.length) {
      providers = this.state.allProviders.filter(
        provider => provider.entityId === entityID
      );
    }
    if (!providers.length) {
      return '';
    }
    return providers[0].name;
  }

  render() {
    if (!this.props.dbSubscription && !this.props.platformSubscription) {
      return null;
    }

    const {
      platformSubscription,
      dbSubscription,
      entity,
      solution
    } = this.props;
    if (!solution || !solution.overview) {
      return null;
    }

    return (
      <div>
        <LayoutGridCell span={9}>
          <LayoutGrid className="fm-form-layout-grid">
            <LayoutGridCell span={12} className="form-box">
              <div>
                <div className="subscription-management-form-title">
                  <Title className="fm-page-content-title fm-page-solution-submission-title">
                    <FormattedMessage
                      id="SubscriptionManagement.apiConfiguration"
                      defaultMessage="API Configuration"
                    />
                  </Title>
                </div>

                <div className="subscription-entity">
                  <EntityDetails entity={entity} />
                </div>
                <Card className="subscription-card">
                  <CardHeader className="subscription-card-header">
                    <div className="subscription-card-header-content">
                      <div style={{ display: 'inline-flex' }}>
                        <MySubscriptionListItemHeaderLogo
                          image={solution.overview.solutionLogo || SolutionLogo}
                        />
                      </div>
                      <div className="subscription-page-card-title-div">
                        <MySubscriptionListItemHeaderTitle
                          name={solution.overview.name}
                          dateOfPurchase={
                            dbSubscription?.subscriptionCreatedOn ||
                            platformSubscription.activated
                          }
                          subscriptionId={
                            dbSubscription?.subscriptionId ||
                            platformSubscription.subscriptionId
                          }
                          activateOnly={solution.activateOnly}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <div className="subscription-api-config-card-body">
                    {!this.state.allProviders && !this.state.error ? (
                      <Spinner />
                    ) : (
                      <DataTable>
                        <DataTableHeader>
                          <DataTableRow>
                            <DataTableHeaderCell
                              className="apiconfig-table-header"
                              nonNumeric
                            >
                              <FormattedMessage
                                id="SubscriptionManagement.DatatableHeader.apiName"
                                defaultMessage="API Name"
                              />
                            </DataTableHeaderCell>
                            <DataTableHeaderCell
                              className="apiconfig-table-header"
                              nonNumeric
                            >
                              <FormattedMessage
                                id="SubscriptionManagement.DatatableHeader.provider"
                                defaultMessage="Provider"
                              />
                            </DataTableHeaderCell>
                            <DataTableHeaderCell
                              className="apiconfig-table-header"
                              nonNumeric
                              style={{ textAlign: 'right' }}
                            >
                              <FormattedMessage
                                id="SubscriptionManagement.DatatableHeader.status"
                                defaultMessage="Status"
                              />
                            </DataTableHeaderCell>
                          </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                          {platformSubscription.connections &&
                            platformSubscription.connections.map(connection => {
                              return (
                                <DataTableRow key={connection.api}>
                                  <DataTableCell
                                    className="api-name"
                                    nonNumeric
                                  >
                                    {connection.apiType !== 'async-api'
                                      ? this.getAPIName(connection.api)
                                      : connection.apiDisplayName}
                                  </DataTableCell>
                                  <DataTableCell nonNumeric>
                                    {connection.apiType !== 'async-api'
                                      ? this.getProviderName(
                                          connection.providerId,
                                          connection.entityId
                                        )
                                      : connection.apiProviderName}
                                  </DataTableCell>
                                  <DataTableCell
                                    className="uppercase_text subscription-spec-status"
                                    nonNumeric
                                  >
                                    {connection.status ===
                                    ApiConnectionStatusConstants.INACTIVE ? (
                                      <span>
                                        {connection.loading ? (
                                          <Spinner />
                                        ) : (
                                          <span>
                                            Inactive
                                            <StatusIndicatorInline
                                              type="inactive"
                                              className="api-enablement-status-indicator"
                                            />
                                          </span>
                                        )}
                                      </span>
                                    ) : connection.status ===
                                      ApiConnectionStatusConstants.PENDING ? (
                                      <span>
                                        {connection.loading ? (
                                          <Spinner />
                                        ) : (
                                          <span>
                                            Pending Approval
                                            <StatusIndicatorInline
                                              type="warning"
                                              className="api-enablement-status-indicator"
                                            />
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <span>
                                        {connection.loading ? (
                                          <Spinner />
                                        ) : (
                                          <span>
                                            Active
                                            <StatusIndicatorInline
                                              type="positive"
                                              className="api-enablement-status-indicator"
                                            />
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </DataTableCell>
                                </DataTableRow>
                              );
                            })}
                        </DataTableBody>
                      </DataTable>
                    )}
                  </div>
                </Card>
                {solution?.activateOnly === false &&
                this.props?.dbSubscription ? (
                  <div className="subscription-management-plan-details">
                    <div className="subscription-management-plan-details-header">
                      <FormattedMessage
                        id="SubscriptionManagement.PlanDetails.title"
                        defaultMessage="Plan Details"
                      />
                    </div>
                    <SubscriptionPlanDetails
                      plan={dbSubscription?.plan || 'No Info Available'}
                    />
                  </div>
                ) : null}
              </div>
            </LayoutGridCell>
          </LayoutGrid>
        </LayoutGridCell>
      </div>
    );
  }
}

export default injectIntl(
  withAuth(withApollo(withRouter(SubscriptionApiConfiguration)))
);
