import React from 'react';
import PropTypes from 'prop-types';
import SolutionStatusColor from '../common/SolutionStatusColor';
import SolutionStatus from '../common/SolutionStatus';

const SolnStatus = ({ status }) => {
  function replaceRejectedWithNotApproved(status) {
    if (status && status === SolutionStatus.REJECTED) {
      return SolutionStatus.NOT_APPROVED;
    }
    return status;
  }

  return (
    <div>
      {status && (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              fontFamily: 'Open Sans',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              minWidth: '110px'
            }}
          >
            {replaceRejectedWithNotApproved(status)}
          </span>
          <div
            style={{
              verticalAlign: 'textBottom',
              marginLeft: '4px',
              height: '16px',
              width: '16px',
              borderRadius: '50%',
              backgroundColor: SolutionStatusColor[status.replace(/ /g, '_')]
            }}
          />
        </span>
      )}
    </div>
  );
};

SolnStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default SolnStatus;
