import React from 'react';
import Spinner from '../../../common/Spinner';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import SideNav from '../../../solution-submission/common-sidenav-api/SideNavComponent/SideNav';
import LeftNavConfig from './left-nav-config';
import { injectIntl } from 'react-intl';
import SelectedPanel from './SelectedPanel';
import SolutionStatus from '../../../common/SolutionStatus';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import SolutionHeaderCommon from './SolutionHeaderCommon';
import moment from 'moment';
import FortellisConstants from '../../../common/FortellisConstants';
import UnauthorizedPage from '../../../common/UnauthorizedPage';
import NotFound from '../../../common/NotFound';
import PaymentInfoConstants from '../../../admin-account/solution-moderation/PaymentInfoConstants';
import navConstants from './left-nav-config-constants';
import { findIndex } from 'lodash';
import { connect } from 'react-redux';
import { getPaymentSetting } from '../../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';
import { getStripeSubscriptionInfo } from '../../../../redux/connectedComponents/stripeSubscriptionInfo/stripeSubscriptionInfo.slice';
import { sendAmplitudeData } from '../../../../utils/amplitude';
import { AMPLITUDE_PROPERTIES } from '../../../../utils/amplitude-constants';

class SolutionManagement extends React.Component {
  constructor(props) {
    super(props);
    let menu = {
      ...JSON.parse(JSON.stringify(LeftNavConfig.MenuItems)),
      activeMenuId:
        this.props.history.location && this.props.history.location.state
          ? this.props.history.location.state.activeTab
          : LeftNavConfig.MenuItems.activeMenuId
    };
    let activeMenuId = 'manageListing';
    let activeMenuItem = {
      id: 'manageListing',
      label: 'Manage Listing Versions',
      type: 'simple'
    };
    if (this.props.solnTobePublished || this.props.otherPaymentOption) {
      activeMenuId = 'manageListing';
      menu = {
        ...JSON.parse(JSON.stringify(LeftNavConfig.MenuItems)),
        activeMenuId: 'manageListing'
      };
      activeMenuItem = {
        id: 'manageListing',
        label: 'Manage Listing Versions',
        type: 'simple'
      };
    }

    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.activeTab
    ) {
      activeMenuId = this.props.history.location.state.activeTab;
      activeMenuItem = {
        id: this.props.history.location.state.activeTab,
        label:
          this.props.history.location.state.activeTab.charAt(0).toUpperCase() +
          this.props.history.location.state.activeTab.slice(1),
        type: 'simple'
      };
    }

    this.state = {
      menu:
        this.props.match.params.activeMenuId !== 'appActivation'
          ? menu
          : {
              ...JSON.parse(JSON.stringify(LeftNavConfig.MenuItems)),
              activeMenuId: 'appActivation'
            },
      activeMenuId:
        this.props.match.params.activeMenuId !== 'appActivation'
          ? activeMenuId
          : 'appActivation',
      activeMenuItem:
        this.props.match.params.activeMenuId !== 'appActivation'
          ? activeMenuItem
          : {
              id: 'appActivation',
              label: 'App Activation',
              type: 'simple'
            },
      randomId: '',
      publishRequest: false
    };
  }

  handleOnApprovedAndOnlinePaymentModeSelected = () => {
    let currentMenu = this.state.menu;
    let resultArr = currentMenu.contents.filter(
      item => item.title === navConstants.REMITTANCE
    );
    if (resultArr.length === 0) {
      //add the remittance tab config in the menu.
      currentMenu.contents.push(navConstants.remittanceTabConfig);
      this.setState({ menu: currentMenu });
    }
  };

  componentDidUpdate(prevProps) {
    const paymentFlag =
      (!prevProps.solutions &&
        this.props.solutions &&
        this.props.solutions[0] &&
        !this.props.solutions[0].payment_info) ||
      (!prevProps.solutions &&
        this.props.solutions &&
        this.props.solutions[0] &&
        this.props.solutions[0].payment_info &&
        this.props.solutions[0].payment_info.paymentMode !==
          PaymentInfoConstants.paymentMode.ONLINE &&
        this.props.solutions[0].payment_info.paymentMode !==
          PaymentInfoConstants.paymentMode.OFFLINE_AND_ONLINE);

    let currentMenu = this.state.menu;
    let newMenu = { ...currentMenu };
    const shareSection = {
      id: 'share',
      label: 'Share',
      type: 'simple'
    };

    if (paymentFlag) {
      let remittanceTabIndex = currentMenu.contents.findIndex(
        item => item.title === navConstants.REMITTANCE
      );
      const newRemittanceSteps = currentMenu.contents[
        remittanceTabIndex
      ].steps.filter(item => item.label !== 'Accounts');
      currentMenu.contents[remittanceTabIndex].steps = newRemittanceSteps;
      newMenu.contents = currentMenu.contents;
    }

    let sharedSectionFlage = false;
    let publicSolution = false;
    if (this.props.solutions !== prevProps.solutions) {
      if (this.props.solutions && this.props.solutions.length > 0) {
        const publishedSolution = this.props.solutions.filter(
          solution => solution.status === 'Published'
        );
        if (publishedSolution && publishedSolution.length > 0) {
          const { solution_visibility } = publishedSolution[
            publishedSolution.length - 1
          ];
          if (solution_visibility) {
            const isVisible =
              solution_visibility.solutionVisibilityType === 'Private';
            if (isVisible) {
              sharedSectionFlage = true;
              if (newMenu.contents && newMenu.contents.length > 0) {
                if (newMenu['contents'][0]['steps']) {
                  const notAlreadyIncluded =
                    findIndex(this.state.menu['contents'][0]['steps'], {
                      id: 'share'
                    }) === -1;
                  if (notAlreadyIncluded) {
                    newMenu['contents'][0]['steps'].push(shareSection);
                  }
                }
              }
            } else {
              publicSolution =
                findIndex(this.state.menu['contents'][0]['steps'], {
                  id: 'share'
                }) >= 0;
              if (publicSolution) {
                newMenu['contents'][0]['steps'].pop();
              }
            }
          }
        }
      }
    }
    if (paymentFlag || sharedSectionFlage || publicSolution) {
      this.setState({ menu: newMenu });
    }
    if (this.props.otherPaymentOption !== prevProps.otherPaymentOption) {
      console.log('here we will trigger the event');
      this.changeMenu('manageListing');
    }
    if (
      !prevProps.solutions &&
      this.props.solutions &&
      this.props.solutions.length &&
      this.props.solutions[0].activateOnly !== false
    ) {
      this.props.getStripeSubscriptionData(
        this.props.solutions[0].id,
        this.props.solutions[0].orgId,
        this.props.auth.accessToken
      );
    }
  }

  handleMenuStateChange = newMenuState => {
    let menuNew = JSON.parse(JSON.stringify(newMenuState));
    this.setState({ menu: menuNew });
    let menuLabel;
    let activeMenuId = menuNew.activeMenuId;
    menuNew.contents.forEach(content => {
      content.steps.forEach(menuItem => {
        if (menuItem.id === activeMenuId) {
          menuLabel = menuItem.label;
          this.setState({
            activeMenuItem: menuItem,
            activeMenuId: activeMenuId
          });
        }
      });
    });
    const { entity, solutions } = this.props;
    sendAmplitudeData(menuLabel, {
      [AMPLITUDE_PROPERTIES.ORG_ID]: entity.id,
      [AMPLITUDE_PROPERTIES.ORG_NAME]: entity.name,
      [AMPLITUDE_PROPERTIES.APP_ID]: solutions[0].id,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solutions[0].overview.name
    });
  };

  onEdit = async solution => {
    try {
      //run mutation to add a new solution listing version with status draft
      let solutionToCopy = { ...solution };
      solutionToCopy.status = SolutionStatus.DRAFT;
      solutionToCopy.registeredOn = moment().toISOString();

      await this.props.solutionListing.execute(solutionToCopy);

      await this.refetchData();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  onOperationComplete = async () => {
    await this.refetchData();
  };

  refetchData = async () => {
    await this.props.dataRefetch();
  };

  static async getDerivedStateFromProps(nextProps, prevState) {
    await nextProps.dataRefetch();
  }

  getOverallSolutionStatus = solutions => {
    if (solutions && solutions.length) {
      let solutionForStatus = solutions[0];
      if (
        solutions.length > 1 &&
        (solutions[0].status !== SolutionStatus.PUBLISHED ||
          solutions[0].status !== SolutionStatus.WITHDRAWN)
      ) {
        solutions.forEach(item => {
          if (
            (item.status === SolutionStatus.PUBLISHED ||
              item.status === SolutionStatus.WITHDRAWN) &&
            item.listingVersion > solutionForStatus.listingVersion
          ) {
            solutionForStatus = item;
          }
        });
      }
      return solutionForStatus;
    }
  };

  handleToggleChange = expandedMenuId => {
    this.setState({
      menu: Object.assign({}, this.state.menu, { expandedMenuId })
    });
  };

  changeMenu(activeMenuId) {
    if (!this.state.menu || !this.state.menu.contents) {
      return;
    }

    let filteredMenuItems = this.state.menu.contents
      .map(menuItem => {
        let filteredMenuItem = menuItem.steps.filter(step => {
          return step.id === activeMenuId;
        });
        return filteredMenuItem.length ? filteredMenuItem[0] : null;
      })
      .filter(item => item);
    if (!filteredMenuItems.length) {
      return;
    }
    let menu = JSON.parse(JSON.stringify(this.state.menu));
    menu.activeMenuId = activeMenuId;
    this.setState({
      activeMenuId: activeMenuId,
      activeMenuItem: filteredMenuItems[0],
      menu: menu
    });
  }

  refreshPendingAppActivations = () => {
    this.setState({ randomId: this.Id() });
  };
  componentDidMount() {
    this.props.getPaymentSettingData(
      this.props.auth.userData.email,
      this.props.entity.address.countryCode,
      this.props.auth.accessToken
    );
  }

  Id() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  render() {
    const {
      loading,
      solutions,
      userPermissions,
      auth: { userData, accessToken },
      entity
    } = this.props;
    const { menu, activeMenuId, activeMenuItem } = this.state;
    if (
      userData &&
      solutions &&
      solutions.length &&
      solutions[0] &&
      solutions[0].orgId !== entity.id
    ) {
      return (
        <div className="solution-belongs-to-diff-entity-txt-container">
          <UnauthorizedPage />
        </div>
      );
    }

    if (
      solutions &&
      solutions.length &&
      solutions.slice(-1)[0].activateOnly !== false &&
      menu.contents[1].steps[2].label !== 'Activations'
    ) {
      let activationMenu = JSON.parse(JSON.stringify(this.state.menu));
      activationMenu.contents[1].steps[2].label = 'Activations';
      this.setState({
        menu: activationMenu
      });
    }

    return (
      <div className="component-content">
        {loading ? (
          <Spinner />
        ) : solutions && solutions.length > 0 ? (
          <LayoutGrid className="max-width-resp page-padding-common">
            <LayoutGridCell span={3} className="fm-side-nav">
              <SideNav
                sideNav={menu}
                handleToggleChange={this.handleToggleChange}
                handleSideNavStateChange={this.handleMenuStateChange}
              />
            </LayoutGridCell>
            <LayoutGridCell span={9}>
              <div>
                <SolutionHeaderCommon
                  solution={this.getOverallSolutionStatus(solutions)}
                  isSolutionReview={false}
                  isSolutionManagement={true}
                  refreshTriggerForPendingAppSubscriptions={this.state.randomId}
                  token={this.props.auth.accessToken}
                />
                {activeMenuId !== 'insights' && (
                  <div className="manage-solution-page-title">
                    {activeMenuItem.label ? activeMenuItem.label : false}
                  </div>
                )}
                {/* TODO - change this to show the correct status */}
                <div style={{ marginTop: '16px' }}>
                  <SelectedPanel
                    activeMenuId={activeMenuId}
                    activeMenuItem={activeMenuItem}
                    solutions={solutions}
                    userPermissions={this.props.userPermissions}
                    remittanceInfo={this.props.remittanceInfo}
                    userData={userData}
                    onEdit={this.onEdit}
                    onOperationComplete={this.onOperationComplete}
                    entity={entity}
                    match={this.props.match}
                    history={this.props.history}
                    changeMenu={this.changeMenu.bind(this)}
                    accessToken={accessToken}
                    handleOnApprovedAndOnlinePaymentModeSelected={
                      this.handleOnApprovedAndOnlinePaymentModeSelected
                    }
                    handleSideNavStateChange={this.handleMenuStateChange}
                    refreshPendingAppActivations={
                      this.refreshPendingAppActivations
                    }
                  />
                </div>
              </div>
            </LayoutGridCell>
          </LayoutGrid>
        ) : (
          <NotFound />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { data } = state.verifyAccount;
  return {
    solnTobePublished: data.verified,
    otherPaymentOption: data.otherPaymentOption
  };
};
const mapDispatchToProps = dispatch => ({
  getPaymentSettingData: (email, countryCode, token) =>
    dispatch(getPaymentSetting({ email: email, countryCode, token: token })),
  getStripeSubscriptionData: (solnId, orgId, token) => {
    dispatch(getStripeSubscriptionInfo({ id: solnId, orgId, token }));
  }
});

SolutionManagement = injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SolutionManagement)
);

class SolutionManagementContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SolutionManagement {...this.props} />
    ) : (
      <></>
    );
  }
}
export default withAuth(SolutionManagementContainer);
