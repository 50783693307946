import React from "react";
import { Button } from "@cdk-uip/react-button";

export class InsightDashboardItem extends React.Component {
  render() {
    const { label, value, onClick } = this.props;
    return (
      <div
        className={
          (this.props.className ? this.props.className : "") +
          " insights-dashboard-item"
        }
      >
        <div className="insights-dashboard-item-label">{label}</div>
        <div className="insights-dashboard-item-value-container">
          <Button onClick={onClick || null}>
            <div className="insights-dashboard-item-value-button">{value}</div>
          </Button>
        </div>
      </div>
    );
  }
}

export default InsightDashboardItem;
