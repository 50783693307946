import React from "react";
import { Tooltip } from "@cdk-uip/react-tooltip";
import { Checkbox } from "@cdk-uip/react-checkbox";

export class FormCheckBox extends React.Component {
  handleCheckBoxChange = (event, name) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = event.target.checked;
    this.props.onChange(
      this.resetDependent(event.target.checked, formFieldValues)
    );
  };

  resetDependent = (value, formFieldValues) => {
    if (this.props.fieldProperties.resetDependent) {
      let resetValues = this.props.fieldProperties.resetDependent(value);
      if (resetValues) {
        Object.keys(resetValues).forEach(key => {
          formFieldValues[key] = resetValues[key];
        });
      }
    }
    return formFieldValues;
  };

  render() {
    return (
      <div
        className="form-field-with-tooltip check-box-div"
        id={this.props.fieldProperties.name}
      >
        <Checkbox
          className="check-box-item-checkBox"
          checked={this.props.value}
          disabled={this.props.fieldProperties.isDisabled}
          required={this.props.fieldProperties.required}
          onChange={event => {
            this.handleCheckBoxChange(event, this.props.fieldProperties.name);
          }}
        />
        <label className="check-box-item-label">
          {this.props.fieldProperties.label}
          {this.props.fieldProperties.toolTipText && (
            <span>
              <i
                id={`tooltip_${this.props.fieldProperties.name}`}
                className="material-icons help-label-icon"
              >
                help
              </i>
            </span>
          )}
        </label>
        <Tooltip
          className="form-field-tooltip  checkbox-field-tooltip"
          htmlFor={`tooltip_${this.props.fieldProperties.name}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}

export default FormCheckBox;
