import SolutionStatus from '../common/SolutionStatus';
import FortellisConstants from '../common/FortellisConstants';
export class MenuDataConfig {
  static MenuData = {
    contents: [
      {
        title: 'Version Updates',
        steps: [
          {
            id: 'listingVersionUpdates',
            label: "What's New",
            type: 'simple'
          }
        ],
        showIf: function(solution) {
          return (
            solution.solutiondata.listingVersion &&
            solution.solutiondata.listingVersion >
              FortellisConstants.LISTING_VERSION.START
          );
        }
      },
      {
        title: 'App Details',
        steps: [
          {
            id: 'overview',
            label: 'Overview',
            type: 'simple'
          },
          {
            id: 'highlights',
            label: 'Highlights',
            type: 'simple'
          },
          {
            id: 'features',
            label: 'Features',
            type: 'expansion',
            nestedSteps: [
              {
                type: 'array',
                dataFrom: 'features'
              }
            ]
          },
          {
            id: 'support',
            label: 'Support',
            type: 'simple'
          },
          {
            id: 'termsOfUse',
            label: 'Terms of Use',
            type: 'simple',
            showIf: function(solution) {
              return (
                solution.solutiondata &&
                solution.solutiondata.activateOnly === false
              );
            }
          }
        ]
      },
      {
        title: 'Pricing Details',
        showIf: function(solution) {
          return (
            solution.solutiondata &&
            solution.solutiondata.activateOnly === false
          );
        },
        steps: [
          {
            id: 'plans',
            label: 'Plans',
            type: 'expansion',
            nestedSteps: [
              {
                type: 'array',
                dataFrom: 'plans'
              }
            ]
          }
        ]
      },
      {
        title: 'Registration Details',
        steps: [
          {
            id: 'registration_details',
            label: 'API Details',
            type: 'simple',
            showIf: function(solution = {}) {
              return (
                solution.solutiondata &&
                solution.solutiondata.solutionType ===
                  FortellisConstants.FORTELLIS
              );
            }
          },
          {
            id: 'partner_program_registration_details',
            label: 'API Integration',
            type: 'simple',
            showIf: function(solution = {}) {
              return (
                solution.solutiondata &&
                solution.solutiondata.solutionType ===
                  FortellisConstants.PARTNER_PROGRAM
              );
            }
          }
        ]
      },
      {
        title: 'Provisioning',
        steps: [
          {
            id: 'subscription_notifications',
            label: 'Subscription Notifications',
            type: 'simple'
          },
          {
            id: 'provisioning_interface',
            label: 'Provisioning Options',
            type: 'simple'
          }
        ]
      },
      {
        title: 'Settings',
        steps: [
          {
            id: 'solution_visibility',
            label: 'Visibility',
            type: 'simple'
          },
          {
            id: 'app_subscriptions',
            label: 'App Activation',
            type: 'simple'
          }
        ]
      },
      {
        title: 'Additional Details',
        steps: [
          {
            id: 'solution_notes',
            label: 'App Notes',
            type: 'simple'
          },
          {
            id: 'comments',
            label: 'Status History',
            type: 'simple'
          }
        ]
      }
    ],
    activeMenuId: 'overview',
    nestedActiveMenuId: 'overview',
    expandedMenuId: 'plans',
    features: [
      {
        id: 'create_feature',
        labelIcon: 'add_circle',
        label: 'Add new feature',
        type: 'simple',
        showIf: function(solution = {}) {
          let status = solution.solutiondata
            ? solution.solutiondata.status
            : '';
          switch (status) {
            case SolutionStatus.REVOKED:
            case SolutionStatus.REGISTERED:
            case SolutionStatus.RECALLED:
            case SolutionStatus.REJECTED:
            case SolutionStatus.DRAFT:
              return true;
            default:
              return false;
          }
        }
      }
    ],
    plans: [
      {
        id: 'create_plan',
        labelIcon: 'add_circle',
        label: 'Add new plan',
        type: 'simple',
        showIf: function(solution) {
          let status = solution.solutiondata
            ? solution.solutiondata.status
            : '';
          switch (status) {
            case SolutionStatus.REVOKED:
            case SolutionStatus.REGISTERED:
            case SolutionStatus.RECALLED:
            case SolutionStatus.REJECTED:
            case SolutionStatus.DRAFT:
              return true;
            default:
              return false;
          }
        }
      }
    ]
  };
}

export default MenuDataConfig;
