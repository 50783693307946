import SolutionStatus from '../common/SolutionStatus';

export const config = {
  formId: 'support',
  formTitle: 'Support',
  formValueType: 'Object',
  tabs: [
    {
      id: 'tutorials_and_documentation',
      label: 'Tutorials and Documentation',
      contents: [
        {
          id: 'tutorials_and_documentation',
          fields: [
            {
              type: 'multiple_add_form',
              name: 'tutorials_and_documentation',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: function(status) {
                return (
                  !status ||
                  status === SolutionStatus.REGISTERED ||
                  status === SolutionStatus.RECALLED ||
                  status === SolutionStatus.REJECTED ||
                  status === SolutionStatus.DRAFT ||
                  status === SolutionStatus.REVOKED
                );
              },
              addLinkText: 'ADD TUTORIALS AND DOCUMENTATION',
              formDefaultValue: {
                id: '',
                title: '',
                url: '',
                purpose: ''
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: 'title',
                        label: 'Title',
                        datacy: 'lbl_supp_tutdoc_title',
                        type: 'text_field',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        maxLength: 40,
                        toolTipText: 'Specify the title of the document.'
                      },
                      {
                        name: 'url',
                        label: 'URL',
                        datacy: 'lbl_supp_tutdoc_url',
                        type: 'text_field',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        toolTipText: 'Specify the link of the document.',
                        validationType: 'tutorials_and_documentation_url',
                        validationErrorMessage:
                          'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
                      },
                      {
                        name: 'purpose',
                        label: 'Purpose',
                        type: 'text_area',
                        style: 'formtextfield form-highlight-text-area',
                        gridStyle: 'layoutcell',
                        maxLength: 128,
                        toolTipText: 'Define the purpose of the document.'
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      id: 'questions_and_support',
      label: 'Questions and Support',
      contents: [
        {
          id: 'questions_and_support',
          fields: [
            {
              name: 'supportMessage',
              label: 'Support Message',
              datacy: 'lbl_supp_qs_supmsg',
              type: 'text_area',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              maxLength: 256,
              toolTipText:
                'This message is shown on the app listing page in support section.'
            },
            {
              name: 'supportCenterUrl',
              label: 'Support Center URL',
              datacy: 'lbl_supp_qs_suppcenurl',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText:
                'Provide a link to portal for submitting support cases.',
              validationType: 'support_center_url',
              validationErrorMessage:
                'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
            },
            {
              name: 'phoneNumber',
              label: 'Phone Number',
              datacy: 'lbl_supp_qs_phnum',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: 'Contact information for customer support.',
              validationType: 'phone_number',
              validationErrorMessage:
                'Please enter a 10-digit valid phone number.'
            },
            {
              name: 'emailAddress',
              label: 'Email Address',
              datacy: 'lbl_supp_qs_email',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: 'Contact information for customer support.',
              required: true,
              validationType: 'support_email_address',
              validationErrorMessage: 'Please enter a valid email.'
            },
            {
              name: 'supportCommunityUrl',
              label: 'Support Community URL',
              datacy: 'lbl_supp_qs_supcomurl',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText:
                'Provide a link for the community portal or forum where customers can discuss their concerns, usecases etc.',
              validationType: 'support_community_url',
              validationErrorMessage:
                'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    support: {
      tutorials_and_documentation: [],
      supportMessage: '',
      supportCenterUrl: '',
      phoneNumber: '',
      emailAddress: '',
      supportCommunityUrl: ''
    }
  },
  requiredFields: ['emailAddress'],
  activeTabId: 'tutorials_and_documentation'
};
