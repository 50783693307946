import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import SubscriptionContactDetails from '../common/subscription-contact-details';

class SubscriptionPlanContactDetails extends React.Component {
  render() {
    const { solution, plan } = this.props;
    return (
      <div>
        {solution && solution.overview && solution.overview.publisherName ? (
          <SubscriptionContactDetails
            contactNote={`App provisioning is currently pending.
          App Publisher - ${solution.overview.publisherName} will contact you
          for provisioning the ${plan.includedUnitOfMeasure &&
            plan.includedUnitOfMeasure.toLowerCase()}.`}
            solution={solution}
          />
        ) : (
          ''
        )}
        <div onClick={this.props.onShow} className="subscribed-plan-terms-link">
          <FormattedMessage
            id="MarketplaceAccount.subscriptions.termsAndConditions"
            defaultMessage="Terms and Conditions"
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(SubscriptionPlanContactDetails);
