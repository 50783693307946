import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class SubscriptionPlanDetails extends React.Component {
  render() {
    const { plan } = this.props;
    if (!plan) {
      return null;
    }

    return (
      <div className="subscription-management-plan-details-body">
        <div className="subscription-management-plan-name">{plan.planName}</div>
        {plan.includedUnitQuantity && plan.includedUnitOfMeasure ? (
          <div className="subscription-management-plan-availability">
            <FormattedMessage
              id="SubscriptionManagement.plan.availableFor"
              defaultMessage="Available for "
            />{' '}
            {plan.includedUnitQuantity + ' ' + plan.includedUnitOfMeasure}
          </div>
        ) : (
          <div />
        )}

        <div className="subscription-management-plan-features">
          {plan.features && plan.features.length
            ? plan.features.map(feature => {
                return (
                  <div className="subscription-management-plan-feature">
                    <i className="material-icons subscription-management-plan-feature-icon">
                      check_circle
                    </i>
                    <span className="subscription-management-plan-feature-text">
                      {feature.title}
                    </span>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}
