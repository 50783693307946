import { configureStore } from '@reduxjs/toolkit';
import landingPageReducer from '../connectedComponents/landingPage/landingPage.slice';
import solutionCardGroupReducer from '../connectedComponents/solutionCardGroup/solutionCardGroup.slice';
import appSubmissionReducer from '../connectedComponents/appSubmission/appSubmission.slice';
import verifyAccountSlice from '../connectedComponents/verifyAccount/verifyAccount.slice';
import subscriptionDataReducer from '../connectedComponents/adminSubscriptionData/subscriptionData.slice';
import stripeSubscriptionSlice from '../connectedComponents/stripeSubscriptionInfo/stripeSubscriptionInfo.slice';
import savedPaymentDetailsSlice from '../connectedComponents/verifyAccount/savedPaymentDetails.slice';
import paymentsInfoSlice from '../connectedComponents/paymentEdit/paymentsInfo.slice'
import bankAppsSlice from '../connectedComponents/verifyAccount/bankApps.slice';

export default configureStore({
  reducer: {
    solutionCardGroup: solutionCardGroupReducer,
    landingPage: landingPageReducer,
    appSubmission: appSubmissionReducer,
    verifyAccount: verifyAccountSlice,
    subscriptionData: subscriptionDataReducer,
    stripeSubscriptionData: stripeSubscriptionSlice,
    savedPaymentDetails: savedPaymentDetailsSlice,
    bankAppsDetails: bankAppsSlice,
    paymentsInfo: paymentsInfoSlice
  }
});
