import React, { useEffect, useState, useContext } from 'react';
import EntityContext from '@cdk-prod/fortellis-entity-context';

import ManageListing from './panels/ManageListing';
import Leads from './panels/Leads';
import Reviews from './panels/Reviews';
import PaymentHistory from './panels/PaymentHistory';
import PaymentMethod from './panels/PaymentMethod';
import SetupBilling from './panels/SetupBilling';
import Subscriptions from './panels/Subscriptions';
import AppActivation from './panels/AppActivation';
import EmptyContent from '../../../common/images/Empty State 2x.png';
import Insights from '../../../../containers/InsightsContainer';
import UtilizationContainer from '../../../../containers/UtilizationContainer';
import { Features, isEnabled } from '../../../../utils/feature-gate-utils';
import { injectIntl } from 'react-intl';
import Share from './panels/Share';

const moment = require('moment');
const FORMAT = 'MM/DD/YYYY';

const SelectedPanel = props => {
  const {
    activeMenuId,
    solutions,
    userPermissions,
    userData,
    onEdit,
    onOperationComplete,
    entity,
    match,
    remittanceInfo,
    publishRequest
  } = props;
  const [selectedPanelCache, setSelectedPanelCache] = useState(null);

  let publishedSolution =
    props.solutions &&
    Array.isArray(props.solutions) &&
    props.solutions.length > 0 &&
    props.solutions.filter(item => {
      return item.status === 'Published';
    });
  const [shareSectionList, setShareSectionList] = useState([]);

  useEffect(() => {
    let user = [];
    if (
      publishedSolution &&
      Array.isArray(publishedSolution) &&
      publishedSolution.length > 0
    ) {
      user = publishedSolution[0].solution_visibility
        ? publishedSolution[publishedSolution.length - 1].solution_visibility
            .privateSolutionUsers
        : [];
    } else if (
      props.solutions &&
      Array.isArray(props.solutions) &&
      props.solutions.length > 0 &&
      props.solutions[0].solution_visibility
    ) {
      user =
        props.solutions[props.solutions.length - 1].solution_visibility
          .privateSolutionUsers;
    }
    setShareSectionList(user);
  }, [props.solutions]);

  const [shareSectionNewUsers, setShareSectionNewUsers] = useState([]);

  function updateSelectedPanelCache(data) {
    setSelectedPanelCache(data);
  }

  switch (activeMenuId) {
    case 'insights':
      return (
        <Insights
          solutionId={solutions && solutions.length ? solutions[0].id : null}
          {...props}
        />
      );
    case 'manageListing':
      return (
        <ManageListing
          {...props}
          solutions={solutions}
          userData={userData}
          onEdit={onEdit}
          onOperationComplete={onOperationComplete}
          entity={entity}
          userPermissions={userPermissions}
          match={match}
          remittanceInfo={remittanceInfo}
          selectedPanelCache={selectedPanelCache}
          updateSelectedPanelCache={updateSelectedPanelCache}
        />
      );
    case 'appActivation':
      const publishedSolution = props.solutions.filter(
        solution => solution.status === 'Published'
      );
      return (
        <AppActivation
          {...props}
          solutionId={solutions && solutions.length ? solutions[0].id : null}
          solution={
            solutions && solutions.length
              ? solutions[solutions.length - 1]
              : null
          }
          publishedSolution={
            publishedSolution && publishedSolution.length
              ? publishedSolution[publishedSolution.length - 1]
              : null
          }
        />
      );
    case 'share':
      return (
        <Share
          solutions={solutions}
          organizationName={entity.name || ''}
          visibleList={shareSectionList}
          setVisibleList={setShareSectionList}
          newVisibleUsers={shareSectionNewUsers}
          setnewVisibleUsers={setShareSectionNewUsers}
          userData={userData}
        />
      );
    case 'leads':
      return <Leads solutionId={solutions[0].id} />;
    case 'reviews':
      return (
        <Reviews
          userPermissions={userPermissions}
          solution={solutions[0]}
          showFilters
        />
      );
    case 'subscriptions':
      return (
        <Subscriptions
          solution={solutions && solutions.length && solutions.slice(-1)[0]}
          solutionId={solutions[0].id}
          entity={entity}
        />
      );
    case 'utilization':
      return (
        <UtilizationContainer
          solutionId={solutions && solutions.length ? solutions[0].id : null}
          apiDetails={
            solutions &&
            solutions.length &&
            solutions.length > 0 &&
            solutions[solutions.length - 1].status === 'Published' &&
            solutions[solutions.length - 1].registration_details &&
            solutions[solutions.length - 1].registration_details.api_details &&
            solutions[solutions.length - 1].registration_details.api_details
              .length
              ? solutions[solutions.length - 1].registration_details.api_details
              : solutions &&
                solutions.length &&
                solutions.length >= 3 &&
                solutions[solutions.length - 2].status === 'Published' &&
                solutions[solutions.length - 2].registration_details &&
                solutions[solutions.length - 2].registration_details
                  .api_details &&
                solutions[solutions.length - 2].registration_details.api_details
                  .length
              ? solutions[solutions.length - 2].registration_details.api_details
              : null
          }
          startDate={moment()
            .startOf('month')
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')}
          endDate={moment()
            .subtract(1, 'day')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')}
          {...props}
        />
      );
    case 'setupBilling':
      return <SetupBilling {...props} userData={userData} entity={entity} />;
    case 'PaymentHistory':
      //return <BillingHistory {...props} />;
      return <PaymentHistory {...props} entity={entity} />;
    case 'PaymentMethod':
      return <PaymentMethod {...props} />;
    default:
      return (
        <div className="empty-content-div">
          <img
            alt="Empty Content"
            className="empty-content-card-img"
            src={EmptyContent}
          />
          <div className="empty-content-card-message">
            We are working to get this feature to you. Watch this space for
            more.
          </div>
        </div>
      );
  }
};

export default injectIntl(SelectedPanel);
