import React from 'react';
import FormField from './FormComponents/FormField';
import moment from 'moment';
import FormDeleteIcon from './FormComponents/FormDeleteIcon';
import { UploadImageUtils } from './utils/UploadImageUtils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tooltip } from '@cdk-uip/react-tooltip';
import EmptyContent from '../../common/empty-content';
import SolutionStatus from '../../common/SolutionStatus';

export class MultipleAddFormBuilder extends React.Component {
  state = {
    deletingForId: ''
  };

  uploadImageUtils = new UploadImageUtils();

  addItem = formDefaultValue => {
    formDefaultValue.id = moment()
      .utc()
      .format('DDMMYYYYHHmmss');
    let formFieldValues = this.props.formFieldValues;
    let name = this.props.fieldProperties.name;
    if (!formFieldValues[name]) {
      formFieldValues[name] = [];
    }
    formFieldValues[name].push(formDefaultValue);
    this.props.onChange(formFieldValues);
  };

  updateItem = formValue => {
    let formFieldValues = JSON.parse(
      JSON.stringify(this.props.formFieldValues)
    );
    let name = this.props.fieldProperties.name;
    let newValues = [];
    formFieldValues[name].forEach(value => {
      if (value.id === formValue.id) {
        Object.assign(value, formValue);
      }
      newValues.push(value);
    });
    formFieldValues[name] = newValues;
    this.props.onChange(formFieldValues);
  };

  onDelete = (valueIndex, tabField, formValue) => {
    this.setState({ deletingForId: formValue.id });
    let formFieldValues = this.props.formFieldValues;
    let name = this.props.fieldProperties.name;
    this.handleImageDelete(tabField, formValue)
      .then(() => {
        if (valueIndex > -1 && valueIndex < formFieldValues[name].length) {
          formFieldValues[name].splice(valueIndex, 1);
        }
        this.props.onChange(formFieldValues);
        this.setState({ deletingForId: '' });
      })
      .catch(error => {
        console.log(error);
        this.setState({ deletingForId: '' });
      });
  };

  handleImageDelete = (tabField, formValue) => {
    const {
      formStaticValues: { orgId }
    } = this.props;
    let promises = [];
    if (
      tabField &&
      tabField.deleteFiles &&
      tabField.multipleFields &&
      tabField.multipleFields.length > 0
    ) {
      tabField.multipleFields.forEach(field => {
        if (
          tabField.deleteFiles.includes(field.name) &&
          formValue[field.name]
        ) {
          const name = field.name;
          let fileName = `images/${orgId}/${
            this.props.formStaticValues.id
          }/${name.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
          if (formValue[field.nameFrom]) {
            let dynamicName = JSON.parse(
              JSON.stringify(formValue[field.nameFrom])
            );
            fileName = `images/${orgId}/${
              this.props.formStaticValues.id
            }/${name}/${dynamicName.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
          }
          promises.push(
            this.uploadImageUtils.deleteImage(
              fileName,
              this.props.formStaticValues.id,
              this.props.formStaticValues.listingVersion
            )
          );
        }
      });
    }
    return Promise.all(promises);
  };
  isDisabled = disabled => {
    if (disabled) {
      if (typeof disabled === 'function') {
        return disabled(this.props.formStaticValues.status);
      }
    }
    return disabled;
  };

  isFormEditable(status) {
    return [
      SolutionStatus.REVOKED,
      SolutionStatus.REGISTERED,
      SolutionStatus.RECALLED,
      SolutionStatus.REJECTED,
      SolutionStatus.DRAFT
    ].includes(status);
  }

  render() {
    const { fieldProperties, value } = this.props;
    const randomNumber = Math.random();
    const showAddLink = this.isDisabled(this.props.fieldProperties.showAddLink);
    if (!fieldProperties) {
      return false;
    }
    const content = fieldProperties.formContent;
    let valueCount = value ? value.length : 0;
    return (
      <div>
        {value && value.length ? (
          value.map((formValue, valueIndex) => {
            return (
              content.fields &&
              content.fields.map((tabField, index) => {
                return (
                  (!tabField.show ||
                    tabField.show(
                      this.props.formFieldValues[tabField.dependentOn]
                    )) && (
                    <div
                      className={fieldProperties.multiFormGridStyle}
                      key={index}
                    >
                      <div className="multiple-add-form-fields">
                        <FormField
                          fieldProperties={tabField}
                          formValues={this.props.formValues}
                          formStaticValues={this.props.formStaticValues}
                          dependentOn={this.props.dependentOn}
                          formFieldValuesAll={this.props.formFieldValuesAll}
                          formFieldValues={formValue}
                          formValueCount={valueCount}
                          value={formValue}
                          deleting={this.state.deletingForId === formValue.id}
                          onChange={formFieldValues => {
                            this.updateItem(formFieldValues);
                          }}
                        />
                      </div>

                      {showAddLink && (
                        <div
                          className="multiple-add-form-delete"
                          id="multiple-add-form-delete"
                        >
                          <FormDeleteIcon
                            confirmMessageTitle={
                              <FormattedMessage
                                id="MultipleAddFormBuilder.deleteTitle"
                                defaultMessage="Delete Item"
                              />
                            }
                            confirmMessage={
                              <FormattedMessage
                                id="MultipleAddFormBuilder.deleteMessage"
                                defaultMessage="This action cannot be undone. Are you sure you want to delete this item?"
                              />
                            }
                            onDelete={() => {
                              this.onDelete(valueIndex, tabField, formValue);
                            }}
                            deleteIconStyle="delete-icon"
                          />
                        </div>
                      )}
                    </div>
                  )
                );
              })
            );
          })
        ) : this.isFormEditable(
            this.props.formStaticValues.status
          ) ? null : this.props.activeFormId === 'overview' &&
          this.props.activeTabId === 'videos' &&
          this.props.activeSubFormId === 'overview' ? (
          this.props.formFieldValuesAll &&
          this.props.formFieldValuesAll.overview &&
          this.props.formFieldValuesAll.overview.overview &&
          !this.props.formFieldValuesAll.overview.overview.videos.length ? (
            <EmptyContent
              message={
                <FormattedMessage
                  id="ManageListing.viewSolution.overview.noVideos"
                  defaultMessage="No Videos"
                />
              }
            />
          ) : null
        ) : this.props.activeFormId === 'overview' &&
          this.props.activeTabId === 'gallery' &&
          this.props.activeSubFormId === 'overview' ? (
          this.props.formFieldValuesAll &&
          this.props.formFieldValuesAll.overview &&
          this.props.formFieldValuesAll.overview.overview &&
          !this.props.formFieldValuesAll.overview.overview.galleryImages
            .length ? (
            <EmptyContent
              message={
                <FormattedMessage
                  id="ManageListing.viewSolution.overview.noImages"
                  defaultMessage="No Images"
                />
              }
            />
          ) : null
        ) : null}

        {showAddLink &&
          (this.props.activeTabId === 'gallery' &&
          this.props.formFieldValuesAll.activateOnly !== false &&
          this.props.formFieldValuesAll.overview.overview.galleryImages
            .length >= 3 ? null : this.props.activeTabId === 'gallery' &&
            this.props.formFieldValuesAll.activateOnly === false &&
            this.props.formFieldValuesAll.overview.overview.galleryImages
              .length >= 9 ? null : (
            <span>
              <span
                data-cy="ln_mp_sol_add_item"
                className="add-multiple-item-form-link"
                onClick={() => {
                  this.addItem(
                    JSON.parse(JSON.stringify(fieldProperties.formDefaultValue))
                  );
                }}
              >
                <i className="material-icons add-multiple-item-form-icon">
                  add_circle
                </i>
                {fieldProperties.addLinkText}
              </span>
              {fieldProperties.toolTipText && (
                <span>
                  <i
                    id={`tooltip_${fieldProperties.name}${randomNumber}`}
                    className="material-icons help-label-icon"
                  >
                    help
                  </i>
                </span>
              )}
            </span>
          ))}
        <Tooltip
          className="form-field-check-box-group"
          htmlFor={`tooltip_${fieldProperties.name}${randomNumber}`}
        >
          <span>{fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}

export default injectIntl(MultipleAddFormBuilder);
