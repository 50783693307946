import React from 'react';
import ReviewsTab from '../../../../../containers/ReviewTabContainer';

class Reviews extends React.Component {
  render() {
    const { solution, userPermissions } = this.props;
    return (
      <div>
        <ReviewsTab
          {...this.props}
          solutionId={solution.id}
          listingVersion={solution.listingVersion}
          solutionVersion={solution.overview ? solution.overview.version : null}
          solutionDeveloperId={solution.developerId}
          solutionName={solution.overview ? solution.overview.name : null}
          userPermissions={userPermissions}
        />
      </div>
    );
  }
}

export default Reviews;
