export const config = {
  formId: "comments",
  formTitle: "Status History",
  formValueType: "Object",
  tabs: [
    {
      id: "comments",
      label: "Status History",
      contents: [
        {
          id: "comments",
          title: "Status History",
          fields: [
            {
              name: "status_history",
              label: "",
              type: "status_cards",
              style: ["formtextfield", "form-text-area"]
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    comments: []
  },
  activeTabId: "comments"
};
