import React from "react";
import { TextField, TextFieldHelperText } from "@cdk-uip/react-text-field";
import { Checkbox } from "@cdk-uip/react-checkbox";

export class FormTextFieldWithToggle extends React.Component {
  handleInputChange = event => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[event.target.name] = event.target.value;
    this.props.onChange(formFieldValues);
  };

  handleCheckBoxChange = (event, name) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = event.target.checked;
    this.props.onChange(
      this.resetDependent(event.target.checked, formFieldValues)
    );
  };

  resetDependent = (value, formFieldValues) => {
    if (this.props.fieldProperties.resetDependent) {
      let resetValues = this.props.fieldProperties.resetDependent(value);
      if (resetValues) {
        Object.keys(resetValues).forEach(key => {
          formFieldValues[key] = resetValues[key];
        });
      }
    }
    return formFieldValues;
  };

  render() {
    const { fieldProperties, disabledOn, formFieldValues } = this.props;
    const randomNumber = Math.random();
    const isDisabled =
      typeof fieldProperties.disabled === "function"
        ? fieldProperties.disabled(disabledOn)
        : fieldProperties.disabled;
    const markAsCheck =
      typeof fieldProperties.markAsCheck === "function"
        ? fieldProperties.markAsCheck(
            formFieldValues,
            fieldProperties.toggleField
          )
        : false;
    return (
      <div
        className={`form-field-with-toggle ${markAsCheck &&
          "form-field-with-toggle-checked"}`}
      >
        <div className="form-toggle-text-wrapper">
          <span
            className={`form-field-label-toggle ${fieldProperties.labelStyle}`}
          >
            {fieldProperties.label}
            {fieldProperties.toolTipText && (
              <span>
                <i
                  id={`tooltip_${fieldProperties.name}${randomNumber}`}
                  className="material-icons help-label-icon"
                >
                  help
                </i>
              </span>
            )}
          </span>
          <TextField
            className={`toggleformtextfield ${fieldProperties.style}`}
            name={fieldProperties.name}
            id={fieldProperties.name}
            type={fieldProperties.fieldType}
            min={fieldProperties.min}
            value={
              formFieldValues[fieldProperties.name]
                ? formFieldValues[fieldProperties.name]
                : ""
            }
            onChange={this.handleInputChange}
            helperText={
              <TextFieldHelperText validation>
                {fieldProperties.helperText}
              </TextFieldHelperText>
            }
            disabled={isDisabled}
            maxLength={fieldProperties.maxLength}
            required={fieldProperties.required}
          />
          {fieldProperties.maxLength && (
            <div className="character-remaining-div">
              {this.props.value ? this.props.value.length : 0} /{" "}
              {fieldProperties.maxLength}
            </div>
          )}
        </div>
        <div className="form-toggle-checkbox-wrapper">
          {fieldProperties.withToggle && (
            <Checkbox
              className="check-box-item-checkBox"
              checked={markAsCheck}
              disabled={isDisabled}
              onChange={event => {
                this.handleCheckBoxChange(event, fieldProperties.toggleField);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FormTextFieldWithToggle;
