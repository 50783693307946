export const config = {
  formId: 'comments',
  formTitle: 'Status History',
  formValueType: 'Object',
  tabs: [
    {
      id: 'comments',
      label: 'Status History',
      contents: [
        {
          id: 'comments',
          fields: [
            {
              name: 'status_history',
              label:
                'Status changes and notes exchange can be seen here to check the app review process.',
              type: 'status_cards',
              style: ['formtextfield', 'form-text-area']
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    comments: []
  },
  activeTabId: 'comments'
};
