import React from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import StoreListingAPI from '../../api/StoreListingAPI';

const entityQuery = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      entityName
      name
    }
  }
`;

class SolutionListItemOrgDetails extends React.Component {
  state = {
    orgName: null
  };

  getOrgName = async orgId => {
    let orgName = null;
    let response = null;
    let entityDetail = null;
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      response = await this.props.client.query({
        query: entityQuery,
        variables: {
          id: orgId
        }
      });
      if (response && response.data && response.data.entitiesFromPlatform) {
        entityDetail = response.data.entitiesFromPlatform;
      }
    } else {
      response = await StoreListingAPI.anonymous.getOrganizationById(orgId);
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.entitiesFromPlatform
      ) {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.entitiesFromPlatform
        ) {
          entityDetail = response.data.data.entitiesFromPlatform;
        }
      }
    }
    if (entityDetail && entityDetail.name) {
      orgName = entityDetail.name;
    } else if (entityDetail && entityDetail.entityName) {
      orgName = entityDetail.entityName;
    }
    return orgName;
  };

  componentDidMount() {
    if (this.props.orgId) {
      this.getOrgName(this.props.orgId).then(orgName => {
        this.setState({ orgName: orgName });
      });
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.state.orgName ? this.state.orgName : '  '}
      </div>
    );
  }
}

export default withApollo(withAuth(SolutionListItemOrgDetails));
