import React from 'react';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { Link } from 'react-router-dom';
import { Divider } from '@cdk-uip/react-divider';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import pathToRegexp from 'path-to-regexp';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

/**
 * Description : Breadcrumb Component with HOC
 * Date : 4th Dec 2018
 * author :  Fortellis Marketplace Team
 * Uses: 
      import Breadcrumbs from "./Breadcrumbs";
      let routes = {
        "home": {
        path: "/",
        breadcrumb: "Home",
        disableBreadcrumb: true // breadcrumb will be disabled on this route, but will be shown on all its children
       }, 
       "user-info": {
          path: "/:userId", // Dynamic userId url
          breadcrumb: (props)=>{
            return <div>{props.match.url +" "+props.match.params.userId}</div>
          } //any React child can be mounted in place of breadcrumb (pathparam and url is present in props.match)
        } 
      }

      let separatorIcon  = "chevron_right" // seperator between breadcrumb labels
      <Breadcrumbs routes={routes} separatorIcon={separatorIcon} />

 * 
 */

function checkLastItemVisibility(breadcrumbs, routes) {
  breadcrumbs = breadcrumbs || [];
  if (!breadcrumbs.length) {
    return [];
  }
  let lastBreadcrumbItem = breadcrumbs[breadcrumbs.length - 1];
  if (
    Object.values(routes).filter(item => {
      return (
        pathToRegexp(item.path).exec(lastBreadcrumbItem.props.match.url) &&
        item.disableBreadcrumb
      );
    }).length
  ) {
    breadcrumbs.pop();
    return checkLastItemVisibility(breadcrumbs, routes);
  }
  return breadcrumbs;
}

const isBreadcrumbVisible = (url, routes) => {
  return Object.values(routes).filter(item => {
    return item.breadcrumb && pathToRegexp(item.path).exec(url);
  }).length;
};

const getVisibleBreadcrumbs = (allBreadcrumbs, routes) => {
  allBreadcrumbs = allBreadcrumbs || [];
  let breadcrumbs = [];
  allBreadcrumbs.forEach(item => {
    isBreadcrumbVisible(item.props.match.url, routes) && breadcrumbs.push(item);
  });
  return checkLastItemVisibility(breadcrumbs, routes);
};

const renderBreadcrumbs = ({ breadcrumbs, routes, separatorIcon }) => {
  const visibleBreadcrumbs = getVisibleBreadcrumbs(breadcrumbs, routes || {});
  return visibleBreadcrumbs && visibleBreadcrumbs.length ? (
    <LayoutGrid className="breadcrumb-page-grid breadcrumb-background">
      <LayoutGridCell span={12} className="breadcrumb-page-title-cell">
        <div className="breadcrumb-page-back-title-div breadcrumb-max-width page-padding-common">
          <div className="breadcrumb-parent">
            {visibleBreadcrumbs.map((breadcrumb, index) =>
              index === visibleBreadcrumbs.length - 1 ? (
                <span key={index} className="breadcrumb-back-link">
                  <span className="breadcrumb-page-back-title-text">
                    {breadcrumb}
                  </span>
                </span>
              ) : (
                <Link
                  key={index}
                  className="breadcrumb-back-link"
                  to={{ pathname: breadcrumb.props.match.url }}
                >
                  <div
                    className={
                      index === visibleBreadcrumbs.length - 1
                        ? 'breadcrumb-page-back-title-text-active'
                        : 'breadcrumb-page-back-title-text'
                    }
                  >
                    {breadcrumb}
                  </div>
                  {index < visibleBreadcrumbs.length - 1 && (
                    <div className="breadcrumb-page-back-title-arrow">
                      <i className="material-icons">
                        {separatorIcon || 'keyboard_arrow_right'}
                      </i>
                    </div>
                  )}
                </Link>
              )
            )}
          </div>
        </div>
      </LayoutGridCell>
      <LayoutGridCell span={12}>
        <Divider />
      </LayoutGridCell>
    </LayoutGrid>
  ) : null;
};

export default props => {
  let Breadcrumb = withBreadcrumbs(Object.values(props.routes || {}))(
    renderBreadcrumbs
  );
  let BreadcrumbHOC = withAuth(Breadcrumb);
  return <BreadcrumbHOC {...props} />;
};
