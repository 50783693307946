import React from 'react';
import { injectIntl } from 'react-intl';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import LoadingSpinner from './loading-spinner';
import { Chip } from '@cdk-uip/react-chip';
import { FormattedMessage } from 'react-intl';
import SubscriptionsList from './SubscriptionsList';
import Spinner from '../common/Spinner';

class MySubscriptionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusChip: null
    };
  }
  render() {
    const { list, loading } = this.props;
    const { entity } = this.props;
    return (
      <LayoutGrid className="fm-page-component-grid fm-subscription-view-page">
        <LayoutGridCell span={12} className="fm-page-header-title">
          <div className="subscription-list-chip-parent">
            <div className="lead-card-chips-div">
              <Chip
                className={
                  this.state.statusChip === 'Active'
                    ? 'category-chip category-chip-selected'
                    : 'category-chip'
                }
                onClick={e => {
                  e.preventDefault();
                  this.state.statusChip !== 'Active'
                    ? this.setState({ statusChip: 'Active' })
                    : this.setState({ statusChip: null });
                }}
              >
                <FormattedMessage
                  id="MySubscriptionList.Chip.activeSubscription"
                  defaultMessage="Active"
                />
              </Chip>
              <Chip
                className={
                  this.state.statusChip === 'Cancelled'
                    ? 'category-chip category-chip-selected'
                    : 'category-chip'
                }
                onClick={e => {
                  e.preventDefault();
                  this.state.statusChip !== 'Cancelled'
                    ? this.setState({ statusChip: 'Cancelled' })
                    : this.setState({ statusChip: null });
                }}
              >
                <FormattedMessage
                  id="MySubscriptionList.Chip.cancelSubscription"
                  defaultMessage="Cancelled"
                />
              </Chip>
              <Chip
                className={
                  this.state.statusChip === 'Pending Activation'
                    ? 'category-chip category-chip-selected'
                    : 'category-chip'
                }
                onClick={e => {
                  e.preventDefault();
                  this.state.statusChip !== 'Pending Activation'
                    ? this.setState({ statusChip: 'Pending Activation' })
                    : this.setState({ statusChip: null });
                }}
              >
                <FormattedMessage
                  id="MySubscriptionList.Chip.pendingActivation"
                  defaultMessage="Pending Activation"
                />
              </Chip>
              <Chip
                className={
                  this.state.statusChip === 'Expired'
                    ? 'category-chip category-chip-selected'
                    : 'category-chip'
                }
                onClick={e => {
                  e.preventDefault();
                  this.state.statusChip !== 'Expired'
                    ? this.setState({ statusChip: 'Expired' })
                    : this.setState({ statusChip: null });
                }}
              >
                <FormattedMessage
                  id="MySubscriptionList.Chip.expired"
                  defaultMessage="Expired"
                />
              </Chip>
            </div>
          </div>
          {loading ? (
            <div>
              <LayoutGrid className="max-width-resp fm-solution-form-content">
                <LayoutGridCell span={12} className="save-loading-spinner">
                  <Spinner />
                  <div style={{ textAlign: 'center' }}>
                    Loading subscriptions list...
                  </div>
                </LayoutGridCell>
              </LayoutGrid>
            </div>
          ) : (
            <SubscriptionsList
              {...this.props}
              list={list}
              statusChip={this.state.statusChip}
              entity={entity}
            />
          )}
        </LayoutGridCell>
      </LayoutGrid>
    );
  }
}

export default injectIntl(MySubscriptionsList);
