import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormUrlValidation } from '../../containers/utils/FormUrlValidation';

class OverviewDetails extends React.Component {
  render() {
    const { solution } = this.props;
    return (
      <div className="solution-left-details">
        <div className="solution-left-details-section">
          <div className="sol-details__support" data-cy="lbl_overview_version">
            Version
          </div>
          <div
            className="solution-left-details-item"
            data-cy="val_overview_version"
          >
            {(solution.overview && solution.overview.version && (
              <span className="sol-details__copyright">
                {solution.overview.version}
              </span>
            )) ||
              '-'}
          </div>
        </div>
        <div className="solution-left-details-section">
          <div
            className="sol-details__support"
            data-cy="lbl_Overview_details_category"
          >
            <FormattedMessage
              id="OverviewDetails.sol_details1"
              defaultMessage="Category"
            />
          </div>
          <div className="solution-left-details-item">
            {(solution.overview && solution.overview.category && (
              <span className="sol-details__copyright">
                {solution.overview.category}
              </span>
            )) ||
              '-'}
          </div>
        </div>
        <div className="solution-left-details-section">
          <div
            className="sol-details__support"
            data-cy="lbl_Overview_details_legal"
          >
            <FormattedMessage
              id="OverviewDetails.legal"
              defaultMessage="Legal"
            />
          </div>
          <div className="solution-left-details-item">
            {(solution.activateOnly === false &&
              solution.overview &&
              solution.overview.termsAndConditionsUrl && (
                <a
                  href={FormUrlValidation.validateUrl(
                    solution.overview.termsAndConditionsUrl
                  )}
                  className="sol-details__email"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use{' '}
                  <i className="material-icons external-link-icon">
                    open_in_new
                  </i>
                </a>
              )) ||
              '-'}
          </div>
          <div className="solution-left-details-item">
            {solution.overview && solution.overview.privacyPolicyUrl && (
              <a
                href={FormUrlValidation.validateUrl(
                  solution.overview.privacyPolicyUrl
                )}
                className="sol-details__email"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy{' '}
                <i className="material-icons external-link-icon">open_in_new</i>
              </a>
            )}
          </div>
        </div>
        <div className="solution-left-details-section">
          <div className="sol-details__subheading-copyright">
            <FormattedMessage
              id="OverviewDetails.sol_details3"
              defaultMessage="Copyright"
            />
          </div>
          <div className="solution-left-details-item">
            {(solution.overview && solution.overview.copyright && (
              <div className="sol-details__copyright">
                © {solution.overview.copyright}
              </div>
            )) ||
              '-'}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(OverviewDetails);
