export const config = {
  formId: "partner_program_registration_details",
  formTitle: "API Integration",
  formValueType: "Object",
  tabs: [
    {
      id: "connectedApis",
      label: "APIs",
      contents: [
        {
          id: "connectedApis",
          fields: [
            {
              type: "multiple_add_form",
              name: "partner_api_details",
              multiFormGridStyle: "multiple-add-form-div",
              showAddLink: false,
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: "applicationName",
                        label: "Application Name",
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell"
                      },
                      {
                        name: "description",
                        label: "Description",
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell"
                      },
                      {
                        name: "dataProvider",
                        label: "Providers",
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    partner_program_registration_details: {
      partner_api_details: []
    }
  },
  activeTabId: "connectedApis"
};
