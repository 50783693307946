import SolutionStatus from '../../common/SolutionStatus';
export const config = {
  formId: 'worksWithIds',
  formTitle: 'Integrations',
  formValueType: 'Object',
  formNote:
    'Search and select the organization(s) whose data this app can integrate with',
  tabs: [
    {
      id: 'works_with_tab',
      contents: [
        {
          id: 'works_with_section',

          fields: [
            {
              name: 'worksWithIds',
              label:
                'Select the values you want to include in the works with filter of the landing page',
              type: 'searchbox_chipset',

              disabled: function(status) {
                return status && status !== SolutionStatus.IN_REVIEW;
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    worksWithIds: []
  },
  activeTabId: 'works_with_tab'
};
