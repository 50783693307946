import React from "react";
import CustomForm from "./CustomForm";

export class FormAPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const formKeys = Object.keys(this.props.formConfig);
    return formKeys.map(formKey => {
      return (
        formKey === this.props.activeFormId && (
          <CustomForm
            {...this.props}
            key={formKey}
            formConfig={this.props.formConfig[formKey]}
            formStaticValues={this.props.formStaticValues}
            formAllValues={this.props.formFieldValues}
            formFieldValues={this.props.formFieldValues[formKey]}
            activeSubFormId={this.props.activeSubFormId}
            onDelete={this.props.onDelete}
            onSave={this.props.onSave}
            isSolutionReview={this.props.isSolutionReview}
            handlePreview={this.props.handlePreview}
            isFormInvalid={this.props.isFormInvalid}
          />
        )
      );
    });
  }
}

export default FormAPI;
