import React from 'react';
import NewLandingPage from './NewLandingPage';
import { filtersObject } from './data/filtersMockData';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

const NewLandingPageContainer = props => {
  const auth = props.auth;
  const entity = props.entity;
  return (
    <NewLandingPage
      filterSections={filtersObject}
      auth={auth}
      entity={entity}
    />
  );
};

export default withAuth(NewLandingPageContainer);
