import React, { Fragment } from 'react';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
  DataTableToolbar
} from '@cdk-uip/react-data-table';
import { Dialog, DialogBody, DialogFooterButton } from '@cdk-uip/react-dialog';
import { Card } from '@cdk-uip/react-card';
import Spinner from '../../../../common/Spinner';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import EntityWithAvatar from '../../../../common/EntityWithAvatar';
import SubscriptionStatus from '../../../../common/subscription-status/SubscriptionStatus';
import SearchComponent from '../../../../common/SearchComponent';
import ListingFilters from './ListingFilters';
import EmptyContent from '../../../../common/empty-content';
import { FortellisConstants } from '../../../../common/FortellisConstants';
import SolutionTypeURLMapperUtil from '../../../../common/SolutionTypeURLMapperUtil';
import { withRouter } from 'react-router-dom';
import LearnMore from '../../../../common/LearnMore/LearnMore';
import { environmentURLs } from '../../../../common/environment/CaptureEnvironment';
class Subscriptions extends React.Component {
  state = {
    solutionSubscriptions: [],
    solutionVersionToListingVerMap: {},
    selectedSolutionVersion: FortellisConstants.SHOW_ALL,
    selectedListingVersion: FortellisConstants.SHOW_ALL,
    searchText: '',
    entity: null,
    isDialogueOpen: false
  };
  componentDidMount() {
    this.fetchSolutionSubscriptions();
  }
  fetchSolutionSubscriptions = async () => {
    const client = getApolloClient();
    this.setState({ loading: true });
    let getSolutionSubscriptionsResponse = await client.query({
      query: getSolutionSubscriptions,
      variables: {
        solutionId: this.props.solutionId
      }
    });
    getSolutionSubscriptionsResponse.data.getSolutionSubscriptions.forEach(
      subscription => {
        if (!subscription.solution) {
          subscription.solution = {
            overview: { version: FortellisConstants.NotAvailable }
          };
          subscription.listingVersion = FortellisConstants.NotAvailable;
          subscription.plan = {
            id: FortellisConstants.NotAvailable,
            planName: FortellisConstants.NotAvailable
          };
        }
      }
    );
    if (
      getSolutionSubscriptionsResponse &&
      getSolutionSubscriptionsResponse.errors
    ) {
      this.setState({ errorFetchingSubscriptions: true });
    } else if (
      getSolutionSubscriptionsResponse &&
      getSolutionSubscriptionsResponse.data &&
      getSolutionSubscriptionsResponse.data.getSolutionSubscriptions
    ) {
      this.setState({
        solutionSubscriptions:
          getSolutionSubscriptionsResponse.data.getSolutionSubscriptions
      });
      let entityIds = [];
      if (
        Array.isArray(
          getSolutionSubscriptionsResponse.data.getSolutionSubscriptions
        )
      ) {
        entityIds = getSolutionSubscriptionsResponse.data.getSolutionSubscriptions.map(
          item => {
            return item.entityId;
          }
        );
      }
      this.createSolutionVerToListingVerMapping(
        getSolutionSubscriptionsResponse.data.getSolutionSubscriptions
      );
      await this.getEntityNames(entityIds);
      getSolutionSubscriptionsResponse.data.getSolutionSubscriptions.forEach(
        subscription => {
          this.state.entity.forEach(entity => {
            if (subscription.entityId === entity.id) {
              subscription.entityName = entity.name;
            }
          });
        }
      );
    }
    this.setState({ loading: false });
  };

  getEntityNames = async entityIds => {
    const client = getApolloClient();
    let entityResponse = await client.query({
      query: getEntitiesQuery,
      variables: { id: entityIds }
    });
    if (!entityResponse.errors) {
      this.setState({
        entity: entityResponse.data.subscribedEntitiesFromPlatfom
          ? entityResponse.data.subscribedEntitiesFromPlatfom
          : null
      });
      return entityResponse.data.subscribedEntitiesFromPlatfom &&
        entityResponse.data.subscribedEntitiesFromPlatfom.name
        ? entityResponse.data.subscribedEntitiesFromPlatfom.name
        : null;
    }
    return null;
  };
  createSolutionVerToListingVerMapping = subscriptions => {
    let solVersions = subscriptions.map(
      subscription => subscription.solution.overview.version
    );
    let distictSolVersions = [...new Set(solVersions)];
    distictSolVersions = distictSolVersions.filter(version => version); //null check

    let listingVersions = subscriptions.map(
      subscription => subscription.listingVersion
    );
    let distictListingVersions = [...new Set(listingVersions)];

    let solutionVersionToListingVerMap = {};
    solutionVersionToListingVerMap[FortellisConstants.SHOW_ALL] = [
      FortellisConstants.SHOW_ALL,
      ...distictListingVersions
    ];

    distictSolVersions.forEach(version => {
      let respectiveListingVersions = [];
      respectiveListingVersions.push(FortellisConstants.SHOW_ALL);
      subscriptions.forEach(subscription => {
        if (version === subscription.solution.overview.version) {
          respectiveListingVersions.push(subscription.listingVersion);
        }
      });
      respectiveListingVersions = [...new Set(respectiveListingVersions)]; //distinct
      respectiveListingVersions = respectiveListingVersions.filter(
        listingVersion => listingVersion
      ); //null check
      solutionVersionToListingVerMap[`${version}`] = respectiveListingVersions;
    });

    this.setState({
      solutionVersionToListingVerMap: solutionVersionToListingVerMap
    });
  };

  onFilterChange = (solutionVersion, listingVersion) => {
    this.setState({ selectedSolutionVersion: solutionVersion });
    this.setState({ selectedListingVersion: listingVersion });
  };
  onSearchChange = val => {
    this.setState({ searchText: val });
  };
  onEntityReception = entity => {
    let subcriptionCopy = JSON.parse(
      JSON.stringify(this.state.solutionSubscriptions)
    );
    subcriptionCopy.forEach(subscription => {
      if (subscription.entityId === entity.id) {
        Object.assign(subscription, { entityName: entity.name });
      }
    });
    this.setState({ solutionSubscriptions: subcriptionCopy });
  };
  onClose = () => {
    this.setState({ isDialogueOpen: false });
  };

  ActivationsMessage = () => {
    return (
      <Fragment>
        <FormattedMessage
          id="Activations.helpText"
          defaultMessage={`Review the current API activations and activate or deactivate their corresponding API connections.
        Use the Search and filter options to find specific activations.`}
        />
        &nbsp;
        <LearnMore
          url={`${
            environmentURLs.docs
          }docs/marketplace/marketplace-for-apps/activating-apps/`}
        />
        <br />
        <br />
      </Fragment>
    );
  };
  SubscriptionsMessage = () => {
    return (
      <Fragment>
        <FormattedMessage
          id="Subscriptions.helpText"
          defaultMessage={`Review the current API subscriptions and subscribe or unsubscribe their corresponding API connections.
            Use the Search and filter options to find specific subscriptions.`}
        />
        &nbsp;
        <LearnMore
          url={`${
            environmentURLs.docs
          }docs/marketplace/marketplace-for-apps/activating-apps/`}
        />
        <br />
        <br />
      </Fragment>
    );
  };
  render() {
    const {
      solutionSubscriptions,
      selectedSolutionVersion,
      selectedListingVersion,
      searchText,
      entity
    } = this.state;
    const { solution } = this.props;
    let subscriptionsToDisplay = solutionSubscriptions;
    let entityDetails = entity;
    subscriptionsToDisplay.sort((a, b) => {
      return a.status.localeCompare(b.status);
    });

    if (
      solutionSubscriptions &&
      solutionSubscriptions.length &&
      selectedSolutionVersion !== FortellisConstants.SHOW_ALL
    ) {
      subscriptionsToDisplay = subscriptionsToDisplay.filter(
        subcription =>
          subcription.solution.overview.version === selectedSolutionVersion
      );
    }
    if (
      solutionSubscriptions &&
      solutionSubscriptions.length &&
      selectedListingVersion !== FortellisConstants.SHOW_ALL
    ) {
      subscriptionsToDisplay = subscriptionsToDisplay.filter(
        subcription => subcription.listingVersion === selectedListingVersion
      );
    }
    if (solutionSubscriptions && solutionSubscriptions.length && searchText) {
      let splittedSearchValues = searchText
        .toString()
        .toLowerCase()
        .trim()
        .split(' ');
      splittedSearchValues.forEach(searchText => {
        subscriptionsToDisplay = subscriptionsToDisplay.filter(
          subscription =>
            subscription.status.toLowerCase().includes(searchText) ||
            (subscription.entityName &&
              subscription.entityName.toLowerCase().includes(searchText)) ||
            (subscription.plan &&
              subscription.plan.planName &&
              subscription.plan.planName.toLowerCase().includes(searchText))
        );
      });
    }
    return (
      <div>
        <div className="api-info-subtitle">
          {solution.activateOnly === true
            ? this.ActivationsMessage()
            : this.SubscriptionsMessage()}
        </div>
        <Card className="sol-managemnt-subscriptions_table">
          <Dialog open={this.state.isDialogueOpen} onCancel={this.onClose}>
            <DialogBody className=" textAlign-center">
              This is a Test Subscription! <br />
              You cannot perform any action on this subscription.
            </DialogBody>
            <DialogFooterButton className="dialogbtn-txt" cancel>
              <span>Okay</span>
            </DialogFooterButton>
          </Dialog>
          <DataTableToolbar>
            <div className="lead-search-parent">
              <SearchComponent
                className="lead-search"
                label="Search"
                onChange={this.onSearchChange}
              />
            </div>
            <div className="listing-filters-parent">
              <ListingFilters
                optionMapping={this.state.solutionVersionToListingVerMap}
                selectedSolutionVersion={this.state.selectedSolutionVersion}
                selectedListingVersion={this.state.selectedListingVersion}
                onFilterChange={this.onFilterChange}
                className="pull-right lead-filters"
              />
            </div>
          </DataTableToolbar>
          {this.state.loading ? (
            <div>
              <Spinner />
            </div>
          ) : subscriptionsToDisplay && subscriptionsToDisplay.length > 0 ? (
            <div class="sol-management-table">
              <DataTable>
                <DataTableHeader>
                  <DataTableRow>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-left"
                      nonNumeric
                    >
                      <FormattedMessage
                        id="DeveloperSubscriptions.table.column1"
                        defaultMessage="Entity"
                      />
                    </DataTableHeaderCell>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-center"
                      nonNumeric
                    >
                      <FormattedMessage
                        id="DeveloperSubscriptions.table.column2"
                        defaultMessage="Plan"
                      />
                    </DataTableHeaderCell>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-center"
                      nonNumeric
                    >
                      <FormattedMessage
                        id="DeveloperSubscriptions.table.column3"
                        defaultMessage="App Version"
                      />
                    </DataTableHeaderCell>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-center"
                      nonNumeric
                    >
                      <FormattedMessage
                        id="DeveloperSubscriptions.table.column4"
                        defaultMessage="Listing Version"
                      />
                    </DataTableHeaderCell>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-center"
                      nonNumeric
                    >
                      <FormattedMessage
                        id="DeveloperSubscriptions.table.column5"
                        defaultMessage="Payment Mode"
                      />
                    </DataTableHeaderCell>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-center"
                      nonNumeric
                    >
                      {solution.activateOnly === false
                        ? 'Subscribed On'
                        : 'Activated On'}
                    </DataTableHeaderCell>
                    <DataTableHeaderCell
                      className="sol-managemnt-subscriptions_table-header-text textAlign-center"
                      nonNumeric
                    >
                      {solution.activateOnly === false
                        ? 'Subscription Status'
                        : 'Activation Status'}
                    </DataTableHeaderCell>
                  </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                  {subscriptionsToDisplay &&
                    subscriptionsToDisplay.map(subscription => {
                      const solutionType =
                        subscription.solution &&
                        subscription.solution.solutionType
                          ? SolutionTypeURLMapperUtil.getSolutionTypeName(
                              subscription.solution.solutionType
                            )
                          : FortellisConstants.FORTELLIS_TYPE_URL;
                      return (
                        <DataTableRow
                          className="sol-managemnt-subscriptions-table-row"
                          key={subscription.subscriptionId}
                          onClick={() => {
                            if (
                              subscription?.entityId !== this.props?.entity?.id
                            ) {
                              this.props.history.push(
                                `/marketplace-account/mysolutions/${solutionType}/${
                                  subscription.solutionId
                                }/solution-management/manage/subscriptions/${
                                  subscription.subscriptionId
                                }/${subscription.entityId}`
                              );
                            } else {
                              this.setState({ isDialogueOpen: true });
                            }
                          }}
                        >
                          <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text">
                            {/*todo - replace hardcoded entity by subscription.entityId when Entity comes from platform*/}
                            {entityDetails && (
                              <EntityWithAvatar
                                entityId={subscription.entityId}
                                entityDetails={entityDetails}
                                onEntityReception={this.onEntityReception}
                              />
                            )}
                          </DataTableCell>
                          <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text textAlign-center">
                            {solution.activateOnly === false
                              ? subscription.plan.planName || 'N/A'
                              : 'N/A'}
                          </DataTableCell>
                          <DataTableCell className="sol-managemnt-subscriptions_table-body-text textAlign-center">
                            {subscription.solution &&
                            subscription.solution.overview &&
                            subscription.solution.overview.version
                              ? subscription.solution.overview.version
                              : '-'}
                          </DataTableCell>
                          <DataTableCell className="sol-managemnt-subscriptions_table-body-text textAlign-center">
                            {subscription.listingVersion
                              ? subscription.listingVersion
                              : '-'}
                          </DataTableCell>
                          <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text textAlign-center">
                            {subscription.paymentMode
                              ? subscription.paymentMode
                              : FortellisConstants.NotAvailable}
                          </DataTableCell>
                          <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text textAlign-center">
                            {subscription.subscriptionStartDate
                              ? moment(
                                  subscription.subscriptionStartDate
                                ).format('DD MMM YYYY')
                              : FortellisConstants.NotAvailable}
                          </DataTableCell>
                          <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text textAlign-center">
                            {subscription && subscription.status && (
                              <SubscriptionStatus subscription={subscription} />
                            )}
                          </DataTableCell>
                        </DataTableRow>
                      );
                    })}
                </DataTableBody>
              </DataTable>
            </div>
          ) : (
            <EmptyContent
              message={
                <FormattedMessage
                  id="DeveloperSubscriptions.subscriptionsEmpty"
                  defaultMessage="No subscriptions yet, Check back soon!"
                />
              }
            />
          )}
        </Card>
      </div>
    );
  }
}
const getSolutionSubscriptions = gql`
  query($solutionId: ID) {
    getSolutionSubscriptions(solutionId: $solutionId) {
      id
      subscriptionId
      listingVersion
      solutionId
      entityId
      userId
      status
      isMarketPlaceSubScription
      plan {
        id
        planName
      }
      subscriptionStartDate
      solution {
        id
        developerId
        submittedOn
        overview {
          name
          version
          solutionLogo
        }
        registeredOn
      }
      paymentMode
    }
  }
`;
const getEntitiesQuery = gql`
  query($id: [ID]) {
    subscribedEntitiesFromPlatfom(id: $id) {
      id
      name
      website
      phone
      type
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
    }
  }
`;
export default withRouter(Subscriptions);
