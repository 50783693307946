import React, { useState } from 'react';
import { Radio } from '@cdk-uip/react-radio';
import FormCaption from '../../solution-submission/common-form-api/FormComponents/FormCaption';

function FormCaptionFormField(props) {
  return (
    <div className="formFields">
      <div className="layoutcell layoutcellRadio">
        <div className="layoutcell layoutcellRadio">
          <FormCaption title={props.title} style={props.style} />
        </div>
      </div>
    </div>
  );
}

export default function FeaturedAppAdminControl(props) {
  const [isFeatured, setIsFeatured] = useState(
    props.formFieldValues.isFeatured || false
  );
  const handleRadioChange = radioVal => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.isFeatured = radioVal;
    setIsFeatured(radioVal);
  };
  return (
    <div>
      <div className="formFields">
        <div className="layoutcell">
          <div className="layoutcell">
            <div
              className="form-field-with-tooltip"
              id={'featured-app-yes-radio'}
            >
              <Radio
                className="radio-item-radio"
                checked={isFeatured === true}
                disabled={props.fieldProperties.disabled}
                onChange={() => handleRadioChange(true)}
              />
              <label className="radio-item-label">Yes</label>
            </div>
          </div>
        </div>
        <FormCaptionFormField
          title={'App will be marked as featured'}
          style={'provisioningcaption'}
        />
      </div>

      <div className="formFields">
        <span className="form-content-divider" />
        <div className="layoutcell">
          <div className="layoutcell">
            <div
              className="form-field-with-tooltip"
              id={'featured-app-no-radio'}
            >
              <Radio
                className="radio-item-radio"
                checked={isFeatured === false}
                disabled={props.fieldProperties.disabled}
                onChange={() => handleRadioChange(false)}
              />
              <label className="radio-item-label">No</label>
            </div>
          </div>
        </div>
        <FormCaptionFormField
          title={'App will not be marked as featured'}
          style={'provisioningcaption'}
        />
      </div>
    </div>
  );
}
