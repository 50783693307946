import React from "react";
import { getApolloClient } from "../../../containers/GraphQLClient";
import gql from "graphql-tag";

const entityQuery = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      entityName
      name
    }
  }
`;

export class EntityBreadcrumb extends React.Component {
  state = {
    data: ""
  };

  getEntityData = async entityId => {
    const client = getApolloClient();
    let data = await client.query({
      query: entityQuery,
      variables: {
        id: entityId
      }
    });
    return data;
  };

  componentDidMount() {
    if (
      !this.props ||
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.entityId
    ) {
      return;
    }
    let data = this.getEntityData(this.props.match.params.entityId);
    data.then(response => {
      let data = "";
      if (response && response.data && response.data.entitiesFromPlatform) {
        if (response.data.entitiesFromPlatform.name) {
          data = response.data.entitiesFromPlatform.name;
        } else if (response.data.entitiesFromPlatform.entityName) {
          data = response.data.entitiesFromPlatform.entityName;
        }
      }
      this.setState({ data: data });
    });
  }

  render() {
    return <span>{this.state.data}</span>;
  }
}
