import SolutionStatus from '../common/SolutionStatus';

export const config = {
  formId: 'termsOfUse',
  formTitle: 'Terms of Use',
  formValueType: 'Object',
  tabs: [
    {
      id: 'termsOfUse',
      label: 'Terms of Use',
      contents: [
        {
          id: 'termsOfUse',
          fields: [
            {
              type: 'termsUpload'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    termsOfUse: {}
  },
  activeTabId: 'termsOfUse'
};
