import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

class SolutionNotAccessible extends React.Component {
  render() {
    return (
      <div>
        <div className="max-width-resp solution-not-accessible-container">
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="SolutionNotAccessible.message1"
              defaultMessage="This app is in private mode. Please reach out to "
            />

            <a href={'mailto:support@fortellis.io'} className="fortellis-link">
              {'support@fortellis.io'}&nbsp;
            </a>

            <FormattedMessage
              id="SolutionNotAccessible.message2"
              defaultMessage="for more details."
            />
          </div>
          <div className="unauthorized-page-content-text">
            <Link to={`/solutions`}>
              <span className="fortellis-link">
                <FormattedMessage
                  id="SolutionNotAccessible.message3"
                  defaultMessage="Click here"
                />
              </span>
            </Link>

            <FormattedMessage
              id="SolutionNotAccessible.message4"
              defaultMessage=" to continue exploring apps."
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SolutionNotAccessible);
