import React from 'react';
import { Title } from '@cdk-uip/react-typography';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { Button } from '@cdk-uip/react-button';
import '../../solution-details/SolutionDetails.scss';
import { Chip } from '@cdk-uip/react-chip';
import Spinner from '../../common/Spinner';
import ReviewDetailsWrapper from './ReviewDetailsWrapper';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import moment from 'moment';
import ActionSnackBar from '../../solution-submission/ActionSnackBar';
import { withApollo } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import EmptyContent from '../../common/empty-content';

const dateRangeGenerator = {
  today: {
    start: moment().startOf('day'),
    end: moment().endOf('day')
  },
  yesterday: {
    start: moment()
      .subtract(1, 'days')
      .startOf('day'),
    end: moment()
      .subtract(1, 'days')
      .endOf('day')
  },
  week: {
    start: moment().startOf('week'),
    end: moment().endOf('week')
  },
  month: {
    start: moment().startOf('month'),
    end: moment().endOf('month')
  }
};

export class AdminReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      tabIndex: 1,
      status: 'Submitted',
      offset: null,
      fetchingReviews: false,
      reviewing: false,
      today: [],
      yesterday: [],
      week: [],
      month: [],
      past: [],
      displaySnackbar: false,
      snackBarMessage: '',
      fetchingSolutionLogo: false
    };
  }

  chipClickHandler = async (tabIndex, status) => {
    await this.setState({
      tabIndex: tabIndex,
      status: status,
      offset: null,
      reviews: [],
      today: [],
      yesterday: [],
      week: [],
      month: [],
      past: []
    });
    this.fetchReviewsHandler();
  };

  loadMoreHandler = async () => {
    let currReviews = this.state.reviews;
    await this.fetchReviewsHandler();
    currReviews = currReviews.concat(this.state.reviews);
    this.setState({ reviews: currReviews });
  };

  reviewSorter = async reviews => {
    for (let i = 0; i < reviews.length; i++) {
      if (
        moment(reviews[i].createdDate).isBetween(
          dateRangeGenerator.today.start,
          dateRangeGenerator.today.end
        )
      ) {
        this.setState({ today: this.state.today.concat(reviews[i]) });
      } else if (
        moment(reviews[i].createdDate).isBetween(
          dateRangeGenerator.yesterday.start,
          dateRangeGenerator.yesterday.end
        )
      ) {
        this.setState({ yesterday: this.state.yesterday.concat(reviews[i]) });
      } else if (
        moment(reviews[i].createdDate).isBetween(
          dateRangeGenerator.week.start,
          dateRangeGenerator.week.end
        )
      ) {
        this.setState({ week: this.state.week.concat(reviews[i]) });
      } else if (
        moment(reviews[i].createdDate).isBetween(
          dateRangeGenerator.month.start,
          dateRangeGenerator.month.end
        )
      ) {
        this.setState({ month: this.state.month.concat(reviews[i]) });
      } else {
        this.setState({ past: this.state.past.concat(reviews[i]) });
      }
    }
  };

  getUpdatedReview = async (reviewId, groupName, status) => {
    this.setState({ displaySnackbar: false });
    await this.setState({
      reviews: this.state.reviews.filter(review => review.id !== reviewId)
    });

    if (status === 'Approved') {
      await this.setState({ snackBarMessage: 'Review approved' });
    } else if (status === 'Rejected') {
      await this.setState({ snackBarMessage: 'Review rejected' });
    } else if (status === 'Deleted') {
      await this.setState({ snackBarMessage: 'Review deleted' });
    }

    this.setState({ displaySnackbar: true });

    if (groupName === 'today') {
      this.setState({
        today: this.state.today.filter(review => review.id !== reviewId)
      });
    } else if (groupName === 'yesterday') {
      this.setState({
        yesterday: this.state.yesterday.filter(review => review.id !== reviewId)
      });
    } else if (groupName === 'week') {
      this.setState({
        week: this.state.week.filter(review => review.id !== reviewId)
      });
    } else if (groupName === 'month') {
      this.setState({
        month: this.state.month.filter(review => review.id !== reviewId)
      });
    } else if (groupName === 'past') {
      this.setState({
        past: this.state.past.filter(review => review.id !== reviewId)
      });
    }
  };

  fetchReviewsHandler = async () => {
    const existingReviewIds = this.state.reviews.map(m => m.solutionId);
    const solLogoReviewId = [];
    let responseReviews = [];
    const client = getApolloClient();
    this.setState({ fetchingReviews: true });
    let queryResponse = await client.query({
      query: fetchReviews,
      variables: { status: this.state.status, offset: this.state.offset },
      fetchPolicy: 'network-only'
    });

    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.reviews &&
      queryResponse.data.reviews.reviews
    ) {
      responseReviews = queryResponse.data.reviews.reviews;
      responseReviews.forEach(
        rvw =>
          existingReviewIds.indexOf(rvw.solutionId) === -1 &&
          solLogoReviewId.push(rvw.solutionId)
      );

      await this.setState({
        offset: queryResponse.data.reviews.pageInfo.offset,
        fetchingReviews: false
      });
    }
    solLogoReviewId.length > 0 &&
      (await this.fetchSolutionLogo(solLogoReviewId, responseReviews));
  };

  fetchSolutionLogo = async (reviewIds, reviews) => {
    const client = getApolloClient();
    this.setState({ fetchingSolutionLogo: true });
    let queryResponse = await client.query({
      query: fetchSolutionLogo,
      variables: { solutionIds: reviewIds }
    });

    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.solutionLists
    ) {
      const solLists = queryResponse.data.solutionLists;

      reviews.forEach(rvw => {
        const solution = solLists.find(sol => sol.id === rvw.solutionId);
        if (!!solution) {
          rvw.solution = solution;
        }
      });

      this.setState({
        reviews: reviews,
        fetchingSolutionLogo: false
      });
      this.reviewSorter(reviews);
    }
  };

  componentDidMount() {
    this.fetchReviewsHandler();
  }

  render() {
    const {
      auth: { userData }
    } = this.props;
    return (
      <div className="component-content">
        <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
          <LayoutGridCell span={12} className="myaccount-component-content">
            <LayoutGrid className="fm-page-component-grid">
              <LayoutGridCell
                span={12}
                className="fm-page-content-title-cell max-width-resp"
              >
                <Title className="fm-page-content-title">
                  <span>
                    <FormattedMessage
                      id="ExchangeHeaderResponsive.adminAccount.menu2"
                      defaultMessage="User Reviews"
                    />
                  </span>
                </Title>
              </LayoutGridCell>
            </LayoutGrid>
          </LayoutGridCell>
          <LayoutGridCell span={12}>
            <div className="max-width-resp">
              <LayoutGrid className="admin-solution-review-content">
                <LayoutGridCell span={12}>
                  <Chip
                    className={
                      this.state.tabIndex === 1
                        ? 'category-chip category-chip-selected'
                        : 'category-chip'
                    }
                    onClick={() => this.chipClickHandler(1, 'Submitted')}
                  >
                    <FormattedMessage
                      id="AdminReview.chip1"
                      defaultMessage="Pending"
                    />
                  </Chip>
                  <Chip
                    className={
                      this.state.tabIndex === 2
                        ? 'category-chip category-chip-selected'
                        : 'category-chip'
                    }
                    onClick={() => this.chipClickHandler(2, 'Approved')}
                  >
                    <FormattedMessage
                      id="AdminReview.chip2"
                      defaultMessage="Approved"
                    />
                  </Chip>
                  <Chip
                    className={
                      this.state.tabIndex === 3
                        ? 'category-chip category-chip-selected'
                        : 'category-chip'
                    }
                    onClick={() => this.chipClickHandler(3, 'Rejected')}
                  >
                    <FormattedMessage
                      id="AdminReview.chip3"
                      defaultMessage="Rejected"
                    />
                  </Chip>
                </LayoutGridCell>
                {this.state.fetchingReviews ? (
                  <LayoutGridCell span={12}>
                    <div>
                      <Spinner />
                    </div>
                  </LayoutGridCell>
                ) : (
                  <LayoutGridCell span={12} className="reviews-sec">
                    {this.state.reviews.length < 0 && (
                      <EmptyContent
                        message={
                          <FormattedMessage
                            id="AdminReview.emptyContent"
                            defaultMessage="No reviews yet, Check back soon!"
                          />
                        }
                      />
                    )}

                    {this.state.reviews.length > 0 && (
                      <ReviewDetailsWrapper
                        today={this.state.today}
                        yesterday={this.state.yesterday}
                        week={this.state.week}
                        month={this.state.month}
                        past={this.state.past}
                        userData={userData}
                        getUpdatedReview={this.getUpdatedReview}
                      />
                    )}
                  </LayoutGridCell>
                )}
                <LayoutGridCell span={12}>
                  {this.state.offset !== null ? (
                    <Button
                      className="review__load-more"
                      onClick={this.loadMoreHandler}
                    >
                      <FormattedMessage
                        id="AdminReview.loadMoreButton"
                        defaultMessage="Load more"
                      />
                    </Button>
                  ) : (
                    false
                  )}
                </LayoutGridCell>
              </LayoutGrid>
            </div>
            <ActionSnackBar
              show={this.state.displaySnackbar}
              message={
                this.state.displaySnackbar ? (
                  this.state.snackBarMessage
                ) : (
                  <FormattedMessage
                    id="AdminReview.snackBarMessage"
                    defaultMessage="No Action taken"
                  />
                )
              }
              close={() => {
                this.setState({ displaySnackbar: false });
              }}
            />
          </LayoutGridCell>
        </LayoutGrid>
      </div>
    );
  }
}

const fetchReviews = gql`
  query($status: String, $offset: String) {
    reviews(
      filter: {
        limit: 8
        offset: $offset
        status: $status
        createdDateDescendingSortOrder: true
      }
    ) {
      reviews {
        id
        authorName
        solutionId
        rating
        reviewText
        reviewTitle
        status
        parentId
        createdDate
      }
      pageInfo {
        offset
      }
    }
  }
`;

const fetchSolutionLogo = gql`
  query($solutionIds: [ID]) {
    solutionLists(ids: $solutionIds) {
      id
      overview {
        name
        solutionLogo
      }
    }
  }
`;

const AdminReviewComp = injectIntl(withApollo(AdminReview));

class AdminReviewContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <AdminReviewComp {...this.props} />
    ) : (
      <></>
    );
  }
}
export default withAuth(AdminReviewContainer);
