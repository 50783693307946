import React from 'react';
import gql from 'graphql-tag';
import axios from 'axios';
import { getApolloClient } from '../../../../containers/GraphQLClient';
const DOC_UPLOAD_FAILED = 'Document upload failed';
const DOC_DELETE_FAILED = 'Document deletion failure';
const MAX_FILE_SIZE_LIMIT = 5;

class UploadDocUtils extends React.Component {
  uploadDoc = async (
    file,
    fileName,
    termsType,
    signatureCoordinates = '',
    isAdminDealerConsent = false
  ) => {
    const fileSizeInMB = file.size / 1000000;
    if (fileSizeInMB > MAX_FILE_SIZE_LIMIT) {
      return Promise.reject({ message: 'Image upload failed' });
    }
    let docUrl = '';
    return this.s3Sign({
      filename: fileName,
      fileType: file.type,
      operation: 'putObject',
      signatureCoordinates: signatureCoordinates,
      termsType: termsType,
      isAdminDealerConsent: isAdminDealerConsent
    })
      .then(response => {
        const { signedRequest, url } = response.data.signS3;
        docUrl = url.toString();
        return this.uploadToS3(file, signedRequest);
      })
      .then(() => {
        return docUrl;
      })
      .catch(err => {
        return Promise.reject({ message: DOC_UPLOAD_FAILED });
      });
  };

  deleteDocFromS3(fileName, fileType, isAdminDealerConsent) {
    return this.s3Sign({
      filename: fileName,
      fileType: fileType,
      operation: 'deleteObject',
      signatureCoordinates: '',
      termsType: '',
      isAdminDealerConsent: isAdminDealerConsent
    })
      .then(response => {
        const { signedRequest } = response.data.signS3;
        return this.deleteFromS3(signedRequest);
      })
      .then(resp => {
        return resp;
      })
      .catch(error => {
        return Promise.reject({ message: DOC_DELETE_FAILED });
      });
  }

  deleteDoc = async (fileName, fileType, isAdminDealerConsent) => {
    return this.deleteDocFromS3(fileName, fileType, isAdminDealerConsent);
  };

  uploadToS3 = (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type
      }
    };
    return Promise.resolve(axios.put(signedRequest, file, options));
  };

  deleteFromS3 = signedRequest => {
    const options = {};
    return Promise.resolve(axios.delete(signedRequest, options));
  };

  s3Sign = variables => {
    const client = getApolloClient();
    return client.mutate({
      mutation: uploadDocMutation,
      variables: variables
    });
  };
}

const uploadDocMutation = gql`
  mutation(
    $filename: String!
    $fileType: String!
    $operation: String!
    $termsType: String
    $signatureCoordinates: String
    $isAdminDealerConsent: Boolean
  ) {
    signS3(
      filename: $filename
      fileType: $fileType
      operation: $operation
      termsType: $termsType
      signatureCoordinates: $signatureCoordinates
      isAdminDealerConsent: $isAdminDealerConsent
    ) {
      url
      signedRequest
    }
  }
`;

export default UploadDocUtils;
