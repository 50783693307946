import React, { useEffect, useState } from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import gql from 'graphql-tag';
import { getApolloClient } from '../../containers/GraphQLClient';
import StoreListingAPI from '../../api/StoreListingAPI';
import Spinner from '../common/Spinner';
import FeaturedListItemForSellPage from './FeaturedListItemForSellPage';
import { Grid, Row, Column } from 'cdk-radial';

const PublishedApps = ({ auth }) => {
  const [featuredSolutions, setFeaturedSolutions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orgMapObj, setOrgMapObj] = useState({});

  const makeSolutionProvidersMapObject = solutionProvidersList => {
    var mapObj = {};
    solutionProvidersList.forEach(orgObj => {
      mapObj[orgObj['id']] = orgObj['name'];
    });

    setOrgMapObj(mapObj);
  };

  const getProvidersData = async () => {
    let queryResponse = {};
    //anon service call
    let filterType = 'solutionProviders';
    try {
      let responseObj = await StoreListingAPI.anonymous.getSolutionProviders(
        filterType
      );
      queryResponse = responseObj.data;
    } catch (error) {
      console.log('Error Ocuurred while fetching providers data!', error);
    }
    if (
      queryResponse &&
      queryResponse.data &&
      queryResponse.data.getStorefrontFilterValues
    ) {
      makeSolutionProvidersMapObject(
        queryResponse.data.getStorefrontFilterValues
      );
    } else {
      console.log('Error while fetching the App Providers data.');
    }
  };

  useEffect(() => {
    getProvidersData();
  }, []);

  useEffect(() => {
    getFeaturedSolutions();
  }, [auth.isAuthenticated]);

  const getFeaturedSolutions = async () => {
    setIsLoading(true);
    let searchParams = {
      searchOnly: false,
      size: 3,
      isFeatured: true
    };
    let filterParams = {
      order: 'desc',
      sortBy: 'lastUpdatedOn'
    };
    let queryResponse = {};
    try {
      if (auth && auth.isAuthenticated) {
        const client = getApolloClient();
        const responseObj = await client.query({
          query: searchSolutionQuery,
          variables: {
            id: getRandomId(),
            ...searchParams,
            ...filterParams
          }
        });
        queryResponse =
          responseObj &&
          responseObj.data &&
          responseObj.data.searchStoreFrontSolution
            ? responseObj.data.searchStoreFrontSolution
            : null;
      } else {
        //anon call for featured solutions
        let payload = {
          ...searchParams,
          ...filterParams
        };
        let responseObj = await StoreListingAPI.anonymous.getStoreFrontSolutions(
          payload
        );
        queryResponse = responseObj.data ? responseObj.data : null;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        'Error occurred while fetching data for featured solutions!',
        error
      );
    }
    if (queryResponse && queryResponse.data) {
      setFeaturedSolutions(queryResponse.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log('Error while fetching Featured Apps');
    }
  };

  /**
   * generate a random uuid
   * Returns:
   *  random uuid
   */
  const getRandomId = () => {
    return (
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  };

  return (
    <div className="published-apps-bg page-padding-common">
      <div className="published-apps-title">Published Apps</div>
      <div className="published-apps-subtitle">
        Check out some of our popular published applications.
      </div>
      {isLoading ? (
        <div className="loading-published-apps">
          <Spinner />
          <div className="published-apps-subtitle">Loading Solutions...</div>
        </div>
      ) : (
        <Grid className="published-apps-grid max-width-resp page-padding-common">
          <Row>
            {featuredSolutions && featuredSolutions.length ? (
              featuredSolutions.map(solution => (
                <FeaturedListItemForSellPage
                  solution={solution}
                  orgMapObj={orgMapObj}
                />
              ))
            ) : (
              <Column
                className="loading-published-apps no-solutions-here"
                span={6}
              >
                Failed to fetch. Check back soon!
              </Column>
            )}
          </Row>
        </Grid>
      )}
    </div>
  );
};

const searchSolutionQuery = gql`
  query(
    $id: String
    $size: Int
    $from: Int
    $sortBy: String
    $order: String
    $searchOnly: Boolean
    $isFeatured: Boolean
  ) {
    searchStoreFrontSolution(
      filters: {
        id: $id
        size: $size
        from: $from
        sortBy: $sortBy
        order: $order
        searchOnly: $searchOnly
        isFeatured: $isFeatured
      }
    ) {
      page
      pageSize
      total
      data {
        id
        orgId
        extensibilityType
        lastUpdatedOn
        activateOnly
        plans {
          isFreeTrialEnabled
        }
        solution_visibility {
          solutionVisibilityType
        }
        overview {
          name
          category
          briefDescription
          solutionLogo
          featuredImage
          publisherName
        }
        worksWithIds
        isFeatured
      }
    }
  }
`;

export default withAuth(PublishedApps);
