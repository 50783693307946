import React from 'react';
import { injectIntl } from 'react-intl';
import SolutionLogo from '../common/images/cogs.png';

class SubscriptionPlanContentHeader extends React.Component {
  render() {
    const { intl, solution } = this.props;
    return (
      <div className="solution-subscription-header-content max-width-resp">
        <div className="solution-subscription-header-logo-div">
          {solution.overview.solutionLogo ? (
            <img
              alt={intl.formatMessage({
                id: 'SolutionsListForAdmin.img.alt',
                defaultMessage: 'App Logo'
              })}
              className="solution-subscription-header-logo"
              src={`${solution.overview.solutionLogo}?${Math.random()}`}
            />
          ) : (
            <img
              src={SolutionLogo}
              className="solution-subscription-header-logo"
              alt={intl.formatMessage({
                id: 'SolutionsListForAdmin.img.alt',
                defaultMessage: 'App Logo'
              })}
            />
          )}
        </div>
        <div className="solution-subscription-header-title">
          {solution.overview.name}
        </div>
      </div>
    );
  }
}

export default injectIntl(SubscriptionPlanContentHeader);
