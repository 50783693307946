import React from 'react';
import { injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';

import { Card } from '@cdk-uip/react-card';
import MySubscriptionListItemHeader from './my-subscription-list-item-header';
import MySubscriptionListItemInProcessContent from './my-subscription-list-item-inprocess-content';
import MySubscriptionListItemContent from './my-subscription-list-item-content';
import MySubscriptionListItemActions from './my-subscription-list-item-actions';
import ApiConfigStatusForSubscriptionConstants from '../../common/ApiConfigStatusForSubscriptionConstants';
import ApiConnectionStatusConstants from '../../common/ApiConnectionStatusConstants';
import mapKeys from 'lodash/mapKeys';
import map from 'lodash/map';

class MySubscriptionListItem extends React.Component {
  state = {
    platformSubscription: null,
    apiConfigurationStatus: null
  };

  componentDidMount() {
    if (this.props.subscription) {
      let {
        solution: { registration_details: { api_details = [] } = {} } = {}
      } = this.props.subscription;
      api_details = api_details.filter(api => {
        return !api.isOptional;
      });
      let solutionAPIs = mapKeys(api_details, function(value) {
        return value.name;
      });

      let subscription = JSON.parse(JSON.stringify(this.props.subscription));
      this.getSubscriptionFromPlatform(subscription.subscriptionId).then(
        response => {
          if (!response.errors) {
            this.setState({
              platformSubscription: response.data.getSubscription
            });

            if (
              response.data.getSubscription.connections &&
              response.data.getSubscription.connections.length
            ) {
              //Default Pending
              let apiConfigurationStatus =
                ApiConfigStatusForSubscriptionConstants.PENDING_CONFIGURATION;

              //Every Connection is Inactive, Then Not Started
              apiConfigurationStatus =
                response.data.getSubscription.connections.filter(connection => {
                  return (
                    connection.status === ApiConnectionStatusConstants.INACTIVE
                  );
                }).length === response.data.getSubscription.connections.length
                  ? ApiConfigStatusForSubscriptionConstants.NOT_STARTED
                  : apiConfigurationStatus;

              //--------------------------------------------------------------
              apiConfigurationStatus =
                response.data.getSubscription.connections.filter(connection => {
                  return (
                    connection.status === ApiConnectionStatusConstants.ACTIVE
                  );
                }).length === response.data.getSubscription.connections.length
                  ? ApiConfigStatusForSubscriptionConstants.ACTIVE
                  : apiConfigurationStatus;

              //Every required connection is active, Then Active

              // response.data.getSubscription.connections.map(connection => {
              //   if (solutionAPIs[connection.api]) {
              //     solutionAPIs[connection.api].connectionStatus =
              //       connection.status;
              //   }
              // });

              // let isActive = true;
              // map(solutionAPIs, api => {
              //   if (
              //     api.connectionStatus !== ApiConnectionStatusConstants.ACTIVE
              //   ) {
              //     isActive = false;
              //   }
              // });

              // apiConfigurationStatus = isActive
              //   ? ApiConfigStatusForSubscriptionConstants.ACTIVE
              //   : apiConfigurationStatus;

              this.setState({
                apiConfigurationStatus: apiConfigurationStatus
              });
            } else {
              this.setState({
                apiConfigurationStatus:
                  ApiConfigStatusForSubscriptionConstants.PENDING_CONFIGURATION
              });
            }
          }
        }
      );
    }
  }

  getSubscriptionFromPlatform = async subscriptionId => {
    return await this.props.client.query({
      query: gql`
        query($subscriptionId: ID) {
          getSubscription(subscriptionId: $subscriptionId) {
            subscriptionId
            entityId
            solutionId
            status
            connections {
              api
              solutionId
              connectionId
              providerId
              subscriptionId
              entityId
              status
            }
          }
        }
      `,
      variables: { subscriptionId: subscriptionId }
    });
  };

  getOverallStatus(
    apiConfigurationStatus,
    userProvisioningStatus,
    overallStatusFromDB
  ) {
    return overallStatusFromDB;
    /**
     * Client Side overall Status Calculation code
     *  */
    // let completedStatus = [
    //   ApiConfigStatusForSubscriptionConstants.ACTIVE,
    //   ApiConfigStatusForSubscriptionConstants.COMPLETED
    // ];
    // let pendingStatus = [
    //   ApiConfigStatusForSubscriptionConstants.PENDING_CONFIGURATION,
    //   ApiConfigStatusForSubscriptionConstants.NOT_STARTED,
    //   ApiConfigStatusForSubscriptionConstants.PENDING
    // ];
    // if (
    //   completedStatus.includes(userProvisioningStatus) &&
    //   completedStatus.includes(apiConfigurationStatus)
    // ) {
    //   return ApiConfigStatusForSubscriptionConstants.ACTIVE;
    // }
    // if (
    //   pendingStatus.includes(userProvisioningStatus) ||
    //   pendingStatus.includes(apiConfigurationStatus)
    // ) {
    //   return ApiConfigStatusForSubscriptionConstants.PENDING_ACTIVATION;
    // }
    // return userProvisioningStatus;
  }
  render() {
    const { subscription } = this.props;
    const { apiConfigurationStatus } = this.state;
    let overallStatus = this.getOverallStatus(
      apiConfigurationStatus,
      subscription.userProvisioningStatus,
      subscription.status
    );

    return (
      <div>
        {subscription ? (
          <Card className="subscription-card">
            <MySubscriptionListItemHeader
              subscription={subscription}
              status={overallStatus}
            />
            {overallStatus !== ApiConfigStatusForSubscriptionConstants.ACTIVE &&
            overallStatus !== ApiConfigStatusForSubscriptionConstants.EXPIRED &&
            overallStatus !==
              ApiConfigStatusForSubscriptionConstants.CANCELLED ? (
              <MySubscriptionListItemInProcessContent
                {...this.props}
                apiConfigurationStatus={apiConfigurationStatus}
                subscription={subscription}
              />
            ) : (
              <MySubscriptionListItemContent
                {...this.props}
                subscription={subscription}
              />
            )}
            {overallStatus !==
              ApiConfigStatusForSubscriptionConstants.EXPIRED &&
            overallStatus !==
              ApiConfigStatusForSubscriptionConstants.CANCELLED &&
            overallStatus !== ApiConfigStatusForSubscriptionConstants.ACTIVE ? (
              <MySubscriptionListItemActions
                {...this.props}
                subscription={subscription}
              />
            ) : null}
          </Card>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default injectIntl(withApollo(MySubscriptionListItem));
