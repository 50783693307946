import React from "react";
import { Tooltip } from "@cdk-uip/react-tooltip";
import { TextField, TextFieldHelperText } from "@cdk-uip/react-text-field";
import ValidationUtils from "../utils/ValidationUtils";

export class FormTextFieldWithPayload extends React.Component {
  state = {
    isDisabled: false,
    isValid: true,
    outOfFocus: false,
    value: this.props.formFieldValues[this.props.fieldProperties.name] || ""
  };

  constructor(props) {
    super(props);
    this.validationUtils = new ValidationUtils();
  }
  handleInputChange = event => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[event.target.name] = event.target.value;
    let isValid = this.validate();
    this.props.onChange(formFieldValues);
    this.setState({
      isValid: isValid,
      value: event.target.value
    });
  };

  validate() {
    const formFieldValues = this.props.formFieldValues;
    let isValid = this.validationUtils.validate(
      this.props.fieldProperties,
      formFieldValues
    );
    this.props.validate &&
      this.props.validate(
        isValid,
        this.props.fieldProperties.name,
        this.props.activeSubFormId,
        this.props.activeTabId
      );
    return isValid;
  }

  componentDidUpdate() {
    if (
      this.state.value !==
      this.props.formFieldValues[this.props.fieldProperties.name]
    ) {
      let isValid = this.validate();
      this.setState({
        isValid: isValid,
        value: this.props.formFieldValues[this.props.fieldProperties.name]
      });
    }
  }

  render() {
    const { fieldProperties, disabledOn } = this.props;
    const randomNumber = Math.random();
    const isDisabled =
      typeof fieldProperties.disabled === "function"
        ? fieldProperties.disabled(disabledOn)
        : fieldProperties.disabled;
    return (
      <div className="form-field-with-tooltip">
        <span className={fieldProperties.labelStyle}>
          {fieldProperties.label}
          {fieldProperties.toolTipText && (
            <span>
              <i
                id={`tooltip_${fieldProperties.name}${randomNumber}`}
                className="material-icons help-label-icon"
              >
                help
              </i>
            </span>
          )}
        </span>
        <TextField
          className={fieldProperties.style}
          name={fieldProperties.name}
          id={fieldProperties.name}
          type={fieldProperties.fieldType}
          min={fieldProperties.min}
          value={
            this.props.formFieldValues[fieldProperties.name]
              ? this.props.formFieldValues[fieldProperties.name]
              : ""
          }
          onChange={this.handleInputChange}
          onBlur={e => {
            this.handleInputChange(e);
            this.setState({ outOfFocus: true });
          }}
          onFocus={e => {
            this.handleInputChange(e);
            this.setState({ outOfFocus: false });
          }}
          invalid={this.state.outOfFocus && !this.state.isValid}
          helperText={
            <TextFieldHelperText persistent validation>
              {!this.state.isValid && fieldProperties.validationErrorMessage}
            </TextFieldHelperText>
          }
          disabled={isDisabled}
          maxLength={fieldProperties.maxLength}
          required={fieldProperties.required}
        />
        {fieldProperties.maxLength && (
          <div className="character-remaining-div">
            {this.props.value ? this.props.value.length : 0} /{" "}
            {fieldProperties.maxLength}
          </div>
        )}
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${fieldProperties.name}${randomNumber}`}
        >
          <span>{fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}

export default FormTextFieldWithPayload;
