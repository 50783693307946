import React from "react";
import { Subheading1, Subheading2 } from "@cdk-uip/react-typography";
import { Checkbox } from "@cdk-uip/react-checkbox";
import { Tooltip } from "@cdk-uip/react-tooltip";

export class FormCheckBoxGroup extends React.Component {
  handleCheckBoxGroupChange = (event, selectedValue, name) => {
    const value = event.target.checked;
    const formFieldValues = this.props.formFieldValues;
    if (value) {
      if (selectedValue.dynamic !== undefined) {
        delete selectedValue.dynamic;
        delete selectedValue.setLabelFrom;
      }
      formFieldValues[name].push(selectedValue);
    } else {
      formFieldValues[name] = formFieldValues[name].filter(item => {
        return !this.compareObject(item, selectedValue);
      });
    }
    this.props.onChange(formFieldValues);
  };

  compareObject = (obj1, obj2) => {
    if (obj1 instanceof Object && obj2 instanceof Object) {
      if (obj1.id && obj1.id !== obj2.id) {
        return false;
      }
      return true;
    } else {
      return obj1 === obj2;
    }
  };

  render() {
    const randomNumber = Math.random();
    return (
      <div>
        <Subheading2 className="check-box-group-title">
          {this.props.fieldProperties.label}
        </Subheading2>
        {this.props.fieldProperties.note && (
          <Subheading1 className="checkbox-group-notes">
            {this.props.options && this.props.options.length > 0
              ? this.props.fieldProperties.note
              : this.props.fieldProperties.noContentNote}

            {this.props.fieldProperties.toolTipText && (
              <span>
                <i
                  id={`tooltip_${
                    this.props.fieldProperties.name
                  }${randomNumber}`}
                  className="material-icons help-label-icon"
                >
                  help
                </i>
              </span>
            )}
          </Subheading1>
        )}

        {this.props.options.map((option, optionIndex) => {
          return (
            <div id={option} key={optionIndex} className="check-box-group-item">
              <Checkbox
                className="check-box-item-checkBox"
                key={optionIndex}
                checked={this.props.value.some(element => {
                  return this.compareObject(option, element);
                })}
                disabled={this.props.fieldProperties.isDisabled}
                onChange={event => {
                  this.handleCheckBoxGroupChange(
                    event,
                    option,
                    this.props.fieldProperties.name
                  );
                }}
              />
              <label className="check-box-group-item-label">
                {option instanceof Object ? option.title : option}
              </label>
            </div>
          );
        })}

        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${this.props.fieldProperties.name}${randomNumber}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}
export default FormCheckBoxGroup;
