import React from 'react';
import axios from 'axios';

import { Button, BUTTON_VARIANTS } from 'cdk-radial';
import EntityRegistrationConfirmation from '../common/EntityRegistrationConfirmation';
import { withRouter } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import FortellisConstants from '../common/FortellisConstants';
import UserConsentDialog, {
  consentStatusConst,
  consentTypeConst
} from '../common/UserConsentDialog';
import { withApollo } from 'react-apollo';

import gql from 'graphql-tag';
import { getApolloClient } from '../../containers/GraphQLClient';
import moment from 'moment';

import ProvisioningConstants from '../common/ProvisioningConstants';
import ApiConfigStatusForSubscriptionConstants from '../common/ApiConfigStatusForSubscriptionConstants';
import ApiConnectionStatusConstants from '../common/ApiConnectionStatusConstants';
import SolutionTypeURLMapperUtil from '../common/SolutionTypeURLMapperUtil';
import NotificationConstants from '../common/NotificationConstants';
import LoadingStepper from '../common/LoadingStepper';
import FortellisDialogSpinner from '../common/FortellisDialogSpinner';
import ActionSnackBar from '../solution-submission/ActionSnackBar';
import FortellisDialog from '../common/FortellisDialog';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import DealerSuiteLoginDialog from './DealerSuiteLoginDialog';
import EmailTypeConstants from '../common/constants/EmailTypeConstants';
import sendEmail from '../common/EmailService';
import ConsentTypeConstants from '../common/constants/ConsentTypeConstants';
import { sendAmplitudeData } from '../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../utils/amplitude-constants';

//Constants
const SOLUTION_TERMS = 'solution terms';

class SubscribeSolution extends React.Component {
  state = {
    showEntityRegistrationPopup: false,
    showSubscriberConsentModal: false,

    isSubscribing: false,
    justSubscribed: false,
    cancel: false,
    response: null,
    subscribingStepsArray: [
      'Saving Consent',
      'Creating Subscription',
      'Notifying App Provider',
      `Sending Email Confirmation to ${
        this.props.entity ? this.props.entity.name : 'You'
      }`
    ],
    subscribingActiveStep: 0,
    finished: false,
    savedTerms: null
  };

  sendSubscriptionNotification = async (
    createSolutionSubscriptionResponse,
    entity,
    client
  ) => {
    let solution = this.props.solution;

    if (
      createSolutionSubscriptionResponse.data.createSolutionSubscription &&
      createSolutionSubscriptionResponse.data.createSolutionSubscription
        .subscriptionId &&
      solution.subscription_notifications &&
      solution.subscription_notifications.apiNotification &&
      solution.subscription_notifications.endpointUrl
    ) {
      let payload = {
        solutionInfo: {
          id: solution.id,
          name: solution.overview.name,
          planInfo: {
            name: this.props.plan.planName,
            description: this.props.plan.planDescription
          }
        },
        subscriptionInfo: {
          id:
            createSolutionSubscriptionResponse.data.createSolutionSubscription
              .subscriptionId,
          subscribedBy:
            createSolutionSubscriptionResponse.data.createSolutionSubscription
              .userId,
          subscribedOn:
            createSolutionSubscriptionResponse.data.createSolutionSubscription
              .dateOfPurchase,
          subscriberEntity: {
            id: entity.id,
            name: entity.name,
            address: {
              region: entity.address.region,
              city: entity.address.city,
              street: entity.address.street,
              countryCode: entity.address.countryCode,
              postalCode: entity.address.postalCode
            },
            phoneNumber: entity.phone,
            website: entity.website
          }
        },
        notificationType: NotificationConstants.type.SUBSCRIPTION
      };

      let stringifiedPayload = JSON.stringify(payload);

      await client.mutate({
        mutation: notifyUrlMutation,
        variables: {
          endpointUrl: solution.subscription_notifications.endpointUrl,
          payload: stringifiedPayload
        }
      });
    }
  };

  saveConsents = async (solution, consentData) => {
    const {
      auth: { accessToken }
    } = this.props;

    //STEP 1 - SAVING CONSENT
    let createConsentPayload = {
      documentLink: solution.overview.termsAndConditionsUrl,
      listingVersion: solution.listingVersion ? solution.listingVersion : null,
      solutionVersion: solution.overview.version
        ? solution.overview.version
        : null,

      acceptedName: consentData.userName ? consentData.userName : null,
      acceptedTitle: consentData.userTitle ? consentData.userTitle : null,
      acceptedCompany: consentData.userCompany ? consentData.userCompany : null,
      reasonForRejection: consentData.rejectionReason
        ? consentData.rejectionReason
        : null,

      status: consentData.consentStatus
        ? consentData.consentStatus.toLowerCase()
        : null,
      consentType: consentTypeConst.SOLUTION_TNC.toLowerCase(),
      //As apiProviderId expected non-empty value in backend
      apiProviderId: 'NA',
      subscriptionStatus: consentData.subscriptionStatus
        ? consentData.subscriptionStatus
        : null,
      consentedSource: ConsentTypeConstants.CONSENTED_SOURCE,
      signedTermsAndConditions: consentData.signedTermsAndConditions
        ? consentData.signedTermsAndConditions
        : null
    };

    this.setState({ savedTerms: consentData.signedTermsAndConditions });
    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    };

    return await axios.post(
      `${environmentURLs.consentServiceBaseUrl}/solutions/${solution.id}/${
        solution.listingVersion
      }/consents`,
      createConsentPayload,
      { headers: headers }
    );
  };

  subscribe = async consentData => {
    const { solution } = this.props;
    const client = getApolloClient();
    try {
      const { entity } = this.props;
      await this.setState({ isSubscribing: true, subscribingActiveStep: 0 });
      this.forceUpdate();

      let consentResponse = await this.saveConsents(solution, consentData);
      if (consentResponse && consentResponse.status === 200) {
        //STEP 0 - SAVING CONSENT
        await this.setState(prevState => ({
          subscribingActiveStep: prevState.subscribingActiveStep + 1
        }));

        //STEP 1 - CREATING SUBSCRIPTION
        let buyerName = this.props.auth.userData.name.split(' ');
        let buyerEmail = this.props.auth.userData.email;

        let response = await client.mutate({
          mutation: createSubscription,
          variables: {
            solutionId: this.props.solution.id,
            listingVersion: this.props.solution.listingVersion,
            firstName: buyerName[0] || null,
            lastName: buyerName[1] || null,
            entityId: entity.id,
            userId: buyerEmail,
            status: 'Pending Activation',
            userProvisioningStatus:
              this.props.solution &&
              this.props.solution.provisioning_interface &&
              this.props.solution.provisioning_interface.provisioningtype ===
                ProvisioningConstants.type.MANUAL
                ? ApiConnectionStatusConstants.PENDING
                : ApiConfigStatusForSubscriptionConstants.NOT_STARTED,
            provisioningInitiatedOn:
              this.props.solution &&
              this.props.solution.provisioning_interface &&
              this.props.solution.provisioning_interface.provisioningtype ===
                ProvisioningConstants.type.MANUAL
                ? moment().toISOString()
                : null,
            plan: this.props.plan,
            termsAndConditions: this.props.solution.overview
              .termsAndConditionsUrl,
            subscriptionCreatedOn: moment().toISOString()
          }
        });

        if (
          response &&
          response.data &&
          response.data.createSolutionSubscription &&
          response.data.createSolutionSubscription.subscriptionId
        ) {
          this.setState({ response: response.data.createSolutionSubscription });

          //STEP 2 - NOTIFYING SOLUTION PROVIDER - subcription endpoint and email
          await this.setState(prevState => ({
            subscribingActiveStep: prevState.subscribingActiveStep + 1
          }));

          //NOTIFYING SOLUTION PROVIDER STEP - subcription endpoint notification
          await this.sendSubscriptionNotification(response, entity, client);

          //NOTIFYING SOLUTION PROVIDER STEP - email notification to solution developer
          let emails = [];
          emails.push(this.props.solution.developerId);
          if (this.props.solution.subscription_notifications.email != null) {
            emails.push(this.props.solution.subscription_notifications.email);
          }

          emails = Array.from(new Set(emails));
          emails = emails.map(email => ({ name: email, email }));
          let attachment = null;
          if (this.state.savedTerms) {
            attachment = {
              mimeType: 'application/pdf',
              fileName: `${this.props.solution.overview.name}.pdf`,
              disposition: 'attachment',
              cid: 'pdf',
              fileUrl: this.state.savedTerms
            };
          }
          try {
            client.mutate({
              mutation: subscriptionDeveloperEmailMutation,
              variables: {
                to: emails,
                emailSubject: 'Your App has a new subscription',
                firstName: this.props.solution.developerName.split(' ').length
                  ? this.props.solution.developerName.split(' ')[0]
                  : ' ',
                emailType: 'buyerSubscribedToApp',
                solutionName: this.props.solution.overview.name,
                solutionId: this.props.solution.id || ' ',
                solutionType:
                  SolutionTypeURLMapperUtil.getSolutionTypeURL(
                    this.props.solution.solutionType
                  ) || ' ',
                buyerName: entity.name || ' ',
                subscriberName: this.props.auth.userData.name || '',
                subscriberOrganization: entity.name || '',
                subscriptionId:
                  response &&
                  response.data &&
                  response.data.createSolutionSubscription
                    ? response.data.createSolutionSubscription.subscriptionId
                    : ' ',
                entityId: this.props.entity.id ? this.props.entity.id : ' ',
                attachments: attachment
              }
            });
          } catch (err) {}

          //STEP 3 - SUBSCRIPTION CONFIRMATION EMAIL NOTIFICATION TO SOLUTION SUBSCRIBER
          await this.setState(prevState => ({
            subscribingActiveStep: prevState.subscribingActiveStep + 1
          }));

          const appDeveloperEmails = emails.filter(
            emailAddressObject => !!emailAddressObject.email
          ); // App providers to be included in the cc
          await client.mutate({
            mutation: subscriptionBuyerEmailNotificationMutation,
            variables: {
              to: [{ name: buyerEmail || ' ', email: buyerEmail }],
              cc: appDeveloperEmails,
              emailAddress: buyerEmail,
              emailSubject: `${
                this.props.solution.overview.name
              } Subscription Details`,
              firstName: this.props.auth.userData.name || ' ',
              emailType: 'solutionSubscriptionBuyerEmail',
              solutionName: this.props.solution.overview.name,
              solutionId: this.props.solution.id || ' ',
              publisher: this.props.solution.overview.publisherName
                ? this.props.solution.overview.publisherName
                : ' ',
              publisherEmail: this.props.solution.support.emailAddress
                ? this.props.solution.support.emailAddress
                : ' ',
              publisherPhone: this.props.solution.support.phoneNumber
                ? this.props.solution.support.phoneNumber
                : ' ',
              buyerName: entity.name || ' ',
              purchaseDate: this.state.response.dateOfPurchase
                ? moment(this.state.response.dateOfPurchase).format(
                    'DD MMM YYYY'
                  )
                : ' ',
              planName: (this.props.plan && this.props.plan.planName) || ' ',
              planDetails: this.props.plan.includedUnitQuantity
                ? this.props.plan.includedUnitQuantity +
                  ' ' +
                  this.props.plan.includedUnitOfMeasure
                : ' ',
              subscriptionId:
                response &&
                response.data &&
                response.data.createSolutionSubscription
                  ? response.data.createSolutionSubscription.subscriptionId
                  : ' ',
              attachments: attachment
            }
          });

          try {
            const apis = this.props.solution.registration_details.api_details.map(
              api => ({
                apiName: api.displayName
              })
            );

            if (!!this.state.savedTerms === false) {
              attachment = {
                mimeType: 'application/pdf',
                fileName: `${this.props.solution.overview.name}.pdf`,
                disposition: 'attachment',
                cid: 'pdf',
                fileUrl: this.props.solution.overview.termsAndConditionsUrl
              };
            }

            let appOrgName = await this.getAppOrgName(
              this.props.solution.orgId
            );
            await client.mutate({
              mutation: internalBuyerSubscriptionEmail,
              variables: {
                emailSubject: 'Marketplace Subscription Alert',
                subscriberOrganization: entity.name || '',
                emailType: 'internalBuyerSubscribedToApp',
                entityId: this.props.entity.id ? this.props.entity.id : ' ',
                subscriberName: this.props.auth.userData.name || ' ',
                buyerEmail: buyerEmail,
                appName: this.props.solution.overview.name,
                appId: this.props.solution.id || ' ',
                appOrgName: appOrgName,
                appOrgId: this.props.solution.orgId,
                appTermsConsentType: !!this.state.savedTerms
                  ? 'Electronic Signature'
                  : 'One Click',
                apis: apis,
                attachments: attachment
              }
            });
          } catch (err) {
            console.log(err);
          }
          await this.setState({ finished: true });
          this.forceUpdate();
          this.props.onSubscriptionComplete(
            solution,
            this.props.plan,
            response.data.createSolutionSubscription
          );
        }

        this.setState({ isSubscribing: false, justSubscribed: true });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /*     //simulation code for development time testing
  spinnerProgress = () => {
    this.setState({ subscribingActiveStep: 0 });
    setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),3000);
    setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),6000);
    setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),9000);
    setTimeout(async ()=>await this.setState((prevState) => ({subscribingActiveStep: prevState.subscribingActiveStep + 1})),12000);
    setTimeout(async () => await this.setState({ finished: true }), 12500);
    this.forceUpdate();
    this.setState({subscribingActiveStep: 0})
  };

  subscribe = async () => {
    await this.setState({ isSubscribing: true });
    this.spinnerProgress();
    setTimeout(async () =>  this.setState({ isSubscribing: false }), 6500);
  };*/

  onConsentResponse = async data => {
    const { solution } = this.props;
    this.setState({ showSubscriberConsentModal: false });
    if (data) {
      if (data.consentStatus === consentStatusConst.ACCEPTED) {
        //SAVE CONSENT ACCEPTANCE, CREATE SUBSCRIPTION (Subscription Stepper) AND NAVIGATE TO CONFIRMATION PAGE
        this.subscribe(data);
      } else if (data.consentStatus === consentStatusConst.REJECTED) {
        this.setState({ savingConsentRejection: true });
        let consentResponse = await this.saveConsents(solution, data);
        if (consentResponse && consentResponse.status === 200) {
          const { entity } = this.props;
          let emailData = {
            emailAddress: this.props.solution.developerId || '',
            emailSubject: 'Terms Rejection',
            firstName: this.props.solution.developerName.split(' ')[0] || '',
            emailType: EmailTypeConstants.TERMS_REJECTION_EMAIL,
            solutionName: this.props.solution.overview.name || '',
            solutionId: this.props.solution.id || '',
            buyerName: this.props.auth.userData.name || '',
            comment: data.rejectionReason || '',
            customerPhone: entity.phone || '',
            customerEmail: this.props.auth.userData.email || '',
            companyName: entity.name
          };
          await sendEmail(EmailTypeConstants.TERMS_REJECTION_EMAIL, emailData);
          this.setState({ isConsentRejectionSaved: true });
        }
        this.setState({ savingConsentRejection: false });
      }
    }
  };

  getAppOrgName = async entityId => {
    const client = getApolloClient();
    try {
      let response = await client.query({
        query: gql`
          query($id: ID) {
            entitiesFromPlatform(id: $id) {
              name
            }
          }
        `,
        variables: {
          id: entityId
        }
      });
      if (!response.errors && response.data.entitiesFromPlatform) {
        return response.data.entitiesFromPlatform.name;
      }
    } catch (err) {
      console.error(err);
      console.error('Entity not present!');
      return null;
    }
  };

  onSubscribeClick = () => {
    const { entity } = this.props;
    if (this.props.auth.isAuthenticated) {
      if (
        !entity ||
        !entity.id ||
        entity.id === FortellisConstants.DEFAULT_ORG_ID ||
        entity.id === FortellisConstants.PERSONAL_ACCOUNT
      ) {
        this.setState({ showEntityRegistrationPopup: true });
      } else {
        this.setState({ showSubscriberConsentModal: true });
      }
    } else {
      this.setState({ dsLoginDialogOpen: true });
    }
  };

  redirectToDealerSuiteSolution = () => {
    const {
      solution: { id: solutionId } = {},
      plan: { id: planId } = {},
      solution,
      plan,
      entity
    } = this.props;

    sendAmplitudeData(AMPLITUDE_EVENTS.SUBSCRIBE_TO_APP_PLAN, {
      [AMPLITUDE_PROPERTIES.APP_ID]: solutionId,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solution.overview.name,
      [AMPLITUDE_PROPERTIES.PRICING_PLAN_ID]: plan.id,
      [AMPLITUDE_PROPERTIES.PRICING_PLAN_NAME]: plan.planName,
      [AMPLITUDE_PROPERTIES.ORG_ID]: entity && entity.id ? entity.id : 'NA',
      [AMPLITUDE_PROPERTIES.ORG_NAME]:
        entity && entity.name ? entity.name : 'NA'
    });

    const url = `${environmentURLs.dealerSuiteLogin}/${solutionId}/${planId}`;
    window.open(url, '_blank');
  };

  render() {
    const {
      solution,
      solutionName,
      plan,
      isAlreadySubscribed,
      isPreview,
      auth: { isAuthenticated },
      auth: { userData },
      extensibilityType
    } = this.props;

    return (
      <div>
        <DealerSuiteLoginDialog
          open={this.state.dsLoginDialogOpen}
          solution={solution}
          solutionName={solutionName}
          plan={plan}
          onAccept={() => {
            this.setState({ dsLoginDialogOpen: false });
          }}
          onCancel={() => {
            this.setState({ dsLoginDialogOpen: false });
          }}
        />

        <EntityRegistrationConfirmation
          openDialog={this.state.showEntityRegistrationPopup}
          onClose={() => this.setState({ showEntityRegistrationPopup: false })}
        />

        {this.state.showSubscriberConsentModal &&
          (solution &&
          solution.overview &&
          solution.overview.termsAndConditionsUrl ? (
            <UserConsentDialog
              open={this.state.showSubscriberConsentModal}
              title={'Terms and Conditions'}
              description={() => (
                <div>
                  Please review and accept these terms to subscribe to&nbsp;
                  <strong>{this.props.solutionName}</strong>
                </div>
              )}
              submitButtonName="Submit"
              cancelButtonName="Cancel"
              acceptMessage="I accept the terms and conditions"
              rejectMessage="I reject the terms and conditions for"
              scrollInstruction="You need to scroll to bottom to accept terms and conditions."
              preFilledName={userData.name}
              onSubmit={data => this.onConsentResponse(data)}
              onCancel={() => {
                this.setState({ showSubscriberConsentModal: false });
              }}
              consentLink={
                solution &&
                solution.overview &&
                solution.overview.termsAndConditionsUrl
                  ? solution.overview.termsAndConditionsUrl
                  : ''
              }
              type={SOLUTION_TERMS}
              entity={this.props.entity}
            />
          ) : (
            <FortellisDialog
              title={'App Terms and Conditions are not available'}
              message={'Please contact App Publisher for subscription.'}
              open={this.state.showSubscriberConsentModal}
              acceptButtonName={'OK'}
              onAccept={() => {
                this.setState({ showSubscriberConsentModal: false });
              }}
            />
          ))}

        {this.state.isSubscribing ? (
          <LoadingStepper
            open={this.state.isSubscribing}
            title={`${this.props.intl.formatMessage(
              { id: 'SubscriptionPlanActions.loadingStepper.title' },
              { defaultMessage: 'Subscribing to ' }
            )}
                    ${solution.overview.name}`}
            steps={this.state.subscribingStepsArray}
            activeStep={this.state.subscribingActiveStep}
            finished={this.state.finished}
            customClassName="subscribe-stepper"
          />
        ) : (
          false
        )}

        {this.state.savingConsentRejection ? (
          <FortellisDialogSpinner open={this.state.savingConsentRejection} />
        ) : null}

        {this.state.isConsentRejectionSaved && (
          <ActionSnackBar
            show={this.state.isConsentRejectionSaved}
            message="Consent Rejected"
            close={() => {
              this.setState({ isConsentRejectionSaved: false });
            }}
          />
        )}

        {isAuthenticated && isAlreadySubscribed ? (
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            raised={true}
            dataTestId="subscribeButton"
            className={this.props.styleClass}
            isDisabled={true}
            text={this.props.message}
          />
        ) : (
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            raised={true}
            dataTestId="subscribeButton"
            className={this.props.styleClass}
            isDisabled={isPreview}
            onClick={this.redirectToDealerSuiteSolution}
            text={this.props.message}
          />
        )}
      </div>
    );
  }
}

const createSubscription = gql`
  mutation(
    $solutionId: ID!
    $listingVersion: String!
    $entityId: ID!
    $userId: String
    $firstName: String
    $lastName: String
    $status: String
    $plan: PlanInfoCmd
    $termsAndConditions: String
    $subscriptionCreatedOn: String
    $userProvisioningStatus: String
    $provisioningInitiatedOn: String
    $subscribedSource: String
  ) {
    createSolutionSubscription(
      command: {
        solutionId: $solutionId
        listingVersion: $listingVersion
        entityId: $entityId
        userId: $userId
        firstName: $firstName
        lastName: $lastName
        status: $status
        plan: $plan
        termsAndConditions: $termsAndConditions
        reminderSentOn: null
        userProvisioningStatus: $userProvisioningStatus
        apiActivationStatus: "Not Started"
        subscriptionStartDate: null
        subscriptionEndDate: null
        subscriptionCreatedOn: $subscriptionCreatedOn
        provisioningInitiatedOn: $provisioningInitiatedOn
        subscribedSource: $subscribedSource
      }
    ) {
      id
      subscriptionId
      userId
      dateOfPurchase
    }
  }
`;

const subscriptionBuyerEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $solutionName: String
    $solutionId: String
    $publisher: String
    $publisherEmail: String
    $publisherPhone: String
    $buyerName: String
    $purchaseDate: String
    $planName: String
    $planDetails: String
    $subscriptionId: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
          publisher: $publisher
          publisherEmail: $publisherEmail
          publisherPhone: $publisherPhone
          subscriptionDetails: {
            buyerName: $buyerName
            purchaseDate: $purchaseDate
            planName: $planName
            planDetails: $planDetails
            subscriptionId: $subscriptionId
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;

const subscriptionBuyerEmailNotificationMutation = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $cc: [csmsgEmailInput]!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $solutionName: String
    $solutionId: String
    $publisher: String
    $publisherEmail: String
    $publisherPhone: String
    $buyerName: String
    $purchaseDate: String
    $planName: String
    $planDetails: String
    $subscriptionId: String
    $attachments: [csmsgEmailAttachmentInput]
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        cc: $cc
        subject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
          publisher: $publisher
          publisherEmail: $publisherEmail
          publisherPhone: $publisherPhone
          subscriptionDetails: {
            buyerName: $buyerName
            purchaseDate: $purchaseDate
            planName: $planName
            planDetails: $planDetails
            subscriptionId: $subscriptionId
          }
        }
        attachments: $attachments
      }
    ) {
      emailResponse
    }
  }
`;

const subscriptionDeveloperEmailMutation = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $solutionName: String
    $solutionId: String
    $solutionType: String
    $buyerName: String
    $subscriptionId: String
    $attachments: [csmsgEmailAttachmentInput]
    $entityId: String
    $subscriberName: String
    $subscriberOrganization: String
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        subject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
          solutionType: $solutionType
          subscriptionDetails: {
            buyerName: $buyerName
            subscriptionId: $subscriptionId
            entityId: $entityId
            subscriberName: $subscriberName
            subscriberOrganization: $subscriberOrganization
          }
        }
        attachments: $attachments
      }
    ) {
      emailResponse
    }
  }
`;

const internalBuyerSubscriptionEmail = gql`
  mutation(
    $emailSubject: String!
    $subscriberOrganization: String
    $emailType: String!
    $entityId: String
    $subscriberName: String
    $buyerEmail: String
    $appName: String
    $appId: String
    $appOrgName: String
    $appOrgId: String
    $appTermsConsentType: String
    $buyerName: String
    $apis: [apiDetailCmd]
    $attachments: [csmsgEmailAttachmentInput]
  ) {
    sendEmailCSMG(
      command: {
        subject: $emailSubject
        withTemplate: {
          emailType: $emailType
          appDetails: {
            appName: $appName
            appId: $appId
            appOrgName: $appOrgName
            appOrgId: $appOrgId
            appTermsConsentType: $appTermsConsentType
          }
          apiDetails: $apis
          subscriptionDetails: {
            buyerName: $buyerName
            subscriberOrganization: $subscriberOrganization
            entityId: $entityId
            subscriberName: $subscriberName
            buyerEmail: $buyerEmail
          }
        }
        attachments: $attachments
      }
    ) {
      emailResponse
    }
  }
`;

const notifyUrlMutation = gql`
  mutation($endpointUrl: String!, $payload: String!) {
    notifyUrl(command: { endpointUrl: $endpointUrl, payload: $payload }) {
      status
    }
  }
`;

export default withRouter(withApollo(withAuth(SubscribeSolution)));
