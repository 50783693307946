import React from "react";
import MySubscriptionStatus from "../../../subscriptions/my-subscription-status";
import { FormattedMessage } from "react-intl";

export default class SubscriptionManagementCardItem extends React.Component {
  render() {
    return (
      <div
        className="subscription-management-card-item"
        style={{ height: this.props.comment ? "256px" : "196px" }}
      >
        <div className="subscription-management-card-item-header">
          <span className="subscription-management-card-item-title">
            {this.props.title}
          </span>
          {this.props.status ? (
            <div className="pull-right subscription-management-card-item-title-status">
              <MySubscriptionStatus status={this.props.status} />
            </div>
          ) : null}
        </div>
        <div className="subscription-management-card-item-body">
          {this.props.body}
        </div>
        {this.props.comment ? (
          <div className="subscription-provisioning-completion-comment">
            <div className="subscription-entity-expansion-panel-body-field-title">
              <FormattedMessage
                id="SubscriptionEntity.phone"
                defaultMessage="Comment "
              />
            </div>
            <div className="subscription-entity-expansion-panel-body-field-content">
              {this.props.comment}
            </div>
          </div>
        ) : null}
        <hr />
        <div className="subscription-management-card-item-footer">
          <span className="pull-right">{this.props.footer}</span>
        </div>
      </div>
    );
  }
}
