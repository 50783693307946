export const config = {
  formId: "features",
  formTitle: "Features",
  formValueType: "Array",
  /*
      formNote:
        "Describe a feature in your product. Customers most often compare features to determine if an app is right for their business.",
    */
  noFormFieldNote:
    "No Features available. Please use add functionality to add new items.",
  tabs: [
    {
      id: "feature_information",
      label: "Feature Information",
      contents: [
        {
          id: "feature_information",
          fields: [
            {
              name: "title",
              label: "Title",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell",
              required: true,
              requiredMessage: "Feature Title is required."
            },
            {
              name: "description",
              label: "Description",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutCellList"
            },
            {
              name: "featureImage",
              label: "Image",
              note: "Recommended aspect ratio - 16:9 and size - 922x518.",
              /*
                                note:
                                  "For the highest quality image, we recommend uploading a high-resolution image (at least 236X236).",
                  */
              nameFrom: "id",
              type: "static_image",
              style: "gallery-image static-image",
              gridStyle: "layoutCellList"
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    create_feature: {
      id: "",
      title: "",
      description: "",
      featureImage: "",
      dynamic: true,
      setLabelFrom: "title"
    }
  },
  insert: {
    in: "plans",
    to: "featureArray",
    of: "formValues"
  },
  activeTabId: "feature_information"
};
