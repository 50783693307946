import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Column, Grid, Row } from 'cdk-radial';
import { Redirect } from 'react-router-dom';
import solutionShape from '../../admin-account/solution-moderation/solutionShape';
import SolutionStatus from '../../common/SolutionStatus';
import gql from 'graphql-tag';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withApollo } from 'react-apollo';
import SolutionLogo from '../../common/images/cogs.png';
import EmptyContent from '../../common/empty-content';
import LoadingSpinner from '../loading-spinner';
import { Loader } from 'cdk-radial';
import SolutionTypeURLMapperUtil from '../../common/SolutionTypeURLMapperUtil';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import FortellisConstants from '../../common/FortellisConstants';
import FortellisTitle2 from '../../common/FortellisTitle2';
import AppSubscriptionsConstants from '../../common/AppSubscriptions';
import VerifyAccount from './solution-management/panels/verifyAccount/VerifyAccount';
import { sendAmplitudeData } from '../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../utils/amplitude-constants';
import {
  getPaymentSetting,
  otherPaymentOptionRequest
} from '../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';
import MySolutionListTable from './MySolutionListTable';
import { connect } from 'react-redux';
const ActivationType = lazy(() => import('./MySolutionList-activationType'));
const INSIGHTS = 'insights';
const MANAGELISTING = 'manageListing';

class MySolutionsList extends React.Component {
  static propTypes = {
    list: PropTypes.arrayOf(solutionShape).isRequired,
    loading: PropTypes.bool
  };

  state = {
    open: false,
    selected: undefined,
    propsUpdated: false,
    userEmail: null,
    userPermissions: [],
    fetchUserPermissions: true,
    redirectToSolution: null,
    errorMessage: '',
    displayList: [],
    currentSelectedSolution: undefined,
    publishedTriggered: false
  };

  fetchUserPermissions = async email => {
    if (!this.props || !this.props.client) {
      return;
    }
    let queryResponse = await this.props.client.query({
      query: gql`
        query($developerEmail: String) {
          userPermissions(developerEmail: $developerEmail) {
            permissions
          }
        }
      `,
      variables: { developerEmail: email }
    });

    if (queryResponse && queryResponse.data) {
      this.setState({
        userPermissions: queryResponse.data.userPermissions.permissions
      });
      this.setState({ fetchUserPermissions: false });
    }
  };

  mergeSolutionList = solutionList => {
    let reducedSolutionList = solutionList.filter(
      item =>
        item.status === SolutionStatus.DRAFT && item.listingVersion === '1'
    );
    return reducedSolutionList;
  };
  handleChooseAnotherPaymentOption = e => {
    e.preventDefault();
    this.props.otherPaymentOptionRequest();
  };
  mergeSolutionCatalogList = (solutionList, solutionCatalogList) => {
    let reducedSolutionList = [];
    let newSolutionArray = [];

    reducedSolutionList = solutionList.filter(
      item =>
        !solutionCatalogList.find(catalogItem => item.id === catalogItem.id) &&
        item.status !== SolutionStatus.DRAFT
    );

    const solutionsWithUniqueIds = uniqBy(reducedSolutionList, sol => sol.id);

    if (solutionsWithUniqueIds) {
      solutionsWithUniqueIds.forEach(item => {
        const solWithMaxListingVersion = maxBy(
          reducedSolutionList,
          sol => item.id === sol.id && sol.listingVersion
        );
        if (
          solWithMaxListingVersion &&
          (solWithMaxListingVersion.status === SolutionStatus.WITHDRAWN ||
            solWithMaxListingVersion.status === SolutionStatus.CANCELLED ||
            solWithMaxListingVersion.listingVersion ===
              FortellisConstants.LISTING_VERSION.START)
        ) {
          newSolutionArray.push(solWithMaxListingVersion);
        } else {
          const nextItem = reducedSolutionList.find(
            sol =>
              sol.id === item.id &&
              sol.listingVersion &&
              solWithMaxListingVersion.listingVersion &&
              parseInt(sol.listingVersion, 10) ===
                solWithMaxListingVersion.listingVersion - 1
          );
          if (nextItem) {
            newSolutionArray.push(nextItem);
          }
        }
      });
    }

    return newSolutionArray.concat(solutionCatalogList);
  };

  componentWillReceiveProps(nextProps) {
    let displayList = [];
    if (nextProps.showSolutionType) {
      switch (nextProps.showSolutionType) {
        case SolutionStatus.DRAFT:
          displayList = this.mergeSolutionList(nextProps.solutionList);
          break;
        case SolutionStatus.PUBLISHED:
          displayList = this.mergeSolutionCatalogList(
            nextProps.solutionList,
            nextProps.solutionCatalogList
          );
          break;
        default:
        //do nothing
      }
    }
    this.setState({ displayList: displayList });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentSelectedSolution &&
      this.state.currentSelectedSolution.status ===
        SolutionStatus.PUBLISH_PENDING
    ) {
      if (
        this.props.solnTobePublished &&
        this.props.solnTobePublished !== prevProps.solnTobePublished
      ) {
        this.setState({
          redirectToSolnManagementAndPublish: this.state
            .currentSelectedSolution,
          publishedTriggered: true
        });
      }

      if (this.props.otherPaymentOption !== prevProps.otherPaymentOption) {
        this.setState({
          redirectToSolnManagementAndPublish: this.state
            .currentSelectedSolution,
          publishedTriggered: false
        });
      }
    }
  }
  componentDidMount() {
    this.props.getPaymentSettingData(
      this.props.userData.email,
      this.props.entity.address.countryCode,
      this.props.auth.accessToken
    );
  }
  handleSolnRowClick = (e, solnId) => {
    e.preventDefault();
    const solution = this.state.displayList.filter(x => x.id === solnId)[0];
    if (e.target.textContent !== 'Verify Account') {
      if (
        solution &&
        (!solution.solutionType || solution.status === SolutionStatus.DRAFT)
      ) {
        this.setState({
          redirectToSolution: solution
        });
      } else {
        this.setState({
          redirectToSolutionManagement: solution
        });
      }
    } else {
      this.setState({
        currentSelectedSolution: solution
      });
    }
    const { entity } = this.props;

    const orgId = entity && entity.id ? entity.id : 'NA';
    const orgName = entity && entity.name ? entity.name : 'NA';

    sendAmplitudeData(AMPLITUDE_EVENTS.VIEW_APP_IN_MARKETPLACE, {
      [AMPLITUDE_PROPERTIES.APP_NAME]: solution.overview.name,
      [AMPLITUDE_PROPERTIES.APP_ID]: solution.id,
      [AMPLITUDE_PROPERTIES.ORG_ID]: orgId,
      [AMPLITUDE_PROPERTIES.ORG_NAME]: orgName
    });
  };

  render() {
    const {
      showTitle,
      blankEmptyContent,
      intl,
      loading,
      auth: { userData },
      entity,
      otherPaymentOption
    } = this.props;
    if (!loading && userData && this.state.fetchUserPermissions) {
      this.fetchUserPermissions(userData.email);
    }

    if (this.state.redirectToSolution) {
      return (
        <Redirect
          to={{
            pathname: SolutionTypeURLMapperUtil.getSolutionFormURL(
              this.state.redirectToSolution.solutionType,
              this.state.redirectToSolution.id
            )
          }}
        />
      );
    }
    if (this.state.redirectToSolutionManagement) {
      return (
        <Redirect
          to={{
            pathname: SolutionTypeURLMapperUtil.getSolutionListingURL(
              this.state.redirectToSolutionManagement.solutionType,
              this.state.redirectToSolutionManagement.id,
              INSIGHTS
            )
          }}
        />
      );
    }
    if (
      this.state.redirectToSolnManagementAndPublish &&
      this.state.publishedTriggered
    ) {
      return (
        <Redirect
          to={{
            pathname: SolutionTypeURLMapperUtil.getSolutionListingURL(
              this.state.redirectToSolnManagementAndPublish.solutionType,
              this.state.redirectToSolnManagementAndPublish.id,
              INSIGHTS
            )
          }}
        />
      );
    }
    if (this.state.redirectToSolnManagementAndPublish && otherPaymentOption) {
      return (
        <Redirect
          to={{
            pathname: SolutionTypeURLMapperUtil.getSolutionListingURL(
              this.state.redirectToSolnManagementAndPublish.solutionType,
              this.state.redirectToSolnManagementAndPublish.id,
              INSIGHTS
            )
          }}
        />
      );
    }

    if (loading) {
      return (
        <div>
          <Grid className="max-width-resp fm-solution-form-content">
            <Row span={12} className="save-loading-spinner">
              <Loader data-testid="loader" label="Loading..." />
            </Row>
          </Grid>
        </div>
      );
    }
    if (
      (!this.state.displayList || !this.state.displayList.length) &&
      !blankEmptyContent
    ) {
      return <EmptyContent message={`No apps yet, Check back soon!`} />;
    }
    if (
      (!this.state.displayList || !this.state.displayList.length) &&
      blankEmptyContent
    ) {
      return null;
    }
    return (
      <div
        className={
          showTitle ? 'field-collection-view clearfix view-mode-full even' : ''
        }
      >
        {showTitle && (
          <Grid className="fm-page-component-grid cards">
            <Row span={12} className="fm-page-header-title">
              <Column>
                <FortellisTitle2
                  title={
                    <FormattedMessage
                      id="SellSolution.registeredSolutions.title"
                      defaultMessage="Proceed to sell from your registered apps"
                    />
                  }
                />
              </Column>
            </Row>
          </Grid>
        )}
        <div
          className={showTitle ? 'fm-page-component-grid solutions-grid' : ''}
        >
          <Grid className="fm-page-component-grid fm-subscription-view-page">
            <Row span={12} className="fm-page-header-title">
              {loading ? (
                <LoadingSpinner />
              ) : this.state.displayList.length > 0 ? (
                <MySolutionListTable
                  data={this.state.displayList}
                  handlerRowClick={this.handleSolnRowClick}
                  entity={entity}
                  userData={userData}
                  tableType={
                    this.props.showSolutionType === SolutionStatus.DRAFT
                      ? 'inDevelopment'
                      : 'inMarketplace'
                  }
                  token={this.props.auth.accessToken}
                />
              ) : (
                <EmptyContent message={`No apps yet, Check back soon!`} />
              )}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { data } = state.verifyAccount;
  return {
    solnTobePublished: data.verified,
    otherPaymentOption: data.otherPaymentOption,
    paymentSettingData: data.paymentSettingData
  };
};
const mapDispatchToProps = dispatch => ({
  getPaymentSettingData: (email, countryCode, token) =>
    dispatch(getPaymentSetting({ email, countryCode, token: token })),
  otherPaymentOptionRequest: () => dispatch(otherPaymentOptionRequest(true))
});

MySolutionsList = injectIntl(
  withApollo(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MySolutionsList)
  )
);

class MySolutionsListContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <MySolutionsList {...this.props} />
    ) : (
      <></>
    );
  }
}
export default injectIntl(withAuth(MySolutionsListContainer));
