import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

class UnauthorizedPage extends React.Component {
  render() {
    return (
      <div>
        <div
          className="max-width-resp"
          style={{ paddingTop: '48px', textAlign: 'center' }}
        >
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="UnauthorizedPage.message1"
              defaultMessage="It appears that you don't have permission to access this page."
            />
          </div>
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="UnauthorizedPage.message2"
              defaultMessage="Please make sure you're authorized to view this content."
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withAuth(UnauthorizedPage));
