import * as SolutionCategories from './solution_categories';
import SolutionStatus from '../common/SolutionStatus';

export const config = {
  formId: 'overview',
  formTitle: 'Overview',
  formValueType: 'Object',
  tabs: [
    {
      id: 'overview',
      label: 'Overview',
      contents: [
        {
          id: 'overview',
          fields: [
            {
              name: 'name',
              label: 'App Name',
              datacy: 'lbl_overview_solname',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              helperText: 'App Name is required.',
              toolTipText: 'Specify the name of the app.',
              required: true,
              maxLength: 40,
              validationType: 'solution_name',
              validationErrorMessage:
                'App name can only contain alphanumeric characters, dashes, and underscores.'
            },
            {
              name: 'category',
              label: 'App Category',
              datacy: 'lbl_overview_solcat',
              type: 'select',
              style: 'formSelectfield',
              gridStyle: 'selectBox_cell',
              options: SolutionCategories.categories,
              required: true,
              validationType: 'category',
              validationErrorMessage: 'Please enter a category.',
              toolTipText:
                'Choose a category for listing. It is used for Store Front search.'
            },
            {
              name: 'applicationArea',
              label: 'Application Area',
              datacy: 'lbl_overview_apparea',
              type: 'text_field',
              maxLength: 40,
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText:
                'Specify the application with which the app is integrated.'
            },
            {
              name: 'searchKeywords',
              label: 'Search Keywords',
              datacy: 'lbl_overview_searchkey',
              type: 'search_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              helperText: 'Enter keywords and separate with a comma.',
              toolTipText: 'Enter keywords and separate with a comma.',
              required: true,
              validationType: 'searchKeywords',
              validationErrorMessage:
                'Please enter Search Keywords and separate with a comma.'
            },
            {
              name: 'version',
              label: 'Version',
              datacy: 'lbl_overview_version',
              type: 'text_field',
              maxLength: 10,
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: 'Specify the app version that is live now.',
              required: true,
              validationType: 'version',
              validationErrorMessage:
                'Please enter app version in format of 1.0.0.'
            },
            {
              name: 'briefDescription',
              label: 'Brief Description',
              datacy: 'lbl_overview_briefdesc',
              type: 'text_area',
              rows: 2,
              maxLength: 128,
              style: ['formtextfield', 'form-text-area'],
              gridStyle: 'layoutcell',
              helperText: 'Description is required.',
              toolTipText:
                'Provide the text to use on the Store Front page and listing page overview tab.',
              required: true,
              validationType: 'solution_brief_description',
              validationErrorMessage: 'Please enter a valid brief description.'
            },
            {
              name: 'detailedDescription',
              label: 'Detailed Description',
              datacy: 'lbl_overview_detaildesc',
              type: 'text_area',
              rows: 2,
              maxLength: 1200,
              style: ['formtextfield', 'form-text-area'],
              gridStyle: 'layoutcell',
              helperText: 'Detailed Description is required.',
              toolTipText:
                'Provide the text to use on the app Listing overview tab.',
              required: true,
              validationType: 'solution_detailed_description',
              validationErrorMessage:
                'Please enter a valid detailed description.'
            },
            {
              name: 'publisherName',
              label: 'Publisher Name',
              datacy: 'lbl_overview_pubname',
              type: 'text_field',
              maxLength: 100,
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              helperText: 'Organization Name is required.',
              toolTipText: "Specify the app publisher's organization name.",
              required: true,
              validationErrorMessage:
                "Please specify the app publisher's organization name."
            },
            {
              name: 'website',
              label: 'Publisher Website',
              datacy: 'lbl_overview_pubweb',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: "Specify the app publisher's website.",
              required: true,
              validationType: 'publisher_website_url',
              validationErrorMessage:
                'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
            },
            {
              name: 'solutionWebsite',
              label: 'App Website',
              datacy: 'lbl_overview_solweb',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: "Specify the app's website.",
              required: true,
              validationType: 'solution_website_url',
              validationErrorMessage:
                'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
            },
            {
              name: 'outageNotificationsEmail',
              label: 'Outage Notifications Email',
              datacy: 'lbl_overview_outage_notifications_email',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: 'Specify the email for sending outage notifications',
              required: true,
              validationType: 'outage_notification_email_address',
              validationErrorMessage:
                'Please provide the email address for sending outage notifactions'
            },
            {
              name: 'copyright',
              label: 'Copyright',
              datacy: 'lbl_overview_copyright',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              helperText: 'Copyright Name is required.',
              toolTipText: "Specify the copyright holder's name.",
              validationType: 'copyright',
              maxLength: 200,
              validationErrorMessage: 'Please enter string only values.'
            },
            {
              name: 'privacyPolicyUrl',
              label: 'Privacy Policy URL',
              datacy: 'lbl_overview_pripolurl',
              type: 'text_field',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              toolTipText: 'Specify the link to the privacy policy of the app.',
              validationType: 'privacy_policy_url',
              validationErrorMessage:
                'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
            },
            {
              name: 'solutionLogo',
              label: 'App Logo',
              note: 'Recommended aspect ratio - 1:1 and size - 256x256.',
              type: 'drop_box_s3_uploader',
              style: 'drop-zone',
              gridStyle: 'layoutCellList',
              disabled: function(status) {
                return (
                  status &&
                  status !== SolutionStatus.REGISTERED &&
                  status !== SolutionStatus.RECALLED &&
                  status !== SolutionStatus.REVOKED &&
                  status !== SolutionStatus.DRAFT &&
                  status !== SolutionStatus.REJECTED
                );
              },
              required: true,
              validationType: 'solution_logo',
              validationErrorMessage:
                'Please enter an image to be used as app logo in the Marketplace. File extensions allowed .jpg, .jpeg & .png. Max file size 5 MB.',
              toolTipText:
                'Provide an image to be used as app logo in the Marketplace. File extensions allowed .jpg, .jpeg & .png. Max file size 5 MB.'
            }
          ]
        }
      ]
    },
    {
      id: 'videos',
      label: 'Videos',
      contents: [
        {
          id: 'videos',
          fields: [
            {
              type: 'multiple_add_form',
              name: 'videos',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: function(status) {
                return (
                  !status ||
                  status === SolutionStatus.REGISTERED ||
                  status === SolutionStatus.RECALLED ||
                  status === SolutionStatus.REVOKED ||
                  status === SolutionStatus.DRAFT ||
                  status === SolutionStatus.REJECTED
                );
              },
              addLinkText: 'ADD VIDEO',
              formDefaultValue: {
                id: '',
                title: '',
                url: '',
                description: ''
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: 'title',
                        label: 'Title',
                        type: 'text_field',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        maxLength: 40,
                        toolTipText: 'Specify the title of the video.'
                      },
                      {
                        name: 'url',
                        label: 'URL',
                        type: 'text_field',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell',
                        toolTipText: 'Specify the URL of the video',
                        validationType: 'video_url',
                        validationErrorMessage:
                          'Please enter a valid URL starting with https or http and ending with a valid domain(e.g. https://marketplace.fortellis.io)'
                      },
                      {
                        name: 'description',
                        label: 'Description',
                        type: 'text_area',
                        style: 'formtextfield form-highlight-text-area',
                        gridStyle: 'layoutcell',
                        maxLength: 128,
                        toolTipText: 'Describe the video.'
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      id: 'gallery',
      label: 'Gallery',
      contents: [
        {
          id: 'featuredImage',
          title: 'Featured Image',
          titleNote:
            'Provide an image to be used in the Store Front if the app is listed as featured app. File extensions allowed .jpg, .jpeg & .png. Max file size allowed 5 MB. Recommended aspect ratio - 16:9 and size - 922x518',
          fields: [
            {
              name: 'featuredImage',
              type: 'drop_box_s3_uploader',
              style: 'featured-image',
              imageStyle: 'featured-drop-box-image',
              deleteIconStyle: 'drop-box-text-with-featured-image-delete',
              uploadText: 'Click to upload',
              iconSize: '56px',
              gridStyle: 'layoutCellList clearfix',
              disabled: function(status) {
                return (
                  status &&
                  status !== SolutionStatus.REGISTERED &&
                  status !== SolutionStatus.RECALLED &&
                  status !== SolutionStatus.REVOKED &&
                  status !== SolutionStatus.DRAFT &&
                  status !== SolutionStatus.REJECTED
                );
              }
            }
          ]
        },
        {
          id: 'galleryImages',
          title: 'Gallery Images',
          titleNote:
            'Multiple gallery images can be uploaded. File extensions allowed .jpg, .jpeg & .png. Max file size allowed 5 MB. Recommended aspect ratio - 16:9 and size - 922x518.',
          fields: [
            {
              type: 'multiple_add_form',
              name: 'galleryImages',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: function(status) {
                return (
                  !status ||
                  status === SolutionStatus.REGISTERED ||
                  status === SolutionStatus.RECALLED ||
                  status === SolutionStatus.REVOKED ||
                  status === SolutionStatus.DRAFT ||
                  status === SolutionStatus.REJECTED
                );
              },
              addLinkText: 'ADD GALLERY IMAGE',
              formDefaultValue: {
                id: '',
                galleryImage: '',
                imagePositionIndex: ''
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    deleteFiles: ['galleryImage'],
                    multipleFields: [
                      {
                        name: 'imagePositionIndex',
                        label: 'Display Order Index',
                        datacy: 'display_order_index',
                        type: 'text_field',
                        fieldType: 'number',
                        min: 0,
                        validationType: 'non_zero_positive_number',
                        validationErrorMessage:
                          'Invalid value. The number should be distinct, non-negative and should be less than or equal to number of images uploaded.',
                        postText: '   ',
                        postTextStyle: 'text-field-post-text',
                        gridStyle: 'text-field-with-pre-post-text',
                        toolTipText:
                          'Defines the order of the images to be displayed on storefront.'
                      },
                      {
                        name: 'galleryImage',
                        type: 'drop_box_s3_uploader',
                        nameFrom: 'id',
                        style: 'gallery-image',
                        imageStyle: 'gallery-drop-box-image',
                        deleteIconStyle:
                          'drop-box-text-with-gallery-image-delete',
                        uploadText: 'Click to upload',
                        iconSize: '24px',
                        gridStyle: 'layoutCellList',
                        disabled: function(status) {
                          return (
                            status &&
                            status !== SolutionStatus.REGISTERED &&
                            status !== SolutionStatus.RECALLED &&
                            status !== SolutionStatus.REVOKED &&
                            status !== SolutionStatus.DRAFT &&
                            status !== SolutionStatus.REJECTED
                          );
                        }
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    overview: {
      name: '',
      category: '',
      applicationArea: '',
      searchKeywords: [],
      version: '',
      briefDescription: '',
      detailedDescription: '',
      publisherName: '',
      website: '',
      solutionWebsite: '',
      outageNotificationsEmail: '',
      copyright: '',
      privacyPolicyUrl: '',
      solutionLogo: '',
      videos: [],
      featuredImage: '',
      galleryImages: []
    }
  },
  requiredFields: [
    'name',
    'searchKeywords',
    'version',
    'briefDescription',
    'publisherName',
    'solutionLogo'
  ],
  activeTabId: 'overview'
};
