import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';
import axios from 'axios';
export const initialState = {
  data: {
    stripeSubscriptionData: [],
    loading: true
  }
};
export const getStripeSubscriptionInfo = createAsyncThunk(
  'getStripeSubscriptionInfo',
  async ({ id, orgId, token }) => {
    const endpoint = `${environmentURLs.marketplaceURL}graphql`;
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const graphqlQuery = {
      query: `
       query($solutionId: String, $orgId: String) {
       getCustomerActiveSubscription(solutionId: $solutionId, orgId: $orgId) {
       id
       org_id
       org_name
       subscription_reference
       solution_id
       taxable_amount
       active
       payment_type
       last4
       next_payment_date
       order_total_amount
       tax_rate
     }
   }`,
      variables: {
        solutionId: id,
        orgId
      }
    };

    const response = await axios({
      url: endpoint,
      method: 'post',
      headers: headers,
      data: graphqlQuery
    });
    return response.data.data.getCustomerActiveSubscription;
  }
);

const stripeSubscriptionSlice = createSlice({
  name: 'stripeSubscriptionInfo',
  initialState: initialState,
  extraReducers: {
    [getStripeSubscriptionInfo.fulfilled]: (state, action) => {
      const results = action.payload;
      state.data.stripeSubscriptionData = results;
      state.data.loading = false;
    },
    [getStripeSubscriptionInfo.pending]: state => {
      state.data.stripeSubscriptionData = [];
      state.data.loading = true;
    },
    [getStripeSubscriptionInfo.rejected]: state => {
      state.data.stripeSubscriptionData = [];
      state.data.loading = false;
    }
  }
});

export default stripeSubscriptionSlice.reducer;
