//polyfills for ie11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/object/values';
import 'core-js/features/string/starts-with';
import 'core-js/features/array/includes';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.function.bind';
import 'core-js/modules/es.map';
import 'core-js/modules/es.set';

import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize-css';
import 'typeface-raleway';
import 'typeface-montserrat';
import '@cdk-uip/react/cdk.css';
import './components/solution-details/SolutionDetailsScreen.scss';
import './custom-theme.scss';
import App from './components/App';
import store from './redux/store/store';
import { Provider } from 'react-redux';
import { initAmplitude } from './utils/amplitude';

initAmplitude();
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
