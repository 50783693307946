import React from 'react';
import Spinner from '../common/Spinner';
import { Form } from '@cdk-uip/react-form';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { Button } from '@cdk-uip/react-button';
import { commandShape } from './shapes';
import PropTypes from 'prop-types';
import SideNav from './common-sidenav-api/SideNavComponent/SideNav';
import MenuDataConfig from './menu-config';
import { FormConfig } from './solution_submition_form_config';
import { FormUtils } from './common-form-api/utils/FormUtils';
import { SideNavUtils } from './common-sidenav-api/utils/SideNavUtils';
import * as SolutionFormData from './solution_data_object';
import FormAPI from './common-form-api/FormAPI';
import moment from 'moment';
import uuid from 'uuid/v4';
import SolutionStatus from '../common/SolutionStatus';
import { FormattedMessage, injectIntl } from 'react-intl';
import ActionSnackBar from './ActionSnackBar';
import SolutionOperations from '../../containers/utils/SolutionOperations';
import FortellisDialog from '../common/FortellisDialog';
import cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';
import { FormConfig as ReviewFormConfig } from '../admin-account/solution-moderation/solution_review_form_config';
import { Redirect } from 'react-router-dom';
import SolutionTypeURLMapperUtil from '../common/SolutionTypeURLMapperUtil';
import PreviewSolution from '../../containers/PreviewSolutionContainer';
import queryString from 'query-string';
import FortellisConstants from '../common/FortellisConstants';
import { ProvisioningConstants } from '../common/ProvisioningConstants';
import UnauthorizedPage from '../common/UnauthorizedPage';
import EntityRegistrationConfirmation from '../common/EntityRegistrationConfirmation';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import axios from 'axios';
import { Tooltip } from '@cdk-uip/react-tooltip';
import LoadingStepper from '../common/LoadingStepper';
import TermTypes from './common-form-api/FormComponents/TermTypes';
import { AppContextConsumer } from '../App';
import { Dialog, DialogBody } from '@cdk-uip/react-dialog';
import DeveloperAgreement from './DeveloperAgreement';
import { getRedLineContractStatus } from '../common/RedlineContract';
import { connect } from 'react-redux';
import { fetchAcceptedRatePlans } from '../../redux/connectedComponents/appSubmission/appSubmission.slice';
import { getApolloClient } from '../../containers/GraphQLClient';

let isSolutionReview = false;

let getFormConfig = (solutionState, type) => {
  switch (solutionState) {
    case SolutionStatus.REVOKED:
    case SolutionStatus.REGISTERED:
    case SolutionStatus.RECALLED:
    case SolutionStatus.REJECTED:
    case SolutionStatus.DRAFT:
      isSolutionReview = false;
      return cloneDeep(FormConfig);
    default:
      isSolutionReview = true;
      return cloneDeep(ReviewFormConfig);
  }
};

const DisplayTermsContext = React.createContext();
export const DisplayContextConsumer = DisplayTermsContext.Consumer;

const SOLUTION_TYPE_CONST = {
  FORTELLIS: 'fortellis',
  PARTNER_PROGRAM: 'partnerProgram'
};
const INSIGHTS = 'insights';

export class SolutionSubmissionForm extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    submit: commandShape,
    solutionListing: commandShape
  };

  state = {
    openLeavePageConfirmationDialog: false,
    activeTabId: null,
    openDialogForRecall: false,
    openDialogForPublish: false,
    solutionSubmissionConfirmation: false,
    openDialog: false,
    menu: MenuDataConfig.MenuData,
    openPreviewDialog: false,
    initialize: false,
    view: false,
    savedNewSolution: false,
    dateFormat: 'DD MMM YYYY hh:mm A',
    developerId: this.props.userData.email,
    developerName: this.props.userData.name,
    orgId: this.props.entity.id,
    saving: false,
    published: false,
    validationResponse: {},
    latestComment: '',
    comment: '',
    solutionName: '',
    confirm: false,
    snackBar: false,
    formStaticValuesTemp: {},
    formStaticValues: {
      id: '',
      listingVersion: '',
      apigee_id: '',
      developerId: '',
      developerName: '',
      orgId: '',
      status: '',
      submittedOn: '',
      lastUpdatedBy: '',
      lastUpdatedOn: '',
      solutionType: '',
      comments: [],
      registeredOn: ''
    },
    formValueFinal: {},
    loadingAPIData: false,
    errorWhileFetchingAPIData: false,
    submitClicked: false,
    publishingSteps: ['Publishing App', 'Notifying API Providers'],
    publishingActiveStep: 0,
    showPublishingStepper: false,
    displayTerms: false,
    termsOfUse: {
      type: '',
      file: '',
      fileName: '',
      lastModified: ''
    },
    showSummary: false,
    isRedLineContractSigned: false,
    activateOnly: true
  };

  constructor(props) {
    super(props);
    this.solutionOps = new SolutionOperations();
    this.validatedFields = {};
  }

  removeFieldsFromOverviewTabForPrivateSolution = formConfig => {
    //this function changes the mandatory fields to non-mandatory in overview tab for private solutions

    let newFormConfig = formConfig;
    //category is not mandatory for private solutions
    delete newFormConfig['overview'].tabs[0].contents[0].fields[1].required;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[1]
      .validationType;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[1]
      .validationErrorMessage;

    //detailed description is not mandatory for private solutions
    delete newFormConfig['overview'].tabs[0].contents[0].fields[6].required;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[6]
      .validationType;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[6]
      .validationErrorMessage;

    //publisher website is not mandatory for private solutions
    delete newFormConfig['overview'].tabs[0].contents[0].fields[8].required;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[8]
      .validationType;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[8]
      .validationErrorMessage;

    //solution website is not mandatory for private solutions
    delete newFormConfig['overview'].tabs[0].contents[0].fields[9].required;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[9]
      .validationType;
    delete newFormConfig['overview'].tabs[0].contents[0].fields[9]
      .validationErrorMessage;

    return newFormConfig;
  };

  validateMandatoryFieldsAgainIfUserDecidesToChangeFromPrivateToPublic = solutionData => {
    //this function validates the mandatory fields again if user decides to change a private solution to public
    const URLRegex = /^(http|https):\/\/(www\.)?(?!\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,7}(:[-a-zA-Z0-9]{1,10})?(\/.*)?$/i;

    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.category
    ) {
      this.validatedFields['category'] = {
        isValidated: false,
        target: 'category',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      this.validatedFields['category'] = {
        isValidated: true,
        target: 'category',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    }
    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.detailedDescription ||
      solutionData.overview.overview.detailedDescription.trim() === ''
    ) {
      this.validatedFields['detailedDescription'] = {
        isValidated: false,
        target: 'detailedDescription',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      this.validatedFields['detailedDescription'] = {
        isValidated: true,
        target: 'detailedDescription',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    }
    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.briefDescription ||
      solutionData.overview.overview.briefDescription.trim() === ''
    ) {
      this.validatedFields['briefDescription'] = {
        isValidated: false,
        target: 'briefDescription',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      this.validatedFields['briefDescription'] = {
        isValidated: true,
        target: 'briefDescription',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    }
    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.briefDescription ||
      solutionData.overview.overview.briefDescription.trim() === ''
    ) {
      this.validatedFields['briefDescription'] = {
        isValidated: false,
        target: 'briefDescription',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      this.validatedFields['briefDescription'] = {
        isValidated: true,
        target: 'briefDescription',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    }
    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.website ||
      solutionData.overview.overview.website.trim() === ''
    ) {
      this.validatedFields['website'] = {
        isValidated: false,
        target: 'website',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      let regex = URLRegex;
      if (!regex.test(solutionData.overview.overview.website)) {
        this.validatedFields['website'] = {
          isValidated: false,
          target: 'website',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      } else {
        this.validatedFields['website'] = {
          isValidated: true,
          target: 'website',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      }
    }
    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.solutionWebsite ||
      solutionData.overview.overview.solutionWebsite.trim() === ''
    ) {
      this.validatedFields['solutionWebsite'] = {
        isValidated: false,
        target: 'solutionWebsite',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      let regex = URLRegex;
      if (!regex.test(solutionData.overview.overview.solutionWebsite)) {
        this.validatedFields['solutionWebsite'] = {
          isValidated: false,
          target: 'solutionWebsite',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      } else {
        this.validatedFields['solutionWebsite'] = {
          isValidated: true,
          target: 'solutionWebsite',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      }
    }

    if (
      solutionData &&
      solutionData.overview &&
      solutionData.overview.overview &&
      !solutionData.overview.overview.privacyPolicyUrl
    ) {
      this.validatedFields['privacyPolicyUrl'] = {
        isValidated: true,
        target: 'privacyPolicyUrl',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      let regex = URLRegex;
      if (!regex.test(solutionData.overview.overview.privacyPolicyUrl)) {
        this.validatedFields['privacyPolicyUrl'] = {
          isValidated: false,
          target: 'privacyPolicyUrl',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      } else {
        this.validatedFields['privacyPolicyUrl'] = {
          isValidated: true,
          target: 'privacyPolicyUrl',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      }
    }

    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.version ||
      solutionData.overview.overview.version.trim() === ''
    ) {
      this.validatedFields['version'] = {
        isValidated: false,
        target: 'version',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      let regex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
      if (!regex.test(solutionData.overview.overview.version)) {
        this.validatedFields['version'] = {
          isValidated: false,
          target: 'version',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      } else {
        this.validatedFields['version'] = {
          isValidated: true,
          target: 'version',
          activeFormId: 'overview',
          activeTabId: 'overview',
          activeSubFormId: 'overview'
        };
      }
    }

    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.publisherName ||
      solutionData.overview.overview.publisherName.trim() === ''
    ) {
      this.validatedFields['publisherName'] = {
        isValidated: false,
        target: 'publisherName',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      this.validatedFields['publisherName'] = {
        isValidated: true,
        target: 'publisherName',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    }
  };

  getConfig = (solutionState, type) => {
    let { features = [] } = this.state.formValueFinal;
    let formConfig = getFormConfig(solutionState, type);
    formConfig['plans']['formValues']['featureArray'] = _map(
      features,
      ({ id, title, description, featureImage }) => {
        return { id, title, description, featureImage };
      }
    );
    if (this.state.activeTabId && formConfig[this.state.menu.activeMenuId]) {
      if (
        formConfig[this.state.menu.activeMenuId]['tabs'].filter(
          tab => tab.id === this.state.activeTabId
        ).length
      ) {
        formConfig[this.state.menu.activeMenuId][
          'activeTabId'
        ] = this.state.activeTabId;
      }
    }
    let originalFormConfig = formConfig;
    if (
      this.state.formValueFinal &&
      this.state.formValueFinal.solution_visibility
    ) {
      if (
        this.state.formValueFinal.solution_visibility.solution_visibility
          .solutionVisibilityType === 'Private'
      ) {
        formConfig = this.removeFieldsFromOverviewTabForPrivateSolution(
          formConfig
        );
      } else {
        this.validateMandatoryFieldsAgainIfUserDecidesToChangeFromPrivateToPublic(
          this.state.formValueFinal
        );
        formConfig = originalFormConfig;
      }
    }
    return formConfig;
  };

  onTabChange(tabId) {
    this.setState({ activeTabId: tabId });
  }
  onShowForRecall = () => {
    this.setState({ openDialogForRecall: true });
  };

  onShowForPublish = () => {
    this.setState({ openDialogForPublish: true });
  };

  getPublishSolutionDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionSubmissionForm.publishTitle"
          defaultMessage="Publish to Marketplace"
        />
      }
      message={
        <FormattedMessage
          id="SolutionSubmissionForm.publishMessage"
          defaultMessage="Are you sure you want to publish this app to marketplace?"
        />
      }
      open={this.state.openDialogForPublish}
      acceptButtonName={
        <FormattedMessage
          id="SolutionSubmissionForm.publishAccept"
          defaultMessage="PROCEED"
        />
      }
      cancelButtonName={
        <FormattedMessage
          id="SolutionSubmissionForm.publishReject"
          defaultMessage="CANCEL"
        />
      }
      onAccept={() => {
        this.setState({
          openDialogForPublish: false
        });

        if (this.isRedLineContractSigned) {
          this.onDeveloperAgreementClose({
            status: 'accepted',
            url: ''
          });
          return;
        }
        this.setState({
          launchDeveloperAgreement: true
        });
      }}
      onCancel={() => {
        this.setState({ openDialogForPublish: false });
      }}
      acceptDataCy={'btn_solution_submission_dialog_publish_ok'}
      cancelDataCy={'btn_solution_submission_dialog_publish_cancel'}
    />
  );

  handleCommentChange = event => {
    this.setState({ comment: event.target.value });
  };

  isFormEditable(status) {
    return [
      SolutionStatus.REVOKED,
      SolutionStatus.REGISTERED,
      SolutionStatus.RECALLED,
      SolutionStatus.REJECTED,
      SolutionStatus.DRAFT
    ].includes(status);
  }

  handlePreview = async () => {
    // Save data only when form is in editable mode
    if (this.isFormEditable(this.state.formStaticValues.status)) {
      await this.saveBeforePreview();
    }
    this.setState({ openPreviewDialog: true });
  };

  saveBeforePreview = async () => {
    await this.solutionOps.onSave({
      formValueFinal: this.state.formValueFinal,
      formStaticValues: this.state.formStaticValues,
      formStaticValuesTemp: this.state.formStaticValuesTemp,
      userData: this.props.userData,
      solution: this.props.solution[0]
        ? this.props.solution[0].solutiondata
        : null,
      solutionWillSave: () => {
        this.setState({
          snackBar: false,
          saving: true
        });
      },
      solutionDidSave: async (formValueFinal, formStaticValuesTemp) => {
        this.setState({
          formValueFinal: formValueFinal,
          formStaticValuesTemp: {},
          saving: false
        });
        if (
          this.props.match &&
          this.props.match.params &&
          !this.props.match.params.id
        ) {
          this.setState({ savedNewSolution: true });
          if (
            this.props.match.params.type ===
            FortellisConstants.PARTNER_PROGRAM_TYPE_URL
          ) {
            //For Partner Program, solutionId and Apigee_Id are generated on first save/Preview and needs to be reinitialized as part of static fields
            let staticData = FormUtils.initializeStaticFormFields(
              this.state.formStaticValues,
              formValueFinal,
              this.state.developerId
            );
            this.setState({ formStaticValues: staticData });
          }
        }
      }
    });
  };

  onLeavePage = () => {
    if (
      !this.state.formStaticValues.status ||
      this.state.formStaticValues.status === SolutionStatus.REGISTERED ||
      this.state.formStaticValues.status === SolutionStatus.RECALLED ||
      this.state.formStaticValues.status === SolutionStatus.REJECTED ||
      this.state.formStaticValues.status === SolutionStatus.REVOKED ||
      this.state.formStaticValues.status === SolutionStatus.DRAFT
    )
      this.setState({ openLeavePageConfirmationDialog: true });
    else {
      let {
        props: {
          history: { push }
        }
      } = this;
      push(
        SolutionTypeURLMapperUtil.getSolutionListingURL(
          this.state.formValueFinal.solutionType,
          this.state.formValueFinal.id,
          INSIGHTS
        )
      );
    }
  };

  onSave = () => {
    this.solutionOps.onSave({
      formValueFinal: this.state.formValueFinal,
      formStaticValues: this.state.formStaticValues,
      formStaticValuesTemp: this.state.formStaticValuesTemp,
      userData: this.props.userData,
      solution: this.props.solution[0]
        ? this.props.solution[0].solutiondata
        : null,
      solutionWillSave: () => {
        this.setState({
          snackBar: false,
          saving: true
        });
      },
      solutionDidSave: formValueFinal => {
        this.setState({
          formValueFinal: formValueFinal,
          formStaticValuesTemp: {},
          saving: false,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage"
                  defaultMessage="App saved successfully."
                />
              );
            }
          }
        });
        if (
          this.props.match &&
          this.props.match.params &&
          !this.props.match.params.id
        ) {
          this.setState({ savedNewSolution: true });
          if (
            this.props.match.params.type ===
            FortellisConstants.PARTNER_PROGRAM_TYPE_URL
          ) {
            //For Partner Program, solutionId and Apigee_Id are generated on first save/Preview and needs to be reinitialized as part of static fields
            let staticData = FormUtils.initializeStaticFormFields(
              this.state.formStaticValues,
              formValueFinal,
              this.state.developerId
            );
            this.setState({ formStaticValues: staticData });
          }
        }
      },
      solutionFailedToSave: formValueFinal => {
        this.setState({
          formValueFinal: formValueFinal,
          formStaticValuesTemp: {},
          saving: false,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarFailMessage"
                  defaultMessage="Failed to save app."
                />
              );
            }
          }
        });
      }
    });
  };

  onRecall = async () => {
    this.solutionOps.onRecall({
      formValueFinal: this.state.formValueFinal,
      formStaticValues: this.state.formStaticValues,
      solutionName: this.state.solutionName,
      userData: this.props.userData,
      solutionWillBeRecalled: () => {
        this.setState({
          snackBar: false,
          saving: true
        });
      },
      solutionWasRecalled: (formValueFinal, formStaticValues, commentText) => {
        this.setState({
          saving: false,
          published: false,
          formValueFinal: formValueFinal,
          latestComment: commentText,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage2"
                  defaultMessage="App recalled successfully."
                />
              );
            }
          }
        });
      }
    });
  };

  isFortellisSolution = solutionType => {
    return solutionType === FortellisConstants.FORTELLIS;
  };

  onPublish = async developerAgreementUrl => {
    const { formValueFinal } = this.state;
    formValueFinal.developerAgreement = developerAgreementUrl;

    await this.solutionOps.onPublish({
      formValueFinal: this.state.formValueFinal,
      formStaticValues: this.state.formStaticValues,
      solutionName: this.state.solutionName,
      userData: this.props.userData,
      entityInfo: {
        name: this.props && this.props.entity && this.props.entity.name,
        id: this.props && this.props.entity && this.props.entity.id
      },
      solutionWillPublish: () => {
        if (this.isFortellisSolution(formValueFinal.solutionType)) {
          this.setState({
            showPublishingStepper: true,
            publishingActiveStep: 0
          });
        } else {
          this.setState({
            saving: true,
            openDialogForPublish: false
          });
        }
      },
      solutionWasPublished: async () => {
        if (this.isFortellisSolution(formValueFinal.solutionType)) {
          this.setState(prevState => ({
            publishingActiveStep: prevState.publishingActiveStep + 1,
            published: true
          }));
          //commenting for time being as the requirement is send email on approval and not publishing
          // await this.solutionOps.notifyAPIProvider(
          //   formValueFinal,
          //   this.props.entity,
          //   this.props.userData
          // );
          this.setState({
            showPublishingStepper: false
          });
        } else {
          this.setState({
            saving: false,
            published: true
          });
        }
        this.setState({
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage3"
                  defaultMessage="App successfully published to Marketplace."
                />
              );
            }
          }
        });
      }
    });
  };

  onAccept = () => {
    const { onAccept } = this.state.confirm;
    this.setState({ confirm: false });
    onAccept();
  };
  onCancel = () => {
    this.setState({ confirm: false });
  };

  onSubmit = () => {
    this.setState({ submitClicked: true });
    if (
      this.isFortellisSolution(this.state.formStaticValues.solutionType) &&
      this.state.formValueFinal.activateOnly === false
    ) {
      this.validatePlans();
    }
    if (this.isFormInvalid()) {
      this.navigateToErrorField();
      return;
    } else {
      if (this.isTermsOfUseSelected()) {
        this.setState({ solutionSubmissionConfirmation: true });
      }
    }
  };

  onDeveloperAgreementClose(status) {
    let { formValueFinal } = this.state;
    this.setState({ launchDeveloperAgreement: false });

    if (status.status === 'accepted') {
      formValueFinal.developerAgreement = status.url;
      this.setState({
        formValueFinal: formValueFinal
      });
      this.onPublish(status && status.url);
    } else if (status.status === 'declined') {
      console.error('user declined to sign');
    }
  }

  getRedLineContractStatus = async () => {
    try {
      const {
        auth: { accessToken }
      } = this.props;

      const response = await getRedLineContractStatus(
        accessToken,
        this.state.orgId
      );
      this.enablePublishToMarketplaceButton = true;
      this.isRedLineContractSigned = response.data.payload;
    } catch (error) {
      console.error({ Error: error });
      this.enablePublishToMarketplaceButton = true;
      this.isRedLineContractSigned = false;
    }
  };

  isTermsOfUseSelected() {
    if (
      this.state.activateOnly === false &&
      !this.state.termsOfUse.fileName &&
      !this.state.formValueFinal.overview.overview.termsAndConditionsUrl
    ) {
      this.setState({
        snackBar: {
          message: () => {
            return (
              <FormattedMessage
                id="SolutionSubmissionForm.termsOfUseError"
                defaultMessage="Please select Terms Of Use for app"
              />
            );
          }
        }
      });
    } else {
      return true;
    }
  }

  acceptSubmitSolution() {
    this.setState({ solutionSubmissionConfirmation: false });
    this.submitSolution();
  }

  isPartnerProgramSolution = () => {
    let isPartnerProgram = false;
    if (this.props.location && this.props.location.search) {
      const values = queryString.parse(this.props.location.search);
      if (values.save) {
        isPartnerProgram = true;
      }
    }
    return isPartnerProgram;
  };

  getOneToOneStatus = () => {
    if (this.props) {
      const { solution } = this.props;
      if (solution && solution.length > 0) {
        const oneToOne = solution[0].oneToOneUI;
        if (oneToOne && oneToOne === true) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  submitSolution = async () => {
    if (this.isFormInvalid()) {
      this.navigateToErrorField();
      return;
    }
    let uploadTerms = true;
    if (!this.state.termsOfUse.fileName) {
      this.setState({ uploadingTerms: false });
      uploadTerms = false;
    } else {
      this.setState({ uploadingTerms: true });
    }
    this.solutionOps.onSubmit({
      oneToOneUI: this.getOneToOneStatus(),
      termsFile: this.state.termsOfUse.file,
      termsFileName: this.state.termsOfUse.fileName,
      signatureCoordinates: this.state.termsOfUse.signatureCoordinates,
      termsType: this.state.termsOfUse.type,
      formValueFinal: this.state.formValueFinal,
      formStaticValues: this.state.formStaticValues,
      formStaticValuesTemp: this.state.formStaticValuesTemp,
      userData: this.props.userData,
      menu: this.props.menu,
      solution: this.props.solution[0]
        ? this.props.solution[0].solutiondata
        : null,
      uploadTerms: uploadTerms,
      termsUploaded: () => {
        this.setState({ uploadingTerms: false });
      },
      solutionWillSubmit: () => {
        this.setState({
          snackBar: false
        });
      },
      solutionValuesWillMap: staticData => {
        this.setState({ formStaticValuesTemp: staticData });
      },
      solutionWillSave: (validationResponse, menu) => {
        this.setState({
          saving: true,
          validationResponse: validationResponse
        });
        if (menu) {
          this.setState({ menu: menu });
        }
      },
      solutionDidSave: (formValueFinal, formStaticValues, commentText) => {
        this.setState({
          formValueFinal: formValueFinal,
          formStaticValues: formStaticValues,
          formStaticValuesTemp: {},
          latestComment: commentText,
          saving: false,
          sentForReview: true,
          snackBar: {
            message: () => {
              return (
                <FormattedMessage
                  id="SolutionSubmissionForm.snackBarMessage4"
                  defaultMessage="App submitted for review successfully."
                />
              );
            }
          }
        });
      }
    });
  };

  async fetchProviders(apis) {
    let providers = [];
    if (apis.length) {
      let response = await this.props.client.query({
        query: getProviders,
        variables: {
          apiIds: apis
        }
      });
      if (response.data && response.data.providers) {
        providers = response.data.providers;
      }
      return providers;
    } else {
      return providers;
    }
  }

  async fetchConsents(solutionId, listingVersion) {
    const {
      auth: { accessToken }
    } = this.props;

    const response = await axios.get(
      `${
        environmentURLs.consentServiceBaseUrl
      }/solutions/${solutionId}/${listingVersion}/consents`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : ''
        }
      }
    );
    return response ? response.data : [];
  }

  async getNoOfSubscription(solutionId) {
    let numberOfSubscriptions = 0;
    if (solutionId) {
      let response = await this.props.client.query({
        query: getNoOfSubscription,
        variables: {
          solutionId: solutionId
        }
      });
      if (response.data && response.data.solutionSubscriptions) {
        numberOfSubscriptions = response.data.solutionSubscriptions.length;
      }
      return numberOfSubscriptions;
    }
  }

  filterConsentsByListingVersion = consentArr => {
    let filteredArray = [];
    const { formStaticValues } = this.state;
    if (formStaticValues.listingVersion) {
      filteredArray = consentArr.filter(
        consent => consent.listingVersion === formStaticValues.listingVersion
      );
    }
    filteredArray = filteredArray.filter(
      consent => consent.consentType === 'api_tos'
    );
    return filteredArray;
  };

  refetchConsents = async () => {
    let solution = this.props.solution[0];
    const listingVersion =
      solution && solution.solutiondata && solution.solutiondata.listingVersion
        ? solution.solutiondata.listingVersion
        : '1';
    let updatedConsents = await this.fetchConsents(solution.id, listingVersion);
    let consentArr = [];
    for (const key in updatedConsents) {
      consentArr.push(updatedConsents[key]);
    }
    consentArr = this.filterConsentsByListingVersion(consentArr);

    let formValueFinalCopy = JSON.parse(
      JSON.stringify(this.state.formValueFinal)
    );
    if (
      formValueFinalCopy.registration_details.registration_details
        .api_details &&
      this.state.apisWithProviders
    ) {
      formValueFinalCopy.registration_details.registration_details.api_details.map(
        api => {
          api.providers.forEach(provider => {
            consentArr.map(consent => {
              if (provider.id === consent.apiProviderId) {
                provider.consent = consent;
              }
            });
          });
        }
      );
    }
    this.setState({
      formValueFinal: formValueFinalCopy
    });
  };

  async getAsyncApiDetails(id) {
    let response = await this.props.client.query({
      query: getAsyncApiData,
      variables: {
        id: id
      }
    });
    return response.data;
  }

  createApiToProvidersMappingWithConsent = async solution => {
    //creating apiToProvidersMapping along with consent Info
    this.setState({ loadingAPIData: true });
    let apisRequiredForSolutions = null;
    let apiIds = [];
    const { formStaticValues } = this.state;
    //To avoid newly added API reflecting in marketplace solution, fetch API details from save record in marketplace instead of APIGEE
    // if one to one ui then call expand service
    let asyncApiData = [];
    let restApiDataOneToOne = [];
    if (solution.oneToOneUI) {
      this.setState({ isSubmitDisable: true });
      let asyncApiDetails = await this.getAsyncApiDetails(solution.id);
      let asyncApis = [];
      let filteredAsyncApiDetails = asyncApiDetails.asyncApiDetails.integrations.filter(
        item => {
          return item.apiType === 'async-api';
        }
      );
      let asyncApiIntegrations = asyncApiDetails.asyncApiDetails.asyncApiIntegrations.map(
        asyncApi => {
          return {
            id: asyncApi.id,
            asyncApiId: asyncApi.asyncApi.id
          };
        }
      );
      let filteredRestApiDetails = asyncApiDetails.asyncApiDetails.integrations.filter(
        item => {
          return item.apiType === 'api';
        }
      );
      let oneToOnefilteredRestApiDetails = asyncApiDetails.asyncApiDetails.apiProducts.map(
        item => {
          return item.name;
        }
      );
      filteredAsyncApiDetails.forEach(item => {
        let apiObj = {};
        apiObj.description = item.description;
        apiObj.displayName = item.displayName;
        apiObj.id = item.id;
        apiObj.name = item.name;
        apiObj.apiType = item.apiType;
        apiObj.providers = item.providers.map(p => {
          return {
            name: p.apiProviderName ? p.apiProviderName : 'N/A',
            id: p.id ? p.id : 'N/A',
            entityId: p.entityId ? p.entityId : 'N/A'
          };
        });
        for (let i = 0; i < asyncApiIntegrations.length; i++) {
          if (item.id === asyncApiIntegrations[i].asyncApiId) {
            apiObj.asyncIntegrationId = asyncApiIntegrations[i].id;
            break;
          }
        }
        asyncApis.push(apiObj);
      });
      asyncApiData = [...asyncApis];

      let restApiDetails = [];
      oneToOnefilteredRestApiDetails.forEach(restApi => {
        filteredRestApiDetails.forEach(item => {
          if (restApi.includes(item.id)) {
            let apiObj = {};
            apiObj.description = item.description;
            apiObj.displayName = item.displayName;
            apiObj.id = restApi;
            apiObj.name = item.name;
            apiObj.apiType = item.apiType;
            apiObj.providers = item.providers.map(p => {
              return {
                name: p.apiProviderName ? p.apiProviderName : 'N/A',
                id: p.id ? p.id : 'N/A',
                entityId: p.entityId ? p.entityId : 'N/A'
              };
            });
            restApiDetails.push(apiObj);
          }
        });
      });
      restApiDataOneToOne = [...restApiDetails];
      this.setState({ isSubmitDisable: false });
    }

    const isApiDetailsSavedInMp = () => {
      return (
        solution.solutiondata &&
        solution.solutiondata.registration_details &&
        solution.solutiondata.registration_details.api_details
      );
    };

    if (isApiDetailsSavedInMp()) {
      apisRequiredForSolutions = JSON.parse(
        JSON.stringify(solution.solutiondata.registration_details.api_details)
      );

      //check if API list for the solution is modified from DevNet, update in MP if submission form is editable
      if (
        solution.solutiondata &&
        solution.solutiondata.status &&
        this.isFormEditable(solution.solutiondata.status)
      ) {
        //Add newly added APIs
        solution.requires.forEach(api => {
          if (!apisRequiredForSolutions.some(api1 => api1.id === api.id)) {
            apisRequiredForSolutions.push(api);
          }
        });
        //Delete deleted APIs
        apisRequiredForSolutions = apisRequiredForSolutions.filter(api =>
          solution.requires.some(api1 => api1.id === api.id)
        );
      }
      apiIds = apisRequiredForSolutions.map(api => api.id);
    } else {
      apisRequiredForSolutions = JSON.parse(JSON.stringify(solution.requires));
      apiIds = solution.requires.map(api => api.id);
    }

    let fetchProviders = this.fetchProviders(apiIds);
    const listingVersion =
      solution && solution.solutiondata && solution.solutiondata.listingVersion
        ? solution.solutiondata.listingVersion
        : '1';
    let fetchConsents = this.fetchConsents(solution.id, listingVersion);
    let numberOfSubscriptions = this.getNoOfSubscription(solution.id);

    Promise.all([fetchProviders, fetchConsents, numberOfSubscriptions])
      .then(values => {
        this.setState({ loadingAPIData: false });
        let providers = values[0];
        let consentsObj = values[1];
        let consentArr = [];
        for (const key in consentsObj) {
          consentArr.push(consentsObj[key]);
        }

        if (formStaticValues.listingVersion) {
          consentArr = consentArr.filter(
            consent =>
              consent.listingVersion === formStaticValues.listingVersion
          );
        }
        consentArr = consentArr.filter(
          consent => consent.consentType === 'api_tos'
        );

        providers &&
          providers.forEach(provider => {
            consentArr.forEach(consent => {
              if (provider.id === consent.apiProviderId) {
                if (consent && consent.consentType === 'api_tos') {
                  provider.consent = consent;
                }
              }
            });
          });

        let apisWithProviders = apisRequiredForSolutions.map(apiDetail => {
          apiDetail.apiType = 'api';
          apiDetail.providers = providers.filter(
            provider => provider.api === apiDetail.id
          );
          return apiDetail;
        });

        let formValueFinalCopy = JSON.parse(
          JSON.stringify(this.state.formValueFinal)
        );
        if (
          formValueFinalCopy.registration_details.registration_details
            .api_details
        ) {
          if (!solution.oneToOneUI) {
            formValueFinalCopy.registration_details.registration_details.api_details = apisWithProviders;
          } else {
            formValueFinalCopy.registration_details.registration_details.api_details = restApiDataOneToOne.concat(
              asyncApiData
            );
          }
          formValueFinalCopy.registration_details.registration_details.numberOfSubscriptions =
            values[2];
        }

        let api_details_array = formValueFinalCopy.registration_details.registration_details.api_details.filter(
          item => {
            if (item.id !== environmentURLs.TestAPI) {
              return item;
            }
          }
        );

        const key = 'id';
        formValueFinalCopy.registration_details.registration_details.api_details = [
          ...new Map(api_details_array.map(item => [item[key], item])).values()
        ];

        let isUpdatedInMpToFortellisApp = false;

        if (
          formValueFinalCopy.solutionType ===
            FortellisConstants.PARTNER_PROGRAM &&
          formValueFinalCopy.registration_details &&
          formValueFinalCopy.registration_details.registration_details &&
          formValueFinalCopy.registration_details.registration_details
            .api_details &&
          Array.isArray(
            formValueFinalCopy.registration_details.registration_details
              .api_details
          ) &&
          formValueFinalCopy.registration_details.registration_details
            .api_details.length > 0 &&
          formValueFinalCopy.registration_details.registration_details
            .api_details[0].id !== environmentURLs.TestAPI
        ) {
          formValueFinalCopy.solutionType = FortellisConstants.FORTELLIS;
          isUpdatedInMpToFortellisApp = true;
        }

        if (
          this.props.solutionType === FortellisConstants.PARTNER_PROGRAM &&
          formValueFinalCopy &&
          formValueFinalCopy.solutionType &&
          formValueFinalCopy.solutionType === FortellisConstants.FORTELLIS &&
          formValueFinalCopy.registration_details &&
          formValueFinalCopy.registration_details.registration_details &&
          formValueFinalCopy.registration_details.registration_details
            .api_details &&
          (formValueFinalCopy.registration_details.registration_details
            .api_details.length === 0 ||
            formValueFinalCopy.registration_details.registration_details
              .api_details[0].id === environmentURLs.TestAPI)
        ) {
          formValueFinalCopy.solutionType = FortellisConstants.PARTNER_PROGRAM;
        }

        this.setState({
          apisWithProviders,
          isUpdatedInMpToFortellisApp,
          formValueFinal: formValueFinalCopy
        });

        if (
          this.props.solutionType !== FortellisConstants.PARTNER_PROGRAM &&
          apisWithProviders &&
          apisWithProviders.length
        ) {
          let atleastOneProviderExistForEachApiOnLoad = apisWithProviders.every(
            api =>
              api.providers.some(
                provider =>
                  provider.consent && provider.consent.status === 'accepted'
              )
          );
          if (solution && solution.oneToOneUI) {
            atleastOneProviderExistForEachApiOnLoad = true;
          }
          this.validatedFields['api_details'] = {
            isValidated: atleastOneProviderExistForEachApiOnLoad,
            target: 'api_details',
            activeFormId: 'registration_details',
            activeTabId: 'connectedApis',
            activeSubFormId: 'registration_details'
          };
          this.setState({ atleastOneProviderExistForEachApiOnLoad });
        }
      })
      .catch(err => {
        console.error('error While Fetching APIData', err);
        this.setState({
          loadingAPIData: false,
          errorWhileFetchingAPIData: true
        });
      });
  };

  validateSolutionLogo = solutionData => {
    if (
      !solutionData ||
      !solutionData.overview ||
      !solutionData.overview.overview ||
      !solutionData.overview.overview.solutionLogo
    ) {
      this.validatedFields['solutionLogo'] = {
        isValidated: false,
        target: 'solutionLogo',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    } else {
      this.validatedFields['solutionLogo'] = {
        isValidated: true,
        target: 'solutionLogo',
        activeFormId: 'overview',
        activeTabId: 'overview',
        activeSubFormId: 'overview'
      };
    }
  };

  validateMandatoryEmails = solutionData => {
    //this function validates the required email fields in subscription notification and support tabs
    //these fields are mandatory both for public and private solutions

    if (
      !solutionData ||
      !solutionData.support ||
      !solutionData.support.support ||
      !solutionData.support.support.emailAddress ||
      solutionData.support.support.emailAddress.trim() === ''
    ) {
      this.validatedFields['emailAddress'] = {
        isValidated: false,
        target: 'emailAddress',
        activeFormId: 'support',
        activeTabId: 'questions_and_support',
        activeSubFormId: 'support'
      };
    } else {
      this.validatedFields['emailAddress'] = {
        isValidated: true,
        target: 'emailAddress',
        activeFormId: 'support',
        activeTabId: 'questions_and_support',
        activeSubFormId: 'support'
      };
    }

    if (
      !solutionData ||
      !solutionData.subscription_notifications ||
      !solutionData.subscription_notifications.subscription_notifications ||
      !solutionData.subscription_notifications.subscription_notifications
        .email ||
      solutionData.subscription_notifications.subscription_notifications.email.trim() ===
        ''
    ) {
      this.validatedFields['email'] = {
        isValidated: false,
        target: 'email',
        activeFormId: 'subscription_notifications',
        activeTabId: 'subscription_notifications',
        activeSubFormId: 'subscription_notifications'
      };
    } else {
      this.validatedFields['email'] = {
        isValidated: true,
        target: 'email',
        activeFormId: 'subscription_notifications',
        activeTabId: 'subscription_notifications',
        activeSubFormId: 'subscription_notifications'
      };
    }
  };

  validateAppSubscriptions = solutionData => {
    if (
      !solutionData ||
      !solutionData.app_subscriptions ||
      !solutionData.app_subscriptions.app_subscriptions ||
      !solutionData.app_subscriptions.app_subscriptions.appSubscriptionsType
    ) {
      this.validatedFields['appSubscriptionsType'] = {
        isValidated: false,
        target: 'appSubscriptionsType',
        activeFormId: 'app_subscriptions',
        activeTabId: 'app_subscriptions_tab',
        activeSubFormId: 'app_subscriptions'
      };
    } else {
      this.validatedFields['appSubscriptionsType'] = {
        isValidated: true,
        target: 'appSubscriptionsType',
        activeFormId: 'app_subscriptions',
        activeTabId: 'app_subscriptions_tab',
        activeSubFormId: 'app_subscriptions'
      };
    }
  };

  setInitialState = async () => {
    if (
      this.props.solution &&
      this.props.solution.length &&
      this.props.isRegistered
    ) {
      let solution = this.props.solution[0];

      this.createApiToProvidersMappingWithConsent(solution);

      let solutionData = JSON.parse(
        JSON.stringify(SolutionFormData.dataObject)
      );

      if (solution.solutiondata) {
        solutionData = JSON.parse(JSON.stringify(solution.solutiondata));
      } else {
        solutionData.id = solution.id;
        solutionData.listingVersion = '1';
        solutionData.apigee_id = solution.apigee_id;
        solutionData.developerId = this.state.developerId;
        solutionData.developerName = this.state.developerName;
        solutionData.orgId = this.state.orgId;
        solutionData.lastUpdatedBy = solution.lastModifier;
        solutionData.lastUpdatedOn = solution.lastModified
          ? moment(solution.lastModified, 'YYYY-MM-DD hh:mm A').format(
              this.state.dateFormat
            )
          : moment().toISOString();
        solutionData.registeredOn = solution.registeredOn
          ? moment(solution.registeredOn, 'YYYY-MM-DD hh:mm A').format(
              this.state.dateFormat
            )
          : moment().toISOString();
        solutionData.status = SolutionStatus.DRAFT;

        if (this.props.solutionType === FortellisConstants.PARTNER_PROGRAM) {
          solutionData.solutionType = FortellisConstants.PARTNER_PROGRAM;
        } else {
          solutionData.solutionType = FortellisConstants.FORTELLIS;
        }
        solutionData['overview']['name'] = solution.name;
        solutionData['overview']['briefDescription'] = solution.description;
        solutionData['overview']['website'] = solution.website;
        solutionData['registration_details'].api_details = solution.requires;
        solutionData['registration_details'].apiKey = solution.consumerKey;
        solutionData['registration_details'].apiSecret =
          solution.consumerSecret;
        solutionData['registration_details'].callbackUrl = solution.callbackUrl;
        solutionData['registration_details'].keyIssuedOn = moment(
          parseInt(solution.issuedAt, 10)
        )
          .utc()
          .format(this.state.dateFormat);
        let keyExpiresOn = 'Never';
        let expiresAt = parseInt(solution.expiresAt, 10);
        if (expiresAt !== -1) {
          keyExpiresOn = moment(expiresAt)
            .utc()
            .format(this.state.dateFormat);
        }
        solutionData['registration_details'].keyExpiresOn = keyExpiresOn;

        let plans = solutionData['plans'];
        let free_plan = {
          id: uuid(),
          isTheFreeTrial: true,
          isFreeTrialEnabled: false,
          position: 0,
          planName: 'Free Trial',
          planDescription: '',
          freeTrialPeriod: 0,
          freeTrialPeriodType: 'Days',
          billingType: '',
          billingFrequency: '',
          basePrice: 0,
          includedUnitOfMeasure: null,
          includedUnitQuantity: null,
          additionalUnitPrice: null,
          additionalUnitOfMeasure: null,
          features: [],
          dynamic: false,
          setLabelFrom: 'planName'
        };
        plans.push(free_plan);
        solutionData['plans'] = plans;
      }

      solutionData['activateOnly'] = this.state.activateOnly;
      solutionData['listingVersionUpdates'] = solutionData[
        'listingVersionUpdates'
      ] || { description: '', updates: [] };
      solutionData['provisioning_interface'] =
        solution['provisioning_interface'] ||
        solutionData['provisioning_interface'];
      solutionData['app_subscriptions'] =
        solution['app_subscriptions'] || solutionData['app_subscriptions'];
      this.setState({ solutionName: solutionData.overview.name });

      let formValueFinal = FormUtils.mapFormValuesForView(
        solutionData,
        FormConfig
      );

      let staticData = FormUtils.initializeStaticFormFields(
        this.state.formStaticValues,
        formValueFinal,
        this.state.developerId
      );
      let initialMenu = SideNavUtils.getInitialMenu(
        formValueFinal,
        cloneDeep(this.state.menu)
      );

      if (formValueFinal.activateOnly !== false) {
        initialMenu.contents.forEach((content, index) => {
          if (content.title === 'App Details') {
            initialMenu.contents[index].steps.splice(2, 1);
          }
        });
      }

      let latestComment = 'No Comments';
      if (
        staticData.comments &&
        staticData.comments.comments &&
        staticData.comments.comments.length > 0
      ) {
        latestComment =
          staticData.comments.comments[staticData.comments.comments.length - 1]
            .commentText;
      }
      formValueFinal['provisioning_interface'] = formValueFinal[
        'provisioning_interface'
      ] || { provisioningtype: ProvisioningConstants.type.MANUAL };
      if (
        formValueFinal.solutionType === FortellisConstants.PARTNER_PROGRAM &&
        formValueFinal.registration_details &&
        formValueFinal.registration_details.registration_details &&
        Array.isArray(
          formValueFinal.registration_details.registration_details.api_details
        ) &&
        formValueFinal.registration_details.registration_details.api_details
          .length > 0 &&
        formValueFinal.registration_details.registration_details.api_details[0]
          .id !== environmentURLs.TestAPI
      ) {
        formValueFinal.solutionType = FortellisConstants.FORTELLIS;
      }

      await this.setState({
        latestComment: latestComment,
        menu: initialMenu,
        formValueFinal: formValueFinal,
        formStaticValues: staticData,
        initialize: true
      });

      if (this.props.activeMenuId) {
        let menu = this.state.menu;
        menu.activeMenuId = this.props.activeMenuId;
        if (this.props.nestedActiveMenuId) {
          menu.nestedActiveMenuId = this.props.nestedActiveMenuId;
        } else {
          menu.nestedActiveMenuId = this.props.activeMenuId;
        }
        this.setState({ menu: menu });
      }
      if (this.isPartnerProgramSolution()) {
        this.onSave();
      }
    } else if (this.props.solutionType === 'partnerProgram') {
      let solutionData = JSON.parse(
        JSON.stringify(SolutionFormData.dataObject)
      );
      solutionData.developerId = this.state.developerId;
      solutionData.developerName = this.state.developerName;
      solutionData.orgId = this.state.orgId;
      solutionData.activateOnly = this.state.activateOnly;
      solutionData.registeredOn = moment().format(this.state.dateFormat);
      solutionData.lastUpdatedOn = moment().format(this.state.dateFormat);
      solutionData.status = SolutionStatus.DRAFT;
      solutionData.solutionType = 'partnerProgram';
      let plans = solutionData['plans'];
      let free_plan = {
        id: uuid(),
        isTheFreeTrial: true,
        isFreeTrialEnabled: false,
        position: 0,
        planName: 'Free Trial',
        planDescription: '',
        freeTrialPeriod: 0,
        freeTrialPeriodType: 'Days',
        billingType: '',
        billingFrequency: '',
        basePrice: 0,
        includedUnitOfMeasure: null,
        includedUnitQuantity: null,
        additionalUnitPrice: null,
        additionalUnitOfMeasure: null,
        features: [],
        dynamic: false,
        setLabelFrom: 'planName'
      };
      plans.push(free_plan);
      solutionData['plans'] = plans;
      solutionData['listingVersionUpdates'] = solutionData[
        'listingVersionUpdates'
      ] || { description: '', updates: [] };
      this.setState({ solutionName: solutionData.overview.name });
      let formValueFinal = FormUtils.mapFormValuesForView(
        solutionData,
        FormConfig
      );

      let staticData = FormUtils.initializeStaticFormFields(
        this.state.formStaticValues,
        formValueFinal,
        this.state.developerId
      );
      let initialMenu = SideNavUtils.getInitialMenu(
        formValueFinal,
        cloneDeep(this.state.menu)
      );
      let latestComment = 'No Comments';
      if (
        staticData.comments &&
        staticData.comments.comments &&
        staticData.comments.comments.length > 0
      ) {
        latestComment =
          staticData.comments.comments[staticData.comments.comments.length - 1]
            .commentText;
      }
      this.setState({
        latestComment: latestComment,
        menu: initialMenu,
        formValueFinal: formValueFinal,
        formStaticValues: staticData,
        initialize: true
      });
      if (this.props.activeMenuId && this.props.nestedActiveMenuId) {
        let menu = this.state.menu;
        menu.activeMenuId = this.props.activeMenuId;
        menu.nestedActiveMenuId = this.props.nestedActiveMenuId;
        this.setState({ menu: menu });
      }
    }
  };

  handleFormFragmentSave = formFragmentValue => {
    this.setState({ snackBar: false });
    if (formFragmentValue.dynamic) {
      let menuNew = SideNavUtils.changeNestedMenuLabel(
        cloneDeep(this.state.menu),
        this.state.menu.activeMenuId,
        this.state.menu.nestedActiveMenuId,
        formFragmentValue[formFragmentValue.setLabelFrom]
      );
      this.setState({ menu: menuNew });
    }

    let formValueFinalNew = FormUtils.updateFormFieldValue(
      this.state.menu.activeMenuId,
      this.state.menu.nestedActiveMenuId,
      JSON.parse(JSON.stringify(this.state.formValueFinal)),
      formFragmentValue,
      FormConfig
    );

    this.setState({ formValueFinal: formValueFinalNew });
  };

  handleMenuStateChange = newMenuState => {
    let menuNew = cloneDeep(newMenuState);
    if (menuNew.activeMenuId !== 'comment_history') {
      let nestedActiveMenuId = menuNew.nestedActiveMenuId;
      let activeMenuId = menuNew.activeMenuId;
      let formFields = JSON.parse(
        JSON.stringify(
          FormConfig[activeMenuId].formFields[nestedActiveMenuId]
            ? FormConfig[activeMenuId].formFields[nestedActiveMenuId]
            : {}
        )
      );
      if (formFields.dynamic) {
        //gets triggered whenever you click on any of dynamic item like feature/plan
        menuNew = SideNavUtils.createDynamicMenuItem(
          menuNew,
          nestedActiveMenuId,
          formFields,
          activeMenuId
        );
        nestedActiveMenuId = menuNew.nestedActiveMenuId;
        formFields.id = uuid();
      }

      let formValueFinalNew = FormUtils.initializeFormValueForFirstLoad(
        JSON.parse(JSON.stringify(this.state.formValueFinal)),
        activeMenuId,
        nestedActiveMenuId,
        formFields
      );

      this.setState({ formValueFinal: formValueFinalNew });
    }
    this.setState({ menu: menuNew });
  };

  handleToggleChange = expandedMenuId => {
    this.setState({
      menu: Object.assign({}, this.state.menu, { expandedMenuId })
    });
  };

  handleItemDelete = () => {
    let formValueFinalNew = FormUtils.deleteFormItem(
      JSON.parse(JSON.stringify(this.state.formValueFinal)),
      this.state.menu.activeMenuId,
      this.state.menu.nestedActiveMenuId,
      FormConfig
    );

    let menuNew = SideNavUtils.deleteMenuItem(cloneDeep(this.state.menu));

    this.setState({ menu: menuNew });
    this.setState({ formValueFinal: formValueFinalNew });
  };

  handleItemsArrayReorder = (
    activeMenuId,
    dataFromArray,
    stepIdOne,
    stepIdTwo
  ) => {
    //menu fields update is working via handleMenuStateChange, this is to update formValues
    let formValueFinalNew = FormUtils.reorderFormItems(
      JSON.parse(JSON.stringify(this.state.formValueFinal)),
      activeMenuId,
      dataFromArray,
      stepIdOne,
      stepIdTwo
    );
    this.setState({ formValueFinal: formValueFinalNew });
  };

  getSolutionFields = () => {
    return {
      solutiondata: {
        solutionType: this.state.formValueFinal.solutionType,
        status: this.state.formStaticValues.status || SolutionStatus.DRAFT,
        activateOnly: this.state.activateOnly
      }
    };
  };

  /**
   * generate a random uuid
   * Returns:
   *  random uuid
   */
  Id() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  componentDidMount() {
    this.getRedLineContractStatus();
    this.loadRatePlans();
    const entityName = (this.props.entity && this.props.entity.name) || '';
    if (environmentURLs.env === 'prod') {
      if (entityName.toLowerCase().includes('cdk global')) {
        this.setState({ activateOnly: false });
      }
    } else {
      if (entityName.toLowerCase().includes('cdk')) {
        this.setState({ activateOnly: false });
      }
    }
    const SubscriptionOrgs = process.env.REACT_APP_SUBSCRIPTION_ORGS || '';
    if (SubscriptionOrgs.split('|').indexOf(entityName) >= 0) {
      this.setState({ activateOnly: false });
    }
  }

  componentDidUpdate() {
    if (
      this.state.validationResponse.field &&
      this.state.validationResponse.field.fieldName
    ) {
      this.state.validationResponse.field.fieldName.forEach(fieldName => {
        if (document.getElementById(fieldName)) {
          document.getElementById(fieldName).focus();
          document.getElementById(fieldName).blur();
        }
      });
      this.setState({ validationResponse: {} });
    }

    if (!this.state.initialize) {
      this.setInitialState();
    }
  }

  componentWillMount() {
    let formValueFinalNew = FormUtils.initializeFormValueForFirstLoad(
      JSON.parse(JSON.stringify(this.state.formValueFinal)),
      this.state.menu.activeMenuId,
      this.state.menu.nestedActiveMenuId,
      {}
    );
    this.setState({ formValueFinal: formValueFinalNew });
    if (this.props.solution && this.props.solution.length > 0) {
      this.componentDidUpdate();
    }
  }

  loadRatePlans = _ => {
    const {
      auth: { accessToken },
      entity
    } = this.props;

    const entityId = !!entity ? entity.id : null;

    if (!!entityId && !!accessToken) {
      this.props.fetchAcceptedRatePlans(entityId, accessToken);
    }
  };

  validate = async (
    isValidated,
    target,
    activeFormId,
    activeTabId,
    activeSubFormId
  ) => {
    if (target) {
      this.validatedFields[target] = {
        isValidated: isValidated,
        target: target,
        activeFormId: activeFormId,
        activeTabId: activeTabId,
        activeSubFormId: activeSubFormId
      };
    }
  };

  isAtLeasetOneValidPlanExist = () => {
    let plansObj = this.state.formValueFinal['plans'];
    const plansValues = Object.values(plansObj);
    let plansArray = plansValues.filter(plan => !plan.isTheFreeTrial);

    if (plansArray.length) {
      return true;
    } else {
      let tempPlansArray = plansValues.filter(plan => plan.isTheFreeTrial);
      if (tempPlansArray.length) {
        if (tempPlansArray[0].isFreeTrialEnabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  getIdOfFreeTrailPlan = () => {
    let plansObj = this.state.formValueFinal['plans'];
    if (plansObj) {
      for (let [key, value] of Object.entries(plansObj)) {
        if (value.isTheFreeTrial) {
          return key;
        }
      }
    }
    return null;
  };

  validatePlans = () => {
    const oneValidPlanExist = this.isAtLeasetOneValidPlanExist();
    if (oneValidPlanExist) {
      delete this.validatedFields['isFreeTrialEnabled'];
      return;
    } else {
      const freeTrailPlanId = this.getIdOfFreeTrailPlan();
      if (freeTrailPlanId) {
        this.validatedFields['isFreeTrialEnabled'] = {
          activeFormId: 'plans',
          activeSubFormId: freeTrailPlanId,
          activeTabId: 'plan',
          isValidated: oneValidPlanExist,
          target: 'isFreeTrialEnabled'
        };
      }
      this.setState({
        snackBar: {
          message: () => {
            return (
              <FormattedMessage
                id="SolutionSubmissionForm.planError"
                defaultMessage="Please select at least one plan for app"
              />
            );
          }
        }
      });
    }
  };

  isFormInvalid() {
    this.validateSolutionLogo(this.state.formValueFinal);
    this.validateMandatoryEmails(this.state.formValueFinal);
    this.validateAppSubscriptions(this.state.formValueFinal);
    let validatedFields = this.validatedFields;
    return Object.keys(validatedFields)
      .map(formFieldId => {
        return validatedFields[formFieldId]['isValidated'];
      })
      .filter(isFormFieldValidated => !isFormFieldValidated).length;
  }

  filterInvalidFields(validatedFields) {
    let inValidFields = {};
    Object.keys(validatedFields).forEach(key => {
      if (!validatedFields[key].isValidated) {
        inValidFields[key] = validatedFields[key];
      }
    });
    return inValidFields;
  }

  menuRedirection(targetField) {
    let menu = cloneDeep(this.state.menu);
    menu.activeMenuId = targetField.activeFormId;
    menu.nestedActiveMenuId = targetField.activeSubFormId;
    this.setState({ activeTabId: targetField.activeTabId, menu: menu }, () => {
      let focusElement = document.getElementById(targetField.target);
      focusElement && focusElement.focus();
      focusElement && focusElement.blur();
      focusElement &&
        focusElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      // focusElement && focusElement.scrollBy(0, -100);
    });
  }

  navigateToErrorField() {
    let invalidFields = this.filterInvalidFields(this.validatedFields);
    Object.keys(invalidFields).forEach(item => {
      if (invalidFields[item].activeFormId === 'overview') {
        invalidFields[item].sortKey = 1;
        return invalidFields[item];
      } else if (invalidFields[item].activeFormId === 'support') {
        invalidFields[item].sortKey = 2;
        return invalidFields[item];
      } else if (invalidFields[item].activeFormId === 'plans') {
        invalidFields[item].sortKey = 3;
        return invalidFields[item];
      } else if (invalidFields[item].activeFormId === 'registration_details') {
        invalidFields[item].sortKey = 4;
        return invalidFields[item];
      } else if (
        invalidFields[item].activeFormId === 'subscription_notifications'
      ) {
        invalidFields[item].sortKey = 5;
        return invalidFields[item];
      } else if (invalidFields[item].activeFormId === 'app_subscriptions') {
        invalidFields[item].sortKey = 6;
        return invalidFields[item];
      }
    });
    let sortedInvalidFields = Object.keys(invalidFields).sort(function(a, b) {
      return invalidFields[a].sortKey - invalidFields[b].sortKey;
    });
    sortedInvalidFields.every(item => {
      let targetField = invalidFields[item];
      return this.menuRedirection(targetField);
    });
  }

  isSaveDisabled(formValueFinal) {
    if (formValueFinal.overview && formValueFinal.overview.overview) {
      if (
        formValueFinal.overview.overview.name &&
        formValueFinal.overview.overview.briefDescription &&
        formValueFinal.overview.overview.name !== null &&
        formValueFinal.overview.overview.briefDescription !== null &&
        formValueFinal.overview.overview.name.trim() !== '' &&
        formValueFinal.overview.overview.briefDescription.trim() !== ''
      ) {
        return false;
      }
      return true;
    }
  }

  isSubmitDisabled() {
    if (
      this.props.solution &&
      this.props.solution[0] &&
      this.props.solution[0].solutiondata &&
      this.props.solution[0].solutiondata.solutionType !== 'fortellis'
    ) {
      return false;
    } else if (
      this.state.formValueFinal &&
      this.state.formValueFinal.registration_details &&
      this.state.formValueFinal.registration_details.registration_details &&
      this.state.formValueFinal.registration_details.registration_details
        .isSubmitEnabled
    ) {
      return false;
    } else if (
      this.state.formValueFinal &&
      this.state.formValueFinal.registration_details &&
      this.state.formValueFinal.registration_details.registration_details &&
      !this.state.formValueFinal.registration_details.registration_details
        .isApiConsentModified &&
      this.state.atleastOneProviderExistForEachApiOnLoad
    ) {
      return false;
    } else {
      return true;
    }
  }

  cleanupFormOnTermsDelete() {
    let newState = this.state.formValueFinal;
    if (newState && newState.overview && newState.overview.overview) {
      newState.overview.overview.termsAndConditionsUrl = null;
      this.setState({
        formValueFinal: newState
      });
    }
  }

  render() {
    const { solution, isRegistered, entity } = this.props;

    const { enablePublishToMarketplaceButton } = this;

    let solutionTypeFromSolution = '';
    if (
      solution &&
      solution[0] &&
      solution[0].solutiondata &&
      solution[0].solutiondata.solutionType
    ) {
      solutionTypeFromSolution = solution[0].solutiondata.solutionType;
    }

    if (
      solution &&
      solution[0] &&
      solution[0].solutiondata &&
      solution[0].solutiondata.orgId &&
      solution[0].solutiondata.orgId !== entity.id
    ) {
      return (
        <div className="solution-belongs-to-diff-entity-txt-container">
          <UnauthorizedPage />
        </div>
      );
    }

    if (
      !entity ||
      !entity.id ||
      entity.id === FortellisConstants.DEFAULT_ORG_ID ||
      entity.id === FortellisConstants.PERSONAL_ACCOUNT
    ) {
      return (
        <div>
          <EntityRegistrationConfirmation isPopup={false} />
        </div>
      );
    }
    if (
      solution &&
      !solution.length &&
      !(
        this.props.solutionType === 'partnerProgram' &&
        this.props.isRegistered === false
      )
    ) {
      return (
        <div className="unauthorized-page-container">
          <UnauthorizedPage />
        </div>
      );
    }

    if (this.state.launchDeveloperAgreement) {
      return (
        <DeveloperAgreement
          solution={solution && Array.isArray(solution) && solution[0]}
          onClose={this.onDeveloperAgreementClose.bind(this)}
          entity={entity}
        />
      );
    }

    return (
      <AppContextConsumer>
        {context => (
          <DisplayTermsContext.Provider
            value={{
              showTermsTypes: () => {
                context.refs.breadcrumbRef.current.style.display = 'none';
                this.setState({
                  displayTerms: true,
                  showSummary: false
                });
              },
              hideTermsTypes: () => {
                context.refs.breadcrumbRef.current.style.display = 'block';
                let newTerms = {};
                newTerms.type = '';
                newTerms.file = '';
                newTerms.fileName = '';
                newTerms.lastModified = '';
                this.setState({
                  displayTerms: false,
                  showSummary: false,
                  type: '',
                  file: '',
                  fileName: '',
                  lastModified: '',
                  termsOfUse: newTerms
                });
              },
              onFinish: (
                type,
                file,
                fileName,
                lastModified,
                signatureCoordinates
              ) => {
                let newState = {};
                newState.type = type;
                newState.file = file;
                newState.fileName = fileName;
                newState.lastModified = lastModified;
                newState.signatureCoordinates = signatureCoordinates;
                this.setState({
                  displayTerms: false,
                  showSummary: true,
                  termsOfUse: newState,
                  snackBar: false
                });
              },
              termsSummary: {
                showSummary: this.state.showSummary,
                type: this.state.termsOfUse.type,
                file: this.state.termsOfUse.file,
                fileName: this.state.termsOfUse.fileName,
                lastModified: this.state.termsOfUse.lastModified
              }
            }}
          >
            <Dialog open={this.state.uploadingTerms}>
              <DialogBody className={'file-upload-spinner'}>
                Uploading Terms. Please wait.
              </DialogBody>
              <Spinner />
            </Dialog>

            {this.state.displayTerms ? (
              <div>
                <TermTypes solutionName={this.state.solutionName} />
              </div>
            ) : (
              <div className="component-content">
                {this.state.sentForReview && (
                  <Redirect
                    to={{
                      pathname: SolutionTypeURLMapperUtil.getSolutionListingURL(
                        this.state.formValueFinal.solutionType,
                        this.state.formValueFinal.id,
                        INSIGHTS
                      )
                    }}
                  />
                )}

                {this.state.savedNewSolution && (
                  <Redirect
                    to={`/marketplace-account/mysolutions/list/${
                      this.props.solutionType ===
                      FortellisConstants.PARTNER_PROGRAM
                        ? FortellisConstants.PARTNER_PROGRAM_TYPE_URL
                        : FortellisConstants.FORTELLIS_TYPE_URL
                    }/${this.state.formValueFinal.id}`}
                  />
                )}

                {this.state.openPreviewDialog && (
                  <PreviewSolution
                    {...this.props}
                    openDialog={this.state.openPreviewDialog}
                    onClose={() => this.setState({ openPreviewDialog: false })}
                    userPermissions={this.props.userPermissions}
                    isPreview={true}
                    listingVersion={
                      solution && solution.length
                        ? solution[0].listingVersion
                        : null
                    }
                  />
                )}
                {this.state.showPublishingStepper && (
                  <LoadingStepper
                    open={this.state.showPublishingStepper}
                    title={`${this.props.intl.formatMessage(
                      { id: 'ManageListing.loadingStepper.title' },
                      { defaultMessage: 'Publishing App' }
                    )}
                                `}
                    steps={this.state.publishingSteps}
                    activeStep={this.state.publishingActiveStep}
                    finished={
                      this.state.publishingActiveStep ===
                      this.state.publishingSteps.length
                    }
                    customClassName="subscribe-stepper"
                  />
                )}
                {this.state.saving ||
                !solution ||
                (solution.length === 0 && isRegistered) ? (
                  <LayoutGrid className="max-width-resp page-padding-common">
                    <LayoutGridCell span={12} className="save-loading-spinner">
                      <div>
                        <Spinner />
                        <div style={{ textAlign: 'center' }}>
                          {' '}
                          <strong>Loading app submission form...</strong>
                        </div>
                      </div>
                    </LayoutGridCell>
                  </LayoutGrid>
                ) : (
                  <LayoutGrid className="max-width-resp page-padding-common">
                    <LayoutGridCell span={3} className="fm-side-nav">
                      <SideNav
                        sideNav={this.state.menu}
                        handleToggleChange={this.handleToggleChange}
                        handleSideNavStateChange={this.handleMenuStateChange}
                        handleSideNavArrayReorder={this.handleItemsArrayReorder}
                        solutionFields={this.getSolutionFields()}
                      />
                    </LayoutGridCell>
                    <LayoutGridCell span={9}>
                      <Form name="solution_form">
                        <LayoutGrid className="fm-form-layout-grid">
                          <LayoutGridCell span={12} className="form-box">
                            {/*formConfig contains details of each of the MenuItem in left navigation panel, their tabs, their fields*/}
                            {/*formStaticValues contains details about solution submission other than input fields such as developerId, orgId, solutionName, paymentStatus etc*/}
                            {/*formFieldValues contains values of formConfig form items*/}
                            <FormAPI
                              {...this.props}
                              formConfig={this.getConfig(
                                this.state.formValueFinal.status
                              )}
                              formStaticValues={this.state.formStaticValues}
                              formFieldValues={this.state.formValueFinal}
                              activeFormId={this.state.menu.activeMenuId}
                              activeSubFormId={
                                this.state.menu.nestedActiveMenuId
                              }
                              onSave={this.handleFormFragmentSave}
                              onDelete={this.handleItemDelete}
                              validate={this.validate}
                              handlePreview={this.handlePreview}
                              isSolutionReview={isSolutionReview}
                              onTabChange={this.onTabChange.bind(this)}
                              refetchConsents={this.refetchConsents}
                              errorWhileFetchingAPIData={
                                this.state.errorWhileFetchingAPIData
                              }
                              loadingAPIData={this.state.loadingAPIData}
                              submitClicked={this.state.submitClicked}
                              cleanupFormOnTermsDelete={() =>
                                this.cleanupFormOnTermsDelete()
                              }
                            />
                          </LayoutGridCell>
                          <LayoutGridCell
                            span={12}
                            className="form-action-buttons"
                          >
                            <span className="float-left">
                              <Button
                                compact={'true'}
                                type="button"
                                onClick={this.onLeavePage}
                              >
                                <FormattedMessage
                                  id="SolutionSubmissionForm.button5"
                                  defaultMessage="Cancel"
                                />
                              </Button>

                              <FortellisDialog
                                title={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.confirmationTitle"
                                    defaultMessage="Leave without submitting?"
                                  />
                                }
                                message={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.confirmationMessage"
                                    defaultMessage="Are you sure you want to leave this page?"
                                  />
                                }
                                open={
                                  this.state.openLeavePageConfirmationDialog
                                }
                                acceptButtonName={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.accept"
                                    defaultMessage="LEAVE THIS PAGE"
                                  />
                                }
                                cancelButtonName={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.reject"
                                    defaultMessage="STAY ON THIS PAGE"
                                  />
                                }
                                onAccept={() => {
                                  this.setState({
                                    openLeavePageConfirmationDialog: false
                                  });
                                  let {
                                    props: {
                                      history: { push }
                                    }
                                  } = this;
                                  if (
                                    this.state.formStaticValues.status ===
                                      SolutionStatus.DRAFT &&
                                    this.state.formStaticValues
                                      .listingVersion === '1'
                                  )
                                    push('/marketplace-account/mysolutions');
                                  else
                                    push(
                                      SolutionTypeURLMapperUtil.getSolutionListingURL(
                                        this.state.formValueFinal.solutionType,
                                        this.state.formValueFinal.id,
                                        INSIGHTS
                                      )
                                    );
                                }}
                                onCancel={() => {
                                  this.setState({
                                    openLeavePageConfirmationDialog: false
                                  });
                                }}
                              />
                              <FortellisDialog
                                title={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.submitTitle"
                                    defaultMessage="Submit app listing for review"
                                  />
                                }
                                message={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.submitMessage"
                                    defaultMessage="Are you sure you want to submit your app listing for review?"
                                  />
                                }
                                acceptButtonName={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.submitAccept"
                                    defaultMessage="Submit"
                                  />
                                }
                                cancelButtonName={
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.submitReject"
                                    defaultMessage="Cancel"
                                  />
                                }
                                open={this.state.solutionSubmissionConfirmation}
                                onAccept={() => {
                                  this.setState({
                                    solutionSubmissionConfirmation: false
                                  });
                                  this.submitSolution();
                                }}
                                onCancel={() =>
                                  this.setState({
                                    solutionSubmissionConfirmation: false
                                  })
                                }
                                acceptDataCy={
                                  'btn_solution_submission_dialog_submit_ok'
                                }
                                cancelDataCy={
                                  'btn_solution_submission_dialog_submit_cancel'
                                }
                              />
                            </span>
                            {(!this.state.formStaticValues.status ||
                              this.state.formStaticValues.status ===
                                SolutionStatus.REGISTERED ||
                              this.state.formStaticValues.status ===
                                SolutionStatus.RECALLED ||
                              this.state.formStaticValues.status ===
                                SolutionStatus.REJECTED ||
                              this.state.formStaticValues.status ===
                                SolutionStatus.REVOKED ||
                              this.state.formStaticValues.status ===
                                SolutionStatus.DRAFT) && (
                              <span className="float-right">
                                <Button
                                  id="formSave"
                                  className="submission-action-button-margin"
                                  compact={'true'}
                                  type="button"
                                  disabled={
                                    this.props.solutionType ===
                                    FortellisConstants.PARTNER_PROGRAM
                                      ? this.isSaveDisabled(
                                          this.state.formValueFinal
                                        )
                                      : false
                                  }
                                  onClick={this.onSave.bind(this)}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button2"
                                    defaultMessage="Save"
                                  />
                                </Button>
                                <Button
                                  className="submission-action-button-margin"
                                  type="button"
                                  onClick={this.handlePreview.bind(this)}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button6"
                                    defaultMessage="Preview"
                                  />
                                </Button>

                                <div className="inline-block">
                                  <span id="formSubmitWrapper">
                                    <Button
                                      className="submission-action-button-margin"
                                      compact={'true'}
                                      id="formSubmit"
                                      type="submit"
                                      //disabled={this.isSubmitDisabled()}
                                      onClick={this.onSubmit.bind(this)}
                                      disabled={this.state.isSubmitDisable}
                                      unelevated
                                    >
                                      <FormattedMessage
                                        id="SolutionSubmissionForm.button1"
                                        defaultMessage="Submit for Review"
                                      />
                                    </Button>
                                  </span>

                                  {this.isSubmitDisabled() &&
                                  this.getOneToOneStatus() === false &&
                                  this.props.solutionType !==
                                    FortellisConstants.PARTNER_PROGRAM ? (
                                    <Tooltip
                                      htmlFor="formSubmitWrapper"
                                      className="fortellis-field-info"
                                    >
                                      You must enable API provider on API
                                      Details tab.
                                    </Tooltip>
                                  ) : null}

                                  {!this.isSubmitDisabled() &&
                                  solutionTypeFromSolution !==
                                    SOLUTION_TYPE_CONST.PARTNER_PROGRAM &&
                                  !this.isAtLeasetOneValidPlanExist() &&
                                  !this.getIdOfFreeTrailPlan() ? (
                                    <Tooltip
                                      htmlFor="formSubmitWrapper"
                                      className="fortellis-field-info"
                                    >
                                      You must provide a plan on Pricing Details
                                      tab.
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </span>
                            )}

                            {this.state.formStaticValues.status ===
                            SolutionStatus.APPROVED ? (
                              <span className="float-right">
                                <Button
                                  className="submission-action-button-margin"
                                  type="button"
                                  onClick={this.handlePreview.bind(this)}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button6"
                                    defaultMessage="Preview"
                                  />
                                </Button>
                                <Button
                                  className="submission-action-button-margin"
                                  compact={'true'}
                                  type="button"
                                  primary
                                  onClick={this.onShowForRecall}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button4"
                                    defaultMessage="Recall"
                                  />
                                </Button>
                                <Button
                                  className="fmk-cover__button--highlighted submission-action-button-margin"
                                  unelevated
                                  id="formPublish"
                                  compact={'true'}
                                  type="button"
                                  onClick={this.onShowForPublish}
                                  disabled={!enablePublishToMarketplaceButton}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button3"
                                    defaultMessage="Publish to Marketplace"
                                  />
                                </Button>
                                {this.getPublishSolutionDialog()}
                              </span>
                            ) : this.state.formStaticValues.status ===
                              SolutionStatus.SUBMITTED ? (
                              <span className="float-right">
                                <Button
                                  className="submission-action-button-margin"
                                  type="button"
                                  onClick={this.handlePreview.bind(this)}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button6"
                                    defaultMessage="Preview"
                                  />
                                </Button>
                                <Button
                                  className="submission-action-button-margin"
                                  compact={'true'}
                                  type="button"
                                  primary
                                  onClick={this.onShowForRecall}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button4"
                                    defaultMessage="Recall"
                                  />
                                </Button>
                              </span>
                            ) : (
                              false
                            )}

                            {this.state.formStaticValues.status ===
                              SolutionStatus.PUBLISHED && (
                              <span className="float-right">
                                <Button
                                  className="submission-action-button-margin"
                                  compact={'true'}
                                  type="button"
                                  onClick={this.handlePreview.bind(this)}
                                >
                                  <FormattedMessage
                                    id="SolutionSubmissionForm.button6"
                                    defaultMessage="Preview"
                                  />
                                </Button>
                              </span>
                            )}
                          </LayoutGridCell>
                        </LayoutGrid>

                        <FortellisDialog
                          title={
                            this.state.confirm
                              ? this.state.confirm.title()
                              : false
                          }
                          message={
                            this.state.confirm
                              ? this.state.confirm.message()
                              : false
                          }
                          open={!!this.state.confirm}
                          acceptButtonName={
                            <FormattedMessage
                              id="Confirm.accept"
                              defaultMessage="Proceed"
                            />
                          }
                          cancelButtonName={
                            <FormattedMessage
                              id="Confirm.cancel"
                              defaultMessage="Cancel"
                            />
                          }
                          onAccept={this.onAccept}
                          onCancel={this.onCancel}
                        />

                        <ActionSnackBar
                          data-cy="snkbar_solutionsubmission"
                          show={!!this.state.snackBar}
                          message={
                            this.state.snackBar ? (
                              this.state.snackBar.message()
                            ) : (
                              <FormattedMessage
                                id="SolutionSubmissionForm.snackBarMessage5"
                                defaultMessage="No Action taken"
                              />
                            )
                          }
                          close={() => {
                            this.setState({ snackBar: false });
                            // this.setState({ reload: true });
                          }}
                        />

                        <FortellisDialog
                          title={
                            <FormattedMessage
                              id="SolutionSubmissionForm.recallTitle"
                              defaultMessage="Recall app listing from review process"
                            />
                          }
                          message={
                            <FormattedMessage
                              id="SolutionSubmissionForm.recallMessage"
                              defaultMessage="Are you sure you want to recall the app listing? After recall you can make further edits to your app listing"
                            />
                          }
                          open={this.state.openDialogForRecall}
                          acceptButtonName={
                            <FormattedMessage
                              id="SolutionSubmissionForm.recallAccept"
                              defaultMessage="Recall"
                            />
                          }
                          cancelButtonName={
                            <FormattedMessage
                              id="SolutionSubmissionForm.recallReject"
                              defaultMessage="Cancel"
                            />
                          }
                          onAccept={() => {
                            this.setState({ openDialogForRecall: false });
                            this.onRecall();
                          }}
                          onCancel={() => {
                            this.setState({ openDialogForRecall: false });
                          }}
                          acceptDataCy={
                            'btn_solution_submission_dialog_recall_ok'
                          }
                          cancelDataCy={
                            'btn_solution_submission_dialog_recall_cancel'
                          }
                        />
                      </Form>
                    </LayoutGridCell>
                  </LayoutGrid>
                )}
              </div>
            )}
          </DisplayTermsContext.Provider>
        )}
      </AppContextConsumer>
    );
  }
}

const getProviders = gql`
  query($apiIds: [ID]) {
    providers(provides: $apiIds) {
      id
      name
      entityId
      productName
      api
      fqdn
      adminAPIUrl
      supportEmail
      termsDoc
      addendumDoc
      terms {
        coordinates {
          signatureCoordinates {
            page
            x
            y
          }
        }
        docSize {
          height
          width
        }
      }
    }
  }
`;

const getNoOfSubscription = gql`
  query($solutionId: ID) {
    solutionSubscriptions(solutionId: $solutionId) {
      id
      solutionId
      status
      dateOfPurchase
      subscriptionStartDate
      subscriptionEndDate
      plan {
        id
        planName
      }
    }
  }
`;

const getBase64DeveloperAgreement = gql`
  query($id: String) {
    developerAgreement(id: $id) {
      document
      signatureCoordinates
      userDetailsCoordinates
    }
  }
`;

const getAsyncApiData = gql`
  query($id: ID) {
    asyncApiDetails(id: $id) {
      id
      orgId
      name
      status
      website
      created
      apigeeAppId
      displayName
      description
      oneToOneUI
      apiProducts {
        name
        displayName
      }
      integrations {
        id
        name
        displayName
        description
        apiType
        providers {
          id
          apiProviderName
          entityId
          pricingPlan {
            name
            type
            visibility
            id
            status
          }
        }
      }
      asyncApiIntegrations {
        id
        asyncApi {
          id
          name
        }
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  fetchAcceptedRatePlans: (entityId, accessToken) =>
    dispatch(fetchAcceptedRatePlans({ entityId, accessToken }))
});

export default withApollo(
  injectIntl(
    connect(
      null,
      mapDispatchToProps
    )(SolutionSubmissionForm)
  )
);
