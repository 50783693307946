import React from 'react';
import { IconInfo, StatusIndicator, THEMES, withTooltip } from 'cdk-radial';

function RawPrivacyProtectionIndicator(props) {
  return (
    <StatusIndicator
      label="Privacy Protection"
      ariaLabel="Privacy Protection"
      variant="warning"
      {...props}
    />
  );
}

export const PrivacyProtectionOnApiIndicator = withTooltip(
  RawPrivacyProtectionIndicator,
  {
    id: 'api-privacy-protection-info-tooltip',
    text:
      'This API contains Consumer Privacy Protection functionality that will be surfaced during the subscription process where the dealer can opt in or out.',
    theme: THEMES.LIGHT
  }
);

export const PrivacyProtectionOnAppIndicator = withTooltip(IconInfo, {
  id: 'app-privacy-protection-info-tooltip',
  text:
    'This app has an API that contains Consumer Privacy Protection functionality that will be surfaced during the subscription process where the dealer can opt in or out.',
  theme: THEMES.LIGHT
});

export const PrivacyProtectionInfoIndicator = withTooltip(IconInfo, {
  id: 'app-privacy-protection-info-tooltip',
  text:
    "If CPP Flag is set to 'True', access to API data for App Publishers is restricted. If it is set to 'False', App Publishers can access all the API data",
  theme: THEMES.LIGHT
});
