import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';

export const AdditionalInfoConstants = {
  VISION_CASE_QUEUE_DEFAULT_VALUE: environmentURLs.cdkVisionQueueName,
  UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE:
    environmentURLs.cdkUninstallVisionQueueName,
  MaxLengthOfCatalogNumber: 8
};

export default AdditionalInfoConstants;
