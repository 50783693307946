import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '@cdk-uip/react-dialog';

class PDFViewer extends React.Component {
  state = {
    open: false
  };

  onShow = () => {
    this.setState({
      open: true
    });
  };

  onClose = () => {
    this.setState({
      open: false
    });
  };

  getFileNameFromS3Url = () => {
    const s3Url = this.props.formFieldValues[this.props.fieldProperties.name];
    return s3Url ? s3Url.split('/')[s3Url.split('/').length - 1] : '';
  };

  render() {
    const s3Url = this.props.formFieldValues[this.props.fieldProperties.name];
    const fileName = s3Url ? s3Url.split('/')[s3Url.split('/').length - 1] : '';

    if (!s3Url) {
      return null;
    }

    return (
      <div>
        <label>{fileName}</label>
        <Button
          className="button-right"
          compact={'true'}
          type="button"
          onClick={this.onShow}
          outlined={true}
        >
          <FormattedMessage
            id="PDFView.viewButton"
            defaultMessage="View Document"
          />
        </Button>
        <Dialog open={this.state.open} onCancel={this.onClose}>
          <embed title={fileName} width="100%" height="800px" src={s3Url} />
        </Dialog>
      </div>
    );
  }
}

export default PDFViewer;
