import React, { Component } from 'react';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage } from 'react-intl';
import { Radio } from '@cdk-uip/react-radio';
import { FileInput } from '@fortellis/file-input';
import { DisplayContextConsumer } from '../../SolutionSubmissionForm';
import CaptureCoordinates from './CaptureCoordinates';
import Preview from './Preview';
import FullScreenOverlay from '../../../common/FullScreenOverlay';
const FILE_TYPE = 'application/pdf';

class TermTypes extends Component {
  state = {
    oneClick: true,
    eSignature: false,
    fileName: '',
    file: '',
    fileUploadError: '',
    isFileValid: true,
    docUploaded: false,
    displaySpinner: false,
    type: 'One-Click Acceptance',
    createForm: false,
    loadPreview: false,
    previewUrl: '',
    buttonText: 'SAVE AND FINISH'
  };

  handleRadioChange = (event, selectedConsentType) => {
    if (selectedConsentType === 'oneClick') {
      this.setState({
        type: 'One-Click Acceptance',
        oneClick: true,
        eSignature: false,
        buttonText: 'SAVE AND FINISH'
      });
    } else {
      this.setState({
        type: 'Electronic Signature',
        oneClick: false,
        eSignature: true,
        buttonText: 'PREPARE TERMS'
      });
    }
    this.setState({
      file: '',
      fileName: '',
      docUploaded: false
    });
  };

  handleFileSelect(file) {
    if (!file) {
      this.setState({
        fileUploadError: 'an unknown error occurred'
      });
      return;
    } else if (file.type === FILE_TYPE) {
      if (file.name.includes('.pdf')) {
        const { name: fileName } = file;
        this.setState(
          {
            fileName: fileName,
            file: file,
            docUploaded: true
          },
          () => {
            this.setState({
              showConsentType: true
            });
          }
        );
      }
    } else {
      this.setState({
        isFileValid: false
      });
      return;
    }
    //check for form fields
    if (this.state.eSignature) {
      this.setState({
        displaySpinner: false
      });
    }
  }

  handleFileReset() {
    this.setState({
      fileName: '',
      file: '',
      docUploaded: false
    });
  }

  onCancel() {
    this.setState({
      showConsentType: false
    });
  }

  onPreview() {
    this.setState({
      createForm: true
    });
  }

  onCreateFormClose(previewUrl, signatureCoordinates) {
    this.setState({
      createForm: false,
      signatureCoordinates: signatureCoordinates
    });

    if (previewUrl) {
      this.setState({
        loadPreview: true,
        previewUrl: previewUrl
      });
    }
  }

  onLoadPreviewClose() {
    this.setState({
      loadPreview: false
    });
  }

  render() {
    let disableSaveButton = this.state.fileName ? false : true;

    return (
      <DisplayContextConsumer>
        {context => (
          <>
            {this.state.createForm && (
              <FullScreenOverlay
                open={this.state.createForm}
                title={'Prepare Terms Of Use'}
                onClose={this.onCreateFormClose.bind(this)}
              >
                <CaptureCoordinates file={this.state.file} />
              </FullScreenOverlay>
            )}
            {this.state.loadPreview && (
              <FullScreenOverlay
                open={this.state.loadPreview}
                title={'Preview Terms Of Use'}
                backButtonStyle={'terms-of-use-icon-white'}
                onClose={this.onLoadPreviewClose.bind(this)}
              >
                <Preview
                  previewUrl={this.state.previewUrl}
                  onLoadPreviewFinish={status => {
                    if (status === 'Accepted') {
                      return context.onFinish(
                        this.state.type,
                        this.state.file,
                        this.state.fileName,
                        this.state.lastModified,
                        this.state.signatureCoordinates
                      );
                    } else {
                      return context.hideTermsTypes();
                    }
                  }}
                />
              </FullScreenOverlay>
            )}
            {!this.state.createForm && !this.state.loadPreview && (
              <div>
                <div className={'terms-of-use-navigate-back-container'}>
                  <div
                    className={
                      'terms-of-use-navigate-back-div terms-of-use-header-text'
                    }
                  >
                    <Button
                      className="float-left terms-of-use-back-button"
                      onClick={() => context.hideTermsTypes()}
                    >
                      <i className="material-icons terms-of-use-icon">
                        arrow_back
                      </i>
                    </Button>
                    <div className={'terms-of-use-navigate-back-text'}>
                      Back to {this.props.solutionName}
                    </div>
                  </div>
                </div>
                <div className={'terms-of-use-container'}>
                  <div>
                    <h1
                      className={'terms-of-use-font'}
                      style={{ fontSize: '2.5vh' }}
                    >
                      Terms Of Use
                    </h1>
                    <div className={'terms-of-use-subtitle'}>
                      Subscribers must accept a terms of use agreement before
                      subscribing to your app.
                    </div>
                    <div className={'terms-of-use-consent-type'}>
                      <div className={'terms-of-use-font'}>
                        <div style={{ fontSize: '2vh' }}>Consent Type</div>
                        <div style={{ marginTop: '20px' }}>
                          <div>
                            <Radio
                              className="radio-item-radio"
                              checked={this.state.oneClick}
                              disabled={false}
                              required={false}
                              id={'oneClick'}
                              onChange={event => {
                                this.handleRadioChange(event, 'oneClick');
                              }}
                            />
                            <label
                              htmlFor={'oneClick'}
                              style={{ cursor: 'pointer' }}
                            >
                              One-Click Acceptance
                            </label>
                            <div className={'terms-of-use-types-description'}>
                              Subscribers view and accept the terms; no
                              signature required.
                            </div>
                          </div>
                        </div>
                        {/* <div style={{ marginTop: '20px' }}>
                          <div>
                            <Radio
                              className="radio-item-radio"
                              checked={this.state.eSignature}
                              disabled={false}
                              required={false}
                              id={'eSignature'}
                              onChange={event => {
                                this.handleRadioChange(event, 'eSignature');
                              }}
                            />
                            <label
                              htmlFor={'eSignature'}
                              style={{ cursor: 'pointer' }}
                            >
                              Electronic Signature
                            </label>
                            <div className={'terms-of-use-types-description'}>
                              Subscribers view and sign the terms you prepared
                              for eSignature.
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div
                        className={
                          'terms-of-use-consent-type terms-of-use-font'
                        }
                      >
                        Upload Terms
                        <div className={'terms-of-use-file-selector'}>
                          <FileInput
                            label="App Terms PDF &#x2A;"
                            id="solutionTerms"
                            accept={FILE_TYPE}
                            value={this.state.fileName}
                            onSelect={file => this.handleFileSelect(file)}
                            onReset={() => this.handleFileReset()}
                            success={this.state.docUploaded}
                            isValid={this.state.isFileValid}
                          />
                        </div>
                        <div className={'terms-of-use-cancel-save'}>
                          <Button
                            className="submission-action-button-margin"
                            compact={'true'}
                            type="submit"
                            onClick={() => {
                              this.state.buttonText === 'SAVE AND FINISH'
                                ? context.onFinish(
                                    this.state.type,
                                    this.state.file,
                                    this.state.fileName,
                                    this.state.lastModified
                                  )
                                : this.onPreview();
                            }}
                            unelevated
                            disabled={disableSaveButton}
                          >
                            <FormattedMessage
                              id={'TermsOfUse.SaveAndFinish'}
                              defaultMessage={this.state.buttonText}
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </DisplayContextConsumer>
    );
  }
}

export default TermTypes;
