import React from 'react';
import { TextField } from '@cdk-uip/react-text-field';
import { ChipSet, Chip, ChipText, ChipDeleteIcon } from '@cdk-uip/react-chips';

const unallowedDomains = ['gmail.com', 'hotmail.com', 'yahoo.com'];
const EMAIL_LIMIT = 5;

export class InputChipset extends React.Component {
  state = {
    inputText: '',
    chipsArray: this.props.value
      ? typeof this.props.value === 'string'
        ? this.props.value.split(',')
        : this.props.value
      : [],
    emailError: null
  };

  updateChipsArrayInFormFieldValues = () => {
    //update the variable in config (passed as name attribute in fieldProperties)
    const {
      formFieldValues,
      fieldProperties: { name }
    } = this.props;
    if (name === 'email') {
      formFieldValues[name] = this.state.chipsArray.join(',');
    } else if (name === 'privateSolutionUsers') {
      formFieldValues[name] = [...this.state.chipsArray];
    }
    this.props.onChange(formFieldValues);
  };

  validateEmail = value => {
    let regex = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/;
    return regex.test(value);
  };

  validateDomainOfEmail = email => {
    for (let domain of unallowedDomains) {
      let regex = new RegExp('^[A-Za-z0-9._%+-]+@' + domain + '$');
      if (regex.test(email)) {
        //this indicates that the email contain one of the unallowed domain..
        //so returning false in this case
        return false;
      }
    }
    //not matched with any of the unallowed domains then return true.
    return true;
  };

  onEnterForPrivateUser = async () => {
    // Add to local array
    let inputVal = this.state.inputText;
    if (!this.state.chipsArray.includes(this.state.inputText)) {
      if (this.validateEmail(inputVal)) {
        await this.setState(state => ({
          chipsArray: [...state.chipsArray, state.inputText],
          inputText: ''
        }));
      } else {
        this.setState({ emailError: 'Please enter a valid email' });
      }
    } else {
      this.setState({ inputText: '', emailError: null });
    }
    this.updateChipsArrayInFormFieldValues();
  };

  onEnterForSubscriptionNotification = async () => {
    let inputVal = this.state.inputText;
    if (!this.state.chipsArray.includes(inputVal)) {
      if (this.state.chipsArray.length < EMAIL_LIMIT) {
        if (this.validateEmail(inputVal)) {
          if (this.validateDomainOfEmail(inputVal)) {
            await this.setState(state => ({
              chipsArray: [...state.chipsArray, state.inputText],
              inputText: ''
            }));
          } else {
            this.setState({
              emailError: 'Emails from this domain are not supported'
            });
          }
        } else {
          this.setState({ emailError: 'Please enter a valid email' });
        }
      } else {
        this.setState({
          emailError: `You can only add upto ${EMAIL_LIMIT} email addresses`
        });
      }
    } else {
      this.setState({ emailError: 'Email already exists' });
    }
    this.updateChipsArrayInFormFieldValues();
  };

  onEnterKeyPress = () => {
    const {
      fieldProperties: { name }
    } = this.props;
    if (name === 'email') this.onEnterForSubscriptionNotification();
    else if (name === 'privateSolutionUsers') this.onEnterForPrivateUser();
  };

  onChipDelete = async index => {
    await this.setState(state => ({
      chipsArray: state.chipsArray.filter((chip, i) => i !== index)
    }));
    this.updateChipsArrayInFormFieldValues();
  };

  handleInputChange = event => {
    this.setState({
      inputText: event.target.value.toLowerCase(),
      emailError: null
    });
  };

  render() {
    const { fieldProperties, disabledOn } = this.props;
    const { solutionVisibilityType } = this.props.formFieldValues;
    const PRIVATE = 'Private';
    const randomNumber = Math.random();
    return (
      (solutionVisibilityType === PRIVATE ||
        fieldProperties.name === 'email') && (
        <div
          className={`form-field-with-tooltip ${
            fieldProperties.containerStyle
          }`}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              this.onEnterKeyPress();
              e.stopPropagation();
            }}
          >
            <div className="input-chipset-container">
              <TextField
                className={fieldProperties.style}
                name={fieldProperties.name}
                id={fieldProperties.name || 'inputChipset_' + randomNumber}
                label={
                  <span className="form-field-label">
                    {fieldProperties.label}
                  </span>
                }
                value={this.state.inputText}
                onChange={this.handleInputChange}
                disabled={
                  typeof fieldProperties.disabled === 'function'
                    ? fieldProperties.disabled(disabledOn)
                    : fieldProperties.disabled
                }
                required={fieldProperties.required}
                maxLength={100}
              />
              {this.state.emailError ? (
                <p
                  className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                  style={{ marginLeft: '16px' }}
                >
                  {this.state.emailError}
                </p>
              ) : null}

              {this.state.chipsArray.length ? (
                <ChipSet className="form-input-chipset">
                  {this.state.chipsArray.map((chipText, index) => (
                    <Chip key={index}>
                      <ChipText>{chipText}</ChipText>
                      {/*<ChipDeleteIcon*/}
                      <span
                        className="material-icons"
                        style={{ marginLeft: '5px', color: 'gray' }}
                        onClick={() => {
                          if (!fieldProperties.disabled) {
                            this.onChipDelete(index);
                          }
                        }}
                      >
                        cancel
                      </span>
                    </Chip>
                  ))}
                </ChipSet>
              ) : (
                false
              )}
            </div>
          </form>
        </div>
      )
    );
  }
}

export default InputChipset;
