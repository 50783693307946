export const config = {
  formId: 'plans',
  formTitle: 'Plans',
  formValueType: 'Array',
  formNote:
    'These plans can be created for Marketplace subscription purposes only. Fortellis is currently not providing payment and billing services to the App Provider. App Providers need to take care of billing and payment collection outside of Fortellis. For Fortellis apps, at least one pricing plan is mandatory.',
  noFormFieldNote:
    'No Plans available. Please use add functionality to add new items.',
  tabs: [
    {
      id: 'plan',
      label: 'Plan',
      contents: [
        {
          id: 'plan',
          fields: [
            {
              name: 'isFreeTrialEnabled',
              labelDependsOn: 'isFreeTrialEnabled',
              label: function(isFreeTrialEnabled) {
                if (isFreeTrialEnabled) {
                  return 'Free Trial Plan Enabled';
                } else {
                  return 'Free Trial Plan Disabled';
                }
              },
              dependentOn: 'isTheFreeTrial',
              show: function(dependOn) {
                return dependOn;
              },
              type: 'on_off_switch',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              isDisabled: true
            },
            {
              name: 'planName',
              label: 'Plan Name',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'planDescription',
              label: 'Plan Description',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              multiple: true,
              dependentOn: 'isTheFreeTrial',
              show: function(dependOn) {
                return !dependOn;
              },
              multipleFieldGridStyle: 'multiple-field-form-div',
              multipleFields: [
                {
                  hasDependent: true,
                  dependentContent: {
                    show: function(dependOn) {
                      return true;
                    },
                    fields: [
                      {
                        multiple: true,
                        dependentOn: 'billingType',
                        multipleFields: [
                          {
                            name: 'billingType',
                            label: 'Billing Type',
                            type: 'static',
                            resetDependent: function(value) {
                              let resetValue = {};
                              if (
                                value === 'ONE TIME' ||
                                value === 'TRANSACTIONAL' ||
                                value === 'FREE'
                              ) {
                                resetValue['billingFrequency'] = null;
                                if (value === 'FREE') {
                                  resetValue['basePrice'] = 0;
                                }
                              } else {
                                resetValue['billingFrequency'] = 'MONTH';
                              }
                              return resetValue;
                            },
                            options: [
                              {
                                name: 'One Time',
                                value: 'ONE TIME'
                              },
                              {
                                name: 'Recurring',
                                value: 'RECURRING'
                              },
                              {
                                name: 'Transactional',
                                value: 'TRANSACTIONAL'
                              },
                              {
                                name: 'Free',
                                value: 'FREE'
                              }
                            ],
                            fieldStyle: 'dependent-field-div',
                            postText: '  ',
                            style: 'static-value-with-pre-post-text',
                            postTextStyle: 'static-field-post-text',
                            gridStyle: 'text-field-with-pre-post-text'
                          },
                          {
                            name: 'billingFrequency',
                            label: 'Billing Frequency',
                            type: 'static',
                            dependentOn: 'billingType',
                            show: function(dependOn) {
                              return dependOn === 'RECURRING';
                            },
                            options: [
                              {
                                name: 'Weekly',
                                value: 'WEEK'
                              },
                              {
                                name: 'Monthly',
                                value: 'MONTH'
                              },
                              {
                                name: 'Quarterly',
                                value: 'QUARTER'
                              },
                              {
                                name: 'Yearly',
                                value: 'YEAR'
                              }
                            ],
                            fieldStyle: 'dependent-field-div',
                            style: 'static-value-with-pre-post-text',
                            postTextStyle: 'static-field-post-text',
                            gridStyle: 'text-field-with-pre-post-text',
                            preText: '  ',
                            preTextStyle: 'static-field-pre-text'
                          }
                        ]
                      },
                      {
                        multiple: true,
                        fieldStyle: 'dependent-field-div',
                        dependentOn: 'billingType',
                        show: dependOn =>
                          dependOn !== 'TRANSACTIONAL' && dependOn !== 'FREE',
                        multipleFields: [
                          {
                            name: 'basePrice',
                            label: 'Base Price',
                            fieldStyle: 'dependent-field-div',
                            preText: '',
                            style: 'static-value-with-pre-post-text',
                            postTextStyle: 'static-field-post-text',
                            postText: function(dependOn) {
                              if (dependOn === 'RECURRING') {
                                return 'per {billingFrequency}';
                              } else {
                                return '';
                              }
                            },
                            type: 'static',
                            fieldType: 'number',
                            gridStyle: 'text-field-with-pre-post-text',
                            dependentOn: 'billingType'
                          },
                          {
                            name: 'includedUnitQuantity',
                            label: 'Quantity',
                            type: 'static',
                            preText: '  includes  ',
                            preTextStyle: 'static-field-pre-text',
                            postText: '  ',
                            style: 'static-value-with-pre-post-text',
                            postTextStyle: 'static-field-post-text',
                            gridStyle: 'text-field-with-pre-post-text'
                          },
                          {
                            name: 'includedUnitOfMeasure',
                            label: 'Unit of Measure',
                            type: 'static',
                            style: 'static-value-with-pre-post-text',
                            postTextStyle: 'static-field-post-text',
                            fieldStyle: 'dependent-field-div',
                            gridStyle: 'text-field-with-pre-post-text'
                          },
                          {
                            multiple: true,
                            title: 'Additional Charges',
                            titleStyle: 'pricing-dependent-filed-title',
                            fieldStyle: 'dependent-field-div',
                            dependentOn: 'billingType',
                            multipleFields: [
                              {
                                name: 'additionalUnitPrice',
                                label: 'Price',
                                type: 'static',
                                postText: 'per ',
                                style: 'static-value-with-pre-post-text',
                                postTextStyle: 'static-field-post-text',
                                gridStyle: 'text-field-with-pre-post-text'
                              },
                              {
                                name: 'additionalUnitOfMeasure',
                                label: 'Unit of Measure',
                                type: 'static',
                                style: 'static-value-with-pre-post-text',
                                postTextStyle: 'static-field-post-text',
                                postText: function(dependOn) {
                                  if (dependOn === 'RECURRING') {
                                    return 'per {billingFrequency}';
                                  } else {
                                    return '';
                                  }
                                },
                                gridStyle: 'text-field-with-pre-post-text'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        multiple: true,
                        fieldStyle: 'dependent-field-div',
                        dependentOn: 'billingType',
                        show: dependOn => dependOn === 'TRANSACTIONAL',
                        multipleFields: [
                          {
                            multiple: true,
                            fieldStyle: 'dependent-field-div',
                            dependentOn: 'billingType',
                            multipleFields: [
                              {
                                name: 'includedTransactionalFee',
                                label: 'Transactional Fee',
                                type: 'static',
                                style: 'static-value-with-pre-post-text',
                                fieldType: 'number',
                                min: 0,
                                helperText: 'Value should not be negative',
                                gridStyle: 'text-field-with-pre-post-text'
                              },
                              {
                                name: 'transactionalTypeOptions',
                                label: 'Type',
                                type: 'static',
                                fieldStyle: 'dependent-field-div',
                                style: 'static-value-with-pre-post-text',
                                gridStyle: 'text-field-with-pre-post-text',
                                options: [
                                  {
                                    name: '%',
                                    value: '%'
                                  },
                                  {
                                    name: '$',
                                    value: '$'
                                  }
                                ],
                                preText: ' ',
                                preTextStyle: 'static-field-pre-text',
                                postText: ' per ',
                                postTextStyle: 'static-field-post-text'
                              },
                              {
                                name: 'includedTransactionalType',
                                label: 'Transactional Type',
                                type: 'static',
                                style: 'static-value-with-pre-post-text',
                                gridStyle: 'text-field-with-pre-post-text'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            },
            {
              multiple: true,
              dependentOn: 'isTheFreeTrial',
              show: function(dependOn) {
                return dependOn;
              },
              multipleFieldGridStyle: 'multiple-field-form-div',
              multipleFields: [
                {
                  name: 'freeTrialPeriod',
                  label: 'Free Trial Period',
                  type: 'static',
                  postText: function(dependOn) {
                    return '{freeTrialPeriodType}   ';
                  },
                  dependentOn: 'freeTrialPeriodType',
                  style: 'static-value-with-pre-post-text',
                  postTextStyle: 'static-field-post-text',
                  gridStyle: 'text-field-with-pre-post-text'
                },
                {
                  name: 'includedUnitQuantity',
                  label: 'Quantity',
                  type: 'static',
                  preText: '  includes  ',
                  preTextStyle: 'static-field-pre-text',
                  postText: '  ',
                  style: 'static-value-with-pre-post-text',
                  postTextStyle: 'static-field-post-text',
                  gridStyle: 'text-field-with-pre-post-text'
                },
                {
                  name: 'includedUnitOfMeasure',
                  label: 'Unit of Measure',
                  type: 'static',
                  style: 'static-value-with-pre-post-text',
                  postTextStyle: 'static-field-post-text',
                  fieldStyle: 'dependent-field-div',
                  gridStyle: 'text-field-with-pre-post-text'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 'features',
      label: 'Features',
      contents: [
        {
          id: 'features',
          fields: [
            {
              name: 'features',
              note: 'Features included in this plan.',
              type: 'check_box_group',
              style: 'formtextfield',
              gridStyle: 'layoutCellList',
              valueType: 'array',
              options: 'featureArray',
              isDisabled: true
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    create_plan: {
      id: null,
      isTheFreeTrial: false,
      isFreeTrialEnabled: false,
      position: 1,
      planName: '',
      planDescription: '',
      freeTrialPeriod: 0,
      freeTrialPeriodType: '',
      billingType: '',
      transactionalTypeOptions: '$',
      billingFrequency: '',
      basePrice: null,
      includedUnitOfMeasure: null,
      includedUnitQuantity: null,
      includedTransactionalType: '',
      includedTransactionalFee: null,
      additionalUnitPrice: null,
      additionalUnitOfMeasure: null,
      features: [],
      dynamic: true,
      setLabelFrom: 'planName'
    }
  },
  formValues: {
    featureArray: []
  },
  activeTabId: 'plan'
};
