import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  sendMarketplaceBetaAccessEmail,
  saveMarketplaceBetaAccessRequest
} from '../../containers/BetaAccessContainer';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import FortellisDialog from './FortellisDialog';

class RequestBetaAccess extends React.Component {
  state = {
    updating: false
  };

  onRequestBetaAccess = userData => {
    saveMarketplaceBetaAccessRequest(userData).then(response => {
      if (!response.errors) {
        if (
          response &&
          response.data &&
          response.data.createBetaAccessRequest &&
          !response.data.createBetaAccessRequest.requestExists
        ) {
          sendMarketplaceBetaAccessEmail(userData);
        }
        this.setState({ updating: true });
      }
    });
  };

  render() {
    return (
      <div>
        <div
          className="max-width-resp"
          style={{ paddingTop: '48px', textAlign: 'center' }}
        >
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="RequestAccess.message1"
              defaultMessage="You currently don’t have access to Marketplace Beta features."
            />
          </div>
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="RequestAccess.message2"
              defaultMessage="You can request access to Marketplace Beta from here."
            />
          </div>
          <div className="unauthorized-page-content-text">
            <FormattedMessage
              id="RequestAccess.message3"
              defaultMessage="Fortellis team will contact you in 2-3 business days."
            />
          </div>
          <Button
            primary
            style={{ marginTop: '20px' }}
            onClick={() => {
              this.onRequestBetaAccess(this.props.auth.userData);
            }}
          >
            <FormattedMessage
              id="RequestAccess.button"
              defaultMessage="Request Beta access"
            />
          </Button>
        </div>
        <div>
          <FortellisDialog
            title="Thank you for your interest"
            message="You request has been received and Fortellis team will contact you."
            open={this.state.updating}
            onAccept={() => this.setState({ updating: false })}
            onCancel={() => this.setState({ updating: false })}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(withAuth(RequestBetaAccess));
