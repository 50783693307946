import React from 'react';
import { FileInput } from '@fortellis/file-input';
import UploadDocUtils from '../utils/UploadDocUtils';
import { Dialog, DialogBody } from '@cdk-uip/react-dialog';
import { TextFieldHelperText } from '@cdk-uip/react-text-field';
import Spinner from '../../../common/Spinner';
import { FormattedMessage } from 'react-intl';
import FortellisDialog from '../../../common/FortellisDialog';

const UPLOAD_ACTION_MSG = 'Uploading document. Please wait.';
const ACTION_ACTION_MSG = 'Deleting document. Please wait';
const FILE_TYPE = 'application/pdf';

class FormS3FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docUploaded: false,
      fileName: '',
      open: false,
      processingMsg: '',
      onError: false
    };
    this.uploadDocUtils = new UploadDocUtils();

    this.handleFileSelect = file => {
      this.setState({
        open: true,
        processingMsg: UPLOAD_ACTION_MSG
      });
      const fieldName = this.props.fieldProperties.name;
      const formFieldValues = JSON.parse(
        JSON.stringify(this.props.formFieldValues)
      );
      const { name: fileName } = file;
      const {
        formStaticValues: { id, orgId, listingVersion }
      } = this.props;
      let finalListingVersion = listingVersion ? listingVersion : 1;
      let filePath = `tnc/${orgId}/${id}/${finalListingVersion}/${fileName}`;
      this.uploadDocUtils
        .uploadDoc(file, filePath)
        .then(docUrl => {
          formFieldValues[fieldName] = docUrl;
          this.props.onChange(formFieldValues);
          this.setState({
            docUploaded: true,
            fileName: fileName,
            open: false,
            processingMsg: ''
          });
        })
        .catch(err => {
          this.setState({ onError: true });
        });
    };

    this.handleFileReset = () => {
      this.setState({
        open: true,
        processingMsg: ACTION_ACTION_MSG
      });
      const fieldName = this.props.fieldProperties.name;
      const formFieldValues = JSON.parse(
        JSON.stringify(this.props.formFieldValues)
      );
      const {
        formStaticValues: { orgId, listingVersion }
      } = this.props;
      let filePath = `tnc/${orgId}/${
        this.props.formStaticValues.id
      }/${listingVersion}/${this.state.fileName}`;
      this.uploadDocUtils
        .deleteDoc(filePath, FILE_TYPE)
        .then(() => {
          formFieldValues[fieldName] = null;
          this.props.onChange(formFieldValues);
          this.setState({
            docUploaded: false,
            fileName: '',
            open: false
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({
            docUploaded: false,
            fileName: '',
            open: false,
            processingMsg: ''
          });
        });
      this.validate();
    };

    this.getFileNameFromS3Url = () => {
      const s3Url = this.props.formFieldValues[this.props.fieldProperties.name];
      return s3Url ? s3Url.split('/')[s3Url.split('/').length - 1] : '';
    };

    this.dismiss = () => {
      this.setState({
        onError: false,
        docUploaded: false,
        fileName: '',
        open: false,
        processingMsg: ''
      });
    };
  }

  componentDidMount() {
    const fileName = this.getFileNameFromS3Url();
    this.setState({ docUploaded: !!fileName });
    this.validate(fileName);
    this.setState({ fileName });
  }

  validate = filename => {
    if (this.props.validate) {
      this.props.validate(
        filename ? filename : !!this.state.fileName,
        this.props.fieldProperties.name,
        this.props.activeFormId,
        this.props.activeTabId,
        this.props.activeSubFormId
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fileName !== this.state.fileName) {
      this.validate();
    }
  }

  render() {
    const { fieldProperties } = this.props;

    return (
      <div>
        <FileInput
          label="Term And Conditions"
          id="termsAndConditions"
          accept={FILE_TYPE}
          value={this.state.fileName}
          disabled
          required={this.props.fieldProperties.required}
          onSelect={file => this.handleFileSelect(file)}
          onReset={this.handleFileReset}
          success={this.state.docUploaded}
          helperText={
            <TextFieldHelperText
              persistent
              isValid={!!this.state.fileName}
              isValidationMessage
              validation
            >
              {!this.state.fileName
                ? fieldProperties.validationErrorMessage
                : null}
            </TextFieldHelperText>
          }
          onBlur={file => {
            this.handleFileSelect(file);
            this.setState({ outOfFocus: true });
          }}
          onFocus={file => {
            this.handleFileSelect(file);
            this.setState({ outOfFocus: false });
          }}
          isValid={!!this.state.fileName}
        />

        <Dialog open={this.state.open}>
          <DialogBody className={'file-upload-spinner'}>
            {this.state.processingMsg}
          </DialogBody>

          <Spinner />
        </Dialog>
        <FortellisDialog
          title={
            <FormattedMessage
              id="FormS3FileUpload.invalidFileDialogTitle"
              defaultMessage="Invalid file"
            />
          }
          message={
            <FormattedMessage
              id="FormS3FileUpload.fileSizeExceeded"
              defaultMessage="File size should not be more that 5 MB"
            />
          }
          open={this.state.onError}
          acceptButtonName={
            <FormattedMessage id="Alert.ok" defaultMessage="OK" />
          }
          onAccept={this.dismiss}
        />
      </div>
    );
  }
}

export default FormS3FileUpload;
