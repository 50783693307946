import React from "react";
import { injectIntl } from "react-intl";
import {
  AutoExpansionPanel,
  AutoExpansionPanelGroup,
  ExpansionPanelContent,
  ExpansionPanelHeader
} from "@cdk-uip/react-expansion-panel";
import MySubscriptionListItemContentStepHeader from "./my-subscription-list-item-content-step-header";
import StepConfigureApiContent from "./step-configureApi-content";
import StepSolutionProvisioning from "./step-solutionProvisioning-content";

class MySubscriptionListItemContentSteps extends React.Component {
  render() {
    const { subscription, apiConfigurationStatus } = this.props;
    return (
      <AutoExpansionPanelGroup className="subscription-card-content-steps">
        <AutoExpansionPanel expanded={true}>
          <ExpansionPanelHeader>
            <MySubscriptionListItemContentStepHeader
              title={`Configure APIs`}
              status={apiConfigurationStatus}
            />
          </ExpansionPanelHeader>
          <ExpansionPanelContent>
            <StepConfigureApiContent
              {...this.props}
              subscription={subscription}
            />
          </ExpansionPanelContent>
        </AutoExpansionPanel>
        <StepSolutionProvisioning subscription={subscription} {...this.props} />
      </AutoExpansionPanelGroup>
    );
  }
}

export default injectIntl(MySubscriptionListItemContentSteps);
