import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { Caption, Subheading1 } from '@cdk-uip/react-typography';
import { DialogSpinnerWithText } from '../../../common/Spinner';
import Dropzone from 'react-dropzone';
import { UploadImageUtils } from '../utils/UploadImageUtils';
import FortellisDialog from '../../../common/FortellisDialog';
import FormDeleteIcon from './FormDeleteIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import ActionSnackBar from './../../ActionSnackBar';
import ValidationUtils from '../utils/ValidationUtils';
import { TextFieldHelperText } from '@cdk-uip/react-text-field';
import { ThreeDots } from '@bit/mhnpd.react-loader-spinner.three-dots';

export class FormDropBoxS3Upload extends React.Component {
  componentDidMount() {
    const { formFieldValues, fieldProperties } = this.props;
    if (
      !formFieldValues[fieldProperties.name] ||
      formFieldValues[fieldProperties.name] === '' ||
      (typeof formFieldValues[fieldProperties.name] === 'string' &&
        formFieldValues[fieldProperties.name].trim() === '')
    ) {
      this.validate();
    }
  }

  validate() {
    const formFieldValues = this.props.formFieldValues;
    let isValid = this.validationUtils.validate(
      this.props.fieldProperties,
      formFieldValues
    );
    let activeFormId = 'overview';
    let activeTabId = 'overview';
    let activeSubFormId = 'overview';
    this.props.validate &&
      this.props.validate(
        isValid,
        this.props.fieldProperties.name,
        activeFormId,
        activeTabId,
        activeSubFormId
      );
    return isValid;
  }

  constructor(props) {
    super(props);
    this.validationUtils = new ValidationUtils();
    this.state = {
      uploaded: true,
      deleted: true,
      disabled: false,
      snackBar: false,
      isValid: true,
      isUploading: false,
      isDeleting: false
    };
    const {
      formStaticValues: { orgId }
    } = this.props;
    this.uploadImageUtils = new UploadImageUtils();

    this.handleImageDrop = (accepted, name) => {
      const formFieldValues = JSON.parse(
        JSON.stringify(this.props.formFieldValues)
      );
      this.setState({ uploaded: false });
      let fileName = `images/${orgId}/${
        this.props.formStaticValues.id
      }/${name.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
      if (this.props.formFieldValues[this.props.fieldProperties.nameFrom]) {
        let dynamicName = JSON.parse(
          JSON.stringify(formFieldValues[this.props.fieldProperties.nameFrom])
        );
        fileName = `images/${orgId}/${
          this.props.formStaticValues.id
        }/${name}/${dynamicName.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
      }
      this.setState({ isUploading: true });
      this.uploadImageUtils
        .uploadImage(accepted[0], fileName)
        .then(imageUrl => {
          formFieldValues[name] = imageUrl;
          this.props.onChange(formFieldValues);
          let isValid = this.validate();
          this.setState({
            isValid: isValid,
            uploaded: true,
            snackBar: true,
            isUploading: false
          });
        })
        .catch(err => {
          this.setState({ onError: true, isUploading: false });
        });
    };

    this.uploadRejected = () => {
      this.setState({ onError: true });
    };

    this.dismiss = () => {
      this.setState({ onError: false });
    };

    this.handleImageDelete = () => {
      const name = this.props.fieldProperties.name;
      const formFieldValues = JSON.parse(
        JSON.stringify(this.props.formFieldValues)
      );
      this.setState({ deleted: false });
      let fileName = `images/${orgId}/${
        this.props.formStaticValues.id
      }/${name.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
      if (this.props.formFieldValues[this.props.fieldProperties.nameFrom]) {
        let dynamicName = JSON.parse(
          JSON.stringify(formFieldValues[this.props.fieldProperties.nameFrom])
        );
        fileName = `images/${orgId}/${
          this.props.formStaticValues.id
        }/${name}/${dynamicName.toLowerCase().replace(/[^a-z0-9]/g, '-')}`;
      }

      this.setState({ isDeleting: true });
      this.uploadImageUtils
        .deleteImage(
          fileName,
          this.props.formStaticValues.id,
          this.props.formStaticValues.listingVersion
        )
        .then(() => {
          formFieldValues[name] = null;
          this.props.onChange(formFieldValues);
          this.setState({ deleted: true });
          let isValid = this.validate();
          this.setState({
            isValid: isValid,
            isDeleting: false
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({ deleted: true, isDeleting: false });
        });
    };

    this.isDisabled = disabled => {
      if (disabled) {
        if (typeof disabled === 'function') {
          return disabled(this.props.formStaticValues.status);
        }
      }
      return disabled;
    };
  }

  render() {
    const disabled = this.isDisabled(this.props.fieldProperties.disabled);
    const randomNumber = Math.random();
    return (
      <div className="form-field-with-tooltip">
        {this.props.fieldProperties.label && (
          <Subheading1
            className="form-field-dropbox-title"
            datacy={`lbl_${this.props.fieldProperties.label}`}
          >
            {this.props.fieldProperties.label}
            {this.props.fieldProperties.toolTipText && (
              <span>
                <i
                  id={`tooltip_${
                    this.props.fieldProperties.label
                  }${randomNumber}`}
                  className="material-icons help-label-icon"
                >
                  help
                </i>
                {this.props.fieldProperties.required && (
                  <div
                    className="required-icon"
                    style={{ display: 'inline-block' }}
                  >
                    *
                  </div>
                )}
              </span>
            )}
          </Subheading1>
        )}
        {this.props.fieldProperties.note && (
          <Subheading1 className="notes">
            {this.props.fieldProperties.note}
          </Subheading1>
        )}
        {!disabled &&
          this.props.value &&
          this.state.uploaded &&
          this.state.deleted && (
            <div
              className={
                this.props.fieldProperties.deleteIconStyle
                  ? this.props.fieldProperties.deleteIconStyle
                  : 'drop-box-text-with-image-delete'
              }
            >
              <FormDeleteIcon
                confirmMessageTitle={
                  <FormattedMessage
                    id="FormDropBoxS3Upload.deleteTitle"
                    defaultMessage="Delete Image"
                  />
                }
                confirmMessage={
                  <FormattedMessage
                    id="FormDropBoxS3Upload.deleteMessage"
                    defaultMessage="This action cannot be undone. Are you sure you want to delete this image?"
                  />
                }
                onDelete={this.handleImageDelete.bind(this)}
                deleteIconStyle="delete-icon"
              />
            </div>
          )}

        <Dropzone
          className={`${
            this.props.fieldProperties.style
          } FormDropBoxS3Upload__dropzone}`}
          accept="image/jpeg, image/png"
          maxSize={5000000}
          onDropAccepted={accepted => {
            this.handleImageDrop(accepted, this.props.fieldProperties.name);
          }}
          onDropRejected={() => {
            this.uploadRejected();
          }}
          disableClick={disabled}
        >
          {this.state.isUploading || this.state.isDeleting ? (
            <div>
              <ThreeDots height={30} width={30} />
            </div>
          ) : this.props.value ? (
            <div className="drop-zone-content-with-image">
              <div>
                {!disabled && (
                  <div className="drop-box-text-with-image">
                    <div className="drop-box-text-with-image-header">
                      {this.props.fieldProperties.uploadText
                        ? this.props.fieldProperties.uploadText
                        : 'Click to upload'}
                    </div>
                  </div>
                )}
                <img
                  alt={this.props.fieldProperties.name}
                  src={`${this.props.value}?${Math.random()}`}
                  className={
                    this.props.fieldProperties.imageStyle
                      ? this.props.fieldProperties.imageStyle
                      : 'drop-box-image'
                  }
                />
              </div>
            </div>
          ) : (
            <div className="drop-zone-content">
              <div>
                <i
                  className="material-icons"
                  style={{
                    fontSize: this.props.fieldProperties.iconSize,
                    color: 'gray'
                  }}
                >
                  file_upload
                </i>
                <div>
                  {this.props.fieldProperties.uploadText
                    ? this.props.fieldProperties.uploadText
                    : 'Click to upload'}
                </div>
                <div className="drop-box-text-note">
                  {this.props.fieldProperties.uploadTextNote}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {this.props.fieldProperties.validationErrorMessage && (
          <div style={{ marginTop: '10px' }}>
            <TextFieldHelperText persistent validation>
              {this.state.valid
                ? this.props.fieldProperties.validationErrorMessage
                : this.props.submitClicked
                ? !this.props.formFieldValues.solutionLogo ||
                  this.props.formFieldValues.solutionLogo === null
                  ? this.props.fieldProperties.validationErrorMessage
                  : null
                : null}
            </TextFieldHelperText>
          </div>
        )}
        <Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${this.props.fieldProperties.label}${randomNumber}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>

        {this.state.isUploading ? (
          <DialogSpinnerWithText open={true} label={'Uploading the image...'} />
        ) : null}
        {this.state.isDeleting ? (
          <DialogSpinnerWithText open={true} label={'Deleting the image...'} />
        ) : null}

        <FortellisDialog
          title={
            <FormattedMessage
              id="FormDropBoxS3Upload.invalidFileDialogTitle"
              defaultMessage="Invalid file"
            />
          }
          message={
            <FormattedMessage
              id="FormDropBoxS3Upload.invalidFileDialogMessage"
              defaultMessage="File may not exceed 5MB and must be formatted as either a PNG or JPG."
            />
          }
          open={this.state.onError}
          acceptButtonName={
            <FormattedMessage id="Alert.ok" defaultMessage="OK" />
          }
          onAccept={this.dismiss}
        />

        <ActionSnackBar
          show={!!this.state.snackBar}
          message={
            <FormattedMessage
              id="FormDropBoxS3Upload.snackBarMessage"
              defaultMessage="Image saved successfully"
            />
          }
          close={() => {
            this.setState({ snackBar: false });
          }}
        />
      </div>
    );
  }
}

export default injectIntl(FormDropBoxS3Upload);
