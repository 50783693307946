import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

class SubscriptionPlanContentPricing extends React.Component {
  render() {
    const {
      plan: {
        basePrice,
        planName,
        includedUnitOfMeasure,
        includedUnitQuantity,
        includedTransactionalFee,
        transactionalTypeOptions
      }
    } = this.props;

    const transactionalFee = () => {
      if (includedTransactionalFee && transactionalTypeOptions) {
        if (transactionalTypeOptions === '%') {
          return `${includedTransactionalFee.toFixed(
            2
          )} ${transactionalTypeOptions}`;
        }

        if (transactionalTypeOptions === '$') {
          return `${transactionalTypeOptions} ${includedTransactionalFee.toFixed(
            2
          )}`;
        }

        return '$0.00';
      }
      return '$0.00';
    };

    return (
      <div className="plan-content-pricing-div">
        <div className="plan-content-pricing-details">
          <div className="plan-content-pricing-details-includes">
            <div className="plan-pricing-include-title">
              {`${planName} will be provisioned for the following`}
            </div>
            <div className="plan-pricing-include-title-note">
              {`Your ${planName} period will begin after the provisioning process is complete.`}
            </div>
            <div className="plan-pricing-include-items">
              <div className="plan-pricing-include-item">
                <span className="plan-pricing-include-item-title">
                  {includedUnitOfMeasure &&
                    `Number of ${includedUnitOfMeasure.toLowerCase()}`}
                </span>
                <span className="plan-pricing-include-item-count">
                  {includedUnitQuantity}
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="plan-content-pricing-details-final">
            <div className="plan-checkout-amount">
              {(basePrice && `$${basePrice.toFixed(2)}`) || transactionalFee()}
            </div>
            <div className="plan-checkout-title">
              <FormattedMessage
                id="MarketplaceAccount.dueAtCheckout"
                defaultMessage="Due at checkout"
              />
            </div>
          </div>
          <div className="plan-checkout-notes">
            <FormattedMessage
              id="MarketplaceAccount.subscriptions.finalPriceNote"
              defaultMessage="Final price may include additional taxes."
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SubscriptionPlanContentPricing);
