import React from "react";
import { Subheading1, Subheading2 } from "@cdk-uip/react-typography";
import Dropzone from "react-dropzone";
import { FormattedMessage, injectIntl } from "react-intl";
export class FromDropBox extends React.Component {
  handleImageDrop(accepted, name) {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = accepted[0];
    this.props.onChange(formFieldValues);
  }

  render() {
    return (
      <div>
        <Subheading2 style={{ fontWeight: "bold" }}>
          {this.props.fieldProperties.label}
        </Subheading2>
        {this.props.fieldProperties.note && (
          <Subheading1 className="notes">
            {this.props.fieldProperties.note}
          </Subheading1>
        )}
        <Dropzone
          className={this.props.fieldProperties.style}
          accept="image/jpeg, image/png"
          onDrop={accepted => {
            this.handleImageDrop(accepted, this.props.fieldProperties.name);
          }}
        >
          <div className="drop-zone-content">
            <i
              className="material-icons"
              style={{ fontSize: "56px", color: "gray" }}
            >
              cloud_upload
            </i>
            <p />
            <div>
              <FormattedMessage
                id="FormDropBox.DropZone.div1"
                defaultMessage="Drag and drop a file here or click"
              />
            </div>
            <div>
              {this.props.value instanceof Object ? (
                this.props.value.name
              ) : (
                <img
                  alt={this.props.fieldProperties.name}
                  src={this.props.value}
                  width="100px"
                  height="100px"
                />
              )}
            </div>
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default injectIntl(FromDropBox);
