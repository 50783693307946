import { getApolloClient } from '../../containers/GraphQLClient';
import EmailTypeConstants from '../common/constants/EmailTypeConstants';
import gql from 'graphql-tag';

const sendEmail = async (emailType, data) => {
  const client = getApolloClient();
  let query = null;
  switch (emailType) {
    case EmailTypeConstants.TERMS_REJECTION_EMAIL:
      query = sendSubscriptionTermsRejectionEmail;
      break;
  }
  if (query) {
    await client.mutate({
      mutation: query,
      variables: data
    });
  }
};

const sendSubscriptionTermsRejectionEmail = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $solutionName: String
    $solutionId: String
    $buyerName: String
    $comment: String
    $customerPhone: String
    $customerEmail: String
    $companyName: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
          subscriptionDetails: {
            comment: $comment
            customerPhone: $customerPhone
            buyerEmail: $customerEmail
            buyerName: $buyerName
            companyName: $companyName
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;
export default sendEmail;
