import React from "react";
import { injectIntl } from "react-intl";
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton,
  DialogHeader
} from "@cdk-uip/react-dialog";
import {
  AutoExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelContentBody,
  ExpansionPanelHeader
} from "@cdk-uip/react-expansion-panel";
import SubscriptionPlanHeader from "../../solution-subscription/subscription-plan-header";
import SubscriptionPlanContentHeader from "../../solution-subscription/subscription-plan-content-header";
import SubscriptionPlanContentFeatures from "../../solution-subscription/subscription-plan-content-features";
import SubscriptionPlanContentPricing from "../../solution-subscription/subscription-plan-content-pricing";

class SubscriptionPlanView extends React.Component {
  render() {
    const { solution, plan } = this.props;
    return (
      <Dialog open={this.props.open} className="view-plan-dialog">
        <DialogHeader>
          <span className="plan-terms-dialog-header">Plan Details</span>
        </DialogHeader>
        <DialogBody className="plan-terms-dialog-body-container">
          <AutoExpansionPanel
            expanded={true}
            className="subscription-plan-details"
          >
            <ExpansionPanelHeader>
              <SubscriptionPlanHeader plan={plan} />
            </ExpansionPanelHeader>
            <ExpansionPanelContent className="subscription-plan-details-content-panel">
              <ExpansionPanelContentBody>
                <section className="plan-content-section">
                  <div className="plan-content-feature-div">
                    <SubscriptionPlanContentHeader solution={solution} />
                    <SubscriptionPlanContentFeatures plan={plan} />
                  </div>
                  <SubscriptionPlanContentPricing plan={plan} />
                </section>
              </ExpansionPanelContentBody>
            </ExpansionPanelContent>
          </AutoExpansionPanel>
        </DialogBody>
        <DialogFooter>
          <DialogFooterButton onClick={this.props.onClose}>
            Close
          </DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default injectIntl(SubscriptionPlanView);
