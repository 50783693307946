import React, { Component } from 'react';

class Rating extends Component {
  state = {
    clicked: false,
    rating: this.props.rating || 0,
    starsStateArray: [
      'star_border',
      'star_border',
      'star_border',
      'star_border',
      'star_border'
    ]
  };

  rate = rating => {
    this.setState({
      rating: rating
    });
    this.props.onRatingChange(rating);
  };

  reset = () => {
    this.setState({
      clicked: false,
      rating: 0,
      starsStateArray: [
        'star_border',
        'star_border',
        'star_border',
        'star_border',
        'star_border'
      ]
    });
  };

  render() {
    const { disabled, deadRating, customClass } = this.props;
    const { rating } = this.state;
    let stars = [];

    if (disabled) {
      for (let i = 0; i < rating; i++) {
        stars.push(
          <i key={i} className={`material-icons ${customClass}`}>
            star
          </i>
        );
      }
      for (let i = rating; i < 5; i++) {
        stars.push(
          <i key={i} className={`material-icons ${customClass}`}>
            star_border
          </i>
        );
      }
    } else if (deadRating) {
      for (let i = 0; i < 5; i++) {
        stars.push(
          <i key={i} className={`material-icons ${customClass}`}>
            star_border
          </i>
        );
      }
    } else {
      for (let i = 0; i <= 4; i++) {
        stars.push(
          <i
            data-cy={`icon_rating_${i}`}
            key={i}
            onClick={() => {
              this.setState({ clicked: true });
              this.rate(i + 1);
            }}
            onMouseEnter={() => {
              let copy = [...this.state.starsStateArray];
              for (let ind = 0; ind <= i; ind++) {
                copy.splice(ind, 1, 'star');
              }
              for (let ind = i + 1; ind <= 4; ind++) {
                copy.splice(ind, 1, 'star_border');
              }
              this.setState({ starsStateArray: copy });
            }}
            className={`material-icons ${customClass}`}
          >
            {this.state.starsStateArray[i]}
          </i>
        );
      }
    }

    return (
      <div
        className={this.props.addReview ? 'add_star_ratings ' : 'star_ratings'}
        onMouseLeave={() => {
          if (this.state.clicked === false) {
            this.setState({
              starsStateArray: [
                'star_border',
                'star_border',
                'star_border',
                'star_border',
                'star_border'
              ]
            });
          } else {
            if (rating !== 0) {
              let copy = [...this.state.starsStateArray];
              for (let ind = 0; ind < rating; ind++) {
                copy.splice(ind, 1, 'star');
              }
              for (let ind = rating; ind < 5; ind++) {
                copy.splice(ind, 1, 'star_border');
              }
              this.setState({ starsStateArray: copy });
            }
          }
        }}
      >
        {stars}
      </div>
    );
  }
}

export default Rating;
