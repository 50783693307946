import React from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import MySubscriptionsList from '../../containers/MySubscriptionsContainer';
import { Title } from '@cdk-uip/react-typography';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withApollo } from 'react-apollo';
import Spinner from '../common/Spinner';
import gql from 'graphql-tag';
import EntityDetails from '../common/EntityDetails';
import EntityRegistrationConfirmation from '../common/EntityRegistrationConfirmation';
import FortellisConstants from '../common/FortellisConstants';

const entityFromPlatform = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      name
      type
      phone
      website
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
    }
  }
`;

class MySubscriptions extends React.Component {
  state = {
    entityFromPlatform: null
  };

  fetchEntityFromPlatform(entityId) {
    this.props.client
      .query({
        query: entityFromPlatform,
        variables: { id: entityId }
      })
      .then(response => {
        if (!response.errors && response.data.entitiesFromPlatform) {
          this.setState({
            entityFromPlatform: response.data.entitiesFromPlatform
          });
        }
      })
      .catch(error => {
        console.log('Entity Not found in platform');
      });
  }

  componentDidMount() {
    this.props.entity &&
      this.props.entity.id &&
      this.fetchEntityFromPlatform(this.props.entity.id);
  }

  render() {
    if (!this.props.auth) {
      return null;
    }

    const {
      auth: { userData },
      entity
    } = this.props;
    const { entityFromPlatform } = this.state;

    return (
      <div className="component-content">
        {!entity ||
        !entity.id ||
        entity.id === FortellisConstants.DEFAULT_ORG_ID ||
        entity.id === FortellisConstants.PERSONAL_ACCOUNT ? (
          <EntityRegistrationConfirmation isPopup={false} />
        ) : !entityFromPlatform ? (
          <div>
            <Spinner />
            <div style={{ textAlign: 'center' }}>
              {' '}
              <strong>Loading subscription...</strong>
            </div>
          </div>
        ) : (
          <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="fm-page-header-title">
              <div className="subscription-title-parent">
                <Title
                  className="fm-page-content-title"
                  data-cy={'title_my_subscriptions'}
                >
                  <span>
                    <FormattedMessage
                      id="MySubscriptions.title"
                      defaultMessage="Subscriptions"
                    />
                  </span>
                </Title>
              </div>
              <EntityDetails entity={entityFromPlatform} />
            </LayoutGridCell>
            <LayoutGridCell span={12}>
              <MySubscriptionsList
                {...this.props}
                userData={userData}
                entity={entityFromPlatform}
              />
            </LayoutGridCell>
          </LayoutGrid>
        )}
      </div>
    );
  }
}

export default injectIntl(withApollo(withAuth(MySubscriptions)));
