import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { Radio } from '@cdk-uip/react-radio';
import { Checkbox } from '@cdk-uip/react-checkbox';

export class FormRadio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowUnAuthorized: this.props.formAllValues['un_authorized_signon']
        .un_authorized_signon.isEnabled
    };
  }
  handleRadioChange = (event, name) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = event.target.checked;
    this.props.onChange(
      this.resetDependent(event.target.checked, formFieldValues)
    );
  };

  resetDependent = (value, formFieldValues) => {
    if (this.props.fieldProperties.resetDependent) {
      let resetValues = this.props.fieldProperties.resetDependent(value);
      if (resetValues) {
        Object.keys(resetValues).forEach(key => {
          formFieldValues[key] = resetValues[key];
        });
      }
    }
    return formFieldValues;
  };

  render() {
    let {
      props: {
        fieldProperties: { name, markAsCheck },
        formFieldValues
      }
    } = this;
    let isChecked = markAsCheck(formFieldValues, name);
    if (!isChecked) {
      this.props.formAllValues[
        'un_authorized_signon'
      ].un_authorized_signon.isEnabled = false;
    }

    return (
      <>
        <div
          className="form-field-with-tooltip"
          id={this.props.fieldProperties.name}
        >
          <Radio
            className="radio-item-radio"
            checked={isChecked}
            disabled={this.props.fieldProperties.disabled}
            required={this.props.fieldProperties.required}
            onChange={event => {
              this.handleRadioChange(event, this.props.fieldProperties.name);
            }}
          />
          <label className="radio-item-label">
            {this.props.fieldProperties.label}
            {this.props.fieldProperties.toolTipText && (
              <span>
                <i
                  id={`tooltip_${this.props.fieldProperties.name}`}
                  className="material-icons help-label-icon"
                >
                  help
                </i>
              </span>
            )}
          </label>
          <Tooltip
            className="form-field-tooltip  checkbox-field-tooltip"
            htmlFor={`tooltip_${this.props.fieldProperties.name}`}
          >
            <span>{this.props.fieldProperties.toolTipText}</span>
          </Tooltip>
        </div>
        <div class="layoutcell layoutcellRadio">
          <div class="layoutcell layoutcellRadio">
            <div class="form-field-label subscriptioncaption">
              By selecting the above option, you will allow subscriptions for
              the app.
            </div>
          </div>
        </div>

        <div>
          <span className="form-content-divider" />
          <div className="online-mode-checkboxes">
            <div className="checkbox-slider-parent">
              <div
                className={`form-field-with-tooltip`}
                id={'stripe-checkbox-div'}
              >
                <Checkbox
                  className="check-box-item-checkBox"
                  checked={isChecked}
                  disabled={true}
                />
                <label className="check-box-item-label">
                  Allow subscription to authorized signers
                </label>
              </div>
            </div>

            <div className="checkbox-slider-parent">
              <div
                className={`form-field-with-tooltip`}
                id={'cdk-checkbox-div'}
              >
                <Checkbox
                  className="check-box-item-checkBox"
                  checked={
                    this.props.formAllValues['un_authorized_signon']
                      .un_authorized_signon.isEnabled
                  }
                  disabled={!isChecked || this.props.fieldProperties.disabled}
                  onChange={event => {
                    this.setState({ allowUnAuthorized: event.target.checked });
                    this.props.formAllValues[
                      'un_authorized_signon'
                    ].un_authorized_signon.isEnabled = event.target.checked;
                  }}
                />
                <label className="check-box-item-label">
                  Allow subscription to unauthorized signers
                </label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FormRadio;
