import React from "react";

const FormFieldDemoField = ({ fieldProperties }) => (
  <div className="static-demo-field-div">
    <div className="static-demo-field-label">{fieldProperties.label}</div>
    <div className={`${fieldProperties.style}  static-demo-field-value`}>
      {fieldProperties.textContent}
    </div>
  </div>
);
export default FormFieldDemoField;
