export const config = {
  formId: "support",
  formTitle: "Support",
  formValueType: "Object",
  tabs: [
    {
      id: "tutorials_and_documentation",
      label: "Tutorials and Documentation",
      contents: [
        {
          id: "tutorials_and_documentation",
          fields: [
            {
              type: "multiple_add_form",
              name: "tutorials_and_documentation",
              multiFormGridStyle: "multiple-add-form-div",
              showAddLink: false,
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: "title",
                        label: "Title",
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell"
                      },
                      {
                        name: "url",
                        label: "URL",
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell"
                      },
                      {
                        name: "purpose",
                        label: "Purpose",
                        type: "static",
                        style: "formtextfield",
                        gridStyle: "layoutcell",
                        maxLength: 128
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      id: "questions_and_support",
      label: "Questions and Support",
      contents: [
        {
          id: "questions_and_support",
          fields: [
            {
              name: "supportMessage",
              label: "Support Message",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "supportCenterUrl",
              label: "Support Center URL",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "phoneNumber",
              label: "Phone Number",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "emailAddress",
              label: "Email Address",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            },
            {
              name: "supportCommunityUrl",
              label: "Support Community URL",
              type: "static",
              style: "formtextfield",
              gridStyle: "layoutcell"
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    support: {
      tutorials_and_documentation: [],
      supportMessage: "",
      supportCenterUrl: "",
      phoneNumber: "",
      emailAddress: "",
      supportCommunityUrl: ""
    }
  },
  activeTabId: "tutorials_and_documentation"
};
