import React, { useState } from 'react';
import SPTextField from '../common/sell-page-reusables/TextField/SPTextField';
import {
  Grid,
  Row,
  Column,
  RadioGroup,
  Button,
  TextArea,
  Loader
} from 'cdk-radial';
import gql from 'graphql-tag';
import { getApolloClient } from '../../containers/GraphQLClient';
import ActionSnackBar from '../solution-submission/ActionSnackBar';
import StoreListingAPI from '../../api/StoreListingAPI';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

const USER_PREFERENCE = {
  CALL: 'call',
  EMAIL: 'email'
};

const RADIO_USER_PREFERENCE = [
  {
    id: 'radio-contact-method-call',
    label: 'Schedule a call',
    value: 'call'
  },
  {
    id: 'radio-contact-method-email',
    label: 'Ask a question',
    value: 'email'
  }
];

const StartAConversation = ({ auth }) => {
  const [userPreference, setUserPreference] = useState(USER_PREFERENCE.CALL);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [workEmail, setWorkEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSubmissionAck, setShowSubmissionAck] = useState(false);
  const [showSubmissionErr, setShowSubmissionErr] = useState(false);
  const [isWorkEmailValid, setIsWorkEmailValid] = useState(true);
  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [companyNameInvalid, setCompanyNameInvalid] = useState(false);

  const callCreateUserQuestionMutation = async userQuestion => {
    if (auth && auth.isAuthenticated) {
      const client = getApolloClient();
      return await client.mutate({
        mutation: captureUserQuestionMutation,
        variables: { userQuestion: userQuestion }
      });
    } else {
      return await StoreListingAPI.anonymous.captureUserQuestion(userQuestion);
    }
  };

  const handleRadioChange = event => {
    setMessage(event.target.value);
  };

  const resetForm = () => {
    setUserPreference(USER_PREFERENCE.CALL);
    setFirstName('');
    setLastName('');
    setWorkEmail('');
    setCompanyName('');
    setMessage('');
  };

  const onSubmit = () => {
    let isFirstNameInvalid = isInputInvalid(
        firstName,
        setFirstName,
        setFirstNameInvalid
      ),
      isLastNameInvalid = isInputInvalid(
        lastName,
        setLastName,
        setLastNameInvalid
      ),
      isCompanyNameInvalid = isInputInvalid(
        companyName,
        setCompanyName,
        setCompanyNameInvalid
      );

    if (isFirstNameInvalid || isLastNameInvalid || isCompanyNameInvalid) return;

    if (!isWorkEmailValid) {
      return;
    }

    let userQuestion = {
      userPreference,
      firstName,
      lastName,
      workEmail,
      companyName,
      message
    };
    setIsSubmitting(true);
    callCreateUserQuestionMutation(userQuestion)
      .then(response => {
        if (!response.errors && response.data && !response.data.error) {
          setIsSubmitting(false);
          setShowSubmissionAck(true);
          resetForm();
        } else {
          setIsSubmitting(false);
          setShowSubmissionErr(true);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        setShowSubmissionErr(true);
      });
  };

  const checkEmailValidity = () => {
    let email = workEmail;
    if (email && email !== '') {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setIsWorkEmailValid(regex.test(email));
    } else {
      setIsWorkEmailValid(true);
    }
  };

  const isInputInvalid = (input, handler, validHandler) => {
    let isInvalid = input.trim().length === 0;
    validHandler(isInvalid);
    handler(input.trim());
    return isInvalid;
  };

  return (
    <div className="max-width-resp">
      <div className="start-a-conversation page-padding-common">
        <div className="title">Start a Conversation</div>
        <div className="description">
          Still have questions? Our on-boarding team will guide you through the
          steps to be successful.
        </div>
        <form onSubmit={onSubmit}>
          <Grid className="start-a-conversation_grid">
            <Row>
              <Column columns={6}>
                <SPTextField
                  label="First Name"
                  dataQA="fname"
                  required
                  value={firstName}
                  onChange={setFirstName}
                  className="margin-bottom-16"
                  invalid={firstNameInvalid}
                />
              </Column>

              <Column columns={6}>
                <SPTextField
                  label="Last Name"
                  dataQA="lname"
                  required
                  value={lastName}
                  onChange={setLastName}
                  className="margin-bottom-16"
                  invalid={lastNameInvalid}
                />
              </Column>
            </Row>
            <Row>
              <Column columns={6}>
                <SPTextField
                  label="Work Email"
                  dataQA="workemail"
                  required
                  value={workEmail}
                  onChange={setWorkEmail}
                  onBlur={checkEmailValidity}
                  invalid={!isWorkEmailValid}
                  helperText={
                    !isWorkEmailValid ? 'Please enter a valid email.' : null
                  }
                  className="margin-bottom-16"
                />
              </Column>

              <Column columns={6}>
                <SPTextField
                  label="Company Name"
                  dataQA="cname"
                  required
                  value={companyName}
                  onChange={setCompanyName}
                  className="margin-bottom-16"
                  invalid={companyNameInvalid}
                />
              </Column>
            </Row>
            <Row>
              <Column
                columns={12}
                className="userPreference_radio-group_container"
              >
                <label className="mdc-form-field">I would like to: *</label>
                <div className="inline-block">
                  <RadioGroup
                    dataTestId="radio-group"
                    id="radio-group-start-conversation"
                    name="contact-method"
                    options={RADIO_USER_PREFERENCE}
                    onChange={e => setUserPreference(e.target.value)}
                    value={userPreference}
                  />
                </div>
              </Column>
            </Row>
            <Row>
              <Column columns={12}>
                <div className="message-class">
                  <TextArea
                    label={'Message'}
                    dataTestId="querymessage"
                    maxLength={300}
                    value={message}
                    onChange={handleRadioChange} //{setMessage}
                  />
                </div>
              </Column>
            </Row>
            <Row>
              <Column columns={12}>
                <div className="character-counter">
                  {message ? message.length : 0} / {300}
                </div>
              </Column>
            </Row>
            <Row className="float-right action-container">
              {isSubmitting ? (
                <div
                  data-qa="submit_que_spinner"
                  className="submit_que_spinner"
                >
                  <Loader data-testid="loader" />
                </div>
              ) : (
                <Button
                  type="submit"
                  dataTestId="submit_question"
                  text="Submit"
                  variant="secondary"
                />
              )}
            </Row>
          </Grid>
        </form>
        {showSubmissionAck && (
          <ActionSnackBar
            dataQA="snackbar_msgsent"
            show={showSubmissionAck}
            message="Message Sent."
            close={() => {
              setShowSubmissionAck(false);
            }}
          />
        )}
        {showSubmissionErr && (
          <ActionSnackBar
            dataQA="snackbar_msg_err"
            show={showSubmissionErr}
            message="Some error occurred."
            close={() => {
              setShowSubmissionErr(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

const captureUserQuestionMutation = gql`
  mutation($userQuestion: captureUserQuestionCmd) {
    captureUserQuestion(command: $userQuestion) {
      id
      userPreference
      firstName
      lastName
      workEmail
      companyName
      message
      createdDate
      status
    }
  }
`;

export default withAuth(StartAConversation);
