import React, { useEffect, useState } from 'react';
import Spinner from '../../../../common/Spinner';
import { TextField } from '@cdk-uip/react-text-field';
import { Select } from '@cdk-uip/react-select';
import { Card } from '@cdk-uip/react-card';
import { LayoutGrid } from '@cdk-uip/react-layout-grid';
import { LayoutGridCell } from '@cdk-uip/react-layout-grid';
import stripe from '../../../../common/images/stripe.svg';
import moment from 'moment';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import { Divider } from '@cdk-uip/react-divider';
import FortellisConstants from '../../../../common/FortellisConstants';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { environmentURLs } from '../../../../common/environment/CaptureEnvironment';
import { PrimaryButton, SecondaryButton, BUTTON_SIZES } from 'cdk-radial';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

const {
  STRIPE_BANK_TYPE,
  STRIPE_BANK_AUTH,
  BANK_ACCOUNT,
  BANK_CARD
} = FortellisConstants;
const client_id = process.env.REACT_APP_STRIPE_CLIENT_ID;
const accountHolderTypes = ['individual', 'company'];

const getRemittanceInfo = gql`
  query {
    getRemittanceInfo {
      id
      org_id
      payment_type
      created
      updated
      modifiedByEmail
      dashboard
      accountLink
      external_accounts {
        routing_number
        last4
        bank_name
        available_payout_methods
        object
        status

        brand
        country
        currency
        exp_month
        exp_year
        funding
      }
    }
  }
`;

const addStripeAccountOauth = gql`
  mutation($code: String!, $orgName: String!, $userName: String!) {
    addStripeAccountOauth(
      command: { code: $code, orgName: $orgName, userName: $userName }
    ) {
      id
      org_id
      payment_type
      created
      updated
      modifiedByEmail
      dashboard
    }
  }
`;
const addStripeAccountExpress = gql`
  mutation($code: String!, $orgName: String!, $userName: String!) {
    addStripeAccountExpress(
      command: { code: $code, orgName: $orgName, userName: $userName }
    ) {
      id
      org_id
      payment_type
      created
      updated
      modifiedByEmail
      external_accounts {
        routing_number
        last4
        bank_name
        available_payout_methods
        object
        status

        brand
        country
        currency
        exp_month
        exp_year
        funding
      }
      accountLink
      dashboard
    }
  }
`;

const addStripeAccountACH = gql`
  mutation(
    $accountHolderName: String!
    $accountHolderType: String!
    $accountNumber: String!
    $routingNumber: String!
  ) {
    addStripeAccountACH(
      command: {
        accountHolderName: $accountHolderName
        accountHolderType: $accountHolderType
        accountNumber: $accountNumber
        routingNumber: $routingNumber
      }
    ) {
      id
      org_id
      payment_type
      created
      updated
      modifiedByEmail
      accountHolderName
      accountHolderType
      routingNumber
      accountNumber
    }
  }
`;

export default function SetupBilling({ remittanceInfo, userData, entity }) {
  const [formData, updateFormData] = useState({});
  const [accountInfo, setAccountInfo] = useState(remittanceInfo);
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };
  const handleAchFormSubmit = async () => {
    const client = getApolloClient();

    setLoading(true);
    let { data = {} } = await client.mutate({
      mutation: addStripeAccountACH,
      variables: {
        ...formData
      }
    });
    setLoading(false);
    setAccountInfo(data.addStripeAccountACH);
    return false;
  };

  const handleConnectWithStripeEexpress = e => {
    e.preventDefault();
    window.open(
      `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${client_id}`,
      'ConnectwithExpress',
      `toolbar=yes,scrollbars=yes,resizable=yes,top=${window.innerHeight /
        2},left=${window.innerWidth / 2},width=900,height=500`
    );
  };

  const handleConnectWithStripe = e => {
    e.preventDefault();
    window.open(
      `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write`,
      'ConnectwithAuth',
      `toolbar=yes,scrollbars=yes,resizable=yes,top=${window.innerHeight /
        2},left=${window.innerWidth / 2},width=900,height=500`
    );
  };

  useEffect(() => {
    let handleMessage = async event => {
      if (
        event.data.code &&
        event.origin ===
          environmentURLs.marketplaceURL.substring(
            0,
            environmentURLs.marketplaceURL.lastIndexOf('/')
          )
      ) {
        console.log(`scope= ${event.data.scope}, code = ${event.data.code}`);
        const client = getApolloClient();
        setLoading(true);
        if (event.source.name === 'ConnectwithExpress') {
          let { data = {} } = await client.mutate({
            mutation: addStripeAccountExpress,
            variables: {
              code: event.data.code,
              orgName: entity.name,
              userName: userData.name
            }
          });
          setAccountInfo(data.addStripeAccountExpress);
          setLoading(false);
          return;
        }
        if (event.source.name === 'ConnectwithAuth') {
          let { data = {} } = await client.mutate({
            mutation: addStripeAccountOauth,
            variables: {
              code: event.data.code,
              orgName: entity.name,
              userName: userData.name
            }
          });

          setLoading(false);
          setAccountInfo(data.addStripeAccountOauth);
          return;
        }
      } else {
        return;
      }
    };
    window.addEventListener('message', handleMessage, false);
    return function cleanup() {
      window.removeEventListener('message', handleMessage);
    };
  });

  const onRefresh = async () => {
    const client = getApolloClient();
    setLoading(true);
    let { data = {} } = await client.query({
      query: getRemittanceInfo,
      fetchPolicy: 'no-cache'
    });
    setAccountInfo(data.getRemittanceInfo);
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="payment_loading">
          <Spinner />
          <div>Loading ...</div>
        </div>
      )}
      {!loading && (
        <React.Fragment>
          {accountInfo && (
            <>
              <RemittanceInfoView {...accountInfo} onRefresh={onRefresh} />
            </>
          )}

          {!accountInfo && (
            <>
              <div className="connect_with_stripe_text_box">
                <div className="payment_info_text">
                  Setup your account by creating or linking existing stripe
                  account using
                </div>
                <img
                  src={stripe}
                  className="connect_with_stripe"
                  alt={'connect'}
                  onClick={handleConnectWithStripe}
                />
              </div>

              <div className="payment_divider_flex">
                <div className="payment_divider">
                  <Divider />
                </div>
                <div className="payment_divider_text">Or</div>
                <div className="payment_divider">
                  <Divider />
                </div>
              </div>

              <div className="connect_with_stripe_text_box">
                {`Set up by connecting your bank account.`}

                <SecondaryButton
                  text="Enter Bank Details"
                  size={BUTTON_SIZES.MEDIUM}
                  onClick={handleConnectWithStripeEexpress}
                  style={{ marginLeft: '40px', paddingBottom: '10px' }}
                />
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function RemittanceInfoView({
  id,
  org_id,
  payment_type = '-',
  created,
  updated,
  modifiedByEmail = '-',
  external_accounts = [],
  accountLink,
  dashboard = '-',
  onRefresh
}) {
  const getBankName = (bankName = '') => {
    bankName = bankName.toLowerCase();
    return bankName.charAt(0).toUpperCase() + bankName.slice(1);
  };
  const openAccountEditOption = e => {
    e.preventDefault();
    window.open(
      accountLink,
      'Stripe account login link',
      `toolbar=yes,scrollbars=yes,resizable=yes,top=${window.innerHeight /
        2},left=${window.innerWidth / 2},width=900,height=500`
    );
  };
  return (
    <div>
      {payment_type === STRIPE_BANK_TYPE && (
        <>
          {external_accounts.map(account => {
            if (account.object === BANK_ACCOUNT) {
              return (
                <Card>
                  <div className="manage-listing-header">
                    <i className="material-icons account-icon">
                      account_balance
                    </i>
                    <div className="account-management-panel-header manage-listing-header-title">
                      {getBankName(account.bank_name)}
                      {accountLink && (
                        <>
                          <LightTooltip title={'Sync record'}>
                            <i
                              className="material-icons account-link-icon"
                              onClick={onRefresh}
                            >
                              sync
                            </i>
                          </LightTooltip>
                          <LightTooltip title={'Update account info'}>
                            <i
                              className="material-icons account-link-icon"
                              onClick={openAccountEditOption}
                            >
                              edit
                            </i>
                          </LightTooltip>
                        </>
                      )}
                    </div>
                  </div>
                  <LayoutGrid style={{ margin: 'unset' }}>
                    <LayoutGridCell span={4}>
                      <div className="solution-metric-label">
                        Account Number
                      </div>
                      <div className="account-metric-value">
                        <span className="account_number">********</span>
                        {account.last4}
                      </div>
                    </LayoutGridCell>
                    <LayoutGridCell span={4}>
                      <div className="solution-metric-label">
                        Routing Number
                      </div>
                      <div className="account-metric-value">
                        {account.routing_number}
                      </div>
                    </LayoutGridCell>
                    <LayoutGridCell span={4}>
                      <div className="solution-metric-label">Status</div>
                      <div className="account-metric-value">
                        {account.status}
                      </div>
                    </LayoutGridCell>
                    <AccountCommanInfo
                      created={created}
                      updated={updated}
                      modifiedByEmail={modifiedByEmail}
                    />
                  </LayoutGrid>
                </Card>
              );
            } else if (account.object === BANK_CARD) {
              return (
                <Card>
                  <div className="manage-listing-header">
                    <i className="material-icons account-icon">payment</i>
                    <div className="account-management-panel-header manage-listing-header-title">
                      {`${account.brand} - ${account.funding}`}
                      {accountLink && (
                        <>
                          <LightTooltip title={'Sync record'}>
                            <i
                              className="material-icons account-link-icon"
                              onClick={onRefresh}
                            >
                              sync
                            </i>
                          </LightTooltip>
                          <LightTooltip title={'Update account info'}>
                            <i
                              className="material-icons account-link-icon"
                              onClick={openAccountEditOption}
                            >
                              edit
                            </i>
                          </LightTooltip>
                        </>
                      )}
                    </div>
                  </div>

                  <LayoutGrid style={{ margin: 'unset' }}>
                    <LayoutGridCell span={4}>
                      <div className="solution-metric-label">Card Number</div>
                      <div className="account-metric-value">
                        <span className="account_number">********</span>
                        {account.last4}
                      </div>
                    </LayoutGridCell>

                    <LayoutGridCell span={4}>
                      <div className="solution-metric-label">Valid upto</div>
                      <div className="account-metric-value">{`${
                        account.exp_month
                      }/${account.exp_year}`}</div>
                    </LayoutGridCell>

                    <LayoutGridCell span={4}>
                      <div className="solution-metric-label">Country</div>
                      <div className="account-metric-value">{`${
                        account.country
                      }`}</div>
                    </LayoutGridCell>

                    <AccountCommanInfo
                      created={created}
                      updated={updated}
                      modifiedByEmail={modifiedByEmail}
                    />
                  </LayoutGrid>
                </Card>
              );
            }
          })}
        </>
      )}
      {payment_type === STRIPE_BANK_AUTH && (
        <Card>
          <div className="manage-listing-header">
            <i className="material-icons account-icon">account_balance</i>
            <div className="account-management-panel-header manage-listing-header-title">
              Stripe Account
            </div>
          </div>
          <LayoutGrid style={{ margin: 'unset' }}>
            <LayoutGridCell span={4}>
              <div className="solution-metric-label">Stripe dashboard</div>
              <div className="account-metric-value">{dashboard}</div>
            </LayoutGridCell>
            <AccountCommanInfo
              created={created}
              updated={updated}
              modifiedByEmail={modifiedByEmail}
            />
          </LayoutGrid>
        </Card>
      )}
    </div>
  );
}

function AccountCommanInfo({ created, updated, modifiedByEmail = '-' }) {
  return (
    <>
      <LayoutGridCell span={4}>
        <div className="solution-metric-label">Modified by</div>
        <div className="account-metric-value">{modifiedByEmail}</div>
      </LayoutGridCell>

      <LayoutGridCell span={4}>
        <div className="solution-metric-label">Created On</div>
        <div className="account-metric-value">
          {moment(created).format('DD MMM YYYY')}
        </div>
      </LayoutGridCell>

      <LayoutGridCell span={4}>
        <div className="solution-metric-label">Updated On</div>
        <div className="account-metric-value">
          {moment(updated).format('DD MMM YYYY')}
        </div>
      </LayoutGridCell>
    </>
  );
}
