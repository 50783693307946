import React from 'react';
import Button from '@material/react-button';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

const SellInMarketplaceCTA = () => {
  return (
    <div className="sell-in-market-cta-container">
      <div
        className="sell-in-market__cta-message"
        data-cy="sell-in-market__cta-message"
      >
        <FormattedMessage
          id="SellInMarketplaceCTA.message"
          defaultMessage="Want to sell your App on Marketplace?"
        />
      </div>

      <div className="sell-in-market__cta-button">
        <Link to={`/sell`} data-cy="sell-in-market__cta-button">
          <Button>
            <FormattedMessage
              id="SellInMarketplaceCTA.button"
              defaultMessage="Get Started"
            />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default injectIntl(SellInMarketplaceCTA);
