import axios from 'axios';
import { environmentURLs } from './environment/CaptureEnvironment';

export const getRedLineContractStatus = async (accessToken, orgId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${
          environmentURLs.agreementManagementUrl
        }/${orgId}/exists/appAgreement`,
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : ''
          }
        }
      );
      if (
        !!response &&
        !!response.data &&
        !!response.data.hasOwnProperty('payload')
      ) {
        resolve(response);
      } else {
        reject();
      }
    } catch (error) {
      console.error({ Error: error });
      reject();
    }
  });
};
