import React from 'react';
import { injectIntl } from 'react-intl';

class MySubscriptionListItemContentTitle extends React.Component {
  render() {
    const {
      subscription: {
        plan: {
          basePrice,
          includedTransactionalFee,
          transactionalTypeOptions,
          isTheFreeTrial,
          billingType,
          billingFrequency,
          includedTransactionalType
        }
      }
    } = this.props;

    const transactionalFee = () => {
      if (includedTransactionalFee && transactionalTypeOptions) {
        if (transactionalTypeOptions === '%') {
          return `${includedTransactionalFee.toFixed(
            2
          )} ${transactionalTypeOptions}`;
        }

        if (transactionalTypeOptions === '$') {
          return `${transactionalTypeOptions} ${includedTransactionalFee.toFixed(
            2
          )}`;
        }

        return '$0.00';
      }
      return '$0.00';
    };

    return (
      <div className="subscription-card-content-title">
        <span className="subscription-card-content-title-text-left">
          Steps for Activation
        </span>
        <span className="subscription-card-content-title-text-right">
          {(basePrice && `$${basePrice.toFixed(2)}`) || transactionalFee()}
          <span className="subscription-card-content-title-text-right-sub">
            {isTheFreeTrial && '/ month'}
            {billingType &&
              billingType === 'RECURRING' &&
              billingFrequency &&
              `/ ${billingFrequency.toLowerCase()}`}
            {billingType &&
              billingType === 'TRANSACTIONAL' &&
              includedTransactionalType &&
              `/ ${includedTransactionalType}`}
          </span>
        </span>
      </div>
    );
  }
}

export default injectIntl(MySubscriptionListItemContentTitle);
