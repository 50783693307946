import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { PRODUCT } from '../../../components/common/constants/MonetizationConstants';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';

export const initialState = {
  savedPaymentDetails: {},
  savedPaymentDetailsLoading: false,
};

export const getSavedPaymentDetailsBySolutionId = createAsyncThunk(
  'getSavedPaymentDetailsBySolutionId ',
  async ({ solnId, orgId, token }) => {
    const url = `${environmentURLs.paymentServiceUrl}/customer/solutionData?solutionId=${solnId}&orgId=${orgId}&productType=${PRODUCT.LISTING_FEE}`;
    try {
      const response = await axios({
        method: 'GET',
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response?.data?.data.length > 0) {
        return (
            response.data.data[0]
)
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const savedPaymentDetailsSlice = createSlice({
  name: 'savedPaymentDetails',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSavedPaymentDetailsBySolutionId.fulfilled]: (state, action) => {
      state.savedPaymentDetails = action.payload;
      state.savedPaymentDetailsLoading = false;
    },
    [getSavedPaymentDetailsBySolutionId.pending]: (state) => {
      state.savedPaymentDetails = null;
      state.savedPaymentDetailsLoading = true;
    },
    [getSavedPaymentDetailsBySolutionId.rejected]: (state) => {
      state.savedPaymentDetails = null;
      state.savedPaymentDetailsLoading = false;
    },
  },
});

export default savedPaymentDetailsSlice.reducer;