import React from 'react';
import { Dialog } from '@cdk-uip/react-dialog';

class ConsentTermsViewer extends React.Component {
  render() {
    const s3Url = this.props.documentLink;
    const fileName = s3Url ? s3Url.split('/')[s3Url.split('/').length - 1] : '';
    if (!s3Url) {
      return null;
    }

    return (
      <div>
        <Dialog open={this.props.open} onCancel={this.props.onClose}>
          <iframe title={fileName} width="100%" height="800px" src={s3Url} />
        </Dialog>
      </div>
    );
  }
}

export default ConsentTermsViewer;
