import React from "react";
import { Button as UIPButton } from "@cdk-uip/react-jsonly-button";

const Button = ({ primary, raised, ...props }) => {
  if (primary) {
    props = {
      ...props,
      primary: true,
      raised: true
    };
  }
  if (raised) {
    props = {
      ...props,
      className: "mdc-button--stroked"
    };
  }
  return <UIPButton {...props} />;
};

export default Button;
