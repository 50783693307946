import React from "react";
import { injectIntl } from "react-intl";
import SubscriptionPlanContentHeader from "./subscription-plan-content-header";
import SubscriptionPlanContentFeatures from "./subscription-plan-content-features";
import SubscriptionPlanContentTermsAction from "./subscription-plan-content-terms-action";
import SubscriptionPlanContentPricing from "./subscription-plan-content-pricing";
//import SubscriptionPlanTerms from "./subscription-plan-terms";

class SubscriptionPlanContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isTermsViewed: false
    };
    this.onShow = () => {
      let { solution } = this.props;
      window.open(solution.overview.termsAndConditionsUrl || "");
      this.setState({ open: true, isTermsViewed: true });
    };
    this.onDialogAction = async isAccepted => {
      // await this.setState({ open: false });
      let acceptedPlanId = isAccepted ? this.props.plan.id : null;
      this.props.onTermsActions(acceptedPlanId);
    };
    this.onClose = () => {
      this.setState({ open: false });
    };
  }

  render() {
    const { solution, plan, isAccepted } = this.props;
    const { state: { isTermsViewed } } = this;
    return (
      <section className="plan-content-section">
        <div className="plan-content-feature-div">
          <SubscriptionPlanContentHeader solution={solution} />
          <SubscriptionPlanContentFeatures plan={plan} />
          <SubscriptionPlanContentTermsAction
            isAccepted={isAccepted}
            onShow={this.onShow}
            plan={plan}
            solution={solution}
            isTermsViewed={isTermsViewed}
            onAcceptAction={this.onDialogAction}
          />
        </div>
        <SubscriptionPlanContentPricing plan={plan} />
        {/* <SubscriptionPlanTerms
          solution={solution}
          plan={plan}
          isAccepted={isAccepted}
          open={this.state.open}
          onClose={this.onClose}
          onDialogAction={this.onDialogAction}
        /> */}
      </section>
    );
  }
}

export default injectIntl(SubscriptionPlanContent);
