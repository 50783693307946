export const config = {
  formId: 'solution_notes',
  formTitle: 'App Notes',
  formValueType: 'Object',
  tabs: [
    {
      id: 'solution_notes',
      label: 'Additional Details',
      contents: [
        {
          id: 'solution_notes',
          title: 'Additional Notes',
          fields: [
            {
              name: 'reviewersComments',
              label: 'Additional information needed for the review process',
              type: 'text_area',
              style: ['formtextfield', 'form-text-area']
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    solution_notes: {
      solution_notes: ''
    }
  },
  activeTabId: 'solution_notes'
};
