import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import NullState from '../common/NullState';
import EmptyContentImage from '../common/images/Empty_State_16x9_2x.png';
import ImageContainer169 from '../common/ImageContainer169';
import SolutionListItemOrgDetails from './SolutionListItemOrgDetails';
import { Card } from '@cdk-uip/react-card';

import { Link } from 'react-router-dom';

const FeaturedListItem = ({ solution }) =>
  solution.overview ? (
    <Link to={`/solutions/${solution.id}`}>
      <div>
        <Card>
          {solution.overview.featuredImage ? (
            <ImageContainer169 src={solution.overview.featuredImage} />
          ) : (
            <ImageContainer169 src={EmptyContentImage} />
          )}
        </Card>
        <div className="featured-solution-description-container">
          <div className="padding-left-zero">
            <div className="featured-solution-title">
              {solution.overview.name ? (
                solution.overview.name
              ) : (
                <FormattedMessage
                  id="FeaturedListItem.text1"
                  defaultMessage="No app name to display..."
                />
              )}
            </div>
            <SolutionListItemOrgDetails
              orgId={solution.orgId}
              className={'featured-solution-orgName'}
            />
            <div className="featured-solution-description">
              {solution.overview.briefDescription ? (
                solution.overview.briefDescription.length < 120 ? (
                  solution.overview.briefDescription
                ) : (
                  solution.overview.briefDescription.substring(0, 120) + ' ...'
                )
              ) : (
                <FormattedMessage
                  id="FeaturedListItem.text2"
                  defaultMessage="No Description to display..."
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <Link to={`/solutions/${solution.id}`}>
      <Card className="solution-list-item">
        <NullState
          text={
            <FormattedMessage
              id="AppListingInfo.noItems"
              defaultMessage="No app listing info to display"
            />
          }
        />
      </Card>
    </Link>
  );

export default injectIntl(FeaturedListItem);
