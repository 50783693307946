import React from 'react';
import Spinner from '../../../../common/Spinner';
import { InsightDashboardItem } from './insight-items/InsightDashboardItem';
import InsightPlanReport from './insight-items/InsightPlanReport';
import { FormattedMessage } from 'react-intl';
import { IconOpenInNew } from 'cdk-radial';
import { environmentURLs } from '../../../../common/environment/CaptureEnvironment';

class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  findTotalSubscribers() {
    let totalSubscriptions = this.props.subscriptions
      ? [...this.props.subscriptions]
      : [];
    let activeSubscriptions = totalSubscriptions.filter(item => {
      return item.status.toLowerCase() === 'active';
    });
    return activeSubscriptions ? activeSubscriptions.length : 0;
  }

  findTotalLeads() {
    return this.props.leads ? this.props.leads.length : 0;
  }

  findTotalReviews() {
    return this.props.reviews
      ? this.props.reviews.filter(review => review.status === 'Approved').length
      : 0;
  }
  render() {
    const { changeMenu, activeMenuItem, loading, solutions } = this.props;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div>
        <div className="manage-solution-page-title">
          {activeMenuItem.label ? activeMenuItem.label : false}
        </div>
        <div>
          View essential details like Activations, Leads, Reviews, and statuses
          of Plans for your app in Marketplace.{' '}
          <a
            target="_blank"
            href={`${
              environmentURLs.docs
            }docs/tutorials/app-lifecycle/managing-app-activations/#the-insights-page`}
            rel="noopener noreferrer"
          >
            <span className="fdn-api__learnmore">Learn More</span>
            <IconOpenInNew className="fdn-api__open_in_new" />
          </a>
        </div>
        <div className="insights-dashboard">
          <InsightDashboardItem
            className="insights-dashboard-item-left"
            label={
              solutions &&
              solutions.length &&
              solutions.slice(-1)[0].activateOnly === false
                ? 'Active Subscriptions'
                : 'Activations'
            }
            value={this.findTotalSubscribers()}
            onClick={() => {
              changeMenu && changeMenu('subscriptions');
            }}
          />
          <InsightDashboardItem
            label={
              <FormattedMessage
                id="InsightPlanReport.Dashboard.Leads"
                defaultMessage="Leads"
              />
            }
            value={this.findTotalLeads()}
            onClick={() => {
              changeMenu && changeMenu('leads');
            }}
          />
          <InsightDashboardItem
            className="insights-dashboard-item-right"
            label={
              <FormattedMessage
                id="InsightPlanReport.Dashboard.Reviews"
                defaultMessage="Reviews"
              />
            }
            value={this.findTotalReviews()}
            onClick={() => {
              changeMenu && changeMenu('reviews');
            }}
          />
        </div>
        <div>
          <InsightPlanReport
            solutions={this.props.solutions}
            subscriptions={this.props.subscriptions}
          />
        </div>
      </div>
    );
  }
}
export default Insights;
