import React from "react";

import { Select } from "@cdk-uip/react-select";

export default class SelectComponent extends React.Component {
  state = {
    value: this.props.value || ""
  };

  componentDidUpdate(prevProps, prevState) {
    let selectedOptionList =
      this.props.options &&
      this.props.options.filter(option => option.value === this.state.value);
    if (!selectedOptionList.length) {
      let selectedOption = this.props.options[0];
      if (!selectedOption) {
        return;
      }
      this.setState({ value: selectedOption.value || "" });
      this.props.onSelectChange &&
        this.props.onSelectChange(
          selectedOption.value,
          selectedOption.label,
          this.props.name
        );
    }
  }

  handleSelectChange(value, componentName) {
    let selectedOption =
      this.props.options &&
      this.props.options.filter(option => option.value === value)[0];
    this.props.onSelectChange &&
      this.props.onSelectChange(
        selectedOption.value,
        selectedOption.label,
        componentName
      );
    this.setState({ value: value });
  }

  render() {
    const randomNumber = Math.random();
    return (
      <div style={{ display: "inline-block" }}>
        <Select
          className={this.props.className || ""}
          name={this.props.name || randomNumber}
          id={this.props.name || randomNumber}
          label={<span className="form-field-label">{this.props.label}</span>}
          value={this.state.value}
          disabled={this.props.isDisabled}
          required={this.props.required}
          onChange={event => {
            this.handleSelectChange(event.target.value, this.props.name);
          }}
        >
          {this.props.options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Select>
        {/*<Tooltip
          className="fortellis-field-info"
          htmlFor={`tooltip_${this.props.name}${randomNumber}`}
        >
          <span>{this.props.toolTipText}</span>
        </Tooltip>*/}
      </div>
    );
  }
}
