import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  root: {
    width: 300
  },
  input: {
    width: 70
  }
});

export default function FormInputSlider({
  initialValue,
  paymentMode,
  paymentOption,
  onInputSliderChange,
  isShow,
  disabled
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    if (isShow === false) {
      setValue('');
    }
  }, [isShow]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    onInputSliderChange(newValue, paymentOption);
  };

  const handleInputChange = event => {
    let value = event.target.value === '' ? '' : Number(event.target.value);
    setValue(value);
    onInputSliderChange(value, paymentOption);
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
      onInputSliderChange(0, paymentOption);
    } else if (value > 100) {
      setValue(100);
      onInputSliderChange(100, paymentOption);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            disabled={!isShow || disabled}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              // step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
            disabled={!isShow || disabled}
          />
        </Grid>
      </Grid>
    </div>
  );
}
