import { ContractNotificationConstants } from './ContractNotificationsConstants';

export const config = {
  formId: 'contract_notifications',
  formTitle: 'Contract Notifications',
  formValueType: 'Object',
  formNote:
    'By selecting below option, you can select the contract notifications type for the app',
  tabs: [
    {
      id: 'contract_notifications',
      label: 'Contract Notifications',
      contents: [
        {
          id: 'productionEmail',
          fields: [
            {
              formtitle: 'Select Contract Notifications Option',
              type: 'FortellisTitle3',
              style: 'provisioningtitle'
            },
            {
              name: 'contractNotificationsType',
              label: 'Production Email',
              type: 'radio',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['contractNotificationsType'] =
                    ContractNotificationConstants.type.PROD_EMAIL;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return (
                  formValue[name] ===
                  ContractNotificationConstants.type.PROD_EMAIL
                );
              }
            },
            {
              formtitle: 'Marketplace Contracts DL.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        },
        {
          id: 'adminsEmail',
          fields: [
            {
              name: 'contractNotificationsType',
              label: 'Admin Email',
              type: 'radio',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['contractNotificationsType'] =
                    ContractNotificationConstants.type.ADMIN_EMAIL;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return (
                  formValue[name] ===
                  ContractNotificationConstants.type.ADMIN_EMAIL
                );
              }
            },
            {
              formtitle: 'Marketplace Support DL.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        },
        {
          id: 'qaEmail',
          fields: [
            {
              name: 'contractNotificationsType',
              label: 'QA Email',
              type: 'radio',
              disabled: false,
              gridStyle: 'layoutcell',
              resetDependent: function(value) {
                let resetValue = {};
                if (value) {
                  resetValue['contractNotificationsType'] =
                    ContractNotificationConstants.type.QA_EMAIL;
                }
                return resetValue;
              },
              markAsCheck: function(formValue, name) {
                return (
                  formValue[name] ===
                  ContractNotificationConstants.type.QA_EMAIL
                );
              }
            },
            {
              formtitle: 'Marketplace Dev QA DL.',
              type: 'FormCaption',
              gridStyle: 'layoutcell layoutcellRadio',
              style: 'provisioningcaption'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    contract_notifications: {
      contractNotificationsType: ''
    }
  },
  activeTabId: 'contract_notifications'
};
