import React from 'react';
import CustomTabForm from './CustomTabForm';

const getKeys = (objMap = {}) => {
  return Object.keys(objMap);
};
export class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValueCount: getKeys(this.props.formFieldValues).length,
      activeSubFormId: this.props.activeSubFormId
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      getKeys(nextProps.formFieldValues).length !== prevState.formValueCount
    ) {
      return {
        activeSubFormId: nextProps.activeSubFormId,
        formValueCount: getKeys(nextProps.formFieldValues).length
      };
    }
    return null;
  }

  render() {
    return getKeys(this.props.formFieldValues).map(formValueKey => {
      return (
        ((isNaN(this.props.activeSubFormId) &&
          formValueKey === this.props.activeSubFormId) ||
          (!isNaN(this.props.activeSubFormId) &&
            parseInt(formValueKey, 10) ===
              parseInt(this.props.activeSubFormId, 10))) && (
          <CustomTabForm
            {...this.props}
            key={formValueKey}
            formConfig={this.props.formConfig}
            formAllValues={this.props.formAllValues}
            formValueCount={this.state.formValueCount}
            formStaticValues={this.props.formStaticValues}
            formFieldValues={this.props.formFieldValues[formValueKey]}
            activeSubFormId={this.props.activeSubFormId}
            onDelete={this.props.onDelete}
            onSave={this.props.onSave}
            isSolutionReview={this.props.isSolutionReview}
            handlePreview={this.props.handlePreview}
            isFormInvalid={this.props.isFormInvalid}
          />
        )
      );
    });
  }
}

export default CustomForm;
