import React from "react";
import CheckIcon from "../common/images/checkMark.jpg";
import { injectIntl } from "react-intl";

class SubscriptionPlanContentFeatures extends React.Component {
  render() {
    const { intl, plan } = this.props;
    return (
      <div>
        <div className="plan-content-includes-title">
          {`${plan.planName} includes the following features`}
        </div>
        {plan &&
          plan.features &&
          plan.features.map((feature, featureIdx) => {
            return (
              <div key={featureIdx} className="plan-content-includes-list-item">
                <img
                  src={CheckIcon}
                  className="plan-content-list-check-icon"
                  alt={intl.formatMessage({
                    id: "EditionFeatures.checkIcon.alt",
                    defaultMessage: "check"
                  })}
                />
                {feature.title}
              </div>
            );
          })}
      </div>
    );
  }
}

export default injectIntl(SubscriptionPlanContentFeatures);
