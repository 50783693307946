import React from "react";
import { injectIntl } from "react-intl";
import { Button } from "@cdk-uip/react-button";

class StepContentActions extends React.Component {
  render() {
    const { actions } = this.props;
    return actions && actions.length > 0
      ? actions.map((action, actionIdx) => {
          return action.hide ? (
            ""
          ) : (
            <Button
              primary={action.isPrimary}
              raised={action.isRaised}
              outlined={action.outlined}
              onClick={action.onClick}
              disabled={action.disabled}
              key={actionIdx}
            >
              {action.label}
            </Button>
          );
        })
      : false;
  }
}

export default injectIntl(StepContentActions);
