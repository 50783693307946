import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogFooterButton,
  DialogHeader
} from '@cdk-uip/react-dialog';
import TextField from '@material-ui/core/TextField';
import Spinner from '../../../../common/Spinner';

const maxSizeForTextField = 100;
const maxRow = 2;

export default class CompleteProvisioningDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null
    };
  }

  handleInputChange(event) {
    this.setState({ comment: event.target.value });
  }

  onAccept = () => {
    this.props.onAccept && this.props.onAccept(this.state.comment);
  };

  onCancel = () => {
    this.setState({ comment: null });
    this.props.onCancel && this.props.onCancel();
  };

  render() {
    return (
      <div className="complete-provisioning-dialog">
        <Dialog
          open={this.props.isOpen}
          onAccept={this.onAccept}
          onCancel={this.onCancel}
        >
          {this.props.loading ? (
            <div className="complete-provisioning-loading">
              <Spinner />
            </div>
          ) : (
            <div>
              <DialogHeader>
                <FormattedMessage
                  id="CompleteProvisioning.DialogHeader"
                  defaultMessage="Complete Provisioning"
                />
              </DialogHeader>
              <div>
                <DialogBody>
                  <div>{this.props.bodyMessage || ''}</div>
                  <div className="complete-provisioning-text">
                    <TextField
                      name="comment"
                      label={
                        <FormattedMessage
                          id="CompleteProvisioning.commentPlaceholder"
                          defaultMessage="Add Comment"
                        />
                      }
                      value={this.state.Comment}
                      onChange={this.handleInputChange.bind(this)}
                      fullWidth={true}
                      inputProps={{
                        maxLength: maxSizeForTextField
                      }}
                      multiline={true}
                      rowsMax={maxRow}
                      rows={maxRow}
                      className="form-text-area formtextfield"
                    />
                    <div className="character-remaining-div">
                      {this.state.comment ? this.state.comment.length : 0} /{' '}
                      {maxSizeForTextField}
                    </div>
                  </div>
                </DialogBody>
              </div>
              <DialogFooter>
                <DialogFooterButton cancel>
                  <FormattedMessage
                    id="CompleteProvisioning.cancel"
                    defaultMessage="Cancel"
                  />
                </DialogFooterButton>
                <DialogFooterButton accept>
                  <FormattedMessage
                    id="CompleteProvisioning.ok"
                    defaultMessage="Ok"
                  />
                </DialogFooterButton>
              </DialogFooter>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}
