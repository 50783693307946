import React from 'react';
import './ShowButtonToggle.scss';

const ShowButtonToggle = ({
  filterArrayLength,
  handleShowToggle,
  isShowMoreSelectedPerSection,
  sectionTitle,
  ...others
}) => {
  const ShowButton = ({ text, ...others }) => (
    <div className="show-more-div-nl">
      <span
        className="show-more-nl"
        data-qa={`Show_${text}-btn`}
        onClick={() => handleShowToggle(filterArrayLength, sectionTitle)}
      >
        {`Show ${text}`}
      </span>
    </div>
  );

  return (
    <>
      {isShowMoreSelectedPerSection[sectionTitle] ? (
        <ShowButton text={'Less'} />
      ) : (
        <ShowButton text={'More'} />
      )}
    </>
  );
};

export default ShowButtonToggle;
