import * as ListingVersionUpdates from './listing_version_updates_config';
import * as Overview from './overview_config';
import * as Highlights from './highlights_config';
import * as Features from './features_config';
import * as Support from './support_config';
import * as TermsOfUse from './termsOfUse_config';
import * as Plans from './plans_config';
import * as RegistrationDetails from './registration_details_config';
import * as ProvisioningInterface from './provisioning_interface_config';
import * as PartnerProgramRegistrationDetails from './partner_program_registration_details_config';
import * as SolutionVisibility from './solution_visibility_config';
import * as AdditionalDetails from './additional_details_config';
import * as StatusHistory from './status_history_config';
import * as subscriptionnotifications from './subscription_notifications_config';
import * as AppSubscriptions from './app_subscriptions_config';

export const FormConfig = {};
FormConfig[ListingVersionUpdates.config.formId] = ListingVersionUpdates.config;
FormConfig[Overview.config.formId] = Overview.config;
FormConfig[Highlights.config.formId] = Highlights.config;
FormConfig[Features.config.formId] = Features.config;
FormConfig[Support.config.formId] = Support.config;
FormConfig[TermsOfUse.config.formId] = TermsOfUse.config;
FormConfig[Plans.config.formId] = Plans.config;
FormConfig[PartnerProgramRegistrationDetails.config.formId] =
  PartnerProgramRegistrationDetails.config;
FormConfig[RegistrationDetails.config.formId] = RegistrationDetails.config;
FormConfig[subscriptionnotifications.config.formId] =
  subscriptionnotifications.config;
FormConfig[ProvisioningInterface.config.formId] = ProvisioningInterface.config;
FormConfig[SolutionVisibility.config.formId] = SolutionVisibility.config;
FormConfig[AdditionalDetails.config.formId] = AdditionalDetails.config;
FormConfig[StatusHistory.config.formId] = StatusHistory.config;
FormConfig[AppSubscriptions.config.formId] = AppSubscriptions.config;
