import React from "react";
import { Dialog, DialogBody, DialogHeader } from "@cdk-uip/react-dialog";
import { HorizontalLinearStepper } from "@cdk-uip/react-stepper";
import Spinner from "./Spinner";

const LoadingStepper = ({
  customClassName,
  open,
  title,
  steps,
  activeStep,
  finished
}) => {
  return (
    <div
      className={`default-fortellis-dialog loading-spinner-fortellis-dialog ${customClassName
        ? customClassName
        : ""}`}
    >
      <Dialog open={open}>
        {title && (
          <DialogHeader className="loading-stepper-title">{title}</DialogHeader>
        )}
        <DialogBody>
          <HorizontalLinearStepper
            steps={steps}
            activeStep={activeStep}
            finished={finished}
          />
        </DialogBody>

        <Spinner customClass="stepper-loading-spinner" />
      </Dialog>
    </div>
  );
};

export default LoadingStepper;
