import navConstants from './left-nav-config-constants';
export class LeftNavConfig {
  static MenuItems = {
    contents: [
      {
        title: 'Version and Insights',
        steps: [
          {
            id: 'manageListing',
            label: 'Manage Listing Versions',
            type: 'simple'
          },
          {
            id: 'insights',
            label: 'Insights',
            type: 'simple'
          },
          {
            id: 'appActivation',
            label: 'App Activation',
            type: 'simple'
          }
        ]
      },
      {
        title: 'Activity',
        steps: [
          {
            id: 'leads',
            label: 'Leads',
            type: 'simple'
          },
          {
            id: 'reviews',
            label: 'Reviews',
            type: 'simple'
          },
          {
            id: 'subscriptions',
            label: 'Subscriptions',
            type: 'simple'
          },
          {
            id: 'utilization',
            label: 'Utilization',
            type: 'simple'
          }
        ]
      },
      {
        title: navConstants.REMITTANCE,
        steps: [
          {
            id: navConstants.SETUP_BILLING,
            label: 'Accounts',
            type: 'simple'
          },
          {
            id: 'PaymentHistory',
            label: 'Payment History',
            type: 'simple'
          },
          {
            id: 'PaymentMethod',
            label: 'Payment Method',
            type: 'simple'
          }
        ]
      }
    ],
    activeMenuId: 'manageListing'
  };
}

export default LeftNavConfig;
