import SolutionList from '../components/storefront/SolutionList';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
const SOLUTION_LIMIT = 12;
const SORT_BY_CRITERIA = 'name';
const OFFSET = null;

export const query = gql`
    query($sortBy: String, $offset: String, $category: String) {
        paginatedSolutionCatalogList(
            filters: {
                sortBy: $sortBy
                offset: $offset
                limit: ${SOLUTION_LIMIT}
                category: $category
                access: "logged"
            }
        ) {
            solutions {
                id
                isFeatured
                listingVersion
                developerId
                orgId
                status
                solution_visibility {
                    solutionVisibilityType
                    privateSolutionUsers
                }
                overview {
                    name
                    briefDescription
                    solutionLogo
                    category
                    featuredImage
                }
            }
            pageInfo{
                offset
            }
        }
        featuredSolutions{
            id
            isFeatured
            listingVersion
            developerId
            orgId
            status
            solution_visibility {
                solutionVisibilityType
                privateSolutionUsers
            }
            overview {
                name
                briefDescription
                solutionLogo
                category
                featuredImage
            }
        }
    }
`;

export const fetchPublishedSolutionsQuery = gql`
    query($sortBy: String, $offset: String, $category: String) {
        paginatedSolutionCatalogList(
            filters: {
                sortBy: $sortBy
                offset: $offset
                limit: ${SOLUTION_LIMIT}
                category: $category
                access: "logged"
            }
        ) {
            solutions {
                id
                isFeatured
                listingVersion
                developerId
                orgId
                status
                solution_visibility {
                    solutionVisibilityType
                    privateSolutionUsers
                }
                overview {
                    name
                    briefDescription
                    solutionLogo
                    category
                    featuredImage
                }
            }
            pageInfo{
                offset
            }
        }
    }
`;

export const mapDataToProps = ({ data }) => ({
  list: data.paginatedSolutionCatalogList || [],
  featuredSolutionsList: data.featuredSolutions || [],
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps,
  options: () => ({
    variables: {
      sortBy: SORT_BY_CRITERIA,
      offset: OFFSET
    }
  })
})(SolutionList);
