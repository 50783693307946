import React from 'react';
import { Subheading2 } from '@cdk-uip/react-typography';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import Spinner from '../../common/Spinner';
import { Link } from 'react-router-dom';
import '../../solution-details/SolutionDetails.scss';
import Cogs from '../../common/images/cogs.png';
import { injectIntl } from 'react-intl';

class SolutionLogo extends React.Component {
  render() {
    const { intl, solution, loading } = this.props;
    return loading ? (
      <Spinner />
    ) : solution ? (
      <LayoutGrid className="padding-less-grid">
        <LayoutGridCell span={3}>
          {solution.overview && solution.overview.solutionLogo ? (
            <Link to={`/solutions/${solution.id}`}>
              <img
                src={`${solution.overview.solutionLogo}?${Math.random()}`}
                alt={intl.formatMessage({
                  id: 'SolutionLogo.admin_review_logo.alt',
                  defaultMessage: 'logo'
                })}
                width="100px"
                height="100px"
                className="admin-review__logo"
              />
            </Link>
          ) : (
            <Link to={`/solutions/${solution.id}`}>
              <img
                src={Cogs}
                alt={intl.formatMessage({
                  id: 'SolutionLogo.admin_review_logo.alt',
                  defaultMessage: 'logo'
                })}
                className="admin-review__logo"
              />
            </Link>
          )}
        </LayoutGridCell>
        <LayoutGridCell span={9}>
          {solution.overview && solution.overview.name && (
            <Subheading2 className="review__sol-name">
              {solution.overview.name}
            </Subheading2>
          )}
        </LayoutGridCell>
      </LayoutGrid>
    ) : (
      false
    );
  }
}

export default injectIntl(SolutionLogo);
