import React from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';

import { injectIntl } from 'react-intl';
import Rating from './Rating';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import PermissionConstants from '../../common/constants/PermissionConstants';
import {
  Input,
  TextArea,
  TextButton,
  Dialog,
  BUTTON_VARIANTS
} from 'cdk-radial';
import { sendAmplitudeData } from '../../../utils/amplitude';
import {
  AMPLITUDE_PROPERTIES,
  AMPLITUDE_EVENTS
} from '../../../utils/amplitude-constants';

class AddReview extends React.Component {
  state = {
    openReviewInputs: false,
    submittedDialogOpen: false,
    review: {
      authorName:
        this.props.auth.isAuthenticated && this.props.auth.userData
          ? this.props.auth.userData.name
          : '',
      authorEmail:
        this.props.auth.isAuthenticated && this.props.auth.userData
          ? this.props.auth.userData.email
          : '',
      solutionId: this.props.solutionId,
      listingVersion: this.props.listingVersion
        ? this.props.listingVersion
        : '1',
      solutionVersion: this.props.solutionVersion,
      rating: null,
      reviewTitle: null,
      reviewText: null,
      entityId: this.props.auth.entityId
    },
    isErrorDialogOpen: false,
    dialogErrorMessage: ''
  };

  showSubmittedDialog = () => {
    this.setState({ submittedDialogOpen: true });
  };

  closeSubmittedDialog = () => {
    this.setState({ submittedDialogOpen: false });
  };

  createAndSubmitAmplitudedata = eventName => {
    const { solutionId, solutionName, entity } = this.props;
    let amplitudeData = {
      [AMPLITUDE_PROPERTIES.APP_ID]: solutionId,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solutionName,
      [AMPLITUDE_PROPERTIES.ORG_ID]: entity?.id || 'N/A',
      [AMPLITUDE_PROPERTIES.ORG_NAME]: entity?.name || 'N/A'
    };
    sendAmplitudeData(eventName, amplitudeData);
  };

  onRatingChange = rating => {
    const { auth } = this.props;
    let { reviewsForSolution } = this.props;
    this.createAndSubmitAmplitudedata(AMPLITUDE_EVENTS.APP_REVIEW);
    if (!auth.isAuthenticated) {
      this.props.auth.login({ return_url: window.location.pathname });
    } else {
      if (reviewsForSolution.length === 0) {
        setTimeout(() => {
          reviewsForSolution = this.props.reviewsForSolution;
          this.setReviewData(rating, auth, reviewsForSolution);
        }, 4000);
      } else {
        this.setReviewData(rating, auth, reviewsForSolution);
      }
    }
  };

  setReviewData = (rating, auth, reviewsForSolution) => {
    const userApprovedCommentLength =
      !!reviewsForSolution &&
      !!auth.userData &&
      !!auth.userData.email &&
      reviewsForSolution.filter(
        f =>
          f.authorEmail === auth.userData.email &&
          f.status.toUpperCase() === 'APPROVED' &&
          f.entityId === auth.entityId
      ).length;
    const userSubmittedCommentLength =
      !!reviewsForSolution &&
      !!auth.userData &&
      !!auth.userData.email &&
      reviewsForSolution.filter(
        f =>
          f.authorEmail === auth.userData.email &&
          f.status.toUpperCase() === 'SUBMITTED' &&
          f.entityId === auth.entityId
      ).length;

    if (userSubmittedCommentLength > 0) {
      let errorId = 'App.Review.AlreadySubmitted';

      this.showError({
        error: this.props.intl.formatMessage(
          { id: errorId },
          { defaultMessage: 'Not enough permissions to perform this action!' }
        )
      });
    }

    if (userApprovedCommentLength + userSubmittedCommentLength === 0) {
      let reviewData = JSON.parse(JSON.stringify(this.state.review));
      reviewData.rating = rating;
      this.setState({ review: reviewData, openReviewInputs: true });
    }
  };

  showError = ({ error }) => {
    this.setState({ isErrorDialogOpen: true, dialogErrorMessage: error });
  };

  closeError = () => {
    this.setState({ isErrorDialogOpen: false, dialogErrorMessage: '' });
  };

  onSubmit = () => {
    if (
      this.props.userPermissions &&
      this.props.userPermissions.includes(PermissionConstants.ADD_REVIEW) &&
      !!this.state.review.reviewTitle &&
      this.state.review.reviewText
    ) {
      this.createAndSubmitAmplitudedata(AMPLITUDE_EVENTS.APP_REVIEW_SUBMITTED);
      this.callCreateReviewMutation(this.state.review).then(response => {
        if (!response.errors) {
          this.onCancel();
          this.showSubmittedDialog();
          this.props.onAddReviewToSolution(this.state.review);
        }
      });
    } else {
      let errorId = '';
      errorId = !this.state.review.reviewTitle ? 'App.Review.Title' : '';
      errorId =
        errorId || (!this.state.review.reviewText ? 'App.Review.Comment' : '');
      errorId = errorId || 'Generic.permission.error';

      this.showError({
        error: this.props.intl.formatMessage(
          { id: errorId },
          { defaultMessage: 'Not enough permissions to perform this action!' }
        )
      });
    }
  };

  callCreateReviewMutation = async review => {
    const client = getApolloClient();
    return await client.mutate({
      mutation: createReviewMutation,
      variables: { review: review }
    });
  };

  onCancel = () => {
    this.refs.userRating.reset();
    let reviewData = JSON.parse(JSON.stringify(this.state.review));
    reviewData.rating = null;
    reviewData.reviewTitle = null;
    reviewData.reviewText = null;
    this.setState({
      openReviewInputs: false,
      review: reviewData
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated && !prevProps.auth.isAuthenticated) {
      this.setState({
        review: {
          authorName:
            this.props.auth.isAuthenticated && this.props.auth.userData
              ? this.props.auth.userData.name
              : '',
          authorEmail:
            this.props.auth.isAuthenticated && this.props.auth.userData
              ? this.props.auth.userData.email
              : '',
          solutionId: this.props.solutionId,
          listingVersion: this.props.listingVersion
            ? this.props.listingVersion
            : '1',
          solutionVersion: this.props.solutionVersion,
          rating: null,
          reviewTitle: null,
          reviewText: null,
          entityId: this.props.auth.entityId
        }
      });
    }
  }

  render() {
    const { solutionName } = this.props;

    return (
      <div id="add-review" className="add-review-container">
        <div className="add-a-review-for-app">
          {this.props.numberOfReviews > 0
            ? `Add a review for ${solutionName}`
            : `Be the first to review ${solutionName}`}
        </div>

        <div>
          <Rating
            addReview={true}
            onRatingChange={this.onRatingChange}
            customClass="add-rating"
            ref="userRating"
          />
        </div>

        {this.state.openReviewInputs && (
          <div className="review-form">
            <Input
              dataTestId="review-input"
              id="review-input"
              label="Enter a title for your review"
              maxLength=""
              onChange={e => {
                let reviewData = JSON.parse(JSON.stringify(this.state.review));
                reviewData.reviewTitle =
                  e.target.value === '' ? null : e.target.value;
                this.setState({ review: reviewData });
              }}
              value={
                this.state.review.reviewTitle
                  ? this.state.review.reviewTitle
                  : ''
              }
            />

            <TextArea
              dataTestId="review-textarea"
              label="Type your review below"
              rows="5"
              maxLength=""
              value={
                this.state.review.reviewText ? this.state.review.reviewText : ''
              }
              onChange={e => {
                let reviewData = JSON.parse(JSON.stringify(this.state.review));
                reviewData.reviewText =
                  e.target.value === '' ? null : e.target.value;
                this.setState({ review: reviewData });
              }}
            />

            <div style={{ minHeight: '45px' }}>
              <TextButton
                style={{ float: 'right', margin: '10px' }}
                dataTestId="review-submit-button"
                iconColor="primary"
                onClick={this.onSubmit}
                text="SUBMIT"
              />
              <TextButton
                style={{ float: 'right', margin: '10px' }}
                dataTestId="review-cancel-button"
                iconColor="primary"
                onClick={this.onCancel}
                text="CANCEL"
              />
            </div>
          </div>
        )}

        <Dialog
          buttonsProps={[
            {
              id: 'add-review-confirm-button',
              onClick: () => {
                this.createAndSubmitAmplitudedata(
                  AMPLITUDE_EVENTS.APP_REVIEW_SUBMISSION_SUCCESSFUL
                );
                this.closeSubmittedDialog();
              },
              text: 'OK',
              variant: BUTTON_VARIANTS.TEXT
            }
          ]}
          id="add-review-dialog"
          isOpen={this.state.submittedDialogOpen}
          hideHeader
          acceptDataCy={'btn_dialog_addreview_ok'}
        >
          <div>
            <span className="review-delete-subheading">Thank you!</span>
            <span className="review-delete-text">
              Your review has been submitted and will be posted following
              moderator approval.
            </span>
          </div>
        </Dialog>

        {/*Generic error dialog, use methods showError, closeError to use this dialog*/}

        <Dialog
          buttonsProps={[
            {
              id: 'add-review-error-button',
              onClick: this.closeError,
              text: 'OK',
              variant: BUTTON_VARIANTS.TEXT
            }
          ]}
          id="error-review-dialog"
          isOpen={this.state.isErrorDialogOpen}
          hideHeader
        >
          <div>
            <span className="review-delete-subheading">
              {this.state.dialogErrorMessage}
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const createReviewMutation = gql`
  mutation($review: createReviewCmd) {
    createReview(command: $review) {
      id
      authorName
      authorEmail
      solutionId
      rating
      reviewText
      reviewTitle
      createdDate
      status
      entityId
    }
  }
`;

export default injectIntl(withApollo(withAuth(AddReview)));
