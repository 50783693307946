import React from "react";
import NumberFormat from "react-number-format";

const phoneNumber = props => {
  return (
    <NumberFormat
      value={props.value}
      displayType={"text"}
      format="(###)###-####"
      {...props}
    />
  );
};

export default phoneNumber;
