import React from 'react';
import { Dialog, DialogBody } from '@cdk-uip/react-dialog';
import Spinner from './Spinner';

class FortellisDialogSpinner extends React.Component {
  render() {
    const { open, customClassName } = this.props;
    return (
      <div
        className={`default-fortellis-dialog ${
          customClassName ? customClassName : ''
        }`}
      >
        <Dialog open={open}>
          <DialogBody>
            <div>
              <Spinner />
              <div style={{ textAlign: 'center' }}>
                <strong>Loading...</strong>
              </div>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    );
  }
}

export default FortellisDialogSpinner;
