import React from 'react';

const Loading = ({ loaderClass, circleClass }) => {
  return (
    <div className={`${loaderClass}`}>
      <div className={`circle ${circleClass}`} />
      <div className={`circle ${circleClass}`} />
      <div className={`circle ${circleClass}`} />
    </div>
  );
};

export default Loading;
