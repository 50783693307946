import React, { useState, useEffect } from 'react';
import SolutionDetailsScreen from '../components/solution-details/SolutionDetailsScreen';
import StoreListingAPI from '../api/StoreListingAPI';
import SolutionNotAccessible from '../components/common/SolutionNotAccessible';

function SolutionDetailsAnonScreen(props) {
  const [loading, setLoading] = useState(true);
  const [anonSolution, setSolution] = useState(null);
  const [isDataFetched, setDataFetched] = useState(false);

  function processData({ data }) {
    return {
      solution:
        data && data.solutionCatalogList ? data.solutionCatalogList[0] : null,
      averageRating:
        data &&
        data.getRatingStatistics &&
        data.getRatingStatistics.averageRating
          ? data.getRatingStatistics.averageRating
          : null,
      numberOfReviews:
        data &&
        data.getRatingStatistics &&
        data.getRatingStatistics.numberOfReviews
          ? data.getRatingStatistics.numberOfReviews
          : null,
      existingSubscriptionByEntity:
        data &&
        data.solutionSubscriptions &&
        data.solutionSubscriptions.length > 0 &&
        data.solutionCatalogList &&
        data.solutionSubscriptions.filter(
          sub => sub.solutionId === data.solutionCatalogList[0].id
        ).length
          ? data.solutionSubscriptions.filter(
              sub => sub.solutionId === data.solutionCatalogList[0].id
            )[0]
          : null,
      loading: false
    };
  }
  function getSolutionsForAnon() {
    setLoading(true);
    let id = props.isOldUrl
      ? props.match.params.id
      : new URLSearchParams(
          props && props.location && props.location.search
        ).get('id');
    if (id) {
      StoreListingAPI.anonymous
        .getSolutionById(id, { entityId: props.entity.id })
        .then(fetchedSolution => {
          const fetchedSolutionData =
            fetchedSolution && fetchedSolution.data
              ? fetchedSolution.data
              : null;

          setSolution(processData(fetchedSolutionData));
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isDataFetched) {
      getSolutionsForAnon();
      setDataFetched(true);
    }
  });

  const anonSolutionView = anonSolution ? anonSolution.solution : null;
  if (!loading && isDataFetched && !anonSolutionView) {
    return (
      <div className="component-content">
        <SolutionNotAccessible />
      </div>
    );
  } else {
    return <SolutionDetailsScreen {...anonSolution} loading={loading} />;
  }
}

export default SolutionDetailsAnonScreen;
