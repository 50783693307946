import React from 'react';
import { Grid, Row, Column } from 'cdk-radial';
import { FormattedMessage } from 'react-intl';
import { environmentURLs } from '../common/environment/CaptureEnvironment';

class HowToSell extends React.Component {
  render() {
    return (
      <div className="max-width-resp page-padding-common">
        <div className="sell-solution-section-header">
          <FormattedMessage
            id="SellSolution.helpSection.title"
            defaultMessage="How to Sell Your App"
          />
        </div>

        <Grid className="how-to-sell-grid">
          <Row>
            <Column>
              <div className="inline-block summary-sell-page">
                <div className="icon-background-circle">
                  <span className="material-icons icon-sell-page">edit</span>
                </div>
                <div className="summary-title-sell-page">
                  Register for Fortellis Marketplace
                </div>
                <div className="summary-desc-sell-page">
                  <a
                    href={environmentURLs.registerYourOrgDocsURLSell}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className="highlighted-text"
                      data-qa="register-your-org"
                    >
                      Register your organization
                    </span>
                  </a>
                  . Become a Marketplace seller.{' '}
                  <a
                    href={environmentURLs.accountsURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="highlighted-text" data-qa="sign-up">
                      Sign up
                    </span>
                  </a>{' '}
                  today!
                </div>
              </div>
            </Column>
            <Column>
              <div className="inline-block summary-sell-page">
                <div className="icon-background-circle">
                  <span className="material-icons icon-sell-page">
                    check_circle
                  </span>
                </div>
                <div className="summary-title-sell-page">
                  Build Your Product
                </div>
                <div className="summary-desc-sell-page">
                  Leverage existing{' '}
                  <a
                    href={environmentURLs.api_reference_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className="highlighted-text"
                      data-qa="implement-your-apis"
                    >
                      APIs, implement your own
                    </span>
                  </a>{' '}
                  and{' '}
                  <a
                    href={environmentURLs.gettingStartedDocsURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="highlighted-text" data-qa="build-apps">
                      build innovative apps
                    </span>
                    .
                  </a>
                </div>
              </div>
            </Column>
            <Column>
              <div className="inline-block summary-sell-page">
                <div className="icon-background-circle">
                  <span className="material-icons icon-sell-page">
                    local_offer
                  </span>
                </div>
                <div className="summary-title-sell-page">Start Selling</div>
                <div className="summary-desc-sell-page">
                  Maximize your listing's impact and Go-To-Market benefits to
                  sell on the{' '}
                  <a
                    href={environmentURLs.marketplaceURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="highlighted-text" data-qa="marketplace">
                      Marketplace
                    </span>
                    .
                  </a>
                </div>
              </div>
            </Column>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default HowToSell;
