import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import { Card } from '@cdk-uip/react-card';
import { Button } from '@cdk-uip/react-button';
import Spinner from '../../../../common/Spinner';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import moment from 'moment';
import EmptyContent from '../../../../common/images/Empty State 2x.png';
import ListingFilters from './ListingFilters';
import SearchComponent from '../../../../common/SearchComponent';
import { FortellisConstants } from '../../../../common/FortellisConstants';
import {FormattedMessage} from "react-intl";
import LearnMore from "../../../../common/LearnMore/LearnMore";
import {environmentURLs} from "../../../../common/environment/CaptureEnvironment";

class Leads extends React.Component {
  state = {
    tabIndex: 1,
    loadMore: false,
    solutionLeads: [],
    offset: '',
    fromDate: null,
    sortCol: 0,
    solutionInformation: {},
    leadGenerationDateDescSortOrder: true,
    fetchingData: true,
    initializing: true,
    sorting: false,
    filteredLeads: [],
    selectedSolutionVersion: FortellisConstants.SHOW_ALL,
    selectedListingVersion: FortellisConstants.SHOW_ALL,
    solutionVersionToListingVersionMap: {}
  };

  fetchLeads = async () => {
    const client = getApolloClient();
    this.setState({ fetchingData: true });
    let leadsResponse = await client.query({
      query: getLeads,
      variables: {
        limit: 8,
        offset: this.state.offset,
        fromDate: this.state.fromDate,
        leadGenerationDateDescSortOrder: this.state
          .leadGenerationDateDescSortOrder,
        solutionId: this.props.solutionId
      }
    });
    if (
      leadsResponse &&
      leadsResponse.data &&
      leadsResponse.data.solutionLeads &&
      leadsResponse.data.solutionLeads.leads
    ) {
      let currLeads = this.state.solutionLeads;
      if (currLeads && currLeads.length > 0) {
        this.setState({
          solutionLeads: currLeads.concat(
            leadsResponse.data.solutionLeads.leads
          )
        });
      } else {
        this.populateFilterOptions(leadsResponse.data.solutionLeads.leads);
        this.setState({
          solutionLeads: leadsResponse.data.solutionLeads.leads
        });
      }
      this.onSearchChange('');
      this.setState({
        offset: leadsResponse.data.solutionLeads.pageInfo
          ? leadsResponse.data.solutionLeads.pageInfo.offset
          : null
      });
      this.setState({ fetchingData: false });
    }
  };

  getSolutionDetails = async solutionId => {
    const client = getApolloClient();
    this.setState({ initializing: true });
    let solutionDetailsResponse = await client.query({
      query: solutionDetails,
      variables: {
        solutionId: solutionId
      }
    });
    if (
      solutionDetailsResponse &&
      solutionDetailsResponse.data &&
      solutionDetailsResponse.data.solutionList &&
      solutionDetailsResponse.data.solutionList.length > 0
    ) {
      let solutionData = solutionDetailsResponse.data.solutionList[0];
      this.setState({ solutionInformation: solutionData.overview });
    }
    await this.fetchLeads();
    this.setState({ initializing: false });
  };
  onSort = async sortCol => {
    this.setState({ sorting: true });
    await this.setState({
      sortCol: sortCol,
      solutionLeads: [],
      offset: '',
      leadGenerationDateDescSortOrder: !this.state
        .leadGenerationDateDescSortOrder
    });
    await this.fetchLeads();
    this.setState({ sorting: false });
  };

  sortDirection(col) {
    return this.state.sortCol === col
      ? this.state.leadGenerationDateDescSortOrder
        ? 'desc'
        : 'asc'
      : 'none';
  }

  componentDidMount() {
    this.getSolutionDetails(this.props.solutionId);
  }

  populateFilterOptions(solutionLeads) {
    solutionLeads = solutionLeads || [];
    let solutionVersionToListingVersionMap = {
      All: [FortellisConstants.SHOW_ALL]
    };

    //adding all unique listing versions to "All"
    solutionVersionToListingVersionMap[FortellisConstants.SHOW_ALL] = [
      FortellisConstants.SHOW_ALL,
      ...new Set(solutionLeads.map(lead => lead.listingVersion))
    ];

    //adding solutionVersions and respective listing versions in solutionVersionToListingVersionMap
    solutionLeads.forEach(lead => {
      if (lead.solutionVersion !== null) {
        solutionVersionToListingVersionMap[
          lead.solutionVersion
        ] = solutionVersionToListingVersionMap[lead.solutionVersion] || [
          FortellisConstants.SHOW_ALL
        ];

        solutionVersionToListingVersionMap[lead.solutionVersion].push(
          lead.listingVersion
        );
      }
    });

    //making listing version array for each solution version unique
    for (let key in solutionVersionToListingVersionMap) {
      solutionVersionToListingVersionMap[key] = [
        ...new Set(solutionVersionToListingVersionMap[key])
      ];
    }
    this.setState({
      solutionVersionToListingVersionMap: solutionVersionToListingVersionMap
    });
  }

  onFilterChange(solutionVersion, listingVersion) {
    this.setState({ selectedSolutionVersion: solutionVersion });
    this.setState({ selectedListingVersion: listingVersion });
  }

  matchesFilterCondition(solutionLead) {
    if (
      this.state.selectedSolutionVersion &&
      this.state.selectedSolutionVersion !== FortellisConstants.SHOW_ALL &&
      solutionLead.solutionVersion !== this.state.selectedSolutionVersion
    ) {
      return false;
    }
    if (
      this.state.selectedListingVersion &&
      this.state.selectedListingVersion !== FortellisConstants.SHOW_ALL &&
      solutionLead.listingVersion !== this.state.selectedListingVersion
    ) {
      return false;
    }
    return true;
  }

  onSearchChange(val) {
    val = (val + '').trim();
    let filteredLeads = [];
    let regex = new RegExp('.*' + val + '.*', 'gi');
    this.state.solutionLeads.map(lead => {
      if (
        lead.userName.match(regex) ||
        lead.leadEmail.match(regex) ||
        lead.message.match(regex) ||
        (moment(lead.leadGenerationDate).format('DD MMM YYYY') + '').match(
          regex
        )
      ) {
        filteredLeads.push(lead);
      }
      return lead;
    });
    this.setState({ filteredLeads: filteredLeads });
  }

  render() {
    return (
      <div>
        <div className={"api-info-subtitle"}>
          <FormattedMessage
            id="Leads.helpText"
            defaultMessage={`Displays a list of registered Fortellis users who want additional information about your app listing.`}
          />
          &nbsp;
          <LearnMore
            url={`${
              environmentURLs.docs
            }docs/tutorials/app-lifecycle/managing-app-activations/#viewing-leads`}
          />
          <br />
          <br />
        </div>
        <Card>
          {this.state.initializing ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <div>
              {this.state.sorting ? (
                <div>
                  <Spinner />
                </div>
              ) : this.state.solutionLeads &&
                this.state.solutionLeads.length > 0 ? (
                <div>
                  <div>
                    <div className="lead-search-parent">
                      <SearchComponent
                        className="lead-search"
                        label="Search"
                        onChange={this.onSearchChange.bind(this)}
                      />
                    </div>
                    <div className="listing-filters-parent">
                      <ListingFilters
                        optionMapping={
                          this.state.solutionVersionToListingVersionMap
                        }
                        selectedSolutionVersion={
                          this.state.selectedSolutionVersion
                        }
                        selectedListingVersion={
                          this.state.selectedListingVersion
                        }
                        onFilterChange={this.onFilterChange.bind(this)}
                        className="pull-right lead-filters"
                      />
                    </div>
                  </div>
                  <div className="leads-table-container">
                    <DataTable sortable className={"table-list"}>
                      <DataTableHeader>
                        <DataTableRow>
                          <DataTableHeaderCell
                            nonNumeric
                            sortable
                            sortDirection={this.sortDirection(0)}
                            onClick={() => this.onSort(0)}
                            className="leads-header-column leads-sort-header-column"
                          >
                            Date
                          </DataTableHeaderCell>
                          <DataTableHeaderCell className="leads-header-column">
                            Lead
                          </DataTableHeaderCell>
                          <DataTableHeaderCell className="leads-header-column">
                            Email
                          </DataTableHeaderCell>
                          <DataTableHeaderCell className="leads-header-column">
                            Message
                          </DataTableHeaderCell>
                        </DataTableRow>
                      </DataTableHeader>
                      <DataTableBody>
                        {this.state.filteredLeads &&
                          this.state.filteredLeads.map((lead, leadIdx) => {
                            if (!this.matchesFilterCondition(lead)) {
                              return null;
                            }
                            return (
                              <DataTableRow className="lead-row" key={leadIdx}>
                                <DataTableCell style={{ textAlign: "left" }}>
                                  {moment(lead.leadGenerationDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </DataTableCell>
                                <DataTableCell style={{ textAlign: "left" }}>
                                  {lead.userName}
                                </DataTableCell>
                                <DataTableCell style={{ textAlign: "left" }}>
                                  <a href={`mailto:${lead.leadEmail}`}>
                                    {lead.leadEmail}
                                  </a>
                                </DataTableCell>
                                <DataTableCell style={{ textAlign: "left" }}>
                                  <div className="lead-message-with-tooltip">
                                    <div
                                      className={
                                        lead.message &&
                                        lead.message.length > 100
                                          ? `lead-message cursor-pointer`
                                          : `lead-message`
                                      }
                                      id={`tooltip_${leadIdx}`}
                                    >
                                      {lead.message && lead.message.length > 100
                                        ? lead.message.substring(0, 100) + "..."
                                        : lead.message}
                                    </div>

                                    {lead.message && lead.message.length > 100 && (
                                      <Tooltip
                                        htmlFor={`tooltip_${leadIdx}`}
                                        className="fortellis-field-info"
                                      >
                                        {lead.message}
                                      </Tooltip>
                                    )}
                                  </div>
                                </DataTableCell>
                              </DataTableRow>
                            );
                          })}

                        {this.state.fetchingData && (
                          <DataTableRow
                            className="lead-row"
                            id="data-table-row-loading"
                          >
                            <DataTableCell colSpan={4}>
                              <div>
                                <Spinner />
                              </div>
                            </DataTableCell>
                          </DataTableRow>
                        )}

                        {this.state.offset && (
                          <DataTableRow className="lead-row">
                            <DataTableCell colSpan={4}>
                              <Button
                                className={"show-more-leads"}
                                onClick={this.fetchLeads}
                              >
                                Show More Leads
                              </Button>
                            </DataTableCell>
                          </DataTableRow>
                        )}
                      </DataTableBody>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="empty-content-div">
                  <img
                    alt="Empty Content"
                    className="empty-content-card-img"
                    src={EmptyContent}
                  />
                  <div className="empty-content-card-message">
                    No leads yet, Check back soon!
                  </div>
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const getLeads = gql`
  query(
    $limit: Float
    $offset: String
    $fromDate: String
    $leadGenerationDateDescSortOrder: Boolean
    $solutionId: ID
  ) {
    solutionLeads(
      filter: {
        limit: $limit
        offset: $offset
        fromDate: $fromDate
        leadGenerationDateDescSortOrder: $leadGenerationDateDescSortOrder
        solutionId: $solutionId
      }
    ) {
      leads {
        leadGenerationDate
        leadEmail
        userName
        message
        solutionId
        solutionVersion
        listingVersion
      }
      pageInfo {
        offset
      }
    }
  }
`;

export const solutionDetails = gql`
  query($solutionId: ID) {
    solutionList(id: $solutionId) {
      id
      developerName
    }
  }
`;
export default Leads;
