import React from 'react';

import {
  ExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelContentBody,
  ExpansionPanelGroup,
  ExpansionPanelHeader,
  ExpansionPanelHeaderTitle
} from '@cdk-uip/react-expansion-panel';

import {
  DrawerList,
  DrawerListItem,
  PermanentDrawer,
  TemporaryDrawerHeader,
  TemporaryDrawerHeaderContent
} from '@cdk-uip/react-drawer';

export class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.sideNav;
  }

  handleMenuStateChange = data => {
    let menuState = this.state;
    for (let key in data) {
      menuState[key] = data[key];
    }
    this.props.handleSideNavStateChange(menuState);
  };

  togglePanel = (name, value) => {
    let newValue = '';
    if (this.state[name] !== value) {
      newValue = value;
    }
    this.props.handleToggleChange(newValue);
  };
  deleteMenuItem = () => {
    this.props.deleteMenuItem();
  };

  handleDragStart = (e, data, step, labelIndex) => {
    e.dataTransfer.dropEffect = 'move';
    //e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData('draggedItemIndex', labelIndex);
    this.draggedStepId = data.id;
  };

  handleOnDragOver = e => {
    e.preventDefault();
    //e.dataTransfer.dropEffect = "move"
    // e.target.style.opacity=0.08;
    //e.target.style.backgroundColor="#904778"
  };

  handleOnDrop = (e, data, step, labelIndex, nestedStep) => {
    const draggedItemIndex = e.dataTransfer.getData('draggedItemIndex');

    //swap array items in MenuConfig
    let dataFromArray = JSON.parse(
      JSON.stringify(this.state[nestedStep.dataFrom])
    );
    let temp = JSON.parse(JSON.stringify(dataFromArray[draggedItemIndex]));
    dataFromArray[draggedItemIndex] = JSON.parse(
      JSON.stringify(dataFromArray[labelIndex])
    );
    dataFromArray[labelIndex] = temp;

    //updating MenuBuilder-ids for swapped array items in MenuConfig
    dataFromArray[draggedItemIndex].id = this.draggedStepId;
    dataFromArray[labelIndex].id = data.id;

    this.handleMenuStateChange({
      [nestedStep.dataFrom]: dataFromArray,
      activeMenuId: step.id,
      nestedActiveMenuId: data.id
    });

    //handleSideNavArrayReorder(activeMenuId, dataFromArray, draggedItemId, droppedItemId)
    this.props.handleSideNavArrayReorder(
      step.id,
      dataFromArray,
      this.draggedStepId,
      data.id
    );

    this.draggedStepId = null;
  };

  getExpansion = (data, step, labelIndex, nestedStep) => {
    return (
      <DrawerListItem
        className="side-nav-item"
        draggable={data.type === 'dynamic'}
        onDragStart={e => this.handleDragStart(e, data, step, labelIndex)}
        onDragOver={this.handleOnDragOver}
        onDrop={e => {
          this.handleOnDrop(e, data, step, labelIndex, nestedStep);
        }}
        //onDragEnter={e=>{e.target.style.opacity=0.08; e.target.style.backgroundColor="#904778"}}
        //onDragLeave={e=>{e.target.style.opacity=1; e.target.style.backgroundColor="#fff"}}
        data-cy={`side_menu_${data.label}`}
        selected={
          this.state.nestedActiveMenuId === data.id &&
          this.state.activeMenuId === step.id
        }
        key={labelIndex}
        onClick={e => {
          e.preventDefault();
          this.handleMenuStateChange({
            activeMenuId: step.id,
            nestedActiveMenuId: data.id
          });
        }}
      >
        {data.labelIcon ? (
          <span>
            <i className="material-icons menuIcon">{data.labelIcon}</i>
            <span className="fm-menu-item-with-icon">{data.label}</span>
          </span>
        ) : (
          data.label
        )}
        <span />
      </DrawerListItem>
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sideNav !== prevState) {
      return nextProps.sideNav;
    }
    return null;
  }

  render() {
    return (
      <PermanentDrawer>
        {this.state.contents.map((menuItem, menuIndex) => {
          return menuItem.showIf === false ? (
            false
          ) : (
            <div className="drawerContent" key={menuIndex}>
              <TemporaryDrawerHeader>
                <TemporaryDrawerHeaderContent>
                  {menuItem.title}
                </TemporaryDrawerHeaderContent>
              </TemporaryDrawerHeader>
              <DrawerList className="list-menu">
                {menuItem.steps.map((step, stepIndex) => {
                  return step.type === 'link' ? (
                    <a
                      href={step.href}
                      className="solution-review__developerAgreement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Publisher Agreement{' '}
                      <i className="material-icons external-link-icon">
                        open_in_new
                      </i>
                    </a>
                  ) : step.type === 'expansion' ? (
                    <ExpansionPanelGroup
                      className="features-expansion feature-expansion-menu"
                      key={stepIndex}
                    >
                      <ExpansionPanel
                        expanded={this.state.expandedMenuId === step.id}
                      >
                        <ExpansionPanelHeader
                          onClick={e => {
                            e.preventDefault();
                            this.togglePanel('expandedMenuId', step.id);
                          }}
                        >
                          <ExpansionPanelHeaderTitle>
                            {step.label}
                          </ExpansionPanelHeaderTitle>
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent
                          style={
                            this.state.expandedMenuId === step.id
                              ? { height: '100%' }
                              : { height: '0px' }
                          }
                        >
                          <ExpansionPanelContentBody>
                            {step.nestedSteps.map(nestedStep => {
                              return nestedStep.type === 'array' ? (
                                this.state[nestedStep.dataFrom].map(
                                  (data, labelIndex) => {
                                    return (
                                      (data.showIf &&
                                        data.showIf(
                                          this.props.solutionFields
                                        ) &&
                                        this.getExpansion(
                                          data,
                                          step,
                                          labelIndex,
                                          nestedStep
                                        )) ||
                                      (!data.showIf &&
                                        this.getExpansion(
                                          data,
                                          step,
                                          labelIndex,
                                          nestedStep
                                        ))
                                    );
                                  }
                                )
                              ) : (
                                <DrawerListItem
                                  selected={
                                    this.state.nestedActiveMenuId ===
                                    nestedStep.id
                                  }
                                  onClick={e => {
                                    e.preventDefault();
                                    this.handleMenuStateChange({
                                      activeMenuId: step.id,
                                      nestedActiveMenuId: nestedStep.id
                                    });
                                  }}
                                >
                                  {nestedStep.labelIcon ? (
                                    <span>
                                      <i className="material-icons menuIcon">
                                        {nestedStep.labelIcon}
                                      </i>{' '}
                                      {nestedStep.label}
                                    </span>
                                  ) : (
                                    nestedStep.label
                                  )}
                                </DrawerListItem>
                              );
                            })}
                          </ExpansionPanelContentBody>
                        </ExpansionPanelContent>
                      </ExpansionPanel>
                    </ExpansionPanelGroup>
                  ) : step.showIf ? (
                    step.showIf(this.props.solutionFields) && (
                      <DrawerListItem
                        className="side-nav-item"
                        key={step.id}
                        selected={this.state.activeMenuId === step.id}
                        onClick={e => {
                          e.preventDefault();
                          this.togglePanel('expandedMenuId', '');
                          this.handleMenuStateChange({
                            activeMenuId: step.id,
                            nestedActiveMenuId: step.id
                          });
                        }}
                      >
                        {step.label}
                      </DrawerListItem>
                    )
                  ) : (
                    <DrawerListItem
                      className="side-nav-item"
                      data-cy={`side_menu_${step.label}`}
                      key={step.id}
                      selected={this.state.activeMenuId === step.id}
                      onClick={e => {
                        e.preventDefault();
                        this.togglePanel('expandedMenuId', '');
                        this.handleMenuStateChange({
                          activeMenuId: step.id,
                          nestedActiveMenuId: step.id
                        });
                      }}
                    >
                      {step.label}
                    </DrawerListItem>
                  );
                })}
              </DrawerList>
            </div>
          );
        })}
      </PermanentDrawer>
    );
  }
}

export default SideNav;
