export const config = {
  formId: 'overview',
  formTitle: 'Overview',
  formValueType: 'Object',
  tabs: [
    {
      id: 'overview',
      label: 'Overview',
      contents: [
        {
          id: 'overview',
          fields: [
            {
              name: 'name',
              label: 'App Name',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              required: true,
              helperText: 'App Name is required.'
            },
            {
              name: 'domain',
              label: 'App Domain',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              value: value => {
                return value ? value.replaceAll(',', ', ') : '';
              }
            },
            {
              name: 'category',
              label: 'App Category',
              type: 'static',
              style: 'formSelectfield',
              gridStyle: 'selectBox_cell'
            },
            {
              name: 'applicationArea',
              label: 'Application Area',
              type: 'static',
              maxLength: 40,
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'searchKeywords',
              label: 'Search Keywords',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              value: value => {
                return value ? value.join(', ').trim() : '';
              }
            },
            {
              name: 'version',
              label: 'Version',
              type: 'static',
              maxLength: 40,
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'briefDescription',
              label: 'Brief Description',
              type: 'static',
              style: ['formtextfield', 'form-text-area'],
              gridStyle: 'layoutcell',
              helperText: 'Description is required.'
            },
            {
              name: 'detailedDescription',
              label: 'Detailed Description',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'publisherName',
              label: 'Publisher Name',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              helperText: 'Organization Name is required.'
            },
            {
              name: 'website',
              label: 'Publisher Website',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'solutionWebsite',
              label: 'App Website',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'outageNotificationsEmail',
              label: 'Outage Notifications Email',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'copyright',
              label: 'Copyright',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell',
              helperText: 'Copyright Name is required.'
            },
            {
              name: 'privacyPolicyUrl',
              label: 'Privacy Policy URL',
              type: 'static',
              style: 'formtextfield',
              gridStyle: 'layoutcell'
            },
            {
              name: 'solutionLogo',
              label: 'App Logo',
              note: 'Recommended aspect ratio - 1:1 and size - 256x256.',

              type: 'static_image',
              style: 'static-form-image',
              gridStyle: 'layoutCellList'
            }
          ]
        }
      ]
    },
    {
      id: 'videos',
      label: 'Videos',
      contents: [
        {
          id: 'videos',
          fields: [
            {
              type: 'multiple_add_form',
              name: 'videos',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: function(status) {
                return false;
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: 'title',
                        label: 'Title',
                        type: 'static',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell'
                      },
                      {
                        name: 'url',
                        label: 'URL',
                        type: 'static',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell'
                      },
                      {
                        name: 'description',
                        label: 'Description',
                        type: 'static',
                        style: 'formtextfield',
                        gridStyle: 'layoutcell'
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      id: 'gallery',
      label: 'Images',
      contents: [
        {
          id: 'featuredImage',
          title: 'Featured Image',
          /*
                                            title: "Gallery",
                                  */
          fields: [
            {
              name: 'featuredImage',
              label: 'Featured Image',
              type: 'static_image',
              style: 'featured-image static-image',
              gridStyle: 'layoutCellList'
            }
          ]
        },
        {
          id: 'galleryImages',
          title: 'Gallery Images',
          titleNote: 'Recommended aspect ratio - 16:9 and size - 922x518.',
          fields: [
            {
              type: 'multiple_add_form',
              name: 'galleryImages',
              multiFormGridStyle: 'multiple-add-form-div',
              showAddLink: false,
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: 'imagePositionIndex',
                        label: 'Display Order Index',
                        type: 'static',
                        style: 'static-value-with-pre-post-text',
                        postTextStyle: 'static-field-post-text',
                        gridStyle: 'text-field-with-pre-post-text'
                      },
                      {
                        name: 'galleryImage',
                        type: 'static_image',
                        nameFrom: 'id',
                        style: 'gallery-image static-image',
                        gridStyle: 'layoutCellList'
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    overview: {
      name: '',
      domain: '',
      category: '',
      applicationArea: '',
      searchKeywords: [],
      version: '',
      briefDescription: '',
      detailedDescription: '',
      publisherName: '',
      website: '',
      solutionWebsite: '',
      outageNotificationsEmail: '',
      copyright: '',
      privacyPolicyUrl: '',
      termsAndConditionsUrl: '',
      solutionLogo: '',
      videos: [],
      featuredImage: '',
      galleryImages: []
    }
  },
  activeTabId: 'overview'
};
