import React from "react";
import { Button } from "@cdk-uip/react-button";

const FormButton = ({ fieldProperties, onClick }) => {
  return (
    <Button
      compact="true"
      type="button"
      raised
      primary
      disabled={fieldProperties.isDisabled ? true : false}
      onClick={onClick}
    >
      {fieldProperties.label}
    </Button>
  );
};

export default FormButton;
