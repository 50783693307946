import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';

export const initialState = {
  searchResultsByApp: [],
  searchResultsByOrg: [],
  fetchingSearchResultsByApp: false,
  fetchingSearchResultsByOrg: false,
  noResultsFoundByApp: false,
  noResultsFoundByOrg: false,

  subscriptionDetailsBySolutionId: [],
  subscriptionDetailsByEntityId: [],
  subscriptionDetailsByCmf: [],
  subscriptionDetailsBySubscriptionId: [],
  subscriptionDetailsByDms: [],
  consentData: [],

  fetchingSubscriptionDetailsBySolutionId: false,
  fetchingSubscriptionDetailsByEntityId: false,
  fetchingSubscriptionDetailsByCmf: false,
  fetchingSubscriptionDetailsBySubscriptionId: false,
  fetchingSubscriptionDetailsByDms: false,
  fetchingConsentData: false,

  consentDataSuccessfullyfetched: false,

  errorSubscriptionDetailsBySolutionId: false,
  errorSubscriptionDetailsByEntityId: false,
  errorSubscriptionDetailsByCmf: false,
  errorSubscriptionDetailsBySubscriptionId: false,
  errorSubscriptionDetailsByDms: false,
  errorFetchingConsentData: false
};

export const getSearchSuggestionsByApp = createAsyncThunk(
  'getSearchSuggestionsByApp',
  async ({ appName }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query(
          $from: Int
          $sortBy: String
          $order: String
          $searchText: String
          $searchOnly: Boolean
          $needAllSolutionVisibilityTypes: Boolean
          $isAdmin: Boolean
        ) {
          searchStoreFrontSolution(
            filters: {
              from: $from
              sortBy: $sortBy
              order: $order
              searchText: $searchText
              searchOnly: $searchOnly
              needAllSolutionVisibilityTypes: $needAllSolutionVisibilityTypes
              isAdmin: $isAdmin
            }
          ) {
            page
            pageSize
            total
            data {
              id
              name
            }
          }
        }
      `,
      variables: {
        searchText: appName,
        searchOnly: true,
        needAllSolutionVisibilityTypes: true,
        isAdmin: true
      }
    });

    return responseObj &&
      responseObj.data &&
      responseObj.data.searchStoreFrontSolution
      ? responseObj.data.searchStoreFrontSolution
      : null;
  }
);

export const getSearchSuggestionsByOrg = createAsyncThunk(
  'getSearchSuggestionsByOrg',
  async ({ orgName }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($orgName: String) {
          getOrganizations(orgName: $orgName) {
            id
            name
          }
        }
      `,
      variables: {
        orgName: orgName
      }
    });
    return responseObj && responseObj.data && responseObj.data.getOrganizations;
  }
);

export const getSubscriptionDataForAdminForSolutionId = createAsyncThunk(
  'getSubscriptionDataForAdminForSolutionId',
  async ({ solutionId }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($solutionId: ID) {
          getSubscriptionDataForAdminForSolutionId(solutionId: $solutionId) {
            consentData {
              consentType
              customConsentForm
              documentLink
              name
              signedTermsAndConditions
              consentId
              solutionId
              organizationId
              consentedSource
              businessTitle
            }
            solutionData {
              id
              name
              status
              apiProducts {
                id
                fields
              }
              overview {
                name
                solutionLogo
              }
              orgId
              orgName
              activateOnly
              registration_details {
                api_details {
                  id
                  displayName
                  fields
                  privacyProtection
                }
              }
              data_rights {
                captureDataRightsConsent
              }
            }
            subscriptionData {
              subscriptionId
              status
              entityId
              created
              entityName
              orgAddress
              solutionId
              storeId
            }
            metadataSubscriptions
          }
        }
      `,
      variables: {
        solutionId: solutionId
      }
    });

    return (
      responseObj &&
      responseObj.data &&
      responseObj.data.getSubscriptionDataForAdminForSolutionId
    );
  }
);

export const getSubscriptionDataForAdminForSubscriptionId = createAsyncThunk(
  'getSubscriptionDataForAdminForSubscriptionId',
  async ({ subscriptionId }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($subscriptionId: ID) {
          getSubscriptionDataForAdminForSubscriptionId(
            subscriptionId: $subscriptionId
          ) {
            consentData {
              consentType
              customConsentForm
              documentLink
              name
              signedTermsAndConditions
              consentId
              solutionId
              organizationId
              consentedSource
              businessTitle
            }
            solutionData {
              id
              name
              status
              apiProducts {
                id
                fields
              }
              overview {
                name
                solutionLogo
              }
              orgId
              orgName
              activateOnly
              registration_details {
                api_details {
                  id
                  displayName
                  fields
                  privacyProtection
                }
              }
              data_rights {
                captureDataRightsConsent
              }
            }
            subscriptionData {
              subscriptionId
              status
              entityId
              created
              entityName
              orgAddress
              solutionId
              storeId
            }
            metadataSubscriptions
          }
        }
      `,
      variables: {
        subscriptionId: subscriptionId
      }
    });

    return (
      responseObj &&
      responseObj.data &&
      responseObj.data.getSubscriptionDataForAdminForSubscriptionId
    );
  }
);

export const getSubscriptionDataForAdminForEntityId = createAsyncThunk(
  'getSubscriptionDataForAdminForEntityId',
  async ({ entityId }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($entityId: ID) {
          getSubscriptionDataForAdminForEntityId(entityId: $entityId) {
            consentData {
              consentType
              customConsentForm
              documentLink
              name
              signedTermsAndConditions
              consentId
              solutionId
              organizationId
              businessTitle
            }
            solutionData {
              id
              status
              overview {
                name
                solutionLogo
              }
              orgId
              orgName
              activateOnly
              registration_details {
                api_details {
                  id
                  displayName
                  fields
                  privacyProtection
                }
              }
              data_rights {
                captureDataRightsConsent
              }
            }
            subscriptionData {
              subscriptionId
              status
              entityId
              created
              entityName
              orgAddress
              solutionId
              storeId
            }
            metadataSubscriptions
          }
        }
      `,
      variables: {
        entityId: entityId
      }
    });
    return (
      responseObj &&
      responseObj.data &&
      responseObj.data.getSubscriptionDataForAdminForEntityId
    );
  }
);

export const getSubscriptionDataForAdminForCmfNumber = createAsyncThunk(
  'getSubscriptionDataForAdminForCmfNumber',
  async ({ cmfNumber }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($cmfNumber: String) {
          getSubscriptionDataForAdminForCmfNumber(cmfNumber: $cmfNumber) {
            consentData {
              consentType
              customConsentForm
              documentLink
              name
              signedTermsAndConditions
              consentId
              solutionId
              organizationId
              businessTitle
            }
            solutionData {
              id
              status
              overview {
                name
                solutionLogo
              }
              orgId
              orgName
              activateOnly
              registration_details {
                api_details {
                  id
                  displayName
                  fields
                  privacyProtection
                }
              }
              data_rights {
                captureDataRightsConsent
              }
            }
            metadataSubscriptions
          }
        }
      `,
      variables: { cmfNumber }
    });
    return responseObj?.data?.getSubscriptionDataForAdminForCmfNumber;
  }
);

export const getSubscriptionDataForAdminForDms = createAsyncThunk(
  'getSubscriptionDataForAdminForDms',
  async ({ cNumber, ipAddress }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($cNumber: String, $ipAddress: String) {
          getSubscriptionDataForAdmin(
            cNumber: $cNumber
            ipAddress: $ipAddress
          ) {
            consentData {
              consentType
              customConsentForm
              documentLink
              name
              signedTermsAndConditions
              consentId
              solutionId
              organizationId
              businessTitle
            }
            solutionData {
              id
              status
              overview {
                name
                solutionLogo
              }
              orgId
              orgName
              activateOnly
              registration_details {
                api_details {
                  id
                  displayName
                  fields
                  privacyProtection
                }
              }
              data_rights {
                captureDataRightsConsent
              }
            }
            subscriptionData {
              subscriptionId
              status
              entityId
              created
              entityName
              orgAddress
              solutionId
              storeId
            }
            metadataSubscriptions
          }
        }
      `,
      variables: { cNumber, ipAddress }
    });
    return responseObj?.data?.getSubscriptionDataForAdmin;
  }
);

export const getConsentData = createAsyncThunk(
  'getConsentData',
  async ({ solutionIds, entities }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($solutionIds: [ID], $entities: [ID]) {
          getConsentData(solutionIds: $solutionIds, entities: $entities) {
            consentType
            customConsentForm
            documentLink
            name
            signedTermsAndConditions
            consentId
            solutionId
            organizationId
            businessTitle
          }
        }
      `,
      variables: { solutionIds, entities }
    });
    return responseObj?.data;
  }
);

function combineSubscriptionDataForOneApp({
  subscriptionData,
  solutionData,
  consentData,
  ...results
}) {
  const metadataSubscriptions =
    results.metadataSubscriptions && JSON.parse(results.metadataSubscriptions);
  const data = [];
  subscriptionData &&
    subscriptionData.forEach(item => {
      const entityId = item.entityId;
      const consents = consentData?.filter(
        item => item.organizationId === entityId
      );
      const metadata = metadataSubscriptions?.find(
        item => item.entityId === entityId
      );
      data.push({
        subscriptionData: item,
        solution: solutionData,
        consents,
        metadata
      });
    });

  return data;
}

function combineSubscriptionDataForMultipleApps({
  subscriptionData,
  solutionData,
  consentData,
  ...results
}) {
  const metadataSubscriptions =
    results.metadataSubscriptions && JSON.parse(results.metadataSubscriptions);
  const data = [];
  const data1 = [];
  subscriptionData &&
    subscriptionData.forEach(item => {
      const solutionId = item.solutionId;
      const solution = solutionData?.find(item => item.id === solutionId);
      const metadata = metadataSubscriptions?.find(
        item => item.solutionId === solutionId
      );
      const consents = consentData?.filter(
        item => item.solutionId === solutionId
      );
      if (solution) {
        if (
          solution.data_rights &&
          solution.data_rights.captureDataRightsConsent
        ) {
          const dataRightsConsent = consentData?.find(
            item => item.consentType === 'data_rights'
          );
          dataRightsConsent && consents.push(dataRightsConsent);
        }
        data.push({ subscriptionData: item, solution, consents, metadata });
      } else {
        data1.push({ subscriptionData: item, solution, consents, metadata });
      }
    });

  return [...data, ...data1];
}

function reconstructSubscriptionDataFromMetadata({
  solutionData,
  consentData,
  ...results
}) {
  const metadataSubscriptions =
    results.metadataSubscriptions && JSON.parse(results.metadataSubscriptions);
  const data = [];
  const data1 = [];

  metadataSubscriptions &&
    metadataSubscriptions.forEach(metadata => {
      const {
        subscriptionId,
        solutionId,
        entityId,
        createdOn,
        status,
        orgAddress,
        entityName,
        storeId
      } = metadata;
      const solution = solutionData?.find(item => item.id === solutionId);
      const consents = consentData?.filter(
        item => item.solutionId === solutionId
      );

      const subscriptionData = {
        subscriptionId,
        status,
        entityId,
        created: createdOn,
        entityName,
        orgAddress,
        solutionId,
        storeId
      };

      if (solution) {
        if (
          solution.data_rights &&
          solution.data_rights.captureDataRightsConsent
        ) {
          const dataRightsConsent = consentData?.find(
            item => item.consentType === 'data_rights'
          );
          dataRightsConsent && consents.push(dataRightsConsent);
        }
        data.push({ subscriptionData, solution, consents, metadata });
      } else {
        data1.push({ subscriptionData, solution, consents, metadata });
      }
    });

  return [...data, ...data1];
}

function combineConsentData(subscriptionsData, consentData) {
  const data = subscriptionsData.map(subscription => {
    const { solutionId = '', entityId = '' } = subscription?.subscriptionData;

    const consents = consentData?.filter(
      item => item.solutionId === solutionId && item.organizationId === entityId
    );
    return {
      ...subscription,
      consents: subscription.consents.concat(consents)
    };
  });
  return data;
}

const subscriptionDataSlice = createSlice({
  name: 'subscriptionData',
  initialState: initialState,
  reducers: {
    clearSubscriptionData: state => {
      state.searchResultsByApp = [];
      state.searchResultsByOrg = [];
      state.fetchingSearchResultsByApp = false;
      state.fetchingSearchResultsByOrg = false;
      state.noResultsFoundByApp = false;
      state.noResultsFoundByOrg = false;

      state.subscriptionDetailsBySolutionId = [];
      state.subscriptionDetailsByEntityId = [];
      state.subscriptionDetailsByCmf = [];
      state.subscriptionDetailsBySubscriptionId = [];
      state.subscriptionDetailsByDms = [];
      state.consentData = [];

      state.fetchingSubscriptionDetailsBySolutionId = false;
      state.fetchingSubscriptionDetailsByEntityId = false;
      state.fetchingSubscriptionDetailsByCmf = false;
      state.fetchingSubscriptionDetailsBySubscriptionId = false;
      state.fetchingSubscriptionDetailsByDms = false;
      state.fetchingConsentData = false;

      state.errorSubscriptionDetailsBySolutionId = false;
      state.errorSubscriptionDetailsByEntityId = false;
      state.errorSubscriptionDetailsByCmf = false;
      state.errorSubscriptionDetailsBySubscriptionId = false;
      state.errorSubscriptionDetailsByDms = false;
      state.errorFetchingConsentData = false;
      state.consentDataSuccessfullyfetched = false;
    }
  },
  extraReducers: {
    [getSearchSuggestionsByApp.fulfilled]: (state, action) => {
      const results = action.payload.data;
      const searchResults = [];
      results &&
        results.length &&
        results.map(item => {
          searchResults.push({ id: item.id, name: item.name });
        });
      state.searchResultsByApp = searchResults;
      state.fetchingSearchResultsByApp = false;
      if (!searchResults.length) {
        state.noResultsFoundByApp = true;
      }
    },
    [getSearchSuggestionsByApp.pending]: state => {
      state.searchResultsByApp = [];
      state.fetchingSearchResultsByApp = true;
      state.noResultsFoundByApp = false;
    },
    [getSearchSuggestionsByApp.rejected]: state => {
      state.searchResultsByApp = [];
      state.fetchingSearchResultsByApp = false;
      state.noResultsFoundByApp = false;
    },

    [getSearchSuggestionsByOrg.fulfilled]: (state, action) => {
      const results = action.payload;
      state.searchResultsByOrg = results;
      state.fetchingSearchResultsByOrg = false;
      if (!results || !results.length) {
        state.noResultsFoundByOrg = true;
      }
    },
    [getSearchSuggestionsByOrg.pending]: state => {
      state.searchResultsByOrg = [];
      state.fetchingSearchResultsByOrg = true;
      state.noResultsFoundByOrg = false;
    },
    [getSearchSuggestionsByOrg.rejected]: state => {
      state.searchResultsByOrg = [];
      state.fetchingSearchResultsByOrg = false;
      state.noResultsFoundByOrg = false;
    },

    [getSubscriptionDataForAdminForSolutionId.fulfilled]: (state, action) => {
      state.subscriptionDetailsBySolutionId = combineSubscriptionDataForOneApp(
        action.payload
      );
      state.fetchingSubscriptionDetailsBySolutionId = false;
      state.errorSubscriptionDetailsBySolutionId = false;
    },
    [getSubscriptionDataForAdminForSolutionId.pending]: state => {
      state.subscriptionDetailsBySolutionId = [];
      state.fetchingSubscriptionDetailsBySolutionId = true;
      state.errorSubscriptionDetailsBySolutionId = false;
    },
    [getSubscriptionDataForAdminForSolutionId.rejected]: state => {
      state.subscriptionDetailsBySolutionId = [];
      state.fetchingSubscriptionDetailsBySolutionId = false;
      state.errorSubscriptionDetailsBySolutionId = true;
    },

    [getSubscriptionDataForAdminForEntityId.fulfilled]: (state, action) => {
      state.subscriptionDetailsByEntityId = combineSubscriptionDataForMultipleApps(
        action.payload
      );
      state.fetchingSubscriptionDetailsByEntityId = false;
      state.errorSubscriptionDetailsByEntityId = false;
      state.consentDataSuccessfullyfetched = false;
    },
    [getSubscriptionDataForAdminForEntityId.pending]: state => {
      state.subscriptionDetailsByEntityId = [];
      state.consentData = [];
      state.errorFetchingConsentData = false;
      state.fetchingSubscriptionDetailsByEntityId = true;
      state.errorSubscriptionDetailsByEntityId = false;
    },
    [getSubscriptionDataForAdminForEntityId.rejected]: state => {
      state.subscriptionDetailsByEntityId = [];
      state.fetchingSubscriptionDetailsByEntityId = false;
      state.errorSubscriptionDetailsByEntityId = true;
    },

    [getSubscriptionDataForAdminForCmfNumber.fulfilled]: (state, action) => {
      state.subscriptionDetailsByCmf = reconstructSubscriptionDataFromMetadata(
        action.payload
      );
      state.fetchingSubscriptionDetailsByCmf = false;
      state.errorSubscriptionDetailsByCmf = false;
    },
    [getSubscriptionDataForAdminForCmfNumber.pending]: state => {
      state.subscriptionDetailsByCmf = [];
      state.fetchingSubscriptionDetailsByCmf = true;
      state.errorSubscriptionDetailsByCmf = false;
    },
    [getSubscriptionDataForAdminForCmfNumber.rejected]: state => {
      state.subscriptionDetailsByCmf = [];
      state.fetchingSubscriptionDetailsByCmf = false;
      state.errorSubscriptionDetailsByCmf = true;
    },

    [getSubscriptionDataForAdminForSubscriptionId.fulfilled]: (
      state,
      action
    ) => {
      state.subscriptionDetailsBySubscriptionId = combineSubscriptionDataForOneApp(
        action.payload
      );
      state.fetchingSubscriptionDetailsBySubscriptionId = false;
      state.errorSubscriptionDetailsBySubscriptionId = false;
    },
    [getSubscriptionDataForAdminForSubscriptionId.pending]: state => {
      state.subscriptionDetailsBySubscriptionId = [];
      state.fetchingSubscriptionDetailsBySubscriptionId = true;
      state.errorSubscriptionDetailsBySubscriptionId = false;
    },
    [getSubscriptionDataForAdminForSubscriptionId.rejected]: state => {
      state.subscriptionDetailsBySubscriptionId = [];
      state.fetchingSubscriptionDetailsBySubscriptionId = false;
      state.errorSubscriptionDetailsBySubscriptionId = true;
    },

    [getSubscriptionDataForAdminForDms.fulfilled]: (state, action) => {
      state.subscriptionDetailsByDms = combineSubscriptionDataForMultipleApps(
        action.payload
      );
      state.fetchingSubscriptionDetailsByDms = false;
      state.errorSubscriptionDetailsByDms = false;
      state.consentDataSuccessfullyfetched = false;
    },
    [getSubscriptionDataForAdminForDms.pending]: state => {
      state.subscriptionDetailsByDms = [];
      state.consentData = [];
      state.errorFetchingConsentData = false;
      state.fetchingSubscriptionDetailsByDms = true;
      state.errorSubscriptionDetailsByDms = false;
    },
    [getSubscriptionDataForAdminForDms.rejected]: state => {
      state.subscriptionDetailsByDms = [];
      state.fetchingSubscriptionDetailsByDms = false;
      state.errorSubscriptionDetailsByDms = true;
    },
    [getConsentData.fulfilled]: (state, action) => {
      if (state.subscriptionDetailsBySolutionId.length) {
        state.subscriptionDetailsBySolutionId = combineConsentData(
          state.subscriptionDetailsBySolutionId,
          action.payload.getConsentData
        );
      } else if (state.subscriptionDetailsByEntityId.length) {
        state.subscriptionDetailsByEntityId = combineConsentData(
          state.subscriptionDetailsByEntityId,
          action.payload.getConsentData
        );
      } else if (state.subscriptionDetailsByCmf.length) {
        state.subscriptionDetailsByCmf = combineConsentData(
          state.subscriptionDetailsByCmf,
          action.payload.getConsentData
        );
      } else if (state.subscriptionDetailsBySubscriptionId.length) {
        state.subscriptionDetailsBySubscriptionId = combineConsentData(
          state.subscriptionDetailsBySubscriptionId,
          action.payload.getConsentData
        );
      } else if (state.subscriptionDetailsByDms.length) {
        state.subscriptionDetailsByDms = combineConsentData(
          state.subscriptionDetailsByDms,
          action.payload.getConsentData
        );
      }
      state.consentData = action.payload.getConsentData;
      state.fetchingConsentData = false;
      state.errorFetchingConsentData = false;
      state.consentDataSuccessfullyfetched = true;
    },
    [getConsentData.pending]: state => {
      state.consentData = [];
      state.fetchingConsentData = true;
      state.errorFetchingConsentData = false;
      state.consentDataSuccessfullyfetched = false;
    },
    [getConsentData.rejected]: state => {
      state.consentData = [];
      state.fetchingConsentData = false;
      state.errorFetchingConsentData = true;
      state.consentDataSuccessfullyfetched = true;
    }
  }
});

export const { clearSubscriptionData } = subscriptionDataSlice.actions;

export default subscriptionDataSlice.reducer;
