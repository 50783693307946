import React from 'react';
import Spinner from '../common/Spinner';
import SolutionDescription from './SolutionDescription';
import Features from './Features';
import ApiInfo from './ApiInfo';
import Support from './Support';
import ReviewsTab from '../../containers/ReviewTabContainer';
import PlansAndPricing from './PlansAndPricing';
import HeadlineAndDivider from './HeadlineAndDivider';
import OverviewDetails from './OverviewDetails';
import ContactPublisher from './ContactPublisher';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import ReviewStats from './Reviews/ReviewStats';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import EmptyContent from './../common/empty-content';
import FortellisConstants from '../common/FortellisConstants';
import CogsLogo from '../common/images/cogs.png';
import NotFound from '../common/NotFound';
import SolutionVisibility from '../common/SolutionVisibility';
import PrivateSolutionBadge from '../common/PrivateSolutionBadge';
import ErrorCodes from '../common/ErrorCodes';
import SolutionNotAccessible from '../common/SolutionNotAccessible';
import ReviewAnonTab from '../../containers/ReviewAnonTabContainer';
import SolutionSubscriptionConfirm from '../solution-subscription/confirmation/solution-subscription-confirm';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import {
  TabPanel,
  Tab,
  Tabs,
  Divider,
  SecondaryButton,
  TextButton,
  Button,
  BUTTON_VARIANTS
} from 'cdk-radial';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../utils/amplitude-constants';
import { sendAmplitudeData } from '../../utils/amplitude';

class SolutionDetailsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 0,
      justSubscribed: false,
      subscriptionInfo: null
    };
    this.plansAndPricingContainerRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  onTabSelect = value => {
    this.setState({ tabSelected: value });
  };

  getFirstEditionId = plans => {
    if (plans && plans.length > 1) {
      for (let i = 0; i < plans.length; i++) {
        if (!plans[i].isTheFreeTrial) {
          return plans[i].id;
        }
      }
    }
    return 0;
  };

  onSubscribeClick = () => {
    const { solution, entity } = this.props;

    // Check for entity's existence before using its properties
    const orgId = entity && entity.id ? entity.id : 'NA';
    const orgName = entity && entity.name ? entity.name : 'NA';

    let amplitudeData = {
      [AMPLITUDE_PROPERTIES.APP_ID]: solution.id,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solution.overview.name,
      [AMPLITUDE_PROPERTIES.ORG_ID]: orgId,
      [AMPLITUDE_PROPERTIES.ORG_NAME]: orgName
    };
    if (solution.activateOnly !== false) {
      sendAmplitudeData(AMPLITUDE_EVENTS.ACTIVATE_AN_APP, amplitudeData);
      const url = `${environmentURLs.dealerSuiteLogin}/${solution.id}`;
      window.open(url, '_blank');
    } else {
      // '3' indexed tab is of pricing tab so opening the pricing tab after clicking on subscribe button.
      sendAmplitudeData(AMPLITUDE_EVENTS.SUBSCRIBE_TO_APP, amplitudeData);
      this.setState({ tabSelected: 3 });
    }
  };

  onSubscriptionComplete = (solution, plan, subscription) => {
    if (solution && plan && subscription) {
      this.setState({
        justSubscribed: true,
        subscriptionInfo: { solution, plan, subscription }
      });
    }
  };

  createAndSubmitAmplitudeData = eventName => {
    const { solution, entity } = this.props;

    let amplitudeData = {
      [AMPLITUDE_PROPERTIES.APP_ID]: solution.id,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solution.overview.name,
      [AMPLITUDE_PROPERTIES.ORG_ID]: entity?.id || 'N/A',
      [AMPLITUDE_PROPERTIES.ORG_NAME]: entity?.name || 'N/A'
    };
    sendAmplitudeData(eventName, amplitudeData);
  };

  render() {
    const {
      intl,
      solution,
      averageRating,
      existingSubscriptionByEntity,
      numberOfReviews,
      loading,
      isPreview,
      userPermissions,
      auth,
      entity
    } = this.props;
    let loggedInUserViewingPluginApp = false;
    if (
      auth &&
      auth.isAuthenticated &&
      solution &&
      solution.extensibilityType === 'plugin'
    ) {
      loggedInUserViewingPluginApp = true;
    }

    /**
     check if app is allowed to take subscriptions
     if solution object contains "allowSubscriptions" then check it's type

     1. if "allowSubscriptions" is boolean that means the app was approved by admin with either enable or disable subscriptions
          "Subscribe" button should be shown based on the value of "allowSubscriptions"

     2. if "allowSubscriptions" does not exist or its value is not boolean then the app is an Old app and it should be allowed to take subscriptions
          "Subscribe" button should be shown for the app
     */

    let allowSubscriptions =
      solution &&
      solution.subscription_management &&
      solution.subscription_management.allowSubscriptions;
    let showSubscribeButton =
      typeof allowSubscriptions === 'boolean' ? allowSubscriptions : true;

    const { justSubscribed, subscriptionInfo } = this.state;

    let plans = [];
    if (solution && solution.plans && solution.plans.length) {
      //condition to remove the free plan from list if Free Plan is NOT ENABLED
      plans = solution.plans.filter(
        plan => !(plan.isTheFreeTrial && !plan.isFreeTrialEnabled)
      );
    }

    if (solution && solution.error && solution.error.message) {
      if (solution.error.message === ErrorCodes.SOLUTION_NOT_ACCESSIBLE) {
        return (
          <div className="component-content">
            <SolutionNotAccessible />
          </div>
        );
      }
      if (solution.error.message === ErrorCodes.SOLUTION_NOT_FOUND) {
        return (
          <div className="component-content">
            <NotFound />
          </div>
        );
      }
    }

    if (justSubscribed && subscriptionInfo) {
      return (
        <SolutionSubscriptionConfirm
          {...this.props}
          solution={subscriptionInfo.solution}
          plan={subscriptionInfo.plan}
          subscriptionDetails={subscriptionInfo.subscription}
        />
      );
    }

    return (
      <div
        className={`component-content ${loading &&
          'solution-details-screen_spinner'}`}
      >
        {loading ? (
          <div>
            <Spinner />
            <div style={{ textAlign: 'center' }}>
              <strong>Loading App Details...</strong>
            </div>
          </div>
        ) : solution === 'Unauthorized' ? (
          <NotFound />
        ) : solution ? (
          <div style={{ paddingTop: '24px' }}>
            <div className="max-width-resp page-padding-common solution-details-screen-details-container overflow-x-unset">
              <div className="solution-details-column-left">
                <div className="sol-details__image-box">
                  {solution.overview && solution.overview.solutionLogo ? (
                    <img
                      src={`${solution.overview.solutionLogo}?${Math.random()}`}
                      alt={intl.formatMessage({
                        id: 'PurchaseSolution.sol_details.alt',
                        defaultMessage: 'Logo'
                      })}
                      className="sol-details__logo"
                    />
                  ) : (
                    <img
                      src={CogsLogo}
                      alt="App Logo"
                      className="sol-details__logo"
                    />
                  )}
                </div>
                <ReviewStats
                  averageRating={averageRating}
                  numberOfReviews={numberOfReviews}
                  orgId={solution.orgId}
                  entity={entity}
                  tabSelect={this.onTabSelect}
                  userPermissions={userPermissions}
                  createAndSubmitAmplitudeData={
                    this.createAndSubmitAmplitudeData
                  }
                />
                {!isPreview &&
                !loggedInUserViewingPluginApp &&
                solution.solutionType !== FortellisConstants.PARTNER_PROGRAM &&
                showSubscribeButton &&
                (solution.activateOnly !== false ||
                  (plans && plans.length > 0)) ? (
                  auth && auth.isAuthenticated ? (
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      raised={true}
                      dataTestId="subscribeButton"
                      className="full-width appDetails-page-secondary-control-buttons"
                      isDisabled={isPreview}
                      text={
                        solution.activateOnly !== false
                          ? 'Activate'
                          : 'Subscribe'
                      }
                      onClick={this.onSubscribeClick}
                    />
                  ) : (
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      raised={true}
                      dataTestId="subscribeButton"
                      className="full-width appDetails-page-secondary-control-buttons"
                      isDisabled={isPreview}
                      text={
                        solution.activateOnly !== false
                          ? 'Activate'
                          : 'Subscribe'
                      }
                      onClick={this.onSubscribeClick}
                    />
                  )
                ) : null}

                <ContactPublisher
                  {...this.props}
                  isPreview={isPreview}
                  customClassName="contact-publisher-top"
                  solutionId={solution.id}
                  listingVersion={solution.listingVersion}
                  solutionVersion={
                    solution.overview && solution.overview.version
                      ? solution.overview.version
                      : null
                  }
                  solutionName={
                    solution.overview && solution.overview.name
                      ? solution.overview.name
                      : null
                  }
                  solutionDeveloperName={solution.developerName}
                  solutionType={solution.solutionType}
                  publisherName={
                    solution.overview && solution.overview.publisherName
                      ? solution.overview.publisherName
                      : 'Publisher'
                    /* I do not know of a better solution here!!! Any suggestions?
                                                                                        <FormattedMessage
                                                                                          id="PurchaseSolution.contact-publisher-top"
                                                                                          defaultMessage="Publisher"
                                                                                        />*/
                  }
                  privacyPolicyUrl={
                    solution.overview && solution.overview.privacyPolicyUrl
                      ? solution.overview.privacyPolicyUrl
                      : ''
                  }
                  planCount={plans && plans.length}
                  showSubscribeButton={showSubscribeButton}
                  isActivateOnly={solution.activateOnly !== false}
                  createAndSubmitAmplitudeData={
                    this.createAndSubmitAmplitudeData
                  }
                />

                {auth &&
                  auth.isAuthenticated &&
                  solution &&
                  entity &&
                  solution.orgId &&
                  solution.orgId === entity.id && (
                    <TextButton
                      className="manage-solution-top appDetails-page-secondary-control-buttons"
                      onClick={() => {
                        this.props.history.push(
                          `/marketplace-account/mysolutions/${
                            solution.solutionType ===
                            FortellisConstants.PARTNER_PROGRAM
                              ? FortellisConstants.PARTNER_PROGRAM_TYPE_URL
                              : FortellisConstants.FORTELLIS_TYPE_URL
                          }/${solution.id}/solution-management`,
                          { activeTab: 'utilization' }
                        );
                      }}
                      isDisabled={isPreview}
                      text={'View Utilization'}
                    >
                      <FormattedMessage
                        id="ManageSolution.button"
                        defaultMessage="View Utilization"
                      />
                    </TextButton>
                  )}

                <HeadlineAndDivider
                  headline={
                    <FormattedMessage
                      id="PurchaseSolution.divider"
                      defaultMessage="Details"
                    />
                  }
                />
                <OverviewDetails solution={solution} />
              </div>
              <div className="solution-details-column-right">
                <div className="sol-details__sol-name">
                  {solution.overview && solution.overview.name
                    ? solution.overview.name
                    : null}

                  {solution.activateOnly === false &&
                    solution.plans &&
                    solution.plans.length &&
                    solution.plans.some(
                      plan => plan.isTheFreeTrial && plan.isFreeTrialEnabled
                    ) && (
                      <div className="free-trial_badge ">
                        <span className="free-trial_text">Free Trial</span>
                      </div>
                    )}
                </div>

                <div className="solution-details-developed-by">
                  {solution.overview && solution.overview.publisherName ? (
                    solution.overview.website ? (
                      <a
                        href={`${solution.overview.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          this.createAndSubmitAmplitudeData(
                            AMPLITUDE_EVENTS.VIEW_APP_PUBLISHER_WEBSITE
                          )
                        }
                      >
                        {solution.overview.publisherName}&nbsp;
                        <i className="material-icons external-link-icon">
                          open_in_new
                        </i>
                      </a>
                    ) : (
                      solution.overview.publisherName
                    )
                  ) : (
                    false
                  )}
                </div>

                {solution.solution_visibility &&
                solution.solution_visibility.solutionVisibilityType &&
                solution.solution_visibility.solutionVisibilityType ===
                  SolutionVisibility.PRIVATE ? (
                  <div className="private-solution-badge-container-sol-details">
                    <PrivateSolutionBadge />
                  </div>
                ) : (
                  false
                )}

                <div className="tab-component">
                  <Tabs
                    activeItemIndex={this.state.tabSelected}
                    items={[
                      {
                        id: 'tab-1',
                        panelId: 'panel-1',
                        renderPanel: props => (
                          <TabPanel {...props}>
                            {solution.overview &&
                            !solution.overview.briefDescription &&
                            !solution.overview.detailedDescription &&
                            !solution.overview.featuredImage &&
                            (!solution.overview.galleryImages ||
                              solution.overview.galleryImages.length < 1) &&
                            (!solution.overview.videos ||
                              solution.overview.videos.length < 1) ? (
                              <EmptyContent
                                message={
                                  <FormattedMessage
                                    id="Overview.emptyContent"
                                    defaultMessage="No overview yet, Check back soon!"
                                  />
                                }
                              />
                            ) : (
                              <SolutionDescription
                                overview={solution.overview}
                                listingVersionUpdates={
                                  solution.listingVersionUpdates
                                }
                                lastUpdatedOn={solution.lastUpdatedOn}
                                extensibilityType={solution.extensibilityType}
                                isAuthenticated={auth && auth.isAuthenticated}
                                solutionId={solution.id}
                                isPreview={isPreview}
                              />
                            )}
                            <br />
                            <Divider />
                            <br />
                            {(solution.highlights &&
                              solution.highlights.highlight_list &&
                              solution.highlights.highlight_list.length > 0) ||
                            (solution.features &&
                              solution.features.length > 0) ? (
                              <Features
                                overview={solution.overview}
                                features={solution.features}
                                highlights={solution.highlights}
                                activateOnly={solution.activateOnly}
                              />
                            ) : (
                              <EmptyContent
                                message={
                                  <FormattedMessage
                                    id="Features.emptyContent"
                                    defaultMessage="No highlights yet, Check back soon!"
                                  />
                                }
                              />
                            )}
                          </TabPanel>
                        ),
                        renderTab: props => (
                          <Tab
                            label="Overview"
                            hideLabel={false}
                            {...props}
                            onClick={() => {
                              this.createAndSubmitAmplitudeData(
                                AMPLITUDE_EVENTS.VIEW_APP_OVERVIEW
                              );
                              this.setState({ tabSelected: 0 });
                            }}
                          />
                        )
                      },
                      {
                        id: 'tab-2',
                        panelId: 'panel-2',
                        renderPanel: props => (
                          <TabPanel {...props}>
                            {<ApiInfo solution={solution} entity={entity} />}
                          </TabPanel>
                        ),
                        renderTab: props => (
                          <Tab
                            label="API Integrations"
                            hideLabel={false}
                            {...props}
                            onClick={() => {
                              this.createAndSubmitAmplitudeData(
                                AMPLITUDE_EVENTS.VIEW_APP_INTEGRATIONS
                              );
                              this.setState({ tabSelected: 1 });
                            }}
                          />
                        )
                      },
                      {
                        id: 'tab-3',
                        panelId: 'panel-3',
                        renderPanel: props => (
                          <TabPanel {...props}>
                            {solution.support &&
                            ((solution.support.tutorials_and_documentation &&
                              solution.support.tutorials_and_documentation
                                .length > 0) ||
                              solution.support.supportCenterUrl ||
                              solution.support.emailAddress ||
                              solution.support.phoneNumber ||
                              solution.support.supportCommunityUrl) ? (
                              <Support support={solution.support} />
                            ) : (
                              <EmptyContent
                                message={
                                  <FormattedMessage
                                    id="Support.emptyContent"
                                    defaultMessage="No tutorials yet, Check back soon!"
                                  />
                                }
                              />
                            )}
                          </TabPanel>
                        ),
                        renderTab: props => (
                          <Tab
                            label="Documentation"
                            hideLabel={false}
                            {...props}
                            onClick={() => {
                              this.createAndSubmitAmplitudeData(
                                AMPLITUDE_EVENTS.VIEW_APP_DOCUMENTATION
                              );
                              this.setState({ tabSelected: 2 });
                            }}
                          />
                        )
                      },
                      {
                        id: 'tab-4',
                        panelId: 'panel-4',
                        renderPanel: props =>
                          solution.activateOnly === false && (
                            <TabPanel {...props}>
                              {solution.activateOnly === false &&
                              plans &&
                              plans.length > 0 ? (
                                <PlansAndPricing
                                  {...this.props}
                                  isPreview={isPreview}
                                  existingSubscriptionByEntity={
                                    existingSubscriptionByEntity
                                  }
                                  plans={solution.plans}
                                  solution={solution}
                                  onSubscriptionComplete={
                                    this.onSubscriptionComplete
                                  }
                                  extensibilityType={
                                    solution && solution.extensibilityType
                                  }
                                  tabSelect={this.onTabSelect}
                                  showSubscribeButton={showSubscribeButton}
                                />
                              ) : (
                                <div className="no-plans-yet">
                                  No pricing plans available. Contact publisher
                                  for subscription!
                                </div>
                              )}
                            </TabPanel>
                          ),
                        renderTab: props =>
                          solution.activateOnly === false && (
                            <Tab
                              label="Pricing"
                              hideLabel={false}
                              {...props}
                              onClick={() => {
                                this.createAndSubmitAmplitudeData(
                                  AMPLITUDE_EVENTS.VIEW_APP_PRICING
                                );
                                this.setState({ tabSelected: 3 });
                              }}
                            />
                          )
                      },
                      {
                        id: 'tab-5',
                        panelId: 'panel-5',
                        renderPanel: props => (
                          <TabPanel {...props}>
                            {auth.isAuthenticated ? (
                              <ReviewsTab
                                solutionId={solution.id}
                                solution={solution}
                                listingVersion={solution.listingVersion}
                                solutionVersion={
                                  solution.overview
                                    ? solution.overview.version
                                    : null
                                }
                                orgId={solution.orgId}
                                entity={entity}
                                solutionName={
                                  solution.overview && solution.overview.name
                                }
                                userPermissions={userPermissions}
                                isPreview={isPreview}
                              />
                            ) : (
                              <ReviewAnonTab
                                solutionId={solution.id}
                                solution={solution}
                                listingVersion={solution.listingVersion}
                                solutionVersion={
                                  solution.overview
                                    ? solution.overview.version
                                    : null
                                }
                                orgId={solution.orgId}
                                entity={entity}
                                solutionName={
                                  solution.overview && solution.overview.name
                                }
                                userPermissions={userPermissions}
                                isPreview={isPreview}
                              />
                            )}
                          </TabPanel>
                        ),
                        renderTab: props => (
                          <Tab
                            label="Reviews"
                            hideLabel={false}
                            {...props}
                            onClick={() => {
                              this.createAndSubmitAmplitudeData(
                                AMPLITUDE_EVENTS.VIEW_APP_REVIEWS
                              );
                              this.setState({ tabSelected: 4 });
                            }}
                          />
                        )
                      }
                    ]}
                    onActiveItemChange={index =>
                      this.setState({ tabSelected: index })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="clear-both" />
            {!loggedInUserViewingPluginApp && showSubscribeButton && (
              <div
                ref={this.plansAndPricingContainerRef}
                id="plans-and-pricing-container"
                className="plans-and-pricing-container"
              >
                <Helmet>
                  <meta charSet="utf-8" />
                  <meta property="og:title" content="Fortellis Marketplace" />
                  {solution.overview && solution.overview.name && (
                    <meta
                      property="og:description"
                      content={`${solution.overview.name}`}
                    />
                  )}
                  {solution.id && (
                    <meta
                      property="og:url"
                      content={`${environmentURLs.marketplaceURL}solutions/${
                        solution.id
                      }`}
                    />
                  )}
                  {solution.overview && solution.overview.briefDescription && (
                    <meta
                      name="description"
                      content={`${solution.overview.briefDescription}`}
                    />
                  )}
                  {solution.overview &&
                    solution.overview.detailedDescription && (
                      <meta
                        name="description"
                        content={`${solution.overview.detailedDescription}`}
                      />
                    )}
                  {solution.features &&
                  Array.isArray(solution.features) &&
                  solution.features.length > 0
                    ? solution.features.map(feature => {
                        return (
                          <meta
                            name="description"
                            content={`${feature.title} ${feature.description}`}
                          />
                        );
                      })
                    : solution.overview &&
                      solution.overview.name && (
                        <meta
                          name="description"
                          content={`${solution.overview.name}`}
                        />
                      )}
                  {solution.highlights &&
                  Array.isArray(solution.highlights.highlight_list) &&
                  solution.highlights.highlight_list.length > 0
                    ? solution.highlights.highlight_list.map(highlight => {
                        return (
                          <meta
                            name="description"
                            content={`${highlight.description}`}
                          />
                        );
                      })
                    : solution.overview &&
                      solution.overview.name && (
                        <meta
                          name="description"
                          content={`${solution.overview.name}`}
                        />
                      )}
                  {solution.overview &&
                    solution.overview.name &&
                    solution.overview.publisherName && (
                      <meta
                        name="description"
                        content={`Fortellis marketplace app ${
                          solution.overview.name
                        } by ${solution.overview.publisherName} provider`}
                      />
                    )}
                  {solution.overview &&
                    solution.overview.galleryImages &&
                    solution.overview.galleryImages.map(gallery => (
                      <meta
                        name="description"
                        content={`${gallery.galleryImage}`}
                      />
                    ))}
                  {solution.overview && solution.overview.featuredImage && (
                    <meta
                      property="og:image"
                      name="image"
                      content={`${solution.overview.featuredImage}`}
                    />
                  )}
                  {solution.overview && solution.overview.website && (
                    <meta
                      name="description"
                      content={`${solution.overview.website}`}
                    />
                  )}
                </Helmet>
              </div>
            )}
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default injectIntl(withAuth(SolutionDetailsScreen));

export const SolutionDetailsScreenComponent = SolutionDetailsScreen;
