import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { environmentURLs } from '../../../../../common/environment/CaptureEnvironment';
import LearnMore from '../../../../../common/LearnMore/LearnMore';

class AppActivationHeader extends Component {
  constructor(props) {
    super(props);
  }

  renderSolutionListing = () => {
    const { handleSideNavStateChange } = this.props;

    let activeMenu = {
      activeMenuId: 'manageListing',
      contents: [
        {
          steps: [
            {
              id: 'manageListing',
              label: 'Manage Listing Versions',
              type: 'simple'
            },
            { id: 'insights', label: 'Insights', type: 'simple' },
            { id: 'appActivation', label: 'App Activation', type: 'simple' }
          ],
          title: 'Version and Insights'
        },
        {
          title: 'Activity',
          steps: [
            { id: 'leads', label: 'Leads', type: 'simple' },
            { id: 'reviews', label: 'Reviews', type: 'simple' },
            { id: 'subscriptions', label: 'Subscriptions', type: 'simple' },
            { id: 'utilization', label: 'Utilization', type: 'simple' }
          ]
        }
      ],

      nestedActiveMenuId: 'manageListing'
    };
    return handleSideNavStateChange(activeMenu);
  };

  render() {
    return (
      <div>
        <div className="app-activation-help-text">
          <FormattedMessage
            id="AppActivation.helpText1"
            defaultMessage={`App activation help text`}
          />
          <span
            className="app-activation-link"
            onClick={this.renderSolutionListing}
          >
            <FormattedMessage
              id="AppActivation.editApp"
              defaultMessage={`App activation edit app`}
            />
          </span>
          <FormattedMessage
            id="AppActivation.helpText2"
            defaultMessage={`App activation help text`}
          />
          &nbsp;
          <LearnMore
            url={`${
              environmentURLs.docs
            }docs/tutorials/app-lifecycle/managing-app-activations/#the-app-activations-page`}
          />
        </div>
        <div />
      </div>
    );
  }
}

export default AppActivationHeader;
