export class FormValidationUtils {
  static validateForm(formValues, formConfig) {
    let response = {
      isValid: true,
      field: {
        activeMenuId: "",
        nestedActiveMenuId: "",
        fieldName: []
      }
    };
    for (let formKey in formConfig) {
      if (formConfig[formKey].requiredFields) {
        formConfig[formKey].requiredFields.forEach(field => {
          if (
            formValues[formKey][field] === null ||
            formValues[formKey][field] === "" ||
            !formValues[formKey][field]
          ) {
            response.isValid = false;
            if (response.field.activeMenuId !== formKey) {
              response.field.fieldName = [];
            }
            response.field.activeMenuId = formKey;
            response.field.nestedActiveMenuId = formKey;
            response.field.fieldName.push(field);
          }
        });
      }
      if (!response.isValid) {
        return response;
      }
    }
    return response;
  }
}

export default FormValidationUtils;
