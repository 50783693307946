import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  data: {
    newestApps: [],
    discoverMoreApps: [],
    categories: [],
    publishers: [],
    integrations: [],
    featuredApps: []
  },
  view: {
    showMore: true
  }
};

const landingPageSlice = createSlice({
  name: 'landingPage',
  initialState: initialState,
  reducers: {
    saveNewestAppsToStore: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.data.newestApps = action.payload;
    },
    saveDiscoverMoreAppsToStore: (state, action) => {
      state.data.discoverMoreApps = action.payload;
    },
    displayShowMore: state => {
      state.view.showMore = true;
    },
    hideShowMore: state => {
      state.view.showMore = false;
    },
    saveFeaturedAppsToStore: (state, action) => {
      state.data.featuredApps = action.payload;
    }
  }
});

export const {
  saveNewestAppsToStore,
  saveDiscoverMoreAppsToStore,
  displayShowMore,
  hideShowMore,
  saveFeaturedAppsToStore
} = landingPageSlice.actions;

export const newestAppsSelector = state =>
  (state &&
    state.landingPage &&
    state.landingPage.data &&
    state.landingPage.data.newestApps) ||
  [];
export const discoverMoreAppsSelector = state =>
  (state &&
    state.landingPage &&
    state.landingPage.data &&
    state.landingPage.data.discoverMoreApps) ||
  [];
export const showMoreSelector = state =>
  (state &&
    state.landingPage &&
    state.landingPage.view &&
    state.landingPage.view.showMore) ||
  true;
export const featuredAppsSelector = state =>
  (state &&
    state.landingPage &&
    state.landingPage.data &&
    state.landingPage.data.featuredApps) ||
  [];
export default landingPageSlice.reducer;
