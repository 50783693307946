import React, { useState, useEffect } from 'react';
import { FileInput } from '@fortellis/file-input';
import UploadDocUtils from '../../../components/solution-submission/common-form-api/utils/UploadDocUtils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '@cdk-uip/react-dialog';
import { Checkbox } from '@cdk-uip/react-checkbox';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import Spinner from '../../common/Spinner';

const FILE_TYPE = 'application/pdf';
const ADDITION = 'addition';
const SELECT_ALL = 'Select All';

const APICheckbox = ({
  apiId,
  handleSelectAPI,
  isChecked,
  api_details,
  disabled
}) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  function onAPIChecked(event, apiId) {
    setChecked(event.target.checked);
    if (event.target.checked) {
      handleSelectAPI(apiId, api_details, ADDITION);
    } else {
      handleSelectAPI(apiId, api_details);
    }
  }

  return (
    <Checkbox
      className={
        disabled ? 'select-api-checkbox-disabled' : 'select-api-checkbox'
      }
      onChange={event => onAPIChecked(event, apiId)}
      checked={checked}
      disabled={disabled}
    />
  );
};
class CustomConsentForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectAPI = this.handleSelectAPI.bind(this);
  }

  state = {
    fileName: '',
    file: '',
    fileUploadError: '',
    isFileValid: true,
    docUploaded: false,
    type: 'One-Click Acceptance',
    waitMessage: '',
    deleteInProgress: false,
    uploadInProgress: false,
    selectedApis: [],
    selectAllChecked: false
  };

  uploadDocUtils = new UploadDocUtils();

  componentDidMount() {
    const {
      formFieldValuesAll: {
        registration_details: {
          registration_details: { api_details }
        }
      }
    } = this.props;

    if (this.props.formFieldValues.customConsentForm) {
      this.setState({ fileName: this.props.formFieldValues.fileName });
    }

    if (
      this.props.formFieldValues.selectedApis &&
      this.props.formFieldValues.selectedApis.length
    ) {
      this.setState({ selectedApis: this.props.formFieldValues.selectedApis });
      if (
        this.props.formFieldValues.selectedApis.length === api_details.length
      ) {
        this.setState({ selectAllChecked: true });
      }
    }
  }

  deleteDealerAdminConsent = async props => {
    this.setState({
      waitMessage: 'Deleting document. Please wait',
      deleteInProgress: true
    });

    const {
      formFieldValuesAll: { orgId, id, listingVersion }
    } = this.props;
    const fileName = this.state.file ? this.state.file.name : '';
    let finalListingVersion = listingVersion ? listingVersion : 1;
    let appName = this.props.formAllValues.overview.overview.name;
    let filePath = `${appName}_${orgId}_${finalListingVersion}_${fileName}`;
    let isAdminDealerConsent = true;
    return new Promise((resolve, reject) => {
      this.uploadDocUtils
        .deleteDoc(filePath, FILE_TYPE, isAdminDealerConsent)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          this.setState({ deleteInProgress: false });
        });
    });
  };

  handleFileReset = async () => {
    const termsAndConditionsUrl = await this.deleteDealerAdminConsent();
    if (termsAndConditionsUrl) {
      this.setState(prevValues => {
        return {
          ...prevValues,
          fileName: '',
          file: '',
          docUploaded: false,
          selectedApis: [],
          selectAllChecked: false
        };
      });
    }
    const formFieldValues = this.props.formFieldValues;
    formFieldValues.fileName = '';
    formFieldValues.file = '';
    formFieldValues.docUploaded = '';
    formFieldValues.selectedApis = [];
    this.props.resetCustomConsentForm();
  };

  uploadDealerAdminConsent = async (
    {
      termsFile,
      termsFileName,
      signatureCoordinates,
      termsType,
      isAdminDealerConsent
    },
    { appName, orgId, listingVersion }
  ) => {
    let finalListingVersion = listingVersion ? listingVersion : 1;
    let filePath = `${appName}_${orgId}_${finalListingVersion}_${termsFileName}`;
    this.setState({ uploadInProgress: true });
    return new Promise((resolve, reject) => {
      this.uploadDocUtils
        .uploadDoc(
          termsFile,
          filePath,
          termsType,
          signatureCoordinates,
          isAdminDealerConsent
        )
        .then(docUrl => {
          resolve(docUrl);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          this.setState({ uploadInProgress: false });
        });
    });
  };

  checkSpecialCharacters = str => {
    var regex = /[!@#$%^&*()-+\=\[\]{};':"\\|,<>\/?]/g;
    return regex.test(str);
  };

  handleFileSelect = async file => {
    let fileName = file.name;
    if (!file) {
      this.setState(prevValues => {
        return { ...prevValues, fileUploadError: 'an unknown error occurred' };
      });
      return;
    } else if (
      file.type === FILE_TYPE &&
      !this.checkSpecialCharacters(fileName.substring(0, fileName.length - 4))
    ) {
      if (file.name.includes('.pdf')) {
        const { name: fileName } = file;
        let dealerFormData = {
          termsFile: file,
          termsFileName: file.name,
          signatureCoordinates: null,
          termsType: 'One-Click Acceptance',
          isAdminDealerConsent: true
        };
        let orgDetails = {
          orgId: this.props.formAllValues.orgId,
          listingVersion: this.props.formAllValues.listingVersion,
          appName: this.props.formAllValues.overview.overview.name
        };
        try {
          const termsAndConditionsUrl = await this.uploadDealerAdminConsent(
            dealerFormData,
            orgDetails
          );
          let selectedApis = this.state.selectedApis;
          const formFieldValues = this.props.formFieldValues;
          if (termsAndConditionsUrl) {
            formFieldValues.customConsentForm = termsAndConditionsUrl;
            formFieldValues.fileName = `${dealerFormData.termsFileName}`;
            formFieldValues.selectedApis = selectedApis;
            this.setState(prevValues => {
              return {
                ...prevValues,
                fileName: fileName,
                file: file,
                docUploaded: true,
                selectedApis: selectedApis,
                isFileValid: true
              };
            });
          }
        } catch (e) {
          console.error('failed to upload terms', e);
        }
      }
    } else {
      this.setState(prevValues => {
        return { ...prevValues, isFileValid: false };
      });
      return;
    }
  };

  viewTerms({ s3Url }) {
    //create signed s3 url
    this.uploadDocUtils
      .s3Sign({
        filename: s3Url && s3Url.slice(s3Url.lastIndexOf('/') + 1),
        fileType: 'application/pdf',
        operation: 'getObject',
        isAdminDealerConsent: true
      })
      .then(res => {
        this.setState({
          open: true,
          adminDealerConsentSignedS3Url:
            res && res.data && res.data.signS3.signedRequest
        });
      });
  }

  onClose() {
    this.setState({
      open: false
    });
  }

  createDeleteButton() {
    if (this.props.isSolutionReview) {
      const { formAllValues } = this.props;
      const s3Url =
        formAllValues.custom_consent.custom_consent.customConsentForm;
      const fileName = formAllValues.custom_consent.custom_consent.fileName;

      return (
        s3Url && (
          <div className="view-document">
            <Button
              className="button-right"
              compact={'true'}
              type="button"
              onClick={() => this.viewTerms({ s3Url })}
              outlined={true}
            >
              <FormattedMessage
                id="PDFView.viewButton"
                defaultMessage="View Document"
              />
            </Button>
            <Dialog open={this.state.open} onCancel={() => this.onClose()}>
              <embed
                title={fileName}
                width="100%"
                height="800px"
                src={this.state.adminDealerConsentSignedS3Url}
              />
            </Dialog>
          </div>
        )
      );
    }
  }

  handleSelectAll(event, api_details) {
    let checked = event.target.checked;
    this.setState({
      selectAllChecked: checked
    });

    let selectedApis = this.state.selectedApis;
    const formFieldValues = this.props.formFieldValues;
    if (checked) {
      api_details &&
        api_details.map(item => {
          if (!selectedApis.includes(item.id)) selectedApis.push(item.id);
        });
      this.setState(prevValues => {
        return {
          ...prevValues,
          selectedApis: selectedApis
        };
      });
      formFieldValues.selectedApis = selectedApis;
    } else {
      this.setState(prevValues => {
        return {
          ...prevValues,
          selectedApis: []
        };
      });
      formFieldValues.selectedApis = [];
    }
  }

  handleSelectAPI(apiId, api_details, type) {
    const formFieldValues = this.props.formFieldValues;
    if (type === ADDITION) {
      let selectedApis = this.state.selectedApis;
      if (!selectedApis.includes(apiId)) {
        selectedApis.push(apiId);
      }
      this.setState(prevValues => {
        return {
          ...prevValues,
          selectedApis: selectedApis
        };
      });

      formFieldValues.selectedApis = selectedApis;

      if (selectedApis && selectedApis.length === api_details.length) {
        this.setState({
          selectAllChecked: true
        });
      }
    } else {
      let selectedApis = this.state.selectedApis;
      const filteredSelectedApis = selectedApis.filter(item => item !== apiId);
      this.setState(prevValues => {
        return {
          ...prevValues,
          selectedApis: filteredSelectedApis
        };
      });

      formFieldValues.selectedApis = filteredSelectedApis;

      if (
        filteredSelectedApis &&
        filteredSelectedApis.length !== api_details.length
      ) {
        this.setState({
          selectAllChecked: false
        });
      }
    }
  }

  selectApis(appStatus) {
    const {
      formFieldValuesAll: {
        registration_details: {
          registration_details: { api_details }
        }
      }
    } = this.props;

    let fileName = this.state.fileName;

    if (api_details.length) {
      return (
        <div className={'admin-dealer-consent-api-container'}>
          <div
            className={'select-all-apis-checkbox'}
            id={'select-all-apis-custom-dealer-consent'}
          >
            <Checkbox
              className={
                fileName
                  ? 'select-api-checkbox'
                  : 'select-api-checkbox-disabled'
              }
              checked={this.state.selectAllChecked}
              disabled={!fileName}
              onChange={event => {
                this.handleSelectAll(event, api_details);
              }}
            />
            <label className="check-box-item-label">{SELECT_ALL}</label>
          </div>
          <div className={'all-apis-container'}>
            {api_details.map(item => (
              <div
                className={'api-checkbox'}
                id={'select-all-apis-custom-dealer-consent'}
              >
                <APICheckbox
                  apiId={item.id}
                  isChecked={
                    this.state.selectedApis &&
                    this.state.selectedApis.includes(item.id)
                  }
                  api_details={api_details}
                  handleSelectAPI={this.handleSelectAPI}
                  disabled={!fileName}
                />
                <label className="check-box-item-label">
                  {item.displayName}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="admin-dealer-consent-data">
          Upload Dealer Integration Addendum
          <div>
            <div className={'admin-dealer-consent-file-selector'}>
              {!this.state.uploadInProgress ? (
                <>
                  <FileInput
                    label="Consent PDF &#x2A;"
                    id="dealerConsents"
                    accept={FILE_TYPE}
                    value={this.state.fileName}
                    onSelect={file => this.handleFileSelect(file)}
                    onReset={() => this.handleFileReset()}
                    success={this.state.docUploaded}
                    isValid={this.state.isFileValid}
                    helperText={
                      this.state.isFileValid ? (
                        <p />
                      ) : (
                        <p style={{ color: 'red' }}>
                          *No special characters are allowed in the file name
                        </p>
                      )
                    }
                  />
                  {this.createDeleteButton()}
                </>
              ) : (
                <LayoutGrid className="max-width-resp page-padding-common">
                  <LayoutGridCell span={12}>
                    <Spinner />
                  </LayoutGridCell>
                </LayoutGrid>
              )}
              {this.state.deleteInProgress && (
                <LayoutGrid className="max-width-resp page-padding-common">
                  <LayoutGridCell span={12}>
                    <Spinner />
                  </LayoutGridCell>
                </LayoutGrid>
              )}
            </div>
            {this.selectApis()}
          </div>
        </div>
        {!this.props.formFieldValues.customConsentForm && (
          <TableContainer style={{ marginTop: '16px' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>No document uploaded</TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}
export default CustomConsentForm;
