import ApiConfigForSolution from '../components/solution-subscription/api-configuration/ApiConfigForSolution';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const queryUpdated = gql`
  query($subscriptionId: ID, $entityId: ID) {
    getSubscription(subscriptionId: $subscriptionId, entityId: $entityId) {
      subscriptionId
      entityId
      solutionId
      status
      connections {
        api
        solutionId
        connectionId
        providerId
        subscriptionId
        entityId
        status
        apiDisplayName
      }
    }

    getSubscriptionFromMarketplace(subscriptionId: $subscriptionId) {
      id
      subscriptionId
      solutionId
      entityId
      userId
      status
      plan {
        id
        planName
      }
      termsAndConditions
      dateOfPurchase
      solution {
        id
        listingVersion
        orgId
        overview {
          version
          name
          publisherName
        }
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  dataRefetch: () => data.refetch(),
  APIsRequiredForSolution:
    data.getSubscription && data.getSubscription.connections
      ? data.getSubscription.connections
      : [],
  subscriptionFromMarketplace:
    data.getSubscriptionFromMarketplace && data.getSubscriptionFromMarketplace
      ? data.getSubscriptionFromMarketplace
      : null,
  solutionId:
    data.getSubscription && data.getSubscription.solutionId
      ? data.getSubscription.solutionId
      : null,
  subscriberEntityId:
    data.getSubscription && data.getSubscription.entityId
      ? data.getSubscription.entityId
      : null,
  loading: data.loading
});

export default compose(
  graphql(queryUpdated, {
    props: mapDataToProps,
    options: ({ match, entity }) => ({
      variables: {
        subscriptionId: match.params.subscriptionId,
        entityId: entity.id
      }
    })
  })
)(ApiConfigForSolution);
