import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { Radio } from '@cdk-uip/react-radio';

export class FormRadio extends React.Component {
  handleRadioChange = (event, name) => {
    const formFieldValues = this.props.formFieldValues;
    formFieldValues[name] = event.target.checked;
    this.props.onChange(
      this.resetDependent(event.target.checked, formFieldValues)
    );
  };

  resetDependent = (value, formFieldValues) => {
    if (this.props.fieldProperties.resetDependent) {
      let resetValues = this.props.fieldProperties.resetDependent(value);
      if (resetValues) {
        Object.keys(resetValues).forEach(key => {
          formFieldValues[key] = resetValues[key];
        });
      }
    }
    return formFieldValues;
  };

  render() {
    let {
      props: {
        fieldProperties: { name, noLabel, markAsCheck },
        formFieldValues
      }
    } = this;
    return (
      <div
        className="form-field-with-tooltip"
        id={this.props.fieldProperties.name}
      >
        <Radio
          className={noLabel ? 'radio-item-noLabel' : 'radio-item-radio'}
          checked={markAsCheck(formFieldValues, name)}
          disabled={this.props.fieldProperties.disabled}
          required={this.props.fieldProperties.required}
          onChange={event => {
            this.handleRadioChange(event, this.props.fieldProperties.name);
          }}
        />
        <label className="radio-item-label">
          {this.props.fieldProperties.label}
          {this.props.fieldProperties.toolTipText && (
            <span>
              <i
                id={`tooltip_${this.props.fieldProperties.name}`}
                className="material-icons help-label-icon"
              >
                help
              </i>
            </span>
          )}
        </label>
        <Tooltip
          className="form-field-tooltip  checkbox-field-tooltip"
          htmlFor={`tooltip_${this.props.fieldProperties.name}`}
        >
          <span>{this.props.fieldProperties.toolTipText}</span>
        </Tooltip>
      </div>
    );
  }
}

export default FormRadio;
