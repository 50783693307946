export const config = {
  formId: 'payment_info',
  formTitle: 'Payment Methods',
  formValueType: 'Object',
  formNote:
    'By selecting below options, you can select the payment methods for the app',
  tabs: [
    {
      id: 'payment_info',
      label: 'Payment Options',
      contents: [
        {
          id: 'payment_methods_contents',
          fields: [
            {
              name: 'payment_info',
              label: 'Payment related options',
              disabled: false,
              type: 'payment',
              style: 'payment-options-style',
              gridStyle: 'layoutcell'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    payment_info: {
      paymentMode: '',
      paymentOption: {}
    }
  },
  activeTabId: 'payment_info'
};
