export const config = {
  formId: "listingVersionUpdates",
  formTitle: "What's New",
  formValueType: "Object",
  formNote: "Add whats new in this listing version",
  tabs: [
    {
      id: "listingVersionUpdates",
      label: "What's New",
      contents: [
        {
          id: "listingVersionUpdates",
          fields: [
            {
              name: "description",
              label: "Description",
              maxLength: 512,
              type: "text_field",
              style: "formtextfield",
              gridStyle: "layoutcell",
              toolTipText:
                "Brief information about what's new in this listing version"
            },
            {
              titleStyle: "multiple-add-title-sub",
              type: "multiple_add_form",
              name: "updates",
              multiFormGridStyle: "multiple-add-form-div",
              showAddLink: function(status) {
                return true;
              },
              addLinkText: "ADD A HIGHLIGHT POINT",
              formDefaultValue: {
                id: "",
                updateText: ""
              },
              formContent: {
                fields: [
                  {
                    multiple: true,
                    multipleFields: [
                      {
                        name: "updateText",
                        label: "Highlight Point",
                        type: "text_field",
                        maxLength: 126,
                        style: "formtextfield",
                        gridStyle: "layoutcell",
                        toolTipText:
                          "Highlights of new features in this listing version"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    listingVersionUpdates: {
      description: "",
      updates: []
    }
  },
  activeTabId: "listingVersionUpdates"
};
