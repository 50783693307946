import React from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { injectIntl } from 'react-intl';
import PlanSubscription from './plan-subscription';
import Spinner from '../common/Spinner';
import RequestBetaAccess from '../common/RequestBetaAccess';
import EntityRegistrationConfirmation from '../common/EntityRegistrationConfirmation';
import FortellisConstants from '../common/FortellisConstants';

class SolutionSubscription extends React.Component {
  state = {
    userPermissions: this.props.userPermissions
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.userPermissions &&
      prevState.userPermissions &&
      nextProps.userPermissions.length !== prevState.userPermissions.length
    ) {
      return {
        userPermissions: nextProps.userPermissions
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.state.entity === '') {
      this.getEntityName().then(entity => {
        this.setState({ entity: entity });
      });
    }
  }

  componentDidUpdate() {
    if (this.state.entity === '') {
      this.getEntityName().then(entity => {
        this.setState({ entity: entity });
      });
    }
  }

  render() {
    const { solution, loading, userPermissions, entity } = this.props;
    return (
      <div className="component-content">
        {!entity ||
        !entity.id ||
        entity.id === FortellisConstants.DEFAULT_ORG_ID ||
        entity.id === FortellisConstants.PERSONAL_ACCOUNT ? (
          <EntityRegistrationConfirmation isPopup={false} />
        ) : loading ||
          !solution ||
          solution.length === 0 ||
          this.state.entity === '' ? (
          <LayoutGrid className="max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="save-loading-spinner">
              <Spinner />
            </LayoutGridCell>
          </LayoutGrid>
        ) : userPermissions &&
          userPermissions.length > 0 &&
          userPermissions.includes('Subscribe') ? (
          <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="myaccount-component-content">
              <PlanSubscription
                {...this.props}
                solution={solution}
                selectedPlanId={this.props.match.params.selectedPlanId}
              />
            </LayoutGridCell>
          </LayoutGrid>
        ) : (
          <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="myaccount-component-content">
              <RequestBetaAccess />
            </LayoutGridCell>
          </LayoutGrid>
        )}
      </div>
    );
  }
}
class SolutionSubscriptionContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <SolutionSubscription {...this.props} />
    ) : (
      <></>
    );
  }
}
export default injectIntl(withAuth(SolutionSubscriptionContainer));
