import SolutionStripForApiConfig from '../components/solution-subscription/api-configuration/SolutionStripForApiConfig';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const query = gql`
  query($solutionId: ID, $entityId: ID) {
    solutionList(id: $solutionId) {
      id
      listingVersion
      apigee_id
      developerId
      developerName
      overview {
        name
        publisherName
        solutionLogo
        copyright
      }
      registration_details {
        api_details {
          id
          name
          isOptional
        }
      }
      plans {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
    }
    solutionSubscriptions(entityId: $entityId, solutionId: $solutionId) {
      id
      solutionId
      subscriptionId
      entityId
      status
      listingVersion
      dateOfPurchase
      apiActivationStatus
      subscriptionStartDate
      subscriptionEndDate
      plan {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  solution: data.solutionList ? data.solutionList[0] : {},
  solutionSubscriptions: data.solutionSubscriptions
    ? data.solutionSubscriptions[0]
    : {},
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps,
  options: ({ solutionId, entityId }) => ({
    variables: {
      solutionId: solutionId,
      entityId: entityId
    }
  })
})(SolutionStripForApiConfig);
