import React, { useState, useEffect } from 'react';
import {
  SimpleTable,
  SimpleTableBody,
  SimpleTableContainer,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  SimpleTableWrapper,
  Loader
} from 'cdk-radial';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import FortellisConstants from '../common/FortellisConstants';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import SolutionListingAPI from '../../api/StoreListingAPI';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import replaceAll from 'string.prototype.replaceall';
import { sendAmplitudeData } from '../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../utils/amplitude-constants';

const ApiInfoSimpleTableRow = styled(SimpleTableRow)`
  :nth-child(even) {
    background-color: #151b250d;
    border-top: 1px solid #dcddde;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const getProviders = gql`
  query($apiIds: [ID]) {
    providers(provides: $apiIds) {
      id
      name
      entityId
      productName
      api
      fqdn
      adminAPIUrl
    }
  }
`;

const getAsyncApiData = gql`
  query($id: ID) {
    asyncApiDetails(id: $id) {
      id
      orgId
      name
      status
      website
      created
      apigeeAppId
      displayName
      description
      oneToOneUI
      apiProducts {
        name
        displayName
      }
      integrations {
        id
        name
        displayName
        description
        apiType
        providers {
          id
          apiProviderName
          entityId
        }
      }
    }
  }
`;

const removeApiV2Prefix = str => {
  const prefixToRemove = 'api-v2-';
  return str.startsWith(prefixToRemove)
    ? str.substring(prefixToRemove.length)
    : str;
};

const APIDescriptionTextLimit = 150;
const ReadMore = ({ textLimit, text }) => {
  const [showMore, setShowMore] = useState(false);
  const [desc, setDesc] = useState(text);

  useEffect(() => {
    if (text.length > textLimit) {
      if (showMore) {
        setDesc(text);
      } else {
        setDesc(text.substring(0, textLimit).trim() + '...');
      }
    }
  }, [showMore]);

  function suppressMarkdown(desc = '') {
    return replaceAll(desc, '#', '');
  }
  const apiDescription = () => {
    return suppressMarkdown(desc);
  };

  return (
    <>
      <div className="api_description">{apiDescription()}</div>
    </>
  );
};
class ApiInfo extends React.Component {
  state = {
    apiProviderMapping: null,
    loading: false,
    isConsentLoaded: false,
    providersData: []
  };

  getTitleMessage(overview) {
    return null;
    // return (
    //   <div>
    //     <FormattedMessage
    //       id="ApiInfo.apiUsedByIntegrationMessage"
    //       defaultMessage="This solution integrates with the following business applications."
    //     />
    //   </div>
    // );
  }

  getApis(solution) {
    if (this.isFortellisSolution()) {
      return solution.registration_details &&
        solution.registration_details.api_details
        ? solution.registration_details.api_details.sort((a, b) => {
            var x =
              a && a.displayName ? a.displayName.toLowerCase() : undefined;
            var y =
              b && b.displayName ? b.displayName.toLowerCase() : undefined;
            if (x > y) return 1;
            if (x < y) return -1;
            return 0;
          })
        : [];
    }
    if (this.isPartnerProgram()) {
      return solution.partner_program_registration_details &&
        solution.partner_program_registration_details.partner_api_details
        ? solution.partner_program_registration_details.partner_api_details.sort(
            (a, b) => {
              var x =
                a && a.applicationName
                  ? a.applicationName.toLowerCase()
                  : undefined;
              var y =
                b && b.applicationName
                  ? b.applicationName.toLowerCase()
                  : undefined;
              if (x > y) return 1;
              if (x < y) return -1;
              return 0;
            }
          )
        : [];
    }
    return [];
  }

  getSolutionType(solution) {
    return solution.solutionType;
  }

  isPartnerProgram() {
    return (
      this.props.solution.solutionType === FortellisConstants.PARTNER_PROGRAM
    );
  }

  isFortellisSolution() {
    return this.props.solution.solutionType === FortellisConstants.FORTELLIS;
  }

  createAndSubmitAmplitudeData = api => {
    const { solution, entity } = this.props;
    let amplitudeData = {
      [AMPLITUDE_PROPERTIES.APP_ID]: solution?.id,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solution?.overview?.name,
      [AMPLITUDE_PROPERTIES.ORG_ID]: entity?.id || 'N/A',
      [AMPLITUDE_PROPERTIES.ORG_NAME]: entity?.name || 'N/A',
      [AMPLITUDE_PROPERTIES.API_ID]: api?.id || 'N/A',
      [AMPLITUDE_PROPERTIES.API_NAME]: api?.displayName || 'N/A'
    };
    sendAmplitudeData(AMPLITUDE_EVENTS.VIEW_API, amplitudeData);
  };

  async getAsyncApiDetails(id) {
    let response = await this.props.client.query({
      query: getAsyncApiData,
      variables: {
        id: id
      }
    });
    return response.data;
  }

  async fetchProviders(apis) {
    let apiProviderMapping = {};
    const solutionId = this.props.solution.id;
    const solutionListingVersion = this.props.solution.listingVersion
      ? this.props.solution.listingVersion
      : '1';
    const consentType = FortellisConstants.CONSENT_TYPE_FOR_API;
    const auth = this.props.auth;

    //if one to one solution then get async api data as well
    let oneToOneUIApiData = [];
    let oneToOneUIApiDetails;
    if (
      this.props.solution &&
      this.props.solution.oneToOneUI &&
      this.props.auth.isAuthenticated
    ) {
      oneToOneUIApiDetails = await this.getAsyncApiDetails(solutionId);
      let filteredApiDetails = oneToOneUIApiDetails.asyncApiDetails.integrations.filter(
        item => {
          if (item.id !== environmentURLs.TestAPI) {
            return item;
          }
        }
      );
      // case for mix response async+ rest
      if (
        oneToOneUIApiDetails.asyncApiDetails.apiProducts &&
        Array.isArray(oneToOneUIApiDetails.asyncApiDetails.apiProducts) &&
        oneToOneUIApiDetails.asyncApiDetails.apiProducts.length > 0
      )
        oneToOneUIApiDetails.asyncApiDetails.apiProducts.forEach(restApi => {
          filteredApiDetails.forEach(item => {
            let apiObj = {};
            if (restApi.name.includes(item.id) && item.apiType === 'api') {
              apiObj.description = item.description;
              apiObj.displayName = item.displayName;
              apiObj.id = restApi.name;
              apiObj.name = item.providers[0].apiProviderName;
              apiObj.apiType = item.apiType;
              apiObj.api = restApi.name;
              apiObj.productName = item.displayName;
              apiObj.providers = item.providers.map(p => {
                return { name: p.apiProviderName, id: p.id ? p.id : 'N/A' };
              });
            } else {
              // case for async-api
              if (item.apiType !== 'api') {
                apiObj.description = item.description;
                apiObj.displayName = item.displayName;
                apiObj.id = item.id;
                apiObj.name = item.displayName;
                apiObj.apiType = item.apiType;
                apiObj.api = item.id;
                apiObj.providers = item.providers.map(p => {
                  return { name: p.apiProviderName, id: p.id ? p.id : 'N/A' };
                });
              }
            }
            if (apiObj && Object.keys(apiObj).length > 0)
              oneToOneUIApiData.push(apiObj);
          });
        });
      if (
        oneToOneUIApiDetails.asyncApiDetails.apiProducts &&
        Array.isArray(oneToOneUIApiDetails.asyncApiDetails.apiProducts) &&
        oneToOneUIApiDetails.asyncApiDetails.apiProducts.length < 1
      ) {
        filteredApiDetails.forEach(item => {
          let apiObj = {};
          apiObj.description = item.description;
          apiObj.displayName = item.displayName;
          apiObj.id = item.id;
          apiObj.name = item.displayName;
          apiObj.apiType = item.apiType;
          apiObj.api = item.id;
          apiObj.providers = item.providers.map(p => {
            return { name: p.apiProviderName, id: p.id ? p.id : 'N/A' };
          });
          oneToOneUIApiData.push(apiObj);
        });
      }
    }
    if (this.props.auth.isAuthenticated) {
      let solutionsConsent;
      if (this.isFortellisSolution()) {
        solutionsConsent = await SolutionListingAPI.anonymous.getSolutionsConsentedProvider(
          solutionId,
          solutionListingVersion,
          consentType,
          auth
        );
      }
      let usedApiIds = apis.map(api => {
        return api.id;
      });
      if (this.isFortellisSolution() && usedApiIds.length) {
        this.setState({ loading: true });
        let response = await this.props.client.query({
          query: getProviders,
          variables: {
            apiIds: usedApiIds
          }
        });
        response.data.providers.forEach(item => {
          item.apiType = 'api';
        });

        if (
          this.isFortellisSolution() &&
          solutionsConsent &&
          solutionsConsent.data
        ) {
          for (let [key, value] of Object.entries(solutionsConsent.data)) {
            if (
              value.listingVersion !== this.props.solution.listingVersion ||
              value.consentType !== 'api_tos' ||
              value.status !== 'accepted'
            ) {
              delete solutionsConsent.data[key];
            }
          }
          if (this.props.solution && this.props.solution.oneToOneUI) {
            response.data.providers = [];
            let allProviders = response.data.providers.concat(
              oneToOneUIApiData
            );
            const key = 'id';
            response.data.providers = [
              ...new Map(allProviders.map(item => [item[key], item])).values()
            ];

            if (response.data && response.data.providers) {
              response.data.providers.forEach(provider => {
                apiProviderMapping[provider.api] =
                  apiProviderMapping[provider.api] || [];
                apiProviderMapping[provider.api].push(provider);
              });
            }
          }
          if (!this.props.solution.oneToOneUI) {
            for (let [key, value] of Object.entries(solutionsConsent.data)) {
              if (response.data && response.data.providers) {
                response.data.providers.forEach(provider => {
                  if (provider.id === value.apiProviderId) {
                    apiProviderMapping[provider.api] =
                      apiProviderMapping[provider.api] || [];
                    apiProviderMapping[provider.api].push(provider);
                  }
                });
              }
            }
          }
        } else if (this.isPartnerProgram()) {
          if (response.data && response.data.providers) {
            response.data.providers.forEach(provider => {
              apiProviderMapping[provider.api] =
                apiProviderMapping[provider.api] || [];
              apiProviderMapping[provider.api].push(provider);
            });
          }
        }
      }
    } else {
      let usedApiIds = [];
      apis.forEach(api => {
        usedApiIds.push(api.id);
      });
      let oneToOneUI = this.props.solution.oneToOneUI;
      if (this.isFortellisSolution() && usedApiIds.length) {
        this.setState({ loading: true });
        let response = await SolutionListingAPI.anonymous.getApiDetailsForSolution(
          { apiIds: usedApiIds, oneToOneUI: oneToOneUI, solutionId: solutionId }
        );
        this.setState({ providersData: response.data.data });
        let solutionsConsent;
        if (this.isFortellisSolution()) {
          solutionsConsent = await SolutionListingAPI.anonymous.getAnonSolutionsConsentedProvider(
            solutionId,
            solutionListingVersion,
            consentType
          );
        }
        if (
          this.isFortellisSolution() &&
          solutionsConsent &&
          solutionsConsent.data
        ) {
          for (let [key, value] of Object.entries(solutionsConsent.data)) {
            if (
              value.listingVersion !== this.props.solution.listingVersion ||
              value.consentType !== 'api_tos' ||
              value.status !== 'accepted'
            ) {
              delete solutionsConsent.data[key];
            }
          }

          if (this.props.solution && this.props.solution.oneToOneUI) {
            if (
              response.data &&
              response.data.data &&
              response.data.data.providers
            ) {
              let allProviders = response.data.data.providers.concat(
                oneToOneUIApiData
              );
              const key = 'id';
              response.data.data.providers = [
                ...new Map(allProviders.map(item => [item[key], item])).values()
              ];
              response.data.data.providers.forEach(provider => {
                apiProviderMapping[provider.api] =
                  apiProviderMapping[provider.api] || [];
                apiProviderMapping[provider.api].push(provider);
              });
            }
          }

          if (!this.props.solution.oneToOneUI) {
            for (let [key, value] of Object.entries(solutionsConsent.data)) {
              if (response.data.data && response.data.data.providers) {
                response.data.data.providers.forEach(provider => {
                  if (provider.id === value.apiProviderId) {
                    apiProviderMapping[provider.api] =
                      apiProviderMapping[provider.api] || [];
                    apiProviderMapping[provider.api].push(provider);
                  }
                });
              }
            }
          }
        } else if (this.isPartnerProgram()) {
          if (response.data && response.data.providers) {
            response.data.providers.forEach(provider => {
              apiProviderMapping[provider.api] =
                apiProviderMapping[provider.api] || [];
              apiProviderMapping[provider.api].push(provider);
            });
          }
        }
      }
    }
    this.setState({
      apiProviderMapping: apiProviderMapping,
      loading: false,
      isConsentLoaded: true
    });
  }

  getConsentedApiNotFoundMessage() {
    const {
      solution: {
        overview: { publisherName }
      }
    } = this.props;
    return `${FortellisConstants.CONSENT_NOT_FOUND_MSG_PART1}
        ${publisherName ? publisherName : 'publisher'}${
      FortellisConstants.CONSENT_NOT_FOUND_MSG_PART2
    }`;
  }

  componentDidMount() {
    if (!this.props.solution || !this.props.solution.overview) {
      return null;
    }
    this.fetchProviders(this.getApis(this.props.solution));
  }

  render() {
    const { solution } = this.props;
    if (!solution || !solution.overview) {
      return null;
    }
    return (
      <div>
        <div>
          <div className="feature-title">
            {this.getTitleMessage(solution.overview)}
          </div>
          <div className="apiInfo-subtitle">
            {this.isFortellisSolution() ? (
              <FormattedMessage
                id="ApiInfo.helpText"
                defaultMessage={`This app uses the following APIs published on the Fortellis platform. Each API can have one or more providers. Please check if your preferred app provider is in the list because the selection of the API provider will be required while configuring the app subscription.`}
              />
            ) : (
              <FormattedMessage
                id="ApiInfo.apiUsedByIntegrationMessage"
                defaultMessage={`This app uses external APIs which are not integrated through the Fortellis platform. This app integrates with the following applications.`}
              />
            )}
          </div>
          <div className="api-info-table">
            {!this.state.isConsentLoaded ? (
              <div className="spinner-container-width">
                <Loader
                  label="Loading..."
                  className="spinner"
                  //variant="inline"
                />
              </div>
            ) : (
              <SimpleTableWrapper data-testid="apiInfo-simple-table-wrapper">
                <SimpleTableContainer>
                  <SimpleTable aria-label="ApiInfo-Table">
                    <SimpleTableHead>
                      <SimpleTableRow>
                        <SimpleTableHeader
                          className={
                            this.isFortellisSolution()
                              ? 'api-info-table-cell-25'
                              : 'api-info-table-cell-50'
                          }
                          colSpan="1"
                        >
                          {this.isFortellisSolution() ? (
                            <FormattedMessage
                              id="ApiInfo.apiName"
                              defaultMessage="Fortellis APIs"
                            />
                          ) : (
                            <FormattedMessage
                              id="ApiInfo.applicationiName"
                              defaultMessage="Application Name"
                            />
                          )}
                        </SimpleTableHeader>
                        <SimpleTableHeader
                          className="api-info-table-cell-25"
                          data-cy="th_api_info_description"
                          colSpan="1"
                        >
                          <FormattedMessage
                            id="ApiInfo.description"
                            defaultMessage="Description"
                          />
                        </SimpleTableHeader>
                        <SimpleTableHeader
                          className="api-info-table-cell-25"
                          colSpan="1"
                          data-cy="th_api_info_provider"
                        >
                          <FormattedMessage
                            id="ApiInfo.providers"
                            defaultMessage="Providers"
                          />
                        </SimpleTableHeader>
                      </SimpleTableRow>
                    </SimpleTableHead>
                    <SimpleTableBody>
                      {this.state.isConsentLoaded === true &&
                        this.getApis(solution).map((api, index) => {
                          // if we can't find a matching provider, don't even show the api
                          if (
                            this.isFortellisSolution() &&
                            !this.state?.providersData?.providers?.find(
                              provider =>
                                removeApiV2Prefix(provider.id) ===
                                removeApiV2Prefix(api.id)
                            )
                          ) {
                            return null;
                          }
                          return (
                            <ApiInfoSimpleTableRow
                              data-testid="apiInfo-simple-table-row"
                              key={index}
                            >
                              <SimpleTableData
                                className={
                                  this.isFortellisSolution()
                                    ? 'api-info-table-cell-25'
                                    : 'api-info-table-cell-50'
                                }
                              >
                                <div className="apiInfo-table-body-api-info">
                                  {this.isFortellisSolution() &&
                                  this.state?.providersData?.providers
                                    ? this.state.providersData.providers.map(
                                        provider => {
                                          if (
                                            removeApiV2Prefix(provider.id) ===
                                            removeApiV2Prefix(api.id)
                                          ) {
                                            return (
                                              <div>
                                                <p>
                                                  <a
                                                    href={`${
                                                      environmentURLs.api_reference_URL
                                                    }${
                                                      provider.apiType === 'api'
                                                        ? 'apis'
                                                        : 'async-apis'
                                                    }/${removeApiV2Prefix(
                                                      api.id
                                                    )}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={() =>
                                                      this.createAndSubmitAmplitudeData(
                                                        api
                                                      )
                                                    }
                                                  >
                                                    {' '}
                                                    {provider.displayName ||
                                                      api.displayName}{' '}
                                                    <i className="material-icons external-link-icon">
                                                      open_in_new
                                                    </i>{' '}
                                                  </a>

                                                  <br />
                                                </p>
                                              </div>
                                            );
                                          }
                                        }
                                      )
                                    : api.applicationName}
                                </div>
                              </SimpleTableData>

                              <SimpleTableData className="api-info-table-cell-25">
                                <div className="apiInfo-table-body-api-info">
                                  <ReadMore
                                    textLimit={APIDescriptionTextLimit}
                                    text={(api && api.description) || ''}
                                  />
                                </div>
                              </SimpleTableData>

                              <SimpleTableData
                                className={
                                  this.isFortellisSolution()
                                    ? 'api-info-table-cell-25'
                                    : 'api-info-table-cell-50'
                                }
                              >
                                {this.isFortellisSolution() ? (
                                  <div className="apiInfo-table-body-providers">
                                    {this.state.apiProviderMapping &&
                                      Object.keys(
                                        this.state.apiProviderMapping
                                      ).map(apiId => {
                                        return apiId === api.id ? (
                                          <div>
                                            {this.state.apiProviderMapping[
                                              api.id
                                            ].map(provider => {
                                              if (
                                                provider.apiType === 'async-api'
                                              ) {
                                                return (
                                                  <div className="api-info-table-body-providers-item">
                                                    {`${
                                                      provider.providers[0].name
                                                    }`}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="api-info-table-body-providers-item">
                                                    {`${provider.name}`}
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        ) : null;
                                      })}
                                  </div>
                                ) : (
                                  <div>{api.dataProvider}</div>
                                )}
                                {this.state.apiProviderMapping === null ||
                                this.state.apiProviderMapping === undefined ||
                                Object.keys(this.state.apiProviderMapping)
                                  .length === 0 ? (
                                  <span>
                                    {this.props.solution &&
                                    this.props.solution.oneToOneUI ? (
                                      <div>
                                        {this.state.apiProviderMapping &&
                                        this.state.apiProviderMapping[api.id]
                                          ? this.state.apiProviderMapping[
                                              api.id
                                            ].map(provider => {
                                              return (
                                                <div>{`${provider.name}`}</div>
                                              );
                                            })
                                          : this.getConsentedApiNotFoundMessage()}
                                      </div>
                                    ) : this.isFortellisSolution() ? (
                                      this.getConsentedApiNotFoundMessage()
                                    ) : null}
                                  </span>
                                ) : null}
                              </SimpleTableData>
                            </ApiInfoSimpleTableRow>
                          );
                        })}
                    </SimpleTableBody>
                  </SimpleTable>
                </SimpleTableContainer>
              </SimpleTableWrapper>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withApollo(withAuth(ApiInfo)));
