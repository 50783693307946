import React, { useState } from 'react';
import { Checkbox } from 'cdk-radial';
import CheckboxWrapper from './CheckboxWrapper';

const CDKPublisherGroup = ({
  cdkPublishers = [],
  onFilterSelect,
  sectionTitle,
  checkedFilters
}) => {
  const spaceSeparator = ' ';
  const cdkText = 'cdk';
  const shouldExpand = () => {
    if (checkedFilters) {
      for (const publisher in checkedFilters['Publishers']) {
        let name = checkedFilters['Publishers'][publisher].name;
        if (
          name &&
          name
            .trim()
            .toLowerCase()
            .split(spaceSeparator)[0] === cdkText
        ) {
          return true;
        }
      }
    }
  };

  const shouldIndeterminate = cdkPublishers => {
    let numberOfSelectedCDKPublishers = 0;
    let totalNumberOfCDKPublishers = cdkPublishers.length;
    if (checkedFilters) {
      for (const publisher in checkedFilters['Publishers']) {
        let name = checkedFilters['Publishers'][publisher].name;
        if (
          name &&
          name
            .trim()
            .toLowerCase()
            .split(spaceSeparator)[0] === cdkText
        ) {
          numberOfSelectedCDKPublishers++;
        }
      }
    }
    if (
      numberOfSelectedCDKPublishers !== totalNumberOfCDKPublishers &&
      numberOfSelectedCDKPublishers !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [isExpanded, setIsExpanded] = useState(shouldExpand());
  const [isSelectorChecked, setIsSelectorChecked] = useState(false);
  const [checkedPublishers, setCheckedPublishers] = useState(
    cdkPublishers.length
  );
  const [isIndeterminate, setIsIndeterminate] = useState(
    shouldIndeterminate(cdkPublishers)
  );

  return (
    <div key={cdkPublishers.name}>
      <Checkbox
        data-qa={
          cdkPublishers.name &&
          `${cdkPublishers.name.replace(/ /g, '_')}-checkbox`
        }
        checked={isSelectorChecked || isExpanded}
        onChange={event => {
          setCheckedPublishers(cdkPublishers.length);
          setIsSelectorChecked(event.target.checked);
          isExpanded ? setIsExpanded(false) : setIsExpanded(true);
          if (event.target.checked && !isIndeterminate) {
            let publisherObj = {};
            cdkPublishers.map(publisher => {
              publisherObj[publisher.id] = publisher;
            });
            onFilterSelect(true, publisherObj, sectionTitle, true);
          } else {
            let publisherObj = {};
            cdkPublishers.map(publisher => {
              publisherObj[publisher.id] = false;
            });
            onFilterSelect(false, publisherObj, sectionTitle, true, true);
          }
        }}
        style={{ '--mdc-ripple-fg-size': '0px' }}
        label={'CDK Global'}
        indeterminate={isIndeterminate}
      />
      {isExpanded &&
        cdkPublishers.map(publisher => (
          <CheckboxWrapper
            key={publisher.name}
            style={{ marginLeft: '20px' }}
            publisher={publisher}
            onFilterSelect={onFilterSelect}
            sectionTitle={sectionTitle}
            numberOfPublishers={cdkPublishers.length}
            publisherUnchecked={publisher => {
              let numberOfCheckedPublishers = checkedPublishers - 1;
              setCheckedPublishers(numberOfCheckedPublishers);
              if (numberOfCheckedPublishers === 0) {
                setIsSelectorChecked(false);
                setIsExpanded(false);
              }
              onFilterSelect(false, publisher, sectionTitle);
            }}
            publisherChecked={() => {
              setCheckedPublishers(checkedPublishers + 1);
            }}
            checkedFilters={checkedFilters}
            isSelectorChecked={isSelectorChecked}
          />
        ))}
    </div>
  );
};

export default CDKPublisherGroup;
