import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";

class WhatsNew extends React.Component {
  state = {
    displayReadMore: false,
    displayReadLess: false,
    updateBulletsLimit: 3
  };

  componentDidMount() {
    if (
      this.props.listingVersionUpdates &&
      this.props.listingVersionUpdates.updates &&
      this.props.listingVersionUpdates.updates.length
    ) {
      if (
        this.props.listingVersionUpdates.updates.length >
        this.state.updateBulletsLimit
      ) {
        this.setState({
          displayReadMore: true,
          displayReadLess: false
        });
      }
    }
  }

  getUpdatesToDisplay = updates => {
    if (this.state.displayReadMore && !this.state.displayReadLess) {
      return updates.slice(0, 3);
    } else {
      return updates;
    }
  };

  onReadMoreClick = () => {
    this.setState({
      displayReadMore: false,
      displayReadLess: true
    });
  };

  onReadLessClick = () => {
    this.setState({
      displayReadMore: true,
      displayReadLess: false
    });
  };

  render() {
    const { overview, listingVersionUpdates, lastUpdatedOn } = this.props;
    const { displayReadMore, displayReadLess } = this.state;

    let updates = [];
    if (
      listingVersionUpdates &&
      listingVersionUpdates.updates &&
      listingVersionUpdates.updates.length
    ) {
      if (
        this.props.listingVersionUpdates.updates.length >
        this.state.updateBulletsLimit
      ) {
        updates = this.getUpdatesToDisplay(
          this.props.listingVersionUpdates.updates
        );
      } else {
        updates = listingVersionUpdates.updates;
      }
    }

    return (
      <div>
        {listingVersionUpdates &&
        (listingVersionUpdates.description ||
          (listingVersionUpdates.updates &&
            listingVersionUpdates.updates.length)) ? (
          <div>
            <div id="solutionDetails" className="whats-new-section">
              <div className="title">
                <FormattedMessage
                  id="ListingVersionUpdates.whatsnew.title1"
                  defaultMessage="Whats New"
                />{" "}
                {overview &&
                  overview.version && (
                    <span>
                      <FormattedMessage
                        id="ListingVersionUpdates.whatsnew.title2"
                        defaultMessage="in version"
                      />{" "}
                      {overview.version}
                    </span>
                  )}
              </div>

              {lastUpdatedOn ? (
                <div className="sub-title">
                  <FormattedMessage
                    id="ListingVersionUpdates.whatsnew.releaseDate"
                    defaultMessage="Released on"
                  />{" "}
                  {moment(lastUpdatedOn).format("DD MMM YYYY")}
                </div>
              ) : (
                false
              )}

              {listingVersionUpdates.description ? (
                <div className="description">
                  {listingVersionUpdates.description}
                </div>
              ) : (
                false
              )}

              {updates && updates.length ? (
                <div className="whats-new-list-items">
                  <ul>
                    {updates.map((versionUpdate, index) => {
                      return <li key={index}>{versionUpdate.updateText}</li>;
                    })}
                  </ul>

                  {displayReadMore && (
                    <div>
                      <span
                        className="read-more-less-text"
                        onClick={this.onReadMoreClick}
                        id="ReadMoreSpan"
                      >
                        <FormattedMessage
                          id="ReadMore"
                          defaultMessage="Read more"
                        />
                      </span>
                    </div>
                  )}

                  {displayReadLess && (
                    <div>
                      <span
                        className="read-more-less-text"
                        onClick={this.onReadLessClick}
                        id="ReadLessSpan"
                      >
                        <FormattedMessage
                          id="ReadLess"
                          defaultMessage="Read less"
                        />
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}
export default injectIntl(WhatsNew);
