import React, { useEffect, useRef, useState } from 'react';
import { Checkbox } from '@cdk-uip/react-checkbox';
import FormCaption from '../../solution-submission/common-form-api/FormComponents/FormCaption';
import FormInputSlider from '../../solution-submission/common-form-api/FormComponents/FormInputSlider';
import PaymentInfoConstants from './PaymentInfoConstants';

const ONLINE = PaymentInfoConstants.paymentMode.ONLINE;
const OFFLINE = PaymentInfoConstants.paymentMode.OFFLINE;
const OFFLINE_AND_ONLINE = PaymentInfoConstants.paymentMode.OFFLINE_AND_ONLINE;

const STRIPE = PaymentInfoConstants.paymentOption.STRIPE;
const CDK = PaymentInfoConstants.paymentOption.CDK;

function FormCaptionFormField(props) {
  return (
    <div className="formFields">
      <div className="layoutcell layoutcellRadio">
        <div className="layoutcell layoutcellRadio-online">
          <FormCaption title={props.title} style={props.style} />
        </div>
      </div>
    </div>
  );
}

export default function PaymentAdminControl(props) {
  let { paymentMode = '', paymentOption = {} } = props.formFieldValues;
  paymentMode = paymentMode !== null ? paymentMode : '';
  paymentOption = paymentOption !== null ? paymentOption : {};
  //initial paymentMode checkbox state
  let initialModeCheckboxState;
  if (paymentMode === ONLINE) {
    initialModeCheckboxState = [true, false];
  } else if (paymentMode === OFFLINE) {
    initialModeCheckboxState = [false, true];
  } else if (paymentMode === OFFLINE_AND_ONLINE) {
    initialModeCheckboxState = [true, true];
  } else {
    initialModeCheckboxState = [false, false];
  }
  //for handelling the edge case.( if the paymentOption property values have null / undefined values..)
  (paymentOption[STRIPE] === null || paymentOption[STRIPE] === undefined) &&
    delete paymentOption[STRIPE];
  (paymentOption[CDK] === null || paymentOption[CDK] === undefined) &&
    delete paymentOption[CDK];

  const [paymentModeState, setPaymentMode] = useState(paymentMode);
  const [paymentOptionState, setPaymentOption] = useState(paymentOption);
  const [modeCheckboxState, setmodeCheckboxState] = useState(
    initialModeCheckboxState
  );

  //change payment mode according to mode checkbox state
  useEffect(() => {
    const formFieldValues = props.formFieldValues;
    if (modeCheckboxState[0] === false && modeCheckboxState[1] === true) {
      //clearing the paymentOptions info..
      formFieldValues.paymentOption = {};
      setPaymentOption({});
      formFieldValues.paymentMode = OFFLINE;
      setPaymentMode(OFFLINE);
    } else if (
      modeCheckboxState[0] === true &&
      modeCheckboxState[1] === false
    ) {
      formFieldValues.paymentMode = ONLINE;
      setPaymentMode(ONLINE);
    } else if (modeCheckboxState[0] === true && modeCheckboxState[1] === true) {
      formFieldValues.paymentMode = OFFLINE_AND_ONLINE;
      setPaymentMode(OFFLINE_AND_ONLINE);
    } else {
      formFieldValues.paymentOption = {};
      setPaymentOption({});
      formFieldValues.paymentMode = '';
      setPaymentMode();
    }
  }, [modeCheckboxState]);

  //Offline as default
  useEffect(() => {
    const formFieldValues = props.formFieldValues;
    setmodeCheckboxState([false, true]);
    formFieldValues.paymentMode = OFFLINE;
    formFieldValues.paymentOption = {};
    props.fieldProperties.disabled = true;
    setPaymentMode(OFFLINE);
  }, [paymentModeState, modeCheckboxState]);

  //handles Payment Mode Checkbox (Online, Offline)
  const handleModeCheckbox = (event, payment_mode_value) => {
    const [online, offline] = modeCheckboxState;
    if (payment_mode_value === ONLINE && event.target.checked) {
      setmodeCheckboxState([true, offline]);
    } else if (payment_mode_value === ONLINE && !event.target.checked) {
      setmodeCheckboxState([false, offline]);
    }
    if (event.target.checked && payment_mode_value === OFFLINE) {
      setmodeCheckboxState([online, true]);
    } else if (!event.target.checked && payment_mode_value === OFFLINE) {
      setmodeCheckboxState([online, false]);
    }
  };

  //handles Payment Option Checkbox (Stripe, CDK)
  const handleOptionCheckbox = (event, payment_option_value) => {
    const formFieldValues = props.formFieldValues;
    if (event.target.checked) {
      //assigning value = 0 , it will be used as a intial value for the input slider
      formFieldValues.paymentOption[payment_option_value] = 0;
      setPaymentOption({ ...formFieldValues.paymentOption });
    } else {
      delete formFieldValues.paymentOption[payment_option_value];
      setPaymentOption({ ...formFieldValues.paymentOption });
    }
  };

  const onInputSliderChange = (sliderValue, paymentOption_value) => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.paymentOption[paymentOption_value] = sliderValue;
    setPaymentOption({ ...formFieldValues.paymentOption });
  };

  return (
    <div>
      <div className="formFields">
        <div className="layoutcell">
          <div className="layoutcell">
            <div
              className="form-field-with-tooltip"
              id={'online-payment-checkbox'}
            >
              <Checkbox
                className="paymentmode-checkbox"
                checked={modeCheckboxState[0]}
                disabled={props.fieldProperties.disabled}
                required={props.fieldProperties.required}
                onChange={event => {
                  handleModeCheckbox(event, ONLINE);
                }}
              />
              <label className="radio-item-label">
                {PaymentInfoConstants.ONLINE_PAYMENT}
              </label>
            </div>
          </div>
        </div>
        <FormCaptionFormField
          title={'Please select payment method along with Revenue share %'}
          style={'provisioningcaption'}
        />

        <div>
          <span className="form-content-divider" />
          <div className="online-mode-checkboxes">
            <div className="checkbox-slider-parent">
              <div
                className={`form-field-with-tooltip check-box-div stripe-checkbox`}
                id={'stripe-checkbox-div'}
              >
                <Checkbox
                  className="check-box-item-checkBox"
                  checked={paymentOptionState.hasOwnProperty(STRIPE)}
                  disabled={!modeCheckboxState[0]}
                  required={props.fieldProperties.required}
                  onChange={event => {
                    handleOptionCheckbox(event, STRIPE);
                  }}
                />
                <label className="check-box-item-label">
                  {PaymentInfoConstants.Stripe}
                </label>
              </div>

              <div className="revenue-slider">
                <FormInputSlider
                  initialValue={
                    paymentOptionState[STRIPE] ? paymentOptionState[STRIPE] : 0
                  }
                  paymentMode={paymentModeState}
                  paymentOption={STRIPE}
                  onInputSliderChange={onInputSliderChange}
                  isShow={paymentOptionState.hasOwnProperty(STRIPE)}
                  disabled={props.fieldProperties.disabled}
                />
                <label className="check-box-item-label">{'%'}</label>
              </div>
            </div>

            <div className="checkbox-slider-parent">
              <div
                className={`form-field-with-tooltip check-box-div cdk-checkbox`}
                id={'cdk-checkbox-div'}
              >
                <Checkbox
                  className="check-box-item-checkBox"
                  checked={paymentOptionState.hasOwnProperty(CDK)}
                  disabled={!modeCheckboxState[0]}
                  required={props.fieldProperties.required}
                  onChange={event => {
                    handleOptionCheckbox(event, CDK);
                  }}
                />
                <label className="check-box-item-label">
                  {PaymentInfoConstants.Cdk}
                </label>
              </div>

              <div className="revenue-slider">
                <FormInputSlider
                  initialValue={
                    paymentOptionState[CDK] ? paymentOptionState[CDK] : 0
                  }
                  paymentMode={paymentModeState}
                  paymentOption={CDK}
                  onInputSliderChange={onInputSliderChange}
                  isShow={paymentOptionState.hasOwnProperty(CDK)}
                  disabled={props.fieldProperties.disabled}
                />
                <label className="check-box-item-label">{'%'}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formFields">
        <span className="form-content-divider" />
        <div className="layoutcell">
          <div className="layoutcell">
            <div
              className="form-field-with-tooltip"
              id={'offline-payment-checkbox'}
            >
              <Checkbox
                className="paymentmode-checkbox"
                checked={modeCheckboxState[1]}
                disabled={props.fieldProperties.disabled}
                required={props.fieldProperties.required}
                onChange={event => {
                  handleModeCheckbox(event, OFFLINE);
                }}
              />
              <label className="offline-checkbox-label">
                {PaymentInfoConstants.OFFLINE_PAYMENT}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
