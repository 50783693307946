import React from "react";
import { Title } from "@cdk-uip/react-typography";
import FormDeleteIcon from "./FormDeleteIcon";
import { FormattedMessage, injectIntl } from "react-intl";

export class FormDeleteLabel extends React.Component {
  render() {
    return this.props.show ? (
      <div className="dynamic-label-grid">
        <Title className="dynamic-label-title">
          {this.props.label}
          {!this.props.disableDelete && (
            <FormDeleteIcon
              confirmMessageTitle={
                <div>
                  <FormattedMessage
                    id="Generic.delete"
                    defaultMessage="Delete"
                  />{" "}
                  {this.props.activeFormLabel}
                </div>
              }
              confirmMessage={
                <div>
                  <FormattedMessage
                    id="Generic.deleteMessage"
                    defaultMessage="This action cannot be undone. Are you sure you want to delete this"
                  />{" "}
                  {this.props.activeFormLabel}?
                </div>
              }
              onDelete={this.props.onDelete}
              deleteIconStyle="delete-icon"
            />
          )}
        </Title>
      </div>
    ) : (
      false
    );
  }
}

export default injectIntl(FormDeleteLabel);
