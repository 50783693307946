import React from 'react';
import { injectIntl } from 'react-intl';
import { CardActions } from '@cdk-uip/react-card';
import { Button } from '@cdk-uip/react-button';
import SubscriptionPlanView from './subscription-plan-view';
import SubscriptionCancelDialog from './subscription-cancel-dialog';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../containers/GraphQLClient';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ApiConfigStatusForSubscriptionConstants from '../../common/ApiConfigStatusForSubscriptionConstants';

const cancelSolutionSubscription = gql`
  mutation($id: ID!, $subscriptionId: ID, $status: String!) {
    cancelSolutionSubscription(
      command: { id: $id, subscriptionId: $subscriptionId, status: $status }
    ) {
      id
      subscriptionId
      status
    }
  }
`;

class MySubscriptionListItemActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPlan: '',
      cancelSubscription: false,
      redirectToConfiguration: false
    };
    this.onClose = () => {
      this.setState({ viewPlan: '' });
    };
    this.onCloseCancelSubscription = () => {
      this.setState({ cancelSubscription: false });
    };
    this.onSelectCancelSubscription = async () => {
      const client = getApolloClient();
      try {
        await client.mutate({
          mutation: cancelSolutionSubscription,
          variables: {
            id: this.props.subscription.id,
            subscriptionId: this.props.subscription.subscriptionId,
            status: ApiConfigStatusForSubscriptionConstants.CANCELLED
          }
        });
      } catch (e) {
        console.error(e);
      }
      window.location.reload();
      this.setState({ cancelSubscription: false });
    };
    this.redirectToApiConfig = () => {
      const { subscription } = this.props;
      this.props.history.push(
        `/marketplace-account/mysubscriptions/${
          subscription.subscriptionId
        }/apiconfig`
      );
    };
  }

  render() {
    const { subscription } = this.props;
    return (
      <div>
        {subscription.solution ? (
          <CardActions className="subscription-card-action-panel">
            <SubscriptionPlanView
              solution={subscription.solution}
              plan={subscription.plan}
              open={this.state.viewPlan === subscription.id}
              onClose={this.onClose}
            />
            <SubscriptionCancelDialog
              solutionName={subscription.solution.overview.name}
              open={this.state.cancelSubscription}
              onClose={this.onCloseCancelSubscription}
              onApprove={this.onSelectCancelSubscription}
            />
            {subscription.status ===
              ApiConfigStatusForSubscriptionConstants.EXPIRED ||
            subscription.status ===
              ApiConfigStatusForSubscriptionConstants.CANCELLED ? (
              <Button
                className="card-action-button"
                onClick={() => {
                  this.props.history.push(
                    `/solutions/${subscription.solution.id}`
                  );
                }}
              >
                <FormattedMessage
                  id="SubscriptionList.button.subscribe"
                  defaultMessage="Subscribe"
                />
              </Button>
            ) : null}
            <Button
              className="card-action-button"
              onClick={() => {
                this.setState({ viewPlan: subscription.id });
              }}
            >
              <FormattedMessage
                id="SubscriptionList.button.viewPlan"
                defaultMessage="View Plan"
              />
            </Button>
            {subscription.status ===
              ApiConfigStatusForSubscriptionConstants.ACTIVE && (
              <Button
                className="card-action-button"
                onClick={this.redirectToApiConfig}
              >
                <FormattedMessage
                  id="SubscriptionList.button.viewConfiguration"
                  defaultMessage="View Configuration"
                />
              </Button>
            )}
            {subscription.status !==
              ApiConfigStatusForSubscriptionConstants.EXPIRED &&
              subscription.status !==
                ApiConfigStatusForSubscriptionConstants.CANCELLED &&
              subscription.status !==
                ApiConfigStatusForSubscriptionConstants.PENDING_ACTIVATION &&
              this.props.userPermissions &&
              this.props.userPermissions.includes('ConfigureSubscription') && (
                <Button
                  className="card-action-button"
                  onClick={() => {
                    this.setState({ cancelSubscription: true });
                  }}
                >
                  <FormattedMessage
                    id="SubscriptionList.button.cancelSubscription"
                    defaultMessage="Cancel Subscription"
                  />
                </Button>
              )}
          </CardActions>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default injectIntl(withRouter(MySubscriptionListItemActions));
