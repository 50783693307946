import React from 'react';
import gql from 'graphql-tag';
import Spinner from '../common/Spinner';
import { withApollo } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import { APIProviderConsent } from '../common/APIProviderConsent';
import FortellisDialog from '../common/FortellisDialog';
import { TemporarySnackbar } from '@cdk-uip/react-snackbar';
import SolutionOperations from '../../containers/utils/SolutionOperations';
import {
  templateConfig,
  attachmentConfig,
  toAddressConfig,
  subscriptionDetailsConfig,
  appDetailsConfig,
  apiDetailsConfig,
  emailType_internal_alert_app_publisher_signed_api_terms,
  subject_internal_alert_app_publisher_signed_api_terms,
  emailType_internal_alert_app_publisher_disabled_an_api,
  subject_internal_alert_app_publisher_disabled_an_api
} from '../common/SolutionStatus';
import axios from 'axios';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow
} from '@cdk-uip/react-data-table';
import moment from 'moment';
import ConsentTermsViewer from '../common/ConsentTermsViewer';
import Button from '../common/Button';
import UserConsentDialog from '../common/UserConsentDialog';
import { Dialog, DialogBody } from '@cdk-uip/react-dialog';
import SolutionStatus from '../common/SolutionStatus';
import Moment from 'react-moment';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { PageSpinner } from '@cdk-uip/react-page-spinner';
import { ThreeDots } from '@bit/mhnpd.react-loader-spinner.three-dots';
import ProviderPricing from './ProviderPricing';
import AcceptedPricing from './AcceptedPricing';
import ConsentTypeConstants from '../common/constants/ConsentTypeConstants';
import { Checkbox } from '@cdk-uip/react-checkbox';
import ActionSnackBar from './ActionSnackBar';
import { connect } from 'react-redux';
import { fetchAcceptedRatePlans } from '../../redux/connectedComponents/appSubmission/appSubmission.slice';
import { getApolloClient } from '../../containers/GraphQLClient';

let consentColor = '';
let message = '';
let consentObj = null;
let providerRatePlans = null;
let selectedPlan = null;
let isratePlansAvailable = false;

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: '-3px'
  }
}))(Tooltip);

const providerConsentStatusConst = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  REVOKED: 'revoked'
};

export class ProviderAndConsentList extends React.Component {
  state = {
    openConsentTerms: false,
    isDialogToRevokeConsentOpen: false,
    isPDFConsentDialogOpen: false,
    isNoPDFConsentDialogOpen: false,
    selectedProvider: null,
    isDeleted: null,
    deleteFailed: null,
    openSpinnerForConsent: false,
    openSpinnerForRevokingConsent: false,
    isApiConsentModified: false,
    consentCount: 1,
    loadingRatePlans: false,
    loadingAcceptedRatePlans: false,
    acceptedRatePlans: null,
    showRatePlansDialog: false,
    chosenPlan: false,
    selectedProviderId: null,
    doRatePlansExist: false,
    showAcceptedPricing: false,
    new_RatePlaneSelectedValueMapping: null,
    signedTermsAndConditionsS3: null,
    pricingPlans: []
  };

  async componentDidMount() {
    this.solutionConfig = new SolutionOperations();
    this.loadRatePlans();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.formFieldValues) !==
      JSON.stringify(this.props.formFieldValues)
    ) {
      this.setIsSubmitEnabled();
    }
    this.loadRatePlans();
  }

  getOneToOneStatus = () => {
    if (this.props) {
      const { solution } = this.props;
      if (solution && solution.length > 0) {
        const oneToOne = solution[0].oneToOneUI;
        if (oneToOne && oneToOne === true) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  loadRatePlans() {
    const sRatePlans = this.state.acceptedRatePlans;
    const pRatePlans = this.props.acceptedRatePlans;
    const loadingRatePlan = this.state.loadingAcceptedRatePlans;

    const sRatePlansLen = sRatePlans ? sRatePlans.length : 0;
    const pRatePlansLen = pRatePlans ? pRatePlans.length : 0;

    const {
      auth: { accessToken },
      entity
    } = this.props;

    let entityId = entity
      ? entity.id
      : !!this.props &&
        this.props.formAllValues &&
        this.props.formAllValues.orgId;

    if (
      !!entityId &&
      !sRatePlans &&
      !loadingRatePlan &&
      !!pRatePlans &&
      pRatePlans.length === 0
    ) {
      this.props.fetchAcceptedRatePlans(entityId, accessToken);
      this.setState({ loadingAcceptedRatePlans: true });
    }
    if (!sRatePlans && !this.state.isPlanLoaded) {
      this.props.fetchAcceptedRatePlans(entityId, accessToken);
      this.setState({ isPlanLoaded: true });
    }

    if (!sRatePlans && !!pRatePlans) {
      this.setState({
        acceptedRatePlans: this.props.acceptedRatePlans,
        loadingAcceptedRatePlans: false
      });
      return;
    }

    if (pRatePlansLen > sRatePlansLen) {
      this.setState({
        acceptedRatePlans: this.props.acceptedRatePlans,
        loadingAcceptedRatePlans: false
      });
    }
  }

  async getProviderRatePlans(providerId) {
    const {
      auth: { accessToken }
    } = this.props;

    const response = await axios.get(
      `${environmentURLs.providerServiceURL}providers/${providerId}/rate-plans`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : ''
        }
      }
    );
    return response ? response.data : [];
  }

  setPlansExistenceStatus = providerId => {
    let transactionalPlan, subscriptionalPlan, freePlan;

    this.setState({
      loadingRatePlans: false,
      showRatePlansDialog: true,
      selectedProviderId: providerId
    });

    providerRatePlans.forEach(ratePlan => {
      if (ratePlan.visibility === 'public') {
        if (ratePlan.type === 'Tiered') {
          transactionalPlan = ratePlan;
        } else if (ratePlan.type === 'Subscription') {
          subscriptionalPlan = ratePlan;
        } else if (ratePlan.type === 'Free') {
          freePlan = ratePlan;
        }
      }
    });
    if (transactionalPlan || subscriptionalPlan || freePlan) {
      this.setState({ doRatePlansExist: true });
    } else {
      this.setState({ doRatePlansExist: false });
    }
  };

  async getPricingPlans(providerId) {
    this.setState({ loadingRatePlans: true });
    let pPlan = this.state.pricingPlans.find(
      pp => pp.providerId === providerId
    );
    if (!!pPlan) {
      providerRatePlans = pPlan['providerRatePlans'];
      this.setPlansExistenceStatus(providerId);
    } else {
      let ratePlans = await this.getProviderRatePlans(providerId);
      Promise.all(ratePlans)
        .then(ratePlans => {
          providerRatePlans = ratePlans;

          if (!providerRatePlans || !providerRatePlans.length) {
            this.setState({
              doRatePlansExist: false,
              chosenPlan: null,
              loadingRatePlans: false,
              showRatePlansDialog: true,
              selectedProviderId: providerId
            });
          } else {
            this.state.pricingPlans.push({
              providerId: providerId,
              providerRatePlans: providerRatePlans
            });
            this.setPlansExistenceStatus(providerId);
          }
        })
        .catch(err => {
          this.setState({ doRatePlansExist: false });
        });
    }
  }

  onChoosingPlan = chosenPlan => {
    const {
      auth: { accessToken },
      entity
    } = this.props;
    if (chosenPlan) {
      if (
        this.state.acceptedRatePlans &&
        this.state.acceptedRatePlans.find(
          acceptedPlan =>
            acceptedPlan.providerId === this.state.selectedProviderId
        )
      ) {
        // let existingMapping = JSON.parse(
        //   JSON.stringify(
        //     this.state.acceptedRatePlans.find(
        //       acceptedPlan =>
        //         acceptedPlan.providerId === this.state.selectedProviderId
        //     )
        //   )
        // );
        // existingMapping.ratePlan = chosenPlan;
        let newPlanMapping = this.state.acceptedRatePlans.map(acceptedPlan => {
          if (acceptedPlan.providerId === this.state.selectedProviderId) {
            acceptedPlan.ratePlan = { ...chosenPlan };
          }
          return acceptedPlan;
        });
        this.setState({
          acceptedRatePlans: newPlanMapping
        });
      } else {
        let newMapping = {
          orgId: entity.id,
          providerId: this.state.selectedProviderId,
          ratePlan: chosenPlan
        };
        this.setState({
          acceptedRatePlans: this.isIterable(this.state.acceptedRatePlans)
            ? [...this.state.acceptedRatePlans, newMapping]
            : [newMapping]
        });
      }
    }
  };

  isIterable = object =>
    object != null && typeof object[Symbol.iterator] === 'function';

  showAcceptedRatePlans(selectedPlan, providerId) {
    this.setState({ showAcceptedPricing: true });
    if (providerId === selectedPlan.providerId) {
      this.setState({ selectedPlan: selectedPlan });
    }
  }

  isConsentEnableBtnDisabled = (providerId, chosenPlan, selectedPlan) => {
    let value = null;
    if (providerId && selectedPlan) {
      if (providerId !== selectedPlan.providerId) {
        if (
          this.state.selectedProviderId &&
          this.state.selectedProviderId === providerId
        ) {
          if (!this.state.doRatePlansExist && !chosenPlan) {
            value = true;
          }
          if (this.state.doRatePlansExist) {
            if (chosenPlan) {
              value = false;
            } else {
              value = true;
            }
          }
        } else {
          value = true;
        }
      }
    } else {
      if (
        this.state.selectedProviderId &&
        this.state.selectedProviderId === providerId
      ) {
        if (!this.state.doRatePlansExist && !chosenPlan) {
          value = true;
        }
        if (this.state.doRatePlansExist) {
          if (chosenPlan) {
            value = false;
          } else {
            value = true;
          }
        }
      } else {
        value = true;
      }
    }
    // this.setState({
    //   selectedProvider:itemIdex
    // })
    return value;
  };

  savePlan = async (providerId, planToBeSaved) => {
    const {
      auth: { accessToken },
      entity
    } = this.props;

    let postData = {
      orgId: entity.id,
      providerId: providerId,
      ratePlanId: planToBeSaved.ratePlan.id
    };
    let postHeaders = {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json'
      }
    };
    axios
      .post(
        `${environmentURLs.providerServiceURL}accepted-rate-plans`,
        postData,
        postHeaders
      )
      .then(response => {
        if (response) {
          this.setState({
            acceptedRatePlans: [...this.state.acceptedRatePlans, response.data]
          });
        }
      })
      .catch(err => {});
  };
  onConsentResponse = async (data, selectedProvider) => {
    if (!this.state.openSpinnerForConsent) {
      this.setState({ openSpinnerForConsent: true });
    }

    let savePlanForProvider = this.state.acceptedRatePlans.filter(
      obj => obj.providerId === selectedProvider.id
    );
    if (savePlanForProvider) {
      this.savePlan(selectedProvider.id, savePlanForProvider[0]);
    }

    const { solution } = this.props;
    this.setState({ isPDFConsentDialogOpen: false });
    if (data) {
      try {
        let consentResponse = await this.saveConsents(solution[0], data);
        // let emailConfig = this.configureEmail(
        //   solution[0],
        //   data,
        //   consentResponse.data.consentDetails,
        //   'apiProviderNotificationEmail',
        //   'Your API has been added to an App'
        // );

        let internalEmailConfig = this.configureInternalEmail(
          solution[0],
          data,
          emailType_internal_alert_app_publisher_signed_api_terms,
          subject_internal_alert_app_publisher_signed_api_terms
        );

        const emailState = await this.notifyInternalAlertOfApiAddition(
          internalEmailConfig
        );

        //const emailState = await this.notifyApiProvider(emailConfig);
        // if (
        //   consentResponse &&
        //   consentResponse.status === 200 &&
        //   data.consentStatus.toLowerCase() ===
        //     providerConsentStatusConst.ACCEPTED
        // ) {
        //   emailConfig.withTemplate.emailType = 'apiProviderTermsAgreement';
        //   emailConfig.subject = 'Marketplace Api Activation Alert';
        //   emailConfig.to = [];
        //   await this.notifyApiProvider(emailConfig);
        // }
        await this.props.refetchConsents();

        if (
          consentResponse &&
          consentResponse.status === 200
          // &&
          // emailState &&
          // emailState.status === 200
        ) {
          if (
            data.consentStatus.toLowerCase() ===
            providerConsentStatusConst.ACCEPTED
          ) {
            this.setState({ consentJustCreated: true });
            /** TODO for XCHNG-15985 */
          } else if (
            data.consentStatus.toLowerCase() ===
            providerConsentStatusConst.REJECTED
          ) {
            this.setState({ consentJustRejected: true });
          }
        } else {
          this.setState({ pleaseTryAgain: true });
        }
      } catch (e) {
        console.error(e);
        this.setState({ pleaseTryAgain: true });
        this.setState({ openSpinnerForConsent: false });
      }

      this.setState({ openSpinnerForConsent: false });
    }
  };

  // configureEmail = (...args) => {
  //   let emailConfig = {};
  //   const { items = [], solution = [], solutionId = '' } = this.props;
  //   const [
  //     solutionData = {},
  //     consentStatusConfig = {},
  //     consentData = {},
  //     type = '',
  //     emailSubject = 'Your API has been added to an App',
  //     toAddressList = [],
  //     ccAddressList = [],
  //     bccAddressList = []
  //   ] = args;
  //   const solutionDetails = solution.filter(({ id }) => id === solutionId);

  //   const providerInfo = this.state.selectedProvider;

  //   emailConfig.to = toAddressList;
  //   emailConfig.to = toAddressList.length
  //     ? toAddressList
  //     : [toAddressConfig(providerInfo.name, providerInfo.supportEmail)];
  //   emailConfig.subject = emailSubject;
  //   emailConfig.attachments = [
  //     attachmentConfig(
  //       undefined,
  //       `${solutionDetails.length ? solutionDetails[0].name : 'solution'}`,
  //       undefined,
  //       undefined,
  //       '',
  //       this.state.signedTermsAndConditionsS3
  //         ? this.state.signedTermsAndConditionsS3
  //         : consentData.documentLink
  //     )
  //   ];
  //   emailConfig.bcc = bccAddressList;
  //   emailConfig.cc = ccAddressList;
  //   const providerName = providerInfo.name.split(' ');
  //   emailConfig.userDetails = {
  //     firstName: providerName[0],
  //     lastName:
  //       providerName.length > 2
  //         ? `${providerName.slice(1, providerName.length).join('')}`
  //         : providerInfo[1]
  //   };
  //   const { acceptedRatePlans = null } = this.state;
  //   let rate_plan = [];
  //   rate_plan =
  //     acceptedRatePlans &&
  //     acceptedRatePlans.filter(
  //       ({ providerId }) => providerInfo.id === providerId
  //     );
  //   const subscribtionDetails = subscriptionDetailsConfig(
  //     this.props.userData.name,
  //     this.props.auth.userData.email,
  //     `${moment(new Date()).format('DD MMM YYYY')}`,
  //     rate_plan.length
  //       ? `${rate_plan[0].ratePlan.name}(${rate_plan[0].ratePlan.type})`
  //       : '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     providerInfo.entityId,
  //     '',
  //     '',
  //     this.props.entity.name,
  //     '',
  //     ''
  //   );

  //   emailConfig.withTemplate = templateConfig(
  //     type,
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     '',
  //     subscribtionDetails,
  //     '',
  //     '',
  //     providerInfo.entityId,
  //     '',
  //     '',
  //     this.props.entity.name,
  //     moment(new Date()).format('DD MM YYYY'),
  //     providerInfo.consent ? providerInfo.consent.cmfNumber : '',
  //     '',
  //     this.state.signedTermsAndConditionsS3 ? 'Electronic Signature' : 'One Click',
  //     '',
  //     appDetailsConfig(
  //       solutionDetails.length ? solutionDetails[0].name : '',
  //       solutionDetails.length ? solutionDetails[0].id : ''
  //     ),
  //     [
  //       apiDetailsConfig(
  //         providerInfo.api,
  //         this.props.entity.name,
  //         providerInfo.entityId,
  //         this.state.signedTermsAndConditionsS3
  //           ? 'Electronic Signature'
  //           : 'One Click'
  //       )
  //     ]
  //   );
  //   return emailConfig;
  // };

  configureInternalEmail = (
    solutionData,
    consentRelatedData,
    emailType,
    emailSubject
  ) => {
    let emailConfig = {};
    const { solution = [], solutionId = '', items = [] } = this.props;

    //taking file url from consentRelatedData
    const file_url =
      consentRelatedData && consentRelatedData.signedTermsAndConditions
        ? consentRelatedData.signedTermsAndConditions
        : consentRelatedData.consentLink;

    const solutionDetails = solution.filter(({ id }) => id === solutionId);

    const providerInfo = this.state.selectedProvider;

    emailConfig.to = [toAddressConfig('Internal Email', 'Internal Email')];
    emailConfig.subject = emailSubject;

    const selectedProvider_api =
      providerInfo && providerInfo.api ? providerInfo.api : '';
    let selectedAPIArr = [];
    let selectedAPIName = '';
    if (items.length > 0 && selectedProvider_api) {
      selectedAPIArr = items.filter(api_item => {
        return api_item && api_item.id === selectedProvider_api;
      });
      selectedAPIName =
        selectedAPIArr.length > 0 && selectedAPIArr[0].displayName
          ? selectedAPIArr[0].displayName
          : '';
    }

    const solution_Name =
      solutionDetails.length && solutionDetails[0].name
        ? solutionDetails[0].name
        : 'solution';
    const api_Name = selectedAPIName ? selectedAPIName : selectedProvider_api;
    const file_name = api_Name + ' ' + solution_Name + '.pdf';

    emailConfig.attachments = [
      attachmentConfig(undefined, file_name, undefined, undefined, '', file_url)
    ];

    const { acceptedRatePlans = null } = this.state;
    let rate_plan = [];
    rate_plan =
      acceptedRatePlans &&
      acceptedRatePlans.filter(
        ({ providerId }) => providerInfo.id === providerId
      );

    const apiRatePlanDetails =
      rate_plan.length > 0 &&
      rate_plan[0].ratePlan &&
      rate_plan[0].ratePlan.name &&
      rate_plan[0].ratePlan.type
        ? `${rate_plan[0].ratePlan.name}(${rate_plan[0].ratePlan.type})`
        : '';

    const userEmail =
      this.props.auth &&
      this.props.auth.userData &&
      this.props.auth.userData.email
        ? this.props.auth.userData.email
        : '';
    const userName =
      this.props.auth &&
      this.props.auth.userData &&
      this.props.auth.userData.name
        ? this.props.auth.userData.name
        : '';

    emailConfig.userDetails = {
      firstName: userName
    };

    emailConfig.withTemplate = {
      emailType: emailType,
      currentdate: moment(new Date()).format('DD-MM-YYYY'),
      appDetails: {
        appName: solutionDetails.length ? solutionDetails[0].name : '',
        appOrgName: this.props.entity.name,
        appOrgId: this.props.entity.id
      },
      userEmail: userEmail,
      apiDetails: [
        {
          apiName: selectedAPIName ? selectedAPIName : selectedProvider_api,
          apiOrgName: providerInfo.name,
          apiOrgId: providerInfo.entityId,
          apiConsentType: this.state.signedTermsAndConditionsS3
            ? 'Electronic Signature'
            : 'One Click',
          apiPlanType: apiRatePlanDetails,
          apiImplementationName: providerInfo.productName
        }
      ]
    };
    return emailConfig;
  };

  notifyInternalAlertOfApiAddition = async emailConfig => {
    try {
      const data = await this.solutionConfig.notifyInternalAlertOfApiAddition(
        emailConfig
      );
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  // notifyApiProvider = async emailConfig => {
  //   try {
  //     const data = await this.solutionConfig.notifyApiProviderActivation(
  //       emailConfig,
  //       this.props.entity,
  //       this.props.userData
  //     );
  //     return data;
  //   } catch (e) {
  //     console.error(e);
  //     return null;
  //   }
  // };
  saveConsents = async (solution, consentData) => {
    const {
      auth: { accessToken },
      formStaticValues
    } = this.props;

    let createConsentPayload = {
      documentLink: this.state.selectedProvider
        ? this.state.selectedProvider.termsDoc || 'NA'
        : '',
      listingVersion: formStaticValues.listingVersion
        ? formStaticValues.listingVersion
        : '1',
      acceptedName: consentData.userName ? consentData.userName : null,
      acceptedTitle: consentData.userTitle ? consentData.userTitle : null,
      acceptedCompany: consentData.userCompany ? consentData.userCompany : null,
      reasonForRejection: consentData.rejectionReason
        ? consentData.rejectionReason
        : null,

      status: consentData.consentStatus
        ? consentData.consentStatus.toLowerCase()
        : null,
      api: this.state.selectedApiId,
      apiProviderId: this.state.selectedProvider.id,
      consentType: 'api_tos',
      subscriptionStatus:
        ConsentTypeConstants.SUBSCRIPTION_STATUS_FOR_NEW_CONNECTION,
      consentedSource: ConsentTypeConstants.CONSENTED_SOURCE,
      signedTermsAndConditions: consentData.signedTermsAndConditions
        ? consentData.signedTermsAndConditions
        : null,
      signedTermsAndConditionsS3: this.state.signedTermsAndConditionsS3
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    };
    const listingVersion =
      solution && solution.solutiondata && solution.solutiondata.listingVersion
        ? solution.solutiondata.listingVersion
        : '1';

    if (
      this.state.selectedProvider.consent &&
      this.state.selectedProvider.consent.consentId
    ) {
      return await axios.put(
        `${environmentURLs.consentServiceBaseUrl}/solutions/${
          solution.id
        }/${listingVersion}/consents/${
          this.state.selectedProvider.consent.consentId
        }`,
        createConsentPayload,
        { headers: headers }
      );
    } else {
      const createdResponse = await axios.post(
        `${environmentURLs.consentServiceBaseUrl}/solutions/${
          solution.id
        }/${listingVersion}/consents`,
        createConsentPayload,
        { headers: headers }
      );
      return createdResponse;
    }
  };

  async cancelAcceptance(
    solutionId,
    solutionListingVersion,
    consentId,
    consentObj
  ) {
    const payload = {
      ...consentObj,
      status: 'revoked',
      api: this.state.selectedApiId
    };
    const {
      auth: { accessToken }
    } = this.props;
    this.setState({ openSpinnerForRevokingConsent: true });
    try {
      const response = await axios.put(
        `${
          environmentURLs.consentServiceBaseUrl
        }/solutions/${solutionId}/${solutionListingVersion}/consents/${consentId}`,
        payload,
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : ''
          }
        }
      );
      if (response.status === 200) {
        await this.props.refetchConsents();
        this.setState(
          {
            openSpinnerForRevokingConsent: false,
            isDeleted: true
          },
          async () => {
            await this.sendDisableEmailAlert();
          }
        );
      } else {
        this.setState({
          deleteFailed: true,
          openSpinnerForRevokingConsent: false
        });
      }
    } catch (e) {
      this.setState({
        deleteFailed: true,
        openSpinnerForRevokingConsent: false
      });
    }
  }

  getConsentStatusAndColor(consentStatus, doc) {
    if (consentStatus) {
      if (consentStatus === 'accepted') {
        message = APIProviderConsent.TERMS_ACCEPTED;
        consentColor = '#1E8700';
      }
      if (consentStatus === 'rejected') {
        message = APIProviderConsent.TERMS_REJECTED;
        consentColor = '#D20000';
      }
      if (consentStatus === 'revoked') {
        message = APIProviderConsent.TERMS_REVOKED;
        consentColor = '#F3C118';
      }
      return { message, consentColor };
    }

    if (doc) {
      message = APIProviderConsent.TERMS_NOT_ACCEPTED;
      consentColor = '#BDBDBD';
    } else {
      message = APIProviderConsent.NO_TERMS_EXIST;
      consentColor = '#BDBDBD';
    }
    return { message, consentColor };
  }

  setIsSubmitEnabled = () => {
    if (this.props.validate) {
      let isSubmitEnabled = this.props.items.every(api =>
        api.providers.some(
          provider => provider.consent && provider.consent.status === 'accepted'
        )
      );

      const formFieldValues = this.props.formFieldValues;
      formFieldValues['isSubmitEnabled'] = isSubmitEnabled;
      formFieldValues['isApiConsentModified'] = true;
      this.props.onChange(formFieldValues);

      this.props.validate(
        isSubmitEnabled,
        this.props.fieldProperties.name,
        this.props.activeFormId,
        this.props.activeTabId,
        this.props.activeSubFormId
      );
    }
  };

  onIsOptionalChange = (apiId, isOptional) => {
    const formFieldValues = JSON.parse(
      JSON.stringify(this.props.formFieldValues)
    );
    if (formFieldValues.api_details) {
      formFieldValues.api_details.forEach(api => {
        if (api.id === apiId) {
          api.isOptional = isOptional;
        }
      });
      this.props.onChange(formFieldValues);
    }
  };

  showActionColumn = () =>
    [
      SolutionStatus.DRAFT,
      SolutionStatus.REJECTED,
      SolutionStatus.REVOKED,
      SolutionStatus.RECALLED
    ].some(status => status === this.props.formStaticValues.status) &&
    this.props.formFieldValues;

  isFormEditable = () => {
    return [
      SolutionStatus.DRAFT,
      SolutionStatus.REVOKED,
      SolutionStatus.RECALLED,
      SolutionStatus.REJECTED
    ].includes(this.props.formStaticValues.status);
  };

  handleSelectedPlan = (providerId, selectedRatePlanType) => {
    if (providerId && selectedRatePlanType) {
      let prevSelectedValue = this.state.new_RatePlaneSelectedValueMapping;
      this.setState({
        new_RatePlaneSelectedValueMapping: {
          ...prevSelectedValue,
          [providerId]: selectedRatePlanType
        }
      });
    }
  };

  configureInternalEmailForDisablementOfAnAPI = (emailType, emailSubject) => {
    const { acceptedRatePlans = null, selectedProvider = {} } = this.state;

    const selectedProvider_api =
      selectedProvider && selectedProvider.api ? selectedProvider.api : '';
    const selectedProvider_id =
      selectedProvider && selectedProvider.id ? selectedProvider.id : '';

    const {
      items = [] //items are needed to get the name of api.
    } = this.props;

    let selectedAPIArr = [];
    let selectedAPIName = '';
    if (items.length > 0 && selectedProvider_api) {
      selectedAPIArr = items.filter(api_item => {
        return api_item && api_item.id === selectedProvider_api;
      });
      selectedAPIName =
        selectedAPIArr.length > 0 && selectedAPIArr[0].displayName
          ? selectedAPIArr[0].displayName
          : '';
    }

    let rate_plan = [];
    rate_plan =
      acceptedRatePlans &&
      selectedProvider_id &&
      acceptedRatePlans.filter(
        ({ providerId }) => selectedProvider_id === providerId
      );

    const apiRatePlanDetails =
      rate_plan.length > 0 &&
      rate_plan[0].ratePlan &&
      rate_plan[0].ratePlan.name &&
      rate_plan[0].ratePlan.type
        ? `${rate_plan[0].ratePlan.name}(${rate_plan[0].ratePlan.type})`
        : '';

    let emailConfig = {};
    const { solution = [], solutionId = '' } = this.props;

    const solutionDetails = solution.filter(({ id }) => id === solutionId);

    emailConfig.to = [toAddressConfig('Internal Email', 'Internal Email')];
    emailConfig.subject = emailSubject;

    const userEmail =
      this.props.auth &&
      this.props.auth.userData &&
      this.props.auth.userData.email
        ? this.props.auth.userData.email
        : '';
    const userName =
      this.props.auth &&
      this.props.auth.userData &&
      this.props.auth.userData.name
        ? this.props.auth.userData.name
        : '';

    emailConfig.userDetails = {
      firstName: userName
    };

    emailConfig.withTemplate = {
      emailType: emailType,
      currentdate: moment(new Date()).format('DD-MM-YYYY'),
      appDetails: {
        appName: solutionDetails.length ? solutionDetails[0].name : '',
        appOrgName:
          this.props.entity && this.props.entity.name
            ? this.props.entity.name
            : ''
      },
      userEmail: userEmail,
      apiDetails: [
        {
          apiName: selectedAPIName ? selectedAPIName : selectedProvider_api,
          apiOrgName:
            selectedProvider && selectedProvider.name
              ? selectedProvider.name
              : '',
          apiOrgId:
            selectedProvider && selectedProvider.entityId
              ? selectedProvider.entityId
              : '',
          apiConsentType:
            selectedProvider && selectedProvider.consent
              ? selectedProvider.consent.signedTermsAndConditions
                ? 'Electronic Signature'
                : 'One Click'
              : '',
          apiPlanType: apiRatePlanDetails,
          apiImplementationName:
            selectedProvider && selectedProvider.productName
              ? selectedProvider.productName
              : ''
        }
      ]
    };
    return emailConfig;
  };

  sendDisableEmailAlert = async () => {
    const internalEmailConfig = this.configureInternalEmailForDisablementOfAnAPI(
      emailType_internal_alert_app_publisher_disabled_an_api,
      subject_internal_alert_app_publisher_disabled_an_api
    );
    const emailState = await this.notifyInternalAlertOfApiAddition(
      internalEmailConfig
    );
  };

  render() {
    const {
      auth: { userData }
    } = this.props;
    let providerName = this.state.selectedProvider
      ? this.state.selectedProvider.name
      : 'Provider';
    return (
      <>
        <div className="api-config-description" style={{ marginTop: '-1px' }}>
          App Provider needs to enable at least one API Provider for each API.
          You need to select a Pricing plan and accept the API Terms of Service
          if they are specified by the API Provider. Only the enabled API
          Providers are shown to the App Buyer. API Providers cannot be enabled
          or disabled, once a Subscription is created for the App.
          <div>
            <strong>
              {this.props.errorWhileFetchingAPIData ? null : this.props
                  .loadingAPIData ? (
                <ThreeDots height={30} width={30} />
              ) : (
                `Total Subscriptions : ${
                  this.props.formFieldValues
                    ? this.props.formFieldValues.numberOfSubscriptions
                    : 0
                }`
              )}
            </strong>
          </div>
        </div>

        {this.props.errorWhileFetchingAPIData ? (
          <div>
            Some error occured while fetching API details. Please try again
            later.
          </div>
        ) : this.props.loadingAPIData || this.state.loadingAcceptedRatePlans ? (
          <div className="loading-api-details">
            <Spinner />
            <div> Loading API details...</div>
          </div>
        ) : (
          <DataTable className="api-provider-and-consent-table">
            <DataTableHeader>
              <DataTableRow>
                <DataTableHeaderCell
                  className="api-info-table-cell-50"
                  data-cy="api_name"
                >
                  Fortellis APIs
                </DataTableHeaderCell>
                <DataTableHeaderCell
                  className="api-info-table-cell-50"
                  data-cy="api_provider"
                >
                  Description
                </DataTableHeaderCell>
                <DataTableHeaderCell
                  className="api-info-table-cell-50"
                  data-cy="api_provider"
                >
                  Providers
                </DataTableHeaderCell>
              </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
              {this.props.items && this.props.items.length
                ? this.props.items.map((item, itemIndex) => {
                    return (
                      <DataTableRow key={itemIndex}>
                        <DataTableCell className={'api-info-table-cell-50'}>
                          <LightTooltip
                            title={
                              <div>
                                <span> {item.displayName} </span>
                              </div>
                            }
                          >
                            <div
                              className={
                                this.getOneToOneStatus()
                                  ? 'api-info-table-body-api-without-desc'
                                  : 'api-info-table-body-api'
                              }
                            >
                              {item.displayName}
                            </div>
                          </LightTooltip>
                        </DataTableCell>
                        <DataTableCell className={'api-info-table-cell-50'}>
                          <LightTooltip
                            title={
                              <div>
                                <span> {item.description} </span>
                              </div>
                            }
                          >
                            <div className="api-info-table-body-description-column">
                              {item.description}
                            </div>
                          </LightTooltip>
                        </DataTableCell>
                        <DataTableCell className={'api-info-table-cell-50'}>
                          <LightTooltip
                            title={
                              <div>
                                <span>
                                  {' '}
                                  {item.providers && item.providers.length
                                    ? item.providers[0].apiProviderName
                                    : 'N/A'}{' '}
                                </span>
                              </div>
                            }
                          >
                            <div className="api-info-table-body-description-column">
                              {item.providers && item.providers.length
                                ? item.providers[0].apiProviderName
                                : 'N/A'}
                            </div>
                          </LightTooltip>
                        </DataTableCell>
                      </DataTableRow>
                    );
                  })
                : null}
            </DataTableBody>
          </DataTable>
        )}

        <div>
          <FortellisDialog
            title={
              <FormattedMessage
                id="SolutionSubmissionForm.consentAcceptance"
                defaultMessage="Cancel Consent"
              />
            }
            message={
              <div>
                <FormattedMessage
                  id="SolutionSubmissionForm.consentMessage1"
                  defaultMessage="Buyer will not be able to use use this Provider for their Subscription"
                />
                <br />
                <FormattedMessage
                  id="SolutionSubmissionForm.consentMessage2"
                  defaultMessage="Do you want to Proceed?"
                />
              </div>
            }
            open={this.state.isDialogToRevokeConsentOpen}
            acceptButtonName={
              <FormattedMessage
                id="SolutionSubmissionForm.consentCancelAcceptance"
                defaultMessage="PROCEED"
              />
            }
            cancelButtonName={
              <FormattedMessage
                id="SolutionSubmissionForm.consentDontCancelAcceptance"
                defaultMessage="CANCEL"
              />
            }
            onAccept={async () => {
              this.setState({ isDialogToRevokeConsentOpen: false });
              await this.cancelAcceptance(
                this.props.formStaticValues.id,
                this.props.formStaticValues.listingVersion,
                this.state.selectedProvider.consent.consentId,
                this.state.selectedProvider.consent
              );
            }}
            onCancel={() => {
              this.setState({ isDialogToRevokeConsentOpen: false });
            }}
          />
          <FortellisDialog
            className="consent-dialog"
            title={
              <FormattedMessage
                id="SolutionSubmissionForm.consentAcceptanceTerms"
                defaultMessage="Enable Provider"
              />
            }
            message={
              <div>
                <FormattedMessage
                  id="SolutionSubmissionForm.consentMessageTerms1"
                  defaultMessage="The provider has not specified terms of use."
                />
                <br />
                <FormattedMessage
                  id="SolutionSubmissionForm.consentMessageTerms2"
                  defaultMessage="Would you like to enable this provider for subscriptions?"
                />
              </div>
            }
            open={this.state.isNoPDFConsentDialogOpen}
            acceptButtonName={
              <FormattedMessage
                id="SolutionSubmissionForm.consentAcceptanceProceed"
                defaultMessage="PROCEED"
              />
            }
            cancelButtonName={
              <FormattedMessage
                id="SolutionSubmissionForm.consentCancelAcceptanceTerms"
                defaultMessage="CANCEL"
              />
            }
            onAccept={() => {
              const payload = {
                consentStatus: 'accepted'
              };
              this.onConsentResponse(payload, this.state.selectedProvider);
              this.setState({ isNoPDFConsentDialogOpen: false });
            }}
            onCancel={() => {
              this.setState({ isNoPDFConsentDialogOpen: false });
            }}
          />

          <div>
            {this.state.pleaseTryAgain && (
              <TemporarySnackbar
                show={this.state.pleaseTryAgain}
                message="Some error occurred!"
                timeout={3000}
                actionText="Close"
                onClose={() => this.setState({ pleaseTryAgain: false })}
                onAction={() => this.setState({ pleaseTryAgain: false })}
              />
            )}
          </div>

          <div>
            {this.state.consentJustCreated && (
              <TemporarySnackbar
                show={this.state.consentJustCreated}
                message="Consented Successfully!"
                timeout={3000}
                actionText="Close"
                onClose={() => this.setState({ consentJustCreated: false })}
                onAction={() => this.setState({ consentJustCreated: false })}
              />
            )}
          </div>

          <div>
            {this.state.consentJustRejected && (
              <TemporarySnackbar
                show={this.state.consentJustRejected}
                message="Consented Rejected Successfully!"
                timeout={3000}
                actionText="Close"
                onClose={() => this.setState({ consentJustRejected: false })}
                onAction={() => this.setState({ consentJustRejected: false })}
              />
            )}
          </div>

          <div>
            {this.state.isDeleted && (
              <TemporarySnackbar
                show={this.state.isDeleted}
                message="Consent Cancelled Successfully!"
                timeout={5000}
                actionText="Close"
                onClose={() => this.setState({ isDeleted: false })}
                onAction={() => this.setState({ isDeleted: false })}
              />
            )}
          </div>

          <div>
            {this.state.deleteFailed && (
              <TemporarySnackbar
                show={this.state.deleteFailed}
                message="Consent could not be cancelled!"
                timeout={5000}
                actionText="Close"
                onClose={() => this.setState({ deleteFailed: false })}
                onAction={() => this.setState({ deleteFailed: false })}
              />
            )}
          </div>

          {this.state.selectedProvider && this.state.isPDFConsentDialogOpen && (
            <div className="providerConsentDialog">
              <UserConsentDialog
                title={`Review terms of service`}
                description={() => (
                  <div>
                    Please review and accept these terms to give your consent
                    to&nbsp;
                    <strong>{providerName}</strong>
                  </div>
                )}
                submitButtonName="Submit"
                cancelButtonName="Cancel"
                acceptMessage="I accept the terms of service"
                rejectMessage="I reject the terms of service for"
                scrollInstruction="You need to scroll to bottom to accept terms of service."
                preFilledName={userData.name}
                onSubmit={data => {
                  this.setState({
                    signedTermsAndConditionsS3: data.signedTermsAndConditions
                  });
                  this.onConsentResponse(data, this.state.selectedProvider);
                }}
                onCancel={error => {
                  this.setState({
                    isPDFConsentDialogOpen: false,
                    consentError: error || false
                  });
                }}
                consentLink={
                  this.state.selectedProvider
                    ? this.state.selectedProvider.termsDoc
                    : ''
                }
                consentCount={this.state.consentCount}
                type={'api-terms'}
                provider={this.state.selectedProvider}
                entity={this.props.entity}
              />
            </div>
          )}
          <ConsentTermsViewer
            documentLink={
              this.state.selectedProvider
                ? this.state.selectedProvider.termsDoc
                : null
            }
            open={this.state.openConsentTerms}
            onClose={() => {
              this.setState({ openConsentTerms: false });
            }}
          />
          <Dialog
            open={this.state.openSpinnerForConsent}
            className="spinnerDialog"
          >
            <DialogBody className={'file-upload-spinner'}>
              Updating status...
            </DialogBody>
            <Spinner />
          </Dialog>
          <Dialog
            open={this.state.openSpinnerForRevokingConsent}
            className="spinnerDialog"
          >
            <DialogBody className={'file-upload-spinner'}>
              Disabling provider...
            </DialogBody>
            <Spinner />
          </Dialog>
          <Dialog open={this.state.loadingRatePlans} className="spinnerDialog">
            <DialogBody className={'file-upload-spinner'}>
              Loading Pricing Plans...
            </DialogBody>
            <Spinner />
          </Dialog>
          <ProviderPricing
            planProviderId={this.state.selectedProviderId}
            handleSelectedPlan={this.handleSelectedPlan}
            prevSelectedRatePlanValue={
              this.state.new_RatePlaneSelectedValueMapping
            }
            ratePlans={providerRatePlans}
            open={this.state.showRatePlansDialog}
            onSavePlan={chosenPlan => {
              this.onChoosingPlan(chosenPlan);
            }}
            onClose={() => {
              this.setState({ showRatePlansDialog: false });
            }}
          />
          <AcceptedPricing
            open={this.state.showAcceptedPricing}
            selectedPlan={this.state.selectedPlan}
            onClose={() => {
              this.setState({
                showAcceptedPricing: false,
                selectedPlan: false
              });
            }}
          />
        </div>

        {this.state.consentError && (
          <ActionSnackBar
            show={true}
            message="Unable to determine consent type"
            close={() => {
              this.setState({ consentError: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAcceptedRatePlans: (entityId, accessToken) =>
    dispatch(fetchAcceptedRatePlans({ entityId, accessToken }))
});

const mapStateToProps = state => {
  return {
    acceptedRatePlans:
      state &&
      state.appSubmission &&
      state.appSubmission.apiDetails &&
      state.appSubmission.apiDetails.acceptedRatePlans
  };
};

export default withApollo(
  injectIntl(
    withAuth(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(ProviderAndConsentList)
    )
  )
);
