import React from 'react';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import { getApolloClient } from '../../containers/GraphQLClient';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import FortellisConstants from '../common/FortellisConstants';
import SolutionTypeURLMapperUtil from '../common/SolutionTypeURLMapperUtil';
import { FormUrlValidation } from '../../containers/utils/FormUrlValidation';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import {
  SecondaryButton,
  TextButton,
  BUTTON_VARIANTS,
  Dialog,
  Input,
  EmailInput,
  TextArea,
  Button
} from 'cdk-radial';
import { Toast, TOAST_POSITIONS, TOAST_VISIBILITY_DURATIONS } from 'cdk-radial';
import { Loader } from 'cdk-radial';
import { AMPLITUDE_EVENTS } from '../../utils/amplitude-constants';

const maxSizeForTextField = 250;

class ContactPublisher extends React.Component {
  state = {
    open: false,
    formFields: {
      userName: '',
      leadEmailAddress: '',
      message: '',
      orgId: ''
    },
    snackBar: false,
    submitted: false,
    spinner: false
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ name: value });
  };
  onShow = () => {
    this.props.createAndSubmitAmplitudeData(AMPLITUDE_EVENTS.CONTACT_PUBLISER);
    if (this.props.auth.isAuthenticated) {
      this.initializeState(this.props.auth.userData);
      this.setState({ open: true });
    } else {
      this.props.auth.login({ return_url: window.location.pathname });
    }
  };
  initializeState = userData => {
    const formFields = this.state.formFields;
    formFields['userName'] = userData.name;
    formFields['leadEmailAddress'] = userData.email;
    formFields['orgId'] = this.props.auth.entityId;
    this.setState({ formFields: formFields });
  };
  onClose = () => {
    this.setState({ open: false });
  };
  sendLeadBuyerEmail = async () => {
    const client = getApolloClient();

    const {
      solution: { support: { emailAddress = '', phoneNumber = '' } = {} } = {}
    } = this.props;

    return await client.mutate({
      mutation: leadBuyerEmailMutation,
      variables: {
        emailAddress: this.state.formFields.leadEmailAddress,
        emailSubject: 'Your Request for Information Has Been Received',
        firstName: this.state.formFields.userName,
        emailType: 'solutionLeadBuyerEmail',
        leadName: this.state.formFields.userName,
        leadEmail: this.state.formFields.leadEmailAddress,
        leadMessage: this.state.formFields.message
          ? this.state.formFields.message
          : null,
        solutionName: this.props.solutionName,
        publisher: this.props.publisherName,
        publisherEmail: emailAddress,
        publisherPhone: phoneNumber
      }
    });
  };
  sendLeadDeveloperEmail = async () => {
    const client = getApolloClient();
    return await client.mutate({
      mutation: leadDeveloperEmailMutation,
      variables: {
        emailSubject: 'You Received a New App Lead',
        firstName: this.props.solutionDeveloperName,
        emailType: 'solutionLeadDeveloperEmail',
        solutionName: this.props.solutionName,
        solutionType:
          SolutionTypeURLMapperUtil.getSolutionTypeURL(
            this.props.solutionType
          ) || ' ',
        solutionId: this.props.solutionId,
        leadName: this.state.formFields.userName,
        leadEmail: this.state.formFields.leadEmailAddress,
        leadMessage: this.state.formFields.message
          ? this.state.formFields.message
          : null
      }
    });
  };
  saveLeadDetails = async () => {
    this.setState({ spinner: true });
    this.props.createAndSubmitAmplitudeData(
      AMPLITUDE_EVENTS.CONTACTED_APP_PUBLISHER
    );
    this.props.saveLeadDetails({
      variables: {
        leadEmail: this.state.formFields.leadEmailAddress,
        leadGenerationDate: moment().format('DD MMM YYYY'),
        solutionId: this.props.solutionId,
        solutionVersion: this.props.solutionVersion,
        listingVersion: this.props.listingVersion,
        userName: this.state.formFields.userName,
        message: this.state.formFields.message
          ? this.state.formFields.message
          : null,
        orgId: this.state.formFields.orgId
      }
    });
    if (!this.props.saveLeadDetails.error) {
      this.sendLeadBuyerEmail().then(() => {
        this.sendLeadDeveloperEmail().then(() => {
          this.setState({ open: false });
          this.setState({
            formFields: { ...this.state.formFields, message: '' }
          });
          this.setState({
            spinner: false,
            snackBar: {
              message: () => {
                return (
                  <FormattedMessage
                    id="ContactPublisher.snackBar"
                    defaultMessage="Thank you for showing interest!"
                  />
                );
              }
            }
          });
        });
      });
    } else {
      this.setState({ spinner: false });
    }
  };
  renderPolicyLink(link, linkText) {
    return (
      <a
        href={FormUrlValidation.validateUrl(link)}
        style={{ color: '#904778' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText}
      </a>
    );
  }
  render() {
    const {
      isPreview,
      planCount,
      showSubscribeButton,
      isActivateOnly
    } = this.props;
    return (
      <div className="appDetails-page-secondary-control-buttons">
        {(showSubscribeButton && planCount) || isActivateOnly ? (
          this.props.solution &&
          this.props.solution.solutionType ===
            FortellisConstants.PARTNER_PROGRAM ? (
            <SecondaryButton
              onClick={this.onShow}
              id="contactPublisher"
              className={this.props.customClassName}
              isDisabled={isPreview}
              text={'Contact Publisher'}
            />
          ) : (
            <TextButton
              onClick={this.onShow}
              id="contactPublisher"
              className={this.props.customClassName}
              isDisabled={isPreview}
              text={'Contact Publisher'}
            />
          )
        ) : (
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            raised={true}
            dataTestId="contactPublisher"
            className={this.props.customClassName}
            isDisabled={isPreview}
            text={'Contact Publisher'}
            onClick={this.onShow}
          />
        )}
        <div className={'contact-publisher-dialog'}>
          <Dialog
            buttonsProps={
              this.state.spinner
                ? []
                : [
                    {
                      id: 'contact-publisher-dialog-submit-action',
                      onClick: this.saveLeadDetails,
                      text: 'Submit',
                      variant: BUTTON_VARIANTS.PRIMARY,
                      isDisabled:
                        !this.state.formFields.message ||
                        !this.state.formFields.userName ||
                        !this.state.formFields.leadEmailAddress
                    }
                  ]
            }
            id="dialog-3"
            isOpen={this.state.open}
            onClose={this.state.spinner ? () => {} : this.onClose}
            className="contact-publisher-dialog-box"
            title="Contact Publisher"
          >
            {this.state.spinner ? (
              <div className="contact-publisher-loader">
                <Loader dataTestId="contact-publisher-loader" label="Loading" />
              </div>
            ) : (
              <div className="contact-publisher-dialog-content">
                <Input
                  dataTestId="input-user-name"
                  className="contact-publisher-user-name"
                  errorMessage="please enter user's name"
                  id="user-name-input"
                  inputDivider="left"
                  label="User Name"
                  name="userName"
                  value={this.state.formFields.userName}
                  onChange={this.handleInputChange}
                  placeholder="enter user name"
                  isRequired
                />

                <EmailInput
                  dataTestId="email-input"
                  className="contact-publisher-email-input"
                  errorMessage="enter user's email address"
                  id="email-input"
                  name="leadEmailAddress"
                  label="Email"
                  value={this.state.formFields.leadEmailAddress}
                  onChange={this.handleInputChange}
                  placeholder="enter email address"
                  isRequired
                />

                <TextArea
                  dataTestId="add-message_text-area"
                  errorMessage="add a message!"
                  className="contact-publisher-add-message-text-area"
                  hasCharacterCount
                  id="add-message-text-area"
                  maxLength={maxSizeForTextField}
                  placeholder="add message"
                  size="large"
                  name="message"
                  label="Add Message"
                  value={this.state.formFields.message}
                  onChange={this.handleInputChange}
                  isRequired
                />

                <div className="contact_publisher_modal_clause">
                  <p className="contact_publisher_modal_clause_content">
                    {`By clicking Submit, you agree to send your information to `}
                    <b>{this.props.publisherName}</b>
                    {`, who agrees to use it according to their `}
                    {this.renderPolicyLink(
                      this.props.privacyPolicyUrl,
                      <FormattedHTMLMessage
                        id="ContactPublisher.modalClause3"
                        defaultMessage="privacy policy."
                      />
                    )}
                  </p>
                </div>
              </div>
            )}
          </Dialog>
        </div>
        {this.state.snackBar && (
          <Toast
            content={
              this.state.snackBar
                ? this.state.snackBar.message()
                : 'No Action taken'
            }
            position={TOAST_POSITIONS.FIXED}
            style={{ marginTop: '70px', zIndex: '5' }}
            variant={'positive'}
            visibilityDuration={TOAST_VISIBILITY_DURATIONS.SEVEN_SECONDS}
            actions={[{ text: 'Dismiss' }]}
            onVisibilityDurationEnd={() => {
              this.setState({ snackBar: false });
              this.setState({ submitted: true });
            }}
            actions={[
              {
                onClick: () => {
                  this.setState({ snackBar: false });
                  this.setState({ submitted: true });
                },
                text: 'Dismiss'
              }
            ]}
          />
        )}
      </div>
    );
  }
}

const captureLeadMutation = gql`
  mutation(
    $leadEmail: String!
    $leadGenerationDate: String!
    $solutionId: String!
    $solutionVersion: String
    $listingVersion: String!
    $userName: String!
    $message: String
    $orgId: String
  ) {
    captureLead(
      command: {
        leadEmail: $leadEmail
        leadGenerationDate: $leadGenerationDate
        solutionId: $solutionId
        solutionVersion: $solutionVersion
        listingVersion: $listingVersion
        userName: $userName
        message: $message
        orgId: $orgId
      }
    ) {
      leadEmail
      leadGenerationDate
      solutionId
      userName
      message
      orgId
    }
  }
`;

const leadBuyerEmailMutation = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $leadName: String!
    $leadEmail: String!
    $leadMessage: String
    $solutionName: String!
    $publisher: String!
    $publisherPhone: String
    $publisherEmail: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          leadName: $leadName
          leadEmail: $leadEmail
          leadMessage: $leadMessage
          solutionName: $solutionName
          publisher: $publisher
          publisherPhone: $publisherPhone
          publisherEmail: $publisherEmail
        }
      }
    ) {
      emailResponse
    }
  }
`;

const leadDeveloperEmailMutation = gql`
  mutation(
    $emailSubject: String!
    $firstName: String!
    $emailType: String!
    $solutionName: String!
    $solutionType: String
    $solutionId: String!
    $leadName: String!
    $leadEmail: String!
    $leadMessage: String!
  ) {
    leadDeveloperEmail(
      command: {
        emailSubject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionType: $solutionType
          solutionId: $solutionId
          leadName: $leadName
          leadEmail: $leadEmail
          leadMessage: $leadMessage
        }
      }
    ) {
      emailResponse
    }
  }
`;

export default injectIntl(
  withApollo(
    compose(graphql(captureLeadMutation, { name: 'saveLeadDetails' }))(
      withAuth(ContactPublisher)
    )
  )
);
