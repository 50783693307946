import SolutionSubscription from '../components/solution-subscription/solution-subscription';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const queryUpdated = gql`
  query($solutionId: ID) {
    solutionCatalogList(id: $solutionId) {
      id
      listingVersion
      developerId
      listingVersion
      developerName
      status
      overview {
        name
        category
        applicationArea
        version
        briefDescription
        detailedDescription
        publisherName
        privacyPolicyUrl
        termsAndConditionsUrl
        solutionLogo
      }
      support {
        supportCenterUrl
        phoneNumber
        emailAddress
      }
      plans {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      provisioning_interface {
        provisioningtype
        endpointUrl
        redirectURL
      }
      subscription_notifications {
        email
        endpointUrl
        apiNotification
      }
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  solution: data.solutionCatalogList ? data.solutionCatalogList[0] : {},
  loading: data.loading
});

export default compose(
  graphql(queryUpdated, {
    props: mapDataToProps,
    options: ({ match }) => ({
      variables: {
        solutionId: match.params.solutionId
      }
    })
  })
)(SolutionSubscription);
