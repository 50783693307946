import Insights from '../components/marketplace-account/my-solutions/solution-management/panels/Insights';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

const getInsightDetails = gql`
  query($solutionId: ID) {
    publishedReviewsForUser(filter: { solutionId: $solutionId }) {
      reviews {
        id
        status
        listingVersion
        solutionVersion
      }
      pageInfo {
        offset
      }
    }
    solutionLeads(filter: { solutionId: $solutionId }) {
      leads {
        solutionVersion
        listingVersion
      }
      pageInfo {
        offset
      }
    }
    getSolutionSubscriptions(solutionId: $solutionId) {
      id
      subscriptionId
      solutionId
      status
      listingVersion
      plan {
        id
        planName
      }
      userProvisioningStatus
      isMarketPlaceSubScription
    }
  }
`;

const mapDataToProps = ({ data }) => ({
  reviews:
    data.publishedReviewsForUser && data.publishedReviewsForUser.reviews
      ? data.publishedReviewsForUser.reviews
      : [],
  leads:
    data.solutionLeads && data.solutionLeads.leads
      ? data.solutionLeads.leads
      : [],
  subscriptions: data.getSolutionSubscriptions
    ? data.getSolutionSubscriptions
    : [],
  loading: data.loading
});

export default compose(
  graphql(getInsightDetails, {
    props: mapDataToProps,
    options: ({ solutionId }) => ({
      variables: {
        solutionId: solutionId
      }
    })
  })
)(Insights);
