import React, { useEffect, useRef, useState } from 'react';
import { Radio } from '@cdk-uip/react-radio';
import { Checkbox } from '@cdk-uip/react-checkbox';
import { Select } from '@cdk-uip/react-select';
import FormCaption from '../../solution-submission/common-form-api/FormComponents/FormCaption';

function FormCaptionFormField(props) {
  return (
    <div className="formFields">
      <div className="layoutcell layoutcellRadio">
        <div className="layoutcell layoutcellRadio">
          <FormCaption title={props.title} style={props.style} />
        </div>
      </div>
    </div>
  );
}

export default function AuthSignOn(props) {
  let { isEnabled } = props.formFieldValues;

  const [isEnabledState, setIsEnabledState] = useState(isEnabled);

  const handleRadioChange = event => {
    const formFieldValues = props.formFieldValues;
    formFieldValues.isEnabled = event.target.checked;
    setIsEnabledState(event.target.checked);
  };

  console.log('props.formFieldValues', props.formFieldValues, isEnabledState);
  return (
    <div>
      <div className="formFields">
        <div className="layoutcell">
          <div className="layoutcell">
            <div
              className="form-field-with-tooltip"
              id={'online-payment-radio'}
            >
              <Checkbox
                className="check-box-item-checkBox"
                checked={isEnabledState}
                disabled={props.fieldProperties.disabled}
                required={props.fieldProperties.required}
                onChange={event => {
                  handleRadioChange(event);
                }}
              />
              <label className="radio-item-label">{'Allow'}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
