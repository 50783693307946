import React from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { DataTableCell, DataTableRow } from '@cdk-uip/react-data-table';
import SolutionDetailsCell from './SolutionDetailsCell';
import moment from 'moment';

class SolutionLeadTable extends React.Component {
  render() {
    const { solutionLead, rowKey } = this.props;
    return (
      <DataTableRow className="lead-row" key={rowKey}>
        <DataTableCell style={{ textAlign: 'left' }}>
          {moment(solutionLead.leadGenerationDate).format('DD MMM YYYY')}
        </DataTableCell>
        <DataTableCell
          className="fm-expansion-panel-title"
          style={{ textAlign: 'left' }}
        >
          <SolutionDetailsCell
            solution={solutionLead.solution}
            loading={false}
          />
        </DataTableCell>
        <DataTableCell style={{ textAlign: 'left' }}>
          {solutionLead.userName}
        </DataTableCell>
        <DataTableCell style={{ textAlign: 'left' }}>
          <a href={`mailto:${solutionLead.leadEmail}`}>
            {solutionLead.leadEmail}
          </a>
        </DataTableCell>
        <DataTableCell style={{ textAlign: 'left' }}>
          <div className="lead-message-with-tooltip">
            <div
              className={
                solutionLead.message && solutionLead.message.length > 80
                  ? `lead-message cursor-pointer`
                  : `lead-message`
              }
              id={`tooltip_${rowKey}`}
            >
              {solutionLead.message && solutionLead.message.length > 80
                ? solutionLead.message.substring(0, 80) + '...'
                : solutionLead.message}
            </div>
            {solutionLead.message && solutionLead.message.length > 80 && (
              <Tooltip
                className="view-lead-message-tooltip admin-leads-tooltip-position"
                htmlFor={`tooltip_${rowKey}`}
              >
                <span>{solutionLead.message}</span>
              </Tooltip>
            )}
          </div>
        </DataTableCell>
      </DataTableRow>
    );
  }
}
export default SolutionLeadTable;
