import React from 'react';
import SolutionStatus from '../../../common/SolutionStatus';
import { Subheading1 } from '@cdk-uip/react-typography';
import SolutionStatusHistoryCard from '../../../../containers/SolutionStatusHistoryContainer';
import { withAuth } from '@cdk-prod/fortellis-auth-context';

export class SolutionStatusHistory extends React.Component {
  render() {
    const {
      auth: { userData }
    } = this.props;
    return (
      <div>
        {this.props.fieldProperties.label && (
          <Subheading1 className="checkbox-group-notes">
            {this.props.fieldProperties.label}
          </Subheading1>
        )}

        {this.props.formFieldValues &&
          this.props.formFieldValues.length > 0 &&
          this.props.formFieldValues
            .slice(0)
            .reverse()
            .map((comment, index) => (
              <SolutionStatusHistoryCard
                key={index}
                commentText={comment.commentText}
                action={comment.action}
                commentDate={comment.commentDate}
                solutionId={this.props.formStaticValues.id}
                actor={comment.userId}
                userEmail={userData.email}
              />
            ))}

        <SolutionStatusHistoryCard
          commentText=""
          action={SolutionStatus.REGISTERED}
          commentDate={
            this.props.formStaticValues &&
            this.props.formStaticValues.registeredOn
              ? this.props.formStaticValues.registeredOn
              : ''
          }
          actor={
            this.props.formStaticValues &&
            this.props.formStaticValues.developerName
              ? this.props.formStaticValues.developerName
              : ''
          }
        />
      </div>
    );
  }
}

export default withAuth(SolutionStatusHistory);
