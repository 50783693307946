import React from 'react';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableHeaderCell,
  DataTableRow,
  DataTableToolbar
} from '@cdk-uip/react-data-table';
import { Card } from '@cdk-uip/react-card';
import Spinner from '../../common/Spinner';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import EntityWithAvatar from '../../common/EntityWithAvatar';
import SubscriptionStatus from '../../common/subscription-status/SubscriptionStatus';
import SearchComponent from '../../common/SearchComponent';
import ListingFilters from '../../marketplace-account/my-solutions/solution-management/panels/ListingFilters';
import EmptyContent from '../../common/empty-content';
import { FortellisConstants } from '../../common/FortellisConstants';
import SolutionWithAvatarLogo from '../../common/SolutionWithAvatarLogo';
import SelectComponent from '../../common/SelectComponent';
import SolutionTypeURLMapperUtil from '../../common/SolutionTypeURLMapperUtil';
import { withRouter } from 'react-router-dom';

const filterOptions = Object.freeze({
  ALL: 'all',
  SOLUTION: 'solution',
  ENTITY: 'entity',
  PLAN: 'plan',
  SUBSCRIPTION_STATUS: 'subscription_status'
});

class AllSubscriptions extends React.Component {
  state = {
    solutionSubscriptions: [],
    solutionVersionToListingVerMap: {},
    selectedSolutionVersion: FortellisConstants.SHOW_ALL,
    selectedListingVersion: FortellisConstants.SHOW_ALL,
    searchText: '',
    labelValueArrayForFilterBy: [
      {
        label: this.props.intl.formatMessage(
          { id: 'AllSubscriptions.table.filter.all' },
          { defaultMessage: 'All' }
        ),
        value: filterOptions.ALL
      },
      {
        label: this.props.intl.formatMessage(
          { id: 'AllSubscriptions.table.column1' },
          { defaultMessage: 'Entity' }
        ),
        value: filterOptions.ENTITY
      },
      {
        label: this.props.intl.formatMessage(
          { id: 'AllSubscriptions.table.column7' },
          { defaultMessage: 'App' }
        ),
        value: filterOptions.SOLUTION
      },
      {
        label: this.props.intl.formatMessage(
          { id: 'AllSubscriptions.table.column2' },
          { defaultMessage: 'Plan' }
        ),
        value: filterOptions.PLAN
      },
      {
        label: this.props.intl.formatMessage(
          { id: 'AllSubscriptions.table.column6' },
          { defaultMessage: 'Subscription Status' }
        ),
        value: filterOptions.SUBSCRIPTION_STATUS
      }
    ],
    filterByValue: '',
    entity: null
  };

  componentDidMount() {
    this.fetchSolutionSubscriptions();
  }

  fetchSolutionSubscriptions = async () => {
    const client = getApolloClient();
    this.setState({ loading: true });
    let getSolutionSubscriptionsResponse = await client.query({
      query: getSolutionSubscriptions
    });

    if (
      getSolutionSubscriptionsResponse &&
      getSolutionSubscriptionsResponse.errors
    ) {
      this.setState({ errorFetchingSubscriptions: true });
    } else if (
      getSolutionSubscriptionsResponse &&
      getSolutionSubscriptionsResponse.data &&
      getSolutionSubscriptionsResponse.data.solutionSubscriptions
    ) {
      this.setState({
        solutionSubscriptions:
          getSolutionSubscriptionsResponse.data.solutionSubscriptions
      });
      let entityIds = [];
      if (
        Array.isArray(
          getSolutionSubscriptionsResponse.data.solutionSubscriptions
        )
      ) {
        entityIds = getSolutionSubscriptionsResponse.data.solutionSubscriptions.map(
          item => {
            return item.entityId;
          }
        );
      }
      this.createSolutionVerToListingVerMapping(
        getSolutionSubscriptionsResponse.data.solutionSubscriptions
      );
      this.getEntityNames(entityIds);
    }

    this.setState({ loading: false });
  };

  getEntityNames = async entityIds => {
    const client = getApolloClient();
    let entityResponse = await client.query({
      query: getEntitiesQuery,
      variables: { id: entityIds }
    });
    if (!entityResponse.errors) {
      this.setState({
        entity: entityResponse.data.subscribedEntitiesFromPlatfom
          ? entityResponse.data.subscribedEntitiesFromPlatfom
          : null
      });
      return entityResponse.data.subscribedEntitiesFromPlatfom &&
        entityResponse.data.subscribedEntitiesFromPlatfom.name
        ? entityResponse.data.subscribedEntitiesFromPlatfom.name
        : null;
    }
    return null;
  };

  createSolutionVerToListingVerMapping = subscriptions => {
    let subscriptionsWithVersion = subscriptions.filter(
      subscription =>
        subscription.solution &&
        subscription.solution.overview &&
        subscription.solution.overview.version
    );

    let solVersions = subscriptionsWithVersion.map(
      subscription => subscription.solution.overview.version
    );
    let distictSolVersions = [...new Set(solVersions)];
    distictSolVersions = distictSolVersions.filter(version => version); //null check

    let listingVersions = subscriptions
      .filter(subscription => subscription.listingVersion)
      .map(subscription => subscription.listingVersion);
    let distictListingVersions = [...new Set(listingVersions)];

    let solutionVersionToListingVerMap = {};
    solutionVersionToListingVerMap[FortellisConstants.SHOW_ALL] = [
      FortellisConstants.SHOW_ALL,
      ...distictListingVersions
    ];

    distictSolVersions.forEach(version => {
      let respectiveListingVersions = [];
      respectiveListingVersions.push(FortellisConstants.SHOW_ALL);

      subscriptionsWithVersion.forEach(subscription => {
        if (version === subscription.solution.overview.version) {
          if (subscription.listingVersion) {
            respectiveListingVersions.push(subscription.listingVersion);
          }
        }
      });
      respectiveListingVersions = [...new Set(respectiveListingVersions)]; //distinct
      respectiveListingVersions = respectiveListingVersions.filter(
        listingVersion => listingVersion
      ); //null check
      solutionVersionToListingVerMap[`${version}`] = respectiveListingVersions;
    });

    this.setState({
      solutionVersionToListingVerMap: solutionVersionToListingVerMap
    });
  };

  onFilterChange = (solutionVersion, listingVersion) => {
    this.setState({
      selectedSolutionVersion: solutionVersion,
      selectedListingVersion: listingVersion
    });
  };

  onSearchChange = val => {
    this.setState({ searchText: val });
  };

  onEntityReception = entity => {
    let subcriptionCopy = JSON.parse(
      JSON.stringify(this.state.solutionSubscriptions)
    );
    subcriptionCopy.forEach(subscription => {
      if (subscription.entityId === entity.id) {
        Object.assign(subscription, { entityName: entity.name });
      }
    });
    this.setState({ solutionSubscriptions: subcriptionCopy });
  };

  onSubscriptionFilterByChange = filterValue => {
    this.setState({ filterByValue: filterValue });
  };

  render() {
    const {
      solutionSubscriptions,
      selectedSolutionVersion,
      selectedListingVersion,
      searchText,
      filterByValue,
      entity
    } = this.state;
    let subscriptionsToDisplay = solutionSubscriptions;
    let entityDetails = entity;

    if (
      solutionSubscriptions &&
      solutionSubscriptions.length &&
      selectedSolutionVersion !== FortellisConstants.SHOW_ALL
    ) {
      subscriptionsToDisplay = subscriptionsToDisplay.filter(
        subscription =>
          subscription.solution &&
          subscription.solution.overview &&
          subscription.solution.overview.version &&
          subscription.solution.overview.version === selectedSolutionVersion
      );
    }

    if (
      solutionSubscriptions &&
      solutionSubscriptions.length &&
      selectedListingVersion !== FortellisConstants.SHOW_ALL
    ) {
      subscriptionsToDisplay = subscriptionsToDisplay.filter(
        subscription =>
          subscription.listingVersion &&
          subscription.listingVersion === selectedListingVersion
      );
    }

    if (solutionSubscriptions && solutionSubscriptions.length && searchText) {
      let splittedSearchValues = searchText
        .toString()
        .toLowerCase()
        .trim()
        .split(' ');
      if (filterByValue === filterOptions.ENTITY) {
        splittedSearchValues.forEach(searchText => {
          subscriptionsToDisplay = subscriptionsToDisplay.filter(
            subscription =>
              subscription.entityName &&
              subscription.entityName.toLowerCase().includes(searchText)
          );
        });
      } else if (filterByValue === filterOptions.SOLUTION) {
        splittedSearchValues.forEach(searchText => {
          subscriptionsToDisplay = subscriptionsToDisplay.filter(
            subscription =>
              subscription.solution &&
              subscription.solution.overview &&
              subscription.solution.overview.name &&
              subscription.solution.overview.name
                .toLowerCase()
                .includes(searchText)
          );
        });
      } else if (filterByValue === filterOptions.PLAN) {
        splittedSearchValues.forEach(searchText => {
          subscriptionsToDisplay = subscriptionsToDisplay.filter(
            subscription =>
              subscription.plan &&
              subscription.plan.planName &&
              subscription.plan.planName.toLowerCase().includes(searchText)
          );
        });
      } else if (filterByValue === filterOptions.SUBSCRIPTION_STATUS) {
        splittedSearchValues.forEach(searchText => {
          subscriptionsToDisplay = subscriptionsToDisplay.filter(
            subscription =>
              subscription.status &&
              subscription.status.toLowerCase().includes(searchText)
          );
        });
      } else {
        splittedSearchValues.forEach(searchText => {
          subscriptionsToDisplay = subscriptionsToDisplay.filter(
            subscription =>
              (subscription.status &&
                subscription.status.toLowerCase().includes(searchText)) ||
              (subscription.entityName &&
                subscription.entityName.toLowerCase().includes(searchText)) ||
              (subscription.plan &&
                subscription.plan.planName &&
                subscription.plan.planName
                  .toLowerCase()
                  .includes(searchText)) ||
              (subscription.solution &&
                subscription.solution.overview &&
                subscription.solution.overview.name &&
                subscription.solution.overview.name
                  .toLowerCase()
                  .includes(searchText))
          );
        });
      }
    }

    return (
      <div className="component-content">
        <div className="fm-page-content-grid max-width-resp page-padding-common">
          <div className="max-width-resp fm-page-content-title admin-subcriptions-title-container">
            <FormattedMessage
              id="AllSubscriptions.page.title"
              defaultMessage="Subscriptions"
            />
          </div>
          <div className="max-width-resp">
            <Card className="sol-managemnt-subscriptions_table">
              <DataTableToolbar>
                <div className="listing-filters-parent inline-block">
                  <div className="inline-block lead-filters float-left">
                    <SelectComponent
                      name="filterBy"
                      label="Filter by"
                      value={
                        this.state.selectedSubscriptionFilter ||
                        filterOptions.ALL
                      }
                      options={this.state.labelValueArrayForFilterBy || []}
                      className="commonSelectfield"
                      onSelectChange={this.onSubscriptionFilterByChange}
                    />
                  </div>
                  <div className="inline-block lead-filters lead-search-parent">
                    <SearchComponent
                      className="lead-search"
                      label="Search"
                      onChange={this.onSearchChange}
                    />
                  </div>
                </div>

                <div className="listing-filters-parent">
                  <ListingFilters
                    optionMapping={this.state.solutionVersionToListingVerMap}
                    selectedSolutionVersion={this.state.selectedSolutionVersion}
                    selectedListingVersion={this.state.selectedListingVersion}
                    onFilterChange={this.onFilterChange}
                    className="pull-right lead-filters"
                  />
                </div>
              </DataTableToolbar>
              {this.state.loading ? (
                <div>
                  <Spinner />
                </div>
              ) : subscriptionsToDisplay &&
                subscriptionsToDisplay.length > 0 ? (
                <DataTable>
                  <DataTableHeader>
                    <DataTableRow>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column1"
                          defaultMessage="Entity"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column7"
                          defaultMessage="App"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column2"
                          defaultMessage="Plan"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text textAlign-right"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column3"
                          defaultMessage="App Version"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text textAlign-right"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column4"
                          defaultMessage="Listing Version"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column5"
                          defaultMessage="Subscribed On"
                        />
                      </DataTableHeaderCell>
                      <DataTableHeaderCell
                        className="sol-managemnt-subscriptions_table-header-text textAlign-right"
                        nonNumeric
                      >
                        <FormattedMessage
                          id="AllSubscriptions.table.column6"
                          defaultMessage="Subscription Status"
                        />
                      </DataTableHeaderCell>
                    </DataTableRow>
                  </DataTableHeader>
                  <DataTableBody>
                    {subscriptionsToDisplay &&
                      subscriptionsToDisplay.map(subscription => {
                        const solutionType =
                          subscription.solution &&
                          subscription.solution.solutionType
                            ? SolutionTypeURLMapperUtil.getSolutionTypeName(
                                subscription.solution.solutionType
                              )
                            : FortellisConstants.FORTELLIS_TYPE_URL;
                        return (
                          <DataTableRow
                            className="sol-managemnt-subscriptions-table-row"
                            key={subscription.id}
                            onClick={() =>
                              this.props.history.push(
                                `/marketplace-account/mysolutions/${solutionType}/${
                                  subscription.solutionId
                                }/solution-management/subscriptions/${
                                  subscription.subscriptionId
                                }/${subscription.entityId}`
                              )
                            }
                            id="subscription-details-row"
                          >
                            <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text">
                              {/*todo - replace hardcoded entity by subscription.entityId when Entity comes from platform*/}
                              {entityDetails && (
                                <EntityWithAvatar
                                  entityId={subscription.entityId}
                                  entityDetails={entityDetails}
                                  onEntityReception={this.onEntityReception}
                                />
                              )}
                            </DataTableCell>
                            <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text">
                              <SolutionWithAvatarLogo
                                solution={subscription.solution}
                              />
                            </DataTableCell>
                            <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text">
                              {subscription.plan.planName}
                            </DataTableCell>
                            <DataTableCell className="sol-managemnt-subscriptions_table-body-text textAlign-right">
                              {subscription.solution &&
                              subscription.solution.overview &&
                              subscription.solution.overview.version
                                ? subscription.solution.overview.version
                                : '-'}
                            </DataTableCell>
                            <DataTableCell className="sol-managemnt-subscriptions_table-body-text textAlign-right">
                              {subscription.listingVersion
                                ? subscription.listingVersion
                                : '-'}
                            </DataTableCell>
                            <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text">
                              {subscription.dateOfPurchase
                                ? moment(subscription.dateOfPurchase).format(
                                    'DD MMM YYYY'
                                  )
                                : '-'}
                            </DataTableCell>
                            <DataTableCell className="nowrap sol-managemnt-subscriptions_table-body-text textAlign-right">
                              {subscription && subscription.status && (
                                <SubscriptionStatus
                                  subscription={subscription}
                                />
                              )}
                            </DataTableCell>
                          </DataTableRow>
                        );
                      })}
                  </DataTableBody>
                </DataTable>
              ) : (
                <EmptyContent
                  message={
                    <FormattedMessage
                      id="AllSubscriptions.subscriptionsEmpty"
                      defaultMessage="No subscriptions yet, Check back soon!"
                    />
                  }
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const getSolutionSubscriptions = gql`
  query {
    solutionSubscriptions {
      id
      subscriptionId
      listingVersion
      solutionId
      entityId
      userId
      status
      plan {
        id
        planName
      }
      dateOfPurchase
      solution {
        id
        developerId
        overview {
          name
          version
          solutionLogo
        }
        registeredOn
      }
    }
  }
`;
const getEntitiesQuery = gql`
  query($id: [ID]) {
    subscribedEntitiesFromPlatfom(id: $id) {
      id
      name
      website
      phone
      type
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
    }
  }
`;

export default injectIntl(withRouter(AllSubscriptions));
