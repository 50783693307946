export const config = {
  formId: 'custom_consent',
  //formTitle: 'Dealer Consent Form',
  formTitle: 'Dealer Integration Addendum',
  formValueType: 'Object',
  formNote:
    'By selecting below option, you can override the dealer integration addendum for selected APIs',
  tabs: [
    {
      id: 'custom_consent',
      //label: 'Custom Consent',
      label: 'Dealer Integration Addendum',
      contents: [
        {
          id: 'custom_consent_data',
          fields: [
            {
              name: 'custom_consent',
              label: 'Custom Consent for app',
              disabled: false,
              type: 'custom_consent',
              style: 'custom-consent-style',
              gridStyle: 'layoutcell'
            }
          ]
        }
      ]
    }
  ],
  formFields: {
    custom_consent: {
      customConsentForm: '',
      fileName: '',
      selectedApis: []
    }
  },
  activeTabId: 'custom_consent'
};
