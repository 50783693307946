import React from 'react';
import { Card } from 'cdk-radial';
import './SolutionSkeleton.scss';

const SolutionSkeleton = () => {
  return (
    <Card className="solution-card__component" data-qa="solution_card">
      <div className="solution-card__top_div">
        <div className="skeleton__logo" />
        <div className="skeleton__solutionTitle_div" />

        <div className="skeleton__solutionDesciption_div" />
      </div>
      <div className="solution-card__ruler" />
      <div className="solution-card__worksWith_category_div">
        <div className="solution-card__worksWith_div">Category</div>
        <div>
          <span className="skeleton__worksWith_solutionName" />
        </div>
      </div>
    </Card>
  );
};

export default SolutionSkeleton;
