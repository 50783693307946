import axios from 'axios';
import { environmentURLs } from '../../src/components/common/environment/CaptureEnvironment';

const apiClient = axios.create({
  // baseURL: 'http://localhost:4000' // Enable, When want to render marketplace anonymous view with Localhost
});
const consentServiceBaseUrl = environmentURLs.consentServiceBaseUrl;

export default {
  anonymous: {
    searchSolution(searchText) {
      return apiClient.get(`/anon/listing/search?q=${searchText}`);
    },

    getExtensibilityApp(solutionId) {
      return apiClient.get(`/anon/extensibility?solutionId=${solutionId}`);
    },

    getAllSolutions(category, payload) {
      if (category === 'all') {
        category = null;
      }

      return apiClient.post(`/anon/listing`, {
        category: category,
        offset: payload
      });
    },
    getSolutionById(id, payload) {
      if (id) {
        const entityId = null;
        return apiClient.get(
          `/anon/solutions/${id}?entityId=${entityId}`,
          payload
        );
      }
    },
    getReviewsForSolution(solutionId, createdDateDescendingSortOrder) {
      return apiClient.get(
        `/anon/reviews/solution/${solutionId}?sortOrder=${createdDateDescendingSortOrder}`
      );
    },
    getApiDetailsForSolution(payload) {
      return apiClient.post('/anon/providers', payload);
    },
    getOrganizationById(id) {
      return apiClient.get(`/anon/organizations/${id}`);
    },
    captureUserQuestion: async userQuestion => {
      return await apiClient.post('/anon/user-questions', { userQuestion });
    },
    getFeaturedSolutions: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const responseData = await apiClient.get('/anon/featured-solutions');
          resolve(responseData.data);
        } catch (err) {
          console.error('call to anon service failed!');
          reject(null);
        }
      });
    },
    async getSolutionsConsentedProvider(
      solutionId,
      solutionListingVersion,
      consentType,
      auth
    ) {
      const authorization = await this.getAuthHeader(auth);
      return apiClient.get(
        `${consentServiceBaseUrl}/solutions/${solutionId}/${solutionListingVersion}/consents?consentType=${consentType}`,
        {
          headers: authorization
        }
      );
    },
    getAnonSolutionsConsentedProvider(
      solutionId,
      solutionListingVersion,
      consentType
    ) {
      return apiClient.get(
        `${consentServiceBaseUrl}/anon/solutions/${solutionId}/${solutionListingVersion}/consents?consentType=${consentType}`
      );
    },
    getAuthHeader(auth) {
      const { accessToken } = auth;
      if (accessToken) {
        return {
          Authorization: `Bearer ${accessToken}`
        };
      }
    },
    getWorksWithValues() {
      return apiClient.get(`/anon/works-with-values`);
    },
    getSolutionProviders(filterType) {
      return apiClient.get(`/anon/solutions/providers/search/${filterType}`);
    },
    getStoreFrontSolutions(payload) {
      return apiClient.post('/anon/solutions', payload);
    }
  }
};
