import React from 'react';
import { injectIntl } from 'react-intl';
import {
  ExpansionPanelContentActions,
  ExpansionPanelContentBody
} from '@cdk-uip/react-expansion-panel';
import StepContentActions from './step-content-actions';
import { withRouter } from 'react-router-dom';

class StepConfigureApiContent extends React.Component {
  state = {
    redirectToConfiguration: false
  };
  redirectToApiConfig = () => {
    this.props.history.push(
      `/marketplace-account/mysubscriptions/${
        this.props.subscription.subscriptionId
      }/apiconfig`
    );
  };

  getGrantedActions() {
    let grantedActions = [];
    if (
      this.props.userPermissions &&
      this.props.userPermissions.includes('ConfigureSubscription')
    ) {
      grantedActions.push({
        label: 'Configure Now',
        isPrimary: false,
        isRaised: true,
        outlined: true,
        onClick: () => {
          this.redirectToApiConfig();
        }
      });
    }
    return grantedActions;
  }
  render() {
    const actions = this.getGrantedActions();
    return (
      <section>
        <ExpansionPanelContentBody>
          <div className="panel-content-div">
            Configure APIs to authorize this app to access your data. This step
            involves selecting the API providers that match your existing
            systems.
          </div>
        </ExpansionPanelContentBody>
        <ExpansionPanelContentActions className="panel-action-div">
          <StepContentActions actions={actions} />
        </ExpansionPanelContentActions>
      </section>
    );
  }
}

export default injectIntl(withRouter(StepConfigureApiContent));
