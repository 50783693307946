import React from 'react';
import FormField from './FormComponents/FormField';
import { Subheading1, Subheading2 } from '@cdk-uip/react-typography';
import ProviderAndConsentList from '../ProviderAndConsentList';
import PaymentAdminControl from '../../admin-account/solution-moderation/PaymentAdminControl';
import AdminControlAdditionalInfo from '../../admin-account/solution-moderation/AdminControlAdditionalInfo';
import CustomConsentForm from '../../admin-account/solution-moderation/CustomConsentForm';
import Authorizedsignon from '../../admin-account/solution-moderation/Authorizedsignon';
import AdminControlListingFee from '../../admin-account/solution-moderation/AdminControlListingFee';
import FeaturedAppAdminControl from '../../admin-account/solution-moderation/FeaturedAppAdminControl';

export class FormBuilder extends React.Component {
  render() {
    const { content, formFieldValues } = this.props;
    if (!content || !formFieldValues) {
      return false;
    }
    return (
      content.fields &&
      content.fields.map((tabField, index) => {
        return (
          (!tabField.show ||
            tabField.show(
              this.props.formFieldValues[tabField.dependentOn]
            )) && (
            <div
              className={
                tabField.fieldStyle ? tabField.fieldStyle : 'formFields'
              }
              key={index}
            >
              {tabField.type === 'list' ? (
                <ProviderAndConsentList
                  {...this.props}
                  items={this.props.formFieldValues[tabField.name]}
                  formStaticValues={this.props.formStaticValues}
                  onChange={this.props.onSave}
                  fieldProperties={tabField}
                />
              ) : tabField.type === 'authorizedsignon' ? (
                <Authorizedsignon {...this.props} fieldProperties={tabField} />
              ) : tabField.type === 'payment' ? (
                <PaymentAdminControl
                  {...this.props}
                  fieldProperties={tabField}
                />
              ) : tabField.type === 'adminControlAdditionalInfo' ? (
                <AdminControlAdditionalInfo
                  {...this.props}
                  fieldProperties={tabField}
                />
              ) : tabField.type === 'adminControlListingFee' ? (
                <AdminControlListingFee
                  {...this.props}
                  fieldProperties={tabField}
                />
              ) : tabField.type === 'adminControlFeaturedApp' ? (
                <FeaturedAppAdminControl
                  {...this.props}
                  fieldProperties={tabField}
                />
              ) : tabField.type === 'custom_consent' ? (
                <CustomConsentForm {...this.props} fieldProperties={tabField} />
              ) : (
                <FormField
                  {...this.props}
                  fieldProperties={tabField}
                  formValues={this.props.formValues}
                  formFieldValuesAll={this.props.formFieldValuesAll}
                  formStaticValues={this.props.formStaticValues}
                  formFieldValues={this.props.formFieldValues}
                  formValueCount={this.props.formValueCount}
                  value={this.props.formFieldValues[tabField.name]}
                  onChange={this.props.onSave}
                />
              )}
              {tabField.hasDependent
                ? tabField.dependentContent.show &&
                  tabField.dependentContent.show(
                    this.props.formFieldValues[tabField.name]
                  ) && (
                    <div>
                      {tabField.dependentNote && (
                        <Subheading1 className="dependentNote">
                          {tabField.dependentNote}
                        </Subheading1>
                      )}
                      <div className="dependent-div">
                        {tabField.dependentContent.title && (
                          <Subheading2>
                            {tabField.dependentContent.title(
                              this.props.formFieldValues[tabField.name]
                            )}
                          </Subheading2>
                        )}
                        <FormBuilder
                          key={index}
                          content={tabField.dependentContent}
                          formStaticValues={this.props.formStaticValues}
                          formFieldValues={this.props.formFieldValues}
                          formValues={this.props.formValues}
                          onSave={this.props.onSave}
                        />
                      </div>
                    </div>
                  )
                : false}
            </div>
          )
        );
      })
    );
  }
}

export default FormBuilder;
