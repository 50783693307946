import React from 'react';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';

export const solutionDetails = gql`
  query($solutionId: ID) {
    solutionDetails(id: $solutionId) {
      activateOnly
      overview {
        name
        publisherName
      }
    }
  }
`;

export class AppTypeBreadcrumb extends React.Component {
  state = {
    data: ''
  };

  getSolutionData = async solutionId => {
    const client = getApolloClient();
    let data = await client.query({
      query: solutionDetails,
      variables: {
        solutionId: solutionId
      }
    });
    return data;
  };

  componentDidMount() {
    if (
      !this.props ||
      !this.props.match ||
      !this.props.match.params ||
      !this.props.match.params.id
    ) {
      return;
    }
    let data = this.getSolutionData(this.props.match.params.id);
    data.then(response => {
      let data = '';
      if (response && response.data && response.data.solutionDetails.length) {
        if (response.data.solutionDetails.slice(-1)[0].activateOnly === false) {
          data = 'Subscriptions';
        } else {
          data = 'Activations';
        }
      }
      this.setState({ data: data });
    });
  }

  render() {
    return <span>{this.state.data}</span>;
  }
}
