import React from 'react';
import { Button } from '@cdk-uip/react-button';
import { FormattedMessage } from 'react-intl';

const Preview = props => {
  function urlChange(e) {
    try {
      var iframeWindowLocation = e.target.contentWindow.location;
      if (
        iframeWindowLocation &&
        iframeWindowLocation.search === '?event=signing_complete'
      ) {
        props.onLoadPreviewFinish('Accepted');
      } else if (iframeWindowLocation.search === '?event=decline') {
        props.onLoadPreviewFinish('Declined');
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className={'preview__container'}>
      <iframe
        className={'preview__frame'}
        src={props.previewUrl}
        onLoad={e => urlChange(e)}
      />
      <div className={'preview__footer'}>
        <div>Do your term fields look correct?</div>
        <span className={'preview__footer__button__container'}>
          <Button
            className={'preview__footer__button__returnToEdit'}
            type="button"
            onClick={() => props.onClose()}
          >
            <span>NO, RETURN TO EDIT</span>
          </Button>
          <Button
            className="submission-action-button-margin preview__footer__button__acceptTerms"
            compact={'true'}
            type="submit"
            unelevated
            disabled={false}
            onClick={() => props.onLoadPreviewFinish('Accepted')}
          >
            <FormattedMessage
              id={'TermsOfUse.SaveAndFinish'}
              defaultMessage="YES, SAVE TERMS"
            />
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Preview;
