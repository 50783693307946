import React, { useEffect } from 'react';
import { environmentURLs } from '../../components/common/environment/CaptureEnvironment';

export default function StripeRedirect() {
  const urlParams = new URLSearchParams(window.location.search);
  const scope = urlParams.get('scope');
  const code = urlParams.get('code');
  useEffect(() => {
    const parentWindow = window.opener;
    parentWindow.postMessage(
      { scope, code },
      environmentURLs.marketplaceURL.substring(
        0,
        environmentURLs.marketplaceURL.lastIndexOf('/')
      )
    );
  }, []);
  setTimeout(() => window.close(), 3000);

  return (
    <React.Fragment>
      <div className="connect_with_stripe_text">loading ...</div>
    </React.Fragment>
  );
}
