import React from 'react';
import MySubscriptionListItem from './subscriptions/my-subscription-list-item';
import EmptyContent from '../common/empty-content';

class SubscriptionsList extends React.Component {
  render() {
    const { list, entity, statusChip } = this.props;
    let filteredList = null;
    if (statusChip && list.length > 0) {
      filteredList = list.filter(subscription => {
        return subscription.status === statusChip;
      });
    } else {
      filteredList = list;
    }

    return filteredList.length > 0 ? (
      filteredList.map((subscription, subscriptionIdx) =>
        subscription.solution && subscription.solution.overview ? (
          <MySubscriptionListItem
            {...this.props}
            subscription={subscription}
            entity={entity}
            key={subscriptionIdx}
            statusChip={statusChip ? statusChip : null}
          />
        ) : (
          false
        )
      )
    ) : (
      <EmptyContent message={`No subscriptions yet, Check back soon!`} />
    );
  }
}

export default SubscriptionsList;
