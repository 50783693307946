import React from 'react';
import { injectIntl } from 'react-intl';
import { withApollo } from 'react-apollo';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { withRouter } from 'react-router-dom';
import { Title } from '@cdk-uip/react-typography';
import { CardHeader, Card } from '@cdk-uip/react-card';
import { Button } from '@cdk-uip/react-button';
import cloneDeep from 'lodash/cloneDeep';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';

import SideNav from '../../../../solution-submission/common-sidenav-api/SideNavComponent/SideNav';
import MenuDataConfig from './menu-config';
import MySubscriptionListItemHeaderTitle from '../../../subscriptions/my-subscription-list-item-header-title';
import MySubscriptionListItemHeaderLogo from '../../../subscriptions/my-subscription-list-item-header-logo';
import SubscriptionManagementCardItem from './subscription-management-card-item';
import ApiConfigStatusForSubscriptionConstants from '../../../../common/ApiConfigStatusForSubscriptionConstants';
import SubscriptionStatusConstants from '../../../../common/subscription-status/SubscriptionStatusConstants';

import ApiConnectionStatusConstants from '../../../../common/ApiConnectionStatusConstants';
import SolutionLogo from '../../../../common/images/cogs.png';
import Spinner from '../../../../common/Spinner';
import EntityDetails from '../../../../common/EntityDetails';

import moment from 'moment';
import CompleteProvisioningDialog from './CompleteProvisioningDialog';
import SubscriptionPlanDetails from './SubscriptionPlanDetails';
import MySubscriptionStatus from '../../../subscriptions/my-subscription-status';
import SubscriptionApiConfiguration from './SubscriptionApiConfiguration';
import SubscriptionManagementBillingDetails from './subscription-management-billing-details';
import mapKeys from 'lodash/mapKeys';
import FortellisDialog from '../../../../common/FortellisDialog';
import ActionSnackBar from '../../../../../components/solution-submission/ActionSnackBar';
import { sendAmplitudeData } from '../../../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../../../utils/amplitude-constants';

const subscriptionFromDBQuery = gql`
  query($entityId: ID, $solutionId: ID) {
    solutionSubscriptions(entityId: $entityId, solutionId: $solutionId) {
      id
      solutionId
      subscriptionId
      entityId
      status
      listingVersion
      dateOfPurchase
      userProvisioningStatus
      apiActivationStatus
      subscriptionStartDate
      subscriptionEndDate
      reminderSentOn
      plan {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      solution {
        id
        activateOnly
        developerId
        overview {
          name
          publisherName
          solutionLogo
        }
        support {
          phoneNumber
          emailAddress
        }
        provisioning_interface {
          endpointUrl
          redirectURL
          provisioningtype
        }
        registration_details {
          api_details {
            id
            name
            isOptional
          }
        }
      }
      userProvisioningStatus
      provisioningCompletedComment
      provisioningCompletedBy
      provisioningCompletedByEmail
      provisioningCompletedOn
      billingStartsOn
      billingEndsOn
      subscriptionCreatedOn
    }
  }
`;

const entityFromPlatform = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      website
      lastUpdated
      created
      address {
        region
        city
        street
        countryCode
        postalCode
      }
      phone
      name
      type
    }
  }
`;

const updateCompleteProvisioning = gql`
  mutation(
    $id: ID!
    $provisioningCompletedComment: String
    $provisioningCompletedBy: String
    $provisioningCompletedByEmail: String
    $provisioningCompletedOn: String
    $userProvisioningStatus: String
    $billingStartsOn: String
    $billingEndsOn: String
    $status: String
    $subscriptionCompletedByEmail: String
    $subscriptionCompletedBy: String
  ) {
    updateCompleteProvisioning(
      command: {
        id: $id
        provisioningCompletedComment: $provisioningCompletedComment
        provisioningCompletedBy: $provisioningCompletedBy
        provisioningCompletedByEmail: $provisioningCompletedByEmail
        provisioningCompletedOn: $provisioningCompletedOn
        userProvisioningStatus: $userProvisioningStatus
        billingStartsOn: $billingStartsOn
        billingEndsOn: $billingEndsOn
        status: $status
        subscriptionCompletedByEmail: $subscriptionCompletedByEmail
        subscriptionCompletedBy: $subscriptionCompletedBy
      }
    ) {
      id
      solutionId
      subscriptionId
      entityId
      status
      listingVersion
      dateOfPurchase
      userProvisioningStatus
      apiActivationStatus
      subscriptionStartDate
      subscriptionEndDate
      reminderSentOn
      plan {
        id
        isTheFreeTrial
        isFreeTrialEnabled
        position
        planName
        planDescription
        freeTrialPeriod
        freeTrialPeriodType
        billingType
        transactionalTypeOptions
        includedTransactionalType
        includedTransactionalFee
        billingFrequency
        basePrice
        includedUnitOfMeasure
        includedUnitQuantity
        additionalUnitPrice
        additionalUnitOfMeasure
        features {
          id
          title
          description
          featureImage
        }
      }
      solution {
        id
        overview {
          name
          publisherName
          solutionLogo
        }
        support {
          phoneNumber
          emailAddress
        }
        provisioning_interface {
          endpointUrl
          redirectURL
          provisioningtype
        }
      }
      userProvisioningStatus
      provisioningCompletedComment
      provisioningCompletedBy
      provisioningCompletedByEmail
      subscriptionCompletedByEmail
      subscriptionCompletedBy
      provisioningCompletedOn
      billingStartsOn
      billingEndsOn
      subscriptionCreatedOn
    }
  }
`;

const subscriptionFromPlatformQuery = gql`
  query($subscriptionId: ID) {
    getSubscription(subscriptionId: $subscriptionId) {
      subscriptionId
      entityId
      solutionId
      status
      activated
      connections {
        api
        solutionId
        connectionId
        providerId
        subscriptionId
        entityId
        status
      }
    }
  }
`;

const enableSubscriptionPlatformQuery = gql`
  mutation($subscriptionId: ID) {
    enableSubscription(subscriptionId: $subscriptionId) {
      subscriptionId
      entityId
      solutionId
      status
    }
  }
`;

const billingStartEntityEmail = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $solutionName: String
    $developerEmail: String
    $buyerName: String
    $subscriptionStartDate: String
    $subscriptionEndDate: String
  ) {
    sendEmail(
      command: {
        userDetails: { firstName: $firstName }
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          developerEmail: $developerEmail
          subscriptionDetails: {
            buyerName: $buyerName
            subscriptionStartDate: $subscriptionStartDate
            subscriptionEndDate: $subscriptionEndDate
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;

const cancelSubscriptionQuery = gql`
  mutation(
    $solutionId: String!
    $entityId: String!
    $subscriptionId: String
    $isBulkSubscribed: Boolean
  ) {
    appDeveloperCancellation(
      command: {
        solutionId: $solutionId
        entityId: $entityId
        subscriptionId: $subscriptionId
        isBulkSubscribed: $isBulkSubscribed
      }
    )
  }
`;

const getAsyncApiData = gql`
  query($solutionId: ID, $entityId: ID) {
    getAsyncConnections(solutionId: $solutionId, entityId: $entityId) {
      solutionId
      entityId
      api
      apiDisplayName
      apiProviderId
      apiType
      consentId
      consentType
      id
      subscriptionId
      status
      apiProviderName
    }
  }
`;

const solutionDetails = gql`
  query($solutionId: ID) {
    solutionDetails(id: $solutionId) {
      activateOnly
      overview {
        name
        solutionLogo
        publisherName
      }
    }
  }
`;

class SubscriptionManagement extends React.Component {
  state = {
    menu: MenuDataConfig.MenuData || {},
    platformSubscription: null,
    dbSubscription: null,
    apiConfigurationStatus: null,
    entity: null,
    openCancelSubscriptionDialog: false,
    configApiProviderBody: '',
    solutionProvisioningBody: '',
    openProvisioningDialog: false,
    subscriptionActivationLoading: false,
    showSnackBar: false,
    cancellingSubscription: false,
    solutionDetails: null
  };
  onSubscriptionActive() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.subscriptionId
    ) {
      let subscriptionId = this.props.match.params.subscriptionId;
      let status = SubscriptionStatusConstants.ACTIVE;
      let solutionName = this.state.solutionDetails.overview.name;
      let firstName = this.props.auth.userData.name
        ? this.props.auth.userData.name.split(' ')[0]
        : '';
      let bso = moment();
      let beo = this.calculateBillingEndDate();

      //--------------------------------------

      let variables = {
        id: this.state.dbSubscription?.id,
        subscriptionCompletedByEmail: this.props.auth.userData.email,
        subscriptionCompletedBy: this.props.auth.userData.name,
        billingStartsOn: bso.toISOString(),
        billingEndsOn: beo ? beo.toISOString() : null,
        status: status
      };
      let email_variables = {
        emailAddress: this.props.auth.userData.email,
        emailSubject: `${solutionName} is ready for use`,
        emailType: 'billingStartEntityEmail',
        firstName: firstName,
        solutionName: solutionName,
        developerEmail: this.state.solutionDetails?.developerId || ' ',
        buyerName: this.state.entity.name || ' ',
        subscriptionStartDate: bso.format('DD MMM YYYY'),
        subscriptionEndDate: beo ? beo.format('DD MMM YYYY') : ''
      };

      this.setState({ subscriptionActivationLoading: true });

      //----Enable subscription--------------
      this.props.client
        .mutate({
          mutation: enableSubscriptionPlatformQuery,
          variables: { subscriptionId }
        })
        .then(() => {
          //---- Email for activation -----------
          return this.props.client.mutate({
            mutation: billingStartEntityEmail,
            variables: email_variables
          });
        })
        .then(() => {
          //---- mutation in DB for activation -----------
          return this.props.client.mutate({
            mutation: updateCompleteProvisioning,
            variables: variables
          });
        })
        .then(response => {
          if (response.data && response.data.updateCompleteProvisioning) {
            this.setState({
              dbSubscription: response.data.updateCompleteProvisioning,
              subscriptionActivationLoading: false
            });
          }
        })
        .catch(e => {
          this.setState({ subscriptionActivationLoading: false });
        });
    }
  }
  getConfigApiProviderBody() {
    if (!this.state.entity) {
      return '';
    }
    if (
      this.state.apiConfigurationStatus ===
      ApiConfigStatusForSubscriptionConstants.ACTIVE
    ) {
      return (
        <FormattedMessage
          id="SubscriptionManagement.apiConfigurationCompleted"
          defaultMessage="API configuration completed."
        />
      );
    }
    return (
      <div>
        <FormattedMessage
          id="SubscriptionManagement.apiConfiguredByMessage"
          defaultMessage="API Will be configured by "
        />
        {this.state.entity.name + '.'}
      </div>
    );
  }

  showApiConfig() {
    let newManu = cloneDeep(this.state.menu);
    newManu.activeMenuId = 'api_configuration';
    this.setState({ menu: newManu });
  }

  getSolutionProvisioningBody() {
    if (!this.state.dbSubscription || !this.state.entity) {
      return '';
    }
    let message = (
      <div>
        <FormattedMessage
          id="SubscriptionManagement.provisionCompletedByMessage"
          defaultMessage="Provisioning to be completed by "
        />
        {this.props.auth.userData.name + '.'}
      </div>
    );
    if (
      this.state.dbSubscription?.userProvisioningStatus ===
      ApiConfigStatusForSubscriptionConstants.NOT_STARTED
    ) {
      message = (
        <div>
          {this.state.entity.name}
          <FormattedMessage
            id="SubscriptionManagement.provisionCompletedByMessage"
            defaultMessage=" has not initiated provisioning process yet."
          />
        </div>
      );
    }

    if (
      this.state.dbSubscription?.userProvisioningStatus ===
      ApiConfigStatusForSubscriptionConstants.COMPLETED
    ) {
      message = (
        <div>
          <FormattedMessage
            id="SubscriptionManagement.provisionCompletedByMessage"
            defaultMessage="Provisioning completed by "
          />
          {this.state.dbSubscription?.provisioningCompletedBy +
            (this.state.dbSubscription?.provisioningCompletedOn
              ? ' on ' +
                moment(
                  this.state.dbSubscription?.provisioningCompletedOn
                ).format('DD MMM YYYY')
              : '') +
            '.'}
        </div>
      );
    }
    return message;
  }

  getCompleteProvisioningConfirmationMesssage() {
    if (!this.state.entity) {
      return '';
    }
    return (
      <div>
        <FormattedMessage
          id="SubscriptionManagement.provisionCompletionConfirmationMessage"
          defaultMessage="Are you sure you want to mark the provisioning as complete for "
        />
        {' ' + this.state.entity.name + "'s subscription."}
      </div>
    );
  }

  handleMenuStateChange = newMenuState => {
    let menuNew = cloneDeep(newMenuState);
    this.setState({ menu: menuNew });
  };

  getAPIconfigStatus = (connections, subscription) => {
    let {
      solution: { registration_details: { api_details = [] } = {} } = {}
    } = subscription;
    api_details = api_details.filter(api => {
      return !api.isOptional;
    });
    let solutionAPIs = mapKeys(api_details, function(value) {
      return value.name;
    });

    //Default Pending
    let apiConfigurationStatus =
      ApiConfigStatusForSubscriptionConstants.PENDING_CONFIGURATION;

    if (connections && connections.length) {
      //Every Connection is Inactive, Then Not Started
      apiConfigurationStatus =
        connections.filter(connection => {
          return connection.status === ApiConnectionStatusConstants.INACTIVE;
        }).length === connections.length
          ? ApiConfigStatusForSubscriptionConstants.NOT_STARTED
          : apiConfigurationStatus;

      apiConfigurationStatus =
        connections.filter(connection => {
          return connection.status === ApiConnectionStatusConstants.ACTIVE;
        }).length === connections.length
          ? ApiConfigStatusForSubscriptionConstants.ACTIVE
          : apiConfigurationStatus;

      // connections.map(connection => {
      //   if (solutionAPIs[connection.api]) {
      //     solutionAPIs[connection.api].connectionStatus = connection.status;
      //   }
      // });

      // let isActive = true;
      // map(solutionAPIs, api => {
      //   if (api.connectionStatus !== ApiConnectionStatusConstants.ACTIVE) {
      //     isActive = false;
      //   }
      // });

      // apiConfigurationStatus = isActive
      //   ? ApiConfigStatusForSubscriptionConstants.ACTIVE
      //   : apiConfigurationStatus;
    }
    return apiConfigurationStatus;
  };

  fetchSubscriptionFromDB(solutionId, entityId) {
    this.props.client
      .query({
        query: subscriptionFromDBQuery,
        variables: { entityId: entityId, solutionId: solutionId }
      })
      .then(response => {
        if (
          !response.errors &&
          response.data.solutionSubscriptions &&
          response.data.solutionSubscriptions.length
        ) {
          let apiConfigurationStatus = this.getAPIconfigStatus(
            this.state.platformSubscription.connections,
            response.data.solutionSubscriptions[0]
          );
          this.setState({
            apiConfigurationStatus,
            dbSubscription: response.data.solutionSubscriptions[0]
          });
        }
      })
      .catch(error => {
        this.setState({
          error: 'Problem while fetching subscription from marketplace'
        });
      });
  }
  fetchEntityFromPlatform(entityId) {
    this.props.client
      .query({
        query: entityFromPlatform,
        variables: { id: entityId }
      })
      .then(response => {
        if (!response.errors && response.data.entitiesFromPlatform) {
          this.setState({ entity: response.data.entitiesFromPlatform });
        }
      })
      .catch(error => {
        this.setState({
          error: 'Entity Not found in platform'
        });
      });
  }
  fetchSolutionInfo(solutionId) {
    this.props.client
      .query({
        query: solutionDetails,
        variables: {
          solutionId: solutionId
        }
      })
      .then(response => {
        if (!response.errors && response?.data?.solutionDetails) {
          this.setState({
            solutionDetails: response.data.solutionDetails.splice(-1)[0]
          });
        }
      });
  }

  async fetchAsyncApi(solutionId, entityId) {
    return this.props.client.query({
      query: getAsyncApiData,
      variables: { solutionId: solutionId, entityId: entityId }
    });
  }

  async fetchData(subscriptionId, solutionId) {
    let response = await this.getSubscriptionFromPlatform(subscriptionId);

    this.fetchSolutionInfo(solutionId);

    if (response.errors || !response.data || !response.data.getSubscription) {
      this.setState({ error: 'Subscription Not found in platform' });
      return;
    }
    let allConnections = await this.fetchAsyncApi(
      solutionId,
      response.data.getSubscription.entityId
    );
    let asyncConnections = allConnections.data.getAsyncConnections.filter(
      item => item.apiType === 'async-api'
    );
    asyncConnections.forEach(element => {
      if (element.status === 'disabled') {
        element.status = 'inactive';
      } else if (element.status === 'enabled') {
        element.status = 'active';
      }
    });
    response.data.getSubscription.connections = response.data.getSubscription.connections.concat(
      asyncConnections
    );

    this.fetchSubscriptionFromDB(
      solutionId,
      response.data.getSubscription.entityId
    );
    this.fetchEntityFromPlatform(response.data.getSubscription.entityId);
    this.setState({
      platformSubscription: response.data.getSubscription,
      apiConfigurationStatus:
        ApiConfigStatusForSubscriptionConstants.PENDING_CONFIGURATION
    });
  }

  getSubscriptionOverallStatus() {
    return (
      this.state.dbSubscription?.status ||
      this.state.platformSubscription?.status
    );
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.subscriptionId &&
      this.props.match.params.solutionId
    ) {
      this.setState({ error: null });
      this.fetchData(
        this.props.match.params.subscriptionId,
        this.props.match.params.solutionId
      );
    } else {
      this.setState({ error: 'SubscriptionId or solutionId not found in URL' });
    }
  }

  getSubscriptionFromPlatform = async subscriptionId => {
    return await this.props.client.query({
      query: subscriptionFromPlatformQuery,
      variables: { subscriptionId: subscriptionId }
    });
  };

  openCompleteProvisioningDialog() {
    this.setState({ openProvisioningDialog: true });
  }

  onAcceptCompleteProvisioning(comment) {
    let variables = {
      id: this.state.dbSubscription?.id,
      provisioningCompletedComment: comment,
      provisioningCompletedBy: this.props.auth.userData.name,
      provisioningCompletedByEmail: this.props.auth.userData.email,
      provisioningCompletedOn: moment().toISOString(),
      userProvisioningStatus: ApiConfigStatusForSubscriptionConstants.COMPLETED
    };

    this.setState({ completeProvisioningLoading: true });
    this.props.client
      .mutate({
        mutation: updateCompleteProvisioning,
        variables: variables
      })
      .then(response => {
        if (response.data && response.data.updateCompleteProvisioning) {
          this.setState({
            openProvisioningDialog: false,
            completeProvisioningLoading: false,
            dbSubscription: response.data.updateCompleteProvisioning
          });
        }
      });
  }

  handleToggleChange = expandedMenuId => {
    this.setState({
      menu: Object.assign({}, this.state.menu, { expandedMenuId })
    });
  };

  calculateBillingEndDate() {
    if (!this.state.dbSubscription || !this.state.dbSubscription?.plan) {
      return null;
    }
    let unitMappingwithDaysValues = {
      Day: 1,
      Days: 1,
      Month: 30,
      Week: 8,
      Year: 365
    };
    let plan = this.state.dbSubscription?.plan;
    if (!plan.isTheFreeTrial) {
      return null;
    }
    let validityInDays = parseInt(
      '' +
        parseFloat('' + this.state.dbSubscription?.plan['freeTrialPeriod']) *
          parseFloat(
            unitMappingwithDaysValues[
              this.state.dbSubscription?.plan['freeTrialPeriodType'] ||
                unitMappingwithDaysValues['Year']
            ]
          ),
      10
    );
    return moment().add('days', validityInDays);
  }

  onCancelSubscription = () => {
    this.setState({ openCancelSubscriptionDialog: true });
  };

  getCancelSubscriptionDialog = () => {
    const { client, match, history, location, auth } = this.props;

    const { dbSubscription, entity, platformSubscription } = this.state;

    const { solutionId, entityId, subscriptionId } = match.params;

    return (
      <FortellisDialog
        message={`Are you sure you want to cancel ${
          this.state.solutionDetails.activateOnly === false
            ? 'subscription'
            : 'activation'
        }?`}
        open={this.state.openCancelSubscriptionDialog}
        acceptButtonName={
          <FormattedMessage
            id="CancelSubscription.accept"
            defaultMessage="Yes"
          />
        }
        cancelButtonName={
          <FormattedMessage
            id="CancelSubscription.cancel"
            defaultMessage="No"
          />
        }
        onAccept={async () => {
          const isBulkSubscribed = !Boolean(dbSubscription);

          try {
            this.setState({
              openCancelSubscriptionDialog: false,
              cancellingSubscription: true
            });

            await client.mutate({
              mutation: cancelSubscriptionQuery,
              variables: {
                solutionId,
                entityId,
                subscriptionId,
                isBulkSubscribed
              }
            });

            history.replace({
              pathname: location.pathname.split('/subscriptions')[0]
            });

            const amplitudeData = {
              [AMPLITUDE_EVENTS.CANCEL_ACTIVATION]: {
                [AMPLITUDE_PROPERTIES.ENTITY_ID]: entity?.id,
                [AMPLITUDE_PROPERTIES.ENTITY_NAME]: entity?.name,
                [AMPLITUDE_PROPERTIES.APP_ID]: platformSubscription?.solutionId,
                [AMPLITUDE_PROPERTIES.APP_NAME]:
                  dbSubscription?.solution?.overview.name,
                [AMPLITUDE_PROPERTIES.SUBSCRIPTION_ID]: this?.planSubscription
                  ?.subscriptionId,
                [AMPLITUDE_PROPERTIES.ORG_ID]: auth?.entityId
              }
            };

            sendAmplitudeData(amplitudeData);
          } catch (err) {
            this.setState({
              openCancelSubscriptionDialog: false,
              cancellingSubscription: false,
              showSnackBar: true
            });
          }
        }}
        onCancel={() => {
          this.setState({ openCancelSubscriptionDialog: false });
        }}
        acceptDataCy={'btn_dialog_cancel_Subscription_ok'}
        cancelDataCy={'btn_dialog_cancel_Subscription_cancel'}
      />
    );
  };

  render() {
    if (this.state.error) {
      return (
        <div className="component-content">
          <LayoutGrid className="max-width-resp fm-solution-form-content">
            <LayoutGridCell span={3} className="fm-side-nav">
              <FormattedMessage
                id="Common.InternalServerError"
                defaultMessage="Internal Server Error 500"
              />
            </LayoutGridCell>
          </LayoutGrid>
        </div>
      );
    }
    if (!this.state.dbSubscription && !this.state.platformSubscription) {
      return (
        <div className="component-content">
          <LayoutGrid className="max-width-resp fm-solution-form-content">
            <LayoutGridCell span={12} className="fm-side-nav">
              <Spinner />
            </LayoutGridCell>
          </LayoutGrid>
        </div>
      );
    }

    const {
      solutionDetails: solution,
      dbSubscription,
      platformSubscription
    } = this.state;

    if (
      solution.activateOnly !== false &&
      this.state.menu.contents[0].title === 'Subscription Details'
    ) {
      let newManu = cloneDeep(this.state.menu);
      newManu.contents[0].title = 'Activation Details';
      this.setState({ menu: newManu });
    }

    return (
      <div className="component-content">
        <div className="max-width-resp page-padding-common">
          <LayoutGrid className="fm-solution-form-content">
            <LayoutGridCell span={3} className="fm-side-nav">
              <SideNav
                sideNav={this.state.menu}
                handleToggleChange={this.handleToggleChange}
                handleSideNavStateChange={this.handleMenuStateChange}
              />
            </LayoutGridCell>
            <LayoutGridCell span={9}>
              <LayoutGrid className="fm-form-layout-grid">
                <LayoutGridCell span={12} className="form-box">
                  {this.state.menu.activeMenuId === 'overview' ? (
                    <div>
                      <div className="subscription-management-form-title">
                        <Title className="fm-page-content-title fm-page-solution-submission-title">
                          <FormattedMessage
                            id="SubscriptionManagement.overview"
                            defaultMessage="Overview"
                          />
                        </Title>
                      </div>

                      <div className="subscription-entity">
                        <EntityDetails entity={this.state.entity} />
                      </div>

                      <Card className="subscription-mamagement-card">
                        <CardHeader className="subscription-card-header">
                          <div className="subscription-card-header-content">
                            <div style={{ display: 'inline-flex' }}>
                              <MySubscriptionListItemHeaderLogo
                                image={
                                  solution.overview.solutionLogo || SolutionLogo
                                }
                              />
                            </div>
                            <div className="subscription-page-card-title-div">
                              <MySubscriptionListItemHeaderTitle
                                name={solution.overview.name}
                                dateOfPurchase={
                                  dbSubscription?.subscriptionCreatedOn ||
                                  platformSubscription?.activated
                                }
                                subscriptionId={
                                  dbSubscription?.subscriptionId ||
                                  platformSubscription?.subscriptionId
                                }
                                activateOnly={solution.activateOnly}
                              />
                            </div>
                            {this.getSubscriptionOverallStatus().toLowerCase() ===
                              'active' && (
                              <div className="cancel-subscription-button">
                                <Button
                                  primary
                                  raised
                                  onClick={this.onCancelSubscription}
                                >
                                  {solution.activateOnly === false
                                    ? 'Cancel Subscription'
                                    : 'Cancel Activation'}
                                </Button>
                              </div>
                            )}
                            {this.getCancelSubscriptionDialog()}
                            <div className="pull-right subscription-entity-expansion-panel-header-status">
                              <MySubscriptionStatus
                                status={this.getSubscriptionOverallStatus()}
                              />
                            </div>
                          </div>
                        </CardHeader>
                        <div className="subscription-management-card-body">
                          {this.getSubscriptionOverallStatus().toLowerCase() ===
                          'active' ? (
                            <div>
                              {solution.activateOnly === false &&
                              dbSubscription ? (
                                <SubscriptionManagementBillingDetails
                                  plan={dbSubscription?.plan || null}
                                  billingEndsOn={dbSubscription?.billingEndsOn}
                                  billingStartsOn={
                                    dbSubscription?.billingStartsOn
                                  }
                                  completedByMessage={this.getSolutionProvisioningBody()}
                                  comment={
                                    dbSubscription?.provisioningCompletedComment
                                  }
                                />
                              ) : null}
                            </div>
                          ) : (
                            <div>
                              <SubscriptionManagementCardItem
                                title={
                                  <FormattedMessage
                                    id="SubscriptionManagement.configureApiProvider"
                                    defaultMessage="Configure API Provider"
                                  />
                                }
                                status={this.state.apiConfigurationStatus}
                                body={this.getConfigApiProviderBody()}
                                footer={
                                  <Button
                                    className="mdc-button mdc-button--outlined"
                                    compact={'true'}
                                    type="submit"
                                    onClick={this.showApiConfig.bind(this)}
                                  >
                                    <FormattedMessage
                                      id="SubscriptionManagement.viewApiConfig"
                                      defaultMessage="View API Configuration"
                                    />
                                  </Button>
                                }
                              />
                              <SubscriptionManagementCardItem
                                title={
                                  <FormattedMessage
                                    id="SubscriptionManagement.solutionProvisioning"
                                    defaultMessage="App Provisioning"
                                  />
                                }
                                status={dbSubscription?.userProvisioningStatus}
                                body={this.getSolutionProvisioningBody()}
                                comment={
                                  dbSubscription?.userProvisioningStatus ===
                                  ApiConfigStatusForSubscriptionConstants.COMPLETED
                                    ? dbSubscription?.provisioningCompletedComment
                                    : false
                                }
                                footer={
                                  <Button
                                    className="mdc-button mdc-button--outlined"
                                    compact={'true'}
                                    type="submit"
                                    disabled={
                                      dbSubscription?.userProvisioningStatus ===
                                        ApiConfigStatusForSubscriptionConstants.COMPLETED ||
                                      dbSubscription?.userProvisioningStatus ===
                                        ApiConfigStatusForSubscriptionConstants.NOT_STARTED
                                    }
                                    onClick={this.openCompleteProvisioningDialog.bind(
                                      this
                                    )}
                                  >
                                    <FormattedMessage
                                      id="SubscriptionManagement.markAsComplete"
                                      defaultMessage="Mark As Complete"
                                    />
                                  </Button>
                                }
                              />
                              <CompleteProvisioningDialog
                                bodyMessage={this.getCompleteProvisioningConfirmationMesssage()}
                                isOpen={this.state.openProvisioningDialog}
                                onAccept={this.onAcceptCompleteProvisioning.bind(
                                  this
                                )}
                                onCancel={() => {
                                  this.setState({
                                    openProvisioningDialog: false
                                  });
                                }}
                                loading={this.state.completeProvisioningLoading}
                              />
                            </div>
                          )}
                        </div>
                        {this.getSubscriptionOverallStatus().toLowerCase() !==
                          'active' && (
                          <div>
                            {this.state.subscriptionActivationLoading && (
                              <Spinner className="subscription-management-subscription-active-spinner" />
                            )}
                            {!this.state.subscriptionActivationLoading && (
                              <Button
                                raised
                                primary
                                className="pull-right subscription-management-subscription-active-button"
                                disabled={
                                  !(
                                    this.state.apiConfigurationStatus ===
                                      ApiConfigStatusForSubscriptionConstants.ACTIVE &&
                                    dbSubscription?.userProvisioningStatus ===
                                      ApiConfigStatusForSubscriptionConstants.COMPLETED
                                  )
                                }
                                onClick={this.onSubscriptionActive.bind(this)}
                              >
                                <FormattedMessage
                                  id="SubscriptionManagement.activeSubscription"
                                  defaultMessage="Activate Subscription"
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </Card>
                      {solution.activateOnly === false && dbSubscription ? (
                        <div className="subscription-management-plan-details">
                          <div className="subscription-management-plan-details-header">
                            <FormattedMessage
                              id="SubscriptionManagement.PlanDetails.title"
                              defaultMessage="Plan Details"
                            />
                          </div>
                          <SubscriptionPlanDetails
                            plan={this.state.dbSubscription?.plan || null}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {this.state.menu.activeMenuId === 'api_configuration' ? (
                    <SubscriptionApiConfiguration
                      platformSubscription={this.state.platformSubscription}
                      dbSubscription={this.state.dbSubscription}
                      entity={this.state.entity}
                      subscriptionStatus={this.getSubscriptionOverallStatus()}
                      solution={this.state.solutionDetails}
                    />
                  ) : null}
                </LayoutGridCell>
              </LayoutGrid>
            </LayoutGridCell>
          </LayoutGrid>
        </div>
        <ActionSnackBar
          show={!!this.state.showSnackBar}
          message={`Failed to cancel the  ${
            solution.activateOnly === false ? 'subscription' : 'activation'
          }. Please try again!!`}
          close={() => {
            this.setState({ showSnackBar: false });
          }}
        />
        <FortellisDialog
          open={this.state.cancellingSubscription}
          message={<Spinner />}
          hideFooter={true}
        />
      </div>
    );
  }
}

export default injectIntl(
  withAuth(withApollo(withRouter(SubscriptionManagement)))
);
