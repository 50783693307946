import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogHeader,
  Loader,
  TOAST_POSITIONS,
  TOAST_VISIBILITY_DURATIONS,
  Toast
} from 'cdk-radial';

import { Checkout } from '@cdk-prod/payment-checkout-component';
import { getApolloClient } from '../../../src/containers/GraphQLClient';
import gql from 'graphql-tag';
import PaymentInfoConstants from '../admin-account/solution-moderation/PaymentInfoConstants';
import FortellisConstants from '../common/FortellisConstants';
import {
  COUNTRY_CODE,
  PRODUCT
} from '../common/constants/MonetizationConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';
import { setPreviousRoute } from '../../redux/connectedComponents/verifyAccount/verifyAccount.slice';

const PaymentCheckoutDialog = props => {
  const {
    solution,
    orgName,
    userData,
    orgAddress,
    isPaymentEdit = false,
    actionBtnText,
    savedAddress
  } = props;
  const { orgId, developerId, id, appPublishingFee, stripePriceId } = solution;
  const { name, email, sub } = userData;
  let REACT_APP_STRIPE_KEY;
  let REACT_APP_CDK_ORG_ID;
  if (orgAddress?.countryCode?.toLowerCase() === 'us') {
    REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_PK;
    REACT_APP_CDK_ORG_ID = process.env.REACT_APP_CDK_USA_ORG_ID;
  } else if (orgAddress?.countryCode?.toLowerCase() === 'ca') {
    REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_CA_PK;
    REACT_APP_CDK_ORG_ID = process.env.REACT_APP_CDK_CANADA_ORG_ID;
  }
  const REACT_APP_LISTING_STRIPE_PLAN_ID = stripePriceId;
  const planAmount = parseFloat(appPublishingFee);
  const [address, setAddress] = useState(savedAddress || []);
  const [savedPaymentSetting, setSavedPaymentSetting] = useState([]);
  const [taxAmount, setTaxAmount] = useState(0);
  const [isLoadingToCalculateTax, setIsLoading] = useState(false);
  const [isSubscribeDisable, setIsSubscribeDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorObject, setErrorObject] = useState({});
  const [addressInput, setAddressIput] = useState(
    (savedAddress &&
      ` ${savedAddress?.street},${savedAddress?.address}, ${
        savedAddress?.city
      }, ${savedAddress?.state} ${savedAddress?.zip}, ${
        savedAddress?.country
      }`) ||
      ''
  );
  const [taxDetails, setTaxDetails] = useState({});
  const [taxes, setTaxes] = useState([]);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const history = useHistory();
  const previousRoute = useSelector(
    state => state.verifyAccount.data.previousRoute
  );
  const dispatch = useDispatch();

  const Achblockedmessage = (
    <span>
      Verification failed for this account and is blocked for further use. Add a
      new payment method or reach out to{' '}
      <a href={`mailto:support@fortellis.io`}>Fortellis Support</a>.
    </span>
  );
  const onAddressSelect = async (address, addressInput) => {
    setIsLoading(true);
    setAddress(address);
    setAddressIput(addressInput);
    const respTax = await getSalesTaxRate(
      REACT_APP_CDK_ORG_ID,
      address,
      planAmount,
      1,
      0,
      PRODUCT.LISTING_FEE
    );
    if (respTax) {
      let taxArr = [];
      setTaxDetails(respTax);
      setTaxAmount(respTax.amount_to_collect);
      if (respTax?.jurisdictions?.country?.toLowerCase() === 'us') {
        const rate = parseFloat(respTax.rate) * 100 || 0;
        const amount = parseFloat(respTax.amount_to_collect) || 0;
        let taxObject = {
          type: 'salesTax',
          rate: parseFloat(rate.toFixed(2)),
          amount: parseFloat(amount.toFixed(2))
        };
        taxArr.push(taxObject);
        setTaxes(taxArr);
      } else if (respTax?.jurisdictions?.country?.toLowerCase() === 'ca') {
        const tax_rates = ['gst', 'pst', 'qst'];
        let combinedTaxData = [];
        tax_rates.forEach(item => {
          const rate =
            parseFloat(respTax.breakdown[`${item}_tax_rate`]) * 100 || 0;
          const amount = parseFloat(respTax.breakdown[item]) || 0;
          let taxObject = {
            type: item,
            rate: parseFloat(rate.toFixed(2)),
            amount: parseFloat(amount.toFixed(2))
          };
          combinedTaxData.push(taxObject);
        });
        setTaxes(combinedTaxData);
      }
      setIsLoading(false);
      setIsSubscribeDisable(false);
      setErrorObject({});
    } else {
      setIsLoading(false);
      setIsSubscribeDisable(true);
      setTaxDetails({});
      setTaxAmount(0);
      setErrorObject({
        message:
          'Facing issue while calculating tax. Try again after sometime!',
        visible: true,
        visibilityDuration: 0, //INFINITE
        showDismiss: false
      });
    }
  };
  const onSubscribe = objPayment => {
    setLoading(true);
    if (isPaymentEdit) {
      updatePaymentMethodOfAppFn(objPayment);
    } else {
      createSubscription(objPayment);
    }
  };
  const errorOnClick = () => {
    setErrorObject({ message: '', visible: false });
  };

  const updatePaymentMethodOfAppFn = async objPayment => {
    const client = getApolloClient();
    const createSubsResp = await client.mutate({
      mutation: updatePaymentMethodOfApp,
      variables: {
        checkoutDetails: JSON.stringify({
          ...objPayment
        }),
        paymentSetting: JSON.stringify(savedPaymentSetting),
        address: address,
        checkoutType: objPayment.checkoutType,
        rShare: 100,
        taxDetails: taxDetails,
        solutionId: id,
        selectedPlanId: REACT_APP_LISTING_STRIPE_PLAN_ID,
        userEmail: email.toLowerCase(),
        userId: sub,
        userName: name,
        appName: solution.overview.name,
        publisherName: solution.overview.publisherName,
        PublisherEmail: developerId,
        orgId: orgId,
        orgName: orgName,
        productType: PRODUCT.LISTING_FEE,
        couponId: ''
      }
    });
    const response = createSubsResp.data.updatePaymentMethodOfApp;
    if (response) {
      const { status, message } = response;
      if (
        status === '500' &&
        response.message === PaymentInfoConstants.BANK_VERIFICATION_FAILED
      ) {
        setVerificationFailed(true);
        setLoading(false);
        return;
      } else if (status !== '200') {
        setErrorObject({
          message: message,
          visible: true
        });
        setLoading(false);
        return;
      }

      props.onClose({
        status: status,
        message: message
      });
    }
    setLoading(false);
  };
  const createSubscription = async objPayment => {
    const client = getApolloClient();
    const createSubsResp = await client.mutate({
      mutation: createSubscriptionInStripe,
      variables: {
        checkoutDetails: JSON.stringify({
          ...objPayment
        }),
        paymentSetting: JSON.stringify(savedPaymentSetting),
        address: address,
        checkoutType: objPayment.checkoutType,
        rShare: 100,
        taxDetails: taxDetails,
        solutionId: id,
        selectedPlanId: REACT_APP_LISTING_STRIPE_PLAN_ID,
        userEmail: email.toLowerCase(),
        userId: sub,
        userName: name,
        appName: solution.overview.name,
        publisherName: solution.overview.publisherName,
        PublisherEmail: developerId,
        orgId: orgId,
        orgName: orgName,
        productType: PRODUCT.LISTING_FEE,
        couponId: ''
      }
    });
    const response = createSubsResp.data.createSubscriptionInStripe;
    if (response) {
      const {
        status,
        verified,
        message,
        subscriptionStatus,
        paymentMethod,
        last4,
        invoiceURL
      } = response;
      if (paymentMethod === PaymentInfoConstants.CARD) {
        if (message !== '' && message !== null) {
          setErrorObject({
            message: message,
            visible: true
          });
        } else if (subscriptionStatus === PaymentInfoConstants.ACTIVE) {
          props.onClose({
            status: status,
            achStatus: verified,
            message: message,
            subscriptionStatus: subscriptionStatus,
            paymentMethod: paymentMethod,
            last4,
            invoiceURL
          });
        }
      } else if (paymentMethod === PaymentInfoConstants.BANKACH) {
        if (status === '500' && verified) {
          setErrorObject({
            message: message ? message : 'Unable to initiate the subscription!',
            visible: true
          });
          setLoading(false);
          return;
        }
        if (
          status === '500' &&
          response.message === PaymentInfoConstants.BANK_VERIFICATION_FAILED
        ) {
          setVerificationFailed(true);
          setLoading(false);
          return;
        }
        if (
          status === '200' &&
          verified &&
          subscriptionStatus === PaymentInfoConstants.INCOMPLETE
        ) {
          setErrorObject({
            message: message ? message : PaymentInfoConstants.ANOTHERPAYOPTION,
            visible: true
          });
          setLoading(false);
          return;
        }
        props.onClose({
          status: status,
          achStatus: verified,
          message: message,
          subscriptionStatus: subscriptionStatus,
          paymentMethod: paymentMethod,
          last4: last4,
          invoiceURL: invoiceURL
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPaymentSetting(email, orgAddress?.countryCode);
  }, []);

  useEffect(() => {
    if (savedAddress) {
      setAddress(savedAddress);
      setAddressIput(
        `${savedAddress?.address}, ${savedAddress?.street}, ${
          savedAddress?.city
        }, ${savedAddress?.state} ${savedAddress?.zip}, ${
          savedAddress?.country
        }`
      );
      onAddressSelect(address, addressInput);
    }
  }, [isPaymentEdit, savedAddress]);
  const getPaymentSetting = async (emailId, countryCode) => {
    const client = getApolloClient();
    let response = await client.query({
      query: getPaymentSettingData,
      variables: {
        emailId: emailId,
        countryCode: countryCode
      },
      fetchPolicy: 'no-cache'
    });
    setSavedPaymentSetting(response.data.getPaymentSettingData);
  };

  const getSalesTaxRate = async (
    orgId,
    address,
    unitAmount,
    qty,
    discount,
    productType
  ) => {
    const client = getApolloClient();
    let response = await client.query({
      query: getSalesTax,
      variables: {
        remittanceOrgId: orgId,
        objFromAddress: JSON.stringify(address),
        unitAmount: unitAmount,
        qty: qty,
        discount: discount,
        productType
      }
    });
    return response.data.getSalesTax;
  };

  return (
    <div>
      <Dialog
        id="dialog-payment"
        hideHeader
        isOpen={true}
        isFullScreen={true}
        title={'Payment Checkout'}
        hideCloseButton={false}
      >
        <DialogHeader
          closeLabel="Close"
          hideCloseButton={false}
          id="header-id"
          onClose={() => {
            props.onClose({ status: PaymentInfoConstants.DECLINED });
            if (
              previousRoute ===
              FortellisConstants.MARKETPLACE_MYSOLUTIONS_LIST_ROUTE
            ) {
              history.push(
                FortellisConstants.MARKETPLACE_MYSOLUTIONS_LIST_ROUTE
              );
              dispatch(setPreviousRoute(''));
            }
          }}
          title="Payment Checkout"
          titleId="title-id"
        />
        {loading ? (
          <div className="payment-checkout-dialog-spinner">
            <Loader
              data-testid="loader"
              hideLabel={false}
              label="Processing..."
              spinnerClassName=""
            />
          </div>
        ) : (
          <div className={'consent-dialog__dialogBody'}>
            {verificationFailed && (
              <Toast
                id="paymentCheckoutToast"
                data-testid="toast-id"
                content={Achblockedmessage}
                position={TOAST_POSITIONS.FIXED}
                style={{
                  marginTop: '32px',
                  zIndex: 6,
                  pointerEvents: 'all',
                  maxWidth: '38.25rem'
                }}
                variant="negative"
                visibilityDuration={TOAST_VISIBILITY_DURATIONS.INFINITE}
                onVisibilityDurationEnd={() => {
                  errorOnClick();
                }}
                actions={[
                  {
                    onClick: () => {
                      setVerificationFailed(false);
                    },
                    text: 'Dismiss'
                  }
                ]}
              />
            )}
            <Checkout
              onAddressSelect={onAddressSelect}
              onSubscribe={onSubscribe}
              isSubscribeDisable={isSubscribeDisable}
              address={address}
              addressInput={addressInput}
              summaryprops={{
                isFailedToCalculateTax: false,
                isLoadingToCalculateTax: isLoadingToCalculateTax,
                totalAmount: parseFloat(planAmount) + parseFloat(taxAmount),
                appName: solution.overview.name,
                publisherName: solution.overview.publisherName,
                plan: {
                  planAmount: planAmount,
                  planFrequency: 'month',
                  planId: 'Plan_1234'
                }
              }}
              taxes={taxes}
              savedcardprops={{ data: savedPaymentSetting }}
              react_app_stripe_key={REACT_APP_STRIPE_KEY}
              error={errorObject}
              errorOnClick={errorOnClick}
              isLoading={loading}
              countryCode={orgAddress?.countryCode || COUNTRY_CODE.USA}
              product={PRODUCT.LISTING_FEE}
              actionBtnText={actionBtnText}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
};
export const getSalesTax = gql`
  query(
    $remittanceOrgId: String
    $objFromAddress: String
    $unitAmount: String
    $qty: String
    $discount: String
    $productType: String
  ) {
    getSalesTax(
      remittanceOrgId: $remittanceOrgId
      objFromAddress: $objFromAddress
      unitAmount: $unitAmount
      qty: $qty
      discount: $discount
      productType: $productType
    ) {
      amount_to_collect
      order_total_amount
      rate
      breakdown {
        taxable_amount
        tax_collectable
        gst
        gst_tax_rate
        pst
        pst_tax_rate
        qst
        qst_tax_rate
        hst
        hst_tax_rate
      }
      jurisdictions {
        country
      }
    }
  }
`;
export const getPaymentSettingData = gql`
  query($emailId: String, $countryCode: String) {
    getPaymentSettingData(emailId: $emailId, countryCode: $countryCode) {
      customer_login_email
      payment_type
      customer_reference
      payment_type_reference
      status
      last4
      country
    }
  }
`;
const createSubscriptionInStripe = gql`
  mutation(
    $checkoutDetails: String
    $paymentSetting: String
    $address: address
    $checkoutType: String
    $rShare: String
    $tax: taxInfo
    $solutionId: String
    $selectedPlanId: String
    $userId: String
    $userName: String
    $userEmail: String
    $appName: String
    $publisherName: String
    $publisherEmail: String
    $orgId: String
    $orgName: String
    $taxDetails: TaxDetailsObj
    $productType: String
    $couponId: String
  ) {
    createSubscriptionInStripe(
      command: {
        checkoutDetails: $checkoutDetails
        paymentSetting: $paymentSetting
        address: $address
        checkoutType: $checkoutType
        rShare: $rShare
        tax: $tax
        solutionId: $solutionId
        selectedPlanId: $selectedPlanId
        userId: $userId
        userName: $userName
        userEmail: $userEmail
        appName: $appName
        publisherName: $publisherName
        publisherEmail: $publisherEmail
        orgId: $orgId
        orgName: $orgName
        taxDetails: $taxDetails
        productType: $productType
        couponId: $couponId
      }
    ) {
      message
      status
      verified
      subscriptionStatus
      paymentMethod
      last4
      invoiceURL
    }
  }
`;
const updatePaymentMethodOfApp = gql`
  mutation(
    $checkoutDetails: String
    $paymentSetting: String
    $address: address
    $checkoutType: String
    $solutionId: String
    $userId: String
    $userName: String
    $userEmail: String
    $appName: String
    $orgId: String
    $orgName: String
    $taxDetails: TaxDetailsObj
    $productType: String
  ) {
    updatePaymentMethodOfApp(
      command: {
        checkoutDetails: $checkoutDetails
        paymentSetting: $paymentSetting
        address: $address
        checkoutType: $checkoutType
        solutionId: $solutionId
        userId: $userId
        userName: $userName
        userEmail: $userEmail
        appName: $appName
        orgId: $orgId
        orgName: $orgName
        taxDetails: $taxDetails
        productType: $productType
      }
    ) {
      message
      status
    }
  }
`;
export default PaymentCheckoutDialog;
