import React from "react";

const ImageContainer169 = ({ src, alt, width, className }) => {
  return (
    <div
      style={{ width: width ? width : "inherit" }}
      className={className ? className : ""}
    >
      <div className="image-wrapper-16_9">
        <img src={src} alt={alt ? alt : ""} />
      </div>
    </div>
  );
};

export default ImageContainer169;
