import React from 'react';
import { injectIntl } from 'react-intl';
import { CardSubtitle, CardTitle } from '@cdk-uip/react-card';
import moment from 'moment';

class MySubscriptionListItemHeaderTitle extends React.Component {
  render() {
    const { name, dateOfPurchase, subscriptionId, activateOnly } = this.props;
    return (
      <div>
        <CardTitle className="subscription-card-title">{name}</CardTitle>
        <CardSubtitle className="subscription-card-sub-title">
          {`${activateOnly === false ? 'Subscribed' : 'Activated'} on ${
            !dateOfPurchase
              ? 'NA'
              : moment(dateOfPurchase).format('DD MMM YYYY')
          }` + (subscriptionId ? ` | Subscription Id: ${subscriptionId}` : '')}
        </CardSubtitle>
      </div>
    );
  }
}

export default injectIntl(MySubscriptionListItemHeaderTitle);
