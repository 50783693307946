import PropTypes from "prop-types";

export const commandShape = PropTypes.shape({
  execute: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.any,
  result: PropTypes.any
});

export const apiShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string
});

export const providerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string
});

export const storeShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string
});

export const providerSubscriptionShape = PropTypes.shape({
  api: apiShape.isRequired,
  provider: providerShape.isRequired
});

export const subscriptionsShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organization: storeShape.isRequired,
  status: PropTypes.string.isRequired
});

export const solutionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.string,
  details: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  requires: PropTypes.arrayOf(apiShape)
});

export const featuresShape = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string
});

export const supportShape = PropTypes.shape({
  tutorials_and_documentation: PropTypes.array,
  supportMessage: PropTypes.string,
  supportCenterUrl: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  supportCommunityUrl: PropTypes.string
});

export const resourcesShape = PropTypes.shape({
  documentName: PropTypes.string,
  documnetLink: PropTypes.string
});

export const reviewShape = PropTypes.shape({
  id: PropTypes.string,
  authorName: PropTypes.string,
  createdDate: PropTypes.string,
  rating: PropTypes.number,
  solutionId: PropTypes.string,
  solutionName: PropTypes.string,
  status: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  updatedDate: PropTypes.string
});
