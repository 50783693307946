import React, { Component } from 'react';
import axios from 'axios';
import { TextEditor } from '@cdk-prod/fortellis-text-editor';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from '@cdk-uip/react-button';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@cdk-uip/react-dialog';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { environmentURLs } from '../common/environment/CaptureEnvironment';

const DOCUMENTS_SERVICE_BASE_URL = environmentURLs.consentServiceBaseUrl;
const ACCOUNTS_SERVER_BASE_URL = environmentURLs.accountsServerURL;

class TermsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTerms: false,
      latestTncText: '',
      termsInfoFetched: false
    };
  }

  async componentDidUpdate(prevProps) {
    const { termsInfoFetched } = this.state;

    if (
      !termsInfoFetched &&
      this.props.auth.isAuthenticated &&
      prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated
    ) {
      const latestTnc = await this.getLatestTncVersion();
      this.setState(latestTnc);
      const userAcceptedTncVersion = await this.getUserAcceptedTncVersion();
      this.setState({ termsInfoFetched: true });
      if (
        Math.floor(userAcceptedTncVersion) <
        Math.floor(latestTnc.latestTncVersion)
      ) {
        this.setState({ showTerms: true });
      }
    }
  }
  render() {
    const { showTerms, latestTncText, createdOn } = this.state;

    if (!this.props.auth.isAuthenticated || !showTerms || !latestTncText) {
      return null;
    }

    let termsContent;
    try {
      termsContent = JSON.parse(latestTncText);
    } catch (err) {
      console.error('Error parsing terms text. ', termsContent);
    }
    return (
      <Dialog open={showTerms} className="signin-tnc-dialog">
        <DialogHeader>
          <div className="signin-tnc-dialog-header-title">
            Terms and Conditions
          </div>
        </DialogHeader>
        <DialogBody scrollable>
          <div className="tnc-updated-on">{`Updated: ${moment(createdOn).format(
            'MMMM DD, YYYY'
          )}`}</div>
          {termsContent && (
            <TextEditor readOnly={true} rawEditorContentState={termsContent} />
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            className="dialog-button"
            onClick={this.updateTermsAcceptance}
          >
            Accept
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  getLatestTncVersion = async () => {
    let tncInfo;
    await axios({
      method: 'GET',
      url: `${DOCUMENTS_SERVICE_BASE_URL}/documents/fortellis/tnc/latest`
    })
      .then(result => {
        const response = result.data;
        tncInfo = {
          latestTncVersion: response.version,
          latestTncText: response.content,
          createdOn: response.created_on
        };
      })
      .catch(() => {
        console.error('failed to get tnc version');
      });
    return tncInfo;
  };

  getUserAcceptedTncVersion = async () => {
    let userAcceptedTncVersion;

    await axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.auth.accessToken}`
      },
      url: `${ACCOUNTS_SERVER_BASE_URL}/api/v1/users/profile/${
        this.props.auth.userData.sub
      }`
    })
      .then(result => {
        userAcceptedTncVersion = result.data.accepted_tnc_version;
      })
      .catch(() => {
        console.error('failed to fetch accepted tnc version');
      });
    return userAcceptedTncVersion;
  };

  updateTermsAcceptance = async () => {
    const { latestTncVersion } = this.state;
    axios({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.props.auth.accessToken}`,
        version: latestTncVersion
      },
      url: `${ACCOUNTS_SERVER_BASE_URL}/api/v2/user/tnc-version`,
      data: {}
    }).catch(() => {
      console.error('failed to submit user acceptance');
    });

    this.setState({
      showTerms: false
    });
  };
}

TermsDialog.propTypes = {
  auth: PropTypes.object
};

export default withAuth(TermsDialog);
