import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { Footer } from '@fortellis/footer';
import { environmentURLs } from '../environment/CaptureEnvironment';
import config from './config';
import './css/footer.scss';

function AuthFooter({ auth, mobile }) {
  return (
    <Footer
      mobile={mobile}
      authenticated={auth.isAuthenticated}
      items={config.footer}
      routerLink={Link}
      signup={environmentURLs.accountsURL}
    />
  );
}

AuthFooter.propTypes = {
  auth: PropTypes.object.isRequired,
  mobile: PropTypes.bool
};

AuthFooter.defaultProps = {
  mobile: false
};

export default withRouter(withAuth(AuthFooter));
