import React, { useState, Fragment, useEffect } from 'react';
import { ChipSet, Chip, ChipText, ChipDeleteIcon } from '@cdk-uip/react-chips';
import gql from 'graphql-tag';
import { getApolloClient } from '../../../../containers/GraphQLClient';
import SolutionStatus from '../../../common/SolutionStatus';
import Spinner from '../../../common/Spinner';

import { PersistentSearchBox } from '@cdk-uip/react-persistent-search-box';

const SearchBoxWithChipSet = props => {
  const [loadingWorksWithValues, setLoadingWorksWithValues] = useState(false);
  const [search, setSearch] = useState('');
  const [chipsArray, setChipsArray] = useState([]);
  const [worksWithMapObjNameToId, setWorksWithMapObjNameToId] = useState({});
  const [worksWithMapObjIdToName, setWorksWithMapObjIdToName] = useState({});
  const [options, setOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const { formStaticValues, formFieldValues, fieldProperties, value } = props;

  useEffect(() => {
    getWorksWithValues();
  }, []);

  useEffect(() => {
    if (formFieldValues && formFieldValues.length) {
      setChipsArray(
        formFieldValues.map(worksWithId => worksWithMapObjIdToName[worksWithId])
      );
    }
  }, [worksWithMapObjNameToId]);

  useEffect(() => {
    if (!chipsArray.includes(undefined)) {
      updateChipsArrayInFormFieldValues();
    }
  }, [chipsArray]);

  const handleChange = e => {
    setOptions(
      filterOptions.filter(filterOption =>
        filterOption.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setSearch(e.target.value);
  };

  const handleClick = search => {
    setSearch('');
    if (!chipsArray.includes(search)) {
      setChipsArray([...chipsArray, search]);
    }
    setOptions(filterOptions);
  };

  const handleClear = () => {
    setSearch('');
    setOptions(filterOptions);
  };

  const updateChipsArrayInFormFieldValues = () => {
    chipsArray.map(chipValue => {
      if (!formFieldValues.includes(worksWithMapObjNameToId[chipValue])) {
        formFieldValues.push(worksWithMapObjNameToId[chipValue]);
      }
    });

    props.onChange(formFieldValues);
  };

  const getWorksWithValues = async () => {
    setLoadingWorksWithValues(true);
    try {
      const client = getApolloClient();
      let response = await client.query({
        query: getWorksWithValuesQuery
      });

      if (response.data && response.data.getWorksWithDataValues) {
        setOptions(
          response.data.getWorksWithDataValues.map(
            worksWithObj => worksWithObj.friendlyName
          )
        );
        setFilterOptions(
          response.data.getWorksWithDataValues.map(
            worksWithObj => worksWithObj.friendlyName
          )
        );
        let mapObjNameToId = {};
        let mapObjIdToName = {};
        response.data.getWorksWithDataValues.map(worksWithObj => {
          mapObjIdToName[worksWithObj['id']] = worksWithObj['friendlyName'];
          mapObjNameToId[worksWithObj['friendlyName']] = worksWithObj['id'];
        });
        setWorksWithMapObjIdToName(mapObjIdToName);
        setWorksWithMapObjNameToId(mapObjNameToId);
      }
      setLoadingWorksWithValues(false);
    } catch (error) {
      console.log('Failed to fetch works with values');
      setLoadingWorksWithValues(false);
    }
  };

  const onChipDelete = async (index, chipValue) => {
    setChipsArray(chipsArray.filter((chip, i) => i !== index));
    formFieldValues.splice(0, formFieldValues.length);
  };

  return (
    <Fragment>
      <div
        style={{ position: 'relative' }}
        className="persistent-search-box-div"
      >
        {loadingWorksWithValues ? (
          <Spinner />
        ) : (
          <PersistentSearchBox
            label=""
            value={search}
            results={options}
            onChange={e => {
              handleChange(e);
            }}
            onClick={search => {
              handleClick(search);
            }}
            onClear={() => {
              handleClear();
            }}
          />
        )}
      </div>

      <div
        style={{
          marginTop: '10px'
        }}
      >
        {chipsArray.length ? (
          !chipsArray.includes(undefined) ? (
            <ChipSet className="form-input-chipset">
              {chipsArray.map((chipText, index) => (
                <Chip key={index}>
                  <ChipText>{chipText}</ChipText>
                  {/*<ChipDeleteIcon*/}

                  <span
                    className="material-icons"
                    style={{ marginLeft: '5px', color: 'gray' }}
                    onClick={() => {
                      onChipDelete(index, chipText);
                    }}
                  >
                    cancel
                  </span>
                </Chip>
              ))}
            </ChipSet>
          ) : (
            formStaticValues.status !== SolutionStatus.IN_REVIEW && <Spinner />
          )
        ) : (
          'No Integrations value selected'
        )}
      </div>
    </Fragment>
  );
};

const getWorksWithValuesQuery = gql`
  query {
    getWorksWithDataValues {
      friendlyName
      id
    }
  }
`;
export default SearchBoxWithChipSet;
