import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogFooterButton
} from '@cdk-uip/react-dialog';

class SubscribedPlanTerms extends React.Component {
  render() {
    const { solution } = this.props;
    return (
      <Dialog open={this.props.open} className="plan-terms-dialog">
        <DialogHeader>
          <span className="plan-terms-dialog-header">
            <FormattedMessage
              id="MarketplaceAccount.subscriptions.termsAndConditions"
              defaultMessage="Terms and Conditions"
            />
          </span>
        </DialogHeader>
        <DialogBody scrollable className="plan-terms-dialog-body-container">
          <div className="tnc-pdf-container">
            <embed
              title="plan-terms-content"
              src={solution.overview.termsAndConditionsUrl}
              className="plan-terms-content-iframe"
            />
          </div>
        </DialogBody>
        <DialogFooter>
          <DialogFooterButton
            onClick={this.props.onClose}
            id="subscribed-plan-terms-dialog-footer-button"
          >
            <FormattedMessage
              id="MarketplaceAccount.closeButton"
              defaultMessage="Close"
            />
          </DialogFooterButton>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default injectIntl(SubscribedPlanTerms);
