import React, { useState } from 'react';
import { Accordion, PanelContent, PanelHeader } from 'cdk-radial';
import CheckboxGroup from './CheckboxGroup';
import ShowButtonToggle from './ShowButtonToggle';
import {
  MINIMUM_FILTERS_TO_SHOW,
  DISCOVER_BY_SECTION_TITLE
} from './constants';
import './FilterGroup.scss';

const FilterGroup = ({
  filterSections,
  openPanels,
  onPanelToggle,
  onFilterSelect,
  checkedFilters,
  showPrivateFilter,
  ...others
}) => {
  const [itemsToShowPerSection, setItemsToShowPerSection] = useState({});
  const [
    isShowMoreSelectedPerSection,
    setIsShowMoreSelectedPerSection
  ] = useState({});

  const handleShowToggle = (filterArrayLength, sectionTitle) => {
    if (
      isShowMoreSelectedPerSection[sectionTitle] !== undefined &&
      isShowMoreSelectedPerSection[sectionTitle]
    ) {
      setItemsToShowPerSection({
        ...itemsToShowPerSection,
        [sectionTitle]: MINIMUM_FILTERS_TO_SHOW
      });
    } else {
      setItemsToShowPerSection({
        ...itemsToShowPerSection,
        [sectionTitle]: filterArrayLength
      });
    }
    setIsShowMoreSelectedPerSection({
      ...isShowMoreSelectedPerSection,
      [sectionTitle]: !isShowMoreSelectedPerSection[sectionTitle]
    });
  };

  const resetShowMoreForASection = sectionTitle => {
    setIsShowMoreSelectedPerSection(prev_isShowMoreSelectedPerSection => {
      delete prev_isShowMoreSelectedPerSection[sectionTitle];
      return { ...prev_isShowMoreSelectedPerSection };
    });
    setItemsToShowPerSection({
      ...itemsToShowPerSection,
      [sectionTitle]: MINIMUM_FILTERS_TO_SHOW
    });
  };

  const NewFilterGroup = () => {
    return (
      <Accordion
        items={Object.values(filterSections).map((section, index) => {
          const content = (
            <>
              <CheckboxGroup
                itemsToShow={
                  itemsToShowPerSection[section.sectionTitle]
                    ? itemsToShowPerSection[section.sectionTitle]
                    : MINIMUM_FILTERS_TO_SHOW
                }
                onFilterSelect={onFilterSelect}
                checkedFilters={checkedFilters}
                filterArray={section.filterArray}
                sectionTitle={section.sectionTitle}
              />
              {section.filterArray.length > MINIMUM_FILTERS_TO_SHOW ? (
                <ShowButtonToggle
                  filterArrayLength={section.filterArray.length}
                  sectionTitle={section.sectionTitle}
                  handleShowToggle={handleShowToggle}
                  isShowMoreSelectedPerSection={isShowMoreSelectedPerSection}
                />
              ) : (
                false
              )}
            </>
          );
          return {
            content: content,
            id: `${section.sectionTitle}`,
            title: `${section.sectionTitle}`
          };
        })}
        onPanelToggle={sectionTitle => {
          onPanelToggle(sectionTitle);
          resetShowMoreForASection(sectionTitle);
        }}
        className="new-expansion-panel"
        showDivider
        openPanels={openPanels}
        panelContentComponent={PanelContent}
        panelHeaderComponent={PanelHeader}
        panelContentClassName="panel-content"
      />
    );
  };

  return <NewFilterGroup />;
};

export default FilterGroup;
