import React from 'react';
import Spinner from '../../common/Spinner';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from '@cdk-uip/react-form';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import { Button } from '@cdk-uip/react-button';
import FortellisDialog from '../../common/FortellisDialog';
import ActionSnackBar from '../../solution-submission/ActionSnackBar';
import { commandShape, solutionShape } from '../../solution-submission/shapes';
import PropTypes from 'prop-types';
import MenuDataConfig from './menu-config';
import { FormConfig } from './solution_review_form_config';
import { FormUtils } from '../../solution-submission/common-form-api/utils/FormUtils';
import { SideNavUtils } from '../../solution-submission/common-sidenav-api/utils/SideNavUtils';
import * as SolutionFormData from '../../solution-submission/solution_data_object';
import SideNav from '../../solution-submission/common-sidenav-api/SideNavComponent/SideNav';
import FormAPI from '../../solution-submission/common-form-api/FormAPI';
import moment from 'moment/moment';
import SolutionStatus from '../../common/SolutionStatus';
import PreviewSolution from '../../../containers/PreviewSolutionContainer';
import { Tooltip } from '@cdk-uip/react-tooltip';
import cloneDeep from 'lodash/cloneDeep';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import axios from 'axios';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { getApolloClient } from '../../../containers/GraphQLClient';
import * as SubscriptionManagement from './subscription_management_config';
import * as DataRightsConsent from './data_rights_config';
import SolutionOperations from '../../../containers/utils/SolutionOperations';
import * as ContractNotificationConfig from './contract_notification_config';
import * as PaymentOptionsConfig from './payment_options_config';
import * as authorized_signon_config from './authorized_signon_config';
import * as authorized_signon_config_static from './authorized_signon_config_static';
import * as PaymentOptionsStaticConfig from './payment_options_config_static';
import * as AdminControlAdditionalInfoConfig from './admin_control_additional_info_config';
import * as CustomConsentConfig from './custom_consent_config';
import * as FeaturedAppConfig from './featured_app_config';
import PaymentInfoConstants from './PaymentInfoConstants';
import AdditionalInfoConstants from './AdminControlAdditionalDetailsConstants';
import { connect } from 'react-redux';
import { getStripeSubscriptionInfo } from '../../../redux/connectedComponents/stripeSubscriptionInfo/stripeSubscriptionInfo.slice';
import listingFeeConstants from './AppListingFeeConstants';
import { COUNTRY_CODE } from '../../common/constants/MonetizationConstants';
const CONSTANTS = {
  SUBMITTED: 'Submitted',
  IN_REVIEW: 'In Review',
  FORTELLIS_APP: 'fortellis',
  SUBSCRIPTION_MANAGEMENT: 'subscription_management',
  DATA_RIGHTS: 'data_rights',
  ADMIN_CONTROLS: 'Admin Controls',
  // SUBSCRIPTION_MANAGEMENT_FORM_TITLE: 'Subscription Management',
  SUBSCRIPTION_MANAGEMENT_FORM_TITLE: 'Subscription Enablement',
  DATA_RIGHTS_FORM_TITLE: 'Data Rights Enablement',
  CONTRACT_NOTIFICATIONS: 'contract_notifications',
  CONTRACT_NOTIFICATIONS_FORM_TITLE: 'Contract Notifications',
  AUTH_SIGNON: 'un_authorized_signon',
  PAYMENT_INFO: 'payment_info',
  PAYMENT_INFO_FROM_TITLE: 'Payment Methods',
  ADDITIONAL_INFO: 'additional_info',
  // ADDITIONAL_INFO_FORM_TITLE: 'Additional Info for the App',
  ADDITIONAL_INFO_FORM_TITLE: 'Catalog & Vision Setup',
  VISION_CASE_QUEUE_DEFAULT_VALUE:
    AdditionalInfoConstants.VISION_CASE_QUEUE_DEFAULT_VALUE,
  UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE:
    AdditionalInfoConstants.UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE,
  CUSTOM_CONSENT: 'custom_consent',
  // DEALER_CONSENT_FORM: 'Dealer Consent Form'
  DEALER_CONSENT_FORM: 'Dealer Consent Form',
  DEALER_CONSENT_SNACKBAR: 'Please Select APIs',
  PAYMENT_INFO_SNACKBAR: 'Please Select Payment Method',
  WORKS_WITH_IDS: 'worksWithIds',
  FEATURED_APP: 'featured_app',
  FEATURED_APP_FORM_TITLE: 'Featured App',
  APP_PUBLISHING_FEE: 'appPublishingFee',
  APP_PUBLISHING_FEE_SNACKBAR: 'Enter valid amount',
  APP_LISTING_FEE_TITLE: 'Marketplace Listing Fee'
};

class SolutionReviewForm extends React.Component {
  static propTypes = {
    solution: PropTypes.arrayOf(solutionShape),
    loading: PropTypes.bool,
    submit: commandShape
  };
  state = {
    openDialogForApprove: false,
    openDialogForWithdraw: false,
    openDialogForReject: false,
    openDialogForRevoke: false,
    openTryAgainDialog: false,
    menu: MenuDataConfig.MenuData,
    openPreviewDialog: false,
    initialize: false,
    submitted: false,
    reload: false,
    startingReview: false,
    startedReview: false,
    saving: false,
    developerId: '',
    developerName: '',
    solutionName: '',
    dateFormat: 'DD MMM YYYY hh:mm A',
    snackBar: false,
    snackBarMessage: '',
    comment: {
      userId: '',
      commentDate: '',
      commentText: '',
      action: ''
    },
    formStaticValues: {
      id: '',
      apigee_id: '',
      developerId: '',
      orgId: '',
      developerName: '',
      status: '',
      comments: [],
      developerComment: '',
      submittedOn: null,
      lastUpdatedBy: null,
      lastUpdatedOn: null,
      solutionType: ''
    },
    formValueFinal: {},
    formFields: {
      status: '',
      comments: [],
      id: '',
      reviewersComments: ''
    },
    emailFields: {
      emailTemplate: '',
      emailSubject: ''
    }
  };

  validateFields() {
    const fields = [];

    //adding conditional validation for app listing fee
    let appListingFeeField = this.validateAppListingFeeField();
    appListingFeeField.length && fields.push(...appListingFeeField);

    try {
      var {
        subscription_management: {
          subscription_management: { allowSubscriptions }
        },
        data_rights: {
          data_rights: { captureDataRightsConsent }
        }
      } = this.state.formValueFinal;
    } catch (e) {}
    //check if subscription management is selected
    let isSubscriptionManagementSettingSelected =
      typeof allowSubscriptions === 'boolean' ? true : false;

    //check if data rights enablement is selected
    let isAdminRightsSettingSelected =
      typeof captureDataRightsConsent === 'boolean' ? true : false;

    !isAdminRightsSettingSelected && fields.push(CONSTANTS.DATA_RIGHTS);
    !isSubscriptionManagementSettingSelected &&
      fields.push(CONSTANTS.SUBSCRIPTION_MANAGEMENT);

    //adding validation on the contract notification field.
    let contractNotificationFields = this.validateFieldOfContractNotifications();
    contractNotificationFields.length &&
      fields.push(...contractNotificationFields);

    //adding validation on the paymeny info control.
    let paymentInfoControlFields = this.validateFieldOfPaymentInfoControl();
    paymentInfoControlFields.length && fields.push(...paymentInfoControlFields);

    const authSignFields = this.validateAuthSignON();
    authSignFields.length && fields.push(...authSignFields);

    //adding conditional validation for the
    let additionalInfoControlFields = this.validateFieldOfAdditionalInfoControl();
    additionalInfoControlFields.length &&
      fields.push(...additionalInfoControlFields);

    //adding conditional validation for custom dealer consent
    let customConsentControlFields = this.validateFieldOfCustomConsent();
    customConsentControlFields.length &&
      fields.push(...customConsentControlFields);

    return fields;
  }

  validateFieldOfAdditionalInfoControl() {
    try {
      var {
        additional_info: {
          additional_info: {
            catalog_number,
            is_vision_case_required,
            vision_case_queue,
            uninstall_queue
          }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    const fields = [];
    if (is_vision_case_required && vision_case_queue.length === 0) {
      fields.push(CONSTANTS.ADDITIONAL_INFO);
    }
    if (is_vision_case_required && uninstall_queue.length === 0) {
      fields.push(CONSTANTS.ADDITIONAL_INFO);
    }
    return fields;
  }

  validateFieldOfCustomConsent() {
    try {
      var {
        custom_consent: {
          custom_consent: { customConsentForm, fileName, selectedApis }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    const fields = [];
    if (customConsentForm && fileName) {
      if (selectedApis && !selectedApis.length) {
        fields.push(CONSTANTS.CUSTOM_CONSENT);
        this.setState({
          snackBar: {
            message: () => {
              return CONSTANTS.DEALER_CONSENT_SNACKBAR;
            }
          }
        });
      }
    } else if (customConsentForm && fileName && selectedApis) {
      if (!customConsentForm.includes('.pdf')) {
        fields.push(CONSTANTS.CUSTOM_CONSENT);
      }
    }
    return fields;
  }

  validateFieldOfContractNotifications() {
    try {
      var {
        contract_notifications: {
          contract_notifications: { contractNotificationsType }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    const fields = [];

    //check if contract notifications type is seleceted or not..
    let isContractNotificationsTypeSelected =
      typeof contractNotificationsType === 'string' &&
      contractNotificationsType.length
        ? true
        : false;

    !isContractNotificationsTypeSelected &&
      fields.push(CONSTANTS.CONTRACT_NOTIFICATIONS);
    return fields;
  }

  validateFieldOfPaymentInfoControl() {
    try {
      var {
        payment_info: {
          payment_info: { paymentMode, paymentOption }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    const fields = [];

    //check if contract notifications type is seleceted or not..
    let isPaymentInfoSelectedProperly = false;
    if (typeof paymentMode === 'string' && paymentMode.length) {
      if (paymentMode === PaymentInfoConstants.paymentMode.OFFLINE) {
        isPaymentInfoSelectedProperly = true;
      } else {
        let selectedPaymentOptions = Object.keys(paymentOption);
        if (selectedPaymentOptions.length) {
          let finalArr = selectedPaymentOptions.map(option => {
            return paymentOption[option] !== '' && paymentOption[option] > 0;
          });
          isPaymentInfoSelectedProperly = finalArr.reduce((val1, val2) => {
            return val1 && val2;
          });
        } else {
          isPaymentInfoSelectedProperly = false;
        }
      }
    } else {
      isPaymentInfoSelectedProperly = false;
    }

    if (isPaymentInfoSelectedProperly === false) {
      fields.push(CONSTANTS.PAYMENT_INFO);
      // this.setState({
      //   snackBar: {
      //     message: () => {
      //       return CONSTANTS.PAYMENT_INFO_SNACKBAR;
      //     }
      //   }
      // });
    }
    return fields;
  }

  validateAuthSignON() {
    try {
      var {
        un_authorized_signon: {
          un_authorized_signon: { isEnabled }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    const fields = [];

    if (typeof isEnabled === 'boolean' || isEnabled === '') {
      return fields;
    }
    fields.push(CONSTANTS.AUTH_SIGNON);
    return fields;
  }

  validateAppListingFeeField() {
    try {
      var {
        appPublishingFee: {
          appPublishingFee: { appPublishingFee }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    const fields = [];
    const regex = /^(?=.*[0-9])\d*(?:\.\d{1,2})?$/;
    if (!regex.test(appPublishingFee)) {
      fields.push(CONSTANTS.APP_PUBLISHING_FEE);
    }
    return fields;
  }

  onSave(
    allowSubscriptions,
    captureDataRightsConsent,
    contractNotificationsType,
    paymentMode,
    paymentOption,
    catalog_number,
    is_vision_case_required,
    vision_case_queue,
    uninstall_queue,
    customConsentForm,
    fileName,
    selectedApis
  ) {
    let fields = [];
    let customConsentField = this.validateFieldOfCustomConsent();
    customConsentField && fields.push(...customConsentField);
    let paymentInfoField = this.validateFieldOfPaymentInfoControl();
    paymentInfoField && fields.push(...paymentInfoField);
    if (this.state.formValueFinal.status === 'Approved') {
      let appListingFeeField = this.validateAppListingFeeField();
      appListingFeeField && fields.push(...appListingFeeField);
    }
    if (fields.length) {
      this.navigateToRequiredFields(fields);
    } else {
      this.setState(
        {
          saving: true,
          allowSubscriptions: allowSubscriptions,
          captureDataRightsConsent: captureDataRightsConsent,
          contractNotificationsType: contractNotificationsType,
          paymentMode: paymentMode,
          paymentOption: paymentOption,
          catalog_number: catalog_number,
          is_vision_case_required: is_vision_case_required,
          vision_case_queue: vision_case_queue,
          uninstall_queue: uninstall_queue,
          customConsentForm: customConsentForm,
          fileName: fileName,
          selectedApis: selectedApis
        },
        () => this.onSaved()
      );
    }
  }

  onShowForApprove(
    allowSubscriptions,
    captureDataRightsConsent,
    contractNotificationsType,
    paymentMode,
    paymentOption,
    catalog_number,
    is_vision_case_required,
    vision_case_queue,
    uninstall_queue,
    customConsentForm,
    fileName,
    selectedApis
  ) {
    if (
      this.props &&
      this.props.solution &&
      Array.isArray(this.props.solution) &&
      this.props.solution.length &&
      this.props.solution[0].solutionType === CONSTANTS.FORTELLIS_APP
    ) {
      // navigate to required fields
      var {
        un_authorized_signon: { un_authorized_signon: { isEnabled } = {} } = {}
      } = this.state.formValueFinal;
      const fields = this.validateFields();
      if (fields.length) {
        fields.map(activeTab => {
          if (!activeTab) {
            return;
          }
          let menu = this.state.menu;
          menu.activeMenuId = activeTab;
          menu.nestedActiveMenuId = activeTab;
          this.setState({ activeTabId: activeTab, menu: menu }, () => {
            let focusElement = document.getElementById(activeTab);
            focusElement && focusElement.focus();
            focusElement && focusElement.blur();
            focusElement &&
              focusElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
          });
        });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } else {
        this.setState({
          openDialogForApprove: true,
          allowSubscriptions: allowSubscriptions,
          captureDataRightsConsent: captureDataRightsConsent,
          contractNotificationsType: contractNotificationsType,
          paymentMode: paymentMode,
          paymentOption: paymentOption,
          catalog_number: catalog_number,
          is_vision_case_required: is_vision_case_required,
          vision_case_queue: vision_case_queue,
          uninstall_queue: uninstall_queue,
          customConsentForm: customConsentForm,
          fileName: fileName,
          selectedApis: selectedApis,
          authSignOnRole: '',
          authSignOnEnabled: isEnabled
        });
      }
    } else {
      const fields = this.validateFieldOfContractNotifications();
      const paymentFields = this.validateFieldOfPaymentInfoControl();
      const authSignFields = this.validateAuthSignON();

      paymentFields.length && fields.push(...paymentFields);
      authSignFields.length && fields.push(...authSignFields);

      const additionalInfoFields = this.validateFieldOfAdditionalInfoControl();
      additionalInfoFields.length && fields.push(...additionalInfoFields);

      let customConsentControlFields = this.validateFieldOfCustomConsent();
      customConsentControlFields.length &&
        fields.push(...customConsentControlFields);
      const appListingFeeField = this.validateAppListingFeeField();
      appListingFeeField.length && fields.push(...appListingFeeField);

      if (fields.length) {
        this.navigateToRequiredFields(fields);
      } else {
        this.setState({
          openDialogForApprove: true,
          contractNotificationsType: contractNotificationsType,
          paymentMode: paymentMode,
          paymentOption: paymentOption,
          catalog_number: catalog_number,
          is_vision_case_required: is_vision_case_required,
          vision_case_queue: vision_case_queue,
          uninstall_queue: uninstall_queue,
          customConsentForm: customConsentForm,
          fileName: fileName,
          selectedApis: selectedApis,
          authSignOnRole: '',
          authSignOnEnabled: isEnabled
        });
      }
    }
  }

  navigateToRequiredFields = fields => {
    fields.map(activeTab => {
      if (!activeTab) {
        return;
      }
      let menu = this.state.menu;
      menu.activeMenuId = activeTab;
      menu.nestedActiveMenuId = activeTab;
      this.setState({ activeTabId: activeTab, menu: menu }, () => {
        let focusElement = document.getElementById(activeTab);
        focusElement && focusElement.focus();
        focusElement && focusElement.blur();
        focusElement &&
          focusElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
      });
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  getApproveSolutionDialog = () => {
    let address;
    if (this.state?.formValueFinal) {
      address = this.state?.formValueFinal.address;
    }
    const prevAppListingFee = this.props.prevListing?.appPublishingFee || '0';
    const appListingFee = this.state.formValueFinal?.appPublishingFee
      ?.appPublishingFee?.appPublishingFee;
    let message = '';
    if (address?.countryCode?.toLowerCase() === 'us') {
      if (
        appListingFee !== prevAppListingFee &&
        this.state.formValueFinal.listingVersion > 1
      ) {
        message = `App Listing Fee will change to $${appListingFee} from $${prevAppListingFee}. `;
      } else {
        message = `App Listing Fee will set to $${appListingFee}. `;
      }
    } else if (address?.countryCode?.toLowerCase() === 'ca') {
      if (
        appListingFee !== prevAppListingFee &&
        this.state.formValueFinal.listingVersion > 1
      ) {
        message = `App Listing Fee will change to $${appListingFee} CAD from $${prevAppListingFee} CAD. `;
      } else {
        message = `App Listing Fee will set to $${appListingFee} CAD. `;
      }
    }
    message +=
      'Are you sure you want to approve this app listing? Once approved developer can publish it in Marketplace.';
    return (
      <FortellisDialog
        title={
          <FormattedMessage
            id="SolutionReviewForm.approveTitle"
            defaultMessage="Approve App Listing?"
          />
        }
        message={
          <FormattedMessage
            id="SolutionReviewForm.approveMsg"
            defaultMessage={message}
          />
        }
        open={this.state.openDialogForApprove}
        acceptButtonName={
          <FormattedMessage
            id="SolutionReviewForm.approveAccept"
            defaultMessage="Approve"
          />
        }
        cancelButtonName={
          <FormattedMessage
            id="SolutionReviewForm.approveReject"
            defaultMessage="Cancel"
          />
        }
        onAccept={() => {
          this.setState({ openDialogForApprove: false });
          this.onApproved();
        }}
        onCancel={() => {
          this.setState({ openDialogForApprove: false });
        }}
        acceptDataCy={'btn_dialog_approve_solution_review_ok'}
        cancelDataCy={'btn_dialog_Approvesolution_review_cancel'}
      />
    );
  };

  onShowForWithdraw = () => {
    this.setState({ openDialogForWithdraw: true });
  };

  getWithDrawSolutionDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionReviewForm.withdrawTitle"
          defaultMessage="Withdraw listing from marketplace?"
        />
      }
      message={
        <FormattedMessage
          id="SolutionReviewForm.withdrawMessage"
          defaultMessage="Are you sure you want to withdraw your app listing from Marketplace?"
        />
      }
      open={this.state.openDialogForWithdraw}
      acceptButtonName={
        <FormattedMessage
          id="SolutionReviewForm.withdrawAccept"
          defaultMessage="Withdraw Listing"
        />
      }
      cancelButtonName={
        <FormattedMessage
          id="SolutionReviewForm.withdrawReject"
          defaultMessage="Cancel"
        />
      }
      onAccept={() => {
        this.setState({ openDialogForWithdraw: false });
        this.onWithdrawn();
      }}
      onCancel={() => {
        this.setState({ openDialogForWithdraw: false });
      }}
    />
  );

  onShowForReject = () => {
    this.setState({ openDialogForReject: true });
  };

  getRejectSolutionDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionReviewForm.rejectTitle"
          defaultMessage="Reject App Listing?"
        />
      }
      message={
        <FormattedMessage
          id="SolutionReviewForm.rejectMessage"
          defaultMessage="Are you sure you want to reject this app listing?"
        />
      }
      open={this.state.openDialogForReject}
      acceptButtonName={
        <FormattedMessage
          id="SolutionReviewForm.rejectAccept"
          defaultMessage="Reject"
        />
      }
      cancelButtonName={
        <FormattedMessage
          id="SolutionReviewForm.rejectReject"
          defaultMessage="Cancel"
        />
      }
      onAccept={() => {
        this.setState({ openDialogForReject: false });
        this.onRejected();
      }}
      onCancel={() => {
        this.setState({ openDialogForReject: false });
      }}
    />
  );

  getRevokeSolutionDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionReviewForm.revokeTitle"
          defaultMessage="Revoke listing from marketplace?"
        />
      }
      message={
        <FormattedMessage
          id="SolutionReviewForm.revokeMessage"
          defaultMessage="Are you sure you want to revoke this app listing from Marketplace?"
        />
      }
      open={this.state.openDialogForRevoke}
      acceptButtonName={
        <FormattedMessage
          id="SolutionReviewForm.revokeAccept"
          defaultMessage="Revoke"
        />
      }
      cancelButtonName={
        <FormattedMessage
          id="SolutionReviewForm.revokeReject"
          defaultMessage="Cancel"
        />
      }
      onAccept={() => {
        this.setState({ openDialogForRevoke: false });
        this.onRevoked();
      }}
      onCancel={() => {
        this.setState({ openDialogForRevoke: false });
      }}
    />
  );

  getTryAgainDialog = () => (
    <FortellisDialog
      title={
        <FormattedMessage
          id="SolutionReviewForm.tryAgaintitle"
          defaultMessage="Something went wrong"
        />
      }
      message={
        <FormattedMessage
          id="SolutionReviewForm.tryAgainMessage"
          defaultMessage="Something went wrong, try again later"
        />
      }
      open={this.state.openTryAgainDialog}
      acceptButtonName={
        <FormattedMessage
          id="SolutionReviewForm.approveReject"
          defaultMessage="Cancel"
        />
      }
      onAccept={() => {
        this.setState({
          openTryAgainDialog: false
        });
      }}
    />
  );

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const formFields = this.state.formFields;
    formFields[name] = value;
    this.setState({ formFields: formFields });
  };
  onChange = e => {
    const formFields = this.state.formFields;
    formFields['reviewersComments'] = e.reviewersComments;
    this.setState({ formFields: formFields });
  };

  sendEmailForStatus = (
    emailSubject,
    emailType,
    solutionName,
    solutionReviewComments
  ) => {
    let userNameData = this.state.formStaticValues.developerName.split(' ');
    if (
      this.state.formFields.status === 'Withdrawn' &&
      this.state.formValueFinal.stripePriceId
    ) {
      emailSubject = 'Your Fortellis App is Withdrawn';
      emailType = 'solutionWithdrawnPaymentStopped';
      return this.props.sendEmailCSMG.execute(
        [{ email: this.state.formStaticValues.developerId }],
        emailSubject,
        emailType,
        solutionName,
        this.state.formStaticValues.id,
        userNameData && userNameData.length > 0 ? userNameData[0] : ``,
        userNameData && userNameData.length > 1 ? userNameData[1] : ``
      );
    }
    if (this.state.formFields.status === 'Withdrawn') {
      return this.props.sendEmailCSMG.execute(
        [{ email: this.state.formStaticValues.developerId }],
        emailSubject,
        (emailType = 'solutionWithdrawnByAdmin'),
        solutionName,
        this.state.formStaticValues.id,
        userNameData && userNameData.length > 0 ? userNameData[0] : ``,
        userNameData && userNameData.length > 1 ? userNameData[1] : ``
      );
    }
    return this.props.sendEmail.execute(
      this.state.formStaticValues.developerId,
      emailSubject,
      emailType,
      solutionName,
      solutionReviewComments,
      userNameData && userNameData.length > 0 ? userNameData[0] : ``,
      userNameData && userNameData.length > 1 ? userNameData[1] : ``
    );
  };

  onSubmit = async (isAppBeingSaved, status) => {
    const client = getApolloClient();
    this.setState({
      snackBar: false
    });
    let solution = this.props.solution[0];
    const comment = this.state.comment;
    comment['userId'] = this.props.userData.name;
    comment['commentDate'] = moment()
      .utc()
      .format(this.state.dateFormat);
    let comments = solution.comments ? [...solution.comments] : [];
    comments.push(comment);
    let listingFeeHistory = solution.listingFeeHistory
      ? [...solution.listingFeeHistory]
      : [];

    try {
      var {
        additional_info: {
          additional_info: {
            catalog_number,
            is_vision_case_required,
            vision_case_queue,
            uninstall_queue
          }
        }
      } = this.state.formValueFinal;
    } catch (e) {}
    if (is_vision_case_required && vision_case_queue.length === 0) {
      vision_case_queue = CONSTANTS.VISION_CASE_QUEUE_DEFAULT_VALUE;
    }
    if (is_vision_case_required && uninstall_queue.length === 0) {
      uninstall_queue = CONSTANTS.UINSTALL_VISION_CASE_QUEUE_DEFAULT_VALUE;
    }

    try {
      var {
        custom_consent: {
          custom_consent: { customConsentForm, fileName, selectedApis }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    try {
      var appPublishingFee;
      if (
        this.state.formValueFinal.appPublishingFee &&
        this.state.formValueFinal.appPublishingFee.appPublishingFee &&
        this.state.formValueFinal.appPublishingFee.appPublishingFee
          .appPublishingFee
      ) {
        appPublishingFee = this.state.formValueFinal.appPublishingFee
          .appPublishingFee.appPublishingFee;
      } else {
        const countryCode =
          this.state.formValueFinal &&
          this.state.formValueFinal.address &&
          this.state.formValueFinal.address.countryCode;
        appPublishingFee =
          this.state.formStaticValues.isCdkApp ||
          this.state.formStaticValues.listingFeeWaived
            ? listingFeeConstants.ListingFee.NO_FEE
            : countryCode === COUNTRY_CODE.CANADA
            ? listingFeeConstants.ListingFee.CAD_DEFAULT
            : listingFeeConstants.ListingFee.DEFAULT;
      }
      if (
        solution.appPublishingFee.appPublishingFee &&
        solution.appPublishingFee.appPublishingFee !== appPublishingFee
      ) {
        let appPublishingFeeChanges = {
          userEmail: this.props.userData.email,
          prevListingFee: solution.appPublishingFee.appPublishingFee,
          newListingFee: appPublishingFee,
          updatedOn: moment()
            .utc()
            .format(this.state.dateFormat)
        };
        listingFeeHistory.push(appPublishingFeeChanges);
      }
      let isFeatured;
      if (
        this.state.formValueFinal.featured_app &&
        this.state.formValueFinal.featured_app.featured_app &&
        typeof this.state.formValueFinal.featured_app.featured_app
          .isFeatured === 'boolean'
      ) {
        isFeatured = this.state.formValueFinal.featured_app.featured_app
          .isFeatured;
      } else {
        isFeatured = false;
      }
      let stripePriceId = this.state.formValueFinal.stripePriceId || '';
      if (parseFloat(appPublishingFee) === 0) stripePriceId = '';
      const prevAppListingFee =
        (this.props.prevListing && this.props.prevListing.appPublishingFee) ||
        '';
      if (isAppBeingSaved && status === 'Published') {
        var {
          un_authorized_signon: {
            un_authorized_signon: { isEnabled } = {}
          } = {}
        } = this.state.formValueFinal;
        const isAppSaved = await client.mutate({
          mutation: savePublishedApp,
          variables: {
            id: this.state.formStaticValues.id,
            listingVersion: this.props.listingVersion
              ? this.props.listingVersion
              : solution.listingVersion,
            status: this.state.formFields.status || status,
            subscription_management: {
              allowSubscriptions: this.state.allowSubscriptions
            },
            data_rights: {
              captureDataRightsConsent: this.state.captureDataRightsConsent
            },
            contract_notifications: {
              contractNotificationsType: this.state.contractNotificationsType
            },
            payment_info: {
              paymentMode: this.state.formValueFinal.payment_info.payment_info
                .paymentMode,
              paymentOption: this.state.formValueFinal.payment_info.payment_info
                .paymentOption
            },
            additional_info: {
              catalog_number: catalog_number || '',
              is_vision_case_required: is_vision_case_required || false,
              vision_case_queue: vision_case_queue || '',
              uninstall_queue: uninstall_queue || ''
            },
            custom_consent: {
              customConsentForm: customConsentForm || '',
              fileName: fileName || '',
              selectedApis: selectedApis || []
            },
            un_authorized_signon: {
              isEnabled
            },
            worksWithIds:
              this.state.formValueFinal.worksWithIds.worksWithIds.length > 0
                ? this.state.formValueFinal.worksWithIds.worksWithIds
                : [],
            isFeatured
          }
        });
        this.setState({ reload: true });
        return;
      }
      // App is being approved & listing fee is different from prev listing
      // OR Approved app is being saved & listing fee is different from last saved fee
      if (
        ((this.state.formFields.status === 'Approved' &&
          appPublishingFee !== prevAppListingFee) ||
          (status === 'Approved' &&
            isAppBeingSaved &&
            solution.appPublishingFee.appPublishingFee &&
            solution.appPublishingFee.appPublishingFee !== appPublishingFee)) &&
        appPublishingFee > 0
      ) {
        const appCountryCode = this.state.formValueFinal?.address?.countryCode;
        const createListingFeePriceResp = await client.mutate({
          mutation: createListingFeePriceInStripe,
          variables: {
            listingFee: appPublishingFee,
            countryCode: appCountryCode || COUNTRY_CODE.USA
          }
        });
        if (createListingFeePriceResp.data.createListingFeePriceInStripe) {
          stripePriceId =
            createListingFeePriceResp.data.createListingFeePriceInStripe;
        } else {
          console.error('ERROR IN FINDING/ CREATING PRICE IN STRIPE');
          this.setState({
            saving: false,
            reload: false,
            openTryAgainDialog: true
          });
          return;
        }
      }

      const updateSolutionStatusResp = await client.mutate({
        mutation: updateSolutionStatusMutation,
        variables: {
          id: this.state.formStaticValues.id,
          listingVersion: this.props.listingVersion
            ? this.props.listingVersion
            : solution.listingVersion,
          status: this.state.formFields.status || status,
          lastUpdatedBy: this.props.userData.email,
          lastUpdatedOn: moment()
            .utc()
            .format(this.state.dateFormat),
          comments: comments,
          developerEmail: this.props.userData.email,
          orgId: solution.orgId,
          appName: solution.apigee_id,
          attributeName: 'solutionStatus',
          attributeValue: this.state.formFields.status,
          worksWithIds:
            this.state.formValueFinal.worksWithIds.worksWithIds.length > 0
              ? this.state.formValueFinal.worksWithIds.worksWithIds
              : [],
          subscription_management: {
            allowSubscriptions: this.state.allowSubscriptions
          },
          data_rights: {
            captureDataRightsConsent: this.state.captureDataRightsConsent
          },
          contract_notifications: {
            contractNotificationsType: this.state.contractNotificationsType
          },
          payment_info: {
            paymentMode: this.state.formValueFinal.payment_info.payment_info
              .paymentMode,
            paymentOption: this.state.formValueFinal.payment_info.payment_info
              .paymentOption
          },
          un_authorized_signon: {
            signOnRole: this.state.authSignOnRole,
            isEnabled: this.state.authSignOnEnabled
          },
          additional_info: {
            catalog_number: catalog_number || '',
            is_vision_case_required: is_vision_case_required || false,
            vision_case_queue: vision_case_queue || '',
            uninstall_queue: uninstall_queue || ''
          },
          custom_consent: {
            customConsentForm: customConsentForm || '',
            fileName: fileName || '',
            selectedApis: selectedApis || []
          },
          appPublishingFee,
          isFeatured,
          stripePriceId,
          listingFeeHistory
        }
      });
      //check for status update successful response before performing next tasks
      if (
        updateSolutionStatusResp.data.updateSolutionStatus &&
        updateSolutionStatusResp.data.updateSolutionStatus.status &&
        updateSolutionStatusResp.data.updateSolutionStatus.status ===
          this.state.formFields.status
      ) {
        const sendEmailForStatusResponse = await this.sendEmailForStatus(
          this.state.emailFields.emailSubject,
          this.state.emailFields.emailTemplate,
          this.state.solutionName,
          comment.commentText
        );
        Promise.all([sendEmailForStatusResponse])
          .then(() => {
            this.setState({
              snackBarMessage:
                'App ' +
                updateSolutionStatusResp.data.updateSolutionStatus.status +
                ' Successfully'
            });
            let formValueFinal = this.state.formValueFinal;
            formValueFinal['status'] = comment.action;
            this.setState({ formValueFinal: formValueFinal });
            let formStaticValues = this.state.formStaticValues;
            formStaticValues['status'] = comment.action;
            formStaticValues['comments'] = comments;
            this.setState({ formStaticValues: formStaticValues });
            this.setState({ saving: false });
            this.setState({
              snackBar: {
                message: () => {
                  return this.state.snackBarMessage;
                }
              }
            });
          })
          .catch(error => {
            console.error(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ saving: false });
      }
      this.setState({ reload: true });
    } catch (e) {
      this.setState({
        saving: false,
        reload: true
      });
    }
  };

  onStartReview = solutionName => {
    this.setState({ saving: true });
    let commentForInReview = {
      userId: this.props.userData.name,
      commentDate: moment()
        .utc()
        .format(this.state.dateFormat),
      commentText: `Review started by user ${this.props.userData.name}`,
      action: SolutionStatus.IN_REVIEW
    };
    this.setState({ startingReview: true });
    const formFields = this.state.formFields;
    formFields['status'] = SolutionStatus.IN_REVIEW;
    this.setState({ formFields: formFields });
    let solution = this.props.solution[0];
    let comments = solution.comments ? [...solution.comments] : [];
    comments.push(commentForInReview);
    const startReviewResponse = this.props.updateSolutionStatus.execute(
      this.props.solutionId,
      this.props.listingVersion
        ? this.props.listingVersion
        : solution.listingVersion,
      this.state.formFields.status,
      solution.lastUpdatedBy,
      solution.lastUpdatedOn,
      comments,
      this.props.userData.email,
      solution.orgId,
      solution.apigee_id,
      'solutionStatus',
      this.state.formFields.status
    );

    const sendEmailForStatusResponse = this.sendEmailForStatus(
      `Your Fortellis App is In Review`,
      `solutionInReviewEmail`,
      solutionName,
      ``
    );

    Promise.all([startReviewResponse, sendEmailForStatusResponse]).then(() => {
      this.setState({ startingReview: false });
      this.setState({ startedReview: true });
      this.setState({ saving: false });
      let formValueFinal = this.state.formValueFinal;
      formValueFinal['status'] = commentForInReview.action;
      this.setState({ formValueFinal: formValueFinal });
      let formStaticValues = this.state.formStaticValues;
      formStaticValues['status'] = commentForInReview.action;
      formStaticValues['comments'] = comments;
      this.setState({ formStaticValues: formStaticValues });
    });
  };

  onRejected = () => {
    this.onStatusChange(
      SolutionStatus.REJECTED,
      'solutionRejectedEmail',
      'Your Fortellis App Was Not Approved'
    );
  };

  onApproved = () => {
    this.onStatusChange(
      SolutionStatus.APPROVED,
      'solutionApprovedEmail',
      'Your Fortellis App is Approved'
    );
  };

  onSaved = () => {
    let currentAppStatus = this.state.formValueFinal.status;
    let appBeingSaved = true;
    this.onStatusChange(currentAppStatus, '', '', appBeingSaved);
  };

  onWithdrawn = () => {
    this.onStatusChange(
      SolutionStatus.WITHDRAWN,
      'solutionWithdrawnEmail',
      'Your Fortellis App Has Been Withdrawn'
    );
  };

  onRevoked = () => {
    this.onStatusChange(
      SolutionStatus.REVOKED,
      'solutionRevokedEmail',
      'Your Fortellis App Has Been Revoked'
    );
  };

  replaceRejectedWithNotApproved = status => {
    if (status && status === SolutionStatus.REJECTED) {
      return SolutionStatus.NOT_APPROVED;
    }
    return status;
  };

  onStatusChange = async (status, emailType, emailSubject, isAppBeingSaved) => {
    if (isAppBeingSaved) {
      this.onSubmit(isAppBeingSaved, status);
      return;
    }

    this.setState({ saving: true });

    const formFields = this.state.formFields;
    formFields['status'] = status;
    this.setState({ formFields: formFields });

    const comment = this.state.comment;
    comment['action'] = this.replaceRejectedWithNotApproved(status);
    comment['commentText'] =
      this.state.formFields.reviewersComments !== ''
        ? this.state.formFields.reviewersComments
        : this.replaceRejectedWithNotApproved(status) +
          ' by user ' +
          this.props.userData.name;
    this.setState({ comment: comment });

    const emailFields = this.state.emailFields;
    emailFields['emailTemplate'] = emailType;
    emailFields['emailSubject'] = emailSubject;
    this.setState({ emailFields: emailFields });

    switch (status) {
      case SolutionStatus.APPROVED:
        this.solutionOps = new SolutionOperations();

        let contractNotificationsFlag = '';
        if (
          this.state.formValueFinal &&
          this.state.formValueFinal.contract_notifications &&
          this.state.formValueFinal.contract_notifications
            .contract_notifications &&
          this.state.formValueFinal.contract_notifications
            .contract_notifications.contractNotificationsType
        ) {
          contractNotificationsFlag = this.state.formValueFinal
            .contract_notifications.contract_notifications
            .contractNotificationsType;
        }
        await this.solutionOps.notifyAPIProvider(
          this.props.solution[0],
          null,
          this.props.userData,
          contractNotificationsFlag
        );
        break;
    }
    this.onSubmit();
  };

  async fetchProviders(apis) {
    let providers = [];
    if (apis.length) {
      let response = await this.props.client.query({
        query: getProviders,
        variables: {
          apiIds: apis
        }
      });
      if (response.data && response.data.providers) {
        providers = response.data.providers;
      }
      return providers;
    }
  }

  async fetchConsents(solutionId, listingVersion) {
    const {
      auth: { accessToken }
    } = this.props;

    const response = await axios.get(
      `${
        environmentURLs.consentServiceBaseUrl
      }/solutions/${solutionId}/${listingVersion}/consents`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : ''
        }
      }
    );
    return response ? response.data : [];
  }

  async getNoOfSubscription(solutionId) {
    let numberOfSubscriptions = 0;
    if (solutionId) {
      let response = await this.props.client.query({
        query: getNoOfSubscription,
        variables: {
          solutionId: solutionId
        }
      });
      if (response.data && response.data.solutionSubscriptions) {
        numberOfSubscriptions = response.data.solutionSubscriptions.length;
      }
      return numberOfSubscriptions;
    }
  }

  async getAsyncApiDetails(id) {
    let response = await this.props.client.query({
      query: getAsyncApiData,
      variables: {
        id: id
      }
    });
    return response.data;
  }

  createApiToProvidersMappingWithConsent = async solution => {
    //creating apiToProvidersMapping along with consent Info
    if (
      solution &&
      solution.registration_details &&
      solution.registration_details.api_details
    ) {
      let apisForRequiredForSolution =
        solution.registration_details.api_details;
      let asyncApiData = [];
      let asyncApiDetails = await this.getAsyncApiDetails(solution.id);
      asyncApiData = asyncApiDetails;
      let numberOfSubscriptions = await this.getNoOfSubscription(solution.id);
      let formValueFinalCopy = JSON.parse(
        JSON.stringify(this.state.formValueFinal)
      );

      asyncApiData = asyncApiData?.asyncApiDetails?.integrations?.filter(
        integration => {
          return apisForRequiredForSolution?.some(api => {
            if (integration.apiType === 'async-api')
              return api.id === integration.id;
            else return api.id.includes(integration.id);
          });
        }
      );

      if (
        formValueFinalCopy &&
        formValueFinalCopy.registration_details &&
        formValueFinalCopy.registration_details.registration_details &&
        formValueFinalCopy.registration_details.registration_details.api_details
      ) {
        formValueFinalCopy.registration_details.registration_details.api_details = asyncApiData;
        formValueFinalCopy.registration_details.registration_details.numberOfSubscriptions = numberOfSubscriptions;
      }

      let api_details_array = formValueFinalCopy.registration_details.registration_details.api_details.filter(
        item => {
          if (item.id !== environmentURLs.TestAPI) {
            return item;
          }
        }
      );

      const key = 'id';
      // trannsfer only the unique apis to ui
      formValueFinalCopy.registration_details.registration_details.api_details = [
        ...new Map(api_details_array.map(item => [item[key], item])).values()
      ];
      this.setState({
        formValueFinal: formValueFinalCopy
      });
    }
  };

  clearAdminControls(solutionData) {
    delete solutionData.subscription_management;
    delete solutionData.data_rights;
    let menu = this.state.menu;
    menu.contents = menu.contents.filter(
      item => item.title !== CONSTANTS.ADMIN_CONTROLS
    );

    //need to add this cause it's necessary to be shown in both fortellis apps + In market apps case.
    menu.contents.push({
      title: CONSTANTS.ADMIN_CONTROLS,
      steps: [
        {
          id: CONSTANTS.PAYMENT_INFO,
          label: CONSTANTS.PAYMENT_INFO_FROM_TITLE,
          type: 'simple'
        },
        {
          id: CONSTANTS.CONTRACT_NOTIFICATIONS,
          label: CONSTANTS.CONTRACT_NOTIFICATIONS_FORM_TITLE,
          type: 'simple'
        },
        {
          id: CONSTANTS.ADDITIONAL_INFO,
          label: CONSTANTS.ADDITIONAL_INFO_FORM_TITLE,
          type: 'simple'
        },
        {
          id: CONSTANTS.FEATURED_APP,
          label: CONSTANTS.FEATURED_APP_FORM_TITLE,
          type: 'simple'
        },
        {
          id: CONSTANTS.APP_PUBLISHING_FEE,
          label: CONSTANTS.APP_LISTING_FEE_TITLE,
          type: 'simple'
        },
        {
          id: CONSTANTS.AUTH_SIGNON,
          label: 'Authorized SignOn',
          type: 'simple'
        }
      ]
    });

    this.setState({
      menu: menu
    });
  }

  addLegal(solution) {
    let menu = this.clearLegal(this.state.menu);

    //check if solution has developer agreement
    if (solution.developerAgreement) {
      menu.contents.push({
        title: 'Legal',
        steps: [
          {
            id: 'developerAgreement',
            label: 'Publisher Agreement',
            type: 'link',
            href: solution.developerAgreement
          }
        ]
      });
    }
    this.setState({ menu: menu });
  }

  clearLegal = menu => {
    menu.contents =
      menu &&
      menu.contents &&
      menu.contents.filter(content => content.title != 'Legal');
    return menu;
  };

  async getEntityData(orgId) {
    try {
      let response = await this.props.client.query({
        query: getEntityData,
        variables: {
          id: orgId
        }
      });
      if (!response.errors && response.data.entitiesFromPlatform)
        return response.data.entitiesFromPlatform;
    } catch (error) {
      console.error('Error in getting entity data', error);
      return null;
    }
  }

  checkIfCdkApp = orgName => {
    return (
      orgName
        .trim()
        .toLowerCase()
        .split(' ')[0] === 'cdk'
    );
  };

  checkIfFeeWaived = enhancedFlags => {
    if (enhancedFlags) {
      return JSON.parse(enhancedFlags)?.skipListingFee;
    }
    return false;
  };

  setIsCdkApp = async orgId => {
    const entityData = await this.getEntityData(orgId);
    const orgName = entityData?.name || '';
    const { address } = entityData;
    const isCdkApp = this.checkIfCdkApp(orgName);
    const listingFeeWaived = this.checkIfFeeWaived(entityData?.enhancedFlags);
    if (listingFeeWaived) {
      this.setState({
        formValueFinal: {
          ...this.state.formValueFinal,
          address,
          appPublishingFee: { appPublishingFee: { appPublishingFee: '0' } }
        }
      });
    } else {
      this.setState({
        formValueFinal: { ...this.state.formValueFinal, address }
      });
    }
    this.setState({
      formStaticValues: {
        ...this.state.formStaticValues,
        isCdkApp,
        listingFeeWaived
      }
    });
  };

  async fetchSolutionCertificateStatus(data) {
    if (data.orgId && data.id) {
      try {
        let response = await this.props.client.query({
          query: getCertificationStaus,
          variables: {
            orgId: data.orgId,
            solutionId: data.id,
            type: 'f'
          }
        });
        if (response.data && response.data.apps) {
          return response.data.apps;
        }
      } catch (error) {
        console.error('Error in getting certification status', error);
        return null;
      }
    }
  }

  getLatestCertificationStaus = async data => {
    if (
      !this.state.formValueFinal.certificationStatus ||
      (data.id && data.orgId)
    ) {
      const certificationData = await this.fetchSolutionCertificateStatus(data);
      let certificationStatus =
        certificationData && Array.isArray(certificationData)
          ? certificationData[0].certificationStatus
          : '';
      if (certificationStatus) {
        this.setState({
          formStaticValues: {
            ...this.state.formStaticValues,
            certificationStatus
          }
        });
        this.setState({
          formValueFinal: { ...this.state.formValueFinal, certificationStatus }
        });
      }
    }
  };

  setInitialState = () => {
    if (this.props.solution && this.props.solution.length) {
      let solution = this.props.solution[0];
      // handling duplicate call
      if (this.props.stripeSubscriptionData?.length === 0)
        this.props.getStripeSubscriptionInfo(
          solution.id,
          solution.orgId,
          this.props.auth.accessToken
        );
      this.createApiToProvidersMappingWithConsent(solution);
      this.setIsCdkApp(solution.orgId);
      this.getLatestCertificationStaus(solution);
      const prevPublishedListing = this.props.prevListing;
      let solutionData = SolutionFormData.dataObject;
      if (solution) {
        solutionData = solution;
        solutionData['featured_app'] = {
          isFeatured: solution.isFeatured || false
        };
        solutionData[CONSTANTS.APP_PUBLISHING_FEE] = {
          appPublishingFee: solution.appPublishingFee
        };
      }
      //Do not show subscription management for partner apps
      if (solutionData.solutionType === CONSTANTS.FORTELLIS_APP) {
        //admins should be able to select enable/disable options from "Subscription Management" if app status is "Submitted" or "In Review"
        if (
          solutionData.status === CONSTANTS.SUBMITTED ||
          solutionData.status === CONSTANTS.IN_REVIEW
        ) {
          FormConfig[SubscriptionManagement.config.formId] =
            SubscriptionManagement.config;
          FormConfig[DataRightsConsent.config.formId] =
            DataRightsConsent.config;
          FormConfig[ContractNotificationConfig.config.formId] =
            ContractNotificationConfig.config;
          FormConfig[PaymentOptionsConfig.config.formId] =
            PaymentOptionsConfig.config;
          FormConfig[authorized_signon_config.config.formId] =
            authorized_signon_config.config;

          FormConfig[AdminControlAdditionalInfoConfig.config.formId] =
            AdminControlAdditionalInfoConfig.config;
          FormConfig[CustomConsentConfig.config.formId] =
            CustomConsentConfig.config;
        } else {
          //if the app status is not "Submitted" nor "In Review", then "Subscription Management" section should be read-only
          if (
            solutionData[CONSTANTS.SUBSCRIPTION_MANAGEMENT] &&
            typeof solutionData[CONSTANTS.SUBSCRIPTION_MANAGEMENT]
              .allowSubscriptions === 'boolean'
          ) {
            FormConfig[SubscriptionManagement.config.formId] =
              SubscriptionManagement.config;
          }

          if (
            solutionData[CONSTANTS.DATA_RIGHTS] &&
            typeof solutionData[CONSTANTS.DATA_RIGHTS]
              .captureDataRightsConsent === 'boolean'
          ) {
            FormConfig[DataRightsConsent.config.formId] =
              DataRightsConsent.config;
          }

          if (
            solutionData[CONSTANTS.PAYMENT_INFO] &&
            typeof solutionData[CONSTANTS.PAYMENT_INFO].paymentMode ===
              'string' &&
            typeof solutionData[CONSTANTS.PAYMENT_INFO].paymentOption ===
              'object'
          ) {
            //to be changed when payments is enabled
            FormConfig[PaymentOptionsConfig.config.formId] =
              PaymentOptionsStaticConfig.config;
          } else {
            FormConfig[PaymentOptionsConfig.config.formId] =
              PaymentOptionsStaticConfig.config;
          }

          if (
            solutionData[CONSTANTS.AUTH_SIGNON] &&
            typeof solutionData[CONSTANTS.AUTH_SIGNON].isEnabled === 'boolean'
          ) {
            FormConfig[authorized_signon_config.config.formId] =
              authorized_signon_config_static.config;
          } else {
            FormConfig[authorized_signon_config.config.formId] =
              authorized_signon_config_static.config;
          }

          if (solutionData[CONSTANTS.ADDITIONAL_INFO]) {
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO].catalog_number !==
              'string'
            )
              solutionData[CONSTANTS.ADDITIONAL_INFO].catalog_number = '';
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO]
                .is_vision_case_required !== 'boolean'
            )
              solutionData[
                CONSTANTS.ADDITIONAL_INFO
              ].is_vision_case_required = false;
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO]
                .vision_case_queue !== 'string'
            )
              solutionData[CONSTANTS.ADDITIONAL_INFO].vision_case_queue = '';
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO].uninstall_queue !==
              'string'
            )
              solutionData[CONSTANTS.ADDITIONAL_INFO].uninstall_queue = '';
            FormConfig[AdminControlAdditionalInfoConfig.config.formId] =
              AdminControlAdditionalInfoConfig.config;
          } else {
            solutionData[CONSTANTS.ADDITIONAL_INFO] = {
              catalog_number: '',
              is_vision_case_required: false,
              vision_case_queue: '',
              uninstall_queue: ''
            };
            FormConfig[AdminControlAdditionalInfoConfig.config.formId] =
              AdminControlAdditionalInfoConfig.config;
          }

          if (
            solutionData[CONSTANTS.CONTRACT_NOTIFICATIONS] &&
            typeof solutionData[CONSTANTS.CONTRACT_NOTIFICATIONS]
              .contractNotificationsType === 'string'
          ) {
            FormConfig[ContractNotificationConfig.config.formId] =
              ContractNotificationConfig.config;
          }
          if (
            solutionData[CONSTANTS.CUSTOM_CONSENT] &&
            typeof solutionData[CONSTANTS.CUSTOM_CONSENT].customConsentForm ===
              'string' &&
            typeof solutionData[CONSTANTS.CUSTOM_CONSENT].fileName ===
              'string' &&
            typeof solutionData[CONSTANTS.CUSTOM_CONSENT].selectedApis ===
              'object' &&
            typeof solutionData[CONSTANTS.CUSTOM_CONSENT] === 'object'
          ) {
            FormConfig[CustomConsentConfig.config.formId] =
              CustomConsentConfig.config;
          } else if (!solutionData[CONSTANTS.CUSTOM_CONSENT]) {
            solutionData[CONSTANTS.CUSTOM_CONSENT] = {
              customConsentForm: '',
              fileName: '',
              selectedApis: []
            };
            FormConfig[CustomConsentConfig.config.formId] =
              CustomConsentConfig.config;
          }

          //subscription management menu item should not be shown if the app does not have 'enable/disable' option selected at the time of approving
          else {
            this.clearAdminControls(solutionData);
          }
        }
      } else {
        //Partner App
        this.clearAdminControls(solutionData);
        if (
          solutionData.status === CONSTANTS.SUBMITTED ||
          solutionData.status === CONSTANTS.IN_REVIEW
        ) {
          FormConfig[ContractNotificationConfig.config.formId] =
            ContractNotificationConfig.config;
          FormConfig[PaymentOptionsConfig.config.formId] =
            PaymentOptionsConfig.config;
          FormConfig[authorized_signon_config.config.formId] =
            authorized_signon_config.config;

          FormConfig[AdminControlAdditionalInfoConfig.config.formId] =
            AdminControlAdditionalInfoConfig.config;
          FormConfig[FeaturedAppConfig.config.formId] =
            FeaturedAppConfig.config;
        } else {
          //if the app status is not "Submitted" or "In Review", then "Contract Notifications" section should be "read-only".
          if (
            solutionData[CONSTANTS.CONTRACT_NOTIFICATIONS] &&
            typeof solutionData[CONSTANTS.CONTRACT_NOTIFICATIONS]
              .contractNotificationsType === 'string'
          ) {
            FormConfig[ContractNotificationConfig.config.formId] =
              ContractNotificationConfig.config;
          }

          if (
            solutionData[CONSTANTS.PAYMENT_INFO] &&
            typeof solutionData[CONSTANTS.PAYMENT_INFO].paymentMode ===
              'string' &&
            solutionData[CONSTANTS.PAYMENT_INFO].paymentMode.length > 0 &&
            typeof solutionData[CONSTANTS.PAYMENT_INFO].paymentOption ===
              'object'
          ) {
            FormConfig[PaymentOptionsConfig.config.formId] =
              PaymentOptionsStaticConfig.config;
          }

          if (
            solutionData[CONSTANTS.AUTH_SIGNON] &&
            typeof solutionData[CONSTANTS.AUTH_SIGNON].isEnabled === 'boolean'
          ) {
            FormConfig[authorized_signon_config.config.formId] =
              authorized_signon_config_static.config;
          }

          if (solutionData[CONSTANTS.ADDITIONAL_INFO]) {
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO].catalog_number !==
              'string'
            )
              solutionData[CONSTANTS.ADDITIONAL_INFO].catalog_number = '';
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO]
                .is_vision_case_required !== 'boolean'
            )
              solutionData[
                CONSTANTS.ADDITIONAL_INFO
              ].is_vision_case_required = false;
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO]
                .vision_case_queue !== 'string'
            )
              solutionData[CONSTANTS.ADDITIONAL_INFO].vision_case_queue = '';
            if (
              typeof solutionData[CONSTANTS.ADDITIONAL_INFO].uninstall_queue !==
              'string'
            )
              solutionData[CONSTANTS.ADDITIONAL_INFO].uninstall_queue = '';
            FormConfig[AdminControlAdditionalInfoConfig.config.formId] =
              AdminControlAdditionalInfoConfig.config;
          } else {
            solutionData[CONSTANTS.ADDITIONAL_INFO] = {
              catalog_number: '',
              is_vision_case_required: false,
              vision_case_queue: '',
              uninstall_queue: ''
            };
            FormConfig[AdminControlAdditionalInfoConfig.config.formId] =
              AdminControlAdditionalInfoConfig.config;
          }
          FormConfig[FeaturedAppConfig.config.formId] =
            FeaturedAppConfig.config;
        }
      }

      this.addLegal(solution);

      if (
        prevPublishedListing &&
        (solutionData.status === CONSTANTS.SUBMITTED ||
          solutionData.status === CONSTANTS.IN_REVIEW)
      ) {
        if (!solutionData['stripePriceId']) {
          solutionData['stripePriceId'] = prevPublishedListing.stripePriceId;
        }
        if (
          solutionData[CONSTANTS.SUBSCRIPTION_MANAGEMENT] &&
          typeof solutionData[CONSTANTS.SUBSCRIPTION_MANAGEMENT]
            .allowSubscriptions !== 'boolean'
        ) {
          solutionData[CONSTANTS.SUBSCRIPTION_MANAGEMENT] =
            prevPublishedListing[CONSTANTS.SUBSCRIPTION_MANAGEMENT];
        }
        if (
          solutionData[CONSTANTS.DATA_RIGHTS] &&
          !solutionData[CONSTANTS.DATA_RIGHTS].captureDataRightsConsent
        ) {
          solutionData[CONSTANTS.DATA_RIGHTS] =
            prevPublishedListing[CONSTANTS.DATA_RIGHTS];
        }
        if (!solutionData[CONSTANTS.PAYMENT_INFO]) {
          solutionData[CONSTANTS.PAYMENT_INFO] =
            prevPublishedListing[CONSTANTS.PAYMENT_INFO];
        }
        if (!solutionData[CONSTANTS.CONTRACT_NOTIFICATIONS]) {
          solutionData[CONSTANTS.CONTRACT_NOTIFICATIONS] =
            prevPublishedListing[CONSTANTS.CONTRACT_NOTIFICATIONS];
        }
        if (!solutionData[CONSTANTS.ADDITIONAL_INFO]) {
          solutionData[CONSTANTS.ADDITIONAL_INFO] =
            prevPublishedListing[CONSTANTS.ADDITIONAL_INFO];
        }
        if (!solutionData[CONSTANTS.AUTH_SIGNON]) {
          solutionData[CONSTANTS.AUTH_SIGNON] =
            prevPublishedListing[CONSTANTS.AUTH_SIGNON];
        }
        if (!solutionData[CONSTANTS.CUSTOM_CONSENT]) {
          solutionData[CONSTANTS.CUSTOM_CONSENT] =
            prevPublishedListing[CONSTANTS.CUSTOM_CONSENT];
        }
        if (!solutionData[CONSTANTS.WORKS_WITH_IDS]) {
          solutionData[CONSTANTS.WORKS_WITH_IDS] =
            prevPublishedListing[CONSTANTS.WORKS_WITH_IDS];
        }
        if (
          solutionData['isFeatured'] === null &&
          typeof prevPublishedListing.isFeatured === 'boolean'
        ) {
          solutionData[CONSTANTS.FEATURED_APP] = {
            isFeatured: prevPublishedListing.isFeatured
          };
        }
        if (
          !solutionData[CONSTANTS.APP_PUBLISHING_FEE][
            CONSTANTS.APP_PUBLISHING_FEE
          ]
        ) {
          solutionData[CONSTANTS.APP_PUBLISHING_FEE] = {
            appPublishingFee: prevPublishedListing.appPublishingFee
          };
        }
      }

      let formValueFinal = FormUtils.mapFormValuesForView(
        solutionData,
        FormConfig
      );

      this.setState({ formValueFinal: formValueFinal });
      let staticData = FormUtils.initializeStaticFormFields(
        this.state.formStaticValues,
        formValueFinal,
        formValueFinal.developerId
      );
      staticData.apigee_id = solution.apigee_id;
      staticData.orgId = solution.orgId;
      this.setState({ formStaticValues: staticData });
      let initialMenu = SideNavUtils.getInitialMenu(
        formValueFinal,
        cloneDeep(this.state.menu)
      );
      if (formValueFinal.activateOnly !== false) {
        initialMenu.contents.forEach((content, index) => {
          if (content.title === 'App Details') {
            initialMenu.contents[index].steps.splice(2, 1);
          }
        });
      }

      this.setState({ solutionName: solutionData.overview.name });
      if (solutionData.status === SolutionStatus.SUBMITTED) {
        this.onStartReview(solutionData.overview.name);
        staticData.status = SolutionStatus.IN_REVIEW;
        this.setState({ formStaticValues: staticData });
      }
      this.setState({ menu: initialMenu });

      this.setState({ initialize: true });
    }
  };

  handleMenuStateChange = newMenuState => {
    let menuNew = cloneDeep(newMenuState);
    if (menuNew.activeMenuId !== 'comment_history') {
      let nestedActiveMenuId = menuNew.nestedActiveMenuId;
      let activeMenuId = menuNew.activeMenuId;
      let formFields = JSON.parse(
        JSON.stringify(
          FormConfig[activeMenuId].formFields[nestedActiveMenuId]
            ? FormConfig[activeMenuId].formFields[nestedActiveMenuId]
            : {}
        )
      );
      if (formFields.dynamic) {
        menuNew = SideNavUtils.createDynamicMenuItem(
          menuNew,
          nestedActiveMenuId,
          formFields,
          activeMenuId
        );
        nestedActiveMenuId = menuNew.nestedActiveMenuId;
      }
      let formValueFinalNew = FormUtils.initializeFormValueForFirstLoad(
        JSON.parse(JSON.stringify(this.state.formValueFinal)),
        activeMenuId,
        nestedActiveMenuId,
        formFields
      );

      this.setState({ formValueFinal: formValueFinalNew });
    }
    this.setState({ menu: menuNew });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  initializeFormValueForFirstLoad(activeMenuId, nestedActiveMenuId, value) {
    let formValueFinal = JSON.parse(JSON.stringify(this.state.formValueFinal));
    if (!formValueFinal[activeMenuId]) {
      formValueFinal[activeMenuId] = {};
    }
    if (!formValueFinal[activeMenuId][nestedActiveMenuId]) {
      formValueFinal[activeMenuId][nestedActiveMenuId] = value;
    }
    this.setState({ formValueFinal: formValueFinal });
  }

  createDynamicMenuItem(nestedActiveMenuId, formFields, activeMenuId) {
    let dynamicMenuLabel = {
      id: '',
      label: '',
      type: 'dynamic'
    };

    if (this.state.menu[activeMenuId].length > 1) {
      dynamicMenuLabel.id = this.state.menu[activeMenuId][0].id + 1;
    } else {
      dynamicMenuLabel.id = 1;
    }

    let menu = this.state.menu;
    menu[activeMenuId].unshift(dynamicMenuLabel);
    menu.nestedActiveMenuId = dynamicMenuLabel.id;
    nestedActiveMenuId = menu.nestedActiveMenuId;
    this.setState({ menu: menu });
    return nestedActiveMenuId;
  }

  onTabChange(tabId) {
    this.setState({ activeTabId: tabId });
  }

  getConfig = () => {
    let formConfig = cloneDeep(FormConfig);
    if (this.state.menu.activeMenuId === 'appPublishingFee') {
    }
    if (this.state.activeTabId && formConfig[this.state.menu.activeMenuId]) {
      if (
        formConfig[this.state.menu.activeMenuId]['tabs'].filter(
          tab => tab.id === this.state.activeTabId
        ).length
      ) {
        formConfig[this.state.menu.activeMenuId][
          'activeTabId'
        ] = this.state.activeTabId;
      }
    }
    return formConfig;
  };

  handleToggleChange = expandedMenuId => {
    this.setState({
      menu: Object.assign({}, this.state.menu, { expandedMenuId })
    });
  };

  reloadOnSuccess = reloadFlag => {
    this.setState({ reload: reloadFlag });
  };

  componentDidUpdate(prevProps) {
    if (!this.state.initialize) {
      this.setInitialState();
    }
  }

  componentDidMount() {
    if (!this.state.initialize) {
      this.setInitialState();
    }
  }

  componentWillMount() {
    this.initializeFormValueForFirstLoad(
      this.state.menu.activeMenuId,
      this.state.menu.nestedActiveMenuId,
      {}
    );
  }

  render() {
    try {
      var {
        subscription_management: {
          subscription_management: { allowSubscriptions }
        },
        data_rights: {
          data_rights: { captureDataRightsConsent }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    try {
      var {
        contract_notifications: {
          contract_notifications: { contractNotificationsType }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    try {
      var {
        payment_info: {
          payment_info: { paymentMode, paymentOption }
        }
      } = this.state.formValueFinal;
    } catch (e) {}

    try {
      var {
        additional_info: {
          additional_info: {
            catalog_number,
            is_vision_case_required,
            vision_case_queue,
            uninstall_queue
          }
        }
      } = this.state.formValueFinal;
    } catch (e) {}
    try {
      var {
        custom_consent: {
          custom_consent: { customConsentForm, fileName, selectedApis }
        }
      } = this.state.formValueFinal;
    } catch (e) {}
    const { solution } = this.props;

    if (this.state.reload) {
      window.location.reload();
    }

    if (this.state.submitted) {
      window.location.reload();
    }

    return (
      <div className="component-content">
        <PreviewSolution
          {...this.props}
          openDialog={this.state.openPreviewDialog}
          onClose={() => this.setState({ openPreviewDialog: false })}
          userPermissions={this.props.userPermissions}
          isPreview={true}
          listingVersion={
            this.props.match.params.listingVersion
              ? this.props.match.params.listingVersion
              : '1'
          }
          match={this.props.match}
        />
        {this.state.saving ||
        this.state.startingReview ||
        !solution ||
        solution.length === 0 ? (
          <LayoutGrid className="max-width-resp page-padding-common">
            <LayoutGridCell span={12} className="save-loading-spinner">
              <Spinner />
            </LayoutGridCell>
          </LayoutGrid>
        ) : (
          <LayoutGrid className="max-width-resp page-padding-common">
            <LayoutGridCell span={3} className="fm-side-nav">
              <SideNav
                sideNav={this.state.menu}
                handleToggleChange={this.handleToggleChange}
                handleSideNavStateChange={this.handleMenuStateChange}
                solutionFields={{ solutiondata: solution[0] }}
              />
            </LayoutGridCell>
            <LayoutGridCell span={9}>
              <Form name="solution_form" onSubmit={this.onSubmit.bind(this)}>
                <LayoutGrid className="fm-form-layout-grid">
                  <LayoutGridCell span={12} className="form-box">
                    <FormAPI
                      formConfig={this.getConfig(
                        this.state.formValueFinal.status
                      )}
                      formStaticValues={this.state.formStaticValues}
                      formFieldValues={this.state.formValueFinal}
                      activeFormId={this.state.menu.activeMenuId}
                      activeSubFormId={this.state.menu.nestedActiveMenuId}
                      onSave={this.onChange}
                      isSolutionReview={true}
                      onTabChange={this.onTabChange.bind(this)}
                      solution={this.props.solution}
                      resetCustomConsentForm={() => {
                        let newFormValues = {
                          ...this.state.formValueFinal,
                          custom_consent: {
                            custom_consent: {
                              customConsentForm: '',
                              fileName: '',
                              selectedApis: []
                            }
                          }
                        };

                        this.setState({
                          formValueFinal: newFormValues
                        });
                      }}
                    />
                  </LayoutGridCell>
                  <LayoutGridCell span={12} className="form-action-buttons">
                    <span>
                      {this.state.formValueFinal.status ===
                      SolutionStatus.IN_REVIEW ? (
                        <>
                          <div
                            id={`tooltip_approve_btn`}
                            className="aprv-button"
                          >
                            <Button
                              className="button-right"
                              unelevated
                              type="button"
                              compact={'true'}
                              onClick={() =>
                                this.onShowForApprove(
                                  allowSubscriptions,
                                  captureDataRightsConsent,
                                  contractNotificationsType,
                                  paymentMode,
                                  paymentOption,
                                  catalog_number,
                                  is_vision_case_required,
                                  vision_case_queue,
                                  uninstall_queue,
                                  customConsentForm,
                                  fileName,
                                  selectedApis
                                )
                              }
                              disabled={
                                this.state.formValueFinal.certificationStatus &&
                                this.state.formValueFinal
                                  .certificationStatus !== 'certified'
                                  ? true
                                  : false
                              }
                              id="InReview-approveBtn"
                            >
                              <FormattedMessage
                                id="SolutionReviewForm.approveButton"
                                defaultMessage="Approve"
                              />
                            </Button>
                          </div>
                          {this.state.formValueFinal.certificationStatus &&
                            this.state.formValueFinal.certificationStatus !==
                              'certified' && (
                              <Tooltip
                                className="view-lead-message-tooltip admin-leads-tooltip-position"
                                htmlFor={`tooltip_approve_btn`}
                              >
                                <span>{`This listing version can not be approved until app is certified.`}</span>
                              </Tooltip>
                            )}
                          {this.getApproveSolutionDialog()}
                          <Button
                            className="button-right"
                            compact={'true'}
                            type="button"
                            outlined={true}
                            onClick={this.onShowForReject}
                            id="InReview-rejectBtn"
                          >
                            <FormattedMessage
                              id="SolutionReviewForm.rejectButton"
                              defaultMessage="Reject"
                            />
                          </Button>
                          {this.getRejectSolutionDialog()}
                        </>
                      ) : this.state.formValueFinal.status ===
                        SolutionStatus.APPROVED ? (
                        <>
                          <Button
                            className="button-right"
                            compact={'true'}
                            type="button"
                            unelevated
                            onClick={() => {
                              this.setState({ openDialogForRevoke: true });
                            }}
                            id="Approved-revokeBtn"
                          >
                            <FormattedMessage
                              id="SolutionReviewForm.revokeButton"
                              defaultMessage="Revoke"
                            />
                          </Button>
                          {this.getRevokeSolutionDialog()}
                        </>
                      ) : this.state.formValueFinal.status ===
                        SolutionStatus.PUBLISHED ? (
                        <>
                          <Button
                            className="button-right"
                            compact={'true'}
                            type="button"
                            primary
                            onClick={this.onShowForWithdraw}
                            id="Published-withdrawBtn"
                          >
                            <FormattedMessage
                              id="SolutionReviewForm.withdrawButton"
                              defaultMessage="Withdraw"
                            />
                          </Button>
                          {this.getWithDrawSolutionDialog()}
                        </>
                      ) : (
                        false
                      )}

                      <Button
                        className="button-right"
                        compact={'true'}
                        type="button"
                        outlined={true}
                        onClick={() => {
                          this.onSave(
                            allowSubscriptions,
                            captureDataRightsConsent,
                            contractNotificationsType,
                            paymentMode,
                            paymentOption,
                            catalog_number,
                            is_vision_case_required,
                            vision_case_queue,
                            uninstall_queue,
                            customConsentForm,
                            fileName,
                            selectedApis
                          );
                        }}
                        id="InReview-saveBtn"
                      >
                        <FormattedMessage
                          id="SolutionReviewForm.saveButton"
                          defaultMessage="SAVE"
                        />
                      </Button>
                    </span>
                    <Link
                      className="menu-link"
                      to={{
                        pathname: `/admin/solutions`
                      }}
                    >
                      <Button
                        className="button-left"
                        compact={'true'}
                        type="button"
                      >
                        <FormattedMessage
                          id="SolutionReviewForm.cancelButton"
                          defaultMessage="Cancel"
                        />
                      </Button>
                    </Link>
                    <Button
                      className="button-right"
                      type="button"
                      onClick={() => this.setState({ openPreviewDialog: true })}
                      id="SolutionReviewForm-PreviewBtn"
                    >
                      <FormattedMessage
                        id="SolutionReviewForm.previewButton"
                        defaultMessage="Preview"
                      />
                    </Button>
                  </LayoutGridCell>
                </LayoutGrid>
                <ActionSnackBar
                  show={!!this.state.snackBar}
                  message={
                    this.state.snackBar
                      ? this.state.snackBar.message()
                      : 'No Action taken'
                  }
                  close={() => {
                    this.setState({ snackBar: false });
                    if (
                      this.state.snackBar.message() !==
                        CONSTANTS.DEALER_CONSENT_SNACKBAR &&
                      this.state.snackBar.message() !==
                        CONSTANTS.PAYMENT_INFO_SNACKBAR &&
                      this.state.snackBar.message() !==
                        CONSTANTS.APP_PUBLISHING_FEE_SNACKBAR
                    ) {
                      this.setState({ submitted: true });
                    }
                  }}
                />
                {this.getTryAgainDialog()}
              </Form>
            </LayoutGridCell>
          </LayoutGrid>
        )}
      </div>
    );
  }
}

export const updateSolutionStatusMutation = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $status: String
    $lastUpdatedBy: String
    $lastUpdatedOn: String
    $comments: [CommentCmd]
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $attributeName: String!
    $attributeValue: String!
    $worksWithIds: [String]
    $subscription_management: SubscriptionManagementCmd
    $data_rights: DataRightsCmd
    $contract_notifications: ContractNotificationsCmd
    $payment_info: PaymentInfoCmd
    $un_authorized_signon: AuthorizedSignonCmd
    $additional_info: AdditionalInfoFromAdminCmd
    $custom_consent: CustomConsentForAdminCmd
    $isFeatured: Boolean
    $appPublishingFee: String
    $stripePriceId: String
    $listingFeeHistory: [ListingFeeHistoryCmd]
  ) {
    updateSolutionStatus(
      command: {
        id: $id
        listingVersion: $listingVersion
        status: $status
        lastUpdatedBy: $lastUpdatedBy
        lastUpdatedOn: $lastUpdatedOn
        comments: $comments
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        attributeName: $attributeName
        attributeValue: $attributeValue
        worksWithIds: $worksWithIds
        subscription_management: $subscription_management
        data_rights: $data_rights
        contract_notifications: $contract_notifications
        payment_info: $payment_info
        additional_info: $additional_info
        custom_consent: $custom_consent
        un_authorized_signon: $un_authorized_signon
        isFeatured: $isFeatured
        appPublishingFee: $appPublishingFee
        stripePriceId: $stripePriceId
        listingFeeHistory: $listingFeeHistory
      }
    ) {
      id
      listingVersion
      status
      error {
        message
      }
    }
  }
`;

const savePublishedApp = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $status: String!
    $subscription_management: SubscriptionManagementCmd
    $data_rights: DataRightsCmd
    $contract_notifications: ContractNotificationsCmd
    $payment_info: PaymentInfoCmd
    $additional_info: AdditionalInfoFromAdminCmd
    $custom_consent: CustomConsentForAdminCmd
    $un_authorized_signon: AuthorizedSignonCmd
    $worksWithIds: [String!]!
    $isFeatured: Boolean
  ) {
    savePublishedApp(
      command: {
        id: $id
        listingVersion: $listingVersion
        status: $status
        subscription_management: $subscription_management
        data_rights: $data_rights
        contract_notifications: $contract_notifications
        payment_info: $payment_info
        additional_info: $additional_info
        custom_consent: $custom_consent
        un_authorized_signon: $un_authorized_signon
        worksWithIds: $worksWithIds
        isFeatured: $isFeatured
      }
    ) {
      isSaved
      error {
        message
      }
    }
  }
`;

export const createListingFeePriceInStripe = gql`
  mutation($listingFee: String!, $countryCode: String) {
    createListingFeePriceInStripe(
      listingFee: $listingFee
      countryCode: $countryCode
    )
  }
`;

const getProviders = gql`
  query($apiIds: [ID]) {
    providers(provides: $apiIds) {
      id
      name
      entityId
      productName
      api
      fqdn
      adminAPIUrl
      termsDoc
      addendumDoc
    }
  }
`;

const getNoOfSubscription = gql`
  query($solutionId: ID) {
    solutionSubscriptions(solutionId: $solutionId) {
      id
      solutionId
      status
      dateOfPurchase
      subscriptionStartDate
      subscriptionEndDate
      plan {
        id
        planName
      }
    }
  }
`;

const getAsyncApiData = gql`
  query($id: ID) {
    asyncApiDetails(id: $id) {
      id
      orgId
      name
      status
      website
      created
      apigeeAppId
      displayName
      description
      oneToOneUI
      apiProducts {
        name
        displayName
      }
      integrations {
        id
        name
        displayName
        description
        apiType
        providers {
          id
          apiProviderName
          entityId
          pricingPlan {
            name
            type
            visibility
            id
            status
          }
        }
      }
      asyncApiIntegrations {
        id
        asyncApi {
          id
          name
        }
      }
    }
  }
`;

const getEntityData = gql`
  query($id: ID) {
    entitiesFromPlatform(id: $id) {
      id
      name
      address {
        region
        city
        street
        countryCode
        postalCode
      }
      enhancedFlags
    }
  }
`;

const getCertificationStaus = gql`
  query($orgId: String!, $solutionId: ID!, $type: String!) {
    apps(orgId: $orgId, id: $solutionId, type: $type) {
      certificationStatus
    }
  }
`;

const mapStateToProps = state => {
  const { data } = state.stripeSubscriptionData;
  return {
    stripeSubscriptionData: data.stripeSubscriptionData
  };
};
const mapDispatchToProps = dispatch => ({
  getStripeSubscriptionInfo: (id, orgId, token) =>
    dispatch(getStripeSubscriptionInfo({ id: id, orgId: orgId, token: token }))
});

export default withApollo(
  injectIntl(
    withAuth(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(SolutionReviewForm)
    )
  )
);
export const SolutionReviewFormComponent = SolutionReviewForm;
