import React from 'react';
import { Card, IconLockOutline } from 'cdk-radial';
import './SolutionCard.scss';
import { Link } from 'react-router-dom';
import { modifyStr } from '../../../utils/utils';
import { sendAmplitudeData } from '../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../utils/amplitude-constants';

const SolutionCard = props => {
  const solution = props.solution;
  const auth = props.auth;
  const worksWithValueMapObj = props.worksWithValueMapObj;
  const appName =
    (solution && solution.overview && solution.overview.name) || '';
  const solutionProviderName = (props && props.solutionProviderName) || '';
  const id = solution && solution.id;
  var freeTrial = false;
  {
    solution.plans.map(plan => {
      if (plan.isFreeTrialEnabled) {
        freeTrial = true;
      }
    });
  }
  return (
    <Link
      to={`/solutions/${modifyStr(
        solutionProviderName
          .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
          .toLowerCase()
      ).toLowerCase()}/${modifyStr(
        appName
          .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
          .toLowerCase()
      ).toLowerCase()}?id=${id}`}
    >
      <Card
        className="solution-card__component"
        data-qa="solution_card"
        data-testid="solution_card_individual"
        onClick={() => {
          sendAmplitudeData(AMPLITUDE_EVENTS.VIEW_APP, {
            [AMPLITUDE_PROPERTIES.ORG_ID]:
              auth && auth.entityId ? auth.entityId : 'NA',
            [AMPLITUDE_PROPERTIES.APP_ID]: solution.id,
            [AMPLITUDE_PROPERTIES.APP_NAME]: solution.overview.name
          });
        }}
      >
        <div className="solution-card__top_div">
          <div className="solution-card__logo_freeTrial_div">
            {solution.overview.solutionLogo ? (
              <img
                src={`${solution.overview.solutionLogo}?${Math.random()}`}
                className="solution-card__logo"
                alt=""
              />
            ) : (
              <img className="solution-card__default_logo" alt="" />
            )}

            <div className="solution-card__solutionTitle_div">
              <div style={{ float: 'left' }}>{solution.overview.name}</div>
              {solution.overview.publisherName ? (
                <div className="solution-card__solutionDeveloper">
                  {`By ${solution.overview.publisherName}`}
                </div>
              ) : (
                <div className="solution-card__solutionDeveloper" />
              )}
              {solution.activateOnly === false && freeTrial ? (
                <div className="solution-card__freeTrial_div">
                  <span className="solution-card__freeTrial_tag">
                    Free Trial
                  </span>
                </div>
              ) : (
                <div className="solution-card__empty_freeTrial_div" />
              )}
            </div>
          </div>

          {!solution.isFeatured && (
            <div className="solution-card__solutionDesciption_div">
              {solution.overview.briefDescription}
            </div>
          )}
          {solution.isFeatured && (
            <div className="solution-card__featured_div">
              <img
                src={`${solution.overview.featuredImage}?${Math.random()}`}
                className="solution-card__featured"
                alt=""
              />
            </div>
          )}
        </div>
        <div className="solution-card__ruler" />
        <div className="solution-card__worksWith_category_div">
          <div className="solution-card__worksWith_div">Category</div>
          {/* <div className="solution-card__category_div">Category</div> */}
          {/* {solution.worksWithIds && solution.worksWithIds.length > 0
                ? solution.worksWithIds.length > 1
                  ? `${worksWithValueMapObj[solution.worksWithIds[0]]} and more`
                  : `${worksWithValueMapObj[solution.worksWithIds[0]]}`
                : 'NA'} */}
          {solution.solution_visibility.solutionVisibilityType === 'Private' ? (
            <span className="solution-card__private-badge-icon-div">
              <IconLockOutline />
            </span>
          ) : (
            false
          )}
          <div className="solution-card__worksWith_solutionName">
            {solution.overview.category}
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default SolutionCard;
