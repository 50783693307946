import MySolutionsList from '../components/marketplace-account/my-solutions/MySolutionsList';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const query = gql`
  query($orgId: String) {
    solutionList(orgId: $orgId) {
      id
      listingVersion
      status
      solutionType
      overview {
        name
        briefDescription
        solutionLogo
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
      app_subscriptions {
        appSubscriptionsType
      }
      stripePriceId
      dnpAppName
    }
    solutionCatalogList(orgId: $orgId) {
      id
      listingVersion
      status
      solutionType
      overview {
        name
        briefDescription
        solutionLogo
      }
      comments {
        userId
        commentDate
        commentText
        action
      }
      app_subscriptions {
        appSubscriptionsType
      }
      dnpAppName
    }
  }
`;

export const mapDataToProps = ({ data }) => ({
  solutionList: data.solutionList || [],
  solutionCatalogList: data.solutionCatalogList || [],
  loading: data.loading
});

export default graphql(query, {
  props: mapDataToProps,
  options: ({ entity }) => ({
    variables: {
      orgId: entity.id
    }
  })
})(MySolutionsList);
