import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  AutoExpansionPanel,
  ExpansionPanelContentActions,
  ExpansionPanelContentBody,
  ExpansionPanelContent,
  ExpansionPanelHeader
} from '@cdk-uip/react-expansion-panel';
import StepContentActions from './step-content-actions';
import SubscriptionContactDetails from '../../solution-subscription/common/subscription-contact-details';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { getApolloClient } from '../../../containers/GraphQLClient';
import MySubscriptionStatus from './my-subscription-status';
import FortellisDialog from '../../common/FortellisDialog';
import { ProvisioningConstants } from '../../common/ProvisioningConstants';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import SolutionTypeURLMapperUtil from '../../common/SolutionTypeURLMapperUtil';
import NotificationConstants from '../../common/NotificationConstants';
import Spinner from '../../common/Spinner';
import ActionSnackBar from '../../solution-submission/ActionSnackBar';

const notifyUrlMutation = gql`
  mutation($endpointUrl: String!, $payload: String!) {
    notifyUrl(command: { endpointUrl: $endpointUrl, payload: $payload }) {
      status
    }
  }
`;

const updateSolutionProvisioning = gql`
  mutation(
    $id: ID
    $listingVersion: String
    $userProvisioningStatus: String
    $reminderSentOn: String
    $provisioningInitiatedOn: String
  ) {
    updateSolutionProvisioning(
      command: {
        id: $id
        listingVersion: $listingVersion
        userProvisioningStatus: $userProvisioningStatus
        reminderSentOn: $reminderSentOn
        provisioningInitiatedOn: $provisioningInitiatedOn
      }
    ) {
      reminderSentOn
      userProvisioningStatus
      userId
    }
  }
`;

const provisioningDeveloperEmailMutation = gql`
  mutation(
    $firstName: String!
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $solutionName: String!
    $solutionType: String
    $solutionId: String!
    $buyerName: String
    $buyerEmail: String
    $subscriptionId: String
    $entityId: String
  ) {
    sendEmail(
      command: {
        userDetails: { firstName: $firstName }
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionType: $solutionType
          solutionId: $solutionId
          subscriptionDetails: {
            buyerName: $buyerName
            buyerEmail: $buyerEmail
            subscriptionId: $subscriptionId
            entityId: $entityId
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;

const provisioningReminderDeveloperEmailMutation = gql`
  mutation(
    $firstName: String!
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $solutionName: String!
    $solutionType: String
    $solutionId: String!
    $buyerName: String
    $buyerEmail: String
    $purchaseDate: String
    $subscriptionId: String
    $entityId: String
  ) {
    sendEmail(
      command: {
        userDetails: { firstName: $firstName }
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionType: $solutionType
          solutionId: $solutionId
          subscriptionDetails: {
            buyerName: $buyerName
            buyerEmail: $buyerEmail
            purchaseDate: $purchaseDate
            subscriptionId: $subscriptionId
            entityId: $entityId
          }
        }
      }
    ) {
      emailResponse
    }
  }
`;

class StepSolutionProvisioningContent extends React.Component {
  state = {
    reminderSentOn: null,
    expanded: false,
    redirectDialogOpen: false,
    startProvisioningSpinner: false,
    startProvisioningSnackbarOpen: false
  };

  updateProvisioning = async variables => {
    const client = getApolloClient();
    try {
      let response = await client.mutate({
        mutation: updateSolutionProvisioning,
        variables
      });
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  getReceiverEmailAdresses = () => {
    const {
      developerId,
      subscription_notifications: { email }
    } = this.props.subscription.solution;
    let emails = [];
    emails.push(developerId);

    if (email && developerId.toLowerCase() !== email.toLowerCase()) {
      emails.push(email);
    }

    return emails;
  };

  provisioningDeveloperEmail = async subscriberId => {
    const client = getApolloClient();
    return this.getReceiverEmailAdresses().forEach(email => {
      client.mutate({
        mutation: provisioningDeveloperEmailMutation,
        variables: {
          firstName: this.props.subscription.solution.developerName.split(' ')
            .length
            ? this.props.subscription.solution.developerName.split(' ')[0]
            : '',
          emailAddress: email,
          emailSubject:
            this.props.entity.name &&
            this.props.subscription.solution.overview.name
              ? 'Provisioning Request from ' +
                this.props.entity.name +
                ' for ' +
                this.props.subscription.solution.overview.name
              : 'Provisioning Request',
          emailType: 'provisioningDeveloperEmail',
          solutionName: this.props.subscription.solution.overview.name
            ? this.props.subscription.solution.overview.name
            : ' ',
          solutionType:
            SolutionTypeURLMapperUtil.getSolutionTypeURL(
              this.props.subscription.solution.solutionType
            ) || ' ',
          solutionId: this.props.subscription.solution.id
            ? this.props.subscription.solution.id
            : ' ',
          buyerName: this.props.entity.name ? this.props.entity.name : ' ',
          buyerEmail: subscriberId || this.props.auth.userData.email,
          subscriptionId: this.props.subscription.subscriptionId
            ? this.props.subscription.subscriptionId
            : ' ',
          entityId: this.props.entity.id ? this.props.entity.id : ' '
        }
      });
    });
  };

  provisioningReminderDeveloperEmail = async subscriberId => {
    const client = getApolloClient();
    return this.getReceiverEmailAdresses().forEach(email => {
      client.mutate({
        mutation: provisioningReminderDeveloperEmailMutation,
        variables: {
          firstName: this.props.subscription.solution.developerName.split(' ')
            .length
            ? this.props.subscription.solution.developerName.split(' ')[0]
            : '',
          emailAddress: email,
          emailSubject:
            this.props.entity.name &&
            this.props.subscription.solution.overview.name
              ? 'Provisioning Reminder from ' +
                this.props.entity.name +
                ' for ' +
                this.props.subscription.solution.overview.name
              : 'Provisioning Reminder',
          emailType: 'provisioningReminderDeveloperEmail',
          solutionName: this.props.subscription.solution.overview.name
            ? this.props.subscription.solution.overview.name
            : ' ',
          solutionType:
            SolutionTypeURLMapperUtil.getSolutionTypeURL(
              this.props.subscription.solution.solutionType
            ) || ' ',
          solutionId: this.props.subscription.solution.id
            ? this.props.subscription.solution.id
            : ' ',
          buyerName: this.props.entity.name ? this.props.entity.name : ' ',
          buyerEmail: subscriberId || this.props.auth.userData.email,
          purchaseDate: this.props.subscription.provisioningInitiatedOn
            ? moment(this.props.subscription.provisioningInitiatedOn).format(
                'DD MMM YYYY'
              )
            : '',
          subscriptionId: this.props.subscription.subscriptionId
            ? this.props.subscription.subscriptionId
            : ' ',
          entityId: this.props.entity.id ? this.props.entity.id : ' '
        }
      });
    });
  };

  sendProvisioningNotification = async () => {
    const client = getApolloClient();
    let subscription = this.props.subscription;
    let solution = subscription.solution;
    let entity = this.props.entity;

    if (
      subscription &&
      subscription.subscriptionId &&
      solution.provisioning_interface &&
      solution.provisioning_interface.provisioningtype ===
        ProvisioningConstants.type.ENDPOINT_URL &&
      solution.provisioning_interface.endpointUrl
    ) {
      let payload = {
        solutionInfo: {
          id: solution.id,
          name: solution.overview.name,
          planInfo: {
            name: subscription.plan.planName,
            description: subscription.plan.planDescription
          }
        },
        subscriptionInfo: {
          id: subscription.subscriptionId,
          subscribedBy: subscription.userId,
          subscribedOn: subscription.dateOfPurchase,
          subscriberEntity: {
            id: entity.id,
            name: entity.name,
            address: {
              region: entity.address.region,
              city: entity.address.city,
              street: entity.address.street,
              countryCode: entity.address.countryCode,
              postalCode: entity.address.postalCode
            },
            phoneNumber: entity.phone,
            website: entity.website
          }
        },
        notificationType: NotificationConstants.type.PROVISIONING
      };

      let stringifiedPayload = JSON.stringify(payload);

      return await client.mutate({
        mutation: notifyUrlMutation,
        variables: {
          endpointUrl: solution.provisioning_interface.endpointUrl,
          payload: stringifiedPayload
        }
      });
    }
  };

  sendReminder = async () => {
    let response = await this.updateProvisioning({
      id: this.props.subscription.id,
      listingVersion: this.props.subscription.listingVersion,
      reminderSentOn: moment.utc().toISOString()
    });
    this.setState({
      reminderSentOn: response.data.updateSolutionProvisioning.reminderSentOn
    });
    this.provisioningReminderDeveloperEmail(
      response.data.updateSolutionProvisioning.userId
    );
  };

  disableShowSendReminder() {
    let {
      state: { reminderSentOn, userProvisioningStatus }
    } = this;
    if (userProvisioningStatus === ProvisioningConstants.status.COMPLETED) {
      return true;
    }
    if (
      moment(reminderSentOn).isValid() &&
      moment.duration(moment.utc().diff(reminderSentOn)).asHours() > 24
    ) {
      return false;
    }
    if (!moment(reminderSentOn).isValid()) {
      return false;
    }
    return true;
  }

  onClickRedirect = async () => {
    let { redirectURL } = this.getDestucturedSolution();
    let divider = isEmpty(new URL(redirectURL).search) ? `?` : `&`;
    window.open(
      `${redirectURL}${divider}subscriptionId=${
        this.props.subscription.subscriptionId
      }`
    );
    let response = await this.updateProvisioning({
      id: this.props.subscription.id,
      listingVersion: this.props.subscription.listingVersion,
      userProvisioningStatus: ProvisioningConstants.status.PENDING,
      provisioningInitiatedOn: moment().toISOString()
    });

    this.provisioningDeveloperEmail(
      response.data.updateSolutionProvisioning.userId
    );
    this.setState({
      userProvisioningStatus:
        response.data.updateSolutionProvisioning.userProvisioningStatus,
      redirectDialogOpen: false
    });
  };

  setSnackbarMessage = provisioningNotificationResp => {
    if (
      provisioningNotificationResp &&
      provisioningNotificationResp.data &&
      provisioningNotificationResp.data.notifyUrl &&
      provisioningNotificationResp.data.notifyUrl.status ===
        NotificationConstants.status.SUCCESSFUL
    ) {
      this.setState({
        startProvisioningSnackbarMessage: () => {
          return this.state.userProvisioningStatus ===
            ProvisioningConstants.status.NOT_STARTED ? (
            <FormattedMessage
              id="MarketplaceAccount.subscriptions.provisioningStarted"
              defaultMessage="Provisioning Started/Resumed Successfully"
            />
          ) : (
            <FormattedMessage
              id="MarketplaceAccount.subscriptions.provisioningResumed"
              defaultMessage="Provisioning Started/Resumed Successfully"
            />
          );
        }
      });
    } else {
      this.setState({
        startProvisioningSnackbarMessage: () => {
          return (
            <FormattedMessage
              id="MarketplaceAccount.subscriptions.provisioningStartFailed"
              defaultMessage="Start Provisioning Failed"
            />
          );
        }
      });
    }
  };

  onClickApiEndpoint = async () => {
    this.setState({ startProvisioningSpinner: true });
    let provisioningNotificationResp = await this.sendProvisioningNotification();
    this.setSnackbarMessage(provisioningNotificationResp);
    //---------------------------------
    let response = await this.updateProvisioning({
      id: this.props.subscription.id,
      listingVersion: this.props.subscription.listingVersion,
      userProvisioningStatus: ProvisioningConstants.status.PENDING
    });
    await this.provisioningDeveloperEmail(
      response.data.updateSolutionProvisioning.userId
    );
    this.setState({
      userProvisioningStatus:
        response.data.updateSolutionProvisioning.userProvisioningStatus,
      redirectDialogOpen: false,
      startProvisioningSpinner: false,
      startProvisioningSnackbarOpen: true
    });
  };

  getDestucturedSolution = () => {
    let {
      props: {
        subscription: { solution = {} }
      }
    } = this;
    let { provisioning_interface = {} } = solution;
    return provisioning_interface || {};
  };

  getGrantedActions = () => {
    let {
      state: { userProvisioningStatus }
    } = this;
    let { provisioningtype } = this.getDestucturedSolution();
    let grantedActions = [];
    if (
      this.props.userPermissions &&
      this.props.userPermissions.includes('ConfigureSubscription')
    ) {
      grantedActions.push({
        label: 'Send Reminder',
        isPrimary: false,
        isRaised: false,
        outlined: false,
        hide: isEmpty(provisioningtype)
          ? false
          : userProvisioningStatus ===
              ProvisioningConstants.status.NOT_STARTED &&
            provisioningtype !== ProvisioningConstants.type.MANUAL,
        disabled: this.disableShowSendReminder(),
        onClick: () => {
          this.sendReminder();
        }
      });

      grantedActions.push({
        label:
          userProvisioningStatus === ProvisioningConstants.status.NOT_STARTED
            ? 'Start Provisioning'
            : 'Resume Provisioning',
        isPrimary: false,
        isRaised: false,
        outlined: true,
        hide:
          provisioningtype === ProvisioningConstants.type.MANUAL ||
          isEmpty(provisioningtype),
        disabled:
          userProvisioningStatus === ProvisioningConstants.status.COMPLETED,
        onClick: async () => {
          if (provisioningtype === ProvisioningConstants.type.REDIRECT_URL) {
            this.setState({ redirectDialogOpen: true });
          } else if (
            provisioningtype === ProvisioningConstants.type.ENDPOINT_URL
          ) {
            await this.onClickApiEndpoint();
          }
        }
      });
    }
    return grantedActions;
  };

  componentDidMount() {
    let {
      props: {
        subscription: { userProvisioningStatus, reminderSentOn }
      }
    } = this;
    this.setState({ userProvisioningStatus, reminderSentOn });
  }

  render() {
    const {
      state: { userProvisioningStatus, expanded, redirectDialogOpen },
      props: { subscription }
    } = this;
    let actions = this.getGrantedActions();
    return (
      <AutoExpansionPanel
        expanded={expanded}
        onClick={() => this.setState({ expanded: !expanded })}
      >
        <ExpansionPanelHeader>
          <section className="expansion-header-section">
            <span className="panel-title">{`App Provisioning`}</span>
            <span className="panel-description">
              {userProvisioningStatus && (
                <MySubscriptionStatus status={userProvisioningStatus} />
              )}
            </span>
          </section>
        </ExpansionPanelHeader>
        <ExpansionPanelContent>
          <section>
            <ExpansionPanelContentBody>
              <div className="panel-content-div">
                <div className="panel-content-div-notes" />
                <div className="subscription-panel-content-contact-div">
                  <SubscriptionContactDetails
                    contactNote={[
                      `Provisioning will be done by App Publisher - `,
                      <b>
                        {subscription &&
                        subscription.solution &&
                        subscription.solution.overview
                          ? subscription.solution.overview.publisherName
                          : null}
                      </b>,
                      ` for `,
                      <b>
                        {subscription && subscription.plan
                          ? subscription.plan.includedUnitQuantity
                          : null}
                      </b>,
                      ` ${
                        subscription &&
                        subscription.plan &&
                        subscription.plan.includedUnitOfMeasure
                          ? subscription.plan.includedUnitOfMeasure.toLowerCase()
                          : null
                      }.`
                    ]}
                    solution={
                      subscription.solution ? subscription.solution : null
                    }
                  />
                </div>
              </div>
            </ExpansionPanelContentBody>
            <ExpansionPanelContentActions className="panel-action-div">
              {moment(this.state.reminderSentOn).isValid() && (
                <span className="subscription-action-note">
                  {`Reminder sent on ${moment(this.state.reminderSentOn).format(
                    'DD MMM YYYY'
                  )}`}
                </span>
              )}

              {this.state.startProvisioningSpinner ? (
                <Spinner />
              ) : (
                <StepContentActions actions={actions} />
              )}
            </ExpansionPanelContentActions>
          </section>
        </ExpansionPanelContent>

        <FortellisDialog
          title={'Redirecting to developer website'}
          message={
            'You will be redirected to app developer portal to capture additional information for provisioning.'
          }
          open={redirectDialogOpen}
          acceptButtonName={'Redirect'}
          cancelButtonName={'Cancel'}
          onAccept={this.onClickRedirect}
          onCancel={() =>
            this.setState({ redirectDialogOpen: !redirectDialogOpen })
          }
        />
        <ActionSnackBar
          show={!!this.state.startProvisioningSnackbarOpen}
          message={
            this.state.startProvisioningSnackbarMessage
              ? this.state.startProvisioningSnackbarMessage()
              : 'No Action taken'
          }
          close={() => {
            this.setState({ startProvisioningSnackbarOpen: false });
          }}
        />
      </AutoExpansionPanel>
    );
  }
}

export default injectIntl(withApollo(StepSolutionProvisioningContent));
