import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@cdk-uip/react-text-field';
import { ChipSet, Chip, ChipText } from '@cdk-uip/react-chips';
import { Button } from '@cdk-uip/react-button';
import { getApolloClient } from '../../../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import { Checkbox } from '@cdk-uip/react-checkbox';
import {
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Input,
  FormControl
} from '@material-ui/core';
import { modifyStr } from '../../../../../utils/utils';
import { sendAmplitudeData } from '../../../../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../../../../utils/amplitude-constants';

const updateVisibleUsers = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $users: [String]!
    $sendEmail: Boolean!
  ) {
    updateVisibleUsers(
      command: {
        id: $id
        listingVersion: $listingVersion
        users: $users
        sendEmail: $sendEmail
      }
    )
  }
`;

const sendEmailCSMG = gql`
  mutation(
    $to: [csmsgEmailInput]
    $subject: String!
    $withTemplate: withTemplateCmd
  ) {
    sendEmailCSMG(
      command: { to: $to, subject: $subject, withTemplate: $withTemplate }
    ) {
      emailResponse
    }
  }
`;

const Share = ({
  solutions,
  organizationName,
  visibleList,
  setVisibleList,
  newVisibleUsers,
  setnewVisibleUsers,
  userData
}) => {
  const [sharedSolution] = useState(() => {
    let sol = undefined;
    if (solutions && solutions.length > 0) {
      const solution = solutions.filter(sl => sl.status === 'Published');
      if (solution.length > 0) {
        sol = solution[solution.length - 1];
      }
      return sol;
    }
  });

  const [solutionURL] = useState(
    `${window.location.origin}/solutions/${modifyStr(
      organizationName
        .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
        .toLowerCase()
    ).toLowerCase()}/${modifyStr(
      sharedSolution.overview.name
        .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
        .toLowerCase()
    ).toLowerCase()}?id=${sharedSolution.id}`
  );
  const [addNewUser, setaddNewUser] = useState('');

  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [saving, setSaving] = useState(false);

  const [sendEmail, setSendEmail] = useState(true);

  const onDeleteHandler = user => {
    const list = visibleList.filter(vl => vl !== user);
    setVisibleList(list);
  };
  const onChipDelete = user => {
    const list = newVisibleUsers.filter(vl => vl !== user);
    setnewVisibleUsers(list);
  };

  const onEnterKeyPress = () => {
    let regex = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/;
    const isValidEmailFormat = regex.test(addNewUser);
    const userAlreadyExists =
      newVisibleUsers.filter(vl => vl === addNewUser).length !== 0 ||
      visibleList.filter(vl => vl === addNewUser).length !== 0;
    if (!isValidEmailFormat) {
      setisError(true);
      setErrorMessage('Please enter a valid email');
      return;
    }
    if (userAlreadyExists) {
      setisError(true);
      setErrorMessage('User already exists');
      return;
    }
    setnewVisibleUsers([...newVisibleUsers, addNewUser]);
    setaddNewUser('');
    setisError(false);
    setErrorMessage('');
  };
  const onSaveHandler = async e => {
    try {
      setSaving(true);
      e.preventDefault();

      const client = getApolloClient();
      const users = [...visibleList, ...newVisibleUsers];
      const { data } = await client.mutate({
        mutation: updateVisibleUsers,
        variables: {
          id: sharedSolution.id,
          listingVersion: sharedSolution.listingVersion,
          users,
          sendEmail
        }
      });
      const status = data.updateVisibleUsers;
      if (sendEmail && newVisibleUsers.length > 0) {
        const emailType = 'privateAppSharing';
        const subject = 'Fortellis Marketplace App';
        const loggedInUserName = (userData && userData.name) || '';
        const AppName = sharedSolution.overview.name;
        const AppURL = solutionURL;
        const template = {
          emailType,
          organizationName,
          developerName: loggedInUserName,
          AppName,
          AppURL
        };
        const emailPromises = [];
        newVisibleUsers.map(vu => {
          emailPromises.push(
            client.mutate({
              mutation: sendEmailCSMG,
              variables: {
                to: [{ email: vu }],
                subject,
                withTemplate: template
              }
            })
          );
        });
        await Promise.all(emailPromises);
      }

      if (status) {
        setVisibleList(users);
        setnewVisibleUsers([]);
        setisError(false);
        setErrorMessage('');
      }
      sendAmplitudeData(AMPLITUDE_EVENTS.SAVE_IN_SHARE, {
        [AMPLITUDE_PROPERTIES.APP_ID]: sharedSolution.id,
        [AMPLITUDE_PROPERTIES.APP_NAME]: sharedSolution.overview.name,
        [AMPLITUDE_PROPERTIES.ORG_ID]: sharedSolution.orgId,
        [AMPLITUDE_PROPERTIES.ORG_NAME]: organizationName,
        [AMPLITUDE_PROPERTIES.PRIVATE_SOLUTION_USER_EMAILS]: newVisibleUsers,
        [AMPLITUDE_PROPERTIES.SEND_EMAIL_FLAG]: sendEmail
      });
    } catch (e) {
      console.error(e);
    }
    setSaving(false);
  };
  return (
    <>
      <div
        className={`form-field-with-tooltip sub-input-chipset`}
        style={{
          marginTop: '20px'
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            onEnterKeyPress();
            e.stopPropagation();
          }}
        >
          <div className="input-chipset-container">
            <TextField
              label={
                <span className="form-field-label">
                  'Type email address and press enter'
                </span>
              }
              value={addNewUser}
              onChange={e => setaddNewUser(e.target.value.toLowerCase())}
              maxLength={100}
            />

            {isError && (
              <p
                style={{ paddingLeft: '20px' }}
                className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
              >
                {errorMessage}
              </p>
            )}

            <ChipSet className="form-input-chipset">
              {visibleList &&
                visibleList.map(chipText => (
                  <Chip key={chipText}>
                    <ChipText>{chipText}</ChipText>
                    <span
                      className="material-icons"
                      style={{ marginLeft: '5px', color: 'gray' }}
                      onClick={() => onDeleteHandler(chipText)}
                    >
                      cancel
                    </span>
                  </Chip>
                ))}
              {newVisibleUsers &&
                newVisibleUsers.map(chipText => (
                  <Chip key={chipText}>
                    <ChipText>{chipText}</ChipText>
                    <span
                      className="material-icons"
                      style={{ marginLeft: '5px', color: 'gray' }}
                      onClick={() => onChipDelete(chipText)}
                    >
                      cancel
                    </span>
                  </Chip>
                ))}
            </ChipSet>
          </div>
        </form>
      </div>
      {/* <div className="float-right">
      <i  className="Assignment">Assignment</i>
    </div> */}

      <Grid
        direction="row"
        justifyContent="right-aligned"
        alignItems="flex-start"
        container
        spacing={1}
        style={{
          paddingTop: '4%',
          paddingBottom: '4%'
        }}
      >
        <Grid item xs={9}>
          <FormControl fullWidth>
            <InputLabel>Share App URL</InputLabel>
            <Input
              id="outlined-adornment-amount"
              value={solutionURL || ''}
              onChange={() => {
                navigator.clipboard.writeText(solutionURL);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(solutionURL);
                    }}
                  >
                    <i className="material-icons">assignment</i>
                  </Button>
                </InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="left-align"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={3}>
          <Button
            data-cy={'btn_manage_listing_submit'}
            className="review__approve fmk-cover__button--highlighted"
            elevated
            onClick={onSaveHandler}
            disabled={saving}
          >
            {saving ? 'SAVING...' : 'SAVE'}
          </Button>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendEmail}
                name="Send Email"
                onChange={e => setSendEmail(e.target.checked)}
              />
            }
            label="Send Email"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Share;
