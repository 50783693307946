import React from 'react';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import PlanSubscribed from './plan-subscribed';

const SolutionSubscriptionConfirm = props => {
  return (
    <div className="component-content">
      <LayoutGrid className="fm-page-content-grid max-width-resp page-padding-common">
        <LayoutGridCell span={12} className="myaccount-component-content">
          <PlanSubscribed {...props} />
        </LayoutGridCell>
      </LayoutGrid>
    </div>
  );
};

export default SolutionSubscriptionConfirm;
