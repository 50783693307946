/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Tooltip } from '@cdk-uip/react-tooltip';
import { LayoutGrid, LayoutGridCell } from '@cdk-uip/react-layout-grid';
import {
  withTooltip,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  THEMES,
  TOOLTIP_VERTICAL_ALIGNMENTS
} from 'cdk-radial';
import Status from '../../../marketplace-account/Status';
import SolutionStatus from '../../../common/SolutionStatus';
import CogsLogo from '../../../common/images/cogs.png';
import AppSubscriptionsConstants from '../../../common/AppSubscriptions';
import FortellisBadge from '../../../common/FortellisBadge';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import Spinner from '../../../common/Spinner';
import VerifyAccount from './panels/verifyAccount/VerifyAccount';
import { useSelector, useDispatch } from 'react-redux';
import {
  otherPaymentOptionRequest,
  getPaymentSetting
} from '../../../../redux/connectedComponents/verifyAccount/verifyAccount.slice';
import { getSavedPaymentDetailsBySolutionId } from '../../../../redux/connectedComponents/verifyAccount/savedPaymentDetails.slice';
import { getAllBanksAppsForUser } from '../../../../redux/connectedComponents/verifyAccount/bankApps.slice';
import { environmentURLs } from '../../../common/environment/CaptureEnvironment';
import { getApolloClient } from '../../../../containers/GraphQLClient';
import gql from 'graphql-tag';

const ActivationType = lazy(() => import('../MySolutionList-activationType'));

const SolutionHeaderCommon = ({
  solution,
  isSolutionReview,
  isSolutionManagement,
  refreshTriggerForPendingAppSubscriptions,
  triggerActiveMenuId,
  token
}) => {
  const verifyAccountSliceData = useSelector(state => state.verifyAccount);
  const { paymentSettingData, loading } = verifyAccountSliceData.data;
  const savedPaymentSliceData = useSelector(state => state.savedPaymentDetails);
  const {
    savedPaymentDetails,
    savedPaymentDetailsLoading
  } = savedPaymentSliceData;
  const bankAppsSliceData = useSelector(state => state.bankAppsDetails);
  const { bankApps, bankAppsLoading } = bankAppsSliceData;
  const stripeSubscriptionSliceData = useSelector(
    state => state.stripeSubscriptionData
  );
  const { stripeSubscriptionData } = stripeSubscriptionSliceData.data;
  const dispatch = useDispatch();
  const dnpAppUrl =
    solution &&
    `${environmentURLs && environmentURLs.developerApps}/solutions/view/${
      solution.id
    }`;
  //this check is required as the solution passed in for SolutionSubmissionForm has nested overview property
  let solutionOverview = solution && solution.overview ? solution.overview : '';
  if (solution && solution.overview && solution.overview.overview) {
    solutionOverview = solution.overview.overview;
  }

  const [certificationStatusFetched, setCertificationStatusFetched] = useState(
    false
  );
  const [latestCertificationStatus, setLatestCertificationStatus] = useState(
    ''
  );
  const fetchLatestCertificationStatus = gql`
    query(
      $orgId: String!
      $solutionId: ID!
      $type: String!
      $listingVersion: String
    ) {
      apps(
        orgId: $orgId
        id: $solutionId
        type: $type
        listingVersion: $listingVersion
      ) {
        certificationStatus
      }
    }
  `;

  const getLatestSolutionCertificationStatus = async data => {
    const client = getApolloClient();
    const currentCertificationStatus = await client.query({
      query: fetchLatestCertificationStatus,
      variables: {
        orgId: data.orgId,
        solutionId: data.id,
        type: 'f'
      }
    });
    return currentCertificationStatus;
  };

  useEffect(() => {
    if (solution && solution.id && solution.orgId) {
      let status = '';
      getLatestSolutionCertificationStatus(solution).then(data => {
        status = data.data?.apps[0]?.certificationStatus;
        if (status) {
          setLatestCertificationStatus(status);
          setCertificationStatusFetched(true);
        } else {
          setCertificationStatusFetched(true);
        }
      });
      if (!isSolutionReview) {
        dispatch(
          getSavedPaymentDetailsBySolutionId({
            solnId: solution.id,
            orgId: solution.orgId,
            token: token
          })
        );
        dispatch(getAllBanksAppsForUser({ orgId: solution.orgId, token }));
      }
    }
  }, [solution]);

  const dnpAppName =
    solution && solution.dnpAppName
      ? solution.dnpAppName
      : solutionOverview && solutionOverview.name;
  const handleChooseAnotherPaymentOption = e => {
    e.preventDefault();
    dispatch(otherPaymentOptionRequest(true));
  };

  return (
    <LayoutGrid className="fm-page-title-grid title-content">
      {solution && (
        <LayoutGridCell span={12} className="solution-name-title">
          {solutionOverview && solutionOverview.solutionLogo ? (
            <img
              alt="App Logo"
              className="fm-solution-name-title-logo"
              src={`${solutionOverview.solutionLogo}?${Math.random()}`}
            />
          ) : (
            <img
              alt="App Logo"
              className="fm-solution-name-title-logo"
              src={CogsLogo}
            />
          )}
          <div className="solution-name-title-div">
            <div className="solution-details-sec">
              <div className="solution-name-title-name">
                {solutionOverview && solutionOverview.name}
              </div>
              <div className="solution-common-header-subtitles">
                Updated:{' '}
                <span className="solution-common-header-subtitles-value">
                  {moment(solution.lastUpdatedOn).format('DD MMM YYYY')}
                </span>
              </div>
              <div className="solution-common-header-subtitles">
                On Developer Account:{' '}
                {isSolutionReview ? (
                  <span className="solution-common-header-subtitles-value">
                    {dnpAppName}
                  </span>
                ) : (
                  <a
                    className="solution-common-header-link"
                    data-testid="developer-app-name"
                    href={dnpAppUrl}
                    target="_blank"
                  >
                    {dnpAppName}
                  </a>
                )}
              </div>
            </div>
            {isSolutionManagement ? (
              solution &&
              solution.app_subscriptions &&
              solution.app_subscriptions.appSubscriptionsType &&
              solution.app_subscriptions.appSubscriptionsType ===
                AppSubscriptionsConstants.MANUAL ? (
                <Suspense
                  fallback={
                    <div className="app-activation-column-spinner">
                      <Spinner />
                    </div>
                  }
                >
                  <ActivationType
                    solutionId={solution.id}
                    isSolutionManagement={isSolutionManagement}
                    refreshTriggerForPendingAppSubscriptions={
                      refreshTriggerForPendingAppSubscriptions
                    }
                  />
                </Suspense>
              ) : (
                <div className="app-activation-solution-header-auto">
                  App Activation: Auto
                </div>
              )
            ) : null}

            {solution.status && (
              <div className="solution-status-in-sol-management">
                {isSolutionReview && (
                  <div className="solution-name-title-name admin-listing-version">
                    Listing Version: {solution.listingVersion}
                  </div>
                )}
                {solution.status && certificationStatusFetched && (
                  <Status
                    icon="lens"
                    status={
                      solution.status
                        ? solution.status || 'Registered'
                        : 'Registered'
                    }
                    certificationStatus={latestCertificationStatus}
                  />
                )}
                {isSolutionReview &&
                  stripeSubscriptionData &&
                  stripeSubscriptionData.length > 0 && (
                    <div className="autopayment-div">
                      {solution?.address?.countryCode?.toLowerCase() === 'us' &&
                        `Autopay: $${stripeSubscriptionData[0].taxable_amount}`}
                      {solution?.address?.countryCode?.toLowerCase() === 'ca' &&
                        `Autopay: $${
                          stripeSubscriptionData[0].taxable_amount
                        } CAD`}
                    </div>
                  )}
                {solution.status === SolutionStatus.REJECTED && (
                  <div>
                    {errorWithTooltip(solution)}
                    {/* <Tooltip
                    className="view-error-tooltip tooltip-submission-form-error"
                    htmlFor={`tooltip_${solution.id}`}
                  >
                    <div>
                      {solution.comments && solution.comments.length > 0 ? (
                        solution.comments[solution.comments.length - 1]
                          .commentText
                      ) : solution.comments &&
                        solution.comments.comments &&
                        solution.comments.comments.length > 0 ? (
                        solution.comments.comments[
                          solution.comments.comments.length - 1
                        ].commentText
                      ) : (
                        <FormattedMessage
                          id="FormTitle.commentText"
                          defaultMessage="No Comments"
                        />
                      )}
                    </div>
                    </Tooltip> */}
                  </div>
                )}

                {/* handle customerRef */}
                {!loading &&
                  !savedPaymentDetailsLoading &&
                  solution.status === SolutionStatus.PUBLISH_PENDING &&
                  paymentSettingData &&
                  paymentSettingData.filter(
                    x => x.payment_type === 'stripe_bank' && !x.status
                  ).length > 0 && (
                    <div>
                      <VerifyAccount
                        solnId={solution.id}
                        orgId={solution.orgId}
                        loggedInEmail={solution.developerId}
                        token={token}
                        stripePriceId={solution.stripePriceId}
                        savedSolutionPaymentDetails={savedPaymentDetails}
                        paymentSettingData={paymentSettingData.filter(
                          x =>
                            x.payment_type === 'stripe_bank' &&
                            savedPaymentDetails?.last4 === x.last4
                        )}
                        bankApps={bankApps}
                      />
                    </div>
                  )}
                {!loading &&
                  solution.status === SolutionStatus.PUBLISH_PENDING &&
                  paymentSettingData &&
                  paymentSettingData.filter(
                    x => x.payment_type === 'stripe_bank' && !x.status
                  ).length === 0 && (
                    <div classname="verify-account">
                      <a
                        className="another-pay-option-anchor"
                        onClick={e => handleChooseAnotherPaymentOption(e)}
                      >
                        <span className="verify-account-link-container">
                          Choose payment option to publish
                        </span>
                      </a>
                    </div>
                  )}
              </div>
            )}
          </div>
        </LayoutGridCell>
      )}
    </LayoutGrid>
  );
};
const errorWithTooltip = props => {
  const { comments } = props;
  const tooltipText =
    comments && comments.length > 0
      ? comments[comments.length - 1].commentText
      : 'No comments has entered.';
  const tooltipProps = {
    id: 'error-tooltip',
    text: tooltipText,
    theme: THEMES.LIGHT
  };

  const ComponentToRender = props => {
    return (
      <div
        id="common-header-error-tooltip"
        className="status-info-header-table"
        {...props}
      >
        View Errors
      </div>
    );
  };
  const ComponentWithTooltip = withTooltip(ComponentToRender, tooltipProps);
  return (
    <ComponentWithTooltip
      horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.MIDDLE}
      marginAroundElement={8}
      verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.BOTTOM}
    />
  );
};

export default injectIntl(SolutionHeaderCommon);
