import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { environmentURLs } from '../../components/common/environment/CaptureEnvironment';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import UserConsentDialog from '../common/UserConsentDialog';
import gql from 'graphql-tag';
import { getApolloClient } from '../../containers/GraphQLClient';

const DeveloperAgreement = props => {
  const client = getApolloClient();
  const { publisher_agreement_pro } = environmentURLs;
  const [developerAgreement, setDeveloperAgreement] = useState();
  const [saving, setSaving] = useState(false);
  const getStampedDeveloperAgreement = async () => {
    try {
      const appListingFee =
        (props.solution &&
          props.solution.stripePriceId &&
          props.solution.appPublishingFee) ||
        '0';
      const countryCode = props?.entity?.address?.countryCode;
      let response = await client.query({
        query: stampDeveloperAgreement,
        variables: {
          appListingFee: appListingFee,
          countryCode: countryCode
        }
      });

      if (
        response &&
        response.data &&
        response.data.getStampedDeveloperAgreement &&
        response.data.getStampedDeveloperAgreement.file
      ) {
        setDeveloperAgreement(response.data.getStampedDeveloperAgreement.file);
      } else {
        console.error('ERROR IN GETTING APP PUBLISHING AGREEMENT');
        props.onClose({
          status: 'declined'
        });
      }
    } catch (e) {
      console.error('ERROR IN GETTING APP PUBLISHING AGREEMENT');
      props.onClose({
        status: 'declined'
      });
    }
  };

  const saveFileToS3AndGetUrl = async () => {
    setSaving(true);
    try {
      let response = await client.query({
        query: getdeveloperAgreementS3Url,
        variables: {
          appId: props.solution.id,
          orgId: props.solution.orgId,
          listingVersion: props.solution.listingVersion,
          file: developerAgreement
        }
      });

      if (
        !response ||
        !response.data ||
        !response.data.developerAgreementS3Url ||
        !response.data.developerAgreementS3Url.s3url
      ) {
        setSaving(false);
        props.onClose({
          status: 'declined'
        });
      } else {
        setSaving(false);
        const savedFileS3Url = response.data.developerAgreementS3Url.s3url;
        props.onClose({
          status: 'accepted',
          url: savedFileS3Url,
          publisherAgreementDetails: {
            status: 'accepted',
            entityId: props.entityContext.entity.id,
            entity: props.entityContext.entity.name,
            acceptedBy: props.auth.userData.name,
            acceptedByEmail: props.auth.userData.email
          }
        });
        if (props.updateAppActivationData) {
          props.modifyAppActivationData();
        }
      }
    } catch (e) {
      console.error('error in querying developerAgreementSigningUrl', e);
      setSaving(false);
      props.onClose({
        status: 'declined'
      });
    }
  };

  const onComplete = () => {
    if (props.solution.activateOnly === false) {
      props.onClose({
        status: 'accepted',
        url: publisher_agreement_pro,
        publisherAgreementDetails: {
          status: 'accepted',
          entityId: props.entityContext.entity.id,
          entity: props.entityContext.entity.name,
          acceptedBy: props.auth.userData.name,
          acceptedByEmail: props.auth.userData.email
        }
      });
    } else saveFileToS3AndGetUrl();
  };

  const onClose = () => {
    props.onClose({
      status: 'declined'
    });
  };

  useEffect(() => {
    if (props.solution.activateOnly === false)
      setDeveloperAgreement(publisher_agreement_pro);
    else getStampedDeveloperAgreement();
  }, []);

  return (
    <UserConsentDialog
      title={`Publisher Agreement`}
      submitButtonName="Submit"
      cancelButtonName="Cancel"
      onSubmit={() => onComplete()}
      onCancel={() => {
        onClose();
      }}
      consentLink={developerAgreement}
      type={'publisher-agreement'}
      saving={saving}
    />
  );
};

DeveloperAgreement.propTypes = {
  onClose: PropTypes.func
};

export default withAuth(withEntityContext(DeveloperAgreement));

const stampDeveloperAgreement = gql`
  query($appListingFee: String, $countryCode: String) {
    getStampedDeveloperAgreement(
      appListingFee: $appListingFee
      countryCode: $countryCode
    ) {
      file
    }
  }
`;

const getdeveloperAgreementS3Url = gql`
  query(
    $appId: String
    $orgId: String
    $listingVersion: String
    $file: String
  ) {
    developerAgreementS3Url(
      appId: $appId
      orgId: $orgId
      listingVersion: $listingVersion
      file: $file
    ) {
      s3url
    }
  }
`;
