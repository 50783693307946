import React from "react";
import { Subheading1 } from "@cdk-uip/react-typography";
import FormContent from "./FormContent";

export class FormTabContent extends React.Component {
  render() {
    return (
      <div>
        {this.props.formFieldValues ? (
          this.props.tabs.map(tab => {
            return (
              tab.contents &&
              this.props.activeTabId === tab.id && (
                <FormContent
                  {...this.props}
                  key={tab.id}
                  contents={tab.contents}
                  formFieldValuesAll={this.props.formFieldValuesAll}
                  formStaticValues={this.props.formStaticValues}
                  formFieldValues={this.props.formFieldValues}
                  formValueCount={this.props.formValueCount}
                  formValues={this.props.formValues}
                  onSave={this.props.onSave}
                />
              )
            );
          })
        ) : (
          <Subheading1 className="notes">
            {this.props.noFormFieldNote}
          </Subheading1>
        )}
      </div>
    );
  }
}

export default FormTabContent;
